import React, { useEffect } from 'react';
import { AddressFormProps, orderAddressesType } from './types';
import CustomTable from 'components/molecules/Table';
import AddressFormHeader from '../Header';
import { StyledAddressesGridBox, StyledAddressesHeaderBox } from './styles';
import AddressFormHeaderWithStates from '../HeaderWithStates';
import { useParams } from 'react-router-dom';
import useSelectableRows from 'hooks/useSelectableRows';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSelectedStateIdForm,
  setOrderAddressesForm,
  setProximityAddressesOrder,
  setStatesDataOrder,
  setSelectedShippingRowsForState,
  setNewOrderAddresses,
  setSelectedFarmsRows,
} from 'store/features/addressesManager/index.slice';
import {
  getAddressesByStateForm,
  getSelectedStateIdForm,
  getSelectedFarmsRows,
  getStatesDataOrder,
  getSelectedShippingRows,
  getNewOrderAddresses,
} from 'store/features/addressesManager/index.selectors';
import { getDataVisibility } from 'utils';
import { RootState } from 'store';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import RenderByCondition from 'components/atoms/RenderByCondition';

type StateInfo = {
  name: string;
  id?: string;
  code?: string;
  color?: string;
};

const AddressFormContent: React.FC<AddressFormProps> = ({
  addressData,
  editMode,
  subTitle,
  onClose,
  addressType,
  onSelectDone,
  modePreviewSelectCount,
  modePreviewTotalCount,
  selectType = 'multiple',
  activeTab = 0,
  CurrentAddressData,
  CurrentAddressState,
  fromShippingPage = false,
  isOrderForm = false,
  custmerId,
}) => {
  const addressesByStateForm = useSelector(getAddressesByStateForm);
  const statesDataOrder = useSelector(getStatesDataOrder);
  const selectedStateIdForm = useSelector(getSelectedStateIdForm);
  const newOrderAddresses = useSelector(getNewOrderAddresses);

  const selectedFarmsRows = useSelector(getSelectedFarmsRows);
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const idFromParams = parseInt(id, 10) || 0;
  const [inputSearchValue, setInputSearchValue] = React.useState('');

  const [state, actions] = useSelectableRows({
    rows: addressData,
    initialMode: selectType,
    stateId: selectedStateIdForm,
  });
  const { isAllChecked, selectedRows, isAllUnchecked } = state;
  const { onSelectAllRows, onSelectRow, AddSelectedRows } = actions;

  const selectedShippingRowsForState = useSelector((state: RootState) =>
    getSelectedShippingRows(state, selectedStateIdForm)
  );

  const handleStateClick = (state: StateInfo) => {
    if (selectedStateIdForm !== state.id) {
      dispatch(setSelectedStateIdForm(state.id));
    }
  };

  function getUniqueArray(
    selectedStatesData: any[],
    tempStateData: any[] | null | undefined
  ): any[] {
    const filteredStates =
      tempStateData?.reduce((acc, selectedAddress) => {
        if (!acc.some((item) => item.id === selectedAddress.stateId)) {
          acc.push({
            variant: 'body1',
            name: selectedAddress.stateName,
            id: selectedAddress.stateId,
          });
        }
        return acc;
      }, []) ?? [];

    const combinedArray = [...filteredStates, ...selectedStatesData];

    return combinedArray.reduce((acc: any[], current: any) => {
      const isDuplicate = acc.some((item) => item.id === current.id);
      if (!isDuplicate) {
        acc.push(current);
      }
      return acc;
    }, []);
  }

  const dispatch = useDispatch();
  const {
    ADDRESSES_BY_STATE_VISIBILITY,
    REACT_APP_ADDRESS_MANAGEMENT_VISIBILITY,
  } = getDataVisibility();

  useEffect(() => {
    const farmsSet = new Set(selectedRows);

    if (activeTab == 0 && selectedRows) {
      let selectedStatesData: any[] = [];
      dispatch(setSelectedFarmsRows(Array.from(farmsSet)));

      const selectedOrderAddresses: any[] = [];

      selectedRows?.forEach((rowId: number) => {
        if (rowId == 0) {
          return;
        }
        const selectedAddress = addressData?.find((add) => add?.id === rowId);

        const filteredSelectedStatesData = selectedStatesData?.filter(
          (stateData) => stateData?.id !== selectedAddress?.stateId
        );

        filteredSelectedStatesData.push({
          id: selectedAddress?.stateId,
          name: selectedAddress.stateName,
          variant: 'body1',
        });

        selectedOrderAddresses.push({
          orderId: idFromParams,
          addressId: selectedAddress?.id,
          farmId: selectedAddress?.farmId,
        });

        selectedStatesData = filteredSelectedStatesData;
      });

      let tempStateData: any[];
      if (activeTab === 0) {
        tempStateData = CurrentAddressData ?? [];
      } else if (activeTab === 1) {
        tempStateData = CurrentAddressState ?? CurrentAddressData;
      }

      const uniqueArray = getUniqueArray(selectedStatesData, tempStateData);

      dispatch(setProximityAddressesOrder(selectedOrderAddresses));

      if (
        (uniqueArray?.length < statesDataOrder?.length &&
          uniqueArray?.length > 0 &&
          isOrderForm) ||
        isAllUnchecked
      ) {
        const ShippingSet = new Set();

        const uniqueIds = new Set(uniqueArray?.map((item) => item?.id));
        const missingIds = statesDataOrder
          .map((item) => item?.id)
          .filter((id) => id && id !== '0' && !uniqueIds?.has(id));

        missingIds?.forEach((element) => {
          dispatch(
            setSelectedShippingRowsForState({
              stateId: element,
              rows: Array.from(ShippingSet),
            })
          );
        });
        const missingIdsNumberParse: number[] = missingIds?.map((id) =>
          parseInt(id)
        );

        const filteredNewOrderAddresses = newOrderAddresses?.filter(
          (address) => !missingIdsNumberParse?.includes(address?.stateId)
        );

        dispatch(setNewOrderAddresses(filteredNewOrderAddresses));
      }

      dispatch(setStatesDataOrder(uniqueArray));
    }
  }, [selectedRows]);

  useEffect(() => {
    if (activeTab == 0) {
      AddSelectedRows(new Set(selectedFarmsRows));

      dispatch(setSelectedStateIdForm(''));
    } else if (activeTab == 1 && !isOrderForm) {
      dispatch(setSelectedStateIdForm(statesDataOrder[0]?.id ?? ''));
      AddSelectedRows(new Set(selectedShippingRowsForState));
    }
  }, [activeTab]);

  useEffect(() => {
    const farmsSet = new Set(selectedRows);

    if (activeTab == 1 && selectedRows) {
      dispatch(
        setSelectedShippingRowsForState({
          stateId: selectedStateIdForm,
          rows: Array.from(farmsSet),
        })
      );

      const selectedOrderAddresses: any[] = [];

      selectedRows?.forEach((rowId: number) => {
        const selectedAddress = addressData?.find((add) => add?.id === rowId);

        selectedOrderAddresses.push({
          orderId: idFromParams,
          stateId: selectedStateIdForm,
          addressId: selectedAddress?.id,
          farmId: 0,
        });
      });
      if (!isOrderForm) {
        dispatch(setOrderAddressesForm(selectedOrderAddresses));

        const existingNewOrderAddresses: orderAddressesType[] =
          newOrderAddresses;

        const filteredAddresses = existingNewOrderAddresses?.filter(
          (address) => address.stateId !== Number(selectedStateIdForm)
        );

        const updatedAddresses = [
          ...filteredAddresses,
          ...selectedOrderAddresses,
        ];

        const validAddresses = updatedAddresses.filter(
          (address) => address.addressId !== undefined
        );

        dispatch(setNewOrderAddresses(validAddresses));
      } else if (isOrderForm) {
        dispatch(setOrderAddressesForm(selectedOrderAddresses));

        const existingNewOrderAddresses: orderAddressesType[] =
          newOrderAddresses;

        const filteredAddresses = existingNewOrderAddresses?.filter(
          (address) => address.stateId !== Number(selectedStateIdForm)
        );

        const updatedAddresses = [
          ...filteredAddresses,
          ...selectedOrderAddresses,
        ];

        const validAddresses = updatedAddresses.filter(
          (address) => address.addressId !== undefined
        );

        dispatch(setNewOrderAddresses(validAddresses));
      }
    }
  }, [selectedRows]);

  useEffect(() => {
    if (activeTab == 1) {
      AddSelectedRows(new Set(selectedShippingRowsForState));
    }
  }, [selectedStateIdForm]);

  useEffect(() => {
    if (activeTab == 1) {
      const ids = statesDataOrder?.map((item) => item.id);
      const isValid =
        selectedStateIdForm == null ||
        selectedStateIdForm == '' ||
        selectedStateIdForm == '0' ||
        !ids.includes(selectedStateIdForm);
      if (isOrderForm && isValid) {
        dispatch(setSelectedStateIdForm(statesDataOrder[0]?.id ?? ''));
      }
    }
  }, [activeTab]);

  return (
    <form>
      <StyledAddressesHeaderBox>
        {!ADDRESSES_BY_STATE_VISIBILITY && (
          <AddressFormHeader
            subTitle={subTitle}
            addressData={addressData}
            editMode={editMode}
            onSelectRow={onSelectRow}
            onSelectAllRows={onSelectAllRows}
            selectedRows={selectedRows}
            isAllChecked={isAllChecked}
            id={''}
            onClose={onClose}
            addressType={addressType}
            onSelectDone={onSelectDone}
            modePreviewSelectCount={modePreviewSelectCount}
            modePreviewTotalCount={modePreviewTotalCount}
            showSelectAll={selectType === 'multiple'}
            custmerId={custmerId}
            inputSearchValue={inputSearchValue}
            setInputSearchValue={(value) => setInputSearchValue(value)}
            isOrderForm={isOrderForm}
          />
        )}
        {fromShippingPage && isOrderForm && ADDRESSES_BY_STATE_VISIBILITY && (
          <AddressFormHeader
            subTitle={subTitle}
            addressData={addressData}
            editMode={editMode}
            onSelectRow={onSelectRow}
            onSelectAllRows={onSelectAllRows}
            selectedRows={selectedRows}
            isAllChecked={isAllChecked}
            id={''}
            onClose={onClose}
            addressType={addressType}
            onSelectDone={onSelectDone}
            modePreviewSelectCount={modePreviewSelectCount}
            modePreviewTotalCount={modePreviewTotalCount}
            showSelectAll={selectType === 'multiple'}
            statesData={statesDataOrder}
            isOrderForm={isOrderForm}
            custmerId={custmerId}
            inputSearchValue={inputSearchValue}
            setInputSearchValue={(value) => setInputSearchValue(value)}
          />
        )}
        {fromShippingPage && ADDRESSES_BY_STATE_VISIBILITY && (
          <AddressFormHeaderWithStates
            subTitle={subTitle}
            addressData={addressData}
            editMode={editMode}
            onSelectRow={onSelectRow}
            onSelectAllRows={onSelectAllRows}
            selectedRows={selectedRows}
            isAllChecked={isAllChecked}
            id={''}
            onClose={onClose}
            addressType={addressType}
            onSelectDone={onSelectDone}
            modePreviewSelectCount={modePreviewSelectCount}
            modePreviewTotalCount={modePreviewTotalCount}
            showSelectAll={selectType === 'multiple'}
            statesData={statesDataOrder}
            selectedStateId={selectedStateIdForm}
            handleStateClick={handleStateClick}
            isStateActive={activeTab == 1}
            addressesByState={addressesByStateForm}
            custmerId={custmerId}
            displayAddAddress={
              activeTab === 0 || (activeTab === 1 && isOrderForm)
            }
          />
        )}
      </StyledAddressesHeaderBox>
      <StyledAddressesGridBox>
        <RenderByCondition
          show={[!isOrderForm && ADDRESSES_BY_STATE_VISIBILITY]}
        >
          <>
            <Box mb={2}>
              <Typography
                variant="body1"
                color="neutral.main"
                fontWeight={800}
                display="inline"
              >
                {t('orderForm.orderCurrentAddresse')} :
              </Typography>
            </Box>
            <RenderByCondition show={[CurrentAddressData?.length > 0]}>
              <CustomTable
                rows={CurrentAddressData}
                selectionMode={!editMode}
                hasPagination={false}
                isAllChecked={isAllChecked}
                selectedRows={new Set()}
                onSelectRow={onSelectRow}
                onSelectAllRows={onSelectAllRows}
                rowsPerPage={100}
                addressType={addressType}
                showSelectAll={selectType === 'multiple'}
                showStatus={true}
              />
            </RenderByCondition>
            <RenderByCondition show={[CurrentAddressData?.length < 1]}>
              <Typography
                variant="body2"
                color="neutral.main"
                fontWeight={500}
                display="inline"
              >
                No Addresses found for the selected state!
              </Typography>
            </RenderByCondition>
          </>
        </RenderByCondition>
        <RenderByCondition show={[REACT_APP_ADDRESS_MANAGEMENT_VISIBILITY]}>
          <Typography
            variant="body1"
            color="neutral.main"
            fontWeight={800}
            display="inline"
          >
            {t('orderForm.addMoreAddresse')} :
          </Typography>
        </RenderByCondition>
        <CustomTable
          rows={addressData ?? []}
          selectionMode={editMode}
          hasPagination={false}
          isAllChecked={isAllChecked}
          selectedRows={selectedRows}
          onSelectRow={onSelectRow}
          onSelectAllRows={onSelectAllRows}
          rowsPerPage={addressData?.length}
          addressType={addressType}
          showSelectAll={selectType === 'multiple'}
          inputSearchValue={inputSearchValue}
        />
      </StyledAddressesGridBox>
    </form>
  );
};

export default AddressFormContent;
