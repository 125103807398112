import React, { useState } from 'react';
import { StyledTab, StyledTabs } from './style';
import { Box, Grid } from '@mui/material';

interface IsolateExtensionTabProps {
  tabs: Array<{ label: string; tabComponent: JSX.Element }>;
  activeTab?: number;
  setActiveTab?: React.Dispatch<React.SetStateAction<number>>;
  canSwitchTab?: boolean;
}

const IsolateExtensionTab = (props: IsolateExtensionTabProps) => {
  const { tabs, activeTab, setActiveTab, canSwitchTab } = props;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [localActiveTab, setLocalActiveTab] = useState(0);

  const handleChange = (event, newValue) => {
    if (!canSwitchTab) return;
    if (setActiveTab) {
      setActiveTab(newValue);
    } else {
      setLocalActiveTab(newValue);
    }
  };
  const value = typeof activeTab === 'number' ? activeTab : localActiveTab;

  return (
    <Grid
      item
      sx={{
        minHeight: { xs: 400, md: 700 },
        maxHeight: { xs: 400, md: 700 },
      }}
    >
      <Box width={'100%'}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="standard"
          allowScrollButtonsMobile
        >
          {tabs.map((tab, index) => (
            <StyledTab key={tab?.label} label={tab.label} value={index} />
          ))}
        </StyledTabs>
      </Box>
      <Grid item xs={12}>
        {tabs[value].tabComponent}
      </Grid>
    </Grid>
  );
};

export default IsolateExtensionTab;
