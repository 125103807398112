import { Grid, Typography, styled } from '@mui/material';
import CustomButton from 'components/atoms/Button';
import theme from 'theme';

export const StyledForm = styled('form')`
  padding: 1rem 1rem 1rem 1rem;
`;
export const StyledGrid = styled(Grid)`
  display: flex;
  padding: 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Extended-Palette-Blue-Lightest, #e5f7fd);
`;
export const StyledButton = styled(CustomButton)`
  ${() => ({
    boxShadow: 'none',
    background: theme.palette.neutral[50],
    ':hover': {
      borderWidth: 0,
      outline: 'none',
      background: theme.palette.neutral[50],
    },
    ':active': {
      borderWidth: 0,
      outline: 'none',
      background: theme.palette.neutral[50],
    },
    ':focus': {
      borderWidth: 0,
      outline: 'none',
      background: theme.palette.neutral[50],
    },
  })}
`;
export const StyledDescription = styled(Typography)`
  color: ${theme.palette.primary[900]};
  font-family: ${theme.typography.fontFamily};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
`;

export const StyledTitle = styled(Typography)`
  ${{
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '35px',
    color: theme.palette.primary.main,
  }}
`;

export const StyledSelect = styled(Grid)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.between('xs', 'sm')]: {
        width: '60%',
      },
      [theme.breakpoints.up('sm')]: {
        width: '100%',
      },
    };
  }};
`;

export const StyledComment = styled(Grid)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.between('xs', 'sm')]: {
        width: '60%',
      },
      [theme.breakpoints.up('sm')]: {
        width: '100%',
      },
    };
  }};
`;

export const StyledBox = styled(Grid)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.between('xs', 'sm')]: {
        width: '100%',
      },
      [theme.breakpoints.up('sm')]: {
        width: '100%',
      },
    };
  }};
`;
export const StyledButtonGroup = styled(Grid)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down('xs')]: {
        width: '30%',
      },
      [theme.breakpoints.between('xs', 'sm')]: {
        width: '60%',
      },
      [theme.breakpoints.up('sm')]: {
        width: '100%',
      },
    };
  }};
`;
