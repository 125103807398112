import ErrorUI from 'components/organisms/ErrorUI';
import withLayout from 'layout';
import { useTranslation } from 'react-i18next';

const ErrorPage = () => {
  const { t } = useTranslation();

  return (
    <ErrorUI
      dataTestId={'500-error-page'}
      title={'500'}
      description={t('errorPage.internalErrorDescription')}
    />
  );
};

export default withLayout(ErrorPage);
