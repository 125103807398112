import { SVGProps } from 'react';

const Logout = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 23"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill="#29306B"
      d="M9.333 3.333l-.94.94 1.054 1.06H4v1.334h5.447L8.393 7.72l.94.947L12 6 9.333 3.333zm-8-2H6V0H1.333C.6 0 0 .6 0 1.333v9.334C0 11.4.6 12 1.333 12H6v-1.333H1.333V1.333z"
    />
  </svg>
);

export default Logout;
