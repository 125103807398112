import React, { SVGProps } from 'react';

const View = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#6A7178"
      d="M8 4.333c2.526 0 4.78 1.42 5.88 3.667A6.506 6.506 0 018 11.667 6.506 6.506 0 012.12 8 6.513 6.513 0 018 4.333zM8 3C4.665 3 1.82 5.073.665 8c1.153 2.927 4 5 7.333 5 3.334 0 6.18-2.073 7.334-5-1.154-2.927-4-5-7.334-5zm0 3.333a1.667 1.667 0 11-.002 3.335A1.667 1.667 0 018 6.333zM8 5C6.345 5 5 6.347 5 8s1.346 3 3 3c1.653 0 3-1.347 3-3S9.652 5 8 5z"
    ></path>
  </svg>
);

export default View;
