import {
  Badge,
  BadgeProps,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItemTextProps,
  Menu,
  MenuItem,
  styled,
} from '@mui/material';

export const StyledBadge: any = styled(Badge)(({ theme, isDisabled }: any) => ({
  '& .MuiBadge-badge': {
    backgroundColor: isDisabled ? '#6A7178' : '#D6336C',
    color: isDisabled ? '#6A7178' : '#D6336C',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: isDisabled ? 'none' : 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
})) as BadgeProps & { isDisabled?: boolean };

export const StyledMenu = styled(Menu)(() => ({
  '& .MuiPaper-root': {
    boxShadow: '0px 7px 14px 0px #4145581A',
    borderRadius: '8px',
  },
}));

export const StyledListItemButton = styled(ListItemButton)(() => ({
  alignItems: 'flex-end',
  padding: 0,
  width: '10%',
}));

export const StyledMenuItem = styled(MenuItem)(() => ({
  display: 'flex',
  flexDirection: 'row',
  padding: 0,
}));

export const StyledListItemIcon = styled(ListItemIcon)(() => ({
  ml: '2px',
  display: 'flex',
}));

export const StyledListItemText: any = styled(ListItemText)(
  ({ theme, isDisabled }: any) => ({
    display: 'flex',
    color: isDisabled ? theme.palette.neutral[700] : theme.palette.neutral.main,
    '& > span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  })
) as ListItemTextProps & { isDisabled?: boolean };
