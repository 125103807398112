import { styled } from '@mui/material';

export const StyledMain = styled('main')`
  ${({ theme }) => ({
    height: '100%',
    padding: '32px 80px',
    position: 'relative',
    width: '100%',
    [theme.breakpoints.down('md')]: { padding: '5px' },
  })}
`;
