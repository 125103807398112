import { Grid, Typography } from '@mui/material';
import React from 'react';
import { OrderPacketProps } from './types';
import { StyledBody, StyledBodyContent, StyledHeader } from './styles';
import TextBadge from 'components/atoms/TextBadge';
import { DownloadIcon } from 'components/icons';
import CustomButton from 'components/atoms/Button';
import { useTranslation } from 'react-i18next';

const OrderPacket: React.FC<OrderPacketProps> = (props) => {
  const { status, color, date, commentProps, buttonProps } = props;
  const { t } = useTranslation();

  return (
    <Grid container direction={'column'}>
      <StyledHeader item>
        <TextBadge name={status} color={color} variant="body2" />
      </StyledHeader>
      <StyledBody container direction={'column'}>
        <StyledBodyContent item>
          {' '}
          <Grid item>
            <Typography> {date}</Typography>
          </Grid>
          <Grid item>
            <CustomButton
              onClick={buttonProps.action}
              endIcon={<DownloadIcon />}
              sx={{ background: 'white' }}
            >
              <Typography color="neutral.700" variant="body1">
                {t('global.download')}
              </Typography>
            </CustomButton>
          </Grid>
        </StyledBodyContent>
        {commentProps.visible && (
          <StyledBodyContent item>{commentProps.comment}</StyledBodyContent>
        )}
      </StyledBody>
    </Grid>
  );
};
export default OrderPacket;
