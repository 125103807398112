import * as signalR from '@microsoft/signalr';
import { SIGNAL_R_EVENT } from 'constants/index';

class SignalRConnectionManager {
  private static instance: SignalRConnectionManager | null = null;
  private static userIdCopy: string | null | number = null;
  private static tokenCopy: string | null = null;
  private connection: any | null = null;
  private eventCache: Record<string, any> = {};
  private callbacks = [];

  public static getInstance(): SignalRConnectionManager {
    if (!SignalRConnectionManager.instance) {
      SignalRConnectionManager.instance = new SignalRConnectionManager();
    }
    return SignalRConnectionManager.instance;
  }

  public registerEventConsumer(cb) {
    if (this.eventCache[SIGNAL_R_EVENT.RECEIVED.LOAD_TASKS]) {
      cb(
        SIGNAL_R_EVENT.RECEIVED.LOAD_TASKS,
        this.eventCache[SIGNAL_R_EVENT.RECEIVED.LOAD_TASKS]
      );
    }
    this.callbacks.push(cb);
  }

  public unregisterEventConsumer(cb) {
    this.callbacks = this.callbacks.filter((c) => c !== cb);
  }

  public start() {
    return new Promise((resolve, reject) => {
      this.connection
        ?.start()
        .then((res) => {
          this.dispatchEvent('start', { isSuccess: true, res });
          resolve(res);
        })
        .catch((err) => {
          this.dispatchEvent('start', { isSuccess: false, res: err });
          reject(err);
        });
    });
  }

  public stop() {
    return new Promise((resolve, reject) => {
      this.connection
        ?.stop()
        .then((res) => {
          this.dispatchEvent('stop', { isSuccess: true, res });

          resolve(res);
        })
        .catch((err) => {
          this.dispatchEvent('stop', { isSuccess: false, res: err });
          reject(err);
        });
    });
  }

  public connect(userId?: string | number, token?: string): Promise<any> {
    if (
      (!SignalRConnectionManager.userIdCopy &&
        !SignalRConnectionManager.tokenCopy) ||
      (userId && token)
    ) {
      SignalRConnectionManager.userIdCopy = userId;
      SignalRConnectionManager.tokenCopy = token;
    }

    if (
      !this.connection &&
      SignalRConnectionManager.userIdCopy &&
      SignalRConnectionManager.tokenCopy
    ) {
      return new Promise((resolve, reject) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}todohub`;
        this.connection = new signalR.HubConnectionBuilder()
          .withUrl(url, {
            accessTokenFactory: () => SignalRConnectionManager.tokenCopy,
          })
          .withAutomaticReconnect()
          .build();

        this.start()
          .then(() => {
            this.connection.send(SIGNAL_R_EVENT.SEND.GET_TASKS);
            this.startClientEventListeners();
            resolve(this.connection);
          })
          .catch(reject);
      });
    }

    return Promise.resolve(this.connection);
  }

  private startClientEventListeners = () => {
    SignalRConnectionManager?.instance?.connection?.on(
      SIGNAL_R_EVENT.RECEIVED.LOAD_TASKS,
      (data) => {
        this.dispatchEvent(SIGNAL_R_EVENT.RECEIVED.LOAD_TASKS, data);
        this.eventCache[SIGNAL_R_EVENT.RECEIVED.LOAD_TASKS] = data;
      }
    );
  };

  private dispatchEvent(eventKey, data) {
    this.callbacks.forEach((cb) => cb(eventKey, data));
  }

  public sendNewMessage(eventKey, data, onSendMessageCallBack) {
    if (data === null || data === undefined) {
      SignalRConnectionManager?.instance?.connection
        ?.send(eventKey)
        .then((result) => {
          if (onSendMessageCallBack) onSendMessageCallBack(result);
        });
    } else
      SignalRConnectionManager?.instance?.connection
        ?.send(eventKey, data)
        .then((result) => {
          if (onSendMessageCallBack) onSendMessageCallBack(result);
        });
  }

  public get getConnectionInstance(): any | null {
    return this.connection;
  }
}

export const signalRConnectionManager = SignalRConnectionManager.getInstance();
