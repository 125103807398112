import { useReducer } from 'react';
import { PDFDocument } from 'pdf-lib';
import { useLazyGetDocByIdQuery } from 'api/docs';
import { preparePdfMimeType } from 'utils';
import { FILE_TYPE } from 'constants/index';

type Action = {
  type: 'isLoading' | 'isError' | 'res' | 'fileDataUri' | 'isSuccess';
  payload: any;
};

type State = {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  error: any;
  res: any;
  fileDataUri: string;
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'isLoading':
      return {
        ...state,
        isLoading: action.payload,
        isError: false,
      };
    case 'isSuccess':
      return {
        ...state,
        isSuccess: action.payload,
      };
    case 'isError':
      return {
        ...state,
        isError: true,
        error: action.payload,
      };

    case 'res':
      return {
        ...state,
        isError: false,
        res: action.payload,
      };
    case 'fileDataUri':
      return {
        ...state,
        isError: false,
        fileDataUri: action.payload,
        res: action.payload,
        isSuccess: true,
      };
    default:
      return state;
  }
};

const usePdfDoc = () => {
  const [{ isLoading, error, res, isError, fileDataUri, isSuccess }, setState] =
    useReducer(reducer, {
      isSuccess: false,
      isLoading: false,
      error: null,
      res: null,
      isError: false,
      fileDataUri: '',
    });
  const [getFile] = useLazyGetDocByIdQuery();

  const fetchFiles = async (urls): Promise<{ data: string }> => {
    setState({ type: 'isLoading', payload: true });
    setState({ type: 'isSuccess', payload: false });
    try {
      const pdfPromises = urls.map(async (url) => await getFile({ url }));

      const pdfBytesArray = await Promise.all(pdfPromises);
      const mergedPdf = await PDFDocument.create();
      for (const pdfBytes of pdfBytesArray) {
        const pdf = await PDFDocument.load(pdfBytes?.data);
        const copiedPages = await mergedPdf.copyPages(
          pdf,
          pdf.getPageIndices()
        );
        copiedPages.forEach((page) => {
          mergedPdf.addPage(page);
        });
      }

      const mergedPdfBytes = await mergedPdf.saveAsBase64();

      setState({
        type: 'fileDataUri',
        payload: preparePdfMimeType(mergedPdfBytes),
      });
      setState({ type: 'isLoading', payload: false });
      return {
        data: preparePdfMimeType(mergedPdfBytes),
      };
    } catch (e) {
      setState({ type: 'isLoading', payload: false });
      setState({ type: 'isError', payload: true });
      return { data: e };
    }
  };

  const convertBase64ToBlob = (data = undefined) => {
    if (data === undefined) data = res;

    if (data) {
      const byteCharacters = atob(data.split(',')[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      return new Blob([byteArray], { type: FILE_TYPE.PDF });
    }
  };

  const downloadFileHelper = (data, fileName) => {
    const byteCharacters = atob(data.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    const blob = new Blob([byteArray], { type: FILE_TYPE.PDF });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    setState({ type: 'isSuccess', payload: false });
  };

  const handleDownload = (data = null, fileName = 'file') => {
    if (data) downloadFileHelper(data, fileName);
    else if (res) downloadFileHelper(res, fileName);
  };

  const openPdfInNewTabHelper = (data) => {
    const byteCharacters = atob(data?.split(',')[1]);
    const byteNumbers = new Array(byteCharacters?.length);
    for (let i = 0; i < byteCharacters?.length; i++) {
      byteNumbers[i] = byteCharacters?.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: FILE_TYPE.PDF });

    const url = URL.createObjectURL(blob);

    const newTab = window.open(url, '_blank');

    newTab?.addEventListener('beforeunload', () => {
      URL.revokeObjectURL(url);
    });
    setState({ type: 'isSuccess', payload: false });
  };

  function openPdfInNewTab(data = null) {
    if (data) openPdfInNewTabHelper(data);
    else if (fileDataUri) {
      openPdfInNewTabHelper(fileDataUri);
    }
  }

  return {
    downloadFile: handleDownload,
    isLoading: isLoading,
    isSuccess: isSuccess,
    error: error,
    res: res,
    isError: isError,
    fileDataUri: fileDataUri,
    fetchFiles,
    openPdfInNewTab,
    convertBase64ToBlob,
  };
};

export default usePdfDoc;
