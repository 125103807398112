import PageHeader from 'components/molecules/PageHeader';
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  Grid,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { StyledCard, StyledEllipsisContainer, StyledTableCell } from './styles';
import PdfPreview from 'components/organisms/PdfPreview';
import { useSelector } from 'react-redux';
import { getExtendedIsolates } from 'store/features/isolate/index.selector';

import ExpirationDate from 'components/icons/ExpirationDate';
import {
  preparePdfMimeType,
  calculateDateDifference,
  formatDate,
  truncateText,
} from 'utils';
import RenderByCondition from 'components/atoms/RenderByCondition';
import { useLocation, useNavigate } from 'react-router-dom';
import BackdropLoader from 'components/atoms/BackdropLoader';
import { useTranslation } from 'react-i18next';
import usePdfDoc from 'hooks/useDocMerge';
import {
  ExtensionRequestFormData,
  useCreateIsolateExtensionRequestMutation,
  useLazyIsolateExtensionIsolatesQuery,
} from 'api/isolate';
import { useEffect, useMemo, useState } from 'react';
import useToast from 'hooks/useToast';
import { PAGES, USER_ROLE } from 'constants/index';
import { getUserRole } from 'store/features/auth/index.selector';
import RenderByRole from 'components/atoms/RenderByRole';
import CustomButton from 'components/atoms/Button';
import DocItem from 'components/molecules/DocItem';
import CustomSelect from 'components/atoms/Select';
import dayjs from 'dayjs';

const RowTable = ({ row }) => {
  const currentDate = formatDate(dayjs());
  const expirationDate = formatDate(row?.expirationDate);

  const { days, months } = calculateDateDifference(currentDate, expirationDate);

  return (
    <TableRow>
      <StyledTableCell>
        <StyledEllipsisContainer>
          <Tooltip title={row.id} placement="top-start">
            <Typography>{row.id}</Typography>
          </Tooltip>
        </StyledEllipsisContainer>
      </StyledTableCell>

      <StyledTableCell>
        <StyledEllipsisContainer>
          <Tooltip title={row.name} placement="top-start">
            <Typography>{row.name}</Typography>
          </Tooltip>
        </StyledEllipsisContainer>
      </StyledTableCell>

      <StyledTableCell>
        <Grid container sx={{ cursor: 'pointer' }}>
          <Grid item>
            <Tooltip title={expirationDate} placement="top-start">
              <Typography>{expirationDate}</Typography>
            </Tooltip>
          </Grid>
          <Grid
            item
            container
            flexDirection={'row'}
            alignItems={'center'}
            alignContent={'center'}
            justifyItems={'center'}
            mt={1}
          >
            <Grid item mr={1} mt={'2px'}>
              <ExpirationDate />
            </Grid>
            <Grid item>
              <Tooltip title={`${months}m, ${days}d`} placement="top-start">
                <Typography color={'warning.900'}>
                  {`${months}m, ${days}d`}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </StyledTableCell>
      <StyledTableCell sx={{ maxWidth: 150 }}>
        <Typography color="neutral.900">
          {row.serialNumbers?.map((serial) => (
            <Tooltip title={serial} placement="top-start" key={serial}>
              <StyledEllipsisContainer>
                {truncateText(serial, 5)}
              </StyledEllipsisContainer>
            </Tooltip>
          ))}
        </Typography>
      </StyledTableCell>
    </TableRow>
  );
};

const Table = ({ data }: any) => {
  const { t } = useTranslation();

  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: 'none',
        overflow: 'auto',
        minHeight: { xs: 400, md: 700 },
        maxHeight: { xs: 400, md: 700 },
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography color="neutral.700" fontWeight={600} variant="body2">
              {t('isolates.isolateId')}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="neutral.700" fontWeight={600} variant="body2">
              {t('isolates.isolateName')}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="neutral.700" fontWeight={600} variant="body2">
              {t('isolates.expiration')}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="neutral.700" fontWeight={600} variant="body2">
              {t('isolates.serialNumber')}
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data?.map((row) => (
          <RowTable row={row} key={row.id} />
        ))}
      </TableBody>
    </TableContainer>
  );
};

const Content = ({ children }) => (
  <Grid item container p={{ xs: 4, md: 8 }} spacing={{ xs: 2, md: 5 }}>
    {children}
  </Grid>
);

const useCreateExtensionRequest = () => {
  const [
    createExtensionRequestApi,
    { isLoading: createExtensionRequestIsLoading, data },
  ] = useCreateIsolateExtensionRequestMutation();
  const { convertBase64ToBlob } = usePdfDoc();
  const location = useLocation();
  const userRole = useSelector(getUserRole);
  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    if (data?.success) {
      toast.success(data?.message, {
        duration: 3000,
      });
      const doesAnyHistoryEntryExist = location.key !== 'default';
      if (doesAnyHistoryEntryExist) navigate(-1);
      else navigate(PAGES[userRole].ROOT);
    }
  }, [createExtensionRequestIsLoading]);

  const createExtensionRequest = ({ isolateIds, base64Pdf, vetValue }) => {
    const formData = new FormData() as ExtensionRequestFormData;

    for (const isolateId of isolateIds) {
      formData.append('IsolateIds', isolateId);
    }

    formData.append('File', convertBase64ToBlob(base64Pdf));
    formData.append('VetId', vetValue?.id || '');

    createExtensionRequestApi({ body: formData });
  };

  return {
    createExtensionRequest,
    createExtensionRequestIsLoading,
  };
};

const useCancel = () => {
  const location = useLocation();
  const userRole = useSelector(getUserRole);
  const navigate = useNavigate();

  const onCancel = () => {
    const doesAnyHistoryEntryExist = location.key !== 'default';
    if (doesAnyHistoryEntryExist) navigate(-1);
    else navigate(PAGES[userRole].ROOT);
  };

  return {
    onCancel,
  };
};

const PdfPreviewContainer = ({
  fileDataUri,
  pdfIsLoading,
  isolateIds,
  base64Pdf,
  previewOn,
  setPreviewOn,
  vetValue,
}) => {
  const { t } = useTranslation();
  const { createExtensionRequest, createExtensionRequestIsLoading } =
    useCreateExtensionRequest();
  const { onCancel } = useCancel();

  return (
    <Grid
      component={Paper}
      sx={{
        boxShadow: 'none',
        padding: { md: 2, xs: 0 },
      }}
      item
      xs={12}
      container
    >
      <PdfPreview
        headerTitle={'Preview Letter'}
        buttonActions={[
          {
            id: 1,
            onClick: () => {
              if (previewOn) {
                setPreviewOn(false);
              } else if (!previewOn) {
                onCancel();
              }
            },
            title: t('global.cancelTitle'),
            variant: 'outlined',
            endIcon: <></>,
          },
          {
            id: 2,
            onClick: () => {
              createExtensionRequest({
                isolateIds,
                base64Pdf,
                vetValue,
              });
            },
            title: t('global.send'),
            variant: 'contained',
            endIcon: <></>,
          },
        ]}
        fileDataUri={fileDataUri}
        pdfIsLoading={pdfIsLoading}
      />
      <BackdropLoader open={createExtensionRequestIsLoading} />
    </Grid>
  );
};

const RequestLetterSection = ({
  isolateIds,
  base64Pdf,
  previewOn,
  setPreviewOn,
  data,
  vetValue,
}) => {
  return (
    <>
      {base64Pdf && (
        <PdfPreviewContainer
          fileDataUri={base64Pdf}
          pdfIsLoading={data?.isLoading}
          isolateIds={isolateIds}
          base64Pdf={base64Pdf}
          previewOn={previewOn}
          setPreviewOn={setPreviewOn}
          vetValue={vetValue}
        />
      )}
    </>
  );
};

const ExtensionIsolateList = ({ data }) => {
  const { t } = useTranslation();

  const rejectComment = data?.comment;

  return (
    <Grid flexDirection={'column'} height={'100%'}>
      <RenderByCondition show={[Boolean(rejectComment)]}>
        <Grid>
          <Typography mb={1} color="primary" variant="body1">
            {t('global.comment')}
          </Typography>
          <Divider />
          <Typography my={2} color="error">
            {rejectComment}
          </Typography>
        </Grid>
      </RenderByCondition>
      <Grid
        mt={3}
        height={'100%'}
        sx={{
          minHeight: { xs: 400, md: 700 },
          maxHeight: { xs: 400, md: 700 },
        }}
      >
        <RenderByCondition show={[Boolean(data?.isolateForExtension?.length)]}>
          <Typography color="primary" variant="h5" mb={4}>
            {t('isolates.selectedIsolates', {
              count: data?.isolateForExtension?.length,
            })}
          </Typography>
          <Table data={data?.isolateForExtension} />
        </RenderByCondition>
      </Grid>
    </Grid>
  );
};

interface SelectItem {
  label: string;
  value: string;
  [key: string]: any;
}

interface RequestLetterWithSelectorsProps {
  onSelectChange: (e: any, value: SelectItem) => void;
  vetValue: SelectItem;
  vetsOptions: SelectItem[];
  setPreviewOn: () => void;
  isolateIds: number[];
  base64Pdf?: string;
}

const ButtonGroup = ({ createExtensionRequest, isDisabled }) => {
  const { t } = useTranslation();
  const { onCancel } = useCancel();

  return (
    <Grid item mt={2}>
      <Grid
        container
        alignContent={'flex-end'}
        alignItems={'flex-end'}
        justifyContent={'flex-end'}
        columnGap={2}
      >
        <CustomButton
          variant="outlined"
          onClick={() => {
            onCancel();
          }}
        >
          {t('global.cancelTitle')}
        </CustomButton>
        <CustomButton
          onClick={() => {
            createExtensionRequest();
          }}
          disabled={isDisabled}
        >
          {t('global.send')}
        </CustomButton>
      </Grid>
    </Grid>
  );
};

const SelectComponent = ({
  vetsOptions,
  onSelectChange,
  vetValue,
  loadRequestFile,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Grid
      item
      xs
      border={`solid ${theme.palette.neutral[200]} 2px`}
      borderRadius={2}
      p={{ md: 4, xs: 2 }}
    >
      <Grid container flexDirection={'column'} rowSpacing={2}>
        <Grid item xs={12}>
          <CustomSelect
            label={'Veterinarian'}
            options={vetsOptions}
            onChange={(e, value) => {
              onSelectChange(e, value as SelectItem);
            }}
            name={'vet'}
            value={vetValue}
            placeholder="Select"
          />
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12} mt={2}>
          <DocItem
            title={
              <Typography color="primary" fontWeight={600}>
                {t('isolates.extensionRequestLetter')}
              </Typography>
            }
            bgColor="neutral.100"
            description={''}
            buttonActions={[
              {
                title: 'Preview',
                icon: <></>,
                type: 'button',
                onClick: () => {
                  loadRequestFile();
                },
              },
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const RequestLetterWithSelectors = ({
  vetsOptions,
  onSelectChange,
  vetValue,
  setPreviewOn,
  isolateIds,
  base64Pdf,
}: RequestLetterWithSelectorsProps) => {
  const { t } = useTranslation();
  const { createExtensionRequest, createExtensionRequestIsLoading } =
    useCreateExtensionRequest();

  const loadRequestFile = () => {
    setPreviewOn();
  };

  return (
    <Grid
      container
      flexDirection={'column'}
      rowSpacing={2}
      sx={{
        minHeight: { xs: 'unset', md: 700 },
        maxHeight: { xs: 'unset', md: 700 },
        mt: { xs: 2, md: 0 },
      }}
    >
      <RenderByCondition show={[Boolean(vetsOptions?.length)]}>
        <Grid item xs={12}>
          <Typography
            color="primary"
            fontWeight={600}
            variant="h5"
            mt={3}
            mb={2}
          >
            {t('isolates.extensionRequest')}
          </Typography>
        </Grid>
        <SelectComponent
          vetValue={vetValue}
          loadRequestFile={loadRequestFile}
          vetsOptions={vetsOptions}
          onSelectChange={onSelectChange}
        />
        <ButtonGroup
          createExtensionRequest={() => {
            createExtensionRequest({
              isolateIds,
              base64Pdf,
              vetValue,
            });
          }}
          isDisabled={base64Pdf === '' || !base64Pdf}
        />
      </RenderByCondition>
      <BackdropLoader open={createExtensionRequestIsLoading} />
    </Grid>
  );
};

const RenderRequestLetterSection = ({
  previewOn,
  isolateIds,
  base64Pdf,
  setPreviewOn,
  data,
  vetValue,
}) => {
  const userRole = useSelector(getUserRole);

  if (
    userRole === USER_ROLE.VET ||
    (userRole === USER_ROLE.TM &&
      previewOn &&
      base64Pdf !== '' &&
      base64Pdf !== null)
  ) {
    return (
      <RequestLetterSection
        isolateIds={isolateIds}
        base64Pdf={base64Pdf}
        setPreviewOn={setPreviewOn}
        previewOn={previewOn}
        data={data}
        vetValue={vetValue}
      />
    );
  }

  return null;
};

const IsolateExtensionOrganism = ({ extendIsolatesIsLoading, isolateIds }) => {
  const theme = useTheme();
  const userRole = useSelector(getUserRole);
  const [selectorValue, setSelectorValue] = useState({
    vet: { label: '', value: '' },
  });
  const data = useSelector(getExtendedIsolates);

  const [previewOn, setPreviewOn] = useState(false);
  const [localData, setLocalData] = useState(null);
  const [
    extendIsolates,
    {
      isLoading: isLoadingExtendIsolates,
      isFetching: isFetchingExtendIsolates,
      isSuccess: isSuccessExtendIsolates,
    },
  ] = useLazyIsolateExtensionIsolatesQuery();

  const vetsOptions = useMemo(() => {
    return localData?.vetsOption?.map((vet) => ({
      ...vet,
      label: vet?.userName,
      value: vet?.userName,
    }));
  }, [localData?.vetsOption]);

  useEffect(() => {
    if (!isLoadingExtendIsolates && !isFetchingExtendIsolates) {
      setLocalData(data);
    }
  }, [
    data,
    isLoadingExtendIsolates,
    isFetchingExtendIsolates,
    isSuccessExtendIsolates,
  ]);

  const onSelectChange = (event, value) => {
    const name = event.target.name;

    setSelectorValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (value?.id) {
      extendIsolates({
        isolatesIds: isolateIds,
        vetId: value?.id,
      });
    }
  };

  const pdf = preparePdfMimeType(data?.extensionRequestDoctBase64);

  return (
    <Grid container>
      <PageHeader title={'Isolate Extension'} />
      <StyledCard flexDirection={'column'} item container theme={theme}>
        <Content>
          <Grid container columnGap={8}>
            <Grid item xs>
              <ExtensionIsolateList data={localData} />
            </Grid>
            <Grid item xs>
              <RenderRequestLetterSection
                previewOn={previewOn}
                isolateIds={isolateIds}
                base64Pdf={pdf}
                setPreviewOn={setPreviewOn}
                data={localData}
                vetValue={selectorValue.vet}
              />
              <RenderByRole userRole={userRole} allowedRoles={[USER_ROLE.TM]}>
                <RenderByCondition show={[!previewOn]}>
                  <RequestLetterWithSelectors
                    vetsOptions={vetsOptions}
                    vetValue={selectorValue.vet}
                    onSelectChange={onSelectChange}
                    setPreviewOn={() => {
                      setPreviewOn(true);
                    }}
                    isolateIds={isolateIds}
                    base64Pdf={pdf}
                  />
                </RenderByCondition>
              </RenderByRole>
            </Grid>
          </Grid>
        </Content>
      </StyledCard>
      <BackdropLoader
        open={
          extendIsolatesIsLoading ||
          isLoadingExtendIsolates ||
          isFetchingExtendIsolates
        }
      />
    </Grid>
  );
};

export default IsolateExtensionOrganism;
