import { Box, Typography, styled } from '@mui/material';

export const StyledInputLabel = styled(Box)`
  ${({ theme }) => ({
    color: theme.palette.secondary['400'],
    textAlign: 'center',
    fontSize: '0.75rem',
    fontFamily: 'Noto Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '1.125rem',
    textDecorationLine: 'underline !important',
    textDecorationColor: theme.palette.secondary['400'],
    ':hover': {
      cursor: 'pointer',
    },
  })}
`;

export const StyledAddressesModalViewTitle = styled(Typography)`
  ${({ theme }) => ({
    color: theme.palette.primary.main,
    textAlign: 'center',
    fontWeight: 500,
    lineHeight: '1.375rem',
    margin: '0 1.5rem 0 1rem',
    [theme.breakpoints.down('md')]: {
      margin: '0',
      textAlign: 'start',
      marginBottom: 12,
    },
  })}
`;
