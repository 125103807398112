import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import withLayout from 'layout';
import { DownloadIcon, ViewIcon } from 'components/icons';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import OrderStateDocs, {
  IPacketActionButton,
} from 'components/organisms/OrderStateDocs';
import {
  DocumentStatus,
  DocumentTypeEnum,
  LIGHT_STATUS_COLOR,
  DARK_STATUS_COLOR,
  PAGES,
  FILE_TYPE,
} from 'constants/index';
import UpdateIcon from 'components/icons/UpdateIcon';
import usePdfUpload from 'hooks/useDocUpload';
import {
  useChangeStatusPacketMutation,
  useGenerateDocument113Mutation,
  useGetOrderStateDocsQuery,
  useUploadDocumentMutation,
} from 'api/docs';
import BackdropLoader from 'components/atoms/BackdropLoader';
import usePdfDoc from 'hooks/useDocMerge';
import {
  formatDate,
  isNotEmptyOrWhitespace,
  prepareDownloadFileUrl,
  prepareDownloadProductLicenseTemplateUrl,
} from 'utils';
import useToast from 'hooks/useToast';
import { StateDocument, DocumentType } from './types';
import PageHeader from 'components/molecules/PageHeader';
import { getUserRole } from 'store/features/auth/index.selector';
import { useSelector } from 'react-redux';
import EditPacketPopUp from 'components/organisms/EditPacketPopUp';

const Content = ({ children }) => (
  <Grid item container p={{ xs: 4, md: 8 }} spacing={{ xs: 2, md: 5 }}>
    {children}
  </Grid>
);

const CardHeader = ({ orderId }) => (
  <Grid
    item
    container
    bgcolor={'info.light'}
    alignItems={'center'}
    py={2}
    px={2}
    mb={4}
  >
    <Grid item>
      <Typography variant="h6" color="primary">
        Order Number : {orderId}
      </Typography>
    </Grid>
  </Grid>
);

const CompleteDocument: React.FC = () => {
  const { toast } = useToast();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [packetViewInEditMode, setPacketViewInEditMode] = useState(false);
  const { id: urlOrderId } = useParams<{ id: string }>();
  const orderId = parseInt(urlOrderId, 10) || 0;
  const {
    data: orderStateDocs,
    isSuccess: orderStateDocsIsSuccess,
    isLoading: orderStateDocsIsLoading,
    refetch: refetchOrderStateDocs,
  } = useGetOrderStateDocsQuery(
    { orderId: urlOrderId },
    {
      skip: !urlOrderId,
    }
  );
  const [
    generateDocument113,
    {
      isSuccess: generateFileIsSuccess,
      isError: generateFileIsError,
      isLoading: generateFileIsLoading,
    },
  ] = useGenerateDocument113Mutation();

  const [orderStateValue, setOrderStateValue] = useState({
    data: orderStateDocs?.states[0],
    index: 0,
  });

  const [
    uploadFn,
    { isLoading, isSuccess: uploadFileIsSuccess, isError: uploadFileIsError },
  ] = useUploadDocumentMutation();

  const [
    changeStatusPacketData,
    {
      isLoading: ChangeStatusPacketIsLoading,
      isSuccess: ChangeStatusPacketIsSuccess,
      isError: ChangeStatusPacketIsError,
    },
  ] = useChangeStatusPacketMutation();

  const { handlePdfUpload } = usePdfUpload(uploadFn);

  const {
    fetchFiles,
    openPdfInNewTab,
    downloadFile,
    isLoading: viewPdfIsLoading,
    isError: viewPdfIsError,
  } = usePdfDoc();

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const userRole = useSelector(getUserRole);

  const createStateDocumentPlaceholder = (
    type: DocumentType
  ): StateDocument => ({
    id: null,
    status: DocumentStatus.None,
    document: {
      id: null,
      type: type,
      owner: null,
      fileName: null,
      contentType: null,
      path: null,
      creationDate: null,
      updatedDate: null,
      documentHistory: [],
    },
    comment: null,
    orderId: null,
    documentId: null,
    stateId: null,
  });

  const getStateRequiredDocuments = (
    documentTypes: DocumentType[],
    stateDocumentTypeInformation: { documentTypeId: number }[]
  ): DocumentType[] => {
    const requiredDocsSet = new Set<DocumentType>();

    stateDocumentTypeInformation?.forEach((info) => {
      const matchingType = documentTypes.find(
        (type) => type.id === info.documentTypeId
      );

      if (matchingType) {
        requiredDocsSet.add(matchingType);
      }
    });

    const coverLetterDocumentType = orderStateDocs?.documentTypes?.find(
      (type: DocumentType) => type.code === DocumentTypeEnum.CoverLetter
    );

    if (coverLetterDocumentType) {
      requiredDocsSet.add(coverLetterDocumentType);
    }

    const requiredDocs = Array.from(requiredDocsSet);

    return requiredDocs;
  };

  const mapDocumentsBasedOnType = (
    stateDocumentTypeInformation: { documentTypeId: number }[],
    documentTypes: DocumentType[],
    documents: StateDocument[]
  ): StateDocument[] => {
    const requiredDocuments = getStateRequiredDocuments(
      documentTypes,
      stateDocumentTypeInformation
    );

    const isMatchingDocument = (item, typeId, orderStateValue) => {
      return (
        item?.document?.type?.id === typeId &&
        item?.stateId === orderStateValue?.data?.id &&
        item?.document?.type?.code !== DocumentTypeEnum.PermissionRequest
      );
    };

    const findMatchingStateDocument = (type: DocumentType) => {
      return documents.find((item) =>
        isMatchingDocument(item, type?.id, orderStateValue)
      );
    };

    let mappedDocumentsTypes = requiredDocuments?.map((type) => {
      const matchingStateDocument = findMatchingStateDocument(type);
      return matchingStateDocument || createStateDocumentPlaceholder(type);
    });

    const prescriptionFile = documents.find(
      (item) => item.document.type.code === DocumentTypeEnum.Prescription
    );
    if (prescriptionFile) {
      mappedDocumentsTypes?.push(prescriptionFile);
    }

    const permissionRequestFiles = documents.filter(
      (item) =>
        item.document.type.code === DocumentTypeEnum.PermissionRequest &&
        item.stateId === orderStateValue?.data?.id
    );

    mappedDocumentsTypes = mappedDocumentsTypes.concat(permissionRequestFiles);

    mappedDocumentsTypes?.sort(
      (a, b) => a?.document?.type?.id - b?.document?.type?.id
    );

    return mappedDocumentsTypes;
  };

  const getDefaultPermissions = (item: StateDocument) => {
    const permissionsMap = {
      [DocumentTypeEnum.Packet]: {
        update: false,
        upload: false,
        view: true,
        generate: false,
        regenerate: false,
      },
      [DocumentTypeEnum.RequestLetter]: {
        update: false,
        upload: false,
        view: true,
        generate: false,
        regenerate: false,
      },
      [DocumentTypeEnum.Form113113]: {
        update: false,
        upload: false,
        view: true,
        generate: !item?.document?.path,
        regenerate: item?.document?.path,
      },
      [DocumentTypeEnum.PermissionRequest]: {
        update: false,
        upload: false,
        view: true,
        generate: false,
        regenerate: false,
      },
      [DocumentTypeEnum.ProductLicense]: {
        update: false,
        upload: false,
        view: true,
        generate: false,
        regenerate: false,
      },
      default: {
        update: item?.document?.path,
        upload: !item?.document?.path,
        view: true,
        generate: false,
        regenerate: false,
      },
    };

    return permissionsMap[item?.document?.type?.code] || permissionsMap.default;
  };

  const getDefaultBgColor = (item: StateDocument) => {
    const statusMap = {
      [DocumentStatus.None]:
        item?.document?.type?.code === DocumentTypeEnum.Form113113 ||
        item?.document?.type?.code === DocumentTypeEnum.RequestLetter ||
        item?.document?.type?.code === DocumentTypeEnum.PermissionRequest ||
        item?.document?.type?.code === DocumentTypeEnum.ProductLicense
          ? LIGHT_STATUS_COLOR.SENT
          : LIGHT_STATUS_COLOR.NONE,
      [DocumentStatus.Sent]:
        (item?.document?.type?.code === DocumentTypeEnum.Packet &&
          !packetViewInEditMode) ||
        item?.document?.type?.code === DocumentTypeEnum.CoverLetter ||
        item?.document?.type?.code === DocumentTypeEnum.Generic
          ? LIGHT_STATUS_COLOR.NONE
          : LIGHT_STATUS_COLOR.SENT,
      [DocumentStatus.Denied]: LIGHT_STATUS_COLOR.DENIED,
      [DocumentStatus.Approved]:
        item?.document?.type?.code === DocumentTypeEnum.RequestLetter ||
        item?.document?.type?.code === DocumentTypeEnum.PermissionRequest
          ? LIGHT_STATUS_COLOR.SENT
          : LIGHT_STATUS_COLOR.APPROVED,
      default: LIGHT_STATUS_COLOR.SENT,
    };

    return statusMap[item?.status] || statusMap.default;
  };

  const prepareDocItems = (data: StateDocument[]) => {
    const prepareButtonActions = (item: StateDocument, permission) => {
      return [
        permission?.update && {
          title: t('global.buttonUpdateTitle'),
          type: 'input',
          icon: <UpdateIcon />,
          onClick: (event) => onUploadFile(event, item),
          disabled: !item?.document?.path,
        },
        permission?.upload && {
          title: t('global.buttonUploadTitle'),
          type: 'input',
          icon: <DownloadIcon />,
          onClick: (event) => onUploadFile(event, item),
          disabled: item?.document?.path,
        },
        permission?.generate && {
          title: t('global.buttonGenerateTitle'),
          type: 'button',
          icon: <DownloadIcon />,
          onClick: () => generateDoc113(orderStateValue?.data?.id),
          disabled: item?.document?.path,
        },
        permission?.regenerate && {
          title: t('global.buttonRegenerateTitle'),
          type: 'button',
          icon: <UpdateIcon />,
          onClick: () => generateDoc113(orderStateValue?.data?.id),
          disabled: !item?.document?.path,
        },
        permission?.view && {
          title: t('global.buttonViewTitle'),
          type: 'button',
          icon: <ViewIcon />,
          onClick: (event) => onViewFile(event, item),
          disabled:
            item.document.type.code !== DocumentTypeEnum.ProductLicense
              ? !item?.document?.path
              : false,
        },
      ].filter(Boolean);
    };

    const prepareDescription = (item: StateDocument) =>
      item?.document?.fileName && item?.document?.creationDate
        ? `${item?.document?.fileName} - ${formatDate(
            item?.document?.creationDate
          )}`
        : '';

    return data?.map((item) => {
      const docPermissions = getDefaultPermissions(item);
      const isProductLicense =
        item?.document?.type?.code === DocumentTypeEnum.ProductLicense;
      console.log('item', item?.document?.path);
      return {
        stateId: orderStateValue?.data?.id,
        title: item?.document?.type?.name,
        description: prepareDescription(item),
        bgColor: getDefaultBgColor(item),
        buttonActions: prepareButtonActions(item, docPermissions),
        path: isProductLicense ? 'null' : item?.document?.path,
        id: isProductLicense ? 'null' : item?.documentId,
        code: item?.document?.type?.code,
        status: item?.status,
        comment: item?.comment,
        creationDate: item?.document?.creationDate,
      };
    });
  };

  const allDocsData: any = useMemo(() => {
    if (orderStateDocsIsSuccess && !orderStateDocsIsLoading) {
      const newState = orderStateDocs?.states[orderStateValue.index];
      setOrderStateValue({ data: newState, index: orderStateValue.index });
      const mapDocumentsResult = mapDocumentsBasedOnType(
        orderStateValue?.data?.stateDocumentTypeInformation,
        orderStateDocs?.documentTypes,
        orderStateDocs?.documents
      );

      return prepareDocItems(mapDocumentsResult);
    }
  }, [
    orderStateDocsIsSuccess,
    orderStateDocsIsLoading,
    orderStateDocs,
    orderStateValue?.data,
    packetViewInEditMode,
    changeStatusPacketData,
  ]);

  const stateIndexByUrlStateId = (array: Array<any>, id: string) => {
    const parsedId = parseInt(id, 10);
    if (isNaN(parsedId)) {
      return 0;
    }
    return array.findIndex((item) => item.id === parsedId);
  };

  const goNext = () => {
    navigate({
      pathname: `${PAGES[userRole].REVIEW_DOC}/${urlOrderId}`,
      search: `?stateId=${orderStateValue?.data?.id}`,
    });
  };
  /** */
  const switchToEditMode = (value) => {
    setPacketViewInEditMode(value);
  };

  const states = useMemo(
    () =>
      orderStateDocs?.states?.map((state) => ({
        ...state,
        id: state?.id,
        name: state?.name,
      })),
    [orderStateDocs]
  );

  const onStateChange = (state, index) => {
    switchToEditMode(false);
    setOrderStateValue({ data: state, index: index });
    setSearchParams({ stateId: state?.id }, { replace: true });
  };

  const onUploadFile = (event, doc) => {
    const file = event?.target?.files[0];
    if (file) {
      if (file.type === FILE_TYPE.PDF) {
        const formData = new FormData();

        formData.append('OrderId', urlOrderId);
        formData.append('TypeId', doc?.document?.type?.id?.toString());
        formData.append('File', file);
        formData.append('StateId', orderStateValue?.data?.id?.toString());

        handlePdfUpload(formData);
      } else {
        // Display an error message if the selected file is not a PDF
        toast.error(t('errorMessages.pdfFileTypeWarning'), {});
      }
    }
  };

  const onViewFile = async (event, doc) => {
    let res;
    if (doc?.document?.type?.code === DocumentTypeEnum.ProductLicense) {
      res = await fetchFiles([
        prepareDownloadProductLicenseTemplateUrl(orderId),
      ]);
    } else {
      res = await fetchFiles([prepareDownloadFileUrl(doc?.document?.id)]);
    }

    openPdfInNewTab(res?.data);
  };

  const generateDoc113 = async (stateId: number) => {
    generateDocument113({ orderId: orderId, stateId: stateId });
  };

  const packetData = useMemo(() => {
    if (orderStateDocs) {
      const packetDoc: any = allDocsData?.find(
        (result) => result.code === DocumentTypeEnum.Packet
      );

      const getStatusText = (status) => {
        switch (status) {
          case 0:
            return t('global.statusSent');
          case 1:
            return t('global.statusNotApproved');
          case 2:
            return t('global.statusApproved');
          case 3:
            return undefined;
          default:
            return undefined;
        }
      };

      const getColor = (status) => {
        switch (status) {
          case DocumentStatus.Sent:
            return DARK_STATUS_COLOR.SENT;
          case DocumentStatus.Denied:
            return DARK_STATUS_COLOR.DENIED;
          case DocumentStatus.Approved:
            return DARK_STATUS_COLOR?.APPROVED;
          case DocumentStatus.None:
            return undefined;
          default:
            return undefined;
        }
      };

      return {
        orderDocumentStatus: getStatusText(packetDoc?.status),
        orderDocumentDate: packetDoc?.creationDate,
        orderDocumentPacketComment: packetDoc?.comment,
        orderDocumentStatusColor: getColor(packetDoc?.status),
        status: packetDoc?.status,
        documentId: packetDoc?.id,
      };
    }
  }, [orderStateValue?.data, orderStateDocs]);

  const doesAddressExistForState = (addresses, targetStateId) => {
    return addresses?.some((address) => address.stateId === targetStateId);
  };

  const doesAnyAddressUnderApprovalExistAndPacketStatusApproved = (
    addresses,
    targetStateId,
    packetStatus
  ) => {
    return (
      doesAddressExistForState(addresses, targetStateId) &&
      packetStatus === DocumentStatus.Approved
    );
  };

  const isNewAddressesAdded = useMemo(() => {
    const docsData: any = orderStateDocs;

    const checkNonAdjacentAddresses =
      doesAnyAddressUnderApprovalExistAndPacketStatusApproved(
        docsData?.nonAdjacentAddressesUnderApproval,
        orderStateValue?.data?.id,
        packetData?.status
      );

    const checkShippingAddresses =
      doesAnyAddressUnderApprovalExistAndPacketStatusApproved(
        docsData?.shippingAddressesUnderApproval,
        orderStateValue?.data?.id,
        packetData?.status
      );

    return checkNonAdjacentAddresses || checkShippingAddresses;
  }, [orderStateValue?.data, packetData]);

  const showDownloadAll = useMemo(() => {
    return (
      [
        DocumentStatus.Sent,
        DocumentStatus.Approved,
        DocumentStatus.Denied,
      ].includes(packetData?.status) &&
      !packetViewInEditMode &&
      !isNewAddressesAdded
    );
  }, [packetData, isNewAddressesAdded, packetViewInEditMode]);
  const downloadAll = async () => {
    const res = await fetchFiles([
      prepareDownloadFileUrl(packetData?.documentId),
    ]);

    downloadFile(res?.data);
  };

  const addCoverLetterRequirement = allDocsData?.filter((item) =>
    [
      DocumentTypeEnum.Packet,
      DocumentTypeEnum.RequestLetter,
      DocumentTypeEnum.CoverLetter,
      DocumentTypeEnum.PermissionRequest,
    ].includes(item.code)
  );

  const commonDocsData = () => {
    const docsContainCoverLetter = allDocsData?.some(
      (doc) =>
        [DocumentTypeEnum.CoverLetter].includes(doc.code) &&
        isNotEmptyOrWhitespace(doc.path)
    );

    if (docsContainCoverLetter) {
      return allDocsData?.filter(
        (item) => ![DocumentTypeEnum.Prescription].includes(item.code)
      );
    }

    return initialStateRequirements;
  };

  const initialStateRequirements = allDocsData?.filter(
    (item) =>
      ![
        DocumentTypeEnum.CoverLetter,
        DocumentTypeEnum.Packet,
        DocumentTypeEnum.Prescription,
      ].includes(item.code)
  );

  const filteredPacketDocs = allDocsData?.filter(
    (item) => item.code === DocumentTypeEnum.Packet
  );

  const isNextButtonDisabled = () => {
    if (isNewAddressesAdded) {
      return allDocsData
        ?.filter((item) => ![DocumentTypeEnum.Packet].includes(item.code))
        ?.some((doc) => !doc?.path);
    }

    return allDocsData
      ?.filter(
        (item) =>
          ![DocumentTypeEnum.Packet, DocumentTypeEnum.CoverLetter].includes(
            item.code
          )
      )
      ?.some((doc) => !doc?.path);
  };

  const handleEditStatusClick = () => {
    setIsOpen(true);
  };

  const content = useMemo(() => {
    const packetActionButtons: IPacketActionButton[] = [
      {
        title: t('docs.reSendPacket'),
        onClick: goNext,
        variant: 'contained' as const,
        size: 'large',
        visible: true,
      },
      {
        title: t('global.buttonUpdateTitle'),
        onClick: () => switchToEditMode(true),
        variant: 'outlined' as const,
        size: 'medium',
        visible: !packetViewInEditMode,
      },
      {
        title: t('orderForm.cancel'),
        onClick: () => switchToEditMode(false),
        variant: 'outlined' as const,
        size: 'medium',
        visible: packetViewInEditMode,
      },
    ];
    const newAddressesActionButtons: IPacketActionButton[] = [
      {
        title: t('docs.send'),
        onClick: goNext,
        variant: 'contained' as const,
        size: 'large',
        visible: isNewAddressesAdded,
        disabled: isNextButtonDisabled(),
      },
    ];
    const docsActionButtons: IPacketActionButton[] = [
      {
        title: t('global.nextTitle'),
        onClick: goNext,
        variant: 'contained' as const,
        size: 'medium',
        visible: true,
        disabled: isNextButtonDisabled(),
      },
    ];

    const getOrderStateDocsProps = () => {
      const commonProps = {
        title: t('docs.orderDocumentsTitle'),
        goNext,
        docListData: commonDocsData(),
        orderDocumentStatus: packetData?.orderDocumentStatus,
        orderDocumentDate: formatDate(packetData?.orderDocumentDate),
        orderDocumentPacketComment: packetData?.orderDocumentPacketComment,
        orderDocumentStatusColor: packetData?.orderDocumentStatusColor,
        showPacketStatus: Boolean(packetData?.orderDocumentStatus),
        orderDocumentStates: states,
        orderDocumentStateValue: orderStateValue?.data,
        onOrderDocumentStateChange: onStateChange,
        showDownloadAll: showDownloadAll,
        downloadAllOnClick: () => downloadAll(),
        handleEditStatusClick: () => handleEditStatusClick(),
      };

      if (
        !isNewAddressesAdded &&
        [DocumentStatus.None].includes(packetData?.status) &&
        !packetViewInEditMode
      ) {
        return {
          ...commonProps,
          docListData: initialStateRequirements,
          actionButtons: docsActionButtons,
        };
      }

      if (packetViewInEditMode) {
        return { ...commonProps, actionButtons: packetActionButtons };
      }

      if (isNewAddressesAdded && !packetViewInEditMode) {
        return {
          ...commonProps,
          title: t('docs.newAddressesTitle'),
          actionButtons: newAddressesActionButtons,
          docListData: addCoverLetterRequirement,
        };
      }

      if (
        [
          DocumentStatus.Sent,
          DocumentStatus.Approved,
          DocumentStatus.Denied,
        ].includes(packetData?.status) &&
        !packetViewInEditMode &&
        !isNewAddressesAdded
      ) {
        return {
          ...commonProps,
          docListData: filteredPacketDocs,
          actionButtons: packetActionButtons,
        };
      }

      // Default case
      return commonProps;
    };

    return <OrderStateDocs {...getOrderStateDocsProps()} />;
  }, [orderStateValue?.data, allDocsData]);

  useEffect(() => {
    if (uploadFileIsSuccess) {
      toast.success(t('messages.fileUploadSuccess'), {});
    } else if (uploadFileIsError) {
      toast.error(t('messages.fileUploadFail'), {});
    }
  }, [uploadFileIsSuccess, uploadFileIsError]);

  useEffect(() => {
    if (generateFileIsSuccess) {
      toast.success(t('messages.fileCreateSuccess'), {});
    } else if (generateFileIsError) {
      toast.error(t('messages.fileCreateFail'), {});
    }
  }, [generateFileIsSuccess, generateFileIsError]);

  useEffect(() => {
    if (viewPdfIsError) {
      toast.error(t('messages.viewFileFail'), {});
    }
  }, [viewPdfIsError]);

  useEffect(() => {
    if (ChangeStatusPacketIsSuccess) {
      toast.success(t('messages.changeStatusPacketSuccess'), {});
      refetchOrderStateDocs();
    } else if (ChangeStatusPacketIsError) {
      toast.error(t('messages.changeStatusPacketFail'), {});
    }
  }, [ChangeStatusPacketIsSuccess, ChangeStatusPacketIsError]);

  useEffect(() => {
    if (orderStateDocs?.states.length > 0) {
      const stateIdParam = searchParams.get('stateId');
      if (stateIdParam !== 'null') {
        setSearchParams({ stateId: stateIdParam }, { replace: true });
        setOrderStateValue({
          data: orderStateDocs?.states[
            stateIndexByUrlStateId(orderStateDocs?.states, stateIdParam)
          ],
          index: stateIndexByUrlStateId(orderStateDocs?.states, stateIdParam),
        });
      } else {
        setSearchParams(
          { stateId: orderStateDocs?.states[0]?.id },
          { replace: true }
        );
      }
    }
  }, [orderStateDocs]);

  return (
    <Grid container>
      <PageHeader title={t('docs.completeDoc')} />
      <Grid item container bgcolor={'white'} direction={'column'}>
        <CardHeader orderId={urlOrderId} />
        <Content>{content}</Content>
      </Grid>
      <EditPacketPopUp
        orderId={urlOrderId}
        stateId={searchParams.get('stateId')}
        documentId={packetData?.documentId}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        changeStatusPacketData={changeStatusPacketData}
      />
      <BackdropLoader
        open={
          isLoading ||
          viewPdfIsLoading ||
          orderStateDocsIsLoading ||
          generateFileIsLoading ||
          ChangeStatusPacketIsLoading
        }
      />
    </Grid>
  );
};

export default withLayout(CompleteDocument);
