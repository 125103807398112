import { api } from 'config/api';

export interface ResultError {
  errorCode: string;
  errorMessage: string;
}

export interface VetAdditionalRecipientForManipulation {
  name: string;
  email: string;
  notifiedForReorder: boolean;
  notifiedForOrderStatusChange: boolean;
}
export interface VetAdditionalRecipient
  extends VetAdditionalRecipientForManipulation {
  id: number;
  vetId: number;
}

export interface VetAdditionalRecipientResponse {
  success: boolean;
  message: string;
  errors: ResultError[];
  data: VetAdditionalRecipient[];
}

const profileApi = api.injectEndpoints({
  endpoints: (build) => ({
    getVetAdditionalRecipients: build.query<
      VetAdditionalRecipientResponse,
      void
    >({
      query: () => ({
        url: '/Vet/additionalRecipients',
        method: 'GET',
      }),
      providesTags: ['VetAdditionalRecipients'],
    }),
    createVetAdditionalRecipient: build.mutation<
      VetAdditionalRecipientResponse,
      VetAdditionalRecipientForManipulation
    >({
      query: (body) => ({
        url: '/Vet/additionalRecipient',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['VetAdditionalRecipients'],
    }),
    updateVetAdditionalRecipient: build.mutation<
      VetAdditionalRecipientResponse,
      { id: number; body: VetAdditionalRecipientForManipulation }
    >({
      query: ({ id, body }) => ({
        url: `/Vet/additionalRecipient/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['VetAdditionalRecipients'],
    }),
    deleteVetAdditionalRecipient: build.mutation<
      VetAdditionalRecipientResponse,
      number
    >({
      query: (id) => ({
        url: `/Vet/additionalRecipient/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['VetAdditionalRecipients'],
    }),
  }),
});

export const {
  useLazyGetVetAdditionalRecipientsQuery,
  useCreateVetAdditionalRecipientMutation,
  useUpdateVetAdditionalRecipientMutation,
  useDeleteVetAdditionalRecipientMutation,
} = profileApi;
export default profileApi;
