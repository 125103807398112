import withLayout from 'layout';
import { StyledCard } from './styles';
import { Grid, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import theme from 'theme';
import AdditionalRecipients from 'components/organisms/AdditionalRecipients';
import { useTranslation } from 'react-i18next';
import PageHeader from 'components/molecules/PageHeader';
import { useNavigate } from 'react-router-dom';
import { PAGES } from 'constants/index';
import { useSelector } from 'react-redux';
import { getUserRole } from 'store/features/auth/index.selector';

const AccountSettings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userRole = useSelector(getUserRole);

  const [activeTabId, setActiveTabId] = useState(0);
  const handleOnActiveTabClick = (event, newValue) => {
    setActiveTabId(newValue);
  };

  const tabs = [
    {
      id: 0,
      label: t('profile.configureEmails'),
      tabComponent: <AdditionalRecipients />,
    },
  ];

  return (
    <>
      <PageHeader
        title={t('profileMenuItems.settings')}
        onClickBack={() => {
          navigate(PAGES[userRole]?.ROOT);
        }}
      />

      <StyledCard
        item
        bgcolor={theme.palette.neutral[50]}
        width={{ md: '100%', xs: '98%' }}
        marginX={{ xs: 'auto' }}
        marginTop={{ xs: 3 }}
      >
        <Grid container>
          <Grid item md={3} xs={12}>
            <Tabs
              value={activeTabId}
              onChange={handleOnActiveTabClick}
              indicatorColor="primary"
              textColor="primary"
              variant="standard"
              aria-label="scrollable auto tabs example"
              allowScrollButtonsMobile
              orientation={'vertical'}
              TabIndicatorProps={{
                style: {
                  left: 0,
                },
              }}
              style={{ maxWidth: '200px', margin: '15px' }}
            >
              {tabs.map((tab, index) => (
                <Tab key={tab.label} label={tab.label} value={index} />
              ))}
            </Tabs>
          </Grid>
          <Grid
            item
            md={9}
            xs={12}
            sx={{ textAlign: 'center', marginTop: '10px' }}
          >
            {tabs[activeTabId].tabComponent}
          </Grid>
        </Grid>
      </StyledCard>
    </>
  );
};

export default withLayout(AccountSettings);
