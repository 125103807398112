export enum AddressTypes {
  Main = 0,
  Shipping = 1,
  Billing = 2,
  Proximity = 3,
}

export enum DocumentStatus {
  Sent = 0,
  Denied = 1,
  Approved = 2,
  None = 3,
}
export enum ContactTypes {
  Main = 0,
  CC = 1,
}
export enum ContactTypeCodes {
  Main = 'main',
  CC = 'cc',
}

export enum IsolateStatus {
  Disposed,
  Received,
  Cancelled,
  InProgress,
  Completed,
}

export enum SortDirection {
  Ascending,
  Descending,
}

export enum OrderSource {
  Application,
  JDEPortal,
  OldDataSource,
}
