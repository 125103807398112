import { CustomCircleProps } from './types';
import { StyledBox } from './styles';
import React from 'react';

const CustomCircle: React.FC<CustomCircleProps> = ({
  value = '',
  size = 'medium',
  color = 'primary',
  variant = 'contained',
  ...props
}) => {
  return (
    <StyledBox size={size} color={color} variant={variant} {...props}>
      {value}
    </StyledBox>
  );
};

export default CustomCircle;
