import { SVGProps } from 'react';

const LetterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="24"
    fill="none"
    viewBox="0 0 22 24"
    {...props}
  >
    <path
      fill="#29306B"
      d="M21.5 16.5V2.25C21.5 1.031 20.469 0 19.25 0h-15A3.731 3.731 0 00.5 3.75v16.5C.5 22.36 2.14 24 4.25 24h16.125a1.11 1.11 0 001.125-1.125 1.14 1.14 0 00-1.125-1.125H20v-3.094c.844-.328 1.5-1.172 1.5-2.156zm-3.75 5.25H4.25a1.48 1.48 0 01-1.5-1.5c0-.797.656-1.5 1.5-1.5h13.5v3zm1.5-5.25h-15c-.563 0-1.078.14-1.5.328V3.75c0-.797.656-1.5 1.5-1.5h15V16.5zm-11.625-9h8.25A1.11 1.11 0 0017 6.375a1.14 1.14 0 00-1.125-1.125h-8.25A1.11 1.11 0 006.5 6.375c0 .656.469 1.125 1.125 1.125zm0 3.75h8.25A1.11 1.11 0 0017 10.125 1.14 1.14 0 0015.875 9h-8.25A1.11 1.11 0 006.5 10.125c0 .656.469 1.125 1.125 1.125z"
    ></path>
  </svg>
);

export default LetterIcon;
