import { SVGProps } from 'react';

const ProfileType = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 23"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill="#29306B"
      d="M1.724 10.165v-.433c0-.227.107-.44.273-.54a7.26 7.26 0 013.727-1.027c.02 0 .033 0 .053.007.067-.467.2-.913.394-1.32a4.896 4.896 0 00-.447-.02c-1.613 0-3.12.447-4.407 1.213-.586.347-.926 1-.926 1.687v1.767h6.173c-.28-.4-.5-.854-.647-1.334H1.724zM5.724 6.165a2.666 2.666 0 100-5.333 2.666 2.666 0 100 5.333zm0-4c.733 0 1.333.6 1.333 1.334 0 .733-.6 1.333-1.333 1.333s-1.333-.6-1.333-1.333c0-.734.6-1.334 1.333-1.334zM12.89 8.832c0-.147-.02-.28-.04-.42l.76-.673-.666-1.154-.967.327a2.43 2.43 0 00-.72-.42l-.2-.993H9.724l-.2.993c-.267.1-.507.24-.72.42l-.967-.327-.666 1.154.76.673c-.02.14-.04.273-.04.42 0 .147.02.28.04.42l-.76.673.666 1.154.967-.327c.213.18.453.32.72.42l.2.993h1.333l.2-.993c.267-.1.507-.24.72-.42l.967.327.667-1.154-.76-.673c.02-.14.04-.273.04-.42zm-2.5 1.333c-.733 0-1.333-.6-1.333-1.333s.6-1.333 1.334-1.333c.733 0 1.333.6 1.333 1.333s-.6 1.333-1.333 1.333z"
    />
  </svg>
);

export default ProfileType;
