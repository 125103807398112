import { Checkbox, Divider, Grid } from '@mui/material';
import {
  StyledConfigGrid,
  StyledForm,
  StyledMenu,
  StyledTemplateTitle,
} from './styles';
import { MoreOptionsIcon } from 'components/icons';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import MenuItem from '@mui/material/MenuItem';
import CustomDialog from 'components/molecules/Dialog';
import CustomInput from 'components/atoms/Input';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import CustomButton from 'components/atoms/Button';
import RenderByCondition from 'components/atoms/RenderByCondition';
import { DocumentTypeEnum } from 'constants/index';
import { useDeleteDocumentMutation, useEditDocumentMutation } from 'api/docs';
import BackdropLoader from 'components/atoms/BackdropLoader';

interface EditNamePopUpProps {
  open: boolean;
  onClose: () => void;
  document: {
    name: string;
    id: number;
  };
  documentTypes: any;
}

const EditNamePopUp = (props: EditNamePopUpProps) => {
  const { t } = useTranslation();
  const { open, onClose, document, documentTypes } = props;
  const [editDocument, { isLoading: editDocumentIsLoading, isSuccess }] =
    useEditDocumentMutation();

  const getUniqueNames = () => {
    const uniqueNames = documentTypes?.map((document) => document?.name);
    return uniqueNames;
  };

  const validationSchema = Yup.object({
    text: Yup.string()
      .required(t('errorMessages.fieldRequired'))
      .notOneOf(getUniqueNames() ?? [], t('errorCodes.nameMustBeUnique')),
  });

  const initialValues = {
    text: document?.name,
  };

  const handleSubmit = (values) => {
    editDocument({ docId: document.id, name: values.text });
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const isValid = formik.isValid;
  const dirty = formik.dirty;

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  return (
    <CustomDialog
      open={open}
      handleClose={onClose}
      modalTitle={'Edit document name'}
      maxWidth="lg"
    >
      <StyledForm onSubmit={formik.handleSubmit}>
        <Grid container direction="column" rowGap={2}>
          <Grid item xs={12}>
            <CustomInput
              sx={{ width: '100%' }}
              name="text"
              label={'Name'}
              placeholder={'Name'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.text}
              error={Boolean(formik.touched.text && formik.errors.text)}
              errorMessage={formik.errors.text}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              columnGap={2}
            >
              <Grid item>
                <CustomButton
                  size="medium"
                  variant="outlined"
                  onClick={onClose}
                >
                  {t('global.cancel')}
                </CustomButton>
              </Grid>
              <Grid item columnSpacing={1}>
                <CustomButton
                  size="medium"
                  type="submit"
                  loading={editDocumentIsLoading}
                  disabled={!(isValid && dirty) || editDocumentIsLoading}
                >
                  {t('global.save')}
                </CustomButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </StyledForm>
    </CustomDialog>
  );
};

function DocActionMenu({ actions, onClose, open, anchorEl }) {
  return (
    <StyledMenu
      anchorEl={anchorEl}
      onClose={onClose}
      open={open}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '10px',
          width: '200px',
        },
      }}
    >
      {actions.map((item) => (
        <MenuItem key={item} onClick={() => item.onClick(item)}>
          {item?.title}
        </MenuItem>
      ))}
    </StyledMenu>
  );
}

const RequirementIndicator = ({ document, onChange, documentTypes }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentSelect, setCurrentSelected] = useState(null);
  const [deleteDocument, { isLoading: deleteDocumentIsLoading }] =
    useDeleteDocumentMutation();

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteDoc = () => {
    deleteDocument({ docId: currentSelect?.id });
    handleClose();
  };

  return (
    <StyledConfigGrid key={document?.id} container direction="row">
      <Grid item xs={10}>
        <StyledTemplateTitle>{document?.name}</StyledTemplateTitle>
      </Grid>
      <Grid item xs={1}>
        <Grid container justifyContent={'center'}>
          <Grid item>
            <Checkbox
              onChange={() => onChange(document)}
              checked={document?.isRequired}
            />
          </Grid>
        </Grid>
      </Grid>
      <RenderByCondition show={[document?.code === DocumentTypeEnum.Generic]}>
        <Grid item xs={1}>
          <Grid
            container
            justifyContent={'center'}
            onClick={(event) => {
              handleClick(event);
              setCurrentSelected(document);
            }}
            sx={{ cursor: 'pointer' }}
          >
            <Grid item>
              <MoreOptionsIcon />
            </Grid>
          </Grid>
        </Grid>
      </RenderByCondition>
      <DocActionMenu
        actions={[
          {
            title: 'Edit name',
            onClick: () => {
              setIsOpen(true);
            },
          },
          {
            title: 'Delete document',
            onClick: () => {
              deleteDoc();
            },
          },
        ]}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
      />
      <EditNamePopUp
        open={isOpen}
        onClose={() => setIsOpen(false)}
        document={currentSelect}
        documentTypes={documentTypes}
      />
      {document?.information?.length > 0 && <Divider sx={{ width: '95%' }} />}
      {document?.information?.map((information) => (
        <>
          <Grid item xs={10} sx={{ mt: '1rem' }}>
            <StyledTemplateTitle>{information?.text}</StyledTemplateTitle>
          </Grid>
          <Grid item xs={1}>
            <Grid container justifyContent={'center'}>
              <Grid item>
                <Checkbox
                  onChange={() => onChange(information)}
                  checked={information?.isRequired}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}></Grid>
        </>
      ))}
      <BackdropLoader open={deleteDocumentIsLoading} />
    </StyledConfigGrid>
  );
};

export default RequirementIndicator;
