import { Theme } from '@mui/material';
import * as React from 'react';
import { SVGProps } from 'react';

const SortArrowDown = (
  props: SVGProps<SVGSVGElement> & { show?: boolean; theme: Theme }
) => (
  <svg
    aria-hidden="true"
    className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall MuiDataGrid-sortIcon css-ptiqhd-MuiSvgIcon-root"
    data-testid="ArrowDownwardIcon"
    viewBox="0 0 24 24"
    style={{
      opacity: props?.show ? 1 : 0,
      fill: props?.theme?.palette?.primary?.main,
    }}
    {...props}
  >
    <path d="m20 12-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z" />
  </svg>
);
export default SortArrowDown;
