import { useGetPageContentQuery } from 'api/policies';
import BackdropLoader from 'components/atoms/BackdropLoader';
import { PolicyPages } from 'constants/index';
import withLayout from 'layout';
import DOMPurify from 'dompurify';

const TermOfService = () => {
  const { data, isLoading } = useGetPageContentQuery({
    searchKey: PolicyPages.TermsOfService,
  });

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(data?.data?.value),
        }}
      ></div>
      <BackdropLoader open={isLoading} />
    </>
  );
};

export default withLayout(TermOfService);
