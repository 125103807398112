import { Grid, Typography, useTheme } from '@mui/material';
import CustomButton from 'components/atoms/Button';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';

const CardHeader = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Grid
      item
      xs={12}
      sx={{ height: 44, backgroundColor: theme.palette.info.light }}
      justifyContent={'center'}
      container
      alignItems={'center'}
    >
      <Typography
        variant="body1"
        color={'primary'}
        fontSize={{ md: '1.8rem', xs: '1.2rem' }}
        fontWeight={500}
      >
        {t('errorPage.title')}
      </Typography>
    </Grid>
  );
};

const CardContent = ({ title, description, titleFontSizeMd = '150px' }) => {
  const { t } = useTranslation();
  const { logout } = useAuth({
    method: 'msal',
  });

  const handleLogout = async () => {
    logout();
  };
  const titleFontSizeXs = `calc(${titleFontSizeMd} / 2)`;

  return (
    <Grid item xs={12}>
      <Grid
        container
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        mb={'6%'}
        p={2}
      >
        <Grid item mb={6}>
          <Typography
            variant="h1"
            color="primary"
            fontWeight={800}
            fontSize={{ md: titleFontSizeMd, xs: titleFontSizeXs }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item mb={3}>
          <Typography
            variant="body1"
            color="primary"
            fontWeight={400}
            fontSize={{ md: '1.4rem', xs: '1.2rem' }}
            maxWidth={{ md: 800, xs: 400 }}
            textAlign={'center'}
          >
            {description}
          </Typography>
        </Grid>
        <Grid item>
          <CustomButton
            variant="contained"
            color="primary"
            onClick={() => {
              handleLogout();
            }}
          >
            {t('errorPage.goToLoginPage')}
          </CustomButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

interface NotAuthorizedUIProps {
  title: string;
  description: string;
  titleFontSizeMd?: string;
  dataTestId?: string;
}

const NotAuthorizedUI: React.FC<NotAuthorizedUIProps> = ({
  title,
  description,
  titleFontSizeMd = '150px',
  dataTestId,
}) => {
  const theme = useTheme();

  return (
    <Grid
      container
      justifyContent="center"
      height={'100vh'}
      width={'100%'}
      data-testid={dataTestId}
    >
      <Grid
        item
        bgcolor={theme.palette.neutral[50]}
        width={{ md: '100%', xs: '98%' }}
        marginX={{ xs: 'auto' }}
        marginTop={{ xs: 3 }}
      >
        <Grid container height={'100%'}>
          <CardHeader />
          <CardContent
            title={title}
            description={description}
            titleFontSizeMd={titleFontSizeMd}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NotAuthorizedUI;
