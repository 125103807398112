import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { PAGES, USER_ROLE, NESTED_PAGES } from 'constants/index';
import { useEffect } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { useAuth } from 'hooks/useAuth';
import { useSelector } from 'react-redux';
import { getApiErrorStateCode } from 'store/features/error/index.selector';
import IsolateDetails from 'pages//Shared/IsolateDetails';

import IsolateExtension from 'pages/User/IsolateExtension';
import IsolateInventory from 'pages/User/IsolateInventory';
import Orders from 'pages/User/Orders';
import AdminOrders from 'pages/Admin/AdminOrders';
import IsolateExtensionRejectRequest from 'pages/User/IsolateExtensionRejectRequest';
import IsolatesExtensionApproval from 'pages/Admin/AdminIsolateApproval';
import IsolatesShareApproval from 'pages/Admin/AdminShareIsolateApproval';
import NotFound from 'pages/Shared/NotFound';
import Dashboard from 'pages/User/Dashboard';
import OrderForm from 'pages/User/OrderForm';
import ReOrderForm from 'pages/User/ReOrderForm';
import EditOrderForm from 'pages/User/EditOrderForm';
import OrderDetails from 'pages/User/OrderDetails';
import ManageAddresses from 'pages/User/ManageAddresses';
import ErrorPage from 'pages/Shared/ErrorPage';
import SystemDown from 'pages/Shared/SystemDown';
import AdminDashboard from 'pages/Admin/AdminDashboard';
import AdminOrderDetails from 'pages/Admin/AdminOrderDetails';
import CompleteDocument from 'pages/User/CompleteDocument';
import AccountSettings from 'pages/User/AccountSettings';
import StateInformation from 'pages/Admin/DocumentConfiguration';
import ShareRequest from 'pages/Shared/ShareRequest';
import ShareRequestDecline from 'pages/Shared/ShareRequestDecline';
import ReviewDocument from 'pages/User/ReviewDocument';
import LegalNotice from 'pages/Shared/LegalNotice';
import PrivacyPolicy from 'pages/Shared/PrivacyPolicy';
import TermsOfService from 'pages/Shared/TermsOfService';
import TermOfSale from 'pages/Shared/TermOfSale';
import NotAuthorizedUI from 'components/organisms/NotAuthorizedUI';
import { useTranslation } from 'react-i18next';

export const ELEMENTS = {
  [USER_ROLE.VET]: {
    ROOT: <Dashboard />,
    DASHBOARD: <Dashboard />,
    ORDER_FORM: <OrderForm />,
    REORDER_FORM: <ReOrderForm />,
    EDIT_FORM: <EditOrderForm />,
    ORDER_DETAILS: <OrderDetails />,
    MANAGE_ADDRESSES: <ManageAddresses />,
    ISOLATE_DETAILS: <IsolateDetails />,
    ACCOUNT_SETTINGS: <AccountSettings />,
    ISOLATE_EXTENSION: <IsolateExtension />,
    SHARE_REQUEST: <ShareRequest />,
    SHARE_REQUEST_DECLINE: <ShareRequestDecline />,
    ISOLATE_EXTENSION_REJECT_REQUEST: <IsolateExtensionRejectRequest />,
    ISOLATE_INVENTORY: <IsolateInventory />,
    ORDERS: <Orders />,
    INTERNAL_SERVER_ERROR: <ErrorPage />,
    SYSTEM_DOWN: <SystemDown />,
    NOT_FOUND: <NotFound />,
    LEGAL_NOTICE: <LegalNotice />,
    PRIVACY_POLICY: <PrivacyPolicy />,
    TERMS_OF_SERVICE: <TermsOfService />,
    TERMS_OF_SALE: <TermOfSale />,
  },
  [USER_ROLE.TM]: {
    ROOT: <AdminDashboard />,
    DASHBOARD: <AdminDashboard />,
    ORDER_FORM: <OrderForm />,
    REORDER_FORM: <ReOrderForm />,
    EDIT_FORM: <EditOrderForm />,
    ORDER_DETAILS: <AdminOrderDetails />,
    MANAGE_ADDRESSES: <ManageAddresses />,
    ORDERS: <AdminOrders />,
    ISOLATE_DETAILS: <IsolateDetails />,
    ISOLATE_EXTENSION: <IsolateExtension />,
    ISOLATE_INVENTORY: <IsolateInventory />,
    ISOLATE_EXTENSION_REJECT_REQUEST: <IsolateExtensionRejectRequest />,
    SHARE_REQUEST: <ShareRequest />,
    SHARE_REQUEST_DECLINE: <ShareRequestDecline />,
    INTERNAL_SERVER_ERROR: <ErrorPage />,
    SYSTEM_DOWN: <SystemDown />,
    NOT_FOUND: <NotFound />,
    LEGAL_NOTICE: <LegalNotice />,
    PRIVACY_POLICY: <PrivacyPolicy />,
    TERMS_OF_SERVICE: <TermsOfService />,
    TERMS_OF_SALE: <TermOfSale />,
  },
  [USER_ROLE.REGULATORY]: {
    ROOT: <AdminDashboard />,
    DASHBOARD: <AdminDashboard />,
    ORDERS: <AdminOrders />,
    ORDER_DETAILS: <AdminOrderDetails />,
    DOCUMENT_CONFIGURATION: <StateInformation />,
    COMPLETE_DOC: <CompleteDocument />,
    REVIEW_DOC: <ReviewDocument />,
    ISOLATE_DETAILS: <IsolateDetails />,
    ISOLATE_INVENTORY: <IsolateInventory />,
    ISOLATE_EXTENSION_APPROVAL: <IsolatesExtensionApproval />,
    ISOLATE_SHARE_APPROVAL: <IsolatesShareApproval />,
    SHARE_REQUEST_DECLINE: <ShareRequestDecline />,
    SYSTEM_DOWN: <SystemDown />,
    INTERNAL_SERVER_ERROR: <ErrorPage />,
    NOT_FOUND: <NotFound />,
    LEGAL_NOTICE: <LegalNotice />,
    PRIVACY_POLICY: <PrivacyPolicy />,
    TERMS_OF_SERVICE: <TermsOfService />,
    TERMS_OF_SALE: <TermOfSale />,
  },
  [USER_ROLE.REGULATORYMANAGER]: {
    ROOT: <AdminDashboard />,
    DASHBOARD: <AdminDashboard />,
    ORDERS: <AdminOrders />,
    ORDER_DETAILS: <AdminOrderDetails />,
    DOCUMENT_CONFIGURATION: <StateInformation />,
    COMPLETE_DOC: <CompleteDocument />,
    REVIEW_DOC: <ReviewDocument />,
    ISOLATE_DETAILS: <IsolateDetails />,
    ISOLATE_INVENTORY: <IsolateInventory />,
    ISOLATE_EXTENSION_APPROVAL: <IsolatesExtensionApproval />,
    ISOLATE_SHARE_APPROVAL: <IsolatesShareApproval />,
    SYSTEM_DOWN: <SystemDown />,
    INTERNAL_SERVER_ERROR: <ErrorPage />,
    NOT_FOUND: <NotFound />,
    LEGAL_NOTICE: <LegalNotice />,
    PRIVACY_POLICY: <PrivacyPolicy />,
    TERMS_OF_SERVICE: <TermsOfService />,
    TERMS_OF_SALE: <TermOfSale />,
  },
  [USER_ROLE.CSS]: {
    ROOT: <AdminDashboard />,
    DASHBOARD: <AdminDashboard />,
    ORDERS: <AdminOrders />,
    ORDER_DETAILS: <AdminOrderDetails />,
    ISOLATE_DETAILS: <IsolateDetails />,
    ISOLATE_INVENTORY: <IsolateInventory />,
    ISOLATE_EXTENSION_APPROVAL: <IsolatesExtensionApproval />,
    ISOLATE_SHARE_APPROVAL: <IsolatesShareApproval />,
    SYSTEM_DOWN: <SystemDown />,
    INTERNAL_SERVER_ERROR: <ErrorPage />,
    NOT_FOUND: <NotFound />,
    LEGAL_NOTICE: <LegalNotice />,
    PRIVACY_POLICY: <PrivacyPolicy />,
    TERMS_OF_SERVICE: <TermsOfService />,
    TERMS_OF_SALE: <TermOfSale />,
  },
} as const;

const PrivatePages = (userRole) => {
  return [
    {
      errorElement: <NotFound />,
    },
    {
      path: PAGES[userRole].ROOT,
      element: ELEMENTS[userRole].ROOT,
      children: [
        {
          path: PAGES[userRole].ROOT,
          search: '?searchKey=text&page=number&pageSize=number',
          element: ELEMENTS[userRole].ORDERS,
        },
        {
          path: NESTED_PAGES[userRole].ISOLATE_INVENTORY,
          search:
            '?customerId=id&farmId=id&pageNumber=number&pageSize=number&extended=boolean&searchKey=text&withoutExtensionOrShare=boolean&shared=boolean',
          element: ELEMENTS[userRole].ISOLATE_INVENTORY,
        },
        {
          path: NESTED_PAGES[userRole].DOCUMENT_CONFIGURATION,
          element: ELEMENTS[userRole].DOCUMENT_CONFIGURATION,
        },
      ],
    },
    {
      path: PAGES[userRole].DASHBOARD,
      element: ELEMENTS[userRole].DASHBOARD,
      children: [
        {
          path: PAGES[userRole].DASHBOARD,
          search: '?searchKey=text&page=number&pageSize=number',
          element: ELEMENTS[userRole].ORDERS,
        },
        {
          path: NESTED_PAGES[userRole].ISOLATE_INVENTORY,
          search:
            '?customerId=id&farmId=id&pageNumber=number&pageSize=number&extended=boolean&searchKey=text&withoutExtensionOrShare=boolean&shared=boolean',
          element: ELEMENTS[userRole].ISOLATE_INVENTORY,
        },
        {
          path: NESTED_PAGES[userRole].DOCUMENT_CONFIGURATION,
          element: ELEMENTS[userRole].DOCUMENT_CONFIGURATION,
        },
      ],
    },
    {
      path: PAGES[userRole].ORDER_FORM,
      element: ELEMENTS[userRole].ORDER_FORM,
    },
    {
      path: `${PAGES[userRole].ORDER_DETAILS}/:id`,
      element: ELEMENTS[userRole].ORDER_DETAILS,
    },
    {
      path: `${PAGES[userRole].REORDER_FORM}/:id`,
      element: ELEMENTS[userRole].REORDER_FORM,
    },
    {
      path: `${PAGES[userRole].EDIT_FORM}/:id`,
      element: ELEMENTS[userRole].EDIT_FORM,
    },
    {
      path: `${PAGES[userRole].ADMIN_DOCS}`,
      element: ELEMENTS[userRole].ADMIN_DOCS,
    },
    {
      path: `${PAGES[userRole].COMPLETE_DOC}/:id`,
      search: '?stateId=id',
      element: ELEMENTS[userRole].COMPLETE_DOC,
    },
    {
      path: `${PAGES[userRole].REVIEW_DOC}/:id`,
      search: '?stateId=stateId',
      element: ELEMENTS[userRole].REVIEW_DOC,
    },
    {
      path: `${PAGES[userRole].MANAGE_ADDRESSES}/:id`,
      element: ELEMENTS[userRole].MANAGE_ADDRESSES,
    },
    {
      path: `${PAGES[userRole].INTERNAL_SERVER_ERROR}`,
      element: ELEMENTS[userRole].INTERNAL_SERVER_ERROR,
    },
    {
      path: `${PAGES[userRole].ISOLATE_DETAILS}/:id`,
      element: ELEMENTS[userRole].ISOLATE_DETAILS,
    },
    {
      path: `${PAGES[userRole].ISOLATE_EXTENSION}`,
      search: '?isolate_ids=ids',
      element: ELEMENTS[userRole].ISOLATE_EXTENSION,
    },
    {
      path: `${PAGES[userRole].ISOLATE_EXTENSION}/:id`,
      element: ELEMENTS[userRole].ISOLATE_EXTENSION_REJECT_REQUEST,
    },
    {
      path: `${PAGES[userRole].ISOLATE_EXTENSION_APPROVAL}/:id`,
      element: ELEMENTS[userRole].ISOLATE_EXTENSION_APPROVAL,
    },
    {
      path: `${PAGES[userRole].ISOLATE_SHARE_APPROVAL}/:id`,
      element: ELEMENTS[userRole].ISOLATE_SHARE_APPROVAL,
    },
    {
      path: `${PAGES[userRole].ACCOUNT_SETTINGS}`,
      element: ELEMENTS[userRole].ACCOUNT_SETTINGS,
    },
    {
      path: `${PAGES[userRole].TEST_INTERNAL_SERVER_ERROR}`,
      element: ELEMENTS[userRole].TEST_INTERNAL_SERVER_ERROR,
    },
    {
      path: `${PAGES[userRole].SHARE_REQUEST}/:id`,
      element: ELEMENTS[userRole].SHARE_REQUEST,
    },
    {
      path: `${PAGES[userRole].SHARE_REQUEST_DECLINE}/:id`,
      element: ELEMENTS[userRole].SHARE_REQUEST_DECLINE,
    },
    {
      path: `${PAGES[userRole].NOT_FOUND}`,
      element: ELEMENTS[userRole].NOT_FOUND,
    },
    {
      path: `${PAGES[userRole].SYSTEM_DOWN}`,
      element: ELEMENTS[userRole].SYSTEM_DOWN,
    },
    {
      path: `${PAGES[userRole].LEGAL_NOTICE}`,
      element: ELEMENTS[userRole].LEGAL_NOTICE,
    },
    {
      path: `${PAGES[userRole].PRIVACY_POLICY}`,
      element: ELEMENTS[userRole].PRIVACY_POLICY,
    },
    {
      path: `${PAGES[userRole].TERMS_OF_SERVICE}`,
      element: ELEMENTS[userRole].TERMS_OF_SERVICE,
    },
    {
      path: `${PAGES[userRole].TERMS_OF_SALE}`,
      element: ELEMENTS[userRole].TERMS_OF_SALE,
    },
  ];
};

const ProtectedRouteContainer = ({ userRole = null, isAuthorized = true }) => {
  const error = useSelector(getApiErrorStateCode);
  const router = createBrowserRouter(PrivatePages(userRole));
  const path = window.location.pathname;
  if (isAuthorized && ['/', '/dashboard', '/admin/dashboard'].includes(path)) {
    const search = window.location.search;

    router.navigate(PAGES[userRole].ROOT + search);
  }

  useEffect(() => {
    if (error?.status === 500) {
      router.navigate(PAGES[userRole].INTERNAL_SERVER_ERROR);
    } else if (error?.status === 503) {
      router.navigate(PAGES[userRole].SYSTEM_DOWN);
    } else if (error?.status === 404) {
      router.navigate(PAGES[userRole].NOT_FOUND);
    }
  }, [error?.status, router]);

  return <RouterProvider router={router} />;
};

const RoutesComponent = () => {
  const { userRole, getUserIsFetching, isAuthorized } = useAuth({
    method: 'msal',
  });
  const { t } = useTranslation();

  if (!isAuthorized) {
    return (
      <NotAuthorizedUI
        dataTestId={'401-notAuthorized-page'}
        title={'401'}
        description={t('errorPage.notAuthorizedDescription')}
      />
    );
  }

  if (getUserIsFetching)
    return (
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color={'primary'} />
        </Backdrop>
      </div>
    );

  if (userRole && !getUserIsFetching) {
    return <ProtectedRouteContainer userRole={userRole} />;
  }
  return <></>;
};

export default RoutesComponent;
