import { Grid } from '@mui/material';
import CustomButton from 'components/atoms/Button';
import { StyledAddressesModalViewTitle, StyledInputLabel } from './styles';
import { AddressFormHeaderProps } from './types';
import AddAddressModal from '../../AddAddress';
import { useState } from 'react';
import { AddressTypes } from 'enums';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getIsUserTypeVet } from 'store/features/orderForm/index.selectors';
import { getDataVisibility, getEnvironmentSettings } from 'utils';
import { getNewOrderAddresses } from 'store/features/addressesManager/index.selectors';
import RenderByCondition from 'components/atoms/RenderByCondition';
import SearchBox from 'components/atoms/SearchBox';
import Search from 'components/icons/Search';

const { BUTTON_VISIBILITY_ADD_SHIPPING_ADDRESS, FARM_VISIBILITY } =
  getEnvironmentSettings();

const AddressFormHeader: React.FC<AddressFormHeaderProps> = ({
  subTitle,
  onSelectAllRows,
  selectedRows,
  editMode,
  addressType,
  modePreviewSelectCount,
  modePreviewTotalCount,
  onSelectDone,
  addressData,
  showSelectAll = false,
  isOrderForm = false,
  custmerId,
  setInputSearchValue,
  inputSearchValue,
}) => {
  const [open, setOpen] = useState(false);
  const isVet = useSelector(getIsUserTypeVet);
  const { t } = useTranslation();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const newOrderAddresses = useSelector(getNewOrderAddresses);

  const handleSelectDone = () => {
    const data = selectedRows;

    const ids = Array.from(data);

    const finalData = [];

    const newShippingObject = newOrderAddresses?.map((address) => ({
      stateId: address?.stateId,
      addressId: address?.addressId,
    }));

    ids?.forEach((id) => {
      finalData.push(addressData?.find((address: any) => address.id === id));
    });

    const totalCount = addressData?.length;

    const selectCount = finalData?.length;

    onSelectDone({
      data: finalData,
      shippingAddressesByState: newShippingObject,
      totalCount,
      selectCount,
      addressType,
    });
  };

  const AddAddressButton = () => {
    const addAddressByVet =
      isVet &&
      !BUTTON_VISIBILITY_ADD_SHIPPING_ADDRESS &&
      addressType === AddressTypes.Shipping;

    if (addAddressByVet) return null;

    return (
      <>
        {editMode && (
          <RenderByCondition show={[FARM_VISIBILITY]}>
            <Grid item>
              <CustomButton
                variant="contained"
                color="primary"
                onClick={() => handleClickOpen()}
              >
                {t('orderForm.addAddress')}
              </CustomButton>
            </Grid>
          </RenderByCondition>
        )}
      </>
    );
  };

  const { ADDRESSES_BY_STATE_VISIBILITY } = getDataVisibility();

  return (
    <>
      <Grid container spacing={2}>
        {editMode && (
          <Grid item xs={10} md={12}>
            <Grid
              container
              justifyContent={{ md: 'flex-end', xs: 'flex-start' }}
            >
              <Grid item>
                <CustomButton
                  variant="contained"
                  color="primary"
                  onClick={handleSelectDone}
                >
                  {t('orderForm.continueToOrder')}
                </CustomButton>
              </Grid>
            </Grid>
          </Grid>
        )}
        <RenderByCondition
          show={[isOrderForm, !(addressType === AddressTypes.Shipping)]}
        >
          <Grid item xs={12}>
            <SearchBox
              size="medium"
              placeholder={t('global.searchPlaceHolder')}
              startIcon={<Search />}
              onChange={(e) =>
                setInputSearchValue && setInputSearchValue(e.target.value)
              }
              value={inputSearchValue}
            />
          </Grid>
        </RenderByCondition>

        {(!isOrderForm || !ADDRESSES_BY_STATE_VISIBILITY) && (
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              padding={{ md: '0px 1rem' }}
            >
              <Grid
                item
                sx={{ display: 'flex' }}
                flexDirection={{ md: 'row', xs: 'column' }}
                alignItems={'flex-start'}
              >
                {editMode && showSelectAll && (
                  <StyledInputLabel
                    onClick={() => {
                      onSelectAllRows();
                    }}
                    mb={{ xs: 2, md: 0 }}
                  >
                    <span>{t('orderForm.selectAll')}</span>
                  </StyledInputLabel>
                )}
                {editMode && (
                  <StyledAddressesModalViewTitle variant="h5">
                    {subTitle}
                  </StyledAddressesModalViewTitle>
                )}
              </Grid>
              {!editMode && (
                <Grid item sx={{ display: 'flex' }}>
                  <StyledAddressesModalViewTitle>
                    {subTitle}
                    <>
                      &nbsp;(
                      {modePreviewSelectCount}/{modePreviewTotalCount} selected
                      )
                    </>
                  </StyledAddressesModalViewTitle>
                </Grid>
              )}
              <AddAddressButton />
            </Grid>
          </Grid>
        )}
      </Grid>
      <AddAddressModal
        open={open}
        setOpen={setOpen}
        addressType={addressType}
        custmerId={custmerId}
      ></AddAddressModal>
    </>
  );
};

export default AddressFormHeader;
