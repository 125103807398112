import { Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import { StyledAppBar, StyledTabs, StyledTab } from './styles';
import { ScrollableTabsButtonProps } from './types';
import theme from 'theme';
import { Outlet, useLinkClickHandler, useLocation } from 'react-router-dom';

const Link = React.forwardRef(({ to = '', ...rest }: any) => {
  const handleClick = useLinkClickHandler(to, { replace: true });

  return (
    <StyledTab
      {...rest}
      onClick={(event) => {
        handleClick(event as any);
      }}
    />
  );
});

Link.displayName = 'CustomLink';

const TabsNavigation: React.FC<ScrollableTabsButtonProps> = ({
  tabs,
  activeTab,
  setActiveTab,
  canSwitchTab = true,
  orientation = 'horizontal',
}) => {
  const [localActiveTab, setLocalActiveTab] = useState(0);
  const location = useLocation();

  const handleChange = (event, newValue) => {
    if (!canSwitchTab) return;
    if (setActiveTab) {
      setActiveTab(newValue);
    } else {
      setLocalActiveTab(newValue);
    }
  };

  React.useEffect(() => {
    const currentPath = location.pathname;

    const activeTabIndex = tabs.findIndex((tab) => currentPath === tab?.path);

    setLocalActiveTab(activeTabIndex < 0 ? 0 : activeTabIndex);
  }, [location]);

  const value = typeof activeTab === 'number' ? activeTab : localActiveTab;

  return (
    <Grid container>
      <Grid item xs={12} sx={{ mr: 0, ml: 0 }}>
        <StyledAppBar
          position="static"
          sx={{
            backgroundColor: theme.palette.neutral[50],
            boxShadow: 0,
            flexDirection: 'row',
            alignItems: 'center',
            textAlign: 'center',
            [theme.breakpoints.up('sm')]: {
              width: '100%',
            },
          }}
        >
          <Box overflow="hidden">
            <StyledTabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="standard"
              aria-label="scrollable auto tabs example"
              allowScrollButtonsMobile
              sx={{ mr: 6 }}
              orientation={orientation}
            >
              {tabs.map((tab, index) => (
                <Link
                  key={tab?.label}
                  label={tab.label}
                  value={index}
                  to={tab?.path + tab?.search || ''}
                />
              ))}
            </StyledTabs>
          </Box>
        </StyledAppBar>
      </Grid>
      <Grid item xs={12}>
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default TabsNavigation;
