import { Grid, Typography, useTheme } from '@mui/material';
import CustomButton from 'components/atoms/Button';
import withLayout from 'layout';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserAccount,
  getUserRole,
} from 'store/features/auth/index.selector';
import { capitalizeFirstLetter } from 'utils';

import { useLocation, useNavigate } from 'react-router-dom';
import { NESTED_PAGES, PAGES, USER_ROLE } from 'constants/index';
import TabsNavigation from 'components/molecules/TabsNavigation';

import { resetAddressManagementAll } from 'store/features/addressesManager/index.slice';

const Dashboard: React.FC = () => {
  const user = useSelector(getUserAccount);
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const userRole = useSelector(getUserRole);
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams, setSearchParams] = useState({
    [PAGES[userRole].DASHBOARD]: '',
    [`${PAGES[userRole].DASHBOARD}/${NESTED_PAGES[userRole].ISOLATE_INVENTORY}`]:
      '',
  });

  const navigateToOrderForm = () => {
    dispatch(resetAddressManagementAll());
    navigate(PAGES[userRole].ORDER_FORM);
  };

  useEffect(() => {
    if (location) {
      setSearchParams((prev) => ({
        ...prev,
        [location.pathname]: location.search,
      }));
    }
  }, [location]);

  const getOrderPageLabel = () => {
    const text =
      t('dashboard_tabs.titleTabOneNavigation')?.split(' ')?.[1] || '';

    switch (userRole) {
      case USER_ROLE.TM:
        return t('dashboard_tabs.titleTabOneNavigation');
      case USER_ROLE.VET:
        return t('dashboard_tabs.titleTabOneNavigation');
      case USER_ROLE.REGULATORY:
        return text;
      case USER_ROLE.REGULATORYMANAGER:
        return text;
      case USER_ROLE.CSS:
        return text;
    }
  };

  const tabs = useMemo(() => {
    return [
      {
        label: getOrderPageLabel(),
        path: `${PAGES[userRole].DASHBOARD}`,
        search: searchParams?.[PAGES[userRole].DASHBOARD],
      },
      {
        label: t('dashboard_tabs.titleTabTwoNavigation'),
        path: `${PAGES[userRole].DASHBOARD}/${NESTED_PAGES[userRole].ISOLATE_INVENTORY}`,
        search:
          searchParams?.[
            `${PAGES[userRole].DASHBOARD}/${NESTED_PAGES[userRole].ISOLATE_INVENTORY}`
          ],
      },
    ];
  }, [location, t]);

  return (
    <Grid container>
      <Grid item xs={12} container direction={'row'} mt={{ xs: 4, md: 0 }}>
        <Grid item xs direction="row" spacing={8}>
          <Typography variant="h4" sx={{ color: theme.palette.primary[500] }}>
            {t('global.greeting', {
              username: capitalizeFirstLetter(user?.userName),
            })}
          </Typography>
        </Grid>
        <Grid item>
          <CustomButton onClick={navigateToOrderForm}>
            {t('orderForm.titleOrder')}
          </CustomButton>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ my: 5 }}>
        <TabsNavigation tabs={tabs} />
      </Grid>
    </Grid>
  );
};

export default withLayout(Dashboard);
