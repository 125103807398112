import * as React from 'react';
import { SVGProps } from 'react';

const SmallRectangleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1"
    height="37"
    fill="none"
    viewBox="0 0 1 37"
    {...props}
  >
    <path
      fill="#CED4DA"
      d="M0 0H1V37H0z"
      transform="matrix(-1 0 0 1 1 0)"
    ></path>
  </svg>
);
export default SmallRectangleIcon;
