import { SVGProps } from 'react';

const ExpirationDate = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#C08A10"
      d="M14.665 6.665V12h-1.333V6.665h1.333Zm-1.333 6.667v1.333h1.333v-1.333h-1.333Zm-1.333-1.807a5.327 5.327 0 0 1-4 1.807 5.34 5.34 0 0 1-5.334-5.333A5.34 5.34 0 0 1 8 2.665v6l5.033-5.033a6.644 6.644 0 0 0-5.033-2.3 6.67 6.67 0 0 0-6.667 6.667 6.67 6.67 0 0 0 10.667 5.333v-1.807Z"
    />
  </svg>
);
export default ExpirationDate;
