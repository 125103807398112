import { api } from 'config/api';

export const appReleaseApi = api.injectEndpoints({
  endpoints: (build) => ({
    getReleaseNumber: build.query<void, void>({
      query: () => 'Application/release-number',
    }),
    getException: build.query<void, void>({
      query: () => 'Application/exception',
    }),
  }),
});

export const { useGetReleaseNumberQuery, useGetExceptionQuery } = appReleaseApi;
