import { useEffect, useMemo, useState } from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material';
import EditPenIcon from 'components/icons/EditIcon';
import { useFormik } from 'formik';
import {
  StyledConfirmationForm,
  StyledForm,
  StyledTableContainer,
  StyledTableHeadCell,
  StyledTableHeadCellText,
} from './styles';
import { useTranslation } from 'react-i18next';
import CustomButton from 'components/atoms/Button';
import CustomDialog from 'components/molecules/Dialog';
import * as Yup from 'yup';
import CustomInput from 'components/atoms/Input';
import { Contact, useDeleteContactMutation } from 'api/docs';
import HeaderWithButton from 'components/molecules/HeaderWithButton';
import { getStateFormContacts } from 'store/features/stateConfigForm/index.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { setFormContacts } from 'store/features/stateConfigForm/index.slice';
import { ContactTypeCodes, ContactTypes } from 'enums';
import theme from 'theme';
import CustomRadioButton from 'components/atoms/RadioButton';
import { getUserRole } from 'store/features/auth/index.selector';
import { USER_ROLE } from 'constants/index';
import { DeleteIcon } from 'components/icons';

function ConfirmationPopup({ open, onClose, handleDelete, loading }) {
  return (
    <CustomDialog
      open={open}
      handleClose={onClose}
      modalTitle={'Confirmation'}
      maxWidth="lg"
    >
      <StyledConfirmationForm sx={{ minHeight: 150 }}>
        <Grid container direction="column" rowGap={4}>
          <Typography>Are you sure you want to delete this contact?</Typography>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            columnGap={2}
          >
            <Grid item>
              <CustomButton size="medium" variant="outlined" onClick={onClose}>
                Cancel
              </CustomButton>
            </Grid>
            <Grid item>
              <CustomButton
                size="medium"
                onClick={handleDelete}
                loading={loading}
              >
                Delete
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </StyledConfirmationForm>
    </CustomDialog>
  );
}

function Contacts({ animalGroupId }) {
  const { t } = useTranslation();
  const isSMup = useMediaQuery(theme.breakpoints.up('sm'));
  const dispatch = useDispatch();
  const contacts = useSelector(getStateFormContacts);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState<Contact>(null);
  const userRole = useSelector(getUserRole);
  const [isConfirmationPopupOpen, setConfirmationPopupOpen] = useState(false);
  const [currentContact, setCurrentContact] = useState(null);
  const [deleteContact, { isLoading: isDeleteContactLoading }] =
    useDeleteContactMutation();

  const transformContactType = (value) => {
    if (value === ContactTypeCodes.Main || value === ContactTypes.Main)
      return ContactTypeCodes.Main;
    else if (value === ContactTypeCodes.CC || value === ContactTypes.CC)
      return ContactTypeCodes.CC;
    return '';
  };

  const handleDelete = (event) => {
    event.preventDefault();

    if (!currentContact?.isDraft) {
      deleteContact({ contactId: currentContact.id });
    }

    const newContacts = contacts.filter(
      (item) => item.id !== currentContact.id
    );

    dispatch(setFormContacts(newContacts));

    setConfirmationPopupOpen(false);
  };

  const handleEditClick = (contact) => {
    setSelectedContact(contact);
    setIsPopupOpen(true);
  };

  const handleAddClick = () => {
    formik.resetForm({
      values: {
        name: '',
        role: '',
        type: '',
        email: '',
        animalGroupId: animalGroupId,
      },
    });
    setSelectedContact(null);
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };
  const handleSubmit = (values) => {
    if (selectedContact) {
      // Update the existing contact
      const updatedContacts = contacts?.map((contact) => {
        if (contact.id === selectedContact.id) {
          return { ...contact, ...values };
        } else {
          return contact;
        }
      });
      dispatch(setFormContacts(updatedContacts));
    } else {
      //Create a new contact with the provided values and set its status as draft until the saveConfiguration is submitted to the server.
      const newContact = { id: contacts.length + 1, ...values, isDraft: true };
      dispatch(setFormContacts([...contacts, newContact]));
    }
    handleClosePopup();
  };

  const columns = [
    { key: 'name', label: 'orderForm.name' },
    { key: 'role', label: 'orderForm.role' },
    { key: 'type', label: 'orderForm.type' },
    { key: 'email', label: 'orderForm.email' },
    { key: 'action', label: 'Action' },
  ];

  const validationSchema = Yup.object({
    name: Yup.string().required(t('errorMessages.fieldRequired')),
    role: Yup.string().required(t('errorMessages.fieldRequired')),
    type: Yup.string()
      .required(t('errorMessages.fieldRequired'))
      .oneOf(['main', 'cc'], t('errorMessages.invalidTypeValue')),
    email: Yup.string()
      .email(t('errorMessages.invalidEmailFormat'))
      .required(t('errorMessages.fieldRequired')),
  });

  const initialValues = useMemo(() => {
    return {
      name: selectedContact ? selectedContact.name : '',
      role: selectedContact ? selectedContact.role : '',
      type: selectedContact ? selectedContact.type : '',
      email: selectedContact ? selectedContact.email : '',
      animalGroupId: animalGroupId,
    };
  }, [selectedContact]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const isValid = formik.isValid;
  const dirty = formik.dirty;

  useEffect(() => {
    formik.resetForm({ values: initialValues });
  }, [selectedContact]);

  function checkUserRole(userRole) {
    return userRole === USER_ROLE.REGULATORY;
  }

  return (
    <>
      <Grid item mb={2}>
        <HeaderWithButton
          title="Contact"
          buttonProps={{
            isHide: checkUserRole(userRole),
            title: 'Add contact',
            action: () => handleAddClick(),
          }}
        />
      </Grid>
      <StyledTableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <StyledTableHeadCell key={column.key} sx={{ paddingLeft: 0 }}>
                  <StyledTableHeadCellText variant="body2">
                    {t(column.label)}
                  </StyledTableHeadCellText>
                </StyledTableHeadCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {contacts
              ?.filter((contact) => contact?.animalGroupId == animalGroupId)
              ?.map((contact) => (
                <TableRow key={contact.id}>
                  <TableCell>{contact.name}</TableCell>
                  <TableCell>{contact.role}</TableCell>
                  <TableCell>{transformContactType(contact.type)}</TableCell>
                  <TableCell>{contact.email}</TableCell>
                  <TableCell>
                    <EditPenIcon
                      onClick={() =>
                        handleEditClick({
                          ...contact,
                          type: transformContactType(contact.type),
                        })
                      }
                      style={{ cursor: 'pointer', marginRight: '8px' }}
                    />
                    <DeleteIcon
                      onClick={() => {
                        setCurrentContact(contact);
                        setConfirmationPopupOpen(true);
                      }}
                      data-testid="edit-contact"
                      style={{ cursor: 'pointer' }}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
      <CustomDialog
        open={isPopupOpen}
        handleClose={handleClosePopup}
        modalTitle={
          selectedContact
            ? t('orderForm.editContact')
            : t('orderForm.addNewContact')
        }
      >
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid
            container
            direction="column"
            columnSpacing={2}
            sx={{ width: isSMup ? '100%' : '55%' }}
          >
            <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
              <CustomInput
                sx={{ width: '100%' }}
                name="name"
                label={t('orderForm.name')}
                placeholder="Name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                error={Boolean(formik.touched.name && formik.errors.name)}
                errorMessage={formik.errors.name}
              />
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
              <CustomInput
                sx={{ width: '100%' }}
                name="role"
                label={t('orderForm.role')}
                placeholder="Role"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.role}
                error={Boolean(formik.touched.role && formik.errors.role)}
                errorMessage={formik.errors.role}
              />
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: '2px' }}>
              <Typography>{t('orderForm.type')}</Typography>
            </Grid>
            <Grid
              container
              ml={2}
              xs={12}
              sx={{ marginBottom: '1rem' }}
              direction={'row'}
            >
              <Grid item>
                <CustomRadioButton
                  label="main"
                  checked={formik.values.type == ContactTypeCodes.Main}
                  onChange={() => formik.setFieldValue('type', 'main')}
                  onBlur={formik.handleBlur}
                  value={formik.values.type}
                  error={Boolean(formik.touched.type && formik.errors.type)}
                  errorMessage={formik.errors.type}
                />
              </Grid>
              <Grid item flexDirection={'row'}>
                <CustomRadioButton
                  label="cc"
                  checked={formik.values.type === ContactTypeCodes.CC}
                  onChange={() => formik.setFieldValue('type', 'cc')}
                  onBlur={formik.handleBlur}
                  value={formik.values.type}
                  error={Boolean(formik.touched.type && formik.errors.type)}
                  errorMessage={formik.errors.type}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
              <CustomInput
                sx={{ width: '100%' }}
                name="email"
                label="Email"
                placeholder="Email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                error={Boolean(formik.touched.email && formik.errors.email)}
                errorMessage={formik.errors.email}
              />
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              marginTop="1rem"
            >
              <Grid item marginRight="0.25rem">
                <CustomButton
                  size="medium"
                  variant="outlined"
                  onClick={handleClosePopup}
                >
                  {t('orderForm.cancel')}
                </CustomButton>
              </Grid>
              <Grid item columnSpacing={1}>
                <CustomButton
                  size="medium"
                  type="submit"
                  disabled={!(isValid && dirty)}
                >
                  {t('orderForm.save')}
                </CustomButton>
              </Grid>
            </Grid>
          </Grid>
        </StyledForm>
      </CustomDialog>
      <ConfirmationPopup
        open={isConfirmationPopupOpen}
        onClose={() => setConfirmationPopupOpen(false)}
        handleDelete={handleDelete}
        loading={isDeleteContactLoading}
      />
    </>
  );
}

export default Contacts;
