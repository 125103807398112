import { Box, Typography, styled } from '@mui/material';
import CustomButton from 'components/atoms/Button';

export const StyledTitleComment = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral[700],
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '21px',
}));

export const StyledDescriptionsComment = styled(Box)(({ theme }) => ({
  color: '#6f767b',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '18px',
  padding: '1rem 0 1rem 0',
  [theme.breakpoints.down('md')]: {
    overflowX: 'auto',
    maxWidth: '100%',
    padding: '1rem 1rem 1rem 1rem',
  },
}));

export const StyledButton = styled(CustomButton)`
  border-color: #f8f9fa;
  color: #6a7178;
  padding: 0 0.5rem 0 0.5rem;
  height: 2rem;
  width: auto;
  left: 30%;
  ${({ theme }) => {
    return {
      [theme.breakpoints.down('md')]: {
        padding: '1rem 1rem 1 1rem',
      },
    };
  }};
`;

export const StyledBox = styled(Box)`
  border: solid 1px #ced4da;
  padding: var(--2, 8px);
  border-radius: 4px;
  margin: 1rem 0 0 0;
`;
