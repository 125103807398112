import { api } from 'config/api';
import { UserRole } from 'types';

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query<
      { role: UserRole; id: number; userName: string },
      void
    >({
      query: () => {
        return { url: 'User/authenticatedUser' };
      },
    }),
  }),
});

export const { useLazyGetUserQuery } = authApi;
