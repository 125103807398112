import React, { useEffect, useMemo, useState } from 'react';
import CustomDialog from 'components/molecules/Dialog';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AddressModalProps } from './types';
import AddressFormContent from '../AddressForms/Main';
import { AddressTypes } from 'enums';
import { getFarmData } from 'store/features/orderForm/index.selectors';
import AddressFormContentViewer from '../AddressForms/MainDisplay';
import { getDataVisibility } from 'utils';

const AddressModal: React.FC<AddressModalProps> = ({
  isOpen,
  addressData,
  addressType,
  editMode,
  onClose,
  viewOnly,
  onSelectDone,
  modePreviewTotalCount,
  modePreviewSelectCount,
  selectType = 'multiple',
  selectionData,
  onCheckChange,
  statesData,
  setStatesData,
  orderAddresses,
  setOrderAddresses,
  addressesByState,
  setAddressesByState,
  isShippingAddresse,
  activeTab = 0,

  CurrentAddressData,
  value,
  onValueChange,
  onStateDataChange,

  selectedStateId,
  setSelectedStateId,

  isOrderDetails = false,
  addressesByStateMapper,
  custmerId,
  isFarmsDisplayed,
  reorderFarmIds,
}) => {
  const { t } = useTranslation();
  const farmData = useSelector(getFarmData);
  const { nonAdjacentRequired: isNonAdjacentRequired } = farmData || {};
  const [, setNewAddresses] = useState(() => addressData);

  useEffect(() => {
    setNewAddresses(addressData);
  }, [addressData]);

  const [transformedData, setTransformedData] = useState([]);
  const [transformedShippingData, setTransformedShippingData] = useState([]);

  const transformData = (data) => {
    return data?.map((item) => ({
      id: item?.id,
      street: item?.address?.street,
      city: item?.address?.city,
      stateName: item?.address?.state?.name,
      zipCode: item?.address?.zipCode,
      country: item?.address?.country,
      phoneNumber: item?.address?.phoneNumber,
      contact: item?.address?.contact,
      stateId: item?.address?.stateId,
      name: item?.name,
      complex: item?.complex,
      capacity: item?.capacity,
      officialNumber: item?.officialNumber,
      farmId: item?.id,
      isolates: item?.isolates,
      status: item?.status,
    }));
  };

  const transformShippingData = (data) => {
    return data?.map((item) => ({
      id: item?.address?.id,
      street: item?.address?.street,
      city: item?.address?.city,
      stateName: item?.address?.state?.name,
      zipCode: item?.address?.zipCode,
      country: item?.address?.country,
      phoneNumber: item?.address?.phoneNumber,
      contact: item?.address?.contact,
      stateId: item?.address?.stateId,
      name: item?.address?.name,
      shippingStateId: item?.stateId,
      shippingState: item?.state,
      status: 0,
    }));
  };

  useEffect(() => {
    // for oreder form
    if (activeTab == 0) {
      setTransformedData(transformData(addressData));
    } else {
      setTransformedShippingData(transformShippingData(addressData));
    }
  }, [addressData]);

  const modalTitle = useMemo(() => {
    if (addressType === AddressTypes.Main) {
      if (viewOnly) {
        return t('orderForm.farmAddressesTitle');
      } else {
        if (isNonAdjacentRequired?.value) {
          return t('orderForm.proximityAddressesTitle');
        }
        return t('orderForm.farmAddressesTitle');
      }
    } else if (addressType === AddressTypes.Shipping) {
      return t('orderForm.shippingSitesTitle');
    } else if (addressType === AddressTypes.Proximity) {
      return t('orderForm.proximityAddressesTitle');
    }
    return null;
  }, [isNonAdjacentRequired?.value, addressType]);

  const { ADDRESSES_BY_STATE_VISIBILITY } = getDataVisibility();

  return (
    <CustomDialog
      open={isOpen}
      handleClose={onClose}
      modalTitle={modalTitle}
      sx={{
        width: '1100px',
      }}
    >
      {!isOrderDetails && ADDRESSES_BY_STATE_VISIBILITY && (
        <AddressFormContent
          id="address-form-content"
          addressType={addressType}
          addressData={
            activeTab == 0 ? transformedData : (transformedShippingData as any)
          }
          editMode={viewOnly ? false : editMode}
          subTitle={modalTitle}
          onClose={onClose}
          onSelectDone={onSelectDone}
          modePreviewTotalCount={modePreviewTotalCount}
          modePreviewSelectCount={modePreviewSelectCount}
          selectType={selectType}
          onCheckChange={onCheckChange}
          selectionData={selectionData}
          statesData={statesData}
          setStatesData={setStatesData}
          isShippingAddresse={isShippingAddresse}
          orderAddresses={orderAddresses}
          setOrderAddresses={setOrderAddresses}
          addressesByState={addressesByState}
          setAddressesByState={setAddressesByState}
          modeTest={false}
          activeTab={activeTab}
          value={value}
          onValueChange={onValueChange}
          CurrentAddressData={CurrentAddressData}
          onStateDataChange={onStateDataChange}
          fromShippingPage={true}
          isOrderForm={true}
          selectedStateId={selectedStateId}
          setSelectedStateId={setSelectedStateId}
          custmerId={custmerId}
          reorderFarmIds={reorderFarmIds}
        />
      )}
      {isOrderDetails && ADDRESSES_BY_STATE_VISIBILITY && (
        <AddressFormContentViewer
          id="address-form-content-viewer"
          addressType={addressType}
          addressData={
            activeTab == 0 ? transformedData : transformedShippingData
          }
          editMode={viewOnly ? false : editMode}
          subTitle={modalTitle}
          onClose={onClose}
          onSelectDone={onSelectDone}
          modePreviewTotalCount={modePreviewTotalCount}
          modePreviewSelectCount={modePreviewSelectCount}
          selectType={selectType}
          onCheckChange={onCheckChange}
          selectionData={selectionData}
          statesData={statesData}
          setStatesData={setStatesData}
          isShippingAddresse={isShippingAddresse}
          orderAddresses={orderAddresses}
          setOrderAddresses={setOrderAddresses}
          addressesByState={addressesByState}
          setAddressesByState={setAddressesByState}
          modeTest={false}
          activeTab={activeTab}
          value={value}
          onValueChange={onValueChange}
          CurrentAddressData={CurrentAddressData}
          onStateDataChange={onStateDataChange}
          fromShippingPage={true}
          isOrderForm={false}
          selectedStateId={selectedStateId}
          setSelectedStateId={setSelectedStateId}
          isOrderDetails={isOrderDetails}
          addressesByStateMapper={addressesByStateMapper}
          isFarmsDisplayed={isFarmsDisplayed}
        />
      )}
      {!ADDRESSES_BY_STATE_VISIBILITY && (
        <AddressFormContent
          id="address-form-content"
          addressType={addressType}
          addressData={
            activeTab == 0 ? transformedData : transformedShippingData
          }
          editMode={viewOnly ? false : editMode}
          subTitle={modalTitle}
          onClose={onClose}
          onSelectDone={onSelectDone}
          modePreviewTotalCount={modePreviewTotalCount}
          modePreviewSelectCount={modePreviewSelectCount}
          selectType={selectType}
          onCheckChange={onCheckChange}
          selectionData={selectionData}
          isOrderForm={!isOrderDetails}
          activeTab={activeTab}
          custmerId={custmerId}
        />
      )}
    </CustomDialog>
  );
};

export default AddressModal;
