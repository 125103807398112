import { Grid, Typography, styled } from '@mui/material';

export const PdfPreviewContainer = styled(Grid)`
  ${() => ({
    textAlign: 'center',
    display: 'flex',
    maxHeight: '100%',
  })}
`;
export const StyledForm = styled('form')`
  padding: 1rem 3rem 1rem 3rem;
  min-width: 700px;
  min-height: 300px;
`;
export const StyledTypography = styled(Typography)`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  height: 2.3rem;
  align-self: stretch;
`;
