import { Grid } from '@mui/material';
const DocList = ({ data, render }) => {
  return (
    <Grid item container>
      {data?.map((d, index) => render(d, index))}
    </Grid>
  );
};

export default DocList;
