import { Grid } from '@mui/material';
import { Stack } from '@mui/system';
import CustomButton from 'components/atoms/Button';
import React from 'react';
import { StyledGrid } from './styles';
import { LeftArrow } from 'components/icons';
import { StepperButtonGroupProps } from './types';
import PopupMessage from '../PopupMessage';
import { useTranslation } from 'react-i18next';
import { IActionButton } from '../PopupMessage/types';
const StepperButtonGroup: React.FC<StepperButtonGroupProps> = (props) => {
  const { t } = useTranslation();
  const {
    goBack,
    goNext,
    onCancel,
    backBtnDisabled,
    nextBtnDisabled,
    cancelBtnDisabled,
    showNext = false,
    showBackBtn = false,
    showCancelBtn = false,
    goNextIsLoading = false,
    goNextBtnTitle = t('global.nextTitle'),
  } = props;
  const [open, setPopUpOpen] = React.useState<boolean>(false);

  const actionButtons: Array<IActionButton> = [
    {
      title: t('global.yesCancel'),
      onClick: () => {
        onCancel();
      },
      variant: 'contained',
    },
    {
      title: t('global.continueNow'),
      onClick: () => {
        handlePopUpClose();
      },
      variant: 'outlined',
    },
  ];

  const handlePopUpOpen = () => setPopUpOpen(true);
  const handlePopUpClose = () => setPopUpOpen(false);

  const handleCancel = () => {
    handlePopUpOpen();
  };

  return (
    <StyledGrid
      container
      justifyContent={'space-between'}
      alignItems={'center'}
      justifyItems={'center'}
      spacing={{ xs: 1, md: 0 }}
    >
      <Grid item xs={6} md={6}>
        <Stack direction={'row'} justifyContent={'start'}>
          {showBackBtn && (
            <CustomButton
              size="medium"
              variant="outlined"
              startIcon={<LeftArrow />}
              onClick={goBack}
              disabled={backBtnDisabled}
            >
              {t('global.stepBack')}
            </CustomButton>
          )}
        </Stack>
      </Grid>
      <Grid
        item
        xs={6}
        md={6}
        container
        justifyContent={'flex-end'}
        alignItems={'center'}
        justifyItems={'center'}
        columnGap={2}
      >
        <Grid item>
          {showCancelBtn && (
            <CustomButton
              size="medium"
              variant="outlined"
              onClick={handleCancel}
              disabled={cancelBtnDisabled}
            >
              {t('global.cancelTitle')}
            </CustomButton>
          )}
        </Grid>
        <Grid item>
          {showNext && (
            <CustomButton
              size="medium"
              onClick={goNext}
              disabled={nextBtnDisabled}
              loading={goNextIsLoading}
            >
              {goNextBtnTitle}
            </CustomButton>
          )}
        </Grid>
        {/* <Stack direction="row">
          {showNext && (
            <CustomButton
              size="medium"
              onClick={goNext}
              disabled={nextBtnDisabled}
              loading={goNextIsLoading}
            >
              {goNextBtnTitle}
            </CustomButton>
          )}
        </Stack> */}
      </Grid>
      <PopupMessage
        isOpen={open}
        handleClose={handlePopUpClose}
        title={t('orderForm.cancelPopUpTitle')}
        description={t('orderForm.cancelOrderDescription')}
        actionButtons={actionButtons}
      />
    </StyledGrid>
  );
};

export default StepperButtonGroup;
