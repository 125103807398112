import { Box, styled } from '@mui/material';

export const StyledAddressesGridBox = styled(Box)`
  padding: 2.25rem 2.625rem 7.25rem 2.625rem;
`;

export const StyledAddressesHeaderBox = styled(Box)`
  padding: 2.25rem 2.625rem 0 2.625rem;
  ${({ theme }) => {
    return {
      [theme.breakpoints.down('md')]: {
        padding: '2.25rem 0rem 0 2.625rem',
      },
    };
  }};
`;
