import {
  Grid,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
  Table,
} from '@mui/material';
import withLayout from 'layout';
import { useTranslation } from 'react-i18next';
import {
  StyledHeadlineContainer,
  StyledTableBodyCell,
  StyledTableBodyCellText,
  StyledTableHeadCell,
  StyledTableHeadCellText,
} from './styles';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import DocList from 'components/organisms/DocList';
import DocItem from 'components/molecules/DocItem';
import { DownloadIcon, ViewIcon } from 'components/icons';
import {
  formatDate,
  getEnvironmentSettings,
  truncateString,
  isNullOrEmpty,
} from 'utils';
import TextBadge from 'components/atoms/TextBadge';
import RenderByCondition from 'components/atoms/RenderByCondition';
import { useGetIsolateDetailsQuery } from 'api/isolate';
import BackdropLoader from 'components/atoms/BackdropLoader';
import usePdfDoc from 'hooks/useDocMerge';
import PageHeader from 'components/molecules/PageHeader';

const { ISOLATE_ORGAN_VISIBILITY, SERIAL_NUMBER_ISOLATE_DETAILS_VISIBILITY } =
  getEnvironmentSettings();

const Card = ({ children }) => {
  return (
    <Grid
      container
      justifyContent="center"
      height={'100%'}
      width={'100%'}
      pb={10}
    >
      {children}
    </Grid>
  );
};

const RenderTypography = ({ text }) => {
  if (isNullOrEmpty(text)) return <>-</>;

  return <>{text}</>;
};

const SectionHeadline = ({ title }) => {
  const theme = useTheme();

  return (
    <StyledHeadlineContainer theme={theme}>
      <Typography color={'primary'} variant="body2" fontWeight={600}>
        {title}
      </Typography>
    </StyledHeadlineContainer>
  );
};

const IsolateNumberTable = ({ data }) => {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableHeadCell sx={{ paddingLeft: 0, width: '50%' }}>
              <Tooltip title={t('isolates.isolateNumber')}>
                <StyledTableHeadCellText variant="body2">
                  {t('isolates.isolateNumber')}
                </StyledTableHeadCellText>
              </Tooltip>
            </StyledTableHeadCell>
            <StyledTableHeadCell sx={{ paddingLeft: 0, width: '50%' }}>
              <Tooltip title={t('isolates.isolateName')}>
                <StyledTableHeadCellText variant="body2">
                  {t('isolates.isolateName')}
                </StyledTableHeadCellText>
              </Tooltip>
            </StyledTableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <StyledTableBodyCell sx={{ borderBottom: 0 }}>
              <Tooltip title={data?.reference}>
                <StyledTableBodyCellText variant="body2">
                  <RenderTypography text={data?.reference} />
                </StyledTableBodyCellText>
              </Tooltip>
            </StyledTableBodyCell>

            <StyledTableBodyCell sx={{ borderBottom: 0 }}>
              <Tooltip title={data?.name}>
                <StyledTableBodyCellText variant="body2">
                  <RenderTypography text={data?.name} />
                </StyledTableBodyCellText>
              </Tooltip>
            </StyledTableBodyCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const IsolateCaseNumberTable = ({ data }) => {
  const { t } = useTranslation();
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableHeadCell sx={{ paddingLeft: 0, width: '50%' }}>
              <Tooltip title={t('isolates.caseNumber')}>
                <StyledTableHeadCellText variant="body2">
                  {t('isolates.caseNumber')}
                </StyledTableHeadCellText>
              </Tooltip>
            </StyledTableHeadCell>
            <StyledTableHeadCell sx={{ paddingLeft: 0, width: '50%' }}>
              <Tooltip title={t('isolates.cevaVeterinaryServiceManager')}>
                <StyledTableHeadCellText variant="body2">
                  {t('isolates.cevaVeterinaryServiceManager')}
                </StyledTableHeadCellText>
              </Tooltip>
            </StyledTableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <StyledTableBodyCell sx={{ borderBottom: 0 }}>
              <Tooltip title={data?.caseNumber}>
                <StyledTableBodyCellText variant="body2">
                  <RenderTypography text={data?.caseNumber} />
                </StyledTableBodyCellText>
              </Tooltip>
            </StyledTableBodyCell>

            <StyledTableBodyCell sx={{ borderBottom: 0 }}>
              <Tooltip title={data?.cevaVeterinary}>
                <StyledTableBodyCellText variant="body2">
                  <RenderTypography text={data?.cevaVeterinary} />
                </StyledTableBodyCellText>
              </Tooltip>
            </StyledTableBodyCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const IsolateDateTable = ({ data }) => {
  const { t } = useTranslation();
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableHeadCell sx={{ paddingLeft: 0, width: '25%' }}>
              <Tooltip title={t('isolates.isolationDate')}>
                <StyledTableHeadCellText variant="body2">
                  {t('isolates.isolationDate')}
                </StyledTableHeadCellText>
              </Tooltip>
            </StyledTableHeadCell>
            <StyledTableHeadCell sx={{ paddingLeft: 0, width: '25%' }}>
              <Tooltip title={t('isolates.receptionDate')}>
                <StyledTableHeadCellText variant="body2">
                  {t('isolates.receptionDate')}
                </StyledTableHeadCellText>
              </Tooltip>
            </StyledTableHeadCell>
            <StyledTableHeadCell sx={{ paddingLeft: 0, width: '25%' }}>
              <Tooltip title={t('isolates.productionDate')}>
                <StyledTableHeadCellText variant="body2">
                  {t('isolates.productionDate')}
                </StyledTableHeadCellText>
              </Tooltip>
            </StyledTableHeadCell>

            <StyledTableHeadCell sx={{ paddingLeft: 0, width: '25%' }}>
              <Tooltip title={t('isolates.expirationDate')}>
                <StyledTableHeadCellText variant="body2">
                  {t('isolates.expirationDate')}
                </StyledTableHeadCellText>
              </Tooltip>
            </StyledTableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <StyledTableBodyCell>
              <Tooltip title={formatDate(data?.isolationDate)}>
                <StyledTableBodyCellText variant="body2">
                  <RenderTypography text={formatDate(data?.isolationDate)} />
                </StyledTableBodyCellText>
              </Tooltip>
            </StyledTableBodyCell>

            <StyledTableBodyCell>
              <Tooltip title={formatDate(data?.receptionDate)}>
                <StyledTableBodyCellText variant="body2">
                  <RenderTypography text={formatDate(data?.receptionDate)} />
                </StyledTableBodyCellText>
              </Tooltip>
            </StyledTableBodyCell>
            <StyledTableBodyCell>
              <Tooltip title={formatDate(data?.productionDate)}>
                <StyledTableBodyCellText variant="body2">
                  <RenderTypography text={formatDate(data?.productionDate)} />
                </StyledTableBodyCellText>
              </Tooltip>
            </StyledTableBodyCell>
            <StyledTableBodyCell>
              <Tooltip title={formatDate(data?.expirationDate)}>
                <StyledTableBodyCellText variant="body2">
                  <RenderTypography text={formatDate(data?.expirationDate)} />
                </StyledTableBodyCellText>
              </Tooltip>
            </StyledTableBodyCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const IsolateExtensionDateTable = ({ data }) => {
  const { t } = useTranslation();
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableHeadCell sx={{ paddingLeft: 0, width: '50%' }}>
              <Tooltip title={t('isolates.extensionDate')}>
                <StyledTableHeadCellText variant="body2">
                  {t('isolates.extensionDate')}
                </StyledTableHeadCellText>
              </Tooltip>
            </StyledTableHeadCell>

            <StyledTableHeadCell sx={{ paddingLeft: 0, width: '50%' }}>
              <Tooltip title={t('isolates.destructionDate')}>
                <StyledTableHeadCellText variant="body2">
                  {t('isolates.destructionDate')}
                </StyledTableHeadCellText>
              </Tooltip>
            </StyledTableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <StyledTableBodyCell sx={{ borderBottom: 0 }}>
              <Tooltip title={formatDate(data?.isolateExtensionRequest?.date)}>
                <StyledTableBodyCellText variant="body2">
                  <RenderTypography
                    text={formatDate(data?.isolateExtensionRequest?.date)}
                  />
                </StyledTableBodyCellText>
              </Tooltip>
            </StyledTableBodyCell>

            <StyledTableBodyCell sx={{ borderBottom: 0 }}>
              <Tooltip title={formatDate(data?.destructionDate)}>
                <StyledTableBodyCellText variant="body2">
                  <RenderTypography text={formatDate(data?.destructionDate)} />
                </StyledTableBodyCellText>
              </Tooltip>
            </StyledTableBodyCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const IsolateInformation = ({ data }) => {
  const { t } = useTranslation();
  return (
    <Grid container flexDirection={'column'}>
      <Grid item xs mb={1}>
        <SectionHeadline title={t('isolates.isolateInformation')} />
      </Grid>

      <Grid item xs p={1}>
        <Grid container flexDirection={'column'} spacing={4}>
          <Grid item xs>
            <IsolateNumberTable data={data} />
          </Grid>
          <Grid item xs>
            <IsolateCaseNumberTable data={data} />
          </Grid>
          <Grid item xs>
            <IsolateDateTable data={data} />
          </Grid>
          <Grid item xs>
            <IsolateExtensionDateTable data={data} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const OriginInformation = ({ data }) => {
  const { t } = useTranslation();

  const name = data?.originFarm?.name;
  const address = data?.originFarm?.address?.street;
  const city = data?.originFarm?.address?.city;
  const phone = data?.originFarm?.address?.phoneNumber;
  const capacity = data?.originFarm?.capacity;

  const fullAddress = address + ' ' + city;

  const originName = data?.originCustomer?.name;
  const originAddress = data?.originCustomer?.mainAddress?.street;
  const originCity = data?.originCustomer?.mainAddress?.city;
  const originPhone = data?.originCustomer?.mainAddress?.phoneNumber;

  const fullOriginAddress = originAddress + ' ' + originCity;

  return (
    <Grid item>
      <Grid item xs mb={1}>
        <SectionHeadline title={t('isolates.originTitle')} />
      </Grid>

      <Grid item xs p={1}>
        <Grid container flexDirection={'column'} spacing={4}>
          <Grid item xs>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableHeadCell sx={{ paddingLeft: 0, width: '33%' }}>
                      <Tooltip title={t('isolates.submitter')}>
                        <StyledTableHeadCellText variant="body2">
                          {t('isolates.submitter')}
                        </StyledTableHeadCellText>
                      </Tooltip>
                    </StyledTableHeadCell>
                    <StyledTableHeadCell sx={{ paddingLeft: 0, width: '33%' }}>
                      <Tooltip title={t('isolates.originFarm')}>
                        <StyledTableHeadCellText variant="body2">
                          {t('isolates.originFarm')}
                        </StyledTableHeadCellText>
                      </Tooltip>
                    </StyledTableHeadCell>
                    <StyledTableHeadCell sx={{ paddingLeft: 0, width: '33%' }}>
                      <Tooltip title={t('isolates.originCompany')}>
                        <StyledTableHeadCellText variant="body2">
                          {t('isolates.originCompany')}
                        </StyledTableHeadCellText>
                      </Tooltip>
                    </StyledTableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <StyledTableBodyCell sx={{ borderBottom: 0 }}>
                      <Tooltip title={data?.submitter}>
                        <StyledTableBodyCellText variant="body2">
                          <RenderTypography text={data?.submitter} />
                        </StyledTableBodyCellText>
                      </Tooltip>
                    </StyledTableBodyCell>

                    <StyledTableBodyCell sx={{ borderBottom: 0 }}>
                      <Tooltip title={name}>
                        <StyledTableBodyCellText variant="body2">
                          <RenderTypography text={name} />
                        </StyledTableBodyCellText>
                      </Tooltip>
                      <Tooltip title={fullAddress}>
                        <StyledTableBodyCellText variant="body2">
                          <RenderTypography
                            text={truncateString(fullAddress, 20)}
                          />
                        </StyledTableBodyCellText>
                      </Tooltip>
                      <Tooltip title={phone}>
                        <StyledTableBodyCellText variant="body2">
                          {'Tel'} <RenderTypography text={phone} />
                        </StyledTableBodyCellText>
                      </Tooltip>
                      <Tooltip title={capacity}>
                        <StyledTableBodyCellText variant="body2">
                          {'Capacity'} <RenderTypography text={capacity} />
                        </StyledTableBodyCellText>
                      </Tooltip>
                    </StyledTableBodyCell>

                    <StyledTableBodyCell sx={{ borderBottom: 0 }}>
                      <Tooltip title={originName}>
                        <StyledTableBodyCellText variant="body2">
                          <RenderTypography text={originName} />
                        </StyledTableBodyCellText>
                      </Tooltip>
                      <Tooltip title={fullOriginAddress}>
                        <StyledTableBodyCellText variant="body2">
                          <RenderTypography
                            text={truncateString(fullOriginAddress, 20)}
                          />
                        </StyledTableBodyCellText>
                      </Tooltip>
                      <Tooltip title={originPhone}>
                        <StyledTableBodyCellText variant="body2">
                          {'Tel'} <RenderTypography text={originPhone} />
                        </StyledTableBodyCellText>
                      </Tooltip>
                    </StyledTableBodyCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const OrderNumberInformation = ({ data }) => {
  const { t } = useTranslation();
  return (
    <Grid item>
      <Grid item xs mb={1}>
        <SectionHeadline title={t('isolates.orderNumberTitle')} />
      </Grid>

      <Grid item xs p={1}>
        <Grid container flexDirection={'column'} spacing={4}>
          <Grid item xs>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableHeadCell sx={{ paddingLeft: 0, width: '25%' }}>
                      <Tooltip title={t('isolates.orderNumber')}>
                        <StyledTableHeadCellText variant="body2">
                          {t('isolates.orderNumber')}
                        </StyledTableHeadCellText>
                      </Tooltip>
                    </StyledTableHeadCell>

                    <RenderByCondition
                      show={[SERIAL_NUMBER_ISOLATE_DETAILS_VISIBILITY]}
                    >
                      <StyledTableHeadCell
                        sx={{ paddingLeft: 0, width: '25%' }}
                      >
                        <Tooltip title={t('isolates.serialNumber')}>
                          <StyledTableHeadCellText variant="body2">
                            {t('isolates.serialNumber')}
                          </StyledTableHeadCellText>
                        </Tooltip>
                      </StyledTableHeadCell>
                    </RenderByCondition>
                    <StyledTableHeadCell sx={{ paddingLeft: 0, width: '25%' }}>
                      <Tooltip title={t('isolates.status')}>
                        <StyledTableHeadCellText variant="body2">
                          {t('isolates.status')}
                        </StyledTableHeadCellText>
                      </Tooltip>
                    </StyledTableHeadCell>

                    <StyledTableHeadCell sx={{ paddingLeft: 0, width: '25%' }}>
                      <Tooltip title={t('isolates.statusDate')}>
                        <StyledTableHeadCellText variant="body2">
                          {t('isolates.statusDate')}
                        </StyledTableHeadCellText>
                      </Tooltip>
                    </StyledTableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.orderIsolates?.map((row) => {
                    return (
                      <React.Fragment key={row?.order?.id}>
                        <TableRow>
                          <StyledTableBodyCell>
                            <Tooltip title={row?.order?.id}>
                              <StyledTableBodyCellText variant="body2">
                                {row?.order?.id}
                              </StyledTableBodyCellText>
                            </Tooltip>
                          </StyledTableBodyCell>

                          <RenderByCondition
                            show={[SERIAL_NUMBER_ISOLATE_DETAILS_VISIBILITY]}
                          >
                            <StyledTableBodyCell>
                              <StyledTableBodyCellText variant="body2">
                                {row?.order?.orderSerials?.map((elem) => (
                                  <Tooltip
                                    title={elem?.serialNumber}
                                    key={elem?.serialNumber}
                                  >
                                    <Typography variant="body2">
                                      <RenderTypography
                                        text={truncateString(
                                          elem?.serialNumber,
                                          20
                                        )}
                                      />
                                    </Typography>
                                  </Tooltip>
                                ))}
                              </StyledTableBodyCellText>
                            </StyledTableBodyCell>
                          </RenderByCondition>

                          <StyledTableBodyCell>
                            <StyledTableBodyCellText variant="body2">
                              {row?.order?.orderSerials?.map((elem) => {
                                const status =
                                  elem?.orderSerialSteps[
                                    elem?.orderSerialSteps?.length - 1
                                  ]?.orderSerialStepStatus;

                                const name = status?.name;
                                const color = status?.color;

                                return (
                                  <TextBadge
                                    key={name}
                                    name={name}
                                    color={color}
                                    variant="body3"
                                  />
                                );
                              })}
                            </StyledTableBodyCellText>
                          </StyledTableBodyCell>

                          <StyledTableBodyCell>
                            <StyledTableBodyCellText variant="body2">
                              {row?.order?.orderSerials?.map((elem) => {
                                const status =
                                  elem?.orderSerialSteps[
                                    elem?.orderSerialSteps?.length - 1
                                  ];

                                const statusDate = formatDate(status?.stepDate);

                                return (
                                  <React.Fragment key={statusDate}>
                                    {statusDate}
                                  </React.Fragment>
                                );
                              })}
                            </StyledTableBodyCellText>
                          </StyledTableBodyCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const AuthorizedCustomerInformation = ({ data }) => {
  const { t } = useTranslation();

  const {
    downloadFile,
    fetchFiles,
    openPdfInNewTab,
    isLoading: pdfIsLoading,
  } = usePdfDoc();

  return (
    <Grid item>
      <Grid item xs mb={1}>
        <SectionHeadline title={t('isolates.authorizedCustomerTitle')} />
      </Grid>

      <Grid item xs p={1}>
        <Grid container flexDirection={'column'} spacing={2}>
          <Grid item xs>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableHeadCell sx={{ paddingLeft: 0 }}>
                      <Tooltip title={t('isolates.nameTitle')}>
                        <StyledTableHeadCellText variant="body2">
                          {t('isolates.nameTitle')}
                        </StyledTableHeadCellText>
                      </Tooltip>
                    </StyledTableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.farmIsolateCustomers?.map((row) => (
                    <React.Fragment key={row?.authorizedCustomer?.name}>
                      <TableRow>
                        <StyledTableBodyCell>
                          <StyledTableBodyCellText variant="body2">
                            <RenderTypography
                              text={row?.authorizedCustomer?.name}
                            />
                          </StyledTableBodyCellText>
                        </StyledTableBodyCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs>
            <StyledTableHeadCellText variant="body2">
              Permission letters
            </StyledTableHeadCellText>
            <Grid item xs p={1}>
              <Grid container flexDirection={'column'} spacing={2}>
                <Grid item mt={1}>
                  <DocList
                    data={data?.isolateShareRequests?.map((item) => ({
                      title: 'Permission letter',
                      description:
                        formatDate(item?.date) +
                        ' ' +
                        item?.requestLetter?.fileName,
                      bgColor: '#F8F9FA',
                      buttonActions: [
                        {
                          title: t('global.buttonViewTitle'),
                          icon: <ViewIcon />,
                          type: 'button',
                          onClick: async () => {
                            fetchFiles([
                              `Document/download/${item?.requestLetter?.id}`,
                            ]).then((data) => {
                              openPdfInNewTab(data?.data);
                            });
                          },
                        },
                        {
                          title: t('global.download'),
                          icon: <DownloadIcon />,
                          type: 'button',
                          onClick: async () => {
                            fetchFiles([
                              `Document/download/${item?.requestLetter?.id}`,
                            ]).then(() => {
                              downloadFile(null, item?.requestLetter?.fileName);
                            });
                          },
                        },
                      ],
                    }))}
                    render={(item) => (
                      <DocItem {...item} sx={{ marginBottom: '12px' }} />
                    )}
                  />
                </Grid>
              </Grid>
              <BackdropLoader open={pdfIsLoading} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ExtensionRequestInformation = ({ data }) => {
  const { t } = useTranslation();
  const {
    downloadFile,
    fetchFiles,
    openPdfInNewTab,
    isLoading: pdfIsLoading,
  } = usePdfDoc();

  useEffect(() => {
    if (data?.isolateExtensionRequest?.requestLetter?.id)
      fetchFiles([
        `Document/download/${data?.isolateExtensionRequest?.requestLetter?.id}`,
      ]);
  }, [data?.isolateExtensionRequest?.requestLetter]);

  return (
    <Grid item>
      <Grid item xs mb={1}>
        <SectionHeadline title={t('isolates.extensionRequest')} />
      </Grid>

      <RenderByCondition show={[data?.isolateExtensionRequest !== null]}>
        <Grid item xs p={1}>
          <Grid container flexDirection={'column'} spacing={2}>
            <Grid item>
              <DocList
                data={[
                  {
                    title: t('isolates.extensionRequest'),
                    description: formatDate(
                      data?.isolateExtensionRequest?.date
                    ),
                    bgColor: '#F8F9FA',
                    buttonActions: [
                      {
                        title: t('global.buttonViewTitle'),
                        icon: <ViewIcon />,
                        type: 'button',
                        onClick: async () => {
                          openPdfInNewTab();
                        },
                      },
                      {
                        title: t('global.download'),
                        icon: <DownloadIcon />,
                        type: 'button',
                        onClick: async () => {
                          downloadFile(null, 'extensionRequestLetter');
                        },
                      },
                    ],
                  },
                ]}
                render={(item) => <DocItem {...item} />}
              />
            </Grid>
          </Grid>
          <BackdropLoader open={pdfIsLoading} />
        </Grid>
      </RenderByCondition>
    </Grid>
  );
};

const Header = ({ data }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Grid
      width={'100%'}
      height={50}
      container
      sx={{ backgroundColor: theme.palette.info.light }}
      alignContent={'center'}
      alignItems={'center'}
    >
      <Typography pl={4} color="primary" fontWeight={600}>
        {`${t('isolates.isolate')}: ${data?.reference} - ${data?.name}`}
      </Typography>
    </Grid>
  );
};

const StatusContainer = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Grid
      height={{ xs: 140, md: 50 }}
      alignItems={'center'}
      container
      flexDirection={{ xs: 'column', md: 'row' }}
      alignContent={'center'}
      justifyItems={'center'}
      bgcolor={'neutral.200'}
      rowSpacing={{ xs: 1, md: 0 }}
      mt={{ md: 0, xs: 2 }}
      p={2}
    >
      <Grid item xs>
        <Grid container flexDirection={{ md: 'row', xs: 'column' }}>
          <Grid item xs>
            <Grid
              container
              alignItems={'center'}
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              mb={{ md: 0, xs: 1 }}
            >
              <TextBadge
                name={data?.status?.name}
                color={data?.status?.color}
                variant="body3"
              />
            </Grid>
          </Grid>
          <Grid item xs pt={'5px'}>
            <Typography
              display={'inline'}
              variant="body2"
              fontWeight={600}
              color="neutral.800"
            >
              {t('global.date')}
            </Typography>
            <Typography variant="body2" display={'inline'} pl={1}>
              {formatDate(data?.statusDate)}{' '}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs>
        <Typography
          display={'inline'}
          variant="body2"
          fontWeight={600}
          color="neutral.800"
        >
          {t('global.description')}
        </Typography>
        <Typography variant="body2" display={'inline'} pl={1}>
          {data?.status?.description || ''}
        </Typography>
      </Grid>
      <Grid item xs display={{ md: 'flex', xs: 'none' }}></Grid>
    </Grid>
  );
};

const Content = ({ data }) => {
  const theme = useTheme();

  return (
    <Grid
      item
      bgcolor={theme.palette.neutral[50]}
      width={{ md: '100%', xs: '98%' }}
      marginX={{ xs: 'auto' }}
      marginTop={{ xs: 3 }}
      container
    >
      <Header data={data} />
      <Grid item xs={12} padding={{ md: 4, xs: 1 }}>
        <Grid container rowGap={6} flexDirection={'column'}>
          <Grid item xs>
            <StatusContainer data={data} />
          </Grid>

          <Grid item xs maxWidth={{ xs: 350, md: 'none' }}>
            <RenderByCondition show={[ISOLATE_ORGAN_VISIBILITY]}>
              <OrganInformation data={data} />
            </RenderByCondition>

            <RenderByCondition show={[!ISOLATE_ORGAN_VISIBILITY]}>
              <IsolateInformation data={data} />
            </RenderByCondition>
          </Grid>

          <Grid item xs maxWidth={{ xs: 350, md: 'none' }}>
            <RenderByCondition show={[!ISOLATE_ORGAN_VISIBILITY]}>
              <OriginInformation data={data} />
            </RenderByCondition>
          </Grid>

          <Grid item xs maxWidth={{ xs: 350, md: 'none' }}>
            <OrderNumberInformation data={data} />
          </Grid>

          <Grid item xs maxWidth={{ xs: 350, md: 'none' }}>
            <RenderByCondition show={[!ISOLATE_ORGAN_VISIBILITY]}>
              <AuthorizedCustomerInformation data={data} />
            </RenderByCondition>
          </Grid>

          <Grid item xs maxWidth={{ xs: 350, md: 'none' }}>
            <RenderByCondition show={[!ISOLATE_ORGAN_VISIBILITY]}>
              <ExtensionRequestInformation data={data} />
            </RenderByCondition>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const BacteriaTable = ({ data }) => {
  const { t } = useTranslation();
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableHeadCell sx={{ paddingLeft: 0, width: '50%' }}>
              <Tooltip title={t('isolates.ref')}>
                <StyledTableHeadCellText variant="body2">
                  {t('isolates.ref')}
                </StyledTableHeadCellText>
              </Tooltip>
            </StyledTableHeadCell>
            <StyledTableHeadCell sx={{ paddingLeft: 0, width: '50%' }}>
              <Tooltip title={t('isolates.bacteria')}>
                <StyledTableHeadCellText variant="body2">
                  {t('isolates.bacteria')}
                </StyledTableHeadCellText>
              </Tooltip>
            </StyledTableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <StyledTableBodyCell sx={{ borderBottom: 0 }}>
              <StyledTableBodyCellText variant="body2">
                {data?.reference}
              </StyledTableBodyCellText>
            </StyledTableBodyCell>

            <StyledTableBodyCell sx={{ borderBottom: 0 }}>
              <StyledTableBodyCellText variant="body2">
                {data?.bacteria}
              </StyledTableBodyCellText>
            </StyledTableBodyCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const SeroTypeTable = ({ data }) => {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableHeadCell sx={{ paddingLeft: 0, width: '32%' }}>
              <Tooltip title={t('isolates.seroType')}>
                <StyledTableHeadCellText variant="body2">
                  {t('isolates.seroType')}
                </StyledTableHeadCellText>
              </Tooltip>
            </StyledTableHeadCell>
            <StyledTableHeadCell sx={{ paddingLeft: 0, width: '32%' }}>
              <Tooltip title={t('isolates.specie')}>
                <StyledTableHeadCellText variant="body2">
                  {t('isolates.specie')}
                </StyledTableHeadCellText>
              </Tooltip>
            </StyledTableHeadCell>
            <StyledTableHeadCell sx={{ paddingLeft: 0, width: '32%' }}>
              <Tooltip title={t('isolates.organ')}>
                <StyledTableHeadCellText variant="body2">
                  {t('isolates.organ')}
                </StyledTableHeadCellText>
              </Tooltip>
            </StyledTableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <StyledTableBodyCell sx={{ borderBottom: 0 }}>
              <StyledTableBodyCellText variant="body2">
                {data?.seroType}
              </StyledTableBodyCellText>
            </StyledTableBodyCell>
            <StyledTableBodyCell sx={{ borderBottom: 0 }}>
              <Tooltip title={data?.animalSpecies?.name}>
                <StyledTableBodyCellText variant="body2">
                  {data?.animalSpecies?.name}
                </StyledTableBodyCellText>
              </Tooltip>
            </StyledTableBodyCell>
            <StyledTableBodyCell sx={{ borderBottom: 0 }}>
              <Tooltip title={data?.organ?.name}>
                <StyledTableBodyCellText variant="body2">
                  {data?.organ?.name}
                </StyledTableBodyCellText>
              </Tooltip>
            </StyledTableBodyCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const OrganInformation = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Grid container flexDirection={'column'}>
      <Grid item xs mb={1}>
        <SectionHeadline title={t('isolates.isolateInformation')} />
      </Grid>

      <Grid item xs p={1}>
        <Grid container flexDirection={'column'} spacing={4}>
          <Grid item xs>
            <BacteriaTable data={data} />
          </Grid>
          <Grid item xs>
            <SeroTypeTable data={data} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

interface IsolateDetailsContainerProps {
  id: string | number;
  showPageHeader?: boolean;
}

export const IsolateDetailsContainer = ({
  id,
  showPageHeader = true,
}: IsolateDetailsContainerProps) => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetIsolateDetailsQuery(
    {
      id: Number(id),
    },
    { skip: !id }
  );

  return (
    <Card>
      <RenderByCondition show={[showPageHeader]}>
        <PageHeader title={t('isolates.details')} />
      </RenderByCondition>
      <Content data={data} />
      <BackdropLoader open={isLoading} />
    </Card>
  );
};

const IsolateDetails = () => {
  const params = useParams();

  return <IsolateDetailsContainer id={params?.id} />;
};

export default withLayout(IsolateDetails);
