import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';

export const StyledLabel = styled(Box)`
  margin: 0px 8px;
  display: inline-block;
`;

export const StyledErrorMessage = styled(Typography)`
  margin-top: 6px;
`;

export const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column;

  '& .MuiFormControlLabel-root' {
    margin-left: 0px;
    margin-right: 0px;
  }
`;

