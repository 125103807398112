import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Box, Tooltip, useTheme } from '@mui/material';
import {
  StyledAutoComplete,
  StyledBox,
  StyledChip,
  StyledErrorText,
  StyledInputLabel,
  StyledListItem,
  StyledTypographyWithEllipsis,
} from './styles';
import { ViewIcon } from 'components/icons';
import { CustomAutoCompleteProps, Option } from './types';
import RenderByCondition from 'components/atoms/RenderByCondition';
import { getFieldVisibility } from 'utils';

const {
  CUSTOMER_VISIBILITY,
  VIEW_ISOLATE_DETAILS_VISIBILITY,
  AUTOCOMPLETE_ISOLATE_FARM_VISIBILITY,
  AUTOCOMPLETE_ISOLATE_SEROTYPE_FIRST_DISPLAY_ORDER,
} = getFieldVisibility();

const CustomOption = (props) => {
  const { renderProps, selected, handleOpenModalIconClick } = props;
  const { reference, farmName, customerName, serotype, name } = props.option;

  return (
    <StyledListItem {...renderProps}>
      <Checkbox
        style={{ marginRight: '8px', display: 'flex' }}
        checked={selected}
      />
      <Tooltip title={name} followCursor>
        <StyledTypographyWithEllipsis>{name}</StyledTypographyWithEllipsis>
      </Tooltip>{' '}
      |
      <RenderByCondition
        show={[AUTOCOMPLETE_ISOLATE_SEROTYPE_FIRST_DISPLAY_ORDER]}
      >
        <Tooltip title={serotype} followCursor>
          <StyledTypographyWithEllipsis>
            {serotype}
          </StyledTypographyWithEllipsis>
        </Tooltip>{' '}
        |
      </RenderByCondition>
      <Tooltip title={reference} followCursor>
        <StyledTypographyWithEllipsis>{reference}</StyledTypographyWithEllipsis>
      </Tooltip>{' '}
      |
      <RenderByCondition show={[AUTOCOMPLETE_ISOLATE_FARM_VISIBILITY]}>
        <Tooltip title={farmName} followCursor>
          <StyledTypographyWithEllipsis>
            {farmName}
          </StyledTypographyWithEllipsis>
        </Tooltip>
        |
      </RenderByCondition>
      <RenderByCondition
        show={[AUTOCOMPLETE_ISOLATE_SEROTYPE_FIRST_DISPLAY_ORDER === false]}
      >
        <Tooltip title={serotype} followCursor>
          <StyledTypographyWithEllipsis>
            {serotype}
          </StyledTypographyWithEllipsis>
        </Tooltip>{' '}
      </RenderByCondition>
      <RenderByCondition show={[CUSTOMER_VISIBILITY]}>
        <>
          |
          <Tooltip title={customerName} followCursor>
            <StyledTypographyWithEllipsis>
              {customerName}
            </StyledTypographyWithEllipsis>
          </Tooltip>{' '}
        </>
      </RenderByCondition>
      <RenderByCondition show={[VIEW_ISOLATE_DETAILS_VISIBILITY]}>
        <StyledBox>
          <IconButton
            sx={{ display: 'flex', width: 30, height: 30 }}
            onClick={(event) => {
              event.stopPropagation();
              handleOpenModalIconClick();
            }}
          >
            <ViewIcon />
          </IconButton>
        </StyledBox>
      </RenderByCondition>
    </StyledListItem>
  );
};

const CustomAutoComplete = (props: CustomAutoCompleteProps) => {
  const [inputValue, setInputValue] = React.useState('');
  const [selectedOptions, setSelectedOptions] = React.useState<
    [] | Array<Option>
  >([]);
  const [selectedIds, setSelectedIds] = React.useState<any>([]);
  const theme = useTheme();
  const {
    handleOpenModalIconClick,
    options,
    onChange,
    name,
    isDisabled = false,
    open = false,
    handleOpen,
    handleOnBlur,
    chipBgColor,
    label,
    labelProps,
    labelColor,
    value = [],
    size = 'medium',
    error = false,
    errorMessage,
    limit,
  } = props;

  React.useEffect(() => {
    if (props?.value) {
      const ids = [];
      props?.value?.forEach((option) => {
        ids.push(option.id);
      });

      setSelectedIds(ids);
      setSelectedOptions(props?.value);
    }
  }, [value]);

  const selectOption = (event, value) => {
    value = value.filter(
      (v, i, a) => a?.filter((t) => t?.id === v?.id)?.length === 1
    );

    const ids = [];

    value?.forEach((option) => {
      ids.push(option.id);
    });

    setSelectedIds(ids);
    setSelectedOptions(value);

    onChange(
      {
        ...event,
        target: {
          ...event.target,
          name: name,
        },
      },
      value
    );
  };

  const removeOption = (event, value) => {
    const ids = [];

    value?.forEach((option) => {
      ids.push(option.id);
    });

    setSelectedIds(ids);
    setSelectedOptions(value);

    onChange(
      {
        ...event,
        target: {
          ...event.target,
          name: name,
        },
      },
      value
    );
  };

  const clear = () => {
    setSelectedIds([]);
    setSelectedOptions([]);
    onChange(
      {
        target: {
          name: name,
        },
      },
      []
    );
  };

  const selectOnChange = (event, value, reason) => {
    if (value?.length === limit && reason === 'selectOption') return;
    if (reason === 'selectOption') {
      selectOption(event, value);
    } else if (reason === 'removeOption') {
      removeOption(event, value);
    } else if (reason === 'clear') {
      clear();
    }
  };

  const RenderTags = (data, getTagProps) => {
    return (
      <>
        {data?.map((option: any, index) => {
          const tagProps = getTagProps(index);

          return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }} key={option?.id}>
              <Tooltip
                key={option?.value}
                title={option?.label}
                placement="top"
              >
                <StyledChip
                  {...tagProps}
                  key={option?.value}
                  label={option?.label}
                  onDelete={(event) => {
                    tagProps?.onDelete(event);
                  }}
                  color={'primary'}
                  chipBgColor={
                    option.styleInfo?.bgColor
                      ? option.styleInfo?.bgColor
                      : chipBgColor
                  }
                  labelColor={
                    option.styleInfo?.textColor
                      ? option.styleInfo?.textColor
                      : labelColor
                  }
                  size="small"
                />
              </Tooltip>
            </Box>
          );
        })}
      </>
    );
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <StyledInputLabel htmlFor={name}>
        <Tooltip placement="top" title={label}>
          <Typography
            variant="body1"
            color={theme.palette.neutral[800]}
            sx={{ mb: 0.5 }}
            {...labelProps}
          >
            {label}
          </Typography>
        </Tooltip>
      </StyledInputLabel>

      <StyledAutoComplete
        size="small"
        disabled={isDisabled}
        multiple
        id="custom-auto-complete"
        options={options?.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        })}
        open={open}
        onClose={() => {
          handleOpen(false);
        }}
        value={selectedOptions}
        onChange={selectOnChange}
        onClick={() => handleOpen(!open)}
        onBlur={handleOnBlur}
        inputValue={inputValue}
        disableCloseOnSelect
        getOptionLabel={(option: any) => {
          return `${option?.name} ${option?.reference} ${option?.farmName}  ${option?.serotype} ${option?.customerName}`;
        }}
        renderTags={RenderTags}
        renderOption={(props, option: any) => {
          return (
            <CustomOption
              option={option}
              selected={selectedIds?.includes(option?.id as any)}
              name={name}
              renderProps={props}
              handleOpenModalIconClick={() =>
                handleOpenModalIconClick(option?.id)
              }
            />
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={(e) => setInputValue(e.target.value)}
            label=""
            placeholder=""
            onClick={() => handleOpen(true)}
            error={error}
          />
        )}
      />
      {error && errorMessage && (
        <StyledErrorText size={size}>{errorMessage}</StyledErrorText>
      )}
    </Box>
  );
};

export default CustomAutoComplete;
