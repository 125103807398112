import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setMode } from 'store/features/orderForm/index.slice';
import FormHeader from 'components/organisms/FormHeader';
import FormContent from 'components/organisms/FormContent';
import { ModeState } from 'store/features/orderForm/index.type';
import {
  useGetOrderConfigQuery,
  useGetOrderByIdQuery,
  useLockOrderMutation,
} from 'api/orderForm';
import useInitializeFormData from 'hooks/useInitializeFormData';
import {
  getUserAccount,
  getUserRole,
} from 'store/features/auth/index.selector';
import withLayout from 'layout';
import BackdropLoader from 'components/atoms/BackdropLoader';
import useToast from 'hooks/useToast';
import { useTranslation } from 'react-i18next';
import { PAGES } from 'constants/index';

const EditOrderForm: React.FC = () => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const navigate = useNavigate();
  const userRole = useSelector(getUserRole);
  const user = useSelector(getUserAccount);
  const { data: config, isLoading: configIsLoading } = useGetOrderConfigQuery(
    {
      userId: user?.id,
      role: user?.role,
    },
    { skip: !(user?.id && user?.role) }
  );
  const [, setActiveStep] = useState(0);
  const dispatch = useDispatch();
  const { id: urlOrderId } = useParams<{ id: string }>();
  const idFromParams = parseInt(urlOrderId, 10) || 0;
  const {
    data: orderData,
    isLoading: orderLoading,
    error: orderError,
    isError: isGetOrderByIdQueryError,
  } = useGetOrderByIdQuery(idFromParams);
  const [lockOrder, { isLoading: lockOrderIsLoading }] = useLockOrderMutation();
  const userAccount = useSelector(getUserAccount);

  const { isFormDisabled, toggleFormDisabled } = useInitializeFormData(
    dispatch,
    config,
    orderData,
    orderLoading,
    orderError
  );

  useEffect(() => {
    const fetchData = async () => {
      if (orderData && userAccount?.id) {
        try {
          await lockOrder({
            orderId: orderData.id,
            userId: userAccount?.id,
          });
        } catch (error) {
          console.error('Error locking order:', error);
        }
      } else if (isGetOrderByIdQueryError) {
        toast.error(t('messages.getOrderFail', { id: idFromParams }), {
          onFinishCallBack: () => {
            navigate(`${PAGES[userRole].DASHBOARD}`);
          },
        });
      }
    };

    fetchData();
    dispatch(setMode(ModeState.EDIT)); // Set the mode to edit
  }, [userAccount?.id, config, isGetOrderByIdQueryError]);

  return (
    <Grid container justifyContent="center" height={'100%'}>
      <Grid
        item
        m={{ xs: '0px', md: '60px' }}
        sx={{ width: 960, minWidth: 200 }}
      >
        <FormHeader hideShare={isFormDisabled} />
        <FormContent
          setActiveStep={setActiveStep}
          isFormDisabled={isFormDisabled}
          toggleFormDisabled={toggleFormDisabled}
        />
        <BackdropLoader
          open={configIsLoading || lockOrderIsLoading || orderLoading}
        />
      </Grid>
    </Grid>
  );
};

export default withLayout(EditOrderForm);
