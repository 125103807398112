import * as React from 'react';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {
  StyledContainer,
  StyledContentContainer,
  StyledStepIcon,
  StyledStepper,
} from './styles';
import { CustomStepperProps } from './types';
import { useNavigate } from 'react-router-dom';
import { PAGES } from 'constants/index';
import { StepIconProps } from '@mui/material';
import { clearOrderForm } from 'store/features/orderForm/index.slice';
import { useDispatch, useSelector } from 'react-redux';
import { getUserRole } from 'store/features/auth/index.selector';

const StepIcon = (props: StepIconProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { icon: value, active, completed } = props;

  return (
    <StyledStepIcon
      size="small"
      value={value}
      active={active?.toString()}
      completed={completed?.toString()}
    />
  );
};

const CustomStepper: React.FC<CustomStepperProps> = (props) => {
  const dispatch = useDispatch();
  const { steps, isFormDisabled, toggleFormDisabled } = props;
  const [activeStep, setActiveStep] = React.useState(0);
  const navigate = useNavigate();
  const userRole = useSelector(getUserRole);

  const goNext = () => {
    if (activeStep + 1 < steps.length) {
      const stepCount = activeStep + 1;
      setActiveStep(() => stepCount);
      steps?.[activeStep]?.setActiveStep(stepCount);
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const goToStep = (index: number) => {
    if (index >= 0 && index < steps.length) {
      setActiveStep(index);
      steps?.[activeStep]?.setActiveStep(index);
    }
  };

  const goBack = () => {
    if (activeStep > 0) {
      const stepCount = activeStep - 1;
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      steps?.[activeStep]?.setActiveStep(stepCount);
    }
  };

  const handleReset = () => {
    setActiveStep(0);
    steps?.[activeStep]?.setActiveStep(0);
  };

  const onCancel = () => {
    navigate(PAGES[userRole].DASHBOARD);
    dispatch(clearOrderForm());
  };

  const Component = steps[activeStep].component;

  return (
    <StyledContainer>
      <StyledStepper activeStep={activeStep} orientation="vertical">
        {steps?.map((step) => {
          return (
            <Step key={step.label}>
              <StepLabel StepIconComponent={StepIcon}>{step.label}</StepLabel>
            </Step>
          );
        })}
      </StyledStepper>
      <StyledContentContainer>
        <Component
          goBack={goBack}
          goNext={goNext}
          handleReset={handleReset}
          goToStep={goToStep}
          onCancel={onCancel}
          isFormDisabled={isFormDisabled}
          toggleFormDisabled={toggleFormDisabled}
        />
      </StyledContentContainer>
    </StyledContainer>
  );
};

export default CustomStepper;
