import { SVGProps } from 'react';

const ExpandIconOpen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="7"
    fill="none"
    viewBox="0 0 12 7"
    {...props}
  >
    <path
      fill="#6A7178"
      d="M11.25 1.813L6.5 6.28A.716.716 0 016 6.5a.849.849 0 01-.531-.188l-4.75-4.5C.406 1.532.406 1.063.688.75c.28-.313.75-.313 1.062-.031l4.25 4 4.219-4C10.53.438 11 .438 11.28.75c.281.313.281.781-.031 1.063z"
    ></path>
  </svg>
);
export default ExpandIconOpen;
