import {
  Box,
  Dialog,
  Typography,
  TypographyProps,
  styled,
} from '@mui/material';
import { TVariant } from 'types';

export const StyledModalHeader = styled(Box)`
  ${({ theme }) => ({
    backgroundColor: theme.palette.info.light,
    display: 'flex',
    height: '2.75rem',
    padding: '0 1.5rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
  })}
`;
export const StyledModalHeaderTitle = styled(Typography)`
  ${({ theme }) => ({
    color: theme.palette.primary[900],
    fontWeight: 700,
  })}
` as React.FC<Omit<TypographyProps, 'variant'> & { variant: TVariant }>;

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
