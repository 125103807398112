import { Box, Typography, styled } from '@mui/material';

export const StyledInputLabel = styled(Box)`
  ${({ theme }) => ({
    color: theme.palette.secondary['400'],
    textAlign: 'left',
    fontSize: '1.0rem',
    fontFamily: 'Noto Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '1.125rem',
    textDecorationLine: 'underline !important',
    textDecorationColor: theme.palette.secondary['400'],
    ':hover': {
      cursor: 'pointer',
    },
  })}
`;

export const StyledAddressesModalViewTitle = styled(Typography)`
  ${({ theme }) => ({
    color: theme.palette.primary[700],
    textAlign: 'center',
    fontWeight: 550,
    lineHeight: '1.375rem',
    [theme.breakpoints.down('md')]: {
      margin: '0',
      textAlign: 'start',
    },
  })}
`;

export const StyledOuterBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#F8F9FA',
  width: '100%',
  padding: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    minHeight: 'auto',
  },
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    height: 45,
  },
  marginTop: theme.spacing(2),
}));

export const StyledSelectedStates = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-right',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    width: '100%',
  },
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    width: '70%',
  },
}));

export const StyledResponsiveBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  [theme.breakpoints.up('md')]: {
    width: '18%',
    marginBottom: 0,
  },
}));
