import { useEffect, useLayoutEffect, useState } from 'react';
import {
  AccountInfo,
  AuthenticationResult,
  InteractionRequiredAuthError,
} from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAccount,
  setUserId,
  setUserName,
  setUserRole,
} from 'store/features/auth/index.slice';
import { loginRequest, passwordResetRequest } from 'config/msal';
import { AUTH_METHODS } from 'constants/index';
import { useLazyGetUserQuery } from 'api/auth';
import { getUserRole } from 'store/features/auth/index.selector';
import { UserRole } from 'types';
import { signalRConnectionManager } from 'config/signalR';

interface AuthenticationResponse {
  isAuthenticated: boolean;
  authIsInProgress: boolean;
  getUserIsFetching: boolean;
  account: (AccountInfo & { token: string }) | null;
  login: () => Promise<void>;
  logout: () => void;
  result: AuthenticationResult;
  error: any;
  userRole: UserRole | '';
  changePassword: () => Promise<void>;
  isAuthorized: boolean;
}

interface AuthenticationParams {
  method?: (typeof AUTH_METHODS)[keyof typeof AUTH_METHODS];
}

interface MsalAuthResponse {
  token: string;
  msalUserIsAuth: boolean;
  authIsInProgress: boolean;
  msalUserLogin: () => Promise<void>;
  msalUserLogout: () => Promise<void>;
  account: AuthenticationResponse['account'];
  result: AuthenticationResult;
  error: any;
  changePassword: () => Promise<void>;
}

const checkForgotPassError = (error) => error?.includes?.('AADB2C90118');

const url = process.env.REACT_APP_ACCESS_TOKEN_SCOPE_URL;
const webClientId = process.env.REACT_APP_ACCESS_TOKEN_WEB_APP_CLIENT_ID;

export const useMsalAuth = (): MsalAuthResponse => {
  const { instance, accounts, inProgress }: any = useMsal();
  const isAuthenticated = useIsAuthenticated(accounts);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    if (!isAuthenticated && accounts[0] && !accessToken) {
      const request = {
        scopes: [`${url}/${webClientId}/access_as_user`],
        account: accounts[0],
      };
      acquireToken(request);
    }
  }, [isAuthenticated, instance, accounts, accessToken]);

  useEffect(() => {
    if (
      !isAuthenticated &&
      inProgress === 'none' &&
      !accessToken &&
      !accounts[0]
    ) {
      msalUserLogin();
    }
  }, [isAuthenticated, inProgress, accessToken]);

  useLayoutEffect(() => {
    instance
      ?.handleRedirectPromise()
      ?.then((res) => {
        setResult(res);
      })
      ?.catch((error) => {
        if (checkForgotPassError(error?.errorMessage)) {
          mslUserChangePassword();
          setError(error);
        }
      });
  }, [instance]);

  const acquireToken = (request) => {
    instance
      .acquireTokenSilent(request)
      .then((tokenResponse) => {
        const fetchedAccessToken = !tokenResponse?.accessToken
          ? tokenResponse?.idToken
          : tokenResponse?.accessToken;

        if (fetchedAccessToken) {
          setAccessToken(fetchedAccessToken);
        }
      })
      .catch((error) => {
        if (error?.name === 'BrowserAuthError')
          return instance.acquireTokenRedirect(request);
        if (error instanceof InteractionRequiredAuthError) {
          return instance.acquireTokenRedirect(request);
        }
      });
  };

  const mslUserChangePassword = async () => {
    await instance.loginRedirect(passwordResetRequest);
  };

  const msalUserLogin = async () => {
    await instance.loginRedirect(loginRequest);
  };

  const msalUserLogout = async () => {
    await instance.logout();
  };

  return {
    msalUserIsAuth: isAuthenticated,
    authIsInProgress: inProgress !== 'none',
    msalUserLogin,
    msalUserLogout,
    account: accounts?.[0],
    result: result,
    error: error,
    changePassword: mslUserChangePassword,
    token: accessToken,
  };
};

export const useAuth = ({
  method = AUTH_METHODS.MSAL,
}: AuthenticationParams = {}): AuthenticationResponse => {
  const {
    msalUserIsAuth,
    msalUserLogin,
    msalUserLogout,
    account,
    authIsInProgress,
    error,
    result,
    changePassword,
    token,
  } = useMsalAuth();
  const dispatch = useDispatch();
  const [getUser, { data: userData }] = useLazyGetUserQuery();
  const userRole = useSelector(getUserRole);
  const [getUserIsLoading, setIsLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(true);

  const fetchUser = async () => {
    try {
      const profile = await getUser();
      if (profile.isError) {
        setIsLoading(false);
        setIsAuthorized(false);
        return;
      }
      signalRConnectionManager.connect(profile.data.id, token);

      return profile?.data?.role;
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    (async function () {
      if (token) {
        const userData = {
          ...account,
          given_name: account?.idTokenClaims?.given_name as string,
          token,
        };

        dispatch(setAccount(userData));
        await fetchUser();
      }
    })();
  }, [token, account, dispatch]);

  useEffect(() => {
    if (userData?.role && !userRole) {
      dispatch(setUserRole(userData?.role));
      dispatch(setUserId(userData?.id));
      dispatch(setUserName(userData?.userName));
      setIsLoading(false);
    }
  }, [dispatch, userData, userRole]);

  if (method === AUTH_METHODS.MSAL)
    return {
      isAuthenticated: msalUserIsAuth,
      authIsInProgress: authIsInProgress,
      getUserIsFetching: getUserIsLoading,
      login: msalUserLogin,
      logout: msalUserLogout,
      account: { ...account, token },
      error,
      result,
      changePassword,
      userRole: userRole,
      isAuthorized: isAuthorized,
    };
};
