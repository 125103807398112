import { SVGProps } from 'react';

const Settings = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 23"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill="#29306B"
      d="M11.955 7.652c.027-.213.047-.427.047-.653 0-.227-.02-.44-.047-.654l1.407-1.1c.127-.1.16-.28.08-.426l-1.334-2.307a.333.333 0 00-.406-.147l-1.66.667a4.869 4.869 0 00-1.127-.653L8.662.612a.325.325 0 00-.327-.28H5.67a.325.325 0 00-.327.28l-.253 1.767c-.407.166-.78.393-1.127.653l-1.66-.667a.378.378 0 00-.12-.02.33.33 0 00-.287.167L.562 4.819a.329.329 0 00.08.426l1.407 1.1A5.288 5.288 0 002.002 7c0 .22.02.44.047.653l-1.407 1.1c-.127.1-.16.28-.08.427l1.333 2.306a.333.333 0 00.407.147l1.66-.667c.347.267.72.487 1.127.654l.253 1.766c.02.16.16.28.327.28h2.666c.167 0 .307-.12.327-.28l.253-1.766c.407-.167.78-.394 1.127-.654l1.66.667c.04.013.08.02.12.02a.33.33 0 00.287-.167l1.333-2.306a.336.336 0 00-.08-.427l-1.407-1.1zm-1.32-1.14c.027.207.034.347.034.487s-.014.286-.034.486l-.093.754.593.466.72.56-.466.807-.847-.34-.693-.28-.6.453c-.287.214-.56.374-.834.487l-.706.287-.107.753-.133.9h-.934l-.233-1.653-.707-.287a3.782 3.782 0 01-.82-.473l-.606-.467-.707.287-.847.34-.466-.807.72-.56.593-.467-.093-.753a5.363 5.363 0 01-.034-.493c0-.134.014-.287.034-.487l.093-.753-.593-.467-.72-.56.466-.807.847.34.693.28.6-.453c.287-.213.56-.373.834-.487l.706-.286.107-.754.133-.9h.927l.233 1.654.707.286c.287.12.553.274.82.474l.607.466.706-.286.847-.34.466.806-.713.567-.593.467.093.753zm-3.633-2.18a2.666 2.666 0 100 5.333 2.666 2.666 0 100-5.333zm0 4c-.733 0-1.333-.6-1.333-1.333 0-.734.6-1.334 1.333-1.334s1.333.6 1.333 1.334c0 .733-.6 1.333-1.333 1.333z"
    />
  </svg>
);

export default Settings;
