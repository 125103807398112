import { Theme, styled } from '@mui/material/styles';
import {
  Card,
  Box,
  Menu,
  MenuItem,
  CardContent,
  CardHeader,
  CardActions,
  Typography,
  TextareaAutosize,
} from '@mui/material';
import Button from 'components/atoms/Button';
import CustomCircle from 'components/atoms/Circle';
import { StyledErrorTextProps } from 'components/atoms/Input/types';

export const StyledCard = styled(Card)(({ theme }) => ({
  margin: '0px',

  position: 'relative',
  '& .MuiCardHeader-root': {
    backgroundColor: '#E5F7FD',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0.5rem 1rem',
  },

  borderRadius: 8,
  boxShadow: theme.shadows[5],
}));

export const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: 8,
    alignItems: 'flex-start !important',
  },
}));

export const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    justifyContent: 'space-between',
  },
}));

export const StyledCircle = styled(CustomCircle)(({ theme }) => ({
  display: 'flex',
  width: '10px 40px',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '0.5rem',
  border: 1,
  borderBlockStyle: 'solid',
  marginTop: '0.3rem',
  borderColor: '#29306B',
  borderRadius: '6.25rem',
  paddingLeft: '0.5rem',
  paddingRight: '0.5rem',
  [theme.breakpoints.down('md')]: {
    width: '30px',
  },
}));

export const StyledMenuItem = styled(MenuItem)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '260px',
  height: '3.5rem',
  gap: '1rem',
}));
export const CustomButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

export const CustomItems = styled(Box)(({ theme }) => ({
  color: 'black',
  padding: theme.spacing(1.3),
  textAlign: 'left',
  paddingLeft: '1.8rem',
}));

export const CenteredBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const StyledCardContent = styled(CardContent)(({ theme }) => ({
  paddingTop: '1.8rem',
  m: 0,
  [theme.breakpoints.down('md')]: {
    padding: 0,
    paddingTop: '1.2rem',
  },
}));

export const StyledCardActions = styled(CardActions)(({ theme }) => ({
  paddingLeft: '1.7rem',
  paddingTop: 0,
  [theme.breakpoints.down('md')]: {
    paddingLeft: '0.8rem',
    marginTop: 24,
    marginBottom: 12,
  },
}));

export const StyledForm = styled('form')`
  padding: 32px 32px 0px 32px;
  min-width: 600px;
  min-height: 300px;
`;

export const StyledTypography = styled(Typography)`
  padding-left: 32px;
`;

export const StyledTextarea = styled(TextareaAutosize)`
  width: 100%;
  min-height: 150px;
  padding: 8px;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  resize: none;
`;

export const StyledErrorText = styled('span')<
  StyledErrorTextProps & { theme?: Theme }
>`
  ${({ size }) => {
    if (['small', 'medium'].includes(size))
      return {
        fontSize: 12,
      };
    return { fontSize: 14 };
  }};
  margin-top: 0;
  color: ${({ theme }) => theme.palette.error.main};
`;
