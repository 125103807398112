import { FormHelperText, Grid } from '@mui/material';
import CustomButton from 'components/atoms/Button';
import CustomSelect from 'components/atoms/Select';
import { useTranslation } from 'react-i18next';
import {
  StyledForm,
  StyledFormControl,
  StyledGridContainer,
  StyledTypography,
} from './styles';
import { useFormik } from 'formik';
import {
  getFarmData,
  getFormConfig,
  getIsUserTypeVet,
  getOrderFormMode,
} from 'store/features/orderForm/index.selectors';
import { setFieldValue } from 'store/features/orderForm/index.slice';
import { IAddressSelectionParams } from './types';
import {
  FIELD_ACTION_NAME,
  COUNTRY_CODE,
  FarmInfoKeys,
  InfectionInfoKeys,
  ORDER_FORM_STEP_NAMES,
} from 'constants/index';
import * as Yup from 'yup';
import StepperButtonGroup from 'components/molecules/StepperButtonGroup';
import { OrderFormStepComponentProps } from 'types';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import AddressModal from 'components/organisms/Addresses/AddressModal';
import { AddressTypes } from 'enums';
import {
  getEnvironmentSettings,
  getFieldVisibility,
  getShippingSitesSelectionModeByCountry,
} from 'utils';
import { ModeState } from 'store/features/orderForm/index.type';
import { getUserAccount } from 'store/features/auth/index.selector';
import RenderByCondition from 'components/atoms/RenderByCondition';
import {
  resetAddressManagementAll,
  resetSelectedShippingRowsForState,
  setNewOrderAddresses,
  setSelectedFarmsRows,
  setStatesDataOrder,
} from 'store/features/addressesManager/index.slice';
import {
  getNewOrderAddresses,
  getStatesDataOrder,
} from 'store/features/addressesManager/index.selectors';
import { useLazyGetIsolateByIdQuery } from 'api/orderForm';

const { IS_SHIPPING_SELECTION_MODE_MULTIPLE } =
  getShippingSitesSelectionModeByCountry();

const formName = ORDER_FORM_STEP_NAMES.FARM_INFO;

const COUNTRY_CODE_VALUE = process.env.REACT_APP_COUNTRY;

const {
  BILL_TO_VISIBILITY,
  CUSTOMER_VISIBILITY,
  SHIPPING_ADDRESS_SELECT_VISIBILITY,
} = getFieldVisibility();

const useForm = () => {
  const initialValues = useSelector(getFarmData);
  const { t } = useTranslation();

  const validationSchema = Yup.object({
    vets: Yup.object().shape({
      value: Yup.object()
        .shape({
          label: Yup.string().required(t('errorMessages.veterinarianRequired')),
          value: Yup.string().required(t('errorMessages.veterinarianRequired')),
        })
        .nullable()
        .required(t('errorMessages.veterinarianRequired')),
    }),
    nonAdjacentRequired: Yup.object().shape({
      value: Yup.boolean().required(),
    }),

    // Custom validation rule for adjacentAddresses based on isAdjacent value
    farmAddresses: Yup.object().test(
      FarmInfoKeys.NON_ADJACENT_REQUIRED,
      t('errorMessages.farmAddressesRequired'),
      function (value: any) {
        // Get the value of isAdjacent from the form
        const isNonAdjacentRequired = this.resolve(
          Yup.ref('nonAdjacentRequired.value')
        );

        // If isAdjacent is true, adjacentAddresses must have at least one address
        if (!isNonAdjacentRequired && value?.selectCount === 0) {
          return this.createError({
            path: `${FarmInfoKeys.FARM_ADDRESSES}.value`,
            message: t('errorMessages.farmAddressesRequired'),
          });
        } else if (value?.selectCount === 0) {
          return this.createError({
            path: `${FarmInfoKeys.FARM_ADDRESSES}.value`,
            message: t('errorMessages.nonAdjacentAddressesRequired'),
          });
        }

        return true; // Return true for valid value
      }
    ),
    shippingSites: Yup.object().test(
      'shippingSitesSelected',
      t('errorMessages.shippingSitesRequired'),
      function (value: any) {
        // If shippingSites is empty, return error
        if (!value || value?.selectCount === 0) {
          return this.createError({
            path: 'shippingSites.value',
            message: t('errorMessages.shippingSitesRequired'),
          });
        }
        return true; // Return true for valid value
      }
    ),
    ...(BILL_TO_VISIBILITY
      ? {
          billingAddresses: Yup.object().shape({
            value: Yup.object()
              .shape({
                label: Yup.string().required(
                  t('errorMessages.billingAddressRequired')
                ),
                value: Yup.string().required(
                  t('errorMessages.billingAddressRequired')
                ),
              })
              .nullable()
              .required(t('errorMessages.billingAddressRequired')),
          }),
        }
      : {}),
    ...(CUSTOMER_VISIBILITY
      ? {
          customer: Yup.object().shape({
            value: Yup.object()
              .shape({
                label: Yup.string().required(t('errorMessages.clinicRequired')),
                value: Yup.string().required(t('errorMessages.clinicRequired')),
              })
              .nullable()
              .required(t('errorMessages.clinicRequired')),
          }),
        }
      : {}),
  });

  const {
    values,
    setFieldValue: formikSetFieldValue,
    handleBlur,
    errors,
    touched,
    isValid,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      console.log('');
    },
    enableReinitialize: true,
    validateOnMount: true,
  });

  return {
    values,
    formikSetFieldValue,
    handleBlur,
    errors,
    touched,
    isValid,
  };
};

const Veterinary = (props) => {
  const {
    values,
    touched,
    errors,
    onChange,
    handleBlur,
    isUserTypeVet,
    isFiledDisabled,
  } = props;
  const apiConfiguration = useSelector(getFormConfig);
  const isVetUser = useSelector(getIsUserTypeVet);
  const user = useSelector(getUserAccount);
  const { t } = useTranslation();
  const orderFormMode = useSelector(getOrderFormMode);
  const [getIsolate] = useLazyGetIsolateByIdQuery();

  const IS_ORDER_CREATION = orderFormMode == ModeState.ORDER;

  const vets = useMemo(() => {
    return values?.vets?.data?.map((vet) => ({
      ...vet,
      label: vet?.userName,
      value: vet?.userName,
    }));
  }, [values]);

  useEffect(() => {
    const currentVet = vets?.find((vet) => vet?.id === user?.id);

    if (isVetUser && !values?.vets?.value?.value && IS_ORDER_CREATION) {
      setFieldsValue({ target: { name: FarmInfoKeys.VETS } }, currentVet);
    }
  }, [vets, user]);

  const setFieldsValue = (event, value) => {
    const vetName = value?.userName;

    const customersForTargetVet = apiConfiguration?.customers.filter(
      (customer) => {
        return customer.vets.some((vet) => vet.userName === vetName);
      }
    );

    const customerValue =
      customersForTargetVet?.length === 1
        ? {
            ...customersForTargetVet?.[0],
            label: customersForTargetVet?.[0]?.name,
            value: customersForTargetVet?.[0]?.name,
          }
        : { label: '', value: '' };

    const actions: any = [
      {
        key: FarmInfoKeys.CUSTOMER,
        name: FIELD_ACTION_NAME.RESET,
        formName: ORDER_FORM_STEP_NAMES.FARM_INFO,
        value: customerValue,
      },
      {
        key: FarmInfoKeys.CUSTOMER,
        name: FIELD_ACTION_NAME.SET,
        formName: ORDER_FORM_STEP_NAMES.FARM_INFO,
        data: customersForTargetVet,
      },
      {
        key: FarmInfoKeys.BILLING_ADDRESSES,
        name: FIELD_ACTION_NAME.RESET,
        formName: ORDER_FORM_STEP_NAMES.FARM_INFO,
        value: customerValue,
      },
      {
        key: FarmInfoKeys.BILLING_ADDRESSES,
        name: FIELD_ACTION_NAME.SET,
        formName: ORDER_FORM_STEP_NAMES.FARM_INFO,
        data: customersForTargetVet,
      },
      {
        key: FarmInfoKeys.FARM_ADDRESSES,
        name: FIELD_ACTION_NAME.RESET,
        formName: ORDER_FORM_STEP_NAMES.FARM_INFO,
        value: [],
        totalCount: 0,
        selectCount: 0,
      },
      {
        key: FarmInfoKeys.SHIPPING_SITES,
        name: FIELD_ACTION_NAME.RESET,
        formName: ORDER_FORM_STEP_NAMES.FARM_INFO,
        value: [],
        totalCount: 0,
        selectCount: 0,
      },
    ];

    if (customersForTargetVet?.length === 1) {
      actions.push(
        {
          key: FarmInfoKeys.FARM_ADDRESSES,
          name: FIELD_ACTION_NAME.RESET,
          formName: ORDER_FORM_STEP_NAMES.FARM_INFO,
          value: [],
          totalCount: 0,
          selectCount: 0,
        },
        {
          key: FarmInfoKeys.FARM_ADDRESSES,
          name: FIELD_ACTION_NAME.SET,
          formName: ORDER_FORM_STEP_NAMES.FARM_INFO,
          data: customersForTargetVet?.[0]?.farms,
        },
        {
          key: FarmInfoKeys.SHIPPING_SITES,
          name: FIELD_ACTION_NAME.RESET,
          formName: ORDER_FORM_STEP_NAMES.FARM_INFO,
          value: [],
          totalCount: 0,
          selectCount: 0,
        },
        {
          key: FarmInfoKeys.SHIPPING_SITES,
          name: FIELD_ACTION_NAME.SET,
          formName: ORDER_FORM_STEP_NAMES.FARM_INFO,
          data: customersForTargetVet?.[0]?.shippingAddresses,
        }
      );
      getIsolate({ customerId: customersForTargetVet?.[0]?.id, farmId: '' });
    }

    onChange(event, value, actions);
  };

  const vetOnChange = (e, value) => {
    setFieldsValue(e, value);
  };

  const isError = touched?.vets && Boolean(errors?.vets);
  const errorMessage: string = errors?.vets?.value?.label;

  const isDisabled = isFiledDisabled(isUserTypeVet);

  return (
    <Grid item xs={12}>
      <StyledFormControl>
        <CustomSelect
          label={t('orderForm.attendingVeterinarian') + ' *'}
          options={vets}
          onChange={vetOnChange}
          name={FarmInfoKeys.VETS}
          value={values?.vets?.value}
          onBlur={() => handleBlur({ target: { id: FarmInfoKeys.VETS } })}
          disabled={isDisabled}
          error={isError}
          errorMessage={errorMessage}
        />
      </StyledFormControl>
    </Grid>
  );
};

const Addresses = (props) => {
  const {
    values,
    touched,
    errors,
    handleBlur,
    isAddressModalOpen,
    isFiledDisabled,
    setIsAddressModalOpen,
    handleCloseShippingModal,
    onSelectDone,
    statesData,
    setStatesData,
    orderAddresses,
    setOrderAddresses,
    addressesByState,
    setAddressesByState,
    handleValueChange,
    handleStateDataChange,
    value,
    custmerId,
    reorderFarmIds,
    apiConfiguration,
  } = props;

  const orderFormMode = useSelector(getOrderFormMode);
  const IS_REORDER_MODE = orderFormMode == ModeState.REORDER;

  const isNonAdjacentRequired = values?.nonAdjacentRequired?.value;

  const { t } = useTranslation();

  const { SELECTED_ADDRESSE_VISIBILITY } = getEnvironmentSettings();

  const getAddressType = () => {
    if (
      COUNTRY_CODE_VALUE === COUNTRY_CODE.US &&
      values?.nonAdjacentRequired?.value
    ) {
      return t('orderForm.noAdjacent');
    }

    return t('orderForm.farm');
  };

  const isError = touched?.farmAddresses && errors.farmAddresses;
  const errorMessage = errors?.farmAddresses?.value;

  const addressesTotalCount = values?.farmAddresses?.totalCount;

  const addressesSelectCount = values?.farmAddresses?.selectCount;

  const isAdjacentAddressesSelected = Boolean(
    values?.farmAddresses?.value?.length
  );

  const isNonAdjacentAddressesSelected = Boolean(
    values?.farmAddresses?.value?.length
  );

  const isMultiaddressesSelected = Boolean(
    values?.farmAddresses?.value?.length > 1
  );

  const isAddressesSelected =
    isAdjacentAddressesSelected || isNonAdjacentAddressesSelected;

  const buttonLabelColor = isAddressesSelected ? 'primary' : 'neutral.800';

  const selectedFarmAddress = values?.farmAddresses?.value?.[0];

  const addressDetails = selectedFarmAddress
    ? IS_REORDER_MODE
      ? `${selectedFarmAddress?.name}, ${selectedFarmAddress?.address?.zipCode} ${selectedFarmAddress?.address?.city}`
      : `${selectedFarmAddress?.name}, ${selectedFarmAddress?.zipCode} ${selectedFarmAddress?.city}`
    : '';

  const buttonVariant =
    isAddressesSelected || isError ? 'outlined' : 'contained';

  const addressesSelectionFieldTitle = SELECTED_ADDRESSE_VISIBILITY
    ? addressDetails
    : t('orderForm.selectAddressFilled');

  const buttonContent = isAddressesSelected
    ? addressesSelectionFieldTitle
    : t('orderForm.selectAddress');

  const buttonColor = isError ? 'error' : 'primary';

  const getFarmAddresses = useMemo(() => {
    return apiConfiguration?.customers?.find(
      (customer) => customer?.id === custmerId
    )?.farms;
  }, [values, apiConfiguration, custmerId]);

  const isDisabled = BILL_TO_VISIBILITY
    ? isFiledDisabled(Boolean(errors?.vets || errors?.billingAddresses))
    : isFiledDisabled(Boolean(errors?.vets || errors?.customer));

  return (
    <>
      <Grid item xs={12}>
        <RenderByCondition show={[!isMultiaddressesSelected]}>
          <StyledTypography variant="h6" color={buttonLabelColor}>
            {isAddressesSelected
              ? t('orderForm.farmAndAddressFilled', {
                  addressType: getAddressType(),
                  count: addressesSelectCount,
                }) + ' *'
              : t('orderForm.farmAndAddress', {
                  addressType: getAddressType(),
                }) + ' *'}
          </StyledTypography>
        </RenderByCondition>
        <RenderByCondition show={[isMultiaddressesSelected]}>
          <StyledTypography variant="h6" color={buttonLabelColor}>
            {isAddressesSelected
              ? t('orderForm.farmsAndAddressesFilled', {
                  addressType: getAddressType(),
                  count: addressesSelectCount,
                }) + ' *'
              : t('orderForm.farmAndAddress', {
                  addressType: getAddressType(),
                }) + ' *'}
          </StyledTypography>
        </RenderByCondition>

        <CustomButton
          variant={buttonVariant}
          color={buttonColor}
          onClick={() => {
            handleBlur({ target: { id: FarmInfoKeys.FARM_ADDRESSES } });
            setIsAddressModalOpen((prev) => !prev);
          }}
          disabled={isDisabled}
        >
          {buttonContent}
        </CustomButton>
        {touched?.farmAddresses && errors.farmAddresses && (
          <FormHelperText error>{errorMessage}</FormHelperText>
        )}
      </Grid>

      <AddressModal
        isOpen={isAddressModalOpen}
        addressData={getFarmAddresses}
        addressType={
          isNonAdjacentRequired ? AddressTypes.Proximity : AddressTypes.Main
        }
        editMode={true}
        onClose={handleCloseShippingModal}
        onSelectDone={onSelectDone}
        modePreviewTotalCount={addressesTotalCount}
        modePreviewSelectCount={addressesSelectCount}
        selectType={isNonAdjacentRequired ? 'multiple' : 'single'}
        statesData={statesData}
        setStatesData={setStatesData}
        orderAddresses={orderAddresses}
        setOrderAddresses={setOrderAddresses}
        addressesByState={addressesByState}
        setAddressesByState={setAddressesByState}
        isShippingAddresse={true}
        activeTab={0}
        value={value}
        onValueChange={handleValueChange}
        onStateDataChange={handleStateDataChange}
        isOrderDetails={false}
        custmerId={custmerId}
        reorderFarmIds={reorderFarmIds}
      />
    </>
  );
};

const Customer = (props) => {
  const {
    errors,
    touched,
    values,
    handleBlur,
    onChange,
    isFiledDisabled,
    //handleSelectCustomer,
  } = props;
  const errorMessage: string = errors?.customer?.value?.label;
  const isError = touched?.customer && Boolean(errors?.customer);
  const orderFormMode = useSelector(getOrderFormMode);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [getIsolate] = useLazyGetIsolateByIdQuery();

  const IS_REORDER_MODE = orderFormMode == ModeState.REORDER;

  const customersOptions = useMemo(() => {
    return values?.customer?.data?.map((customer) => ({
      ...customer,
      label: customer.name,
      value: customer.id,
    }));
  }, [values]);

  const customerOnChange = (e, value) => {
    const farms = value?.farms;

    const shippingAddresses = value?.shippingAddresses;

    const actions: any = [
      {
        key: FarmInfoKeys.FARM_ADDRESSES,
        name: FIELD_ACTION_NAME.RESET,
        formName: ORDER_FORM_STEP_NAMES.FARM_INFO,
        value: [],
      },
      {
        key: FarmInfoKeys.FARM_ADDRESSES,
        name: FIELD_ACTION_NAME.SET,
        formName: ORDER_FORM_STEP_NAMES.FARM_INFO,
        data: farms,
      },
      {
        key: FarmInfoKeys.SHIPPING_SITES,
        name: FIELD_ACTION_NAME.RESET,
        formName: ORDER_FORM_STEP_NAMES.FARM_INFO,
        value: [],
        totalCount: 0,
        selectCount: 0,
        totalStateCount: 0,
      },
      {
        key: FarmInfoKeys.SHIPPING_SITES,
        name: FIELD_ACTION_NAME.SET,
        formName: ORDER_FORM_STEP_NAMES.FARM_INFO,
        data: shippingAddresses,
      },
    ];

    if (!IS_REORDER_MODE) {
      actions.push({
        key: InfectionInfoKeys.ISOLATES,
        name: FIELD_ACTION_NAME.RESET,
        formName: ORDER_FORM_STEP_NAMES.INFECTION_INFO,
        value: [],
      });
    }

    dispatch(resetAddressManagementAll());

    getIsolate({ customerId: value?.id, farmId: '' });

    onChange(e, value, actions);
  };

  const isDisabled = isFiledDisabled(Boolean(errors?.vets));

  return (
    <Grid item xs={12}>
      <StyledFormControl>
        <CustomSelect
          label={t('orderForm.customer') + ' *'}
          options={customersOptions}
          onChange={customerOnChange}
          name={FarmInfoKeys.CUSTOMER}
          value={values?.customer?.value}
          onBlur={() => handleBlur({ target: { id: FarmInfoKeys.CUSTOMER } })}
          disabled={isDisabled}
          error={isError}
          errorMessage={errorMessage}
        />
      </StyledFormControl>
    </Grid>
  );
};

const Shipping = (props) => {
  const {
    values,
    touched,
    errors,
    handleBlur,
    isFiledDisabled,
    onSelectDone,
    statesData,
    setStatesData,
    orderAddresses,
    setOrderAddresses,
    addressesByState,
    setAddressesByState,
    handleValueChange,
    handleStateDataChange,
    setIsShippingAddressOpen,
    isShippingAddressOpen,
    selectedStateId,
    setSelectedStateId,
    value,
    custmerId,
    apiConfiguration,
  } = props;
  const newOrderAddresses = useSelector(getNewOrderAddresses);

  const isShippingSiteSelected = Boolean(newOrderAddresses?.length);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isError = touched?.shippingSites && errors.shippingSites;
  const errorMessage = errors?.shippingSites?.value;

  const buttonText = isShippingSiteSelected
    ? t('orderForm.selectShippingAddressFilled')
    : t('orderForm.selectAddressShippingSite');

  const buttonVariant =
    isShippingSiteSelected || isError ? 'outlined' : 'contained';

  const statesDataOrder = useSelector(getStatesDataOrder);

  const [totalSelected, setTotalSelected] = useState<number>(0);

  useEffect(() => {
    if (newOrderAddresses?.length == 0 || statesDataOrder?.length == 0) {
      setTotalSelected(0);
    } else {
      setTotalSelected(newOrderAddresses?.length);
    }
  }, [newOrderAddresses, statesDataOrder]);

  const buttonLabel = isShippingSiteSelected
    ? t('orderForm.shippingAddressFilled', {
        count: totalSelected,
      })
    : t('orderForm.shippingAddress') + ' *';

  const onClick = () => {
    handleBlur({ target: { id: FarmInfoKeys.SHIPPING_SITES } });
    setIsShippingAddressOpen((prev) => !prev);
  };

  const buttonLabelColor = !isShippingSiteSelected ? 'primary' : 'neutral.800';

  const buttonColor = isError ? 'error' : 'primary';

  const shippingAddresses = useMemo(() => {
    return apiConfiguration?.customers
      ?.find((customer) => customer?.id === custmerId)
      ?.shippingAddresses?.map((address) => ({ address: address }));
  }, [values, apiConfiguration]);

  useEffect(() => {
    if (
      !values?.farmAddresses?.value ||
      values?.farmAddresses?.value?.length === 0
    ) {
      dispatch(resetSelectedShippingRowsForState());
      dispatch(setNewOrderAddresses([]));
      dispatch(setStatesDataOrder([]));
    }
  }, [values?.farmAddresses?.value]);

  return (
    <>
      <Grid item xs={12}>
        <StyledTypography variant="h6" color={buttonLabelColor}>
          {buttonLabel}
        </StyledTypography>
        <CustomButton
          variant={buttonVariant}
          color={buttonColor}
          onClick={onClick}
          disabled={isFiledDisabled(
            !values?.farmAddresses?.value ||
              values?.farmAddresses?.value?.length === 0
          )}
        >
          {buttonText}
        </CustomButton>
        {touched?.shippingSites && errors.shippingSites && (
          <FormHelperText error>{errorMessage}</FormHelperText>
        )}
      </Grid>

      <AddressModal
        isOpen={isShippingAddressOpen}
        addressData={shippingAddresses}
        addressType={AddressTypes.Shipping}
        editMode={true}
        onClose={() => setIsShippingAddressOpen(false)}
        onSelectDone={onSelectDone}
        modePreviewTotalCount={values?.shippingSites?.totalCount}
        modePreviewSelectCount={values?.shippingSites?.selectCount}
        selectType={IS_SHIPPING_SELECTION_MODE_MULTIPLE ? 'multiple' : 'single'}
        statesData={statesData}
        setStatesData={setStatesData}
        orderAddresses={orderAddresses}
        setOrderAddresses={setOrderAddresses}
        addressesByState={addressesByState}
        setAddressesByState={setAddressesByState}
        value={value}
        onValueChange={handleValueChange}
        onStateDataChange={handleStateDataChange}
        isShippingAddresse={false}
        activeTab={1}
        selectedStateId={selectedStateId}
        setSelectedStateId={setSelectedStateId}
        custmerId={custmerId}
      />
    </>
  );
};

const BillTo = (props) => {
  const { errors, touched, values, handleBlur, isFiledDisabled, onChange } =
    props;
  const errorMessage = errors?.billingAddresses?.value?.label;
  const isError = touched.billingAddresses && Boolean(errors?.billingAddresses);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const billingOptions = useMemo(() => {
    return values?.customer?.data?.map((customer) => ({
      ...customer,
      value: customer?.name,
      label: customer?.name,
    }));
  }, [values]);

  const onBillChange = (event, value) => {
    const shippingAddresses = value?.shippingAddresses;

    const farms = value?.farms;

    const actions: any = [
      {
        key: FarmInfoKeys.FARM_ADDRESSES,
        name: FIELD_ACTION_NAME.RESET,
        formName: ORDER_FORM_STEP_NAMES.FARM_INFO,
        value: [],
        totalCount: 0,
        selectCount: 0,
      },
      {
        key: FarmInfoKeys.FARM_ADDRESSES,
        name: FIELD_ACTION_NAME.SET,
        formName: ORDER_FORM_STEP_NAMES.FARM_INFO,
        data: farms,
      },
      {
        key: FarmInfoKeys.SHIPPING_SITES,
        name: FIELD_ACTION_NAME.RESET,
        formName: ORDER_FORM_STEP_NAMES.FARM_INFO,
        value: [],
        totalCount: 0,
        selectCount: 0,
      },
      {
        key: FarmInfoKeys.SHIPPING_SITES,
        name: FIELD_ACTION_NAME.SET,
        formName: ORDER_FORM_STEP_NAMES.FARM_INFO,
        data: shippingAddresses,
      },
    ];

    dispatch(resetAddressManagementAll());

    onChange(event, value, actions);
  };

  const isDisabled = isFiledDisabled(Boolean(errors?.vets));

  return (
    <Grid item xs={12}>
      <StyledFormControl>
        <CustomSelect
          label={t('orderForm.billingAddress') + ' *'}
          options={billingOptions}
          onChange={onBillChange}
          name={FarmInfoKeys.BILLING_ADDRESSES}
          value={values?.billingAddresses?.value}
          onBlur={() =>
            handleBlur({ target: { id: FarmInfoKeys.BILLING_ADDRESSES } })
          }
          disabled={isDisabled}
          error={isError}
          errorMessage={errorMessage}
        />
      </StyledFormControl>
    </Grid>
  );
};

const ShippingAddress = (props) => {
  const { errors, touched, values, handleBlur, isFiledDisabled, onChange } =
    props;
  const errorMessage = errors?.shippingSites?.value;
  const isError = touched.shippingSites && Boolean(errors?.shippingSites);
  const { t } = useTranslation();

  const shippingSitesOptions = useMemo(() => {
    return values?.shippingSites?.data?.map((address) => ({
      ...address,
      value: address?.name,
      label: `${address.name} - ${address.street}, ${address.city}, ${address.country}`,
    }));
  }, [values]);

  const isDisabled = isFiledDisabled(!values?.billingAddresses?.value?.value);

  const shippingOnSiteChange = (event, value) => {
    const actions = [
      {
        key: FarmInfoKeys.SHIPPING_SITES,
        name: FIELD_ACTION_NAME.SET,
        formName: ORDER_FORM_STEP_NAMES.FARM_INFO,
        selectCount: 1,
        totalCount: 1,
      },
    ];
    onChange(event, [value], actions);
  };

  const value = values?.shippingSites?.value?.[0]
    ? values?.shippingSites?.value?.[0]
    : { label: '', value: '' };

  return (
    <Grid item xs={12}>
      <StyledFormControl>
        <CustomSelect
          label={t('orderForm.shippingAddress') + ' *'}
          options={shippingSitesOptions}
          onChange={shippingOnSiteChange}
          name={FarmInfoKeys.SHIPPING_SITES}
          value={value}
          onBlur={() =>
            handleBlur({ target: { id: FarmInfoKeys.SHIPPING_SITES } })
          }
          disabled={isDisabled}
          error={isError}
          errorMessage={errorMessage}
        />
      </StyledFormControl>
    </Grid>
  );
};

const StepperButton = (props) => {
  const { goNext, goBack, onCancel, isValid, isFiledDisabled } = props;

  return (
    <Grid item xs={12} sx={{ mt: 6 }}>
      <StepperButtonGroup
        goNext={goNext}
        goBack={goBack}
        onCancel={onCancel}
        showNext={true}
        showBackBtn={false}
        showCancelBtn={true}
        nextBtnDisabled={isFiledDisabled(!isValid)}
      />
    </Grid>
  );
};

const FarmAndAddresses: React.FC<OrderFormStepComponentProps> = (props) => {
  const { goNext, goBack, onCancel, isFormDisabled } = props;
  const isUserTypeVet = useSelector(getIsUserTypeVet);
  const dispatch = useDispatch();
  const formFieldData = useSelector(getFarmData);
  const { values, formikSetFieldValue, handleBlur, errors, touched, isValid } =
    useForm();
  const apiConfiguration = useSelector(getFormConfig);
  const isNonAdjacentRequired = values?.nonAdjacentRequired?.value;
  const [getIsolate] = useLazyGetIsolateByIdQuery();

  const getStateData = (stateId) =>
    apiConfiguration?.states?.find((state) => state.id === stateId);

  const onChange = (e: any, value: any, actions = []) => {
    const fieldName = e.target.name;
    const propPathValue = `${fieldName}.value`;

    formikSetFieldValue(propPathValue, value);

    dispatch(
      setFieldValue({ propPath: propPathValue, value, formName, actions })
    );
  };

  const setFormikFieldValuesFromObject = (
    fieldName,
    obj,
    formName = ORDER_FORM_STEP_NAMES.FARM_INFO
  ) => {
    for (const key in obj) {
      const value = obj[key];
      const propPath = `${fieldName}.${key}`;

      formikSetFieldValue(propPath, value);
      dispatch(setFieldValue({ propPath, value, formName }));
    }
  };

  const setFieldToEmptyForDependencies = (propPathValue, value, formName) => {
    formikSetFieldValue(propPathValue, value);
    dispatch(setFieldValue({ propPath: propPathValue, value, formName }));
  };

  const setFarmsRows = (selectedAddresses) => {
    const currentFarms = selectedAddresses || [];
    const ids: number[] = [];

    currentFarms?.forEach((farm) => {
      ids.push(farm?.id);
    });
    const farmsSet = new Set(ids);

    dispatch(setSelectedFarmsRows(Array.from(farmsSet)));
  };

  const onSelectDone = ({
    data,
    shippingAddressesByState,
    totalCount,
    selectCount,
    addressType,
    totalStateCount,
  }: IAddressSelectionParams) => {
    if (
      addressType === AddressTypes.Main ||
      addressType === AddressTypes.Proximity
    ) {
      setFarmsRows(data);

      const actions: any = [
        {
          key: FarmInfoKeys.FARM_ADDRESSES,
          name: FIELD_ACTION_NAME.SET,
          formName: ORDER_FORM_STEP_NAMES.FARM_INFO,
          value: data,
          totalCount: totalCount,
          selectCount: selectCount,
        },
      ];

      if (Array.isArray(data) && data[0] && !isNonAdjacentRequired) {
        getIsolate({ customerId: selectedCustomerId, farmId: data[0]?.id });

        actions.push(
          {
            key: InfectionInfoKeys.ISOLATES,
            name: FIELD_ACTION_NAME.RESET,
            formName: ORDER_FORM_STEP_NAMES.INFECTION_INFO,
            value: [],
          },
          {
            key: InfectionInfoKeys.ANIMAL_SPECIES,
            name: FIELD_ACTION_NAME.RESET,
            formName: ORDER_FORM_STEP_NAMES.INFECTION_INFO,
            value: { label: '', value: '' },
          }
        );
      }

      onChange(
        { target: { name: FarmInfoKeys.FARM_ADDRESSES } },
        data,
        actions
      );
    } else if (addressType === AddressTypes.Shipping)
      setFormikFieldValuesFromObject(FarmInfoKeys.SHIPPING_SITES, {
        value: data,
        totalCount: totalCount,
        selectCount: selectCount,
      });
    setFormikFieldValuesFromObject(FarmInfoKeys.SHIPPING_SITES_BY_STATES, {
      value: shippingAddressesByState,
      totalCount: totalCount,
      selectCount: selectCount,
      totalStateCount: totalStateCount,
    });
    setIsAddressModalOpen(false);
    setIsShippingAddressOpen(false);
  };

  const isFiledDisabled = (...additionalConditions) => {
    return [isFormDisabled, ...additionalConditions].some(
      (condition) => condition
    );
  };

  const [addressesByState, setAddressesByState] = useState<any[]>([]);

  const [statesData, setStatesData] = useState<any[]>([]);
  const [orderAddresses, setOrderAddresses] = useState<any[]>([]);

  const [value, setValue] = useState<number>(0);

  const handleValueChange = useCallback((newValue: number) => {
    setValue(newValue);
  }, []);

  const [selectedStateId, setSelectedStateId] = useState<string | null>(null);

  const handleStateDataChange = useCallback((newValue: any[]) => {
    setStatesData(newValue);
  }, []);

  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [isShippingAddressOpen, setIsShippingAddressOpen] = useState(false);

  const selectedCustomerId = BILL_TO_VISIBILITY
    ? values?.billingAddresses?.value?.id
    : values?.customer?.value?.id;

  const handleCloseShippingModal = () => {
    setIsAddressModalOpen(false);
  };

  return (
    <StyledForm>
      <StyledGridContainer container rowGap={5}>
        <Veterinary
          values={values}
          touched={touched}
          errors={errors}
          onChange={onChange}
          setFormikFieldValuesFromObject={setFormikFieldValuesFromObject}
          setFieldToEmptyForDependencies={setFieldToEmptyForDependencies}
          handleBlur={handleBlur}
          isUserTypeVet={isUserTypeVet}
          isFiledDisabled={isFiledDisabled}
        />

        <RenderByCondition show={[CUSTOMER_VISIBILITY]}>
          <Customer
            errors={errors}
            touched={touched}
            values={values}
            handleBlur={handleBlur}
            onChange={onChange}
            setFieldToEmptyForDependencies={setFieldToEmptyForDependencies}
            isFiledDisabled={isFiledDisabled}
            formFieldData={formFieldData}
          />
        </RenderByCondition>

        <RenderByCondition show={[BILL_TO_VISIBILITY]}>
          <BillTo
            errors={errors}
            touched={touched}
            values={values}
            handleBlur={handleBlur}
            isFiledDisabled={isFiledDisabled}
            onChange={onChange}
          />
        </RenderByCondition>

        <Addresses
          values={values}
          touched={touched}
          errors={errors}
          getStateData={getStateData}
          handleBlur={handleBlur}
          isAddressModalOpen={isAddressModalOpen}
          isFiledDisabled={isFiledDisabled}
          setIsAddressModalOpen={setIsAddressModalOpen}
          handleCloseShippingModal={handleCloseShippingModal}
          onSelectDone={onSelectDone}
          statesData={statesData}
          setStatesData={setStatesData}
          orderAddresses={orderAddresses}
          setOrderAddresses={setOrderAddresses}
          addressesByState={addressesByState}
          setAddressesByState={setAddressesByState}
          handleValueChange={handleValueChange}
          handleStateDataChange={handleStateDataChange}
          value={value}
          custmerId={selectedCustomerId}
          reorderFarmIds={[]}
          apiConfiguration={apiConfiguration}
        />

        <RenderByCondition show={[SHIPPING_ADDRESS_SELECT_VISIBILITY]}>
          <ShippingAddress
            errors={errors}
            touched={touched}
            values={values}
            handleBlur={handleBlur}
            isFiledDisabled={isFiledDisabled}
            onChange={onChange}
          />
        </RenderByCondition>

        <RenderByCondition show={[!SHIPPING_ADDRESS_SELECT_VISIBILITY]}>
          <Shipping
            values={values}
            touched={touched}
            errors={errors}
            getStateData={getStateData}
            handleBlur={handleBlur}
            isFiledDisabled={isFiledDisabled}
            onSelectDone={onSelectDone}
            statesData={statesData}
            setStatesData={setStatesData}
            orderAddresses={orderAddresses}
            setOrderAddresses={setOrderAddresses}
            addressesByState={addressesByState}
            setAddressesByState={setAddressesByState}
            handleValueChange={handleValueChange}
            handleStateDataChange={handleStateDataChange}
            setIsShippingAddressOpen={setIsShippingAddressOpen}
            isShippingAddressOpen={isShippingAddressOpen}
            selectedStateId={selectedStateId}
            setSelectedStateId={setSelectedStateId}
            value={value}
            custmerId={selectedCustomerId}
            apiConfiguration={apiConfiguration}
          />
        </RenderByCondition>

        <StepperButton
          goNext={goNext}
          goBack={goBack}
          onCancel={onCancel}
          isFiledDisabled={isFiledDisabled}
          isValid={isValid}
        />
      </StyledGridContainer>
    </StyledForm>
  );
};

export default FarmAndAddresses;
