import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ErrorType = { error: null | { status: number; message: string } };
const initialState: ErrorType = {
  error: null,
};

const apiErrorSlice = createSlice({
  name: 'apiErrorSlice',
  initialState,
  reducers: {
    setApiError: (state, action: PayloadAction<ErrorType['error']>) => {
      state.error = action.payload;
    },
  },
});

export const { setApiError } = apiErrorSlice.actions;

export default apiErrorSlice.reducer;
