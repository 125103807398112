import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthState, Token } from './index.type';

const initialState: any = {
  account: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccount: (state, action: PayloadAction<AuthState['account']>) => {
      state.account = action.payload;
    },
    setUserRole: (
      state,
      action: PayloadAction<AuthState['account']['role']>
    ) => {
      state.account.role = action.payload;
    },
    setUserId: (state, action: PayloadAction<AuthState['account']['id']>) => {
      state.account.id = action.payload;
    },
    setUserName: (
      state,
      action: PayloadAction<AuthState['account']['userName']>
    ) => {
      state.account.userName = action.payload;
    },
    setToken: (state, action: PayloadAction<Token>) => {
      state.account.token = action.payload.token;
    },
  },
});

export const { setAccount, setUserRole, setUserId, setUserName, setToken } =
  authSlice.actions;

export default authSlice.reducer;
