import { CircularProgress, useTheme } from '@mui/material';
import StyledButton from './styles';
import { CustomButtonProps } from './types';

const CustomButton: React.FC<CustomButtonProps> = ({
  children,
  variant = 'contained',
  size = 'medium',
  color = 'primary',
  loading = false,
  ...rest
}) => {
  const theme = useTheme();
  return (
    <StyledButton size={size} variant={variant} color={color} {...rest}>
      {loading ? (
        <CircularProgress
          size={20}
          sx={{
            color:
              variant === 'outlined'
                ? theme.palette.primary.main
                : theme.palette.neutral['50'],
          }}
        />
      ) : (
        children
      )}
    </StyledButton>
  );
};

export default CustomButton;
