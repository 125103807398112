import React from 'react';
import { TextBadgeProps } from './types';
import { StyledContainer, StyledTypography } from './styles';
import { useTheme } from '@mui/material/styles';

const TextBadge: React.FC<TextBadgeProps> = ({
  name,
  color,
  variant = 'body3',
  onClick,
  active,
  IconComponent,
  sx = {},
}) => {
  const theme = useTheme();
  return (
    <StyledContainer
      color={
        active ? theme.palette.info[500] : color || theme.palette.neutral[800]
      }
      onClick={onClick}
      style={{ cursor: onClick ? 'pointer' : 'default' }}
      sx={{ ...sx }}
    >
      <StyledTypography variant={variant}>
        {IconComponent && (
          <span style={{ marginRight: '6px' }}>
            <IconComponent />
          </span>
        )}
        {name}
      </StyledTypography>
    </StyledContainer>
  );
};

export default TextBadge;
