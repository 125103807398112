import { SVGProps } from 'react';
const DropIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <path
      fill="#29306B"
      d="m18.09 12.7-.68 1.014h5.18l-.68-1.014a.292.292 0 0 0-.239-.128h-3.346a.283.283 0 0 0-.24.128h.004Zm5.25-.95 1.31 1.964h2.493c.475 0 .857.383.857.857a.855.855 0 0 1-.857.858h-.286v10.857A2.856 2.856 0 0 1 24 29.143h-8a2.856 2.856 0 0 1-2.857-2.857V15.429h-.286a.855.855 0 0 1-.857-.857c0-.475.382-.858.857-.858h2.493l1.31-1.968c.372-.553.997-.889 1.665-.889h3.346c.668 0 1.293.336 1.665.89l.003.003Zm-8.483 3.679v10.857c0 .632.51 1.143 1.143 1.143h8c.632 0 1.143-.511 1.143-1.143V15.429H14.857Zm2.857 2.285v7.429a.573.573 0 0 1-.571.571.573.573 0 0 1-.572-.571v-7.429c0-.314.258-.571.572-.571.314 0 .571.257.571.571Zm2.857 0v7.429a.573.573 0 0 1-.571.571.573.573 0 0 1-.571-.571v-7.429c0-.314.257-.571.571-.571.314 0 .571.257.571.571Zm2.858 0v7.429a.573.573 0 0 1-.572.571.573.573 0 0 1-.571-.571v-7.429c0-.314.257-.571.571-.571.314 0 .572.257.572.571Z"
    />
  </svg>
);
export default DropIcon;
