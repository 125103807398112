import { Menu } from '@mui/material';
import React from 'react';
import {
  StyledAvatar,
  StyledButton,
  StyledDivider,
  StyledIconBox,
  StyledMenuItem,
} from './styles';
import { ProfileMenuProps } from './types';
import { useNavigate } from 'react-router-dom';

export const ProfileMenu: React.FC<ProfileMenuProps> = ({ menuItems }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <StyledButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        data-testid="basic-button"
      >
        <StyledAvatar color="neutral" data-testid="profile-avatar" />
      </StyledButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        data-testid="profile-menu"
      >
        {menuItems?.map((option) => (
          <>
            <StyledMenuItem
              onClick={() => {
                handleClose();
                option.onClick && option.onClick();
                navigate(option.redirectUrl);
              }}
            >
              <StyledIconBox>{option.icon}</StyledIconBox>
              <span>{option.label}</span>
            </StyledMenuItem>
            {option.hasDivider && <StyledDivider />}
          </>
        ))}
      </Menu>
    </div>
  );
};

export default ProfileMenu;
