import { api } from 'config/api';
import { Customer } from './orderForm';
export interface QueryParams {
  pageSize?: number;
  pageNumber?: number;
  searchKey?: string;
  sortCriteria?: SortCriteria[];
  customerId?: number;
  farmId?: number;
}

export interface SortCriteria {
  propertyName: string;
  direction: number;
}

export interface IsolateResponse {
  customerDtos: CustomerDto[];
  farmDtos: FarmDto[];
  items: Item[];
  totalItems: number;
  totalPages: number;
  pageNumber: number;
  pageSize: number;
}

export interface CustomerDto {
  id: number;
  name: string;
  type: number;
  mainAddress: any;
  billingAddress: any;
}

export interface FarmDto {
  id: number;
  officialNumber: string;
  name: string;
  capacity: number;
  complex: string;
  addressId: number;
  address: any;
}

export enum ExtensionDocumentStatus {
  Sent,
  Denied,
  Approved,
  None,
}

export enum ExtensionErrorType {
  None = 'None',
  NeverUsed = 'NeverUsed',
  AlreadyExtended = 'AlreadyExtended',
  AlreadyRequested = 'AlreadyRequested',
}

export enum ShareIsolateRequestStatus {
  Sent,
  Denied,
  Approved,
  None,
}

export interface Item {
  originFarm: OriginFarm;
  originCustomer: OriginCustomer;
  animalSpecies: AnimalSpecies;
  id: number;
  name: string;
  reference: string;
  seroType: string;
  canBeBoosted: boolean;
  expirationDate: string;
  isolationDate: string;
  status: number;
  extendable: boolean;
  type: 0 | 1;
  isolateExtensionRequest: {
    id: number;
    status: ExtensionDocumentStatus;
    extensionRequestIsolatesLength: number;
  } | null;
  isolateExtensionRequestId: number | null;
  extensionError: ExtensionErrorType;
  canBeShared: boolean;
  isolateShareRequests: Array<{
    id: number;
    isolateId: number;
    isolate: any;
    date: string;
    status: number;
    comment: string;
    customerId: number;
    customer: {
      id: number;
      name: string;
    };
  }>;
}

export interface OriginFarm {
  id: number;
  officialNumber: string;
  name: string;
  capacity: number;
  complex: string;
  addressId: number;
  address: any;
}

export interface OriginCustomer {
  id: number;
  name: string;
  type: number;
  mainAddress: any;
  billingAddress: any;
}

export interface AnimalSpecies {
  name: string;
  animalGroupId: number;
  animalGroup: any;
  products: any[];
  authorizedIsolates: any[];
  id: number;
}

export interface IsolateExtensionQueryParams {
  isolatesIds?: number[];
  vetId?: number;
}

export interface GetExtensionRequestQueryParams {
  id: number;
}

interface IsolateForExtensionItem {
  id: number;
  name: string;
  expirationDate: string;
  serialNumbers: string[];
}

export interface IsolateExtensionData {
  isolateForExtension: IsolateForExtensionItem[];
  extensionRequestDoctBase64: string;
  comment: string | null;
  vetsOption: Array<{
    id: number;
    userName: string;
  }>;
}

export interface IsolateExtensionResponse {
  data: IsolateExtensionData;
}

export interface ExtensionRequestFormData extends FormData {
  IsolateIds: string[];
  File: Blob;
  Comment: string;
}

export interface CreateIsolateExtensionRequestParams {
  body: ExtensionRequestFormData;
}

export interface GetExtensionRequestForRegQueryParams {
  id: number;
}

export interface IsolateExtensionRequestApprovalBody {
  isolateRequestId: number;
  approvalStatus: number;
  declineComment?: string;
}

// create inteface fro bellow code
interface IsolateExtensionRequestForApprovalResponse {
  id: number;
  isolates: [];
  date: string;
  status: number;
  comment: string;
  documentId: any;
  customer: Customer;
  requestLetter: RequestLetter;
  vetId: number;
  vet: any;
}
interface RequestLetter {
  id: number;
  fileName: string;
  contentType: string;
  path: string;
  creationDate: string;
  updatedDate: string;
  documentHistory: any[];
}

export interface GetIsolateQueryParams {
  id: number;
}

const transformResponse = (res: any) => res;

const getIsolateDetailsTransformResponse = (res) => res?.data;

const isolateApi = api.injectEndpoints({
  endpoints: (build) => ({
    getIsolates: build.mutation<IsolateResponse, QueryParams>({
      query: (query) => {
        return {
          url: 'Isolate/search',
          method: 'POST',
          body: query,
        };
      },
      transformResponse: transformResponse,
    }),
    getIsolateDetails: build.query<any, GetIsolateQueryParams>({
      query: (params) => {
        return {
          url: `isolate/${params.id}`,
        };
      },
      transformResponse: getIsolateDetailsTransformResponse,
    }),
    isolateExtensionIsolates: build.query<
      IsolateExtensionResponse,
      IsolateExtensionQueryParams
    >({
      query: (params) => {
        return {
          url: `Isolate/getIsolatesForExtensionRequest?isolateIds=${params?.isolatesIds?.join(
            ','
          )}&vetId=${params?.vetId || ''}`,
        };
      },
    }),
    isolateExtensionRequestById: build.query<
      IsolateExtensionResponse,
      GetExtensionRequestQueryParams
    >({
      query: (params) => {
        return {
          url: `Isolate/getExtensionRequest?extensionRequestId=${params.id}`,
        };
      },
    }),
    createIsolateExtensionRequest: build.mutation<
      any,
      CreateIsolateExtensionRequestParams
    >({
      query: ({ body }) => {
        return {
          url: 'Isolate/createExtensionRequest',
          method: 'POST',
          body: body,
        };
      },
    }),
    getIsolateExtensionRequestForReg: build.query<
      IsolateExtensionRequestForApprovalResponse,
      GetExtensionRequestForRegQueryParams
    >({
      query: (params) => {
        return {
          url: `Isolate/getExtensionRequestForApproval?extensionRequestId=${params?.id}`,
          method: 'GET',
        };
      },
      transformResponse: (res: {
        data: IsolateExtensionRequestForApprovalResponse;
      }) => res?.data,
    }),
    GetIsolateShareRequestForReg: build.query<
      IsolateExtensionRequestForApprovalResponse[],
      GetExtensionRequestForRegQueryParams
    >({
      query: (params) => {
        return {
          url: `Isolate/getShareRequestForApproval?isolateId=${params?.id}`,
          method: 'GET',
        };
      },
      transformResponse: (res: {
        data: IsolateExtensionRequestForApprovalResponse[];
      }) => res?.data,
      keepUnusedDataFor: 0,
      providesTags: ['shareRequest'],
    }),
    IsolateExtensionRequestApproval: build.mutation<
      any,
      IsolateExtensionRequestApprovalBody
    >({
      query: (body) => {
        return {
          url: 'Isolate/handleExtensionRequest',
          method: 'POST',
          body: body,
        };
      },
    }),
    IsolateShareRequestApproval: build.mutation<
      any,
      IsolateExtensionRequestApprovalBody
    >({
      query: (body) => {
        return {
          url: 'Isolate/handleShareRequest',
          method: 'POST',
          body: body,
        };
      },
      invalidatesTags: ['shareRequest'],
    }),
  }),
});

export const {
  useGetIsolatesMutation,
  useLazyIsolateExtensionIsolatesQuery,
  useLazyIsolateExtensionRequestByIdQuery,
  useCreateIsolateExtensionRequestMutation,
  useLazyGetIsolateExtensionRequestForRegQuery,
  useIsolateExtensionRequestApprovalMutation,
  useGetIsolateExtensionRequestForRegQuery,
  useGetIsolateShareRequestForRegQuery,
  useIsolateShareRequestApprovalMutation,
  useGetIsolateDetailsQuery,
} = isolateApi;

export default isolateApi;
