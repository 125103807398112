import {
  Chip,
  ChipProps,
  FormControl,
  InputLabel,
  Select,
  Theme,
} from '@mui/material';
import styled from '@emotion/styled';
import { CustomSelectProps, StyledErrorTextProps } from './types';
import { Size } from 'types';

const getSize = (size: Size) => {
  if (size === 'small') {
    return {
      '& .MuiSelect-select': {
        paddingBlock: '6px',
      },
    };
  } else if (size === 'medium') {
    return {
      '& .MuiSelect-select': {
        paddingBlock: '10px',
      },
    };
  } else if (size === 'large') {
    return {
      '& .MuiSelect-select': {
        paddingBlock: '12px',
      },
    };
  }
};

export const StyledSelect = styled(Select)<CustomSelectProps>`
  max-height: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 50px;

  ${({ theme }) => {
    return {
      [theme.breakpoints.down('md')]: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    };
  }};

  ${({ size }) => {
    return getSize(size);
  }};
` as React.FC<CustomSelectProps>;

export const StyledErrorText = styled('span')<
  StyledErrorTextProps & { theme?: Theme }
>`
  ${({ size }) => {
    if (['small', 'medium'].includes(size))
      return {
        fontSize: 12,
      };

    return { fontSize: 14 };
  }};
  margin-top: 8;
  color: ${({ theme }) => theme.palette.error.main};
`;

export const StyledFormControl = styled(FormControl)`
  ${() => {
    return {
      m: 1,
      minWidth: 50,
    };
  }};
`;

type StyledChipProps = ChipProps & {
  chipBgColor: string;
  labelColor: string;
  deleteIconHidden?: boolean;
};

export const StyledChip = styled(Chip)<StyledChipProps>`
  ${({ labelColor, chipBgColor, deleteIconHidden }) => {
    return {
      marginRight: 4,
      zIndex: 10,
      borderRadius: '25px',
      padding: '16px 8px',
      marginBlock: '2px',
      background: chipBgColor,
      '& .MuiChip-label': {
        textFillColor: labelColor,
      },

      '& .MuiChip-deleteIcon': {
        display: deleteIconHidden ? 'none' : 'block',
      },
    };
  }}
` as React.FC<StyledChipProps>;

export const listContainerStyle = {
  mt: 1,
  background: '#FFFFFF',
  border: '1px solid #CED4DA',
  borderRadius: '4px',
  marginTop: 1,
  maxHeight: 250,
};

export const StyledInputLabel = styled(InputLabel)`
  transform: none;
  position: relative;
`;
