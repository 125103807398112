import { Container, Grid, useTheme } from '@mui/material';
import {
  AdditionalInstructionsElement,
  StyledTitleTextarea,
  StyledTextarea,
  StyledTitle,
  StyledOptionValue,
  StyledErrorText,
} from './styles';

import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import StepperButtonGroup from 'components/molecules/StepperButtonGroup';
import {
  getAdditionalInstructions,
  getIsUserTypeVet,
} from 'store/features/orderForm/index.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { setFieldValue } from 'store/features/orderForm/index.slice';
import {
  AdditionalInstructionsInfoKeys,
  ORDER_FORM_STEP_NAMES,
} from 'constants/index';
import CustomSelect from 'components/atoms/Select';
import * as Yup from 'yup';
import { useMemo } from 'react';
import theme from 'theme';
import { getFieldVisibility } from 'utils';
import RenderByCondition from 'components/atoms/RenderByCondition';

const formName = ORDER_FORM_STEP_NAMES.ADDITIONAL_INSTRUCTIONS_INFO;

const {
  BOTTLE_NAME_VISIBILITY,
  INTERNAL_REFERENCE_VISIBILITY,
  CLINICAL_SIGNS_VISIBILITY,
  VACCINATION_SCHEDULE_REQUIRED,
  REQUEST_LETTER_COMMENT,
  CAP_COLOR_VISIBILITY,
  BOTTLE_PERSONALIZATION_VISIBILITY,
} = getFieldVisibility();

const useForm = () => {
  const initialFormData = useSelector(getAdditionalInstructions);
  const { t } = useTranslation();
  const validationSchema = Yup.object({
    ...(CAP_COLOR_VISIBILITY
      ? {
          colors: Yup.object().shape({
            value: Yup.object()
              .shape({
                label: Yup.string().required(
                  t('errorMessages.cupColorRequired')
                ),
                value: Yup.string().required(
                  t('errorMessages.cupColorRequired')
                ),
              })
              .nullable()
              .required(t('errorMessages.cupColorRequired')),
          }),
        }
      : {}),
    ...(VACCINATION_SCHEDULE_REQUIRED
      ? {
          vaccinationSchedule: Yup.object().shape({
            value: Yup.string()
              .nullable()
              .required(t('errorMessages.vaccinationScheduleRequired')),
          }),
        }
      : {}),
    ...(CLINICAL_SIGNS_VISIBILITY
      ? {
          clinicalSigns: Yup.object().shape({
            value: Yup.string()
              .nullable()
              .required(t('errorMessages.vaccinationScheduleRequired')),
          }),
        }
      : {}),
  });

  const {
    values: formikValues,
    setFieldValue: formikSetFieldValue,
    errors,
    isValid,
    touched,
    handleBlur,
  } = useFormik({
    initialValues: initialFormData,
    validationSchema,
    enableReinitialize: true,
    onSubmit: () => {
      console.log('');
    },
    validateOnMount: true,
  });
  return {
    formikValues,
    formikSetFieldValue,
    errors,
    isValid,
    touched,
    handleBlur,
    initialFormData,
  };
};

const ColorSelect = ({
  value,
  onChange,
  handleBlur,
  errors,
  touched,
  initialFormData,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const colors = useMemo(() => {
    return initialFormData?.colors?.data?.map((color) => ({
      ...color,
      label: color?.name,
      value: color?.hexCode,
      id: color?.id,
    }));
  }, [initialFormData?.colors?.data]);

  const isError = touched?.colors && Boolean(errors?.colors);

  const errorMessage = errors?.colors?.value?.label;

  const onBlur = () =>
    handleBlur({ target: { id: AdditionalInstructionsInfoKeys.COLORS } });

  return (
    <Grid item xs={12} md={6} spacing={2}>
      <CustomSelect
        options={colors}
        name={AdditionalInstructionsInfoKeys.COLORS}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        label={t('orderForm.capColor') + ' *'}
        withCustomOption={true}
        renderOption={(option: any) => {
          return (
            <Grid style={{ display: 'flex', flexDirection: 'row' }}>
              <StyledOptionValue
                color={option.value}
                style={{
                  background: option.value,
                  border: `1px solid ${theme.palette.neutral[600]}`,
                }}
              ></StyledOptionValue>
              <Grid>{option.label}</Grid>
            </Grid>
          );
        }}
        error={isError}
        errorMessage={errorMessage}
      />
    </Grid>
  );
};

const BottleTag = ({ value, onChange, placeholder }) => {
  const { t } = useTranslation();
  return (
    <Grid item xs={12} sx={{}}>
      <StyledTitleTextarea
        sx={{ marginY: 2, color: theme.palette.neutral[800] }}
      >
        {t('orderForm.bottleTag')}
      </StyledTitleTextarea>
      <StyledTextarea
        name={AdditionalInstructionsInfoKeys.BOTTLE_TAG}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </Grid>
  );
};

const VaccinationSchedule = ({
  value,
  onChange,
  placeholder,
  errors,
  touched,
  handleBlur,
}) => {
  const { t } = useTranslation();

  const vaccinationScheduleOnBlur = () =>
    handleBlur({
      target: { id: AdditionalInstructionsInfoKeys.VACCINATION_SCHEDULE },
    });

  const errorMessage = errors?.vaccinationSchedule?.value;

  const isError =
    touched?.vaccinationSchedule && Boolean(errors?.vaccinationSchedule);

  return (
    <Grid item xs={12} sx={{}}>
      <StyledTitleTextarea
        sx={{ marginY: 2, color: theme.palette.neutral[800] }}
      >
        {t('orderForm.vaccinationSchedule') +
          (VACCINATION_SCHEDULE_REQUIRED ? '*' : '')}
      </StyledTitleTextarea>
      <StyledTextarea
        name={AdditionalInstructionsInfoKeys.VACCINATION_SCHEDULE}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        onBlur={vaccinationScheduleOnBlur}
      />
      {isError && (
        <StyledErrorText size={'small'}>{errorMessage}</StyledErrorText>
      )}
    </Grid>
  );
};

const ClinicalSigns = ({
  value,
  onChange,
  placeholder,
  handleBlur,
  errors,
  touched,
}) => {
  const { t } = useTranslation();

  const isError = touched?.clinicalSigns && Boolean(errors?.clinicalSigns);

  const errorMessage = errors?.clinicalSigns?.value;

  const clinicalSignsOnBlur = () =>
    handleBlur({
      target: { id: AdditionalInstructionsInfoKeys.CLINICAL_SIGNS },
    });

  return (
    <Grid item xs={12} sx={{}}>
      <StyledTitleTextarea
        sx={{ marginY: 2, color: theme.palette.neutral[800] }}
      >
        {t('orderForm.clinicalSigns') + '*'}
      </StyledTitleTextarea>
      <StyledTextarea
        name={AdditionalInstructionsInfoKeys.CLINICAL_SIGNS}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        onBlur={clinicalSignsOnBlur}
      />
      {isError && (
        <StyledErrorText size={'small'}>{errorMessage}</StyledErrorText>
      )}
    </Grid>
  );
};
const InternalReference = ({ value, onChange, placeholder }) => {
  const { t } = useTranslation();
  return (
    <Grid item xs={12} sx={{}}>
      <StyledTitleTextarea
        sx={{ marginY: 2, color: theme.palette.neutral[800] }}
      >
        {t('orderForm.internalReference')}
      </StyledTitleTextarea>
      <StyledTextarea
        name={AdditionalInstructionsInfoKeys.INTERNAL_REFERENCE}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </Grid>
  );
};

const AdditionalComment = ({ value, onChange, placeholder }) => {
  const { t } = useTranslation();
  return (
    <Grid item xs={12} sx={{}}>
      <StyledTitleTextarea
        sx={{ marginY: 2, color: theme.palette.neutral[800] }}
      >
        {t('orderForm.additionalComments')}
      </StyledTitleTextarea>
      <StyledTextarea
        name={AdditionalInstructionsInfoKeys.ADDITIONAL_COMMENT}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </Grid>
  );
};

const RequestLetterComment = ({ value, onChange, placeholder }) => {
  const { t } = useTranslation();
  return (
    <Grid item xs={12}>
      <StyledTitleTextarea
        sx={{ marginY: 2, color: theme.palette.neutral[800] }}
      >
        {t('orderForm.requestLetterComments')}
      </StyledTitleTextarea>
      <StyledTextarea
        name={AdditionalInstructionsInfoKeys.REQUEST_LETTER_COMMENT}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </Grid>
  );
};

const AdditionalInstructionsStep = (props) => {
  const { goNext, goBack, onCancel } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isVetUser = useSelector(getIsUserTypeVet);

  const {
    formikValues,
    formikSetFieldValue,
    errors,
    isValid,
    touched,
    handleBlur,
    initialFormData,
  } = useForm();

  const setField = (propPathValue, value) => {
    formikSetFieldValue(propPathValue, value);
    dispatch(setFieldValue({ propPath: propPathValue, value, formName }));
  };

  const onChange = (e: any, value: any) => {
    const fieldName = e.target.name;
    const propPathValue = `${fieldName}.value`;
    setField(propPathValue, value);
  };

  return (
    <Container maxWidth="md">
      <RenderByCondition show={[BOTTLE_PERSONALIZATION_VISIBILITY]}>
        <StyledTitle variant="h1">
          {t('orderForm.bottleAdditionalInfo')}
        </StyledTitle>
      </RenderByCondition>
      <RenderByCondition show={[!BOTTLE_PERSONALIZATION_VISIBILITY]}>
        <StyledTitle variant="h1">
          {t('orderForm.bottlePersonalization')}
        </StyledTitle>
      </RenderByCondition>

      <AdditionalInstructionsElement container rowSpacing={1} spacing={3}>
        <RenderByCondition show={[CAP_COLOR_VISIBILITY]}>
          <ColorSelect
            initialFormData={initialFormData}
            value={formikValues?.colors?.value}
            onChange={onChange}
            touched={touched}
            errors={errors}
            handleBlur={handleBlur}
          />
        </RenderByCondition>

        <RenderByCondition show={[BOTTLE_NAME_VISIBILITY]}>
          <BottleTag
            value={formikValues?.bottleTag?.value}
            onChange={(event) => onChange(event, event.target.value)}
            placeholder={t('orderForm.bottleTagText')}
          />
        </RenderByCondition>

        <VaccinationSchedule
          value={formikValues?.vaccinationSchedule?.value}
          onChange={(event) => onChange(event, event.target.value)}
          placeholder={t('orderForm.vaccinationSchedule')}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
        />

        <RenderByCondition show={[CLINICAL_SIGNS_VISIBILITY]}>
          <ClinicalSigns
            value={formikValues?.clinicalSigns?.value}
            onChange={(event) => onChange(event, event.target.value)}
            placeholder={t('orderForm.clinicalSigns')}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
          />
        </RenderByCondition>

        <RenderByCondition show={[INTERNAL_REFERENCE_VISIBILITY]}>
          <InternalReference
            value={formikValues?.internalReference?.value}
            onChange={(event) => {
              if (!isVetUser) return;
              onChange(event, event.target.value);
            }}
            placeholder={t('orderForm.internalReferencePlaceHolder')}
          />
        </RenderByCondition>

        <AdditionalComment
          value={formikValues?.additionalComment?.value}
          onChange={(event) => onChange(event, event.target.value)}
          placeholder={t('orderForm.placeholderAdditionalComments')}
        />

        <RenderByCondition show={[REQUEST_LETTER_COMMENT]}>
          <RequestLetterComment
            value={formikValues?.requestLetterComment?.value}
            onChange={(event) => onChange(event, event.target.value)}
            placeholder={t('orderForm.requestLetterCommentsPlaceHolder')}
          />
        </RenderByCondition>
      </AdditionalInstructionsElement>

      <Grid item xs={12} sx={{ mt: 6 }}>
        <StepperButtonGroup
          goNext={goNext}
          goBack={goBack}
          onCancel={onCancel}
          showNext={true}
          showBackBtn={true}
          showCancelBtn={true}
          nextBtnDisabled={!isValid}
        />
      </Grid>
    </Container>
  );
};

export default AdditionalInstructionsStep;
