import CustomModal from 'components/molecules/Modal';
import { Grid } from '@mui/material';
import CustomInput from 'components/atoms/Input';
import CustomButton from 'components/atoms/Button';
import { AddressTypes } from 'enums';
import { COUNTRY_CODE } from 'constants/index';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  getFarmData,
  getFormConfig,
} from 'store/features/orderForm/index.selectors';
import { useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import {
  useUpdateCustomerFarmsMutation,
  useUpdateCustomerShippingAddressesMutation,
} from 'api/orderForm';
import CustomSelect from 'components/atoms/Select';
import { getFieldVisibility } from 'utils';

const { STATE_VISIBILITY, IDM_VISIBILITY } = getFieldVisibility();

interface InputField {
  label: string;
  propName: string;
  component: (props) => JSX.Element;
}

interface AddAddressModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  addressType: AddressTypes;
  custmerId?: number;
}

const AddAddressModal: React.FC<AddAddressModalProps> = ({
  open,
  setOpen,
  addressType,
  custmerId,
}) => {
  const { t } = useTranslation();
  const farmData = useSelector(getFarmData);
  const isNonAdjacentRequired = farmData?.nonAdjacentRequired?.value;
  const isUsCountry = process.env.REACT_APP_COUNTRY === COUNTRY_CODE.US;
  const isFieldDisabled = (propName) => propName === 'country' && isUsCountry;
  const apiConfiguration = useSelector(getFormConfig);

  const getFieldValue = (propName, values) =>
    propName === 'country' && isUsCountry ? 'US' : values[propName] || '';
  const getFieldError = (propName, errors, touched) => {
    return errors[propName] && touched[propName];
  };
  const getFieldHelperText = (propName, errors, touched) =>
    errors[propName] && touched[propName] ? errors[propName] : '';

  const initialFormValues = {
    codeIDM: '',
    name: '',
    street: '',
    city: '',
    state: { label: '', name: '' },
    country: isUsCountry ? 'US' : '',
    zipCode: '',
    capacity: 0,
    complex: '',
  };

  const modalTitle = useMemo(() => {
    if (addressType === AddressTypes.Main) {
      if (isNonAdjacentRequired) {
        return t('orderForm.addNonAdjacentAddressTitle');
      }
      return t('orderForm.addFarmAddressTitle');
    } else if (addressType === AddressTypes.Shipping) {
      return t('orderForm.addShippingSiteTitle');
    }
    return null;
  }, [isNonAdjacentRequired, addressType]);

  const getValidationSchema = (addressType: AddressTypes) => {
    const commonSchema = yup.object().shape({
      name: yup.string().required(t('errorMessages.fieldRequired')),
      street: yup.string().required(t('errorMessages.fieldRequired')),
      city: yup.string().required(t('errorMessages.fieldRequired')),
      state: STATE_VISIBILITY
        ? yup.object().shape({
            value: yup.string().required(t('errorMessages.fieldRequired')),
            label: yup.string(),
          })
        : yup.object().notRequired(),
      country: yup.string().required(t('errorMessages.fieldRequired')),
      zipCode: yup
        .number()
        .moreThan(0, t('errorMessages.fieldRequired'))
        .typeError(t('errorMessages.numberType'))
        .required(t('errorMessages.fieldRequired')),
    });

    const nonShippingFields = {
      capacity: yup
        .number()
        .moreThan(0, t('errorMessages.fieldRequired'))
        .typeError(t('errorMessages.numberType'))
        .required(t('errorMessages.fieldRequired')),
    };

    if (IDM_VISIBILITY) {
      nonShippingFields['codeIDM'] = yup
        .string()
        .required(t('errorMessages.fieldRequired'));
    }

    const nonShippingSchema = commonSchema.shape(nonShippingFields);

    const shippingSchema = commonSchema.shape({
      contact: yup.string().required(t('errorMessages.fieldRequired')),
      phoneNumber: yup
        .string()
        .required(t('errorMessages.fieldRequired'))
        .matches(/^\d+$/, t('errorMessages.numberType')),
    });

    return addressType === AddressTypes.Shipping
      ? shippingSchema
      : nonShippingSchema;
  };

  const validationSchema = getValidationSchema(addressType);

  let inputFields: InputField[] = useMemo(() => {
    return [
      {
        label: t('orderForm.codeIDM') + ' *',
        propName: 'codeIDM',
        component: (props) => <CustomInput {...props} />,
      },
      {
        label: t('orderForm.name') + ' *',
        propName: 'name',
        component: (props) => <CustomInput {...props} />,
      },
      {
        label: t('orderForm.address') + ' *',
        propName: 'street',
        component: (props) => <CustomInput {...props} />,
      },
      {
        label: t('orderForm.city') + ' *',
        propName: 'city',
        component: (props) => <CustomInput {...props} />,
      },
      {
        label: t('orderForm.state') + ' *',
        propName: 'state',
        component: ({ setFieldValue, ...props }) => {
          const options = apiConfiguration?.states
            ?.map((state) => ({
              ...state,
              value: state?.name,
              label: state?.name,
            }))
            .sort((a, b) => a.label.localeCompare(b.label));

          const handleChange = (event, value) => {
            const name = event?.target?.name;

            setFieldValue(name, value);
          };
          return (
            <CustomSelect
              {...props}
              value={props?.value}
              errorMessage={props?.errorMessage?.value}
              options={options}
              onChange={handleChange}
              size="small"
            />
          );
        },
      },
      {
        label: t('orderForm.country') + ' *',
        propName: 'country',
        component: (props) => <CustomInput {...props} />,
      },
      {
        label: t('orderForm.zip') + ' *',
        propName: 'zipCode',
        component: (props) => <CustomInput {...props} />,
      },
      {
        label: t('orderForm.capacity') + ' *',
        propName: 'capacity',
        component: (props) => <CustomInput {...props} />,
      },
      {
        label: 'Complex',
        propName: 'complex',
        component: (props) => <CustomInput {...props} />,
      },
      {
        label: t('orderForm.contact') + ' *',
        propName: 'contact',
        component: (props) => <CustomInput {...props} />,
      },
      {
        label: t('orderForm.phone') + ' *',
        propName: 'phoneNumber',
        component: (props) => <CustomInput {...props} />,
      },
    ];
  }, [apiConfiguration?.states]);

  const removeFromArray = (array, itemsToRemove) => {
    return array.filter((item) => !itemsToRemove.includes(item.propName));
  };

  if (!STATE_VISIBILITY) {
    inputFields = removeFromArray(inputFields, ['state']);
  }

  if (!IDM_VISIBILITY) {
    inputFields = removeFromArray(inputFields, ['codeIDM']);
  }

  if (addressType === AddressTypes.Shipping) {
    const itemsToRemove = ['codeIDM', 'capacity', 'complex'];
    inputFields = removeFromArray(inputFields, itemsToRemove);
  } else {
    const itemsToRemove = ['contact', 'phoneNumber'];
    inputFields = removeFromArray(inputFields, itemsToRemove);
  }

  const [addFarmToCustomer, { isLoading, isSuccess }] =
    useUpdateCustomerFarmsMutation();

  const [
    addShippingAddressesToCustomer,
    { isLoading: addShippingIsLoading, isSuccess: addShippingIsSuccess },
  ] = useUpdateCustomerShippingAddressesMutation();

  function deleteProperties(obj, propNames) {
    for (const propName of propNames) {
      if (Object.hasOwn(obj, propName)) {
        delete obj[propName];
      }
    }
    return obj;
  }

  const createAddress = (values) => {
    return {
      name: values?.name,
      street: values?.street,
      city: values?.city,
      state: deleteProperties({ ...values?.state }, ['value', 'label']),
      zipCode: values?.zipCode,
      country: values?.country,
      phoneNumber: values?.phoneNumber,
      contact: values?.contact,
    };
  };

  const createShippingAddress = (values) => {
    return {
      name: values?.name,
      street: values?.street,
      city: values?.city,
      zipCode: values?.zipCode,
      country: values?.country,
      phoneNumber: values?.phoneNumber,
      contact: values?.contact,
      stateId: values?.state?.id,
    };
  };

  const handleSaveAddress = async (values) => {
    const addressData =
      addressType == AddressTypes.Shipping
        ? createShippingAddress(values)
        : createAddress(values);

    const address = {
      type:
        addressType === AddressTypes.Main && isNonAdjacentRequired
          ? AddressTypes.Proximity
          : addressType,
      ...addressData,
    };

    const customerFarm = {
      officialNumber: values?.codeIDM,
      name: values?.name,
      capacity: values?.capacity,
      complex: values?.complex,
      address: address,
    };

    const data = {
      id: custmerId,
      data: addressType == AddressTypes.Shipping ? address : customerFarm,
    };

    if (addressType == AddressTypes.Shipping) {
      await addShippingAddressesToCustomer(data);
    } else {
      await addFarmToCustomer(data);
    }
  };

  useEffect(() => {
    if (isSuccess && !isLoading) setOpen(false);
  }, [isSuccess, isLoading]);

  useEffect(() => {
    if (addShippingIsSuccess && !addShippingIsLoading) setOpen(false);
  }, [addShippingIsSuccess, addShippingIsLoading]);

  return (
    <CustomModal
      title={modalTitle}
      open={open}
      setOpen={setOpen}
      maxWidth={'sm'}
    >
      <Formik
        validateOnMount={true}
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSaveAddress(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          touched,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          isValid,
          dirty,
        }) => (
          <Grid
            container
            padding={{ md: '16px 62px', xs: '16px 24px' }}
            rowGap={2}
          >
            {inputFields.map((field) => {
              const Component = field?.component;

              return (
                <Grid item md={12} width={'100%'} key={field.propName}>
                  <Component
                    name={field.propName}
                    label={field.label}
                    sx={{ width: '100%' }}
                    value={getFieldValue(field.propName, values)}
                    disabled={isFieldDisabled(field.propName)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={getFieldError(field.propName, errors, touched)}
                    errorMessage={getFieldHelperText(
                      field.propName,
                      errors,
                      touched
                    )}
                    setFieldValue={setFieldValue}
                  />
                </Grid>
              );
            })}
            <Grid
              container
              md={12}
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={0.5}
              mt={2}
            >
              <Grid item>
                <CustomButton
                  size="medium"
                  variant="outlined"
                  onClick={() => setOpen(false)}
                  disabled={false}
                >
                  {t('orderForm.cancel')}
                </CustomButton>
              </Grid>
              <Grid item>
                <CustomButton
                  size="medium"
                  type="button"
                  loading={isSubmitting}
                  onClick={() => {
                    handleSubmit();
                  }}
                  disabled={!(isValid && dirty)}
                >
                  {t('orderForm.save')}
                </CustomButton>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddAddressModal;
