import { Grid } from '@mui/material';
import { LeftArrow } from 'components/icons';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { StyledTitle, StyledLink } from './styles';
import { useSelector } from 'react-redux';
import { getUserRole } from 'store/features/auth/index.selector';
import { PAGES } from 'constants/index';

interface PageHeaderProps {
  title: string;
  onClickBack?: () => void;
}

const PageHeader = (props: PageHeaderProps) => {
  const { title, onClickBack } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const userRole = useSelector(getUserRole);

  const back = () => {
    if (onClickBack) {
      onClickBack();
      return;
    }
    const doesAnyHistoryEntryExist = location.key !== 'default';

    if (doesAnyHistoryEntryExist) navigate(-1);
    else navigate(PAGES[userRole].ROOT);
  };

  return (
    <Grid
      container
      direction="row"
      my={{ xs: 2, md: 2 }}
      mb={{ xs: 4, md: 4 }}
      alignItems={'center'}
    >
      <Grid item xs={2}>
        <StyledLink onClick={back} underline="none" sx={{ cursor: 'pointer' }}>
          <LeftArrow />
          {t('orderForm.back')}
        </StyledLink>
      </Grid>
      <Grid item xs={8} textAlign={'center'}>
        <StyledTitle variant="h3" fontWeight={600}>
          {title}
        </StyledTitle>
      </Grid>
      <Grid item xs={2}></Grid>
    </Grid>
  );
};
export default PageHeader;
