import { PAGES } from 'constants/index';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getUserRole } from 'store/features/auth/index.selector';

interface UseCustomNavigationLogicProps {
  queryParams: string[];
  originLocation?: string;
}

export const useCustomNavigationLogic = ({
  queryParams,
}: UseCustomNavigationLogicProps) => {
  const location = useLocation();
  const userRole = useSelector(getUserRole);
  const navigation = useNavigate();
  const [paramsValues, setParamsValues] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  const getParamsValues = () => {
    const data = {};
    queryParams.forEach((param) => {
      if (searchParams.size === 0) return;
      const value = searchParams.get(param);
      if (value !== 'null' && value !== 'undefined') {
        data[param] = value;
      } else {
        navigation(PAGES[userRole].NOT_FOUND);
      }
    });
    setParamsValues(data);
  };

  const setParams = (queries) => {
    Object.keys(queries).forEach((key) => {
      if (queryParams.includes(key)) {
        setSearchParams(
          (prev: any) => {
            prev.set(key, queries[key]);
            return prev;
          },
          { replace: true }
        );
      }
    });
  };

  return { getParamsValues, paramsValues, location, setParams };
};
