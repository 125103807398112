import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  IOrderForm,
  ISetFieldValueAction,
  ISetUser,
  ModeState,
} from './index.type';
import { orderFormApi } from 'api/orderForm';
import { getEnvironmentSettings } from 'utils';

import {
  FarmInfoKeys,
  ORDER_FORM_STEP_NAMES,
  USER,
  InfectionInfoKeys,
  DosingAndFillingInfoKeys,
  AdditionalInstructionsInfoKeys,
  BottlesPricingInfoKeys,
  FormStepKeys,
  FIELD_ACTION_NAME,
} from 'constants/index';

const { ADDRESSES_TYPE_RADIO_BUTTON_DEFAULT_VALUE } = getEnvironmentSettings();

export const initialState: IOrderForm = {
  orderForm: {
    [FormStepKeys.ORDER_MODE]: ModeState.ORDER,
    [FormStepKeys.ID]: 0,
    [FormStepKeys.CONFIG]: null,
    user: {
      type: USER.VET,
    },
    [ORDER_FORM_STEP_NAMES.FARM_INFO]: {
      [FarmInfoKeys.VETS]: {
        data: [],
        value: { label: '', value: '' },
      },
      [FarmInfoKeys.NON_ADJACENT_REQUIRED]: {
        value: ADDRESSES_TYPE_RADIO_BUTTON_DEFAULT_VALUE,
      },
      [FarmInfoKeys.FARM_ADDRESSES]: {
        value: [],
        data: [],
        totalCount: 0,
        selectCount: 0,
      },
      [FarmInfoKeys.SHIPPING_SITES]: {
        value: [],
        data: [],
        totalCount: 0,
        selectCount: 0,
      },
      [FarmInfoKeys.SHIPPING_SITES_BY_STATES]: {
        value: [],
        data: [],
        totalCount: 0,
        selectCount: 0,
        totalStateCount: 0,
      },
      [FarmInfoKeys.BILLING_ADDRESSES]: {
        value: { label: '', value: '' },
        data: [],
      },
      [FarmInfoKeys.CUSTOMER]: {
        value: { label: '', value: '' },
        data: [],
      },
    },
    [ORDER_FORM_STEP_NAMES.INFECTION_INFO]: {
      [InfectionInfoKeys.ANIMAL_GROUPS]: {
        data: [],
        value: { label: '', value: '' },
      },
      [InfectionInfoKeys.ANIMAL_SPECIES]: {
        data: [],
        value: { label: '', value: '' },
      },
      [InfectionInfoKeys.AUTHORIZED_ISOLATES]: {
        data: [],
      },
      [InfectionInfoKeys.PRODUCTS]: {
        value: { label: '', value: '' },
        data: [],
      },
      [InfectionInfoKeys.ISOLATES]: {
        data: [],
        value: [],
      },
      [InfectionInfoKeys.ADJUVANTS]: {
        value: { label: '', value: '' },
        data: [],
        totalCount: 0,
        selectCount: 0,
      },
      [InfectionInfoKeys.ADMINISTRATION_ROUTES]: {
        data: [],
        value: { label: '', value: '' },
      },
      [InfectionInfoKeys.DOSES]: {
        value: { label: '', value: '' },
        data: [],
        min: 0,
        max: Infinity,
      },
      [InfectionInfoKeys.NUM_OF_ANIMAL]: {
        value: 0,
        min: 0,
        max: Infinity,
      },
    },
    [ORDER_FORM_STEP_NAMES.DOSING_AND_FILLING_INFO]: {
      [DosingAndFillingInfoKeys.BOTTLES]: {
        value: null,
        data: [],
        totalVolume: 0,
        totalCount: 0,
        totalDoses: 0,
      },
      [DosingAndFillingInfoKeys.BOTTLE_USAGE]: {
        value: 0,
        min: 0,
        max: Infinity,
      },
    },
    [ORDER_FORM_STEP_NAMES.BOTTLE_PRICING]: {
      [BottlesPricingInfoKeys.BOTTLES]: {
        value: [],
        data: [],
      },
    },
    [ORDER_FORM_STEP_NAMES.ADDITIONAL_INSTRUCTIONS_INFO]: {
      [AdditionalInstructionsInfoKeys.COLORS]: {
        value: { label: '', value: '' },
        data: [],
      },
      [AdditionalInstructionsInfoKeys.BOTTLE_TAG]: {
        value: '',
        data: [],
      },
      [AdditionalInstructionsInfoKeys.VACCINATION_SCHEDULE]: {
        value: '',
      },
      [AdditionalInstructionsInfoKeys.CLINICAL_SIGNS]: {
        value: '',
      },
      [AdditionalInstructionsInfoKeys.INTERNAL_REFERENCE]: {
        value: '',
      },
      [AdditionalInstructionsInfoKeys.ADDITIONAL_COMMENT]: {
        value: '',
        data: [],
      },
      [AdditionalInstructionsInfoKeys.REQUEST_LETTER_COMMENT]: {
        value: '',
        data: [],
      },
    },
  },
};

export const orderFormSlice = createSlice({
  name: 'orderForm',
  initialState,
  reducers: {
    setFieldValue: (state, action: PayloadAction<ISetFieldValueAction>) => {
      const { propPath, value, formName, actions = [] } = action.payload;
      const nestedFieldPath = propPath?.split('.');
      let field = state?.orderForm[formName];

      for (let i = 0; i < nestedFieldPath.length - 1; i++) {
        field = field?.[nestedFieldPath[i]];
      }

      const fieldName = nestedFieldPath?.[nestedFieldPath.length - 1];
      field[fieldName] = value;

      const resetPropertyMap = {
        value: 'value',
        totalCount: 'totalCount',
        selectCount: 'selectCount',
        totalStateCount: 'totalStateCount',
      };

      const setPropertyMap = {
        data: 'data',
        totalCount: 'totalCount',
        selectCount: 'selectCount',
        totalStateCount: 'totalStateCount',
      };

      if (actions?.length > 0) {
        actions?.forEach((action) => {
          if (action.name === FIELD_ACTION_NAME.RESET) {
            for (const propertyName in resetPropertyMap) {
              if (Object.prototype.hasOwnProperty.call(action, propertyName)) {
                state.orderForm[action.formName][action.key][
                  resetPropertyMap[propertyName]
                ] = action[propertyName];
              }
            }
          } else if (action.name === FIELD_ACTION_NAME.SET) {
            for (const propertyName in setPropertyMap) {
              if (Object.prototype.hasOwnProperty.call(action, propertyName)) {
                state.orderForm[action.formName][action.key][
                  setPropertyMap[propertyName]
                ] = action[propertyName];
              }
            }
          }
        });
      }
    },
    setUser: (state, action: PayloadAction<ISetUser>) => {
      const { type } = action.payload;

      state.orderForm.user.type = type;
    },
    setMode: (state, action) => {
      state.orderForm.orderMode = action.payload;
    },
    setOrderId: (state, action) => {
      state.orderForm.id = action.payload;
    },
    clearOrderForm: (state) => {
      state.orderForm = initialState.orderForm;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      orderFormApi.endpoints.getOrderConfig.matchFulfilled,
      (state, action) => {
        const formConfig = action.payload;
        state.orderForm.config = formConfig;

        const allVets = formConfig?.customers?.reduce(
          (accumulator, customer) => {
            customer?.vets?.forEach((vet) => {
              if (!accumulator.has(vet.id)) {
                accumulator.set(vet.id, vet);
              }
            });
            return accumulator;
          },
          new Map()
        );

        state.orderForm[ORDER_FORM_STEP_NAMES.FARM_INFO][
          FarmInfoKeys.VETS
        ].data = [...allVets.values()];

        state.orderForm[ORDER_FORM_STEP_NAMES.INFECTION_INFO][
          InfectionInfoKeys.ANIMAL_GROUPS
        ].data = formConfig.animalGroups as any;

        state.orderForm[ORDER_FORM_STEP_NAMES.ADDITIONAL_INSTRUCTIONS_INFO][
          AdditionalInstructionsInfoKeys.COLORS
        ].data = formConfig.capColors as any;
      }
    );
    builder.addMatcher(
      orderFormApi.endpoints.getIsolateById.matchFulfilled,
      (state, action) => {
        const isolates = action.payload;

        state.orderForm[ORDER_FORM_STEP_NAMES.INFECTION_INFO][
          InfectionInfoKeys.ISOLATES
        ].data = isolates;
      }
    );
    builder.addMatcher(
      orderFormApi.endpoints.getAuthorizedIsolates.matchFulfilled,
      (state, action) => {
        const data: any = action.payload;

        state.orderForm[ORDER_FORM_STEP_NAMES.INFECTION_INFO][
          InfectionInfoKeys.AUTHORIZED_ISOLATES
        ].data = data;
      }
    );
  },
});

// Action creators are generated for each case reducer function
export const { setFieldValue, setUser, setMode, setOrderId, clearOrderForm } =
  orderFormSlice.actions;

export default orderFormSlice.reducer;
