import { Box, Grid, Typography, styled } from '@mui/material';
import CircleUp from 'components/icons/CircleUp';
import Link from '@mui/material/Link';
import theme from 'theme';

export const StyledTypography = styled(Typography)`
  ${{
    fontFamily: theme.typography.fontFamily,
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '27px',
  }}
`;
export const StyledLink = styled(Link)`
  ${{
    fontFamily: theme.typography.fontFamily,
    fontSize: '22px',
    paddingRight: '16px',
    fontStyle: 'normal',
    lineHeight: '27px',
    alignItems: 'center',
    display: 'inline-flex',
    color: theme.palette.primary[600],
  }}
`;
export const StyledFormHeaderContainer = styled(Box)`
  ${({ theme }) => ({
    height: 44,
    backgroundColor: theme.palette.info.light,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  })}
`;
export const StyledCircleupIcon = styled(CircleUp)`
  ${() => ({
    display: 'flex',
    width: '40px',
    height: '50px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  })}
`;
export const StyledGridScroll = styled(Grid)`
  ${({ theme }) => ({
    display: 'flex',
    width: '854px',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    gap: '10px',
    right: '126px',
    bottom: '155px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: 10,
    },
  })}
`;
export const StyledTitleStatusOrder = styled(Typography)`
  ${() => ({
    color: `${theme.palette.primary['900']}`,
    fontFamily: 'Noto Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '21px',
    display: 'flex',
    width: '816px',
    paddingTop: '8px',
    alignItems: 'flex-start',
    gap: '10px',
  })}
`;
