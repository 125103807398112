import { Alert, Snackbar } from '@mui/material';
import React from 'react';
import { ToastProps } from './types';

const Toast: React.FC<ToastProps> = ({
  message,
  type,
  autoHideDuration,
  anchorOrigin = { vertical: 'top', horizontal: 'center' },
  open,
  setOpen,
  onFinishCallBack,
  key,
}) => {
  const handleClose = (_, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);

    if (onFinishCallBack) {
      onFinishCallBack();
    }
  };

  return (
    <Snackbar
      open={open || false}
      key={key}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      autoHideDuration={autoHideDuration}
    >
      <Alert severity={type} variant="filled" onClose={handleClose}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
