import {
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import CustomButton from 'components/atoms/Button';
import { StyledListItemText, StyledMenu } from './styles';
import TextBadge from 'components/atoms/TextBadge';
import ReadCheck from 'components/icons/ReadCheck';
import RenderByCondition from 'components/atoms/RenderByCondition';
import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { SIGNAL_R_EVENT } from 'constants/index';
import useNotification from 'hooks/useNotification';

interface Item {
  text: string;
  icon: React.ReactNode | JSX.Element;
  endButton: {
    text: string;
    onClick: () => void;
    style: any;
  };
  avatar: {
    src?: string;
    alt?: string;
    name: string;
  };
  onClick: () => void;
  isRead: boolean;
}

interface NotificationPopUpProps {
  open: boolean;
  handleClose: () => void;
  items: Item[];
  countNoRead?: number;
}

const NotificationItem = ({ item }) => {
  const [isLoading, setIsLoading] = useState([]);

  const observer = function (item) {
    return {
      on: function (event, data) {
        if (event === 'isLoading') {
          if (data.isLoading) {
            setIsLoading([...isLoading, item?.id]);
          } else if (!data.isLoading) {
            setIsLoading(isLoading.filter((id) => id !== item?.id));
          }
        }
      },
    };
  };

  return (
    <Grid
      container
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      my={2}
    >
      <Grid item xs={item?.isRead ? 12 : 8}>
        <Tooltip title={item?.text} placement="top">
          <StyledListItemText isDisabled={item?.isRead}>
            {item?.text}
          </StyledListItemText>
        </Tooltip>
      </Grid>
      <RenderByCondition show={[item?.isRead === false]}>
        <Grid
          item
          container
          xs={item?.isRead ? 0 : 4}
          justifyContent="flex-end"
        >
          <Grid item sx={{ maxWidth: '100px' }}>
            <CustomButton
              variant="outlined"
              size="small"
              sx={{
                display: 'inline-block',
                whiteSpace: 'normal',
                minWidth: '80px',
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (item?.endButton?.onClick) {
                  item?.endButton?.onClick(observer(item));
                }
              }}
              loading={isLoading?.includes(item?.id)}
            >
              <Typography variant="body2" fontWeight={600}>
                {item?.endButton?.text}
              </Typography>
            </CustomButton>
          </Grid>
          <Grid item>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                if (item?.onClick) {
                  item?.onClick();
                }
              }}
              sx={{
                width: '30px',
                height: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 0,
                marginLeft: '2px',
              }}
            >
              <ReadCheck />
            </IconButton>
          </Grid>
        </Grid>
      </RenderByCondition>
    </Grid>
  );
};

const NotificationList = ({ items }) => {
  return (
    <Grid item xs={12} mt={3} p={'4px'}>
      {items?.map((item) => {
        return (
          <>
            <NotificationItem key={item.id} item={item} />
            <Divider sx={{ my: '4px' }} />
          </>
        );
      })}
    </Grid>
  );
};

const NotificationHeader = ({ countNoRead }: { countNoRead: number }) => {
  const { sendNewMessage } = useNotification([]);
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Grid item xs>
      <Grid container flexDirection={'row'} alignItems={'center'}>
        <Grid item xs={7} md={7} container alignItems={'center'}>
          <Grid item xs>
            <Typography variant="body1" color="primary" fontWeight={600}>
              {t('notifications.title')}
            </Typography>
          </Grid>
          <Grid item xs ml={'8px'}>
            <TextBadge
              name={t('notifications.new', { count: countNoRead })}
              color={'#FFFFFF'}
              variant="body2"
              sx={{
                background: '#D6336C',
                py: '3px',
                px: '12px',
                borderRadius: '50px',
              }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs
          sx={{ textAlign: 'right' }}
          onClick={() => {
            if (countNoRead > 0) {
              sendNewMessage(
                SIGNAL_R_EVENT.SEND.ON_MARK_ALL_TASKS_AS_READ,
                null,
                undefined
              );
            }
          }}
        >
          <Typography
            sx={{
              textDecoration: 'underline',
              color: countNoRead === 0 ? theme.palette.neutral[700] : '#00AEEF',
              cursor: 'pointer',
              px: '2px',
              fontSize: { md: '16px', xs: '14px' },
            }}
          >
            {t('notifications.markAllAsRead')}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const NotificationContainer = ({ children, handleClose, open }) => {
  return (
    <StyledMenu
      id="basic-menu"
      onClose={handleClose}
      open={open}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Grid
        container
        flexDirection={'row'}
        sx={{
          width: { md: 400, xs: 350 },
          p: { md: 2, xs: 1 },
          maxHeight: 400,
        }}
      >
        {children}
      </Grid>
    </StyledMenu>
  );
};

const NotificationPopUp: React.FC<NotificationPopUpProps> = ({
  open,
  handleClose,
  items,
  countNoRead,
}) => {
  return (
    <NotificationContainer open={open} handleClose={handleClose}>
      <NotificationHeader countNoRead={countNoRead} />
      <NotificationList items={items} />
    </NotificationContainer>
  );
};

export default NotificationPopUp;
