import * as React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Divider, Stack, IconButton } from '@mui/material';
import CustomButton from 'components/atoms/Button';
import { PopupMessageProps } from './types';
import Close from 'components/icons/Close';
import { StyledPaper } from './styles';

const PopupMessage: React.FC<PopupMessageProps> = ({
  title,
  description,
  actionButtons,
  isOpen,
  handleClose,
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledPaper>
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="space-between">
            <Typography id="modal-modal-title" variant="h4" component="h2">
              {title}
            </Typography>
            <IconButton onClick={handleClose} aria-label="Close">
              <Close />
            </IconButton>
          </Stack>
          <Typography
            id="modal-modal-description"
            variant="body2"
            sx={{ paddingX: 1 }}
          >
            {description}
          </Typography>
          {actionButtons && actionButtons.length > 0 && <Divider />}
          <Stack direction={'row-reverse'} spacing={2}>
            {actionButtons?.map(({ title, onClick, variant, loading }) => (
              <CustomButton
                key={title}
                size="medium"
                onClick={() => {
                  onClick();
                }}
                variant={variant}
                loading={loading}
              >
                {title}
              </CustomButton>
            ))}
          </Stack>
        </Stack>
      </StyledPaper>
    </Modal>
  );
};

export default PopupMessage;
