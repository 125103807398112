import { Box } from '@mui/material';
import {
  StyledAddressesModalViewTitle,
  StyledInputLabel,
  StyledOuterBox,
  StyledResponsiveBox,
  StyledSelectedStates,
} from './styles';
import { AddressFormHeaderProps } from './types';
import AddAddressModal from '../../AddAddress';
import { useState } from 'react';
import StateList from 'components/molecules/StateList';
import { TextBadgeProps } from 'components/atoms/TextBadge/types';
import RenderByCondition from 'components/atoms/RenderByCondition';
import RenderByRole from 'components/atoms/RenderByRole';
import { USER_ROLE } from 'constants/index';
import { useSelector } from 'react-redux';
import { getUserRole } from 'store/features/auth/index.selector';

const AddressFormHeaderWithStates: React.FC<AddressFormHeaderProps> = ({
  addressType,
  statesData,
  selectedStateId,
  handleStateClick,
  isStateActive,
  addressesByState,
  custmerId,
  displayAddAddress = true,
}) => {
  const [open, setOpen] = useState(false);
  const userRole = useSelector(getUserRole);
  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <StyledOuterBox>
        <StyledResponsiveBox>
          <RenderByRole
            userRole={userRole}
            allowedRoles={[USER_ROLE.VET, USER_ROLE.TM]}
          >
            <RenderByCondition show={[displayAddAddress]}>
              <StyledInputLabel
                onClick={() => {
                  handleClickOpen();
                }}
                mb={{ xs: 2, md: 0 }}
              >
                <span>{'Add new address'}</span>
              </StyledInputLabel>
            </RenderByCondition>
          </RenderByRole>
        </StyledResponsiveBox>
        <StyledSelectedStates>
          <Box width={{ xs: '100%', md: '30%' }} paddingTop={1}>
            <StyledAddressesModalViewTitle>
              {'Selected states'}
              <>
                &nbsp;(
                {statesData?.length})
              </>
            </StyledAddressesModalViewTitle>
          </Box>
          <Box width={{ xs: '90%', md: '70%' }}>
            <StateList
              states={statesData as TextBadgeProps[]}
              addressesByState={addressesByState}
              selectedStateId={selectedStateId}
              handleStateClick={handleStateClick}
              isStateActive={isStateActive}
            />
          </Box>
        </StyledSelectedStates>
      </StyledOuterBox>
      <AddAddressModal
        open={open}
        setOpen={setOpen}
        addressType={addressType}
        custmerId={custmerId}
      ></AddAddressModal>
    </>
  );
};

export default AddressFormHeaderWithStates;
