import { styled, Box, Typography } from '@mui/material';

const getSelectContainerStyles = (theme) => ({
  width: '22%',
  margin: '-10px 0px 16px 10px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
});

const StyledSelectContainer = styled(Box)`
  ${({ theme }) => getSelectContainerStyles(theme)}
`;

const getHorizontalLineStyle = (theme) => ({
  width: '100%',
  height: '1.2px',
  backgroundColor: theme.palette.neutral[500],
  margin: '0 4px',
  [theme.breakpoints.down('md')]: {
    width: '1.2px',
    height: 80,
    position: 'absolute',
    top: -90,
    left: '5%',
  },
});

export const StyledHorizontalLine = styled(Box)`
  ${({ theme }) => {
    return getHorizontalLineStyle(theme);
  }};
`;

const getContainerStyle = (
  currentStepIndex: boolean | undefined,
  themeColor: string,
  theme
) => ({
  backgroundColor: currentStepIndex ? themeColor : 'transparent',
  marginRight: currentStepIndex ? '6px' : '0',
  marginLeft: currentStepIndex ? '6px' : '0',
  borderRadius: currentStepIndex ? '12px' : '0',
  padding: currentStepIndex ? '8px 0px 8px 8px' : '8px 0px 8px 0px',
  paddingBottom: '12px',
  [theme.breakpoints.down('md')]: {
    marginRight: '6px',
    marginLeft: '6px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: 100,
    position: 'relative',
  },
});

export const StyledContainer = styled(Box)<{
  currentStepIndex: boolean | undefined;
}>`
  ${({ currentStepIndex, theme }) => {
    return getContainerStyle(
      currentStepIndex,
      theme.palette.neutral[200],
      theme
    );
  }};
`;

export const StyledDateTypography = styled(Typography)`
  font-size: 10px;
  margin-top: 15px;
`;

export const StyledDescriptionTypography = styled(Typography)`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.neutral[600]};
  margin-top: 15px;
  font-weight: 500;
  margin-right: 10px;
`;

export const StyledTimelineWrapper = styled(Box)`
  ${({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  })}
`;

export const InnerBox = styled(Box)<{
  isCentered: boolean;
}>(({ isCentered }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: isCentered ? 'center' : 'flex-start',
}));

export const DateDescriptionBox = styled(Box)({
  // If there are specific styles for this box,we will add them here.
});

export { StyledSelectContainer };
