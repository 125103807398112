import { Grid, ListItemButton, Stack, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  StyledListItem,
  StyledListItemText,
  CustomTitle,
  StyledContentContainer,
} from './styles';
import theme from 'theme';
import HeaderWithButton from 'components/molecules/HeaderWithButton';
import StatusPacket from 'components/molecules/StatusPacket';
import CustomButton from 'components/atoms/Button';
import DocItem from 'components/molecules/DocItem';
import DocList from '../DocList';
import RenderByCondition from 'components/atoms/RenderByCondition';

const ListState: React.FC<{
  text: string;
  isSelected: boolean;
  onClick: () => void;
}> = ({ text, isSelected, onClick }) => {
  return (
    <StyledListItem disablePadding isSelected={isSelected} onClick={onClick}>
      <ListItemButton>
        <StyledListItemText isSelected={isSelected} primary={text} />
      </ListItemButton>
    </StyledListItem>
  );
};

const LeftSideBar = ({ states, handleListStateClick, selectedState }) => {
  const { t } = useTranslation();
  return (
    <Grid item md={2} xs={12}>
      <Grid>
        <CustomTitle variant="h6" width={{ xs: 200, md: 500 }}>
          {t('docs.statesList')}
        </CustomTitle>
      </Grid>
      <Grid>
        {states?.map((item, index) => {
          return (
            <ListState
              key={item?.id}
              text={item?.name}
              isSelected={selectedState?.id === item?.id}
              onClick={() => handleListStateClick(item, index)}
            />
          );
        })}
      </Grid>
    </Grid>
  );
};

type OrderDocState = { name: string };

interface OrderStateDocsProps {
  title: string;
  docListData: any;
  orderDocumentStatus?: string;
  orderDocumentDate?: string;
  orderDocumentPacketComment?: string;
  orderDocumentStatusColor?: string;
  showPacketStatus?: boolean;
  orderDocumentStates: Array<OrderDocState>;
  orderDocumentStateValue: OrderDocState;
  showDownloadAll?: boolean;
  actionButtons?: Array<IPacketActionButton>;
  goNext?: () => void;
  onOrderDocumentStateChange: (value: OrderDocState, index: number) => void;
  downloadAllOnClick?: () => void;
  handleEditStatusClick?: () => void;
}
export interface IPacketActionButton {
  title: string;
  onClick: () => void;
  variant?: 'contained' | 'outlined';
  loading?: boolean;
  visible?: boolean;
  disabled?: boolean;
  size?: 'small' | 'medium' | 'large';
}
const OrderStateDocs: React.FC<OrderStateDocsProps> = (props) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const {
    orderDocumentStatus,
    orderDocumentDate,
    orderDocumentPacketComment,
    orderDocumentStatusColor,
    docListData,
    showPacketStatus,
    showDownloadAll,
    downloadAllOnClick
    ,
    actionButtons,
    title,
    handleEditStatusClick,
  } = props;

  return (
    <Grid container direction="column">
      <StyledContentContainer container direction={isMobile ? 'column' : 'row'}>
        <LeftSideBar
          states={props?.orderDocumentStates}
          handleListStateClick={props?.onOrderDocumentStateChange}
          selectedState={props?.orderDocumentStateValue}
        />
        <Grid item md={8} xs={12}>
          <CustomTitle variant="h6" width={{ xs: 300, md: 500 }}>
            {t('docs.stateOfTitle', {
              name: props?.orderDocumentStateValue?.name as any,
            })}
          </CustomTitle>
          <HeaderWithButton
            title={title}
            buttonProps={{
              isHide: showDownloadAll,
              title: t('docs.downloadPacketButton'),
              action: function (): void {
                downloadAllOnClick();
              },
            }}
          ></HeaderWithButton>
          {showPacketStatus && showDownloadAll && (
            <StatusPacket
              date={orderDocumentDate}
              status={orderDocumentStatus}
              comment={orderDocumentPacketComment}
              color={orderDocumentStatusColor}
              editStatus={handleEditStatusClick}
            />
          )}
          <Grid item xs={12} mt={4}>
            <DocList
              data={docListData}
              render={(data) => <DocItem sx={{ mb: 2 }} {...data} />}
            />
          </Grid>

          <Grid
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              marginTop: 5,
            }}
          >
            <Stack direction={'row-reverse'} spacing={2}>
              {actionButtons?.map(
                ({
                  title,
                  onClick,
                  variant,
                  loading,
                  visible,
                  size,
                  disabled,
                }) => (
                  <RenderByCondition show={[visible]} key={title}>
                    <CustomButton
                      size={size}
                      onClick={() => {
                        onClick();
                      }}
                      variant={variant}
                      loading={loading}
                      disabled={disabled}
                    >
                      {title}
                    </CustomButton>
                  </RenderByCondition>
                )
              )}
            </Stack>
          </Grid>
        </Grid>
      </StyledContentContainer>
    </Grid>
  );
};

export default OrderStateDocs;
