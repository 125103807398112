import { FormControl, Grid, Typography, styled } from '@mui/material';

export const StyledTypography = styled(Typography)`
  ${() => ({
    fontWeight: 500,
    marginBottom: '24px',
  })}
`;

export const StyledForm = styled('form')`
  width: 100%;
  height: 100%;
`;

export const StyledGridContainer = styled(Grid)`
  margin: 0;
  width: 100%;
  height: 100%;
  padding-left: 40px;
  padding-right: 40px;

  align-content: baseline;
  ${({ theme }) => {
    return {
      [theme.breakpoints.down('md')]: {
        paddingLeft: '15px',
        paddingRight: '15px',
      },
    };
  }}
`;

export const StyledFormControl = styled(FormControl)`
  ${({ theme }) => ({
    width: '60%',
    maxWidth: 500,
    [theme.breakpoints.down('md')]: {
      maxWidth: 'none',
      width: '90%',
    },
  })}
`;
