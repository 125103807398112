import { Grid, GridProps, Typography } from '@mui/material';
import { StyledButton, VisuallyHiddenInput } from './styles';
import TextBadge from 'components/atoms/TextBadge';

interface DocumentRendrerProps {
  sx?: GridProps['sx'];
  title: string | JSX.Element;
  description: string | JSX.Element;
  bgColor: string;
  badgeColor?: string;
  badgeText?: string;
  buttonActions: Array<{
    title: string;
    icon: JSX.Element;
    type?: 'input' | 'button';
    onClick: (e) => void;
    disabled?: boolean;
  }>;
}

const DocumentRendrer = (props: DocumentRendrerProps) => {
  const {
    sx,
    title,
    description,
    bgColor,
    badgeColor,
    badgeText,
    buttonActions,
  } = props;
  return (
    <Grid container alignItems="center" spacing={2} sx={sx}>
      <Grid item>
        <TextBadge name={badgeText} color={badgeColor} variant="body3" />
      </Grid>
      <Grid item xs>
        <Grid
          container
          bgcolor={bgColor}
          sx={{ padding: 2, borderRadius: '8px', ...sx }}
        >
          <Grid
            md={6}
            container
            item
            direction="row"
            alignItems="center"
            spacing={4}
          >
            <Grid item>{title}</Grid>
            <Grid item>{description}</Grid>
          </Grid>
          <Grid
            md={6}
            container
            item
            alignItems={'center'}
            justifyContent={'flex-end'}
            columnGap={2}
          >
            {buttonActions?.map((button, index) => (
              <Grid item key={index}>
                <StyledButton
                  size="small"
                  onClick={
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    button?.type === 'input' ? () => {} : button?.onClick
                  }
                  endIcon={button?.icon}
                  component={button?.type === 'input' ? 'label' : 'button'}
                  disabled={button?.disabled}
                >
                  <Typography variant="body2" color="neutral.700">
                    {button?.title}
                  </Typography>
                  {button?.type === 'input' && (
                    <VisuallyHiddenInput
                      type="file"
                      onChange={(event) => {
                        const file = event?.target?.files?.[0];
                        if (file) button?.onClick(event);
                        event.target.value = '';
                      }}
                    />
                  )}
                </StyledButton>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DocumentRendrer;
