import {
  Autocomplete,
  Box,
  Chip,
  InputLabel,
  Theme,
  Typography,
  styled,
} from '@mui/material';
import { StyledChipProps } from './types';
import { Size } from 'types';

export const StyledTypographyWithEllipsis = styled(Typography)(() => ({
  marginRight: '8px',
  display: 'flex',
  flex: 1,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  cursor: 'pointer',
}));

export const StyledListItem = styled('li')(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '16px',
}));

export interface StyledErrorTextProps {
  size: Size | string;
}

export const StyledErrorText = styled('span')<
  StyledErrorTextProps & { theme?: Theme }
>`
  ${({ size }) => {
    if (['small', 'medium'].includes(size))
      return {
        fontSize: 12,
      };

    return { fontSize: 14 };
  }};
  margin-top: 8;
  color: ${({ theme }) => theme.palette.error.main};
`;

export const StyledAutoComplete = styled(Autocomplete)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  '& .MuiChip-root': {
    background: theme.palette.primary.main,
  },
  '& .MuiChip-label': {
    color: theme.palette.neutral[50],
  },
  '& .MuiChip-deleteIcon': {
    fill: theme.palette.neutral[50],
  },

  '& .MuiInputBase-root': {
    paddingBlock: '8px !important',
  },
}));

export const StyledChip = styled(Chip)<StyledChipProps>`
  ${({ labelColor, chipBgColor, deleteIconHidden }) => {
    return {
      marginRight: 4,
      zIndex: 10,
      borderRadius: '25px',
      padding: '16px 8px',
      marginBlock: '2px',
      background: `${chipBgColor} !important`,
      '& .MuiChip-label': {
        textFillColor: labelColor,
      },
      '& .MuiChip-deleteIcon': {
        display: deleteIconHidden ? 'none' : 'block',
      },
      '& > svg': {
        fill: `${labelColor} !important`,
      },
    };
  }}
` as React.FC<StyledChipProps>;

export const StyledInputLabel = styled(InputLabel)`
  transform: none;
  position: relative;
`;

export const StyledBox = styled(Box)`
  ${({ theme }) => {
    return {
      flex: 'unset',
      border: `1px solid ${theme.palette.neutral[100]}`,
      marginLeft: '2px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '2px',
    };
  }}
`;
