import { PaginationItem, styled } from '@mui/material';

export const StyledPaginationItem = styled(PaginationItem)(({ theme }) => ({
  '&.Mui-selected': {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: 'white !important',
    border: 'none !important',
  },
  '&.MuiPaginationItem-page': {
    backgroundColor: 'white',
  },
  '&.MuiPaginationItem-previousNext': {
    backgroundColor: 'white',
  },

  '&.MuiButtonBase-root': {
    fontWeight: 400,
    color: `${theme.palette.neutral[800]}`,
    borderRadius: '4px',
    border: `1px solid ${theme.palette.neutral[500]}`,
  },
}));
