import { Badge, BadgeProps, styled } from '@mui/material';

export const StyledHeader = styled('header')(({ theme }) => ({
  // height: '56px',
  flex: '0 0 auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '12px 32px',
  boxShadow:
    '0px 3px 6px rgba(0, 0, 0, 0.07), 0px 7px 14px rgba(65, 69, 88, 0.1)',
  backgroundColor: `${theme.palette.background.default}`,

  '& > div:nth-of-type(1)': {
    display: 'flex',
  },

  [theme.breakpoints.down('md')]: {
    padding: '10px 8px',
  },
}));

export const StyledBadge: any = styled(Badge)(({ theme, isDisabled }: any) => ({
  '& .MuiBadge-badge': {
    backgroundColor: isDisabled ? '#6A7178' : '#D6336C',
    color: isDisabled ? '#6A7178' : '#D6336C',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    width: '18px',
    minWidth: 'unset',
    height: '18px',
    borderRadius: '100%',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '100%',
      animation: isDisabled ? 'none' : 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
})) as BadgeProps & { isDisabled?: boolean };

export const NotificationIconContainer = styled('div')(() => ({
  padding: '0px 10px',
  cursor: 'pointer',
}));

export const LanguageSelectorContainer = styled('div')(() => ({
  padding: '0px 10px',
  cursor: 'pointer',
}));

export const ProfileMenuContainer = styled('div')(() => ({
  padding: '0px 10px',
  cursor: 'pointer',
}));

export const PendingSignaturesContainer = styled('div')(() => ({
  padding: '2px 5px 0 20px',
  margin: '0 0 0 0',
  cursor: 'pointer',
}));
