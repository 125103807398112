import { styled } from '@mui/material';
import CustomButton from 'components/atoms/Button';

export const StyledButton = styled(CustomButton)`
  ${({ theme }) => ({
    boxShadow: 'none',
    background: theme.palette.neutral[50],
    ':hover': {
      borderWidth: 0,
      outline: 'none',
      background: theme.palette.neutral[50],
    },
    ':active': {
      borderWidth: 0,
      outline: 'none',
      background: theme.palette.neutral[50],
    },
    ':focus': {
      borderWidth: 0,
      outline: 'none',
      background: theme.palette.neutral[50],
    },
  })}
`;

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
