import IconButton from '@mui/material/IconButton';
import SignatureIcon from 'components/icons/SignatureIcon';
import { SmallBadge } from './styles';

const PendingSignatures = ({ count }) => {
  return (
    <IconButton
      aria-label="pending-signatures"
      sx={{
        padding: 0,
        margin: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        verticalAlign: 'middle',
      }}
    >
      <SmallBadge
        badgeContent={count}
        color="secondary"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <SignatureIcon />
      </SmallBadge>
    </IconButton>
  );
};

export default PendingSignatures;
