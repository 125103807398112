import { useEffect, useState } from 'react';
import {
  UseTableOptions,
  SelectableRowsState,
  SelectableRowsActions,
} from 'types';

const useSelectableRows = ({
  rows,
  initialMode,
}: UseTableOptions): [SelectableRowsState, SelectableRowsActions] => {
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [isAllUnchecked, setIsAllUnchecked] = useState(false);

  useEffect(() => {
    if (selectedRows.size === 0) {
      setIsAllChecked(false);
    } else if (selectedRows.size >= rows?.length) {
      setIsAllChecked(true);
    } else {
      setIsAllChecked(false);
    }
  }, [selectedRows, rows]);

  const clearSelectedRows = () => {
    setSelectedRows(new Set());
  };

  const AddSelectedRows = (newRows: Set<number>) => {
    setSelectedRows(newRows);
  };

  const onSelectRow = (rowId: number) => {
    if (initialMode === 'single') {
      if (selectedRows.has(rowId)) {
        setSelectedRows(new Set());
        setIsAllChecked(false);
        setIsAllUnchecked(true);
      } else {
        setSelectedRows(new Set([rowId]));
        setIsAllChecked(false);
      }
    } else {
      const updatedSelectedRows = new Set(selectedRows);
      if (updatedSelectedRows.has(rowId)) {
        updatedSelectedRows.delete(rowId);
        if (updatedSelectedRows.size == 0) {
          setIsAllUnchecked(true);
        }
      } else {
        updatedSelectedRows.add(rowId);
        if (updatedSelectedRows.size > 0) {
          setIsAllUnchecked(false);
        }
      }
      setSelectedRows(updatedSelectedRows);
      setIsAllChecked(false);
    }
  };

  const onSelectAllRows = () => {
    if (isAllChecked) {
      setSelectedRows(new Set());
      setIsAllChecked(false);
      setIsAllUnchecked(true);
    } else {
      const rowsData = rows?.map((item) => ({
        id: item.id,
        data: Object.values(item),
      }));
      setSelectedRows(new Set(rowsData?.map((row) => row.id)));
      setIsAllChecked(true);
      setIsAllUnchecked(false);
    }
  };

  return [
    { selectedRows, isAllChecked, isAllUnchecked },
    { AddSelectedRows, clearSelectedRows, onSelectRow, onSelectAllRows },
  ];
};

export default useSelectableRows;
