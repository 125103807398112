import { SVGProps } from 'react';

const SmallArrowUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={7}
    fill="none"
    {...props}
  >
    <path
      fill="#6A7178"
      d="M.719 5.219 5.469.75C5.625.594 5.812.5 6 .5c.156 0 .344.094.5.219l4.75 4.468c.313.282.313.75.031 1.063-.281.313-.75.313-1.062.031l-4.25-4-4.219 4c-.313.282-.781.282-1.063-.031A.71.71 0 0 1 .72 5.219Z"
    />
  </svg>
);
export default SmallArrowUp;
