import { styled } from '@mui/material';

export const StyledButton = styled('button')<{ theme: any }>`
  ${({ theme }) => {
    return {
      border: 'none',
      borderBottomWidth: 1,
      borderBottomColor: theme.palette.primary.main,
      borderBottomStyle: 'solid',
      background: theme.palette.neutral[50],
      cursor: 'pointer',
      paddingInline: 0,
    };
  }}
`;
