import { useEffect, useMemo, useState } from 'react';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { useFormik } from 'formik';
import {
  StyledEditButton,
  StyledForm,
  StyledNoteContainer,
  StyledScrollableContent,
} from './styles';
import { useTranslation } from 'react-i18next';
import CustomButton from 'components/atoms/Button';
import CustomDialog from 'components/molecules/Dialog';
import * as Yup from 'yup';
import CustomInput from 'components/atoms/Input';
import { Note } from 'api/docs';
import HeaderWithButton from 'components/molecules/HeaderWithButton';
import TextBadge from 'components/atoms/TextBadge';
import { EditPen } from 'components/icons';
import theme from 'theme';
import { formatDate } from 'utils';
import { getStateFormNotes } from 'store/features/stateConfigForm/index.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { setFormNotes } from 'store/features/stateConfigForm/index.slice';
import { USER_ROLE } from 'constants/index';
import { getUserRole } from 'store/features/auth/index.selector';

function Notes({ animalGroupId }) {
  const { t } = useTranslation();
  const isSMup = useMediaQuery(theme.breakpoints.up('sm'));
  const dispatch = useDispatch();
  const notes = useSelector(getStateFormNotes);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedNote, setSelectedNote] = useState<Note>(null);
  const userRole = useSelector(getUserRole);

  const handleEditClick = (note) => {
    setSelectedNote(note);
    setIsPopupOpen(true);
  };

  const handleAddClick = () => {
    formik.resetForm({
      values: {
        text: '',
        date: new Date(),
        animalGroupId: animalGroupId,
      },
    });
    setSelectedNote(null);
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };
  const handleSubmit = (values) => {
    if (selectedNote) {
      // Update the existing note
      const updatedNotes = notes?.map((note) => {
        if (
          note.id === selectedNote.id &&
          note?.animalGroupId == animalGroupId
        ) {
          return {
            ...values,
            id: note.id,
            date: new Date(),
          };
        } else {
          return note;
        }
      });

      dispatch(setFormNotes(updatedNotes));
    } else {
      // Add a new note
      const newNote = {
        ...values,
        id: notes.length + 1,
        date: new Date(),
      };

      dispatch(setFormNotes([...notes, newNote]));
    }
    handleClosePopup();
  };

  const validationSchema = Yup.object({
    text: Yup.string().required(t('errorMessages.fieldRequired')),
  });

  const initialValues = useMemo(() => {
    return {
      text: selectedNote ? selectedNote?.text : '',
      date: selectedNote ? selectedNote?.date : new Date(),
      animalGroupId: animalGroupId,
    };
  }, [selectedNote]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    formik.resetForm({ values: initialValues });
  }, [selectedNote]);

  function checkUserRole(userRole) {
    return userRole === USER_ROLE.REGULATORY;
  }

  return (
    <>
      <Grid item mb={2}>
        <HeaderWithButton
          title={t('orderForm.notes')}
          buttonProps={{
            isHide: checkUserRole(userRole),
            title: t('buttons.addStateNote'),
            action: () => handleAddClick(),
          }}
        />
      </Grid>

      {notes
        ?.filter((note) => note?.animalGroupId == animalGroupId)
        ?.map((note) => (
          <StyledNoteContainer key={note?.id}>
            <Grid container>
              <Grid item xs={10}>
                <TextBadge name={formatDate(note?.date)} variant="body3" />
              </Grid>
              <Grid item xs={2}>
                <StyledEditButton
                  onClick={() => handleEditClick(note)}
                  size="small"
                >
                  {t('global.edit')} <EditPen />
                </StyledEditButton>
              </Grid>
              <Grid item xs={12}>
                <StyledScrollableContent>
                  <Typography
                    variant="body1"
                    color={theme.palette.neutral.light}
                  >
                    {note?.text}
                  </Typography>
                </StyledScrollableContent>
              </Grid>
            </Grid>
          </StyledNoteContainer>
        ))}
      <CustomDialog
        open={isPopupOpen}
        handleClose={handleClosePopup}
        modalTitle={selectedNote ? t('docs.editNote') : t('docs.addNotes')}
      >
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid container direction="column">
            <Grid item xs={12} sx={{ width: isSMup ? '100%' : '55%' }}>
              <CustomInput
                multiline
                minRows={5}
                sx={{ width: '100%' }}
                name="text"
                label={t('orderForm.notes')}
                placeholder={t('orderForm.notes')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.text}
                error={Boolean(formik.touched.text && formik.errors.text)}
                errorMessage={formik.errors.text}
              />
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              marginTop="1rem"
              sx={{ width: isSMup ? '100%' : '55%' }}
            >
              <Grid item marginRight="0.25rem">
                <CustomButton
                  size="medium"
                  variant="outlined"
                  onClick={handleClosePopup}
                >
                  {t('orderForm.cancel')}
                </CustomButton>
              </Grid>
              <Grid item columnSpacing={1}>
                <CustomButton size="medium" type="submit">
                  {t('orderForm.save')}
                </CustomButton>
              </Grid>
            </Grid>
          </Grid>
        </StyledForm>
      </CustomDialog>
    </>
  );
}

export default Notes;
