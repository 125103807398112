import { Typography, useTheme } from '@mui/material';
import { StyledButton } from './styles';
import { UnderlineButtonProps } from './types';

const UnderlineButton: React.FC<UnderlineButtonProps> = (props) => {
  const { title, onClick, sx } = props;
  const theme = useTheme();

  return (
    <StyledButton theme={theme} onClick={onClick} sx={sx}>
      <Typography color="primary" variant="body2">
        {title}
      </Typography>
    </StyledButton>
  );
};

export default UnderlineButton;
