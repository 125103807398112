import { Autocomplete, Grid, Popper } from '@mui/material';
import { styled } from '@mui/material/styles';
import theme from 'theme';

export const AutocompleteContainer = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});
export const OptionBox = styled('li')({
  ml: 2,
  mr: 2,
  flexShrink: 0,
  p: '8px',
  alignItems: 'center',
  height: '50px',
  display: 'flex',
  gap: '10px',
  borderBottom: `1px solid  ${theme.palette.neutral[100]}`,
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 300,
  lineHeight: '21px',
  '&:hover': {
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    Color: theme.palette.primary[50],
  },
});

export const PopperContent = styled(Popper)({
  padding: '8px',
  width: '230px',
});
export const StyledAutocomplete = styled(Autocomplete)({
  alignSelf: 'stretch',
  borderRadius: '4px',
});
