import { SVGProps } from 'react';

const EditIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="58"
    height="33"
    fill="none"
    viewBox="0 0 58 33"
    {...props}
  >
    <g fill="#6A7178" clipPath="url(#clip0_5117_56672)">
      <path d="M13.972 21.5H9.148v-8.568h4.824v1.104h-3.528v2.448h3.312v1.092h-3.312v2.808h3.528V21.5zm3.932.12c-.776 0-1.404-.28-1.884-.84-.473-.56-.708-1.392-.708-2.496 0-1.112.24-1.952.72-2.52.48-.568 1.111-.852 1.895-.852.329 0 .613.044.852.132.248.08.46.192.637.336.183.144.34.304.468.48h.072a19.215 19.215 0 01-.06-.48 5.28 5.28 0 01-.037-.576V12.38h1.272v9.12h-.995l-.216-.864h-.06a2.106 2.106 0 01-1.092.852 2.58 2.58 0 01-.864.132zm.3-1.044c.623 0 1.063-.172 1.32-.516.255-.352.383-.876.383-1.572v-.192c0-.76-.123-1.34-.372-1.74-.24-.4-.688-.6-1.343-.6-.52 0-.913.212-1.177.636-.264.416-.396.988-.396 1.716s.133 1.288.396 1.68c.264.392.66.588 1.188.588zm6.193-5.544V21.5h-1.272v-6.468h1.272zm-.624-2.46c.192 0 .36.056.504.168.144.112.216.3.216.564 0 .256-.072.444-.216.564a.797.797 0 01-.504.168.834.834 0 01-.528-.168c-.136-.12-.204-.308-.204-.564 0-.264.068-.452.204-.564a.834.834 0 01.528-.168zm4.95 8.016c.167 0 .34-.016.515-.048.176-.032.328-.068.456-.108v.96a2.21 2.21 0 01-.552.156 3.427 3.427 0 01-.696.072c-.352 0-.676-.06-.972-.18a1.453 1.453 0 01-.696-.624c-.176-.296-.264-.708-.264-1.236v-3.576h-.9v-.576l.948-.48.444-1.368h.78v1.452h1.848v.972h-1.848v3.552c0 .352.084.612.252.78.176.168.404.252.684.252zM46.667 21.167v-9.334h-9.334v9.334h9.334zm0-10.667A1.334 1.334 0 0148 11.833v9.334c0 .74-.6 1.333-1.333 1.333h-9.334A1.334 1.334 0 0136 21.167v-9.334a1.334 1.334 0 011.333-1.333h9.334zm-1.534 4.233l-.666.667-1.367-1.367.667-.666a.354.354 0 01.513 0l.853.853a.354.354 0 010 .513zm-6.466 3.727l4.04-4.04 1.373 1.373-4.04 4.04h-1.373V18.46z"></path>
    </g>
    <rect width="57" height="31" x="0.5" y="1" stroke="#F8F9FA" rx="3.5"></rect>
    <defs>
      <clipPath id="clip0_5117_56672">
        <rect width="58" height="32" y="0.5" fill="#fff" rx="4"></rect>
      </clipPath>
    </defs>
  </svg>
);

export default EditIcon;
