import { useTranslation } from 'react-i18next';
import {
  AccordionDetails,
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  LinearProgress,
  Switch,
  TextField,
  useMediaQuery,
} from '@mui/material';
import theme from 'theme';
import {
  StyledHeaderContainer,
  StyledCustomButton,
  StyledTypography,
  StyledSelectTitle,
  CustomAccordion,
  CustomAccordionSummary,
  StyledBox,
} from './styles';
import ExpandIconOpen from 'components/icons/ExpandIconOpen';
import RequirementIndicator from './RequirementIndicators';
import { useDispatch, useSelector } from 'react-redux';
import {
  getIsEditBySpecies,
  getStateFormAnimalGroups,
  getStateFormContacts,
  getStateFormDocuSignEnabled,
  getStateFormDocumentTypes,
  getStateFormNotes,
  getStateFormStateId,
  getStateFormStateName,
} from 'store/features/stateConfigForm/index.selectors';
import {
  toggleDocuSign,
  toggleEditBySpecies,
  toggleTemplate,
} from 'store/features/stateConfigForm/index.slice';
import {
  ISaveDocumentTypePayload,
  State,
  StateConfig,
  useAddDocumentTypeMutation,
  useGetStatesQuery,
  useLazyGetStateConfigQuery,
  useSaveStateConfigurationMutation,
} from 'api/docs';
import { useEffect, useState } from 'react';
import HeaderWithButton from 'components/molecules/HeaderWithButton';
import {
  AutocompleteContainer,
  StyledAutocomplete,
  OptionBox,
  PopperContent,
} from 'components/molecules/StateSelectList/styles';
import Contacts from './Contacts';
import Notes from './Notes';
import CustomButton from 'components/atoms/Button';
import DocumentPopUp from 'components/molecules/DocumentPopUp';
import { ContactTypeCodes, ContactTypes } from 'enums';
import useToast from 'hooks/useToast';
import RenderByRole from 'components/atoms/RenderByRole';
import { USER_ROLE } from 'constants/index';
import { getUserRole } from 'store/features/auth/index.selector';
import RenderByCondition from 'components/atoms/RenderByCondition';

const StateRequirements = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userRole = useSelector(getUserRole);

  const isSMup = useMediaQuery(theme.breakpoints.up('sm'));

  const [selectedStateId, setSelectedStateId] = useState(1);
  const [documentPopUpOpen, setDocumentPopUpOpen] = useState(false);
  const [allNotRequiredError, setAllNotRequiredError] = useState(false);
  const [stateOptions, setStateOptions] = useState([]);
  const { data: statesData, isSuccess: statesIsSuccess } = useGetStatesQuery();

  const [
    addNewDocumentType,
    {
      data: addDocumentTypeData,
      isSuccess: addDocumentTypeIsSuccess,
      isError: addDocumentTypeIsError,
      isLoading: addDocumentTypeIsLoading,
      error: addDocumentTypeError,
    },
  ] = useAddDocumentTypeMutation();
  const [getStateConfig, { isLoading: configDataIsLoading }] =
    useLazyGetStateConfigQuery();
  const [
    saveStateConfiguration,
    {
      isSuccess: saveStateConfigurationIsSuccess,
      isError: saveStateConfigurationIsError,
      isLoading: saveStateConfigurationIsLoading,
    },
  ] = useSaveStateConfigurationMutation();
  const documentsTypes = useSelector(getStateFormDocumentTypes);
  const editBySpeciesEnabled = useSelector(getIsEditBySpecies);
  const animalGroups = useSelector(getStateFormAnimalGroups);
  const stateName = useSelector(getStateFormStateName);
  const stateId = useSelector(getStateFormStateId);
  const contacts = useSelector(getStateFormContacts);
  const notes = useSelector(getStateFormNotes);
  const docuSignEnabled = useSelector(getStateFormDocuSignEnabled);
  const { toast } = useToast();

  const handleRequirementIndicatorChange = (value) => {
    dispatch(toggleTemplate(value));
  };
  const handleSwitchChange = () => {
    dispatch(toggleEditBySpecies(!editBySpeciesEnabled));
  };

  const handleDocuSignSwitchChange = () => {
    dispatch(toggleDocuSign(!docuSignEnabled));
  };

  useEffect(() => {
    if (statesData && statesIsSuccess) {
      setStateOptions(statesData);
    }
  }, [statesData, statesIsSuccess]);

  useEffect(() => {
    if (selectedStateId) {
      getStateConfig({ id: selectedStateId });
    }
  }, [
    selectedStateId,
    saveStateConfigurationIsSuccess,
    addDocumentTypeIsSuccess,
  ]);
  useEffect(() => {
    if (statesIsSuccess && selectedStateId === undefined) {
      setSelectedStateId(1);
    }
  }, [statesIsSuccess]);
  const handleStateChange = (stateId: number) => {
    if (stateId !== undefined) setSelectedStateId(stateId);
  };

  const handleDocumentPopUpOpen = (value) => {
    setDocumentPopUpOpen(value);
  };

  const handleAddDocument = (documentName) => {
    const payload: ISaveDocumentTypePayload = {
      name: documentName,
      information: [],
      usedInStateRequirements: true,
    };
    addNewDocumentType({ data: payload });
  };

  const handleSuccessToast = () => {
    toast.success(t('messages.templateCreateSuccess'), {});
  };

  const handleErrorToast = () => {
    const error: any = addDocumentTypeError;
    const errorCode = error?.data?.errorCode || 'unknown';

    toast.error(t(`errorCodes.${errorCode}`), {});
  };

  useEffect(() => {
    if (addDocumentTypeIsSuccess) {
      handleSuccessToast();
    } else if (addDocumentTypeIsError) {
      handleErrorToast();
    }
  }, [addDocumentTypeIsSuccess, addDocumentTypeIsError]);

  useEffect(() => {
    if (saveStateConfigurationIsSuccess) {
      toast.success(t('messages.configSaveSuccess'), {});
    } else if (saveStateConfigurationIsError) {
      toast.error(t('messages.configSaveFail'), {});
    }
  }, [saveStateConfigurationIsSuccess, saveStateConfigurationIsError]);

  useEffect(() => {
    if (allNotRequiredError) {
      toast.success(t('errorMessages.oneDocumentRequired'), {});
    }
  }, [allNotRequiredError]);

  const transformContactType = (value) => {
    if (value === ContactTypeCodes.Main || value === ContactTypes.Main)
      return ContactTypes.Main;
    else if (value === ContactTypeCodes.CC || value === ContactTypes.CC)
      return ContactTypes.CC;
    return '';
  };
  const areAllDocumentTypesNotRequired = (data) => {
    if (editBySpeciesEnabled) {
      // Group the data by 'animalGroupId'
      const groups = {};
      data.forEach((documentType) => {
        if (!groups[documentType.animalGroupId]) {
          groups[documentType.animalGroupId] = [];
        }
        groups[documentType.animalGroupId].push(documentType);
      });

      // Check if all 'documentTypes' within each 'animalGroupId' are not required
      for (const groupId in groups) {
        const documentTypes = groups[groupId];
        const areNotRequired = documentTypes.every(
          (docType) => docType.isRequired === false
        );
        if (areNotRequired) {
          return true; // Return true if all documentTypes in this group are not required
        }
      }

      return false; // Return false if no group with all documentTypes unchecked is found
    } else {
      return data.every((documentType) => documentType.isRequired === false);
    }
  };
  const handleConfigSave = () => {
    if (areAllDocumentTypesNotRequired(documentsTypes)) {
      setAllNotRequiredError(true);
    } else {
      const updatedContacts = [];
      const updatedNotes = [];

      contacts?.forEach((contact) => {
        const updatedContact = {
          ...contact,
          type: transformContactType(contact?.type),
          id: 0,
        };

        updatedContacts.push(updatedContact);
      });

      notes?.forEach((note) => {
        const updatedNote = {
          ...note,
          id: 0,
        };

        updatedNotes.push(updatedNote);
      });

      const payload: StateConfig = {
        id: selectedStateId,
        name: stateName,
        animalGroups: animalGroups,
        contacts: updatedContacts,
        notes: updatedNotes,
        editBySpecies: editBySpeciesEnabled,
        documentTypes: documentsTypes,
        docuSignEnabled: docuSignEnabled,
      };

      saveStateConfiguration({ id: selectedStateId, data: payload });
    }
  };

  useEffect(() => {
    if (
      (addDocumentTypeData && addDocumentTypeIsSuccess) ||
      addDocumentTypeIsError
    ) {
      setDocumentPopUpOpen(false);
    }
    setSelectedStateId(stateId);
  }, [addDocumentTypeIsSuccess, addDocumentTypeIsError]);

  return (
    <>
      <StyledHeaderContainer container direction={isSMup ? 'row' : 'column'}>
        <Grid item xs={6}>
          <StyledTypography variant="h4" color="primary.main">
            {t('orderForm.stateInformation')}
          </StyledTypography>
        </Grid>
        <RenderByRole
          userRole={userRole}
          allowedRoles={[USER_ROLE.REGULATORY, USER_ROLE.REGULATORYMANAGER]}
        >
          <Grid item xs={6}>
            <StyledCustomButton onClick={() => handleDocumentPopUpOpen(true)}>
              {t('buttons.addStateDocumentType')}
            </StyledCustomButton>
          </Grid>
        </RenderByRole>
      </StyledHeaderContainer>
      {configDataIsLoading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <Grid
        container
        sx={{ backgroundColor: 'white', padding: isSMup ? 5 : 2 }}
        direction={isSMup ? 'row' : 'column'}
      >
        {isSMup && (
          <Grid item xs={3}>
            <StyledSelectTitle variant="h6">
              {t('docs.statesList')}
            </StyledSelectTitle>
          </Grid>
        )}
        <Grid item xs={9}>
          <StyledSelectTitle variant="h6">
            State of&nbsp;{stateName}
          </StyledSelectTitle>
        </Grid>
        <Grid item xs={3}>
          <AutocompleteContainer>
            <StyledAutocomplete
              options={stateOptions}
              autoHighlight
              sx={{ width: 280 }}
              getOptionLabel={(option) => (option as State).name}
              renderOption={(props, option) => (
                <OptionBox {...props}>{(option as State).name}</OptionBox>
              )}
              onChange={(props, option) =>
                handleStateChange((option as State)?.id)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('orderForm.findStatesList')}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
              PopperComponent={(props) => <PopperContent {...props} />}
            />
          </AutocompleteContainer>
        </Grid>
        <Grid item xs={9}>
          <FormGroup style={{ marginBottom: '1.25rem' }}>
            <FormControlLabel
              control={
                <Switch
                  checked={editBySpeciesEnabled}
                  onChange={handleSwitchChange}
                />
              }
              label={t('labels.speciesSwitchLabel')}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={9} sx={{ width: '100%' }}>
          <RenderByCondition show={[editBySpeciesEnabled]}>
            {animalGroups?.map((group) => {
              return (
                <CustomAccordion
                  key={group?.id}
                  sx={{ width: isSMup ? '75%' : '100%' }}
                >
                  <CustomAccordionSummary expandIcon={<ExpandIconOpen />}>
                    {group?.name}
                  </CustomAccordionSummary>
                  <AccordionDetails>
                    <Grid item mb={2}>
                      <HeaderWithButton
                        title="Documents"
                        buttonProps={{
                          isHide: false,
                        }}
                      />
                    </Grid>
                    {documentsTypes
                      ?.filter((doc) => doc?.animalGroupId === group?.id)
                      ?.map((document) => {
                        return (
                          <Box key={document?.id}>
                            <RequirementIndicator
                              document={document}
                              onChange={handleRequirementIndicatorChange}
                              documentTypes={documentsTypes}
                            />
                          </Box>
                        );
                      })}
                    <Contacts animalGroupId={group?.id} />
                    <Notes animalGroupId={group?.id} />
                  </AccordionDetails>
                </CustomAccordion>
              );
            })}
          </RenderByCondition>

          <RenderByCondition show={[!editBySpeciesEnabled]}>
            <StyledBox sx={{ width: isSMup ? '75%' : '100%' }}>
              <Grid item mb={2}>
                <HeaderWithButton
                  title="Documents"
                  buttonProps={{
                    isHide: false,
                  }}
                />
              </Grid>
              {documentsTypes?.map((document) => {
                return (
                  <RequirementIndicator
                    key={document?.id}
                    document={document}
                    onChange={handleRequirementIndicatorChange}
                    documentTypes={documentsTypes}
                  />
                );
              })}
              <Contacts animalGroupId={null} />
              <Notes animalGroupId={null} />
            </StyledBox>
          </RenderByCondition>
          <StyledBox sx={{ width: isSMup ? '75%' : '100%' }}>
            <Grid item mb={2}>
              <HeaderWithButton
                title={t('docs.signatureProcess')}
                buttonProps={{
                  isHide: false,
                }}
              />
            </Grid>

            <Grid item xs={9}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={docuSignEnabled}
                      onChange={handleDocuSignSwitchChange}
                    />
                  }
                  label={t('docs.docuSign')}
                />
              </FormGroup>
            </Grid>
          </StyledBox>
          <RenderByRole
            userRole={userRole}
            allowedRoles={[USER_ROLE.REGULATORY, USER_ROLE.REGULATORYMANAGER]}
          >
            <Grid
              item
              xs={12}
              md={9}
              container
              justifyContent="flex-end"
              alignItems="center"
            >
              <CustomButton
                size="medium"
                type="button"
                onClick={handleConfigSave}
                loading={saveStateConfigurationIsLoading}
              >
                {t('orderForm.save')}
              </CustomButton>
            </Grid>
          </RenderByRole>
        </Grid>
      </Grid>
      <DocumentPopUp
        loading={addDocumentTypeIsLoading}
        open={documentPopUpOpen}
        setOpen={setDocumentPopUpOpen}
        onAddDocument={handleAddDocument}
        documentTypes={documentsTypes}
      />
    </>
  );
};

export default StateRequirements;
