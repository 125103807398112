import styled from '@emotion/styled';
import {
  Grid,
  ListItem,
  ListItemProps,
  ListItemText,
  ListItemTextProps,
  Typography,
} from '@mui/material';
import CustomButton from 'components/atoms/Button';
import theme from 'theme';

export const StyledTypography = styled(Typography)`
  display: flex;
  padding: var(--2, 16px);
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;
export const StyledContentContainer = styled(Grid)`
  ${() => ({
    backgroundColor: theme.palette.background.paper,
    alignItems: 'flex-start',
    display: 'flex',
    gap: '32px',
    padding: '8px',
  })}
`;
export const CustomTitle = styled(Typography)`
  ${() => ({
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '25px',
    color: theme.palette.primary[800],
  })}
`;

export const StyledListItem = styled(ListItem)<
  ListItemProps & { isSelected?: boolean }
>`
  ${({ isSelected }) => ({
    display: 'flex',
    height: '50px',
    alignItems: 'center',
    gap: '10px',
    alignSelf: 'stretch',
    background: isSelected ? theme.palette.neutral[200] : 'transparent',
    borderBottom: `1px solid ${theme.palette.neutral[100]}`,
  })}
`;
export const StyledListItemText = styled(ListItemText)<
  ListItemTextProps & { isSelected?: boolean }
>`
  ${({ isSelected }) => ({
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: isSelected ? 500 : 300,
    lineHeight: '21px',
    color: isSelected ? theme.palette.primary.main : theme.palette.neutral[700],
    fontSize: '14px',
    '&:hover': {
      fontWeight: 600,
      color: theme.palette.primary.main,
    },
  })}
`;
export const StyledForm = styled('form')`
  padding: 1rem 1rem 1rem 1rem;
  min-width: 500px;
  min-height: 250px;
`;
export const StyledGrid = styled(Grid)`
  display: flex;
  padding: 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Extended-Palette-Blue-Lightest, #e5f7fd);
`;
export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
export const StyledButton = styled(CustomButton)`
  ${() => ({
    boxShadow: 'none',
    background: theme.palette.neutral[50],
    ':hover': {
      borderWidth: 0,
      outline: 'none',
      background: theme.palette.neutral[50],
    },
    ':active': {
      borderWidth: 0,
      outline: 'none',
      background: theme.palette.neutral[50],
    },
    ':focus': {
      borderWidth: 0,
      outline: 'none',
      background: theme.palette.neutral[50],
    },
  })}
`;
export const StyledTitle = styled(Typography)`
  color: ${theme.palette.primary[900]};
  font-family: ${theme.typography.fontFamily};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
`;
