import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { CloseIcon } from 'components/icons';
import { CustomDialogProps, DialogTitleProps } from './types';
import { StyledDialog, StyledModalHeader } from './styles';
import { Grid } from '@mui/material';

const CustomDialogTitle: React.FC<DialogTitleProps> = ({
  children,
  onClose,
  ...other
}) => {
  return (
    <StyledModalHeader>
      <DialogTitle sx={{ m: 0, p: 0, width: '100%' }} {...other}>
        <Grid container>
          <Grid item xs={11}>
            {children}
          </Grid>
          <Grid item xs={1}>
            {onClose ? (
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </Grid>
        </Grid>
      </DialogTitle>
    </StyledModalHeader>
  );
};

const CustomDialog: React.FC<CustomDialogProps> = ({
  modalTitle,
  children,
  open,
  handleClose,
  maxWidth = 'lg',
  fullScreen = false,
  sx,
  containerSx,
}) => {
  return (
    <StyledDialog
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      sx={{ ...containerSx }}
    >
      <CustomDialogTitle id="customized-dialog-title" onClose={handleClose}>
        {modalTitle}
      </CustomDialogTitle>
      <DialogContent
        sx={{ md: { width: '70rem' }, xs: { width: '100%' }, ...sx }}
        dividers
      >
        {children}
      </DialogContent>
    </StyledDialog>
  );
};
export default CustomDialog;
