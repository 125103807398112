import { COUNTRY_CODE } from 'constants/index';
import dayjs from 'dayjs';

export const displayByCountry = () => {
  const countryCode = process.env.REACT_APP_COUNTRY;

  return {
    displayOnlyForUS: countryCode === COUNTRY_CODE.US,
    displayOnlyForFR: countryCode === COUNTRY_CODE.FR,
  };
};

export const isUsCountry = process.env.REACT_APP_COUNTRY === COUNTRY_CODE.US;
export const isFrCountry = process.env.REACT_APP_COUNTRY === COUNTRY_CODE.FR;

export const displayCurrencyByCountry = () => {
  return process.env.REACT_APP_FIELD_VISIBILITY_CURRENCY_SYMBOL_COUNTRY;
};

export function truncateText(text, maxLength) {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }
  return text;
}

export const convertBooleanStringToBoolean = (value: string) =>
  value === 'true';

export const capitalizeFirstLetter = (str: string) =>
  str?.charAt(0)?.toUpperCase() + str?.slice(1);

export const isNumber = (input: string) => {
  const regex = /^\d+$/;

  return regex.test(input);
};

const convertToBoolean = (obj) => {
  for (const key in obj)
    obj[`${key}`] = convertBooleanStringToBoolean(obj[key]);
  return obj;
};

export const getFieldVisibility = () => {
  return convertToBoolean({
    SPECIFIC_PRODUCT_VISIBILITY:
      process.env.REACT_APP_FIELD_VISIBILITY_SPECIFIC_PRODUCT,
    SWITCH_ADDRESS_TYPE_VISIBILITY:
      process.env.REACT_APP_FIELD_VISIBILITY_SWITCH_ADDRESS_TYPE,
    DOSES_PER_BOTTLE_VISIBILITY:
      process.env.REACT_APP_FIELD_VISIBILITY_DOSES_PER_BOTTLE,
    BOTTLE_USAGE_VISIBILITY:
      process.env.REACT_APP_FIELD_VISIBILITY_BOTTLE_USAGE,
    FILLING_PER_BOTTLE_VISIBILITY:
      process.env.REACT_APP_FIELD_VISIBILITY_FILLING_PER_BOTTLE,
    ROUTE_OF_ADMINISTRATION_VISIBILITY:
      process.env.REACT_APP_FIELD_VISIBILITY_ROUTE_OF_ADMINISTRATION,
    ADDRESS_STATE_VISIBILITY:
      process.env.REACT_APP_FIELD_VISIBILITY_ADDRESS_STATE_FIELD,
    ADDRESS_CAPACITY_VISIBILITY:
      process.env.REACT_APP_FIELD_VISIBILITY_ADDRESS_CAPACITY_FIELD,
    ADDRESS_COMPLEX_VISIBILITY:
      process.env.REACT_APP_FIELD_VISIBILITY_ADDRESS_COMPLEX_FIELD,
    SHARE_FORM_VISIBILITY: process.env.REACT_APP_FIELD_VISIBILITY_SHARE_FORM,
    PRICING_STEP_VISIBILITY:
      process.env.REACT_APP_FIELD_VISIBILITY_PRICING_STEP,
    BOTTLE_NAME_VISIBILITY: process.env.REACT_APP_FIELD_VISIBILITY_BOTTLE_NAME,
    INTERNAL_REFERENCE_VISIBILITY:
      process.env.REACT_APP_FIELD_VISIBILITY_INTERNAL_REFERENCE,
    ANIMAL_COUNT_VISIBILITY:
      process.env.REACT_APP_FIELD_VISIBILITY_ANIMAL_COUNT,
    SERIALS_VISIBILITY: process.env.REACT_APP_FIELD_VISIBILITY_SERIALS,
    ISOLATE_DATA_VISIBILITY:
      process.env.REACT_APP_FIELD_VISIBILITY_ISOLATE_DATA,
    ISOLATE_DATA_SEROTYPE_VISIBILITY:
      process.env.REACT_APP_FIELD_VISIBILITY_ISOLATE_DATA_SEROTYPE,
    STATE_VISIBILITY: process.env.REACT_APP_FIELD_VISIBILITY_STATE,
    CLINICAL_SIGNS_VISIBILITY:
      process.env.REACT_APP_FIELD_VISIBILITY_CLINICAL_SIGNS,
    VACCINATION_SCHEDULE_REQUIRED:
      process.env.REACT_APP_FIELD_REQUIRED_VACCINATION_SCHEDULE,
    BILL_TO_VISIBILITY: process.env.REACT_APP_FIELD_VISIBILITY_BILL_TO,
    IDM_VISIBILITY: process.env.REACT_APP_FIELD_VISIBILITY_IDM_CODE,
    CUSTOMER_VISIBILITY: process.env.REACT_APP_FIELD_VISIBILITY_CUSTOMER,
    REQUEST_LETTER_COMMENT: process.env.REACT_APP_FIELD_REQUEST_LETTER_COMMENT,
    REORDER_VISIBILITY_ISOLATE:
      process.env.REACT_APP_REORDER_FIELD_VISIBILITY_ISOLATE,
    SHIPPING_ADDRESS_SELECT_VISIBILITY:
      process.env.REACT_APP_FIELD_VISIBILITY_SHIPPING_ADDRESS_SELECT,
    LIMIT_OF_BOTTLES_VISIBILITY:
      process.env.REACT_APP_CREATE_LIMIT_OF_BOTTLES_VISIBILITY,
    ISOLATE_DEROGATION_COLUMN_VISIBILITY:
      process.env.REACT_APP_FIELD_VISIBILITY_ISOLATE_DEROGATION_COLUMN,
    ISOLATES_TABLE_SELECT_VISIBILITY_FARMS:
      process.env.REACT_APP_ISOLATES_TABLE_SELECT_VISIBILITY_FARMS,
    ISOLATES_TABLE_EXPIRATION_DATE_VISIBILITY:
      process.env.REACT_APP_ISOLATES_EXPIRATION_DATE_VISIBILITY,
    ISOLATE_ACTION_COLUMN_VISIBILITY:
      process.env.REACT_APP_ISOLATES_TABLE_ACTION_VISIBILITY,
    CAP_COLOR_VISIBILITY: process.env.REACT_APP_CAP_COLOR_VISIBILITY,
    BOTTLE_PERSONALIZATION_VISIBILITY:
      process.env.REACT_APP_BOTTLE_PERSONALIZATION_VISIBILITY,
    ORDER_DETAILS_VERTICAL_ADDRESSES_DISPLAY:
      process.env.REACT_APP_ORDER_DETAILS_VERTICAL_ADDRESSES_DISPLAY,
    VIEW_ISOLATE_DETAILS_VISIBILITY:
      process.env.REACT_APP_AUTOCOMPLETE_ISOLATE_DETAILS_VISIBILITY,
    AUTOCOMPLETE_ISOLATE_FARM_VISIBILITY:
      process.env.REACT_APP_AUTOCOMPLETE_ISOLATE_FARM_VISIBILITY,
    AUTOCOMPLETE_ISOLATE_SEROTYPE_FIRST_DISPLAY_ORDER:
      process.env.REACT_APP_AUTOCOMPLETE_SEROTYPE_FIRST_DISPLAY_ORDER,
  });
};

export const getDataVisibility = () => {
  return convertToBoolean({
    DASHBOARD_SHIPPING_DATE_VISIBILITY:
      process.env.REACT_APP_DATE_VISIBILITY_SHIPPING_DATE,
    SERIALS_VISIBILITY: process.env.REACT_APP_FIELD_VISIBILITY_SERIALS,
    DASHBOARD_FARM_NAME_VISIBILITY: process.env.REACT_APP_FARM_NAME_VISIBILITY,
    DASHBOARD_ADDRESSE_VISIBILITY:
      process.env.REACT_APP_NON_ADJACENT_ADDRESSE_VISIBILITY,
    ADDRESSES_BY_STATE_VISIBILITY:
      process.env.REACT_APP_SHIPPING_SITES_MANAGEMENT_VISIBLITY,
  });
};

export const getEnvironmentSettings = () => {
  return convertToBoolean({
    ADDRESSES_TYPE_RADIO_BUTTON_DEFAULT_VALUE:
      process.env.REACT_APP_ADDRESSES_TYPE_RADIO_BUTTON_DEFAULT_VALUE,
    BUTTON_VISIBILITY_ADD_SHIPPING_ADDRESS:
      process.env.REACT_APP_BUTTON_VISIBILITY_ADD_SHIPPING_ADDRESS,
    SERIALS_SELECT_VISIBILITY: process.env.REACT_APP_SERIALS_SELECT_VISIBILITY,
    STATE_INFORMATION_VISIBILITY:
      process.env.REACT_APP_STATE_INFORMATION_VISIBILITY,
    ADDRESS_MANAGEMENT_VISIBILITY:
      process.env.REACT_APP_ADDRESS_MANAGEMENT_VISIBILITY,
    VACCINE_INVENTORY_VISIBILITY:
      process.env.REACT_APP_VACCINE_INVENTORY_VISIBILITY,
    COMPLETE_DOC_PROCESS_VISIBILITY:
      process.env.REACT_APP_COMPLETE_DOC_PROCESS_VISIBILITY,
    CREATE_ORDER_BY_ROLE_VISIBILITY:
      process.env.REACT_APP_CREATE_ORDER_BY_ROLE_VISIBILITY,
    REORDER_RESET_NUMBER_OF_BOTTLES:
      process.env.REACT_APP_REORDER_RESET_NUMBER_OF_BOTTLES,
    FARM_VISIBILITY: process.env.REACT_APP_FIELD_VISIBILITY_FARM,
    IDM_VISIBILITY: process.env.REACT_APP_FIELD_VISIBILITY_IDM,
    ISOLATE_ORGAN_VISIBILITY:
      process.env.REACT_APP_ISOLATE_ORGAN_SECTION_VISIBILITY,
    REORDER_REMINDER_VISIBILITY:
      process.env.REACT_APP_CREATE_REORDER_REMINDER_VISIBILITY,
    APP_VERSION_VISIBILITY:
      process.env.REACT_APP_DISPLAY_APP_VERSION_VISIBILITY,
    SELECTED_ADDRESSE_VISIBILITY:
      process.env.REACT_APP_SELECTED_ADDRESSE_VISIBILITY,
    REORDER_RESET_BOTTLES_VOLUMES:
      process.env.REACT_APP_REORDER_RESET_BOTTLES_VOLUMES,
    JDE_ID_VISIBILITY: process.env.REACT_APP_JDE_ID_VISIBILITY,
    SERIAL_NUMBER_ISOLATE_DETAILS_VISIBILITY:
      process.env.REACT_APP_SERIAL_NUMBER_ISOLATE_DETAILS_VISIBILITY,
  });
};

export const ISOLATE_SELECTION_LIMIT =
  process.env.REACT_APP_ISOLATE_SELECTION_LIMIT;

export const displayNumberWithoutComma = (input: number) => {
  return input.toString().replace('.00', '');
};

export const toSpaceSeparatedNumber = (
  num: number | string | undefined | null | ''
) => {
  if (num === undefined || num === null || num === '') return 0;

  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
  })
    .format(num as number)
    .replace(/,/g, ' ');
};

export const camelCaseToSpaceSeparated = (camelCaseString) => {
  return camelCaseString.replace(/([A-Z])/g, ' $1').trim();
};
export const getShippingSitesSelectionModeByCountry = () => {
  return convertToBoolean({
    IS_SHIPPING_SELECTION_MODE_MULTIPLE:
      process.env.REACT_APP_SHIPPING_ADDRESSES_IS_SELECTION_MODE_MULTIPLE,
  });
};

export const formatDate = (date): any => {
  if (!date) return '';
  return dayjs(date).format('L');
};

export const formatNumber = (value) => {
  return value === 0 ? '' : value;
};

export const prepareDownloadFileUrl = (id) =>
  `${process.env.REACT_APP_API_BASE_URL}api/Document/download/${id}`;

export const prepareDownloadProductLicenseTemplateUrl = (id) =>
  `${process.env.REACT_APP_API_BASE_URL}api/OrderDocument/getProductLicenseDocument?orderId=${id}`;

export const getDocumentTypeIdByCode = (data, documentCode) =>
  data?.find((item) => item?.code === documentCode)?.id || null;

export const isNullOrEmpty = (str: string | null | undefined) => {
  return str === '' || str === null || str === undefined;
};

export const isNotEmptyOrWhitespace = (
  str: string | null | undefined
): boolean => str !== null && str !== undefined && str.trim() !== '';

export const getDistinctByProperty = (array, property) => {
  const uniqueValues = [];
  return array.filter((item) => {
    if (!uniqueValues.includes(item[property])) {
      uniqueValues.push(item[property]);
      return true;
    }
    return false;
  });
};

export function calculateDateDifference(startDate, endDate) {
  // Parse dates if they are not already dayjs objects
  const start = dayjs(startDate, 'L');
  const end = dayjs(endDate, 'L');

  // Calculate the difference in months
  const diffMonths = end.diff(start, 'month');

  // Get the start of the next month after the start date
  const startOfNextMonth = start.add(diffMonths, 'month');

  // Calculate the difference in days
  const diffDays = end.diff(startOfNextMonth, 'day');

  return { months: diffMonths, days: diffDays };
}

export function truncateString(str, maxLength) {
  if (!str) return '';
  if (str?.length <= maxLength) {
    return str;
  } else {
    return str?.slice(0, maxLength) + '...';
  }
}

export function calculateDaysDateDifference(startDate, endDate) {
  const start = dayjs(startDate, 'L');
  const end = dayjs(endDate, 'L');

  return end?.diff(start, 'day') + 1;
}

export const preparePdfMimeType = (base64) => {
  if (base64) return `data:application/pdf;base64,${base64}`;
  return null;
};
