import {
  Container,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { PricingElement, StyleCustomInput, StyledTitle } from './styles';
import Stack from '@mui/material/Stack';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import StepperButtonGroup from 'components/molecules/StepperButtonGroup';
import { getDosingAndFillingInfo } from 'store/features/orderForm/index.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { setFieldValue } from 'store/features/orderForm/index.slice';
import { BottlesPricingInfoKeys, ORDER_FORM_STEP_NAMES } from 'constants/index';
import { formatNumber, isNumber } from 'utils';
import { useState, useEffect, useMemo } from 'react';

const formName = ORDER_FORM_STEP_NAMES.DOSING_AND_FILLING_INFO;

const PricingStep = (props) => {
  const { goNext, goBack, onCancel } = props;
  const { t } = useTranslation();

  const [isValid, setIsValid] = useState(false);

  return (
    <Container maxWidth="md">
      <StyledTitle variant="h1">{t('orderForm.bottlePricing')}</StyledTitle>
      <PricingList setIsValid={setIsValid} />
      <Grid item xs={12} sx={{ mt: 6 }}>
        <StepperButtonGroup
          goNext={goNext}
          goBack={goBack}
          onCancel={onCancel}
          showNext={true}
          showBackBtn={true}
          showCancelBtn={true}
          nextBtnDisabled={!isValid}
        />
      </Grid>
    </Container>
  );
};

const Pricing = (props: any) => {
  const { onChange, price, blurProp } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const isSMup = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      <PricingElement container spacing={4}>
        <Grid item xs={4} md={6}>
          <Stack
            direction="row"
            spacing={1}
            justifyContent={'start'}
            alignItems="center"
            style={{ height: '100%' }}
          >
            <Typography
              variant="body1"
              sx={{ fontSize: { xs: '0.8rem', md: '1rem' } }}
            >
              Volume:
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: theme.palette.primary.main,
                fontSize: { xs: '0.8rem', md: '1rem' },
              }}
            >
              {props.volume}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: theme.palette.primary.main,
                fontSize: { xs: '0.8rem', md: '1rem' },
              }}
            >
              ml
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={8} md={6}>
          <Stack direction="row" justifyContent={'end'} alignItems="center">
            <StyleCustomInput
              size={isSMup ? 'medium' : 'small'}
              label={t('orderForm.pricePerBottle') + ' *'}
              name={BottlesPricingInfoKeys.BOTTLES}
              value={formatNumber(price)}
              variant="outlined"
              startIcon={<span>$</span>}
              onChange={(event: any) => onChange(event, props.id)}
              onBlur={blurProp}
              inputProps={{
                style: {
                  textAlign: 'center',
                },
              }}
              labelProps={{
                paddingRight: isSMup ? 16 : 19,
                paddingTop: isSMup ? 0 : 1,
                fontSize: { xs: '0.8rem', md: '1rem' },
              }}
            />
          </Stack>
        </Grid>
      </PricingElement>
      <Divider />
    </>
  );
};

const useForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dosingAndFillingInfo = useSelector(getDosingAndFillingInfo);

  const validationSchema = Yup.object({
    bottles: Yup.object().shape({
      value: Yup.array().of(
        Yup.object().shape({
          count: Yup.number(),
          price: Yup.number().when('count', (count: any, schema: any) => {
            if (count > 0) {
              return schema.moreThan(
                0,
                t('errorMessages.bottlesPriceRequired')
              );
            }
            return schema;
          }),
        })
      ),
    }),
  });

  const {
    values,
    errors,
    touched,
    isValid,
    setFieldValue: formikSetFieldValue,
    handleBlur,
  } = useFormik({
    initialValues: dosingAndFillingInfo,

    validationSchema,
    onSubmit: () => {
      console.log('');
    },
    enableReinitialize: true,
    validateOnMount: true,
  });

  return {
    values,
    errors,
    touched,
    isValid,
    formikSetFieldValue,
    handleBlur,
    dispatch,
  };
};

const useLogic = ({
  setIsValid,
  values,
  errors,
  touched,
  isValid,
  formikSetFieldValue,
  dispatch,
}) => {
  useEffect(() => {
    setIsValid(isValid);
  }, [isValid, setIsValid]);

  const setField = (propPathValue, value) => {
    formikSetFieldValue(propPathValue, value);
    dispatch(setFieldValue({ propPath: propPathValue, value, formName }));
  };

  const onChange = (e: any, id: number) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;

    if (!isNumber(fieldValue) && fieldValue !== '') return;

    const numberValue = parseInt(fieldValue) || 0;

    if (numberValue >= 0) {
      const updatedValues = values?.bottles?.value.map(({ ...bottle }) => {
        if (bottle?.id === id) {
          return {
            ...bottle,
            price: numberValue,
          };
        }
        return { ...bottle };
      });
      const propPathValue = `${fieldName}.value`;
      setField(propPathValue, updatedValues);
    }
  };

  const errorMessage = errors?.bottles?.value.find(
    (error: any) => error?.price !== undefined
  )?.price;

  const isError = touched?.bottles && Boolean(errors?.bottles);

  const bottles = useMemo(() => {
    return values?.bottles?.value?.filter((bottle) => bottle?.count > 0);
  }, [values?.bottles]);

  return {
    onChange,
    errorMessage,
    isError,
    bottles,
  };
};

const PricingList = ({ setIsValid }) => {
  const {
    values,
    errors,
    touched,
    isValid,
    formikSetFieldValue,
    handleBlur,
    dispatch,
  } = useForm();
  const { onChange, errorMessage, isError, bottles } = useLogic({
    setIsValid,
    values,
    errors,
    touched,
    isValid,
    formikSetFieldValue,
    dispatch,
  });

  return (
    <>
      {bottles?.map((bottle) => (
        <Pricing
          key={bottle?.id}
          volume={bottle?.volume}
          unitVolume={'ml'}
          price={bottle?.price}
          onChange={onChange}
          handleBlur={handleBlur}
          id={bottle?.id}
          blurProp={() =>
            handleBlur({ target: { id: BottlesPricingInfoKeys.BOTTLES } })
          }
        />
      ))}
      {isError && (
        <Typography variant="body2" color="error" sx={{ my: 1 }}>
          {errorMessage}
        </Typography>
      )}
    </>
  );
};

export default PricingStep;
