'use client';

import { Provider } from 'react-redux';
import { store } from 'store';
import theme from 'theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { MsalProvider, useMsal } from '@azure/msal-react';
import RoutesComponent from 'routes';
import ToastProvider from 'context/toast';
import MsalSingleton from 'config/msal';
import 'config/date.ts';
import { useEffect } from 'react';

const pca = MsalSingleton.getInstance();

const GOOGLE_TAG_MANAGER_ID = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;
const GOOGLE_TAG_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_TAG_ANALYTICS_ID;

const useGtm = () => {
  const data: any = useMsal();

  useEffect(() => {
    let gTmScript = null;
    let gAtmScript = null;

    const addGtm = (
      window: any,
      document: any,
      tagName: any,
      dataLayerName: any,
      trackingId: any
    ) => {
      return new Promise<void>((resolve, reject) => {
        window[dataLayerName] = window[dataLayerName] || [];
        window[dataLayerName].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js',
        });

        const firstScriptTag = document.getElementsByTagName(tagName)[0];
        gTmScript = document.createElement(tagName);
        const dataLayerParam =
          dataLayerName !== 'dataLayer' ? '&l=' + dataLayerName : '';

        gTmScript.async = true;
        gTmScript.src =
          'https://www.googletagmanager.com/gtm.js?id=' +
          trackingId +
          dataLayerParam;
        gTmScript.onload = resolve;
        gTmScript.onerror = reject;
        firstScriptTag.parentNode.insertBefore(gTmScript, firstScriptTag);
      });
    };

    function addGoogleTagAnalyticsManagerScript(trackingId) {
      return new Promise<void>((resolve, reject) => {
        gAtmScript = document.createElement('script');
        gAtmScript.async = true;
        gAtmScript.src =
          'https://www.googletagmanager.com/gtag/js?id=' + trackingId;

        gAtmScript.onload = resolve;
        gAtmScript.onerror = reject;
        document.head.appendChild(gAtmScript);

        const initializeFunction = function () {
          (window as any).dataLayer = (window as any).dataLayer || [];

          function gtag(...args: any[]) {
            (window as any).dataLayer.push(...args);
          }
          gtag('js', new Date());
          gtag('config', trackingId);
        };

        initializeFunction();
      });
    }

    if (data?.accounts?.length) {
      addGtm(window, document, 'script', 'dataLayer', GOOGLE_TAG_MANAGER_ID)
        .then(() => addGoogleTagAnalyticsManagerScript(GOOGLE_TAG_ANALYTICS_ID))
        .catch((error) => console.error('Error loading scripts:', error));
    }

    return () => {
      try {
        if (
          gTmScript &&
          document.head?.contains(gTmScript) &&
          data?.accounts?.length
        ) {
          document.head?.removeChild(gTmScript);
        }
        if (
          gAtmScript &&
          document.head?.contains(gAtmScript) &&
          data?.accounts?.length
        ) {
          document.head?.removeChild(gAtmScript);
        }
      } catch (error) {
        console.error('Error loading scripts:', error);
      }
    };
  }, [data]);
};

const GtmWrapper = ({ children }) => {
  useGtm();

  return children;
};

function App() {
  useGtm();

  return (
    <MsalProvider instance={pca}>
      <GtmWrapper>
        <Provider store={store}>
          <ToastProvider>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <RoutesComponent />
            </ThemeProvider>
          </ToastProvider>
        </Provider>
      </GtmWrapper>
    </MsalProvider>
  );
}

export default App;
