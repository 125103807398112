import { Tab, Tabs, styled } from '@mui/material';

export const StyledTabs = styled(Tabs)`
  display: flex;
  height: 49px;
  align-items: flex-end;
  align-self: stretch;
  width: 100%;
`;
export const StyledTab = styled(Tab)`
  display: flex;
  height: 31px;
  flex-direction: column;
  align-items: start;
  gap: 15px;
  flex: 1 0 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  text-transform: none;
`;
