import { Grid, Typography } from '@mui/material';
import { PdfPreviewContainer } from './style';
import { useEffect, useState } from 'react';
import usePdfDoc from 'hooks/useDocMerge';
import ExtensionRequest from './ExtensionRequests';
import IsolateExtensionTab from './IsolateExtensionTab';
import { useTranslation } from 'react-i18next';
import PdfPreview from '../PdfPreview';
import { DownloadIcon } from 'components/icons';
import CustomButton from 'components/atoms/Button';
import CustomInput from 'components/atoms/Input';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  useIsolateExtensionRequestApprovalMutation,
  useIsolateShareRequestApprovalMutation,
} from 'api/isolate';
import { PAGES, USER_ROLE } from 'constants/index';
import { getUserRole } from 'store/features/auth/index.selector';
import { useSelector } from 'react-redux';
import BackdropLoader from 'components/atoms/BackdropLoader';
import CustomDialog from 'components/molecules/Dialog';
import { formatDate } from 'utils';
import useToast from 'hooks/useToast';

const ReviewDocsIsolatesRequests = ({ data, isShareRequest = false }) => {
  const {
    fileDataUri,
    isLoading: pdfIsLoading,
    fetchFiles,
    downloadFile,
  } = usePdfDoc();
  const { t } = useTranslation();
  const params = useParams();
  const userRole = useSelector(getUserRole);
  const navigate = useNavigate();
  const location = useLocation();
  const { toast } = useToast();

  const [
    extensionRequestApprovalMutation,
    {
      data: extensionData,
      isLoading: extensionLoading,
      isSuccess: extensionSuccess,
    },
  ] = useIsolateExtensionRequestApprovalMutation();

  const [
    shareRequestApprovalMutation,
    { data: shareData, isLoading: shareLoading, isSuccess: shareSuccess },
  ] = useIsolateShareRequestApprovalMutation();

  const requestData = isShareRequest ? shareData : extensionData;
  const isLoading = isShareRequest ? shareLoading : extensionLoading;
  const isSuccess = isShareRequest ? shareSuccess : extensionSuccess;

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const validationSchema = Yup.object({
    text: Yup.string().required(t('errorMessages.fieldRequired')),
  });

  useEffect(() => {
    if (isSuccess && !isLoading && requestData?.isSuccess) {
      toast.success(requestData?.message, {
        duration: 1500,
      });
      if (isShareRequest && data?.length > 1) {
        setSelectedItem(0);
        return;
      }
      const doesAnyHistoryEntryExist = location.key !== 'default';
      if (doesAnyHistoryEntryExist) navigate(-1);
      else navigate(PAGES[userRole].ROOT);
    }
  }, [isSuccess, isLoading, requestData]);

  const [selectedItem, setSelectedItem] = useState(0);

  useEffect(() => {
    if (isShareRequest) {
      if (data)
        fetchFiles([
          `Document/download/${data[selectedItem]?.requestLetter?.id}`,
        ]);
    } else if (data?.requestLetter?.id) {
      fetchFiles([`Document/download/${data?.requestLetter?.id}`]);
    }
  }, [data, selectedItem]);

  const initialValues = {
    text: '',
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  });

  const approveRequest = () => {
    if (isShareRequest) {
      shareRequestApprovalMutation({
        isolateRequestId: data[selectedItem].id,
        approvalStatus: 0,
      });
    } else {
      extensionRequestApprovalMutation({
        isolateRequestId: Number(params.id),
        approvalStatus: 0,
      });
    }
    setIsPopupOpen(false);
  };

  const rejectRequest = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const onSave = () => {
    if (isShareRequest) {
      shareRequestApprovalMutation({
        isolateRequestId: data[selectedItem]?.id,
        approvalStatus: 1,
        declineComment: formik.values.text,
      });
    } else {
      extensionRequestApprovalMutation({
        isolateRequestId: Number(params.id),
        approvalStatus: 1,
        declineComment: formik.values.text,
      });
    }
    setIsPopupOpen(false);
  };

  const handleSelectItem = (index) => {
    setSelectedItem(index);
  };

  const tabs = [
    {
      label: isShareRequest
        ? t('isolates.isolateShare', { count: data?.length })
        : t('isolates.isolateExtension', { count: 1 }),
      tabComponent: (
        <ExtensionRequest
          data={
            isShareRequest
              ? data?.map((item) => ({
                  date: `${item?.customer?.name}`,
                  title: 'Share letter',
                  description: formatDate(item?.requestLetter?.creationDate),
                  bgColor: '#F8F9FA',
                  requestFileName: `${item?.requestLetter?.fileName}`,
                  buttonActions: [
                    {
                      title: 'download',
                      icon: <DownloadIcon />,
                      type: 'button',
                      onClick: () =>
                        downloadFile(
                          null,
                          `Extension request ${item?.requestLetter?.id}`
                        ),
                    },
                  ],
                }))
              : [
                  {
                    date: formatDate(data?.requestLetter?.creationDate),
                    title: `Extension request ${data?.requestLetter?.id}`,
                    description: formatDate(data?.requestLetter?.creationDate),
                    bgColor: '#F8F9FA',
                    buttonActions: [
                      {
                        title: 'download',
                        icon: <DownloadIcon />,
                        type: 'button',
                        onClick: () =>
                          downloadFile(
                            null,
                            `Extension request ${data?.requestLetter?.id}`
                          ),
                      },
                    ],
                  },
                ]
          }
          {...(isShareRequest && {
            selectedItem,
            onSelectItem: handleSelectItem,
          })}
          isShareRequest={isShareRequest}
        />
      ),
    },
  ];

  return (
    <Grid container columnSpacing={8} padding={{ xs: 1, md: 2 }}>
      <Grid item xs={12} md>
        <IsolateExtensionTab
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          canSwitchTab={true}
        />
      </Grid>
      <PdfPreviewContainer item xs={12} md>
        <Grid container bgcolor="neutral.200" maxHeight={750}>
          <PdfPreview
            headerTitle={t('isolates.documentsPreview')}
            fileDataUri={fileDataUri}
            pdfIsLoading={pdfIsLoading}
            buttonActions={
              userRole === USER_ROLE.CSS
                ? []
                : [
                    {
                      id: 1,
                      title: 'Decline',
                      onClick: () => rejectRequest(),
                      endIcon: ' ',
                      variant: 'outlined',
                    },
                    {
                      id: 2,
                      title: (
                        <Typography sx={{ color: 'white' }}>Approve</Typography>
                      ),
                      onClick: () => approveRequest(),
                      endIcon: ' ',
                    },
                  ]
            }
          />
        </Grid>
      </PdfPreviewContainer>
      <CustomDialog
        open={isPopupOpen}
        handleClose={handleClosePopup}
        modalTitle={t('isolates.refusalComment')}
        maxWidth="md"
      >
        <Grid
          container
          direction="column"
          width={{ md: 800, xs: '100%' }}
          p={{ md: 2, xs: 1 }}
          rowSpacing={3}
        >
          <Grid item xs={12}>
            <Typography>{t('isolates.refusalCommentDescription')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomInput
              multiline
              minRows={5}
              sx={{ width: '100%' }}
              name="text"
              label={t('isolates.comment')}
              placeholder={t('isolates.refusalComment')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              marginTop="1rem"
            >
              <Grid item marginRight="0.25rem">
                <CustomButton
                  size="medium"
                  variant="outlined"
                  onClick={handleClosePopup}
                >
                  {t('orderForm.cancel')}
                </CustomButton>
              </Grid>
              <Grid item columnSpacing={1}>
                <CustomButton
                  size="medium"
                  onClick={() => onSave()}
                  disabled={!(formik.isValid && formik.dirty)}
                >
                  {t('orderForm.save')}
                </CustomButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CustomDialog>
      <BackdropLoader open={isLoading} />
    </Grid>
  );
};

export default ReviewDocsIsolatesRequests;
