import React, { useEffect } from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PopupMessage from 'components/molecules/PopupMessage';
import { useSelector } from 'react-redux';
import { getFieldVisibility } from 'utils';
import { StyledFormHeaderContainer } from './styles';
import {
  getUserAccount,
  getUserRole,
} from 'store/features/auth/index.selector';
import { PAGES, USER_ROLE } from 'constants/index';
import useShareOrder from 'hooks/useShareOrder';
import {
  getSelectedCustomer,
  getSelectedVet,
} from 'store/features/orderForm/index.selectors';
import { ShareIcon } from 'components/icons';
import RenderByCondition from 'components/atoms/RenderByCondition';
import { useNavigate } from 'react-router';
import useToast from 'hooks/useToast';

const { SHARE_FORM_VISIBILITY } = getFieldVisibility();

interface FormHeaderProps {
  hideShare?: boolean;
}

const FormHeader = (props: FormHeaderProps) => {
  const { hideShare = false } = props;
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const userAccount = useSelector(getUserAccount);
  const vetData = useSelector(getSelectedVet);
  const isSelectedVet = vetData?.value?.value?.length !== 0;
  const customer = useSelector(getSelectedCustomer);
  const isSelectedCustomer = customer?.value?.value?.length !== 0;
  const {
    handleShareDraft,
    shareOrderIsLoading,
    saveReOrderIsLoading,
    editOrderIsLoading,
    saveOrderIsLoading,
    shareOrderIsSuccess,
    shareOrderIsError,
    saveOrderIsError,
    saveOrderIsSuccess,
  } = useShareOrder();
  const { toast } = useToast();
  const navigate = useNavigate();
  const userRole = useSelector(getUserRole);

  const actionButtons = [
    {
      title: t('orderForm.shareDraftButton'),
      onClick: async () => {
        await handleShareDraft();
        handleClose();
      },
      loading:
        shareOrderIsLoading ||
        editOrderIsLoading ||
        saveOrderIsLoading ||
        saveReOrderIsLoading,
    },
  ];

  useEffect(() => {
    if (shareOrderIsSuccess || saveOrderIsSuccess) {
      toast.success(t('messages.shareOrderSuccess'), {
        onFinishCallBack: () => {
          navigate(`${PAGES[userRole].DASHBOARD}`);
        },
      });
    } else if (shareOrderIsError || saveOrderIsError) {
      toast.error(t('messages.shareOrderFail'), {
        duration: 3000,
      });
    }
  }, [
    shareOrderIsSuccess,
    shareOrderIsSuccess,
    saveOrderIsError,
    saveOrderIsSuccess,
  ]);

  return (
    <StyledFormHeaderContainer>
      <Grid item xs={11}>
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography
            variant="h4"
            color="primary.main"
            style={{ alignContent: 'center' }}
          >
            {t('orderForm.titleOrder')}
          </Typography>
        </Box>
      </Grid>
      <RenderByCondition
        show={[
          isSelectedVet && isSelectedCustomer && SHARE_FORM_VISIBILITY,
          !hideShare,
        ]}
      >
        <Grid item justifyContent="flex-end" p={0} mr={'3px'}>
          <IconButton onClick={handleOpen} disabled={!isSelectedVet}>
            <ShareIcon />
          </IconButton>
          <PopupMessage
            isOpen={open}
            handleClose={handleClose}
            title={t('orderForm.shareOrderTitle')}
            description={t('orderForm.shareOrderDescription', {
              name:
                userAccount.role == USER_ROLE.TM
                  ? t('orderForm.vet')
                  : t('orderForm.tm'),
            })}
            actionButtons={actionButtons}
          />
        </Grid>
      </RenderByCondition>
    </StyledFormHeaderContainer>
  );
};

export default FormHeader;
