import { api } from 'config/api';
import { IContact } from 'types';

interface IGetOrderStateDocsRes {
  states: Array<any>;
  orderId?: number;
  documents: Array<any>;
  documentTypes: Array<any>;
}

interface ISentPacketPayload {
  orderId: number | string;
  stateId: number | string;
  contacts: Array<IContact>;
  emailSubject: string;
  emailBody: string;
  documentId: number;
}

export type State = {
  id: number;
  name: string;
  code: string;
  animalGroups: AnimalGroup[];
  contacts: Contact[];
  notes: Note[];
  stateDocumentTypeInformation: StateDocumentTypeInformation[];
};

export type StateConfig = {
  id: number;
  name: string;
  code?: string;
  animalGroups: AnimalGroup[];
  contacts: Contact[];
  notes: Note[];
  documentTypes: DocumentType[];
  editBySpecies: boolean;
  docuSignEnabled: boolean;
};

export type AnimalGroup = {
  id: number;
  name: string;
};

export type DocumentType = {
  id: number;
  name: string;
  code?: number;
  isRequired?: boolean;
  animalGroupId?: number;
  information: Information[];
};

export type StateDocumentTypeInformation = {
  documentTypeId: number;
  informationId: number | null;
  animalGroupId: number;
  stateId: number;
};

export type Information = {
  id: number;
  text: string;
  isRequired?: boolean;
  animalGroupId?: number;
  documentTypeId?: number;
};

export type Contact = {
  id: number;
  name: string;
  role: string;
  email: string;
  type: string;
  stateId: number;
  animalGroupId?: number;
};
export type Note = {
  id: number;
  text: string;
  date: Date;
  stateId: number;
  animalGroupId?: number;
};

export interface ISaveStateConfigPayload {
  id: number;
  animalGroups: AnimalGroup[];
  contacts: Contact[];
  notes: Note[];
  stateDocumentTypeInformation: StateDocumentTypeInformation[];
  editBySpecies?: boolean;
}
export type ISaveDocumentTypePayload = {
  name: string;
  code?: number;
  information: Information[];
  usedInStateRequirements: boolean;
};

const transformStateConfigResponse = (res: any) => res;
const transformStatesResponse = (res: any) => res;
const transformDocumentTypesResponse = (res: any) => res;

const docApi = api.injectEndpoints({
  endpoints: (build) => ({
    getOrderStateDocs: build.query<IGetOrderStateDocsRes, { orderId: string }>({
      query: ({ orderId }) => {
        return {
          url: `Order/documentsConfiguration/${orderId}`,
        };
      },
      providesTags: ['complete-docs'],
      keepUnusedDataFor: 1,
    }),
    getDocById: build.query<void, { url: string }>({
      query: ({ url }) => {
        return {
          url,
          responseHandler: async (response) => await response.arrayBuffer(),
          responseType: 'arrayBuffer',
          cache: 'no-cache',
        };
      },
    }),
    uploadDocument: build.mutation<any, FormData>({
      query: (document) => {
        return {
          url: 'Document/uploadOrderDocument',
          method: 'POST',
          body: document,
        };
      },
      invalidatesTags: ['complete-docs'],
    }),
    generateRequestLetter: build.mutation<any, { orderId: number }>({
      query: ({ orderId }) => {
        return {
          url: `DocumentSigning/sendRequestLetterToSign?orderId=${orderId}`,
          method: 'POST',
        };
      },
    }),
    sentPacket: build.mutation<void, ISentPacketPayload>({
      query: (payload) => {
        return {
          url: 'DocumentSigning/sendPacketToSign',
          method: 'POST',
          body: payload,
        };
      },
    }),
    sentPacketByEmail: build.mutation<void, ISentPacketPayload>({
      query: (payload) => {
        return {
          url: 'Document/sendPacketByEmail',
          method: 'POST',
          body: payload,
        };
      },
    }),
    changeStatusPacket: build.mutation<void, FormData>({
      query: (payload) => {
        return {
          url: 'Document/changePacketDocumentStatus',
          method: 'POST',
          body: payload,
        };
      },
    }),
    getDocumentTypeId: build.query<void, void>({
      query: () => {
        return {
          url: 'DocumentType',
        };
      },
    }),
    getStateConfig: build.query<StateConfig, { id: number }>({
      query: ({ id }) => `State/configuration/${id}`,
      transformResponse: transformStateConfigResponse,
      // TODO: VERIFY LATER
      providesTags: ['delete-contact', 'delete-document', 'edit-document'],
    }),
    getStates: build.query<State[], void>({
      query: () => '/State',
      transformResponse: transformStatesResponse,
    }),
    getDocumentTypes: build.query<DocumentType[], void>({
      query: () => '/DocumentType',
      transformResponse: transformDocumentTypesResponse,
    }),
    updateState: build.mutation<any, { id: number; data: State }>({
      query: ({ id, data }) => ({
        url: `State/${id}`,
        method: 'PUT',
        body: data,
      }),
      transformResponse: transformStateConfigResponse,
    }),
    addDocumentType: build.mutation<any, { data: ISaveDocumentTypePayload }>({
      query: ({ data }) => ({
        url: '/DocumentType',
        method: 'POST',
        body: data,
      }),
      transformResponse: transformStateConfigResponse,
    }),
    saveStateConfiguration: build.mutation<
      any,
      { id: number; data: StateConfig }
    >({
      query: ({ id, data }) => ({
        url: `State/configuration/${id}`,
        method: 'PUT',
        body: data,
      }),
      transformResponse: transformStateConfigResponse,
    }),
    generateDocument113: build.mutation<
      any,
      { stateId: number; orderId: number }
    >({
      query: ({ orderId, stateId }) => {
        return {
          url: `Document/generateDoc113/${orderId}/${stateId}`,
          method: 'POST',
        };
      },
      invalidatesTags: ['complete-docs'],
    }),
    editDocument: build.mutation<any, { name: string; docId: number }>({
      query: ({ name, docId }) => {
        return {
          url: `DocumentType/${docId}`,
          method: 'PUT',
          body: { name },
        };
      },
      invalidatesTags: ['edit-document'],
    }),
    deleteDocument: build.mutation<any, { docId: number }>({
      query: ({ docId }) => {
        return {
          url: `DocumentType/${docId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['delete-document'],
    }),
    deleteContact: build.mutation<any, { contactId: number }>({
      query: ({ contactId }) => {
        return {
          url: `State/removeContact/${contactId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['delete-contact'],
    }),
  }),
});

export const {
  useLazyGetDocByIdQuery,
  useUploadDocumentMutation,
  useGenerateRequestLetterMutation,
  useGetOrderStateDocsQuery,
  useSentPacketMutation,
  useGetDocumentTypeIdQuery,
  useLazyGetStateConfigQuery,
  useUpdateStateMutation,
  useGetStatesQuery,
  useAddDocumentTypeMutation,
  useGetDocumentTypesQuery,
  useSaveStateConfigurationMutation,
  useGenerateDocument113Mutation,
  useChangeStatusPacketMutation,
  useSentPacketByEmailMutation,
  useDeleteContactMutation,
  useDeleteDocumentMutation,
  useEditDocumentMutation,
} = docApi;

export default docApi;
