import * as React from 'react';

const Delete = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={33}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#6A7178"
        d="M18.665 14.5v6.667h-5.333V14.5h5.333Zm-1-4h-3.333l-.667.667h-2.333V12.5h9.333v-1.333h-2.333l-.667-.667ZM20 13.167h-8v8c0 .733.6 1.333 1.333 1.333h5.333c.734 0 1.334-.6 1.334-1.333v-8Z"
      />
    </g>
    <rect width={31} height={31} x={0.5} y={1} stroke="#F8F9FA" rx={3.5} />
    <defs>
      <clipPath id="a">
        <rect width={32} height={32} y={0.5} fill="#fff" rx={4} />
      </clipPath>
    </defs>
  </svg>
);
export default Delete;
