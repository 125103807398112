import React, { useRef, useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { LeftArrow, RightArrowIcon, VerifiedIcon } from 'components/icons';
import TextBadge from 'components/atoms/TextBadge';
import theme from 'theme';
import { StyledBox } from './styles';

type StateInfo = {
  name: string;
  id?: string;
  code?: string;
  color?: string;
};

type StateListProps = {
  states: StateInfo[];
  selectedStateId: string | null;
  handleStateClick: (state: StateInfo) => void;
  isStateActive?: boolean;
  addressesByState?: any[];
};

const StateList: React.FC<StateListProps> = ({
  states,
  selectedStateId,
  handleStateClick,
  isStateActive,
  addressesByState,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  const handleScroll = () => {
    if (!containerRef.current) return;
    const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;

    setShowLeftArrow(scrollLeft > 0);
    setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const handleArrowClick = (direction: 'left' | 'right') => {
    if (!containerRef.current) return;

    const scrollAmount = containerRef?.current?.clientWidth * 0.8;
    if (direction === 'left') {
      containerRef?.current?.scrollTo?.({
        left: containerRef?.current?.scrollLeft - scrollAmount,
        behavior: 'smooth',
      });
    } else {
      containerRef?.current?.scrollTo?.({
        left: containerRef?.current?.scrollLeft + scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Box display="flex" alignItems="center" width="100%">
      {showLeftArrow && (
        <IconButton
          data-testid="left-arrow-button"
          onClick={() => handleArrowClick('left')}
        >
          <LeftArrow />
        </IconButton>
      )}

      <StyledBox ref={containerRef} data-testid="state-list-container">
        {states?.map((state) => (
          <TextBadge
            key={state?.id}
            name={state.name}
            color={
              selectedStateId === state.id
                ? theme.palette.info[500]
                : state.color
            }
            onClick={isStateActive ? () => handleStateClick(state) : undefined}
            active={selectedStateId === state.id}
            IconComponent={
              addressesByState?.some((s) => s?.stateId?.toString() == state.id)
                ? VerifiedIcon
                : undefined
            }
          />
        ))}
      </StyledBox>
      {showRightArrow && (
        <IconButton
          data-testid="right-arrow-button"
          onClick={() => handleArrowClick('right')}
        >
          <RightArrowIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default StateList;
