import { Paper, Typography, TypographyProps, styled } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { TVariant } from './types';

const StyledContainer = styled(Paper)<{ color: string }>(({ color }) => ({
  borderRadius: '25px',
  backgroundColor: alpha(color, 0.1),
  padding: '4px 8px 4px 8px',
  color: color,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  display: 'inline-flex',
  alignItems: 'center',
}));

const StyledTypography = styled(Typography)(() => ({
  lineHeight: '24px',
  fontWeight: '500',
  display: 'inline-flex',
})) as React.FC<Omit<TypographyProps, 'variant'> & { variant: TVariant }>;

export { StyledContainer, StyledTypography };
