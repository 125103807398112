import { Backdrop, CircularProgress } from '@mui/material';
import { BackdropLoaderProps } from './types';

const BackdropLoader: React.FC<BackdropLoaderProps> = ({ open }) => {
  return (
    <Backdrop
      data-testid="backdrop"
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <CircularProgress color="primary" data-testid="circular-progress" />
    </Backdrop>
  );
};

export default BackdropLoader;
