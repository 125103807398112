import { DialogContent, IconButton } from '@mui/material';
import {
  StyledDialog,
  StyledModalHeader,
  StyledModalHeaderTitle,
} from './styles';
import { CustomModalProps } from './types';
import { CloseIcon } from 'components/icons';

const CustomModal: React.FC<CustomModalProps> = ({
  title,
  open,
  children,
  setOpen,
  ...props
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <StyledDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={props.maxWidth}
      >
        <StyledModalHeader>
          <StyledModalHeaderTitle variant={'body1'}>
            {title}
          </StyledModalHeaderTitle>
          {open ? (
            <IconButton
              aria-label="close"
              onClick={() => setOpen(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
                padding: { md: 1, xs: 0 },
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </StyledModalHeader>
        <DialogContent dividers>{children}</DialogContent>
      </StyledDialog>
    </div>
  );
};

export default CustomModal;
