import { createTheme, experimental_extendTheme } from '@mui/material';
import './types.d.ts';

const muiTheme = experimental_extendTheme();

export const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      '50': '#e9ebf4',
      '100': '#c7cde4',
      '200': '#a3aed1',
      '300': '#808ebe',
      '400': '#6574b1',
      '500': '#4b5ca4',
      '600': '#44549b',
      '700': '#3c4a8f',
      '800': '#354182',
      '900': '#29306b',
      main: '#29306B',
    },
    secondary: {
      50: '#e0f4fd',
      100: '#b0e2f9',
      200: '#7bd0f5',
      300: '#43bdf1',
      400: '#00afef',
      500: '#00a1ec',
      600: '#0094de',
      700: '#0070b6',
      800: '#0070b6',
      900: '#005195',
      main: '#00aeef',
    },
    neutral: {
      '50': '#FFFFFF',
      '100': '#F8F9FA',
      '200': '#F1F3F5',
      '300': '#E9ECEF',
      '400': '#DEE2E6',
      '500': '#CED4DA',
      '600': '#ADB5BD',
      '700': '#6A7178',
      '800': '#4F575E',
      '900': '#272B30',
      main: '#272B30',
    },
    background: {
      default: '#f0f2f6',
    },
    info: {
      '50': '#e0f4fd',
      '100': '#b0e2f9',
      '200': '#7bd0f5',
      '300': '#43bdf1',
      '400': '#00afef',
      '500': '#00a1ec',
      '600': '#0094de',
      '700': '#0081ca',
      '800': '#0070b6',
      '900': '#005195',
      main: '#00aeef',
      light: '#e5f7fd',
    },
    success: {
      '50': '#e7f5ea',
      '100': '#c6e7cc',
      '200': '#a2d7ac',
      '300': '#7cc88b',
      '400': '#5fbc73',
      '500': '#43b05b',
      '600': '#3aa151',
      '700': '#308f46',
      '800': '#277e3b',
      '900': '#165f29',
      main: '#277e3c',
      light: '#e9f2eb',
    },
    warning: {
      '50': '#f7f8e4',
      '100': '#ecedbd',
      '200': '#dfe193',
      '300': '#d4d56a',
      '400': '#cdce4c',
      '500': '#c6c72f',
      '600': '#c5b628',
      '700': '#c3a11d',
      '800': '#c08b10',
      '900': '#b96700',
      main: '#c08a10',
      light: '#fff7e7',
    },
    error: {
      '50': '#ffeaec',
      '100': '#ffcacd',
      '200': '#f69590',
      '300': '#ed6a64',
      '400': '#f6463b',
      '500': '#fa3217',
      '600': '#ec2419',
      '700': '#da1414',
      '800': '#cd030b',
      '900': '#bf0000',
      main: '#da1414',
      light: '#da1414',
    },
  },
  typography: {
    fontFamily: 'Noto Sans, sans-serif',
    fontSize: 14,
  },
  shadows: [
    'none',
    '0px 1px 2px rgba(0, 0, 0, 0.1)',
    '0px 2px 4px rgba(0, 0, 0, 0.1)',
    '0px 4px 6px rgba(0, 0, 0, 0.1)',
    '0px 6px 8px rgba(0, 0, 0, 0.1)',
    '0px 8px 10px rgba(0, 0, 0, 0.1)',
    ...muiTheme.shadows,
  ] as any,
});

theme.typography = {
  ...theme.typography,
  display1: {
    fontSize: '2.25rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '2.125rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '2rem',
    },
  },
  h1: {
    fontSize: '2rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.875rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.75rem',
    },
  },
  h2: {
    fontSize: '1.75rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.625rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
    },
  },
  h3: {
    fontSize: '1.625rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.375rem',
    },
  },
  h4: {
    fontSize: '1.375rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.3125rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.25rem',
    },
  },
  h5: {
    fontSize: '1.25rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.1875rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.125rem',
    },
  },
  h6: {
    fontSize: '1.125rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.0625rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
  },
  body1: {
    fontSize: '1rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
  },
  body2: {
    fontSize: '0.875rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.875rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '0.875rem',
    },
  },
  body3: {
    fontSize: '0.75rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.75rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '0.75rem',
    },
  },
  body4: {
    fontSize: '0.60rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.60rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '0.60rem',
    },
  },
};

export default Object.freeze(theme);
