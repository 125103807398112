import {
  useEditOrderMutation,
  useSaveOrderMutation,
  useSaveReOrderMutation,
  useShareOrderMutation,
} from 'api/orderForm';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getOrderFormMode,
  getOrderId,
  getSaveOrderData,
  getShareData,
} from 'store/features/orderForm/index.selectors';
import { ModeState } from 'store/features/orderForm/index.type';
import { setOrderId } from 'store/features/orderForm/index.slice';

const useShareOrder = () => {
  const orderId = useSelector(getOrderId);
  const dispatch = useDispatch();
  const orderFormMode = useSelector(getOrderFormMode);
  const shareData = useSelector(getShareData);
  const saveReOderData = useSelector(getSaveOrderData);
  const { id: urlOrderId } = useParams<{ id: string }>();
  const idFromParams = parseInt(urlOrderId, 10) || 0;
  const IS_ORDER_MODE = orderFormMode == ModeState.ORDER;
  const IS_REORDER_MODE = orderFormMode == ModeState.REORDER;
  const IS_EDIT_MODE = orderFormMode == ModeState.EDIT;

  const handleShareDraft = async () => {
    // Check if it's ORDER_MODE
    if (IS_ORDER_MODE) {
      // ORDER_MODE: Save or Edit Order
      if (orderId === 0) {
        await saveOrder(shareData);
      } else {
        await editOrder({ id: orderId, data: shareData });
      }
    } else if (IS_REORDER_MODE) {
      // REORDER_MODE: Save Reorder or Edit Order
      saveReOderData.parentOrderID = orderId === 0 ? idFromParams : orderId;
      if (orderId === 0) await saveReOrder(saveReOderData);
      await editOrder({ id: orderId, data: saveReOderData });
    } else if (IS_EDIT_MODE) {
      // EDIT_MODE: Edit Order
      await editOrder({ id: idFromParams, data: shareData });
      dispatch(setOrderId(idFromParams));
    }
  };
  const [
    shareOrder,
    {
      isSuccess: shareOrderIsSuccess,
      isError: shareOrderIsError,
      isLoading: shareOrderIsLoading,
    },
  ] = useShareOrderMutation();
  const [
    editOrder,
    {
      isSuccess: editOrderIsSuccess,
      isError: editOrderIsError,
      isLoading: editOrderIsLoading,
    },
  ] = useEditOrderMutation();
  const [
    saveOrder,
    {
      data: saveOrderData,
      isSuccess: saveOrderIsSuccess,
      isError: saveOrderIsError,
      isLoading: saveOrderIsLoading,
    },
  ] = useSaveOrderMutation();
  const [
    saveReOrder,
    {
      data: saveReOrderData,
      isSuccess: saveReOrderIsSuccess,
      isError: saveReOrderIsError,
      isLoading: saveReOrderIsLoading,
    },
  ] = useSaveReOrderMutation();

  useEffect(() => {
    if (editOrderIsSuccess && !editOrderIsError) {
      shareOrder({ id: idFromParams });
    }
  }, [editOrderIsSuccess, editOrderIsError]);

  useEffect(() => {
    if (saveOrderIsSuccess && !saveOrderIsError && saveOrderData) {
      dispatch(setOrderId(saveOrderData.id));
      shareOrder({ id: saveOrderData.id });
    }
  }, [saveOrderIsSuccess, saveOrderIsError]);

  useEffect(() => {
    if (saveReOrderIsSuccess && !saveReOrderIsError && saveReOrderData) {
      dispatch(setOrderId(saveReOrderData?.id));
      shareOrder({ id: saveReOrderData?.id });
    }
  }, [saveReOrderIsSuccess, saveReOrderIsError]);

  return {
    handleShareDraft,
    shareOrderIsSuccess,
    shareOrderIsLoading,
    saveReOrderIsLoading,
    saveOrderIsLoading,
    editOrderIsLoading,
    shareOrderIsError,
    saveOrderIsError,
    saveOrderIsSuccess,
  };
};
export default useShareOrder;
