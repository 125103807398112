import styled from '@emotion/styled';
import { Grid, Link, Typography } from '@mui/material';
import theme from 'theme';

export const StyledSubHeader = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

export const StyledTitle = styled(Typography)`
  font-size: 28px;
  font-weight: 500;
  line-height: 35px;
  text-align: center;
  flex-grow: 1;
  margin: 0;
  padding-top: 10px;
`;

export const StyledBackLink = styled(Link)`
  ${{
    fontFamily: theme.typography.fontFamily,
    fontSize: '22px',
    paddingRight: '16px',
    fontStyle: 'normal',
    lineHeight: '27px',
    color: theme.palette.primary[600],
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',

    '&:hover': {
      textDecoration: 'underline',
    },
  }}
`;

export const StyledCard = styled(Grid)`
  padding: 20px;
  border-radius: 10px;

  ${{
    boxShadow: theme.shadows[5],
  }}
`;
