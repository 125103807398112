import * as React from 'react';

const ExtensionRequest = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill={props?.fill ?? '#006B52'}
      d="M9.336 1.332H2.669c-.733 0-1.333.6-1.333 1.333v6.667h1.333V2.665h6.667V1.332Zm2.667 2.667H5.336c-.733 0-1.333.6-1.333 1.333v6.667h1.333V5.332h6.667V3.999Zm1.333 2.666H8.003c-.734 0-1.334.6-1.334 1.334v5.333c0 .733.6 1.333 1.334 1.333h5.333c.733 0 1.333-.6 1.333-1.333V7.999c0-.734-.6-1.334-1.333-1.334Zm0 6.667H8.003V7.999h5.333v5.333Z"
    />
  </svg>
);
export default ExtensionRequest;
