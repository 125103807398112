import React from 'react';
import { FormControlLabel } from '@mui/material';

import { CustomCheckBoxProp } from './types';
import {
  StyledCheckBox,
  StyledContainer,
  StyledErrorMessage,
  StyledLabel,
} from './styles';
import {
  ActiveCheckBoxIcon,
  ActiveDisabledCheckBoxIcon,
  NormalCheckBoxIcon,
  DisabledCheckBoxIcon,
  ErrorCheckBoxIcon,
} from 'components/icons';

const CustomCheckBox: React.FC<CustomCheckBoxProp> = ({
  label = '',
  value,
  checked,
  disabled,
  type = 'normal',
  error,
  errorMessage,
  onChange,
  variant,
  ...props
}) => {
  const getIcon = () => {
    if (type === 'active') return <ActiveCheckBoxIcon />;
    else if (type === 'active-disabled') return <ActiveDisabledCheckBoxIcon />;
    else if (type === 'normal') return <NormalCheckBoxIcon />;
    else if (type === 'disabled') return <DisabledCheckBoxIcon />;
    else if (type === 'error') return <ErrorCheckBoxIcon />;
    return <NormalCheckBoxIcon />;
  };

  return (
    <StyledContainer>
      <FormControlLabel
        {...props}
        value={value}
        control={<StyledCheckBox icon={getIcon()} disableRipple={true} />}
        label={<StyledLabel>{label}</StyledLabel>}
        onChange={onChange}
        disabled={disabled}
        checked={checked}
    
      />
      {error && (
        <StyledErrorMessage variant={variant} color="error">
          {errorMessage}
        </StyledErrorMessage>
      )}
    </StyledContainer>
  );
};

export default CustomCheckBox;
