import { Stack, Skeleton } from '@mui/material';

const TableLoaderSkeleton: React.FC = () => {
  return (
    <Stack spacing={2} width="100%">
      <Skeleton animation="wave" variant="rounded" height={30} />
      <Skeleton animation="wave" variant="rounded" height={60} />
      <Skeleton animation="wave" variant="rounded" height={60} />
    </Stack>
  );
};

export default TableLoaderSkeleton;
