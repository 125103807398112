import styled from '@emotion/styled';
import { TableCell, TableRow } from '@mui/material';

export const CustomTableCell = styled(TableCell)(() => ({
  color: '#29306B',
  textAlign: 'left',
  fontSize: '14px',
  fontFamily: 'Noto Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '21px',
  background: '#F1F3F5',
  borderBottom: '1px solid #CED4DA',
}));

export const CustomTableRow = styled(TableRow)(() => ({
  backgroundColor: '#FFFFFF',
  padding: '0px 16px !important',
  height: '56px',
  alignItems: 'flex-start',
  '&:hover': {
    border: '0px solid white',
    backgroundColor: '#F0FAFF !important',
  },
}));
