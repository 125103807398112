import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import isolateApi, { IsolateResponse } from 'api/isolate';

const initialState = {
  isolate: null,
  extendedIsolateData: null,
};

const isolateSlice = createSlice({
  name: 'isolate',
  initialState,
  reducers: {
    setIsolates: (state, action: PayloadAction<IsolateResponse>) => {
      state.isolate = action.payload;
    },
    setExtendedIsolateData: (state, action: PayloadAction<IsolateResponse>) => {
      state.extendedIsolateData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isolateApi.endpoints.isolateExtensionIsolates.matchFulfilled,
      (state, action) => {
        const data = action.payload?.data;

        state.extendedIsolateData = {
          isolateForExtension: data?.isolateForExtension,
          extensionRequestDoctBase64: data?.extensionRequestDoctBase64,
          comment: data?.comment,
          isLoading: false,
          vetsOption: data?.vetsOption,
        };
      }
    );
    builder.addMatcher(
      isolateApi.endpoints.isolateExtensionRequestById.matchFulfilled,
      (state, action) => {
        const data = action.payload?.data;

        state.extendedIsolateData = {
          isolateForExtension: data?.isolateForExtension,
          extensionRequestDoctBase64: data?.extensionRequestDoctBase64,
          comment: data?.comment,
          isLoading: false,
          vetsOption: data?.vetsOption,
        };
      }
    );
    builder.addMatcher(
      isolateApi.endpoints.isolateExtensionIsolates.matchPending,
      (state) => {
        state.extendedIsolateData = {
          isLoading: true,
        };
      }
    );
    builder.addMatcher(
      isolateApi.endpoints.isolateExtensionRequestById.matchPending,
      (state) => {
        state.extendedIsolateData = {
          isLoading: true,
        };
      }
    );
  },
});

export const { setIsolates, setExtendedIsolateData } = isolateSlice.actions;

export default isolateSlice.reducer;
