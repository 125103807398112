import React from 'react';
import { TextBadgeProps } from './types';
import { StyledContainer, StyledTypography, TextWrapper } from './styles';
import { useTheme } from '@mui/material/styles';
import RenderByCondition from '../RenderByCondition';
import { Box } from '@mui/material';

const InfoField: React.FC<TextBadgeProps> = ({
  message = '',
  color,
  variant = 'body3',
  onClick,
  IconComponent = null,
  alignIcon = 'left',
  style = { cursor: onClick ? 'pointer' : 'default', width: '100%' },
  IconStyle,
  onIconClick = null,
}) => {
  const theme = useTheme();
  return (
    <StyledContainer
      color={color || theme.palette.neutral[600]}
      onClick={onClick}
      style={style}
    >
      <RenderByCondition
        show={[IconComponent !== null && alignIcon === 'left']}
      >
        <Box style={{ marginRight: '6px' }} onClick={onIconClick}>
          {IconComponent && <IconComponent />}
        </Box>
      </RenderByCondition>
      <TextWrapper>
        <StyledTypography variant={variant}>{message}</StyledTypography>
      </TextWrapper>

      <RenderByCondition
        show={[IconComponent !== null && alignIcon === 'right']}
      >
        <Box style={IconStyle} onClick={onIconClick}>
          {IconComponent && <IconComponent />}
        </Box>
      </RenderByCondition>
    </StyledContainer>
  );
};

export default InfoField;
