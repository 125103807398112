import { Box, Dialog, styled } from '@mui/material';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export const StyledModalHeader = styled(Box)`
  ${({ theme }) => ({
    backgroundColor: theme.palette.info.light,
    display: 'flex',
    height: '2.75rem',
    padding: '0 1.5rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    color: theme.palette.primary[900],
    width: '100%',
  })}
`;
export const StyledModalHeaderTitle = styled(Box)`
  ${({ theme }) => ({
    color: theme.palette.primary[900],
    fontSize: '1.25rem',
    fontFamily: 'Noto Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '1.25rem',
  })}
`;
