import styled from '@emotion/styled';
import { Button, TableCell, TableContainer, Typography } from '@mui/material';
import theme from 'theme';

export const StyledTypography = styled(Typography)`
  ${{
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    paddingRight: '16px',
    fontStyle: 'normal',
    lineHeight: '21px',
    color: theme.palette.info[300],
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      paddingRight: '8px',
    },
  }}
`;

export const StyledTableBodyCell = styled(TableCell)`
  ${{
    color: theme.palette.neutral.main,
    fontWeight: 500,
    lineHeight: '21px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxHeight: '40px',
  }}
`;

export const StyledTableBodyCellEmail = styled(TableCell)`
  ${{
    color: theme.palette.info[300],
    fontSize: '12px',
    fontWeight: 600,

    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '120px',
    maxHeight: '40px',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '80px',
    },
  }}
`;

export const StyledTableHeadCellText = styled(TableCell)`
  ${{
    fontWeight: 600,
    fontSize: 14,
    color: theme.palette.neutral[700],
  }}
`;

export const StyledTableContainer = styled(TableContainer)`
  ${{
    marginTop: 15,
    width: '100%',
  }}
`;

export const StyledButton = styled(Button)`
  margin-left: 5px;
  padding: 0px;
`;

export const StyledForm = styled('form')`
  padding: 2rem 2rem 2rem 2rem;
  min-width: 500px;
  min-height: 300px;
`;
