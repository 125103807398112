// eslint-disable-next-line quotes
import { api } from 'config/api';
import { OrderSource } from 'enums';

export interface OrderFormResponse {
  customers: Customer[];
  animalGroups: AnimalGroup[];
  capColors: CapColor[];
  states: any[];
  vets?: Vet[];
}

export interface Customer {
  farms: Farm[];
  vets: Vet[];
  shippingAddresses?: ShippingAddress[];
  name?: string;
  type: number;
  mainAddress: MainAddress2;
  billingAddress: BillingAddress2;
  isolates?: Isolate[];
  id: number;
}

export interface Farm {
  isolates?: Isolate[];
  officialNumber: string;
  name: string;
  capacity: number;
  complex: string;
  addressId: number;
  id: number;
  address: Address;
}

export interface Isolate {
  originFarm: OriginFarm;
  originCustomer: OriginCustomer;
  id: number;
  name: string;
  reference: string;
  seroType: string;
  canBeBoosted: boolean;
  expirationDate: string;
  isolationDate: string;
  styleInfo?: StyleInfo;
}

export interface StyleInfo {
  color: string;
  name: string;
}

export interface OriginFarm {
  id: number;
  officialNumber: string;
  name: string;
  capacity: number;
  complex: string;
  addressId: number;
  address: Address;
}

export interface Address {
  id?: number;
  type?: number;
  name?: string;
  street?: string;
  city?: string;
  state?: State;
  stateName?: string;
  zipCode?: string;
  country?: string;
  phoneNumber?: string;
  contact?: string;
  farmId?: number;
  complex?: string;
  capacity?: number;
  stateId?: number;
  officialNumber?: string;
}

export interface OriginCustomer {
  id: number;
  name: string;
  type: number;
  mainAddress: MainAddress;
  billingAddress: BillingAddress;
}

export interface MainAddress {
  id: number;
  state: any;
  name: string;
  street: string;
  city: string;
  zipCode: string;
  country: string;
  phoneNumber: string;
  contact: string;
}

export interface BillingAddress {
  id: number;
  state: any;
  name: string;
  street: string;
  city: string;
  zipCode: string;
  country: string;
  phoneNumber: string;
  contact: string;
}

export interface Address2 {
  id: number;
  state: any;
  name: string;
  street: string;
  city: string;
  zipCode: string;
  country: string;
  phoneNumber: string;
  contact: string;
}

export interface Vet {
  id: number;
  userName: string;
  email: string;
  addresses: any;
  farms: any;
}

export interface ShippingAddress {
  id: number;
  state: any;
  name: string;
  street: string;
  city: string;
  zipCode: string;
  country: string;
  phoneNumber: string;
  contact: string;
}

export interface MainAddress2 {
  id: number;
  state: any;
  name: string;
  street: string;
  city: string;
  zipCode: string;
  country: string;
  phoneNumber: string;
  contact: string;
}

export interface BillingAddress2 {
  id: number;
  state: any;
  name: string;
  street: string;
  city: string;
  zipCode: string;
  country: string;
  phoneNumber: string;
  contact: string;
}

export interface AnimalGroup {
  id: number;
  animalSpecies: AnimalSpecie[];
  name: string;
}

export interface AnimalSpecie {
  id: number;
  products: Product[];
  animalGroupId: number;
  name: string;
  authorizedIsolates: AuthorizedIsolate[];
}

export interface AuthorizedIsolate {
  id: number;
  animalSpecieId: number;
  isolateId: number;
}

export interface Product {
  id: number;
  isBoosting: boolean;
  adjuvants: Adjuvant[];
  name: string;
}

export interface Adjuvant {
  id: number;
  doses?: Dose[];
  administrationRoutes: AdministrationRoute[];
  name: string;
}

export interface AdministrationRoute {
  id: number;
  doses?: Dose[];
  name: string;
}

export interface Dose {
  id: number;
  bottles: Bottle[];
  volume: number;
}

export interface Bottle {
  id: number;
  volume: number;
}

export interface CapColor {
  id: number;
  name: string;
  hexCode: string;
}

export interface OrderBottle {
  bottleCount: number;
  bottlePrice: number;
  fillingId: number;
  bottleId: number;
  orderId: number;
  id: number;
}

export interface ShareOrderApi {
  createdByUserId: number;
  lockedByUserId: number;
  vetId: number;
  lockTime: string;
  status: number;
  isNonAdjacentRequired: boolean;
  animalCount: number;
  monthlyBottleUsage: number;
  bottleTag: string;
  comment: string;
  hasAgreedToTerms?: boolean;
  colorId: number;
  customerId: number;
  orderBottles: OrderBottle[];
  animalGroupId: number;
  animalSpeciesId: number;
  productId: number;
  doseId: number;
  administrationRouteId: number;
  isolateIds: any[];
  farmIds: any[];
  addressIds: any[];
  internalReference: string;
  referredOrderId: number;
  adjuvantId: number;
}

export interface IOrderByIdResponse {
  id: number;
  comment: string;
  bottleTag: string;
  color: CapColor;
  orderIsolates?: OrderIsolate[];
  animalSpecies: AnimalSpecie;
  animalGroup: AnimalGroup;
  adjuvant: Adjuvant;
  administrationRoute: AdministrationRoute;
  dose: Dose;
  animalCount: number;
  orderBottles: OrderBottle[];
  isNonAdjacentRequired: boolean;
  vet: Vet;
  addresses: Address[];
  monthlyBottleUsage: number;
  product: Product;
  internalReference: string;
  orderSerials: OrderSerial[];
  farms: Farm[];
  isDraft?: boolean;
  farm: {
    id: number;
  };
  customer: {
    id: number;
  };
}

interface OrderIsolate {
  orderId: number;
  isolateId: number;
  Isolate: Isolate;
  isBoosted: boolean;
}

interface IGetOrderPayload {
  pageSize?: number;
  pageNumber: number;
  searchKey?: string;
}

interface OrderSerialStepStatus {
  id: number;
  index: number;
  name: string;
  color: string;
  description: string;
}

export interface OrderSerialStep {
  orderSerialId: number;
  orderSerialStepStatusId: number;
  stepDate: string | null;
  orderSerialStepStatus: OrderSerialStepStatus;
}

interface OrderSerial {
  id: number;
  orderId: number;
  serialNumber: string;
  orderSerialSteps: OrderSerialStep[];
}

export interface OrderStatus {
  id: number;
  name: string;
  code: string;
  description: string;
  color: string;
}

interface OrderItem {
  id: number;
  orderNumber: string;
  farmName: string;
  animalSpecies: string;
  nonAdjacentAddressesCount: number;
  numberOfIsolates: number;
  specificProduct: string | null;
  volume: number;
  numberOfDoses: number;
  adjuvant: string;
  customer?: Customer;
  vet?: Vet;
  orderStatus: OrderStatus;
  releaseDate: string;
  creationDate: string;
  confirmationDate: string;
  orderSerials: OrderSerial[];
  isRequestLetterGenerationEnabled: boolean;
  jdeId: string;
  farmOfficialNumber: string;
  source: OrderSource;
  canBeEdited: boolean;
}

export interface IOrderResponse {
  orderSerialStepStatuses: OrderSerialStepStatus[];
  items: OrderItem[];
  totalItems: number;
  totalPages: number;
  pageNumber: number;
  pageSize: number;
}
export interface IOrderSaveResponse {
  id: number;
  createdByUserId: number;
  lockedByUserId: any;
  vetId: number;
  lockTime: any;
  isNonAdjacentRequired: boolean;
  animalCount: number;
  monthlyBottleUsage: any;
  bottleTag: string;
  comment: string;
  hasAgreedToTerms?: boolean;
  colorId: number;
  customerId: number;
  orderBottles: OrderBottle[];
  animalGroupId: number;
  animalSpeciesId: number;
  productId: number;
  doseId: number;
  administrationRouteId: number;
  orderIsolates: OrderIsolateForOrderCreation[];
  farmIds: any[];
  addressIds: any[];
  internalReference: string;
  referredOrderId: any;
  adjuvantId: number;
}

export interface OrderIsolateForOrderCreation {
  isolateId: number;
  isBoosted: boolean;
}

export interface IOrderAddresses {
  id?: number;
  orderId: number;
  addressId: number;
  stateId: number;
  status?: number;
}

interface State {
  id: number;
  name: string;
  code?: string;
}

interface IorderAddressByState {
  orderId: number;
  addressId: number;
  address?: Address;
  stateId?: number;
  state?: State;
  status?: number;
  farm?: Farm;
}
interface ILockOrderPayload {
  orderId: number;
  userId: number;
}
export interface IDocumentType {
  stateId: number;
  name: string;
  code: number;
  information: Information[];
}

export interface Information {
  text: string;
  documentTypeId: number;
  id: number;
}

export interface OrderAddress extends Address {
  status?: number;
}

export interface IAuthorizedCustomerResponse {
  data: AuthorizedIsolate[];
}

export interface IAuthorizedCustomerParams {
  customerId: number | '';
  farmId?: number | '';
  animalSpecieId?: number | '';
}

const transformOrderConfigResponse = (res: any) => res;

const transformOrderByIdResponse = (res: any) => res;

export const orderFormApi = api.injectEndpoints({
  endpoints: (build) => ({
    getOrderConfig: build.query<
      any,
      // OrderFormResponse,
      { userId: number; role: string }
    >({
      query: ({ userId, role }) =>
        `Order/configuration?userId=${userId}&role=${role}`,
      transformResponse: transformOrderConfigResponse,
      providesTags: ['Config'],
      keepUnusedDataFor: 1,
    }),
    updateVet: build.mutation<any, { id: number; data: Vet }>({
      query: ({ id, data }) => ({
        url: `/Vet/${id}`,
        method: 'PUT',
        body: data,
      }),
      transformResponse: transformOrderConfigResponse,
      invalidatesTags: ['vetData'],
    }),
    getVetData: build.query<
      OrderFormResponse,
      { userId: number; role: string }
    >({
      query: ({ userId, role }) =>
        `Order/configuration?userId=${userId}&role=${role}`,
      transformResponse: transformOrderConfigResponse,
      providesTags: ['vetData'],
    }),
    saveOrder: build.mutation<IOrderSaveResponse, any>({
      query: (order) => {
        return {
          url: '/Order',
          method: 'POST',
          body: order,
        };
      },
    }),
    signOrder: build.mutation<any, any>({
      query: ({ orderId }) => {
        return {
          url: `DocumentSigning/sendOrderDocumentToSign?orderId=${orderId}`,
          method: 'POST',
        };
      },
    }),
    getOrderById: build.query<IOrderByIdResponse, number>({
      query: (id) => `Order/${id}`,
      transformResponse: transformOrderByIdResponse,
      providesTags: ['Config'],
    }),
    getOrders: build.query<IOrderResponse, IGetOrderPayload>({
      query: ({ pageSize = 5, pageNumber, searchKey = '' }) => {
        let url = '';

        if (!searchKey)
          url = `/Order/search?PageSize=${pageSize}&PageNumber=${pageNumber}`;
        else
          url = `/Order/search?PageSize=${pageSize}&PageNumber=${pageNumber}&SearchKey=${searchKey}`;

        return {
          url,
        };
      },
      providesTags: ['Orders'],
      keepUnusedDataFor: 0,
    }),
    detailsOrder: build.query<any, number>({
      query: (orderId) => {
        return {
          url: `Order/${orderId}`,
          method: 'GET',
          providesTags: ['Config'],
        };
      },
    }),
    getDocumentById: build.query<any, number>({
      query: (id) => `Document/download/${id}`,
      transformResponse: transformOrderConfigResponse,
    }),
    addDocumentType: build.mutation<any, { data: IDocumentType }>({
      query: ({ data }) => ({
        url: '/DocumentType/',
        method: 'POST',
        body: data,
      }),
    }),
    saveReOrder: build.mutation<any, any>({
      query: (order) => {
        return {
          url: '/Order/reorder',
          method: 'POST',
          body: order,
        };
      },
    }),
    shareOrder: build.mutation<any, { id: number }>({
      query: ({ id }) => ({
        url: `Order/share?orderId=${id}`,
        method: 'POST',
      }),
      transformResponse: transformOrderConfigResponse,
      invalidatesTags: ['Config'],
    }),
    lockOrder: build.mutation<any, ILockOrderPayload>({
      query: ({ orderId, userId }) => ({
        url: `Order/${orderId}/lock/${userId}`,
        method: 'POST',
      }),
      transformResponse: transformOrderConfigResponse,
      invalidatesTags: ['Config'],
    }),
    editOrder: build.mutation<any, { id: number; data: any }>({
      query: ({ id, data }) => ({
        url: `/Order/${id}`,
        method: 'PUT',
        body: data,
      }),
      transformResponse: transformOrderConfigResponse,
      invalidatesTags: ['Config'],
    }),
    getOrderStateDocuments: build.query<any, number>({
      query: (id) => `Order/orderDocuments/${id}`,
      transformResponse: transformOrderByIdResponse,
    }),
    saveOrderAddresses: build.mutation<IOrderAddresses, any>({
      query: (orderAddresses) => {
        return {
          url: '/Order/orderAddresses',
          method: 'POST',
          body: orderAddresses,
        };
      },
      invalidatesTags: ['orderAddresses'],
    }),
    orderAddresses: build.query<IorderAddressByState[], number>({
      query: (orderId) => {
        return {
          url: `Order/orderAddresses/${orderId}`,
          method: 'GET',
        };
      },
      providesTags: ['orderAddresses'],
    }),
    updateCustomerFarms: build.mutation<any, { id: number; data: any }>({
      query: ({ id, data }) => ({
        url: `/Customer/${id}/addFarmToCustomer`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Config'],
    }),
    updateCustomerShippingAddresses: build.mutation<
      any,
      { id: number; data: any }
    >({
      query: ({ id, data }) => ({
        url: `/Customer/${id}/addShippingAddressToCustomer`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Config'],
    }),
    getIsolateById: build.query<
      any,
      { customerId: number | ''; farmId?: number | '' }
    >({
      query: ({ farmId, customerId }) => {
        return {
          url: `/Order/orderIsolatesConfiguration?farmId=${farmId}&customerId=${customerId}`,
          method: 'GET',
        };
      },
      transformResponse: (res: any) => res?.data,
    }),
    getAuthorizedIsolates: build.query<
      IAuthorizedCustomerResponse,
      IAuthorizedCustomerParams
    >({
      query: ({ farmId, customerId, animalSpecieId }) => {
        return {
          url: `/Order/GetAuthorizedOrderIsolatesBySpecies?animalSpeciesId=${animalSpecieId}&farmId=${farmId}&customerId=${customerId}`,
          method: 'GET',
        };
      },
      transformResponse: (res: any) => res?.data,
    }),
  }),
});
export const {
  useGetOrderConfigQuery,
  useLazyGetOrderConfigQuery,
  useShareOrderMutation,
  useUpdateVetMutation,
  useGetOrderByIdQuery,
  useSaveReOrderMutation,
  useSaveOrderMutation,
  useSignOrderMutation,
  useLazyDetailsOrderQuery,
  useLazyGetOrdersQuery,
  useEditOrderMutation,
  useLockOrderMutation,
  useGetVetDataQuery,
  useAddDocumentTypeMutation,
  useLazyGetOrderStateDocumentsQuery,
  useSaveOrderAddressesMutation,
  useOrderAddressesQuery,
  useUpdateCustomerFarmsMutation,
  useUpdateCustomerShippingAddressesMutation,
  useLazyGetIsolateByIdQuery,
  useLazyGetAuthorizedIsolatesQuery,
} = orderFormApi;
