import * as React from 'react';
import Modal from '@mui/material/Modal';
import { Stack, IconButton } from '@mui/material';
import CustomButton from 'components/atoms/Button';
import { PopupMessageProps } from './types';
import Close from 'components/icons/Close';
import {
  StyledDescription,
  StyledHeader,
  StyledPaper,
  StyledTitle,
} from './styles';
import theme from 'theme';

const PopupConfirmation: React.FC<PopupMessageProps> = ({
  title,
  description,
  actionButtons,
  isOpen,
  handleClose,
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledPaper>
        <Stack spacing={3}>
          <StyledHeader direction="row" justifyContent="space-between" m={15}>
            <StyledTitle variant="body1" color={theme.palette.primary.main}>
              {title}
            </StyledTitle>
            <IconButton onClick={handleClose} aria-label="Close">
              <Close />
            </IconButton>
          </StyledHeader>
          <StyledDescription variant="body2" color={theme.palette.primary.main}>
            {description}
          </StyledDescription>
          {actionButtons && actionButtons.length > 0}
          <Stack direction={'row-reverse'} spacing={2}>
            {actionButtons?.map(
              ({ title, onClick, variant, loading, icon }) => (
                <CustomButton
                  key={title}
                  size="medium"
                  onClick={() => {
                    onClick();
                  }}
                  variant={variant}
                  loading={loading}
                  style={{ marginRight: '10px' }}
                  startIcon={icon}
                >
                  {title}
                </CustomButton>
              )
            )}
          </Stack>
        </Stack>
      </StyledPaper>
    </Modal>
  );
};

export default PopupConfirmation;
