import { Typography, styled } from '@mui/material';

export const StyledEditButtonContainer = styled('button')`
  ${({ theme }) => {
    return {
      background: theme.palette.primary[50],
      border: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 0,
      cursor: 'pointer',
      borderRadius: '80px',
      '& > div:last-child': {
        paddingRight: '10px',
      },
    };
  }}
`;

export const StyledParagraph = styled(Typography)`
  padding: 0 40px;
`;
