import {
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Switch,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material';
import HeaderWithButton from 'components/molecules/HeaderWithButton';
import { useTranslation } from 'react-i18next';
import {
  StyledButton,
  StyledForm,
  StyledTableBodyCell,
  StyledTableBodyCellEmail,
  StyledTableContainer,
  StyledTableHeadCellText,
  StyledTypography,
} from './styles';
import { EditPen } from 'components/icons';
import {
  VetAdditionalRecipient,
  useCreateVetAdditionalRecipientMutation,
  useDeleteVetAdditionalRecipientMutation,
  useLazyGetVetAdditionalRecipientsQuery,
  useUpdateVetAdditionalRecipientMutation,
} from 'api/profile';
import RenderByCondition from 'components/atoms/RenderByCondition';
import { useEffect, useMemo, useState } from 'react';
import TableLoaderSkeleton from 'components/atoms/TableLoaderSkeleton';
import CustomDialog from 'components/molecules/Dialog';
import CustomInput from 'components/atoms/Input';
import CustomButton from 'components/atoms/Button';
import theme from 'theme';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import BackdropLoader from 'components/atoms/BackdropLoader';
import useToast from 'hooks/useToast';

interface AdditionalRecipientRow {
  id: number;
  name: string;
  email: string;
  notifications: string[];
}

const AdditionalRecipients: React.FC = () => {
  const { t } = useTranslation();
  const isSMup = useMediaQuery(theme.breakpoints.up('sm'));
  const { toast } = useToast();

  const [selectedRecipient, setSelectedRecipient] =
    useState<VetAdditionalRecipient>(null);

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);

  const validationSchema = Yup.object({
    name: Yup.string().required(t('errorMessages.fieldRequired')),
    email: Yup.string()
      .email(t('errorMessages.invalidEmailFormat'))
      .required(t('errorMessages.fieldRequired')),
  });

  const initialValues = useMemo(() => {
    return {
      name: selectedRecipient ? selectedRecipient.name : '',
      email: selectedRecipient ? selectedRecipient.email : '',
      notifiedForReorder: selectedRecipient
        ? selectedRecipient.notifiedForReorder
        : false,
      notifiedForOrderStatusChange: selectedRecipient
        ? selectedRecipient.notifiedForOrderStatusChange
        : false,
    };
  }, [selectedRecipient]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: (values) => {
      handleSave(values);
    },
  });

  useEffect(() => {
    formik.resetForm({ values: initialValues });
  }, [selectedRecipient]);

  const [refetch, { data, isFetching, isLoading }] =
    useLazyGetVetAdditionalRecipientsQuery();

  const [
    createRecipient,
    {
      data: createRecipientData,
      isError: createRecipientError,
      isLoading: createRecipientLoading,
      isSuccess: createRecipientSuccess,
    },
  ] = useCreateVetAdditionalRecipientMutation();

  const [
    updateRecipient,
    {
      data: updateRecipientData,
      isError: updateRecipientError,
      isLoading: updateRecipientLoading,
      isSuccess: updateRecipientSuccess,
    },
  ] = useUpdateVetAdditionalRecipientMutation();

  const [
    deleteRecipient,
    {
      data: deleteRecipientData,
      isError: deleteRecipientError,
      isLoading: deleteRecipientLoading,
      isSuccess: deleteRecipientSuccess,
    },
  ] = useDeleteVetAdditionalRecipientMutation();

  const aditionalRecipients = data?.data;
  const rows: AdditionalRecipientRow[] = aditionalRecipients?.map((item) => {
    const notifications = [];
    if (item.notifiedForReorder) {
      notifications.push(t('profile.notifyForReorder'));
    }
    if (item.notifiedForOrderStatusChange) {
      notifications.push(t('profile.notifyForOrderStatusChange'));
    }
    return {
      id: item.id,
      name: item.name,
      email: item.email,
      notifications: notifications,
    };
  });

  const handleEditClick = (recipientId) => {
    setSelectedRecipient(
      aditionalRecipients.find((item) => item.id === recipientId)
    );
    setIsPopupOpen(true);
  };

  const handleAddRecipientClick = () => {
    formik.resetForm({
      values: {
        name: '',
        email: '',
        notifiedForReorder: false,
        notifiedForOrderStatusChange: false,
      },
    });
    setSelectedRecipient(null);
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleSave = async (values) => {
    try {
      if (selectedRecipient) {
        /* if email already exst (except for the current contact), raise popup error */
        const recipient = aditionalRecipients?.find(
          (item) =>
            item.email === values.email && item.id !== selectedRecipient.id
        );
        if (recipient) {
          toast.error(t('profile.contactAlreadyExist'), {});
          return;
        }
        await updateRecipient({
          id: selectedRecipient.id,
          body: {
            name: values.name,
            email: values.email,
            notifiedForReorder: values.notifiedForReorder,
            notifiedForOrderStatusChange: values.notifiedForOrderStatusChange,
          },
        }).unwrap();
      } else {
        /* if email already exist, raise popup error */
        const recipient = aditionalRecipients?.find(
          (item) => item.email === values.email
        );
        if (recipient) {
          toast.error(t('profile.contactAlreadyExist'), {});
          return;
        }
        await createRecipient(values).unwrap();
      }
      setIsPopupOpen(false);
    } catch (e) {
      toast.error(t('profile.contactAddFailed'), {});
    } finally {
      formik.resetForm({ values: initialValues });
    }
  };

  const handleDelete = () => {
    setIsDeleteConfirmationOpen(true);
  };

  const handleConfirmDelete = async () => {
    await deleteRecipient(selectedRecipient.id).unwrap();
    setIsDeleteConfirmationOpen(false);
    setIsPopupOpen(false);
  };

  const handleCancelDelete = () => {
    setIsDeleteConfirmationOpen(false);
  };

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (deleteRecipientSuccess) {
      toast.success(t('profile.contactSuccessfullyDeleted'), {});
    } else if (deleteRecipientError) {
      toast.error(t('profile.contactDeleteFailed'), {});
    }
  }, [deleteRecipientData, deleteRecipientError]);

  useEffect(() => {
    if (updateRecipientSuccess) {
      toast.success(t('profile.contactSuccessfullyUpdated'), {});
    } else if (updateRecipientError) {
      toast.error(t('profile.contactUpdateFailed'), {});
    }
  }, [updateRecipientData, updateRecipientError]);

  useEffect(() => {
    if (createRecipientSuccess) {
      toast.success(t('profile.contactSuccessfullyAdded'), {});
    } else if (createRecipientError) {
      toast.error(t('profile.contactAddFailed'), {});
    }
  }, [createRecipientData, createRecipientError]);

  return (
    <>
      <Grid item container xs={12}>
        <Grid item xs={12} mb={2}>
          <HeaderWithButton
            title={t('profile.configureEmails')}
            buttonProps={{
              isHide: true,
              title: t('profile.addContact'),
              action: handleAddRecipientClick,
            }}
            titleStyle={{ fontWeight: '600', whiteSpace: 'break-spaces' }}
          />
        </Grid>

        <Grid item container xs={12} columnGap={2}>
          <StyledTypography>
            {t('profile.emailConfigurationMessage')}
          </StyledTypography>
        </Grid>

        <RenderByCondition show={[isFetching || isLoading]}>
          <Stack width="100%" style={{ marginTop: '20px' }}>
            <TableLoaderSkeleton />
          </Stack>
        </RenderByCondition>

        <RenderByCondition show={[!isFetching && !isLoading]}>
          <Grid item container xs={12} justifyContent="center">
            <StyledTableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <StyledTableHeadCellText>
                      {t('profile.name')}
                    </StyledTableHeadCellText>
                    <StyledTableHeadCellText>
                      {t('profile.email')}
                    </StyledTableHeadCellText>
                    <StyledTableHeadCellText>
                      {t('profile.notification')}
                    </StyledTableHeadCellText>
                    <StyledTableHeadCellText>
                      {t('profile.action')}
                    </StyledTableHeadCellText>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.map((row) => (
                    <TableRow key={row.name}>
                      <StyledTableBodyCell>{row.name}</StyledTableBodyCell>
                      <StyledTableBodyCellEmail>
                        {row.email}
                      </StyledTableBodyCellEmail>
                      <StyledTableBodyCell>
                        {row.notifications.map((item) => (
                          <div key={item}>{item}</div>
                        ))}
                      </StyledTableBodyCell>
                      <StyledTableBodyCell
                        style={{ margin: '0px', padding: '0px' }}
                      >
                        <StyledButton onClick={() => handleEditClick(row.id)}>
                          {t('global.edit')} <EditPen />
                        </StyledButton>
                      </StyledTableBodyCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </Grid>
        </RenderByCondition>
      </Grid>

      <CustomDialog
        open={isPopupOpen}
        handleClose={handleClosePopup}
        modalTitle={t('profile.addContact')}
      >
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid
            container
            direction="column"
            columnSpacing={2}
            sx={{ width: isSMup ? '100%' : '55%' }}
          >
            <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
              <CustomInput
                sx={{ width: '100%' }}
                name="name"
                label={t('profile.name')}
                placeholder={t('profile.name')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                error={Boolean(formik.touched.name && formik.errors.name)}
                errorMessage={formik.errors.name}
              />
            </Grid>

            <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
              <CustomInput
                sx={{ width: '100%' }}
                name="email"
                label="Email"
                placeholder="Email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                error={Boolean(formik.touched.email && formik.errors.email)}
                errorMessage={formik.errors.email}
              />
            </Grid>

            <Grid item>
              <FormGroup
                style={{ marginBottom: '1.25rem', marginLeft: '1.25rem' }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={formik.values.notifiedForReorder}
                      onChange={formik.handleChange}
                      name="notifiedForReorder"
                    />
                  }
                  label={t('profile.notifyForReorder')}
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={formik.values.notifiedForOrderStatusChange}
                      onChange={formik.handleChange}
                      name="notifiedForOrderStatusChange"
                    />
                  }
                  label={t('profile.notifyForOrderStatusChange')}
                />
              </FormGroup>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{ marginBottom: '1rem' }}
              hidden={!selectedRecipient}
            >
              <CustomButton
                size="medium"
                variant="outlined"
                onClick={handleDelete}
                style={{ float: 'right' }}
              >
                {t('profile.deleteContact')}
              </CustomButton>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              marginTop="1rem"
            >
              <Grid item marginRight="0.25rem">
                <CustomButton
                  size="medium"
                  variant="outlined"
                  onClick={handleClosePopup}
                >
                  {t('orderForm.cancel')}
                </CustomButton>
              </Grid>
              <Grid item columnSpacing={1}>
                <CustomButton
                  size="medium"
                  type="submit"
                  disabled={
                    formik.isSubmitting || !formik.isValid || !formik.dirty
                  }
                >
                  {selectedRecipient
                    ? t('orderForm.update')
                    : t('orderForm.save')}
                </CustomButton>
              </Grid>
            </Grid>
          </Grid>
          <BackdropLoader
            open={updateRecipientLoading || createRecipientLoading}
          />
        </StyledForm>

        <CustomDialog
          open={isDeleteConfirmationOpen}
          handleClose={handleCancelDelete}
          modalTitle={t('profile.deleteConfirmationTitle')}
        >
          <Typography margin={'10px'}>
            {t('profile.deleteConfirmationMessage')}
          </Typography>
          <CustomButton
            size="medium"
            variant="outlined"
            onClick={handleCancelDelete}
            style={{ margin: '10px' }}
          >
            {t('orderForm.cancel')}
          </CustomButton>
          <CustomButton
            size="medium"
            onClick={handleConfirmDelete}
            style={{ margin: '10px' }}
          >
            {t('orderForm.confirm')}
          </CustomButton>
          <BackdropLoader open={deleteRecipientLoading} />
        </CustomDialog>
      </CustomDialog>
    </>
  );
};

export default AdditionalRecipients;
