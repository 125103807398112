import { api } from 'config/api';

interface IGetPageContent {
  data: {
    data: {
      key: string;
      value: string;
    };
  };
}

export const PolicyApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPageContent: build.query<IGetPageContent['data'], { searchKey: string }>(
      {
        query: ({ searchKey }) => {
          return {
            url: `/Application/getConfigurationValue?key=${searchKey}`,
          };
        },
        transformErrorResponse: (response: any) => response?.data,
      }
    ),
  }),
});

export const { useGetPageContentQuery } = PolicyApi;
