import {
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import CustomCircle from 'components/atoms/Circle';
import UnderlineButton from 'components/atoms/UnderlineButton';
import { Element } from 'react-scroll';
import {
  DocumentTypeEnum,
  ISOLATE_DEROGATION_INFO,
  USER_ROLE,
} from 'constants/index';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyledAccordion,
  StyledBox,
  StyledPacketOrder,
  StyledTableBodyCell,
  StyledTableBodyCellText,
  StyledTableContainer,
  StyledTableHeadCell,
  StyledTableHeadCellText,
  StyledTitlePacketOrder,
  StyledTypography,
  LastTableRow,
  StyledDownloadAllPacketsButton,
  StyledGrid,
  StyledTableHeadCellTextWithoutEllipsis,
} from './styles';
import theme from 'theme';
import {
  formatDate,
  getFieldVisibility,
  isNullOrEmpty,
  prepareDownloadFileUrl,
  toSpaceSeparatedNumber,
} from 'utils';
import { AddressTypes, DocumentStatus } from 'enums';
import AddressModal from '../Addresses/AddressModal';
import {
  getAuthorizedIsolates,
  getFarmData,
  getFormConfig,
} from 'store/features/orderForm/index.selectors';
import { useDispatch, useSelector } from 'react-redux';
import TextBadge from 'components/atoms/TextBadge';
import { ExpendIcon, CheckedIcon, DownloadIcon } from 'components/icons';
import RenderByRole from 'components/atoms/RenderByRole';
import { getUserRole } from 'store/features/auth/index.selector';
import usePdfDoc from 'hooks/useDocMerge';
import RenderByCondition from 'components/atoms/RenderByCondition';
import OrderPacket from 'components/molecules/OrderPacket';
import {
  setSelectedStateId,
  setStatesFormData,
} from 'store/features/addressesManager/index.slice';
import DocList from '../DocList';
import DocumentRendrer from 'components/molecules/DocumentRendrer';

const {
  PRICING_STEP_VISIBILITY,
  FILLING_PER_BOTTLE_VISIBILITY,
  ROUTE_OF_ADMINISTRATION_VISIBILITY,
  ISOLATE_DATA_VISIBILITY,
  SERIALS_VISIBILITY,
  SWITCH_ADDRESS_TYPE_VISIBILITY,
  BOTTLE_USAGE_VISIBILITY,
  INTERNAL_REFERENCE_VISIBILITY,
  BOTTLE_NAME_VISIBILITY,
  DOSES_PER_BOTTLE_VISIBILITY,
  ANIMAL_COUNT_VISIBILITY,
  CLINICAL_SIGNS_VISIBILITY,
  BILL_TO_VISIBILITY,
  CUSTOMER_VISIBILITY,
  REQUEST_LETTER_COMMENT,
  ISOLATE_DEROGATION_COLUMN_VISIBILITY,
  CAP_COLOR_VISIBILITY,
  ORDER_DETAILS_VERTICAL_ADDRESSES_DISPLAY,
} = getFieldVisibility();

const OrderDetailsContent = ({ activeSection, data }) => {
  const { t } = useTranslation();

  const [countOfDocumentStates, setCountOfDocumentStates] = useState(0);
  const [countOfApprovalDocuments, setCountOfApprovalDocuments] = useState(0);

  const FarmAndAddresses = ({ ...StyledBoxProps }) => {
    const orderAddresses = data?.orderAddresses || [];

    const aggregatedData = orderAddresses
      ?.filter((curr) => curr?.stateId && curr?.stateId !== 0)
      ?.map((curr) => ({
        stateId: curr?.stateId,
        stateName: curr?.state?.name,
        stateCode: curr?.state?.code,
        addressId: curr?.address?.id,
      }));

    const processedData = aggregatedData.reduce((acc, curr) => {
      if (!acc.find((item) => item.id === curr?.stateId)) {
        acc.push({
          id: curr?.stateId,
          name: curr?.stateName,
          variant: 'body1',
        });
      }
      return acc;
    }, []);

    const [isShippingSiteModalOpen, setIsShippingSiteModalOpen] =
      useState(false);
    const [isProximityAddressesModalOpen, setIsProximityAddressesModalOpen] =
      useState(false);
    const [isMainAddressesModalOpen, setIsMainAddressesModalOpen] =
      useState(false);
    const isNonAdjacentRequired =
      useSelector(getFarmData)?.nonAdjacentRequired?.value;
    const apiConfiguration = useSelector(getFormConfig);

    const customerName = data?.customer?.name;

    const shippingSites = data?.orderShippingAddresses;
    const shippingSitesCount = shippingSites?.length;
    const totalShippingSites = apiConfiguration?.customers?.find(
      (customer) => customer.id === data?.customer?.id
    )?.shippingAddresses?.length;

    let farms = [];

    if (isNonAdjacentRequired) farms = data?.nonAdjacentFarms;
    else farms = [data?.farm];

    const farmsCount = farms?.length;

    const getStates = (data) => {
      const uniqueStates = [
        ...new Set(data?.map((item) => item?.address?.state?.id)),
      ];

      const uniqueStateObjects: any = uniqueStates.map((stateId) => ({
        id: stateId,
        name: data.find((item) => item?.address?.state?.id === stateId)?.address
          ?.state?.name,
        variant: 'body1',
      }));

      return uniqueStateObjects;
    };

    const transformDataHelper = (data) => {
      if (isNonAdjacentRequired)
        return data?.map((item) => ({
          id: item?.farm?.id,
          address: {
            street: item?.farm?.address?.street,
            city: item?.farm?.address?.city,
            stateName: item?.farm?.address?.state?.name,
            zipCode: item?.farm?.address?.zipCode,
            country: item?.farm?.address?.country,
            phoneNumber: item?.farm?.address?.phoneNumber,
            contact: item?.farm?.address?.contact,
            stateId: item?.farm?.address?.stateId,
            state: item?.farm?.address?.state,
          },
          name: item?.farm?.name,
          complex: item?.farm?.complex,
          capacity: item?.farm?.capacity,
          officialNumber: item?.farm?.officialNumber,
          farmId: item?.farm?.id,
          status: item?.status,
        }));
      else {
        return data?.map((item) => ({
          id: item?.id,
          name: item?.name,
          address: {
            street: item?.address?.street,
            country: item?.address?.country,
            city: item?.address?.city,
            zipCode: item?.address?.zipCode,
          },
          officialNumber: item?.officialNumber,
        }));
      }
    };

    const dispatch = useDispatch();

    const transformedFarms = useMemo(() => {
      const data = transformDataHelper(farms);
      const states = getStates(data);
      dispatch(setStatesFormData(states ?? []));
      dispatch(setSelectedStateId(states?.[0]?.id));
      return data;
    }, [farms]);

    const transformedShipping = useMemo(() => {
      return shippingSites?.map((item) => ({
        ...item,
      }));
    }, [shippingSites]);

    const totalFarmsCount = apiConfiguration?.customers?.find(
      (customer) => customer?.id === data?.customer?.id
    )?.farms?.length;

    return (
      <Grid container direction={'column'} rowGap={4}>
        <Grid item>
          <StyledBox {...StyledBoxProps}>
            {t('orderForm.stepOneTitle')}
          </StyledBox>
        </Grid>
        <Grid container rowGap={1} direction={'column'}>
          <Typography color="neutral.700" variant="body2" fontWeight={600}>
            {t('orderForm.veterinaryName')}
          </Typography>
          <Divider />
          <Typography color="neutral.main" fontWeight={400} variant="body1">
            {data?.vet?.userName}
          </Typography>
        </Grid>
        <Grid container rowGap={1} direction={'column'}>
          {/* NEW */}
          <RenderByCondition show={[ORDER_DETAILS_VERTICAL_ADDRESSES_DISPLAY]}>
            <Grid container direction={'column'} rowGap={1}>
              <Grid item>
                <Grid container direction={'column'} rowGap={1}>
                  <Typography
                    color="neutral.700"
                    variant="body2"
                    fontWeight={600}
                  >
                    {t('orderForm.shippingSiteSelected', {
                      count: shippingSitesCount,
                    })}
                  </Typography>
                  <Divider />
                </Grid>
              </Grid>

              <Grid item>
                <Grid container direction="row">
                  <Typography
                    color="neutral.700"
                    variant="body2"
                    fontWeight={600}
                  >
                    {transformedShipping?.[0]?.address?.name},{' '}
                    {transformedShipping?.[0]?.address?.zipCode},{' '}
                    {transformedShipping?.[0]?.address?.city}{' '}
                  </Typography>
                  <UnderlineButton
                    title={t('global.seeAll')}
                    onClick={() => {
                      setIsShippingSiteModalOpen((prev) => !prev);
                    }}
                    sx={{ marginLeft: '4px' }}
                  />

                  <AddressModal
                    isOpen={isShippingSiteModalOpen}
                    addressData={transformedShipping}
                    addressType={AddressTypes.Shipping}
                    editMode={false}
                    viewOnly
                    onClose={() => setIsShippingSiteModalOpen(false)}
                    modePreviewTotalCount={totalShippingSites}
                    modePreviewSelectCount={shippingSitesCount}
                    statesData={processedData ?? []}
                    isOrderDetails={true}
                    addressesByStateMapper={aggregatedData ?? []}
                    activeTab={1}
                    isFarmsDisplayed={false}
                  />
                </Grid>
              </Grid>
            </Grid>
          </RenderByCondition>

          {/* OLD */}
          <RenderByCondition show={[!ORDER_DETAILS_VERTICAL_ADDRESSES_DISPLAY]}>
            <Grid container direction={'row'}>
              <Typography color="neutral.700" variant="body2" fontWeight={600}>
                {t('orderForm.shippingSiteSelected', {
                  count: shippingSitesCount,
                })}
              </Typography>
              <UnderlineButton
                title={t('global.seeAll')}
                onClick={() => {
                  setIsShippingSiteModalOpen((prev) => !prev);
                }}
                sx={{ marginLeft: '4px' }}
              />
              <AddressModal
                isOpen={isShippingSiteModalOpen}
                addressData={transformedShipping}
                addressType={AddressTypes.Shipping}
                editMode={false}
                viewOnly
                onClose={() => setIsShippingSiteModalOpen(false)}
                modePreviewTotalCount={totalShippingSites}
                modePreviewSelectCount={shippingSitesCount}
                statesData={processedData ?? []}
                isOrderDetails={true}
                addressesByStateMapper={aggregatedData ?? []}
                activeTab={1}
                isFarmsDisplayed={false}
              />
            </Grid>
            <Divider />
          </RenderByCondition>

          <RenderByCondition show={[SWITCH_ADDRESS_TYPE_VISIBILITY]}>
            <>
              <Grid container direction={'row'} mt={2}>
                <Typography
                  color="neutral.700"
                  variant="body2"
                  fontWeight={600}
                >
                  {isNonAdjacentRequired
                    ? t('orderForm.proximityAddressSelected', {
                        count: farmsCount,
                      })
                    : t('orderForm.farmAddressSelected', {
                        count: farmsCount,
                      })}
                </Typography>
                <UnderlineButton
                  title={t('global.seeAll')}
                  onClick={() => {
                    isNonAdjacentRequired
                      ? setIsProximityAddressesModalOpen((prev) => !prev)
                      : setIsMainAddressesModalOpen((prev) => !prev);
                  }}
                  sx={{ marginLeft: '4px' }}
                />
                <AddressModal
                  isOpen={
                    isNonAdjacentRequired
                      ? isProximityAddressesModalOpen
                      : isMainAddressesModalOpen
                  }
                  addressData={transformedFarms}
                  addressType={
                    isNonAdjacentRequired
                      ? AddressTypes.Proximity
                      : AddressTypes.Main
                  }
                  editMode={false}
                  viewOnly
                  onClose={() =>
                    isNonAdjacentRequired
                      ? setIsProximityAddressesModalOpen(false)
                      : setIsMainAddressesModalOpen(false)
                  }
                  modePreviewTotalCount={totalFarmsCount}
                  modePreviewSelectCount={farmsCount}
                  activeTab={0}
                  isFarmsDisplayed={true}
                  isOrderDetails={true}
                />
              </Grid>
              <Divider />
            </>
          </RenderByCondition>
          <RenderByCondition show={[!SWITCH_ADDRESS_TYPE_VISIBILITY]}>
            <Grid container direction={'column'} rowGap={1} mt={2.5}>
              <Grid item>
                <Grid container direction={'column'} rowGap={1}>
                  <Typography
                    color="neutral.700"
                    variant="body2"
                    fontWeight={600}
                  >
                    {t('orderForm.farmAddressSelected', {
                      count: farmsCount,
                    })}
                  </Typography>
                  <Divider />
                </Grid>
              </Grid>

              <Grid item>
                <Grid container direction={'row'}>
                  <Typography
                    color="neutral.700"
                    variant="body2"
                    fontWeight={600}
                  >
                    {transformedFarms?.[0]?.name}
                  </Typography>
                  <UnderlineButton
                    title={t('global.seeAll')}
                    onClick={() => {
                      setIsMainAddressesModalOpen((prev) => !prev);
                    }}
                    sx={{ marginLeft: '4px' }}
                  />
                  <AddressModal
                    isOpen={isMainAddressesModalOpen}
                    addressData={transformedFarms}
                    addressType={AddressTypes.Main}
                    editMode={false}
                    viewOnly
                    onClose={() => setIsMainAddressesModalOpen(false)}
                    modePreviewTotalCount={totalFarmsCount}
                    modePreviewSelectCount={farmsCount}
                    activeTab={0}
                    isFarmsDisplayed={true}
                    isOrderDetails={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </RenderByCondition>
        </Grid>
        <Grid container rowGap={1} direction={'column'}>
          <RenderByCondition show={[BILL_TO_VISIBILITY]}>
            <>
              <Typography color="neutral.700" variant="body2" fontWeight={600}>
                {t('orderForm.billingAddress')}
              </Typography>
              <Divider />
              <Typography color="neutral.main" fontWeight={400} variant="body1">
                {customerName}
              </Typography>
            </>
          </RenderByCondition>

          <RenderByCondition show={[CUSTOMER_VISIBILITY]}>
            <Grid container rowGap={1} direction={'column'}>
              <Typography color="neutral.700" variant="body2" fontWeight={600}>
                {t('orderForm.selectedCustomer')}
              </Typography>
              <Divider />
              <Typography color="neutral.main" fontWeight={400} variant="body1">
                {customerName}
              </Typography>
            </Grid>
          </RenderByCondition>
        </Grid>
      </Grid>
    );
  };

  const InfectionAndIsolates = ({ ...StyledBoxProps }) => {
    const authorizedIsolates = useSelector(getAuthorizedIsolates);

    const AnimalGroup = () => {
      return (
        <Grid container rowGap={1} direction={'column'}>
          <Grid container direction={'row'}>
            <Typography
              color="neutral.700"
              variant="body2"
              fontWeight={600}
              mr={8}
            >
              {t('orderForm.animalGroup')}
            </Typography>
            <Typography color="neutral.700" variant="body2" fontWeight={600}>
              {t('orderForm.animalSpecies')}
            </Typography>
          </Grid>
          <Divider />
          <Grid container direction={'row'}>
            <Typography
              color="neutral.main"
              fontWeight={400}
              variant="body1"
              mr={13}
            >
              {data?.animalGroup?.name}
            </Typography>
            <Typography color="neutral.main" fontWeight={400} variant="body1">
              {data?.animalSpecies?.name}
            </Typography>
          </Grid>
        </Grid>
      );
    };

    const SpecificProduct = () => {
      return (
        <Grid container rowGap={1} direction={'column'}>
          <Grid container direction={'row'}>
            <Typography
              color="neutral.700"
              variant="body2"
              fontWeight={600}
              mr={8}
            >
              {t('orderForm.specificProduct')}
            </Typography>
          </Grid>
          <Divider />
          <Grid container direction={'row'}>
            <Typography color="neutral.main" fontWeight={400} variant="body1">
              {data?.product?.name}
            </Typography>
          </Grid>
        </Grid>
      );
    };

    const IsolateItem = ({ orderIsolate }) => {
      const farmName = orderIsolate?.isolate?.originFarm?.name;
      const farmAddress = `${
        orderIsolate?.isolate?.originFarm?.address?.street || ''
      }, ${orderIsolate?.isolate?.originFarm?.address?.city || ''}, ${
        orderIsolate?.isolate?.originFarm?.address?.country || ''
      }`;
      const farmPhone = orderIsolate?.isolate?.originFarm?.address?.phoneNumber;
      const capacity = orderIsolate?.isolate?.originFarm?.capacity || 0;

      const isolateNeedsDerogation = (orderIsolate) =>
        !authorizedIsolates?.some(
          (ai) => ai.isolateId === orderIsolate?.isolateId
        ) && ISOLATE_DEROGATION_COLUMN_VISIBILITY;
      orderIsolate = {
        ...orderIsolate,
        styleInfo: isolateNeedsDerogation(orderIsolate)
          ? {
              ...ISOLATE_DEROGATION_INFO,
              name: ISOLATE_DEROGATION_INFO.getName(t),
            }
          : null,
      };

      const chosenProductIsBoosting: boolean = data?.product?.isBoosting;

      return (
        <TableRow key={orderIsolate?.isolate?.id}>
          <StyledTableBodyCell>
            <Tooltip
              title={orderIsolate?.isolate?.reference}
              style={{ color: orderIsolate?.styleInfo?.textColor }}
            >
              <StyledTableBodyCellText variant="body2">
                {orderIsolate?.isolate?.reference}
              </StyledTableBodyCellText>
            </Tooltip>
            <Tooltip
              title={orderIsolate?.isolate?.name}
              style={{ color: orderIsolate?.styleInfo?.textColor }}
            >
              <StyledTableBodyCellText variant="body2">
                {orderIsolate?.isolate?.name}
              </StyledTableBodyCellText>
            </Tooltip>
          </StyledTableBodyCell>

          <RenderByCondition show={[ISOLATE_DATA_VISIBILITY]}>
            <StyledTableBodyCell>
              <Tooltip title={farmName}>
                <StyledTableBodyCellText variant="body2">
                  {farmName}
                </StyledTableBodyCellText>
              </Tooltip>
              <Tooltip title={farmAddress}>
                <StyledTableBodyCellText variant="body2">
                  {farmAddress}
                </StyledTableBodyCellText>
              </Tooltip>
              <Tooltip title={farmPhone}>
                <StyledTableBodyCellText variant="body2">
                  {farmPhone}
                </StyledTableBodyCellText>
              </Tooltip>
            </StyledTableBodyCell>
            <StyledTableBodyCell>
              <Tooltip title={capacity}>
                <StyledTableBodyCellText variant="body2">
                  {capacity}
                </StyledTableBodyCellText>
              </Tooltip>
            </StyledTableBodyCell>
          </RenderByCondition>
          <StyledTableBodyCell>
            <Tooltip
              title={orderIsolate?.isolate?.seroType}
              style={{ color: orderIsolate?.styleInfo?.textColor }}
            >
              <StyledTableBodyCellText variant="body2">
                {orderIsolate?.isolate?.seroType}
              </StyledTableBodyCellText>
            </Tooltip>
          </StyledTableBodyCell>

          <RenderByCondition show={[ISOLATE_DEROGATION_COLUMN_VISIBILITY]}>
            <StyledTableBodyCell>
              <Tooltip title={orderIsolate?.styleInfo?.name}>
                <Typography display="inline">
                  <TextBadge
                    name={orderIsolate?.styleInfo?.name}
                    color={orderIsolate?.styleInfo?.textColor}
                    variant="body3"
                  />
                </Typography>
              </Tooltip>
            </StyledTableBodyCell>
          </RenderByCondition>

          <RenderByCondition show={[chosenProductIsBoosting]}>
            <StyledTableBodyCell>
              <RenderByCondition show={[orderIsolate?.isBoosted]}>
                <StyledTableBodyCellText style={{ paddingLeft: '10px' }}>
                  <CheckedIcon style={{ textAlign: 'center' }} />
                </StyledTableBodyCellText>
              </RenderByCondition>
            </StyledTableBodyCell>
          </RenderByCondition>
        </TableRow>
      );
    };

    const Isolates = () => {
      const isolatesListLength = data?.orderIsolates?.length;
      const chosenProductIsBoosting: boolean = data?.product?.isBoosting;
      return (
        <Grid container rowGap={1} direction={'column'}>
          <Typography color="neutral.700" variant="body2" fontWeight={600}>
            {t('orderForm.isolateList', {
              isolateCount: isolatesListLength,
            })}
          </Typography>
          <Grid
            container
            direction={'row'}
            justifyContent={'flex-start'}
            marginTop={'1rem'}
          >
            <StyledTableContainer>
              <Table
                sx={{
                  width: '100%',
                }}
              >
                <TableHead>
                  <TableRow>
                    <StyledTableHeadCell
                      sx={{ paddingLeft: 0, paddingRight: 0 }}
                    >
                      <Tooltip title={t('orderForm.isolateId')}>
                        <StyledTableHeadCellText variant="body2">
                          {t('orderForm.isolateId')}
                        </StyledTableHeadCellText>
                      </Tooltip>
                    </StyledTableHeadCell>

                    <RenderByCondition show={[ISOLATE_DATA_VISIBILITY]}>
                      <StyledTableHeadCell
                        sx={{ paddingLeft: 0, paddingRight: 0 }}
                      >
                        <Tooltip title={t('orderForm.farm')}>
                          <StyledTableHeadCellText variant="body2">
                            {t('orderForm.farm')}
                          </StyledTableHeadCellText>
                        </Tooltip>
                      </StyledTableHeadCell>
                      <StyledTableHeadCell
                        sx={{ paddingLeft: 0, paddingRight: 0 }}
                      >
                        <Tooltip title={t('orderForm.capacity')}>
                          <StyledTableHeadCellText variant="body2">
                            {t('orderForm.capacity')}
                          </StyledTableHeadCellText>
                        </Tooltip>
                      </StyledTableHeadCell>
                    </RenderByCondition>
                    <StyledTableHeadCell
                      sx={{ paddingLeft: 0, paddingRight: 0 }}
                    >
                      <Tooltip title={t('orderForm.seroType')}>
                        <StyledTableHeadCellText variant="body2">
                          {t('orderForm.seroType')}
                        </StyledTableHeadCellText>
                      </Tooltip>
                    </StyledTableHeadCell>

                    <RenderByCondition
                      show={[ISOLATE_DEROGATION_COLUMN_VISIBILITY]}
                    >
                      <StyledTableHeadCell
                        sx={{ paddingLeft: 0, paddingRight: 0 }}
                      >
                        <Tooltip title={''}>
                          <StyledTableHeadCellText variant="body2">
                            {''}
                          </StyledTableHeadCellText>
                        </Tooltip>
                      </StyledTableHeadCell>
                    </RenderByCondition>

                    <RenderByCondition show={[chosenProductIsBoosting]}>
                      <StyledTableHeadCell
                        sx={{ paddingLeft: 0, paddingRight: 0 }}
                      >
                        <Tooltip title={t('orderForm.boostColumnName')}>
                          <StyledTableHeadCellText variant="body2">
                            {t('orderForm.boostColumnName')}
                          </StyledTableHeadCellText>
                        </Tooltip>
                      </StyledTableHeadCell>
                    </RenderByCondition>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <React.Fragment>
                    {data?.orderIsolates?.map((orderIsolate) => {
                      return (
                        <IsolateItem
                          orderIsolate={orderIsolate}
                          key={orderIsolate?.isolateId}
                        />
                      );
                    })}
                  </React.Fragment>
                </TableBody>
              </Table>
            </StyledTableContainer>
          </Grid>
        </Grid>
      );
    };

    const AdministrationRoute = () => {
      return (
        <Grid container rowGap={1} direction={'column'}>
          <Typography color="neutral.700" variant="body2" fontWeight={600}>
            {t('orderForm.administrationRoutes')}
          </Typography>
          <Divider />
          <Typography color="neutral.main" fontWeight={400} variant="body1">
            {data?.administrationRoute?.name}
          </Typography>
        </Grid>
      );
    };

    const DoseAndAnimalsNumber = () => {
      return (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableHeadCell>
                  <StyledTableHeadCellTextWithoutEllipsis variant="body2">
                    {t('orderForm.volumePerDoses')}
                  </StyledTableHeadCellTextWithoutEllipsis>
                </StyledTableHeadCell>
                <RenderByCondition show={[ANIMAL_COUNT_VISIBILITY]}>
                  <StyledTableHeadCell>
                    <StyledTableHeadCellText variant="body2">
                      {t('orderForm.numberOfAnimals')}
                    </StyledTableHeadCellText>
                  </StyledTableHeadCell>
                </RenderByCondition>
              </TableRow>
            </TableHead>
            <TableBody>
              <LastTableRow>
                <StyledTableBodyCell style={{ borderBottom: '0px' }}>
                  <StyledTableBodyCellText variant="body1">
                    {data?.dose?.volume}
                  </StyledTableBodyCellText>
                </StyledTableBodyCell>
                <RenderByCondition show={[ANIMAL_COUNT_VISIBILITY]}>
                  <StyledTableBodyCell style={{ borderBottom: '0px' }}>
                    <StyledTableBodyCellText variant="body1">
                      {data?.animalCount}
                    </StyledTableBodyCellText>
                  </StyledTableBodyCell>
                </RenderByCondition>
              </LastTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      );
    };

    const Adjuvant = () => {
      return (
        <Grid container rowGap={1} direction={'column'}>
          <Typography color="neutral.700" variant="body2" fontWeight={600}>
            {t('orderForm.adjuvant')}
          </Typography>
          <Divider />
          <Typography color="neutral.main" fontWeight={400} variant="body1">
            {data?.adjuvant?.name}
          </Typography>
        </Grid>
      );
    };

    return (
      <Grid container direction={'column'} rowGap={4}>
        <Grid item>
          <StyledBox {...StyledBoxProps}>
            {t('orderForm.stepTwoTitle')}
          </StyledBox>
        </Grid>
        <AnimalGroup />
        <SpecificProduct />
        <Adjuvant />

        <RenderByCondition show={[ROUTE_OF_ADMINISTRATION_VISIBILITY]}>
          <AdministrationRoute />
        </RenderByCondition>
        <DoseAndAnimalsNumber />
        <Isolates />
      </Grid>
    );
  };

  const DosingAndFilling = ({ ...StyledBoxProps }) => {
    const Bottles = () => {
      let totalVolume = 0;
      let totalCount = 0;
      let totalDoses = 0;

      data?.orderBottles?.forEach((bottle) => {
        totalVolume += bottle?.bottleCount * bottle?.bottle?.volume;
        totalCount += bottle?.bottleCount;
      });

      totalDoses =
        data?.dose?.volume == 0 ? 0 : totalVolume / data?.dose?.volume || 0;

      const calculateDosesForRow = ({
        volumePerDose,
        bottlesCount,
        bottleVolume,
      }) => {
        if (volumePerDose === 0 || bottlesCount === 0) return '0';

        const result = (bottleVolume / volumePerDose).toFixed(2);

        return toSpaceSeparatedNumber(parseFloat(result));
      };

      const BottleItem = ({ item, index }) => {
        return (
          <React.Fragment key={index}>
            <TableRow>
              <StyledTableBodyCell>
                <StyledTableBodyCellText variant="body1">
                  {item?.bottle?.volume} ml
                </StyledTableBodyCellText>
              </StyledTableBodyCell>
              <StyledTableBodyCell>
                <StyledTableBodyCellText variant="body1">
                  {item.bottleCount}
                </StyledTableBodyCellText>
              </StyledTableBodyCell>

              <StyledTableBodyCell>
                <StyledTableBodyCellText variant="body1">
                  {calculateDosesForRow({
                    volumePerDose: data?.dose?.volume,
                    bottlesCount: item?.bottleCount,
                    bottleVolume: item?.bottle?.volume,
                  })}
                </StyledTableBodyCellText>
              </StyledTableBodyCell>
            </TableRow>
            {index < data?.length - 1 && <Divider />}
          </React.Fragment>
        );
      };

      const TotalsSummary = () => {
        return (
          <Grid
            container
            bgcolor={'neutral.200'}
            p={2}
            direction={{ xs: 'column', md: 'row' }}
          >
            <Grid item xs={4}>
              <Typography
                variant="subtitle1"
                color="primary.main"
                fontWeight={600}
              >
                {t('orderForm.totalVolume', { totalVolume: totalVolume })}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="subtitle1"
                color="primary.main"
                fontWeight={600}
              >
                {t('orderForm.totalBottles')}: {totalCount}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="subtitle1"
                color="primary.main"
                fontWeight={600}
              >
                {t('orderForm.totalDoses')}:{' '}
                {totalDoses == 0 ? t('global.notApplicable') : totalDoses}
              </Typography>
            </Grid>
          </Grid>
        );
      };

      return (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableHeadCell>
                  <Tooltip title={t('orderForm.bottleVolume')}>
                    <StyledTableHeadCellText variant="body2">
                      {t('orderForm.bottleVolume')}
                    </StyledTableHeadCellText>
                  </Tooltip>
                </StyledTableHeadCell>
                <StyledTableHeadCell>
                  <Tooltip title={t('orderForm.numberOfBottles')}>
                    <StyledTableHeadCellText variant="body2">
                      {t('orderForm.numberOfBottles')}
                    </StyledTableHeadCellText>
                  </Tooltip>
                </StyledTableHeadCell>
                <RenderByCondition show={[FILLING_PER_BOTTLE_VISIBILITY]}>
                  <StyledTableHeadCell>
                    <Tooltip title={t('orderForm.numberOfDosesPerBottle')}>
                      <StyledTableHeadCellText variant="body2">
                        {t('orderForm.numberOfDosesPerBottle', {
                          pluralLetter: 's',
                        })}
                      </StyledTableHeadCellText>
                    </Tooltip>
                  </StyledTableHeadCell>
                </RenderByCondition>

                <RenderByCondition show={[DOSES_PER_BOTTLE_VISIBILITY]}>
                  <StyledTableHeadCell>
                    <Tooltip title={t('orderForm.dosesPerBottle')}>
                      <StyledTableHeadCellText variant="body2">
                        {t('orderForm.dosesPerBottle')}
                      </StyledTableHeadCellText>
                    </Tooltip>
                  </StyledTableHeadCell>
                </RenderByCondition>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.orderBottles?.map((orderBottle, index) => (
                <BottleItem
                  item={orderBottle}
                  index={index}
                  key={orderBottle?.id}
                />
              ))}
              <TableRow>
                <StyledTableBodyCell colSpan={4} sx={{ padding: 0 }}>
                  <TotalsSummary />
                </StyledTableBodyCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      );
    };

    const UsageOfBottle = () => {
      return (
        <Grid container rowGap={1} direction={'column'} mt={2}>
          <Typography color="neutral.700" variant="body2" fontWeight={600}>
            {t('orderForm.usageOfBottle')}
          </Typography>
          <Divider />
          <Typography color="neutral.main" fontWeight={400} variant="body1">
            {data?.monthlyBottleUsage}
          </Typography>
        </Grid>
      );
    };

    return (
      <Grid container direction={'column'} rowGap={2}>
        <Grid item>
          <StyledBox {...StyledBoxProps}>
            {t('orderForm.stepThreeTitle')}
          </StyledBox>
        </Grid>

        <Bottles />

        <RenderByCondition show={[BOTTLE_USAGE_VISIBILITY]}>
          <UsageOfBottle />
        </RenderByCondition>
      </Grid>
    );
  };

  const BottlePricing = ({ ...StyledBoxProps }) => {
    const totalPrice = data?.orderBottles?.reduce(
      (total, item) => total + item?.bottlePrice * item?.bottleCount,
      0
    );

    return (
      <Grid container direction={'column'} rowGap={4}>
        <Grid item>
          <StyledBox {...StyledBoxProps}>
            {t('orderForm.stepFourTitle')}
          </StyledBox>
        </Grid>
        <Grid container rowGap={1}>
          <StyledTableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableHeadCell sx={{ paddingLeft: 0 }}>
                    <Tooltip title={t('orderForm.bottleVolume')}>
                      <StyledTableHeadCellText variant="body2">
                        {t('orderForm.bottleVolume')}
                      </StyledTableHeadCellText>
                    </Tooltip>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell sx={{ paddingLeft: 0 }}>
                    <Tooltip title={t('orderForm.pricePerBottle')}>
                      <StyledTableHeadCellText variant="body2">
                        {t('orderForm.pricePerBottle')}
                      </StyledTableHeadCellText>
                    </Tooltip>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell sx={{ paddingLeft: 0 }}>
                    <Tooltip title={t('orderForm.numberOrdered')}>
                      <StyledTableHeadCellText variant="body2">
                        {t('orderForm.numberOrdered')}
                      </StyledTableHeadCellText>
                    </Tooltip>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell sx={{ paddingLeft: 0 }}>
                    <Tooltip title={t('global.price')}>
                      <StyledTableHeadCellText variant="body2">
                        {t('global.price')}
                      </StyledTableHeadCellText>
                    </Tooltip>
                  </StyledTableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.orderBottles?.map((row, index) => (
                  <React.Fragment key={row?.bottleCount}>
                    <TableRow>
                      <StyledTableBodyCell>
                        <StyledTableBodyCellText variant="body2">
                          {row?.bottle?.volume} ml
                        </StyledTableBodyCellText>
                      </StyledTableBodyCell>

                      <StyledTableBodyCell>
                        <StyledTableBodyCellText variant="body2">
                          $ {row?.bottlePrice}
                        </StyledTableBodyCellText>
                      </StyledTableBodyCell>
                      <StyledTableBodyCell>
                        <StyledTableBodyCellText variant="body2">
                          {row?.bottleCount}
                        </StyledTableBodyCellText>
                      </StyledTableBodyCell>
                      <StyledTableBodyCell>
                        <StyledTableBodyCellText variant="body2">
                          ${(row?.bottlePrice * row?.bottleCount).toFixed(2)}
                        </StyledTableBodyCellText>
                      </StyledTableBodyCell>
                    </TableRow>
                    {index < data?.orderBottles?.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
                <TableRow sx={{ background: theme.palette.neutral[200] }}>
                  <TableCell colSpan={4}>
                    <Typography
                      variant="body2"
                      color="primary"
                      fontWeight={600}
                    >
                      {t('orderForm.totalPrice', { totalPrice: totalPrice })}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Grid>
      </Grid>
    );
  };

  const AdditionalInstructions = ({ ...StyledBoxProps }) => {
    const BottlePersonalization = () => {
      return (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <RenderByCondition show={[CAP_COLOR_VISIBILITY]}>
                  <StyledTableHeadCell>
                    <StyledTableHeadCellText
                      variant="body2"
                      sx={{ textAlign: 'left' }}
                    >
                      {t('orderForm.capColor')}
                    </StyledTableHeadCellText>
                  </StyledTableHeadCell>
                </RenderByCondition>
                <RenderByCondition show={[BOTTLE_NAME_VISIBILITY]}>
                  <StyledTableHeadCell>
                    <StyledTableHeadCellText
                      variant="body2"
                      sx={{ textAlign: 'left' }}
                    >
                      {t('orderForm.bottleTag')}
                    </StyledTableHeadCellText>
                  </StyledTableHeadCell>
                </RenderByCondition>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <RenderByCondition show={[CAP_COLOR_VISIBILITY]}>
                  <StyledTableBodyCell style={{ borderBottom: '0px' }}>
                    <Grid container direction={'row'} columnGap={2}>
                      <Grid item>
                        <CustomCircle
                          color={data?.color?.hexCode}
                          size="small"
                          border={`1px solid ${theme.palette.neutral[600]}`}
                        />
                      </Grid>
                      <Grid item>
                        <StyledTableBodyCellText
                          variant="body2"
                          sx={{ textAlign: 'left' }}
                        >
                          {data?.color?.name}
                        </StyledTableBodyCellText>
                      </Grid>
                    </Grid>
                  </StyledTableBodyCell>
                </RenderByCondition>
                <RenderByCondition show={[BOTTLE_NAME_VISIBILITY]}>
                  <StyledTableBodyCell style={{ borderBottom: '0px' }}>
                    <StyledTableBodyCellText
                      variant="body2"
                      sx={{ textAlign: 'left' }}
                    >
                      {data?.bottleTag}
                    </StyledTableBodyCellText>
                  </StyledTableBodyCell>
                </RenderByCondition>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      );
    };

    return (
      <Grid container direction={'column'} rowGap={4}>
        <Grid item>
          <StyledBox {...StyledBoxProps}>
            {t('orderForm.stepFiveTitle')}
          </StyledBox>
        </Grid>

        <BottlePersonalization />

        <Grid container rowGap={1} direction={'column'}>
          <Typography color="neutral.700" variant="body2" fontWeight={600}>
            {t('orderForm.vaccinationSchedule')}
          </Typography>
          <Divider />
          <Typography color="neutral.main" fontWeight={400} variant="body1">
            {data?.vaccinationSchedule}
          </Typography>
        </Grid>

        <RenderByCondition show={[CLINICAL_SIGNS_VISIBILITY]}>
          <Grid container rowGap={1} direction={'column'}>
            <Typography color="neutral.700" variant="body2" fontWeight={600}>
              {t('orderForm.clinicalSigns')}
            </Typography>
            <Divider />
            <Typography color="neutral.main" fontWeight={400} variant="body1">
              {data?.clinicalSign}
            </Typography>
          </Grid>
        </RenderByCondition>

        <RenderByCondition show={[INTERNAL_REFERENCE_VISIBILITY]}>
          <Grid container rowGap={1} direction={'column'}>
            <Typography color="neutral.700" variant="body2" fontWeight={600}>
              {t('orderForm.internalReference')}
            </Typography>
            <Divider />
            <Typography color="neutral.main" fontWeight={400} variant="body1">
              {data?.internalReference}
            </Typography>
          </Grid>
        </RenderByCondition>

        <Grid container rowGap={1} direction={'column'}>
          <Typography color="neutral.700" variant="body2" fontWeight={600}>
            {t('orderForm.additionalComments')}
          </Typography>
          <Divider />
          <Typography color="neutral.main" fontWeight={400} variant="body1">
            {data?.comment}
          </Typography>
        </Grid>

        <RenderByCondition show={[REQUEST_LETTER_COMMENT]}>
          <Grid container rowGap={1} direction={'column'}>
            <Typography color="neutral.700" variant="body2" fontWeight={600}>
              {t('orderForm.requestLetterComments')}
            </Typography>
            <Divider />
            <Typography color="neutral.main" fontWeight={400} variant="body1">
              {data?.requestLetterComment}
            </Typography>
          </Grid>
        </RenderByCondition>
      </Grid>
    );
  };

  const Documents = ({ ...StyledBoxProps }) => {
    const userRole = useSelector(getUserRole);

    const {
      downloadFile,
      fetchFiles,
      openPdfInNewTab,
      isSuccess,
      isLoading: pdfIsLoading,
    } = usePdfDoc();

    useEffect(() => {
      if (isSuccess) downloadFile();
    }, [isSuccess, downloadFile]);

    const getStatusInfo = (status: DocumentStatus) => {
      switch (status) {
        case DocumentStatus.Sent:
          return { name: 'Sent', color: '#986D0D' };
        case DocumentStatus.Denied:
          return { name: 'Denied', color: '#FF0000' };
        case DocumentStatus.Approved:
          return { name: 'Approved', color: '#006B52' };
        case DocumentStatus.None:
          return { name: 'None', color: '#808080' };
        default:
          break;
      }
    };

    useEffect(() => {
      if (data?.orderStateDocuments) {
        setCountOfDocumentStates(data?.orderStateDocuments?.length);

        setCountOfApprovalDocuments(
          data?.orderStateDocuments?.filter(
            (item) => item?.packetDetails?.status == DocumentStatus.Approved
          ).length
        );
      }
    }, [data]);

    const getApprovalPath = () => {
      return data?.orderStateDocuments
        ?.filter(
          (item) => item?.packetDetails?.status == DocumentStatus.Approved
        )
        .map((x) => `Document/download/${x.packetDetails.documentId}`);
    };
    const handleDownloadButtonClick = async (documentId, documentName) => {
      const res = await fetchFiles([prepareDownloadFileUrl(documentId)]);
      downloadFile(res?.data, documentName);
    };

    const hasIntegrationPartner = data?.hasIntegrationPartner;
    const hasModified = data?.hasModified;
    const hasComment =
      data?.orderStateDocuments[0]?.comment != null &&
      data?.orderStateDocuments[0]?.comment != '';
    let badgeText = '';
    if (hasModified) {
      badgeText = t('docs.SignedWithModification');
    } else if (hasComment) {
      badgeText = t('docs.SignedWithComment');
    } else {
      badgeText = t('docs.Signed');
    }

    return (
      <Grid container direction={'column'} rowGap={4}>
        <Grid item>
          <StyledBox {...StyledBoxProps}>
            {t('orderForm.documentsSection')}{' '}
          </StyledBox>
        </Grid>
        <RenderByRole
          userRole={userRole}
          allowedRoles={[USER_ROLE.VET, USER_ROLE.TM, USER_ROLE.CSS]}
        >
          {data?.orderStateDocuments
            ?.filter(
              (osd, index, self) =>
                self.findIndex((o) => o.documentId === osd.documentId) === index
            )
            .map((orderStateDocument) => {
              return (
                (orderStateDocument?.document?.type?.code ===
                  DocumentTypeEnum.Prescription ||
                  orderStateDocument?.document?.type?.code ===
                    DocumentTypeEnum.RequestLetter) && (
                  <Grid item key={orderStateDocument.id}>
                    {
                      <Grid container rowGap={1} direction={'column'} mb={2}>
                        <RenderByCondition show={[!hasIntegrationPartner]}>
                          <Typography
                            color="neutral.700"
                            variant="body2"
                            fontWeight={600}
                          >
                            {orderStateDocument?.document?.type?.name}{' '}
                            <Button
                              onClick={() =>
                                handleDownloadButtonClick(
                                  orderStateDocument?.document?.id,
                                  orderStateDocument?.document?.fileName
                                )
                              }
                              style={{ textTransform: 'none' }}
                            >
                              <Link download>{t('orderForm.download')}</Link>
                            </Button>
                          </Typography>
                          <Divider />
                        </RenderByCondition>
                        <RenderByCondition show={[hasIntegrationPartner]}>
                          <DocList
                            data={data?.orderStateDocuments?.map((item) => ({
                              title: t('docs.SignedPdf'),
                              description: formatDate(
                                item?.document?.creationDate
                              ),
                              bgColor: '#F8F9FA',
                              buttonActions: [
                                {
                                  title: t('global.buttonViewTitle'),
                                  type: 'button',
                                  onClick: async () => {
                                    fetchFiles([
                                      `Document/download/${item?.document?.id}`,
                                    ]).then((data) => {
                                      openPdfInNewTab(data?.data);
                                    });
                                  },
                                },
                                {
                                  icon: <DownloadIcon />,
                                  type: 'button',
                                  onClick: async () => {
                                    fetchFiles([
                                      `Document/download/${item?.document?.id}`,
                                    ]).then(() => {
                                      downloadFile(
                                        null,
                                        item?.document?.fileName
                                      );
                                    });
                                  },
                                },
                              ],
                            }))}
                            render={(item) => (
                              <DocumentRendrer
                                {...item}
                                badgeColor={hasModified ? '#61386D' : '#00AEEF'}
                                badgeText={badgeText}
                                sx={{ marginBottom: '1px' }}
                              />
                            )}
                          />
                          <Typography
                            color="neutral.700"
                            variant="body2"
                            fontWeight={400}
                          >
                            {orderStateDocument?.comment}
                          </Typography>
                        </RenderByCondition>
                      </Grid>
                    }
                  </Grid>
                )
              );
            })}
        </RenderByRole>
        <RenderByRole userRole={userRole} allowedRoles={[USER_ROLE.REGULATORY]}>
          <Grid
            container
            flexDirection={{ md: 'row', xs: 'column' }}
            gap={{ md: 2, xs: 1 }}
          >
            <StyledGrid item>
              <TextBadge
                name={`${t(
                  'orderForm.approvalStates'
                )}: ${countOfApprovalDocuments}/${countOfDocumentStates}`}
                color={'#0D8091'}
              />
            </StyledGrid>
            <StyledDownloadAllPacketsButton
              variant="outlined"
              onClick={() => {
                const ApprovalDocumentsDownload = [...getApprovalPath()];
                if (!ApprovalDocumentsDownload.length) return;
                fetchFiles(ApprovalDocumentsDownload);
              }}
              disabled={countOfApprovalDocuments <= 0}
              loading={pdfIsLoading}
            >
              {t('orderForm.downloadPacket')}
            </StyledDownloadAllPacketsButton>
          </Grid>
          {data?.orderStateDocuments?.map((item) => {
            return (
              <StyledAccordion key={item?.stateName}>
                <AccordionSummary
                  expandIcon={<ExpendIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  disabled={isNullOrEmpty(item?.packetDetails)}
                >
                  <StyledTypography>{item?.stateName}</StyledTypography>
                </AccordionSummary>
                <AccordionDetails>
                  <StyledPacketOrder
                    container
                    flexDirection={{ md: 'row', xs: 'column' }}
                  >
                    <Grid item>
                      <StyledTitlePacketOrder>
                        {t('docs.packetOrder')}&nbsp;{data?.id}
                      </StyledTitlePacketOrder>
                    </Grid>
                  </StyledPacketOrder>
                  {item?.packetDetails &&
                    item?.packetDetails?.status !== DocumentStatus.Sent && (
                      <OrderPacket
                        key={item?.packetDetails?.document?.id}
                        status={
                          getStatusInfo(item?.packetDetails?.status)?.name
                        }
                        color={
                          getStatusInfo(item?.packetDetails?.status)?.color
                        }
                        date={formatDate(
                          item?.packetDetails?.document?.updatedDate
                        )}
                        commentProps={{
                          comment: item?.packetDetails?.comment,
                          visible: !isNullOrEmpty(item?.packetDetails?.comment),
                        }}
                        buttonProps={{
                          action: () => {
                            fetchFiles([
                              `Document/download/${item?.packetDetails?.documentId}`,
                            ]);
                          },
                        }}
                      />
                    )}
                  {item?.packetDetails?.document?.documentHistory
                    .filter(
                      (packetHistory) =>
                        packetHistory?.status !== DocumentStatus.Sent
                    )
                    .map((packetHistory) => (
                      <OrderPacket
                        key={packetHistory?.id}
                        status={getStatusInfo(packetHistory?.status)?.name}
                        color={getStatusInfo(packetHistory?.status)?.color}
                        date={formatDate(packetHistory?.date)}
                        commentProps={{
                          comment: packetHistory?.comment,
                          visible: !isNullOrEmpty(packetHistory?.comment),
                        }}
                        buttonProps={{
                          action: () => {
                            fetchFiles([
                              `Document/download/${item?.packetDetails?.documentId}?version=${packetHistory?.id}`,
                            ]);
                          },
                        }}
                      />
                    ))}
                  {item?.packetDetails && (
                    <OrderPacket
                      key={item?.packetDetails?.document?.id}
                      status={getStatusInfo(DocumentStatus.Sent)?.name}
                      color={getStatusInfo(DocumentStatus.Sent)?.color}
                      date={formatDate(
                        item?.packetDetails?.document?.creationDate
                      )}
                      commentProps={{
                        comment: null,
                        visible: false,
                      }}
                      buttonProps={{
                        action: () => {
                          fetchFiles([
                            `Document/download/${item?.packetDetails?.documentId}`,
                          ]);
                        },
                      }}
                    />
                  )}
                </AccordionDetails>
              </StyledAccordion>
            );
          })}
        </RenderByRole>
      </Grid>
    );
  };

  const Serials = ({ ...StyledBoxProps }) => {
    return (
      <Grid container direction={'column'} rowGap={4}>
        <Grid item>
          <StyledBox {...StyledBoxProps}>
            {' '}
            {t('orderForm.serialsSection')}
          </StyledBox>
        </Grid>

        <StyledTableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableHeadCell sx={{ padding: 0 }}>
                  <StyledTableHeadCellText variant="body2">
                    {t('orderForm.serialNumber')}
                  </StyledTableHeadCellText>
                </StyledTableHeadCell>
                <StyledTableHeadCell sx={{ padding: 0 }}>
                  <StyledTableHeadCellText variant="body2">
                    {t('orderForm.serialStatus')}
                  </StyledTableHeadCellText>
                </StyledTableHeadCell>
                <StyledTableHeadCell sx={{ padding: 0 }}>
                  <StyledTableHeadCellText variant="body2" width={140}>
                    {t('orderForm.numberOfBottles')}
                  </StyledTableHeadCellText>
                </StyledTableHeadCell>
                <StyledTableHeadCell sx={{ padding: 0 }}>
                  <StyledTableHeadCellText variant="body2">
                    {t('orderForm.inventory')}
                  </StyledTableHeadCellText>
                </StyledTableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.orderSerials?.map((row) => (
                <React.Fragment key={row?.serialNumber}>
                  <TableRow>
                    <StyledTableBodyCell>
                      <StyledTableBodyCellText variant="body2" padding={0}>
                        {row?.serialNumber}
                      </StyledTableBodyCellText>
                    </StyledTableBodyCell>
                    {(() => {
                      const filteredSteps = row?.orderSerialSteps?.filter(
                        (step) => step?.stepDate !== null
                      );
                      const lastStep = filteredSteps?.length
                        ? filteredSteps[filteredSteps?.length - 1]
                        : null;

                      return lastStep ? (
                        <StyledTableBodyCell>
                          <StyledTableBodyCellText variant="body2">
                            {lastStep.orderSerialStepStatus?.name}
                          </StyledTableBodyCellText>
                        </StyledTableBodyCell>
                      ) : null;
                    })()}

                    <StyledTableBodyCell>
                      <StyledTableBodyCellText variant="body2">
                        {row?.numberOfBottles}
                      </StyledTableBodyCellText>
                    </StyledTableBodyCell>
                    <StyledTableBodyCell>
                      <StyledTableBodyCellText variant="body2">
                        {row?.inventory}
                      </StyledTableBodyCellText>
                    </StyledTableBodyCell>
                  </TableRow>
                  <Divider />
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Grid>
    );
  };

  const handleStyledBox = (section) => {
    return {
      ...(activeSection === section
        ? {
            style: {
              backgroundColor: theme.palette.primary[900],
              color: theme.palette.neutral[50],
            },
          }
        : { variant: 'outlined' }),
    };
  };

  return (
    <Grid container rowGap={6} flexDirection={'column'}>
      <Element name="section1" className="section">
        <FarmAndAddresses {...(handleStyledBox('section1') as any)} />{' '}
      </Element>

      <Element name="section2" className="section">
        <InfectionAndIsolates {...(handleStyledBox('section2') as any)} />
      </Element>

      <Element name="section3" className="section">
        <DosingAndFilling {...(handleStyledBox('section3') as any)} />
      </Element>

      <RenderByCondition show={[PRICING_STEP_VISIBILITY]}>
        <Element name="section4" className="section">
          <BottlePricing {...(handleStyledBox('section4') as any)} />
        </Element>
      </RenderByCondition>

      <Element name="section5" className="section">
        <AdditionalInstructions {...(handleStyledBox('section5') as any)} />
      </Element>

      <Element name="section6" className="section">
        <Documents {...(handleStyledBox('section6') as any)} />
      </Element>

      <RenderByCondition show={[SERIALS_VISIBILITY]}>
        <Element name="section7" className="section">
          <Serials {...(handleStyledBox('section7') as any)} />
        </Element>
      </RenderByCondition>
    </Grid>
  );
};

export default OrderDetailsContent;
