import { Paper, Typography, TypographyProps, styled } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { TVariant } from './types';

const StyledContainer = styled(Paper)<{ color: string }>(({ color }) => ({
  borderRadius: '8px',
  backgroundColor: alpha(color, 0.1),
  padding: '6px 8px',
  color: color,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  alignItems: 'center',
  minWidth: '150px',
  width: '100%',
  display: 'flex',
  marginBottom: '6px',
  height: 40,
}));

const TextWrapper = styled('div')({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const StyledTypography = styled(Typography)(() => ({
  fontSize: '0.9em',
  fontWeight: '600',
  lineHeight: '20px',
})) as React.FC<Omit<TypographyProps, 'variant'> & { variant: TVariant }>;

export { StyledContainer, StyledTypography, TextWrapper };
