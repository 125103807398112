import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAddressesManager } from './index.type';
import {
  orderAddressesType,
  statesDataType,
} from 'components/organisms/Addresses/AddressForms/Main/types';

export const initialState: IAddressesManager = {
  statesFormData: [],
  selectedStateIdForm: '',
  orderAddressesForm: [],
  addressesByStateForm: [],
  proximityAddressesOrder: [],
  statesDataOrder: [],
  selectedFarmsRows: [],
  selectedShippingRows: {},
  newOrderAddresses: [],
  custmerId: 0,
  selectedStateId: '',
};

const addressesManagerSlice = createSlice({
  name: 'addressesManager',
  initialState,
  reducers: {
    setStatesFormData: (state, action: PayloadAction<any[]>) => {
      state.statesFormData = action.payload;
    },
    setSelectedStateIdForm: (state, action: PayloadAction<string>) => {
      state.selectedStateIdForm = action.payload;
    },
    setOrderAddressesForm: (
      state,
      action: PayloadAction<orderAddressesType[]>
    ) => {
      state.orderAddressesForm = action.payload;
    },
    setAddressesByStateForm: (state, action: PayloadAction<any[]>) => {
      state.addressesByStateForm = action.payload;
    },
    setProximityAddressesOrder: (
      state,
      action: PayloadAction<orderAddressesType[]>
    ) => {
      state.proximityAddressesOrder = action.payload;
    },
    setStatesDataOrder: (state, action: PayloadAction<statesDataType[]>) => {
      state.statesDataOrder = action.payload;
    },
    setSelectedFarmsRows: (state, action: PayloadAction<any[]>) => {
      state.selectedFarmsRows = action.payload;
    },
    setSelectedShippingRowsForState: (
      state,
      action: PayloadAction<{ stateId: string; rows: any[] }>
    ) => {
      const { stateId, rows } = action.payload;
      state.selectedShippingRows[stateId] = rows;
    },
    resetSelectedShippingRowsForState: (state) => {
      state.selectedShippingRows = {};
    },
    setNewOrderAddresses: (
      state,
      action: PayloadAction<orderAddressesType[]>
    ) => {
      state.newOrderAddresses = action.payload;
    },
    setCustmerId: (state, action: PayloadAction<number>) => {
      state.custmerId = action.payload;
    },
    setSelectedStateId: (state, action: PayloadAction<string>) => {
      state.selectedStateId = action.payload;
    },
    resetAddressManagementAll: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  setStatesFormData,
  setSelectedStateIdForm,
  setOrderAddressesForm,
  setAddressesByStateForm,
  setProximityAddressesOrder,
  setStatesDataOrder,
  setSelectedFarmsRows,
  setSelectedShippingRowsForState,
  setNewOrderAddresses,
  setCustmerId,
  setSelectedStateId,
  resetAddressManagementAll,
  resetSelectedShippingRowsForState,
} = addressesManagerSlice.actions;
export default addressesManagerSlice.reducer;
