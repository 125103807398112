import { Grid, Typography, useTheme } from '@mui/material';
import CustomButton from 'components/atoms/Button';
import CustomInput from 'components/atoms/Input';
import { useTranslation } from 'react-i18next';
import { IContact } from 'types';
import { StyledCustomSelect } from './style';
import RenderByRole from 'components/atoms/RenderByRole';
import { useSelector } from 'react-redux';
import { getUserRole } from 'store/features/auth/index.selector';
import { USER_ROLE } from 'constants/index';

interface FormProps {
  goNext: () => void;
  mails?: { cc: Array<IContact>; main: Array<IContact> };
  stateName?: string;
  formData?: any;
  sendByEmailForm: () => void;
  sendByDocSignForm: () => void;
  btnIsLoading?: boolean;
  isBtnDisabled?: boolean;
  docuSignEnabled?: boolean;
}
const Form = (props: FormProps) => {
  const {
    stateName,
    formData,
    sendByEmailForm,
    sendByDocSignForm,
    btnIsLoading,
    isBtnDisabled,
    docuSignEnabled,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const userRole = useSelector(getUserRole);

  const ccMails = formData?.values?.ccEmails?.map((mail) => ({
    ...mail,
    value: mail?.email,
    label: mail?.email,
  }));

  const mainEmails = formData?.values?.mainEmails?.map((mail) => ({
    ...mail,
    value: mail?.email,
    label: mail?.email,
  }));

  const objectErrorMessage = formData?.errors?.object;
  const objectIsError =
    formData?.touched.object && Boolean(formData?.errors?.object);

  const mailContentErrorMessage = formData?.errors?.emailContent;
  const mailContentIsError =
    formData?.touched.emailContent && Boolean(formData?.errors?.emailContent);

  return (
    <form onSubmit={formData.handleSubmit}>
      <Grid container rowSpacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" color={'primary'}>
            {stateName}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomInput
            fullWidth
            id="object"
            name="object"
            label={t('docs.objectLabel')}
            variant="outlined"
            value={formData.values.object}
            onChange={formData.handleChange}
            size="medium"
            error={objectIsError}
            errorMessage={objectErrorMessage}
            onBlur={formData?.handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledCustomSelect
            fullWidth
            options={[]}
            id="mainEmails"
            name="mainEmails"
            label={t('docs.mainEmailsLabel')}
            variant="outlined"
            value={mainEmails}
            onChange={formData.handleChange}
            size="medium"
            multiple={true}
            labelColor={theme.palette.secondary.main}
            chipBgColor={theme.palette.neutral[200]}
            deleteIconHidden={true}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledCustomSelect
            fullWidth
            options={[]}
            disabled={true}
            id="ccEmails"
            name="ccEmails"
            label={t('docs.ccEmailsLabel')}
            variant="outlined"
            value={ccMails}
            onChange={formData.handleChange}
            size="medium"
            multiple={true}
            labelColor={theme.palette.secondary.main}
            chipBgColor={theme.palette.neutral[200]}
            deleteIconHidden={true}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInput
            fullWidth
            multiline
            minRows={12}
            id="emailContent"
            name="emailContent"
            label={t('docs.emailContentLabel')}
            variant="outlined"
            value={formData.values.emailContent}
            onChange={formData.handleChange}
            size="medium"
            errorMessage={mailContentErrorMessage}
            error={mailContentIsError}
            onBlur={formData?.handleBlur}
          />
        </Grid>
        <Grid
          item
          pt={2}
          xs={12}
          container
          alignItems={'flex-end'}
          justifyContent={'flex-end'}
        >
          <RenderByRole
            userRole={userRole}
            allowedRoles={[USER_ROLE.REGULATORY, USER_ROLE.REGULATORYMANAGER]}
          >
            <Grid item>
              <CustomButton
                onClick={sendByEmailForm}
                type="submit"
                variant="outlined"
                size="medium"
                loading={btnIsLoading}
                disabled={isBtnDisabled}
                style={{ marginRight: 4 }}
              >
                {t('docs.sendEmail')}
              </CustomButton>
              <CustomButton
                onClick={sendByDocSignForm}
                type="submit"
                variant="contained"
                size="medium"
                loading={btnIsLoading}
                disabled={docuSignEnabled}
              >
                {t('docs.sendDocSign')}
              </CustomButton>
            </Grid>
          </RenderByRole>
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;
