import { Box, styled } from '@mui/material';
import { CustomCircleProps } from './types';

export const StyledBox = styled(Box)<Omit<CustomCircleProps, 'value'>>`
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;

  ${({ size }) => {
    if (size === 'small') return { height: 24, width: 24, fontSize: 12 };
    else if (size === 'medium') return { height: 32, width: 32, fontSize: 14 };
    else if (size === 'large') return { height: 40, width: 40, fontSize: 16 };
  }}

  ${({ color, theme, variant }) => {
    switch (color) {
      case 'primary':
        return variant === 'contained'
          ? { background: theme.palette.primary.main }
          : {
              border: `1px solid ${theme.palette.primary.main}`,
              background: theme.palette.neutral[50],
            };
      case 'secondary':
        return variant === 'contained'
          ? { background: theme.palette.secondary.main }
          : {
              border: `1px solid ${theme.palette.secondary.main}`,
              background: theme.palette.neutral[50],
            };
      case 'success':
        return variant === 'contained'
          ? { background: theme.palette.success.main }
          : {
              border: `1px solid ${theme.palette.success.main}`,
              background: theme.palette.neutral[50],
            };
      case 'error':
        return variant === 'contained'
          ? { background: theme.palette.error.main }
          : {
              border: `1px solid ${theme.palette.error.main}`,
              background: theme.palette.neutral[50],
            };
      case 'info':
        return variant === 'contained'
          ? { background: theme.palette.info.main }
          : {
              border: `1px solid ${theme.palette.info.main}`,
              background: theme.palette.neutral[50],
            };
      case 'warning':
        return variant === 'contained'
          ? { background: theme.palette.warning.main }
          : {
              border: `1px solid ${theme.palette.warning.main}`,
              background: theme.palette.neutral[50],
            };
      default:
        return color ? { background: color } : null;
    }
  }}
`;
