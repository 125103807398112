import { Typography } from '@mui/material';

import { useMemo } from 'react';
import { Grid } from '@mui/material';
import { LogoIcon } from 'components/icons';
import { useSelector } from 'react-redux';
import { getUserRole } from 'store/features/auth/index.selector';
import { PAGES } from 'constants/index';
import { Link } from 'react-router-dom';
import {
  AppVersionContainer,
  ReleaseContainer,
  StyledFooter,
  StyledLink,
  StyledList,
  StyledListItem,
  StyledTypography,
} from './styles';
import { useTranslation } from 'react-i18next';
import { useGetReleaseNumberQuery } from 'api/appRealseNumber';
import RenderByCondition from 'components/atoms/RenderByCondition';
import { getEnvironmentSettings } from 'utils';

const ReservedRights = () => {
  const { t } = useTranslation();

  return (
    <Grid item container alignItems="center" justifyContent={'center'}>
      <Grid item>
        <Typography variant="body2">
          Copyright ©{new Date().getFullYear()} Ceva.{' '}
          {t('footer.rightsReserved')}
        </Typography>
      </Grid>
    </Grid>
  );
};

const Links = () => {
  const { t, i18n } = useTranslation();
  const userRole = useSelector(getUserRole);

  const links = useMemo(
    () => [
      {
        id: 1,
        label: t('footer.cookieParameters'),
        to: '#',
      },
      {
        id: 2,
        label: t('footer.legalNotice'),
        to: PAGES[userRole].LEGAL_NOTICE,
      },
      {
        id: 3,
        label: t('footer.privacyPolicy'),
        to: PAGES[userRole].PRIVACY_POLICY,
      },
      {
        id: 4,
        label: t('footer.termsOfService'),
        to: PAGES[userRole].TERMS_OF_SERVICE,
      },
      {
        id: 5,
        label: t('footer.termsOfSale'),
        to: PAGES[userRole].TERMS_OF_SALE,
      },
    ],
    [i18n, t, userRole]
  );

  const showConsentChoices = () => {
    (window as any)?.Didomi?.preferences?.show();
  };

  return (
    <Grid item xs={12} md={i18n?.language == 'fr' ? 10 : 8}>
      <StyledList sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
        {links.map((link) => (
          <StyledListItem
            key={link.id}
            onClick={() => {
              if (link.id === 1) showConsentChoices();
            }}
          >
            <StyledLink to={link.to}>
              <StyledTypography
                variant="body2"
                fontWeight={500}
                color="primary"
              >
                {link.label}
              </StyledTypography>
            </StyledLink>
          </StyledListItem>
        ))}
      </StyledList>
    </Grid>
  );
};

const Logo = () => {
  const userRole = useSelector(getUserRole);

  return (
    <Grid item xs>
      <Grid
        container
        ml={{ md: 6, xs: 0 }}
        alignItems={'center'}
        justifyContent={{ xs: 'center', md: 'flex-start' }}
      >
        <Link to={PAGES[userRole]?.DASHBOARD}>
          <LogoIcon data-testid="logo-icon" />
        </Link>
      </Grid>
    </Grid>
  );
};

const { APP_VERSION_VISIBILITY } = getEnvironmentSettings();

const Footer = () => {
  const data: any = useGetReleaseNumberQuery();
  const frontAppRelease = process.env.REACT_APP_VERSION;

  return (
    <>
      <RenderByCondition show={[APP_VERSION_VISIBILITY]}>
        <AppVersionContainer>
          <ReleaseContainer>
            <Typography variant="body2" color="inherit">
              Front Application Release: {frontAppRelease}
            </Typography>
            <Typography variant="body2" color="inherit">
              BackOffice Application Release: {data?.data}
            </Typography>
          </ReleaseContainer>
        </AppVersionContainer>
      </RenderByCondition>

      <StyledFooter component="footer">
        <Grid container flexDirection={'column'}>
          <Grid
            container
            item
            justifyContent="space-between"
            alignItems="center"
            flexDirection={{ xs: 'column', sm: 'row' }}
            spacing={2}
            mb={2}
          >
            <Logo />
            <Links />
          </Grid>
          <ReservedRights />
        </Grid>
      </StyledFooter>
    </>
  );
};

export default Footer;
