import React, { SVGProps } from 'react';

const Update = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#6A7178"
      d="M4.94 8.94L4 8l-2.666 2.667 2.667 2.667.94-.94-1.054-1.06h10.114V10H3.887l1.054-1.06zM11.06 7.06L12 8l2.667-2.666-2.666-2.667-.94.94 1.053 1.06H2.001V6h10.113l-1.053 1.06z"
    ></path>
  </svg>
);

export default Update;
