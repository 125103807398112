import styled from '@emotion/styled';
import {
  Grid,
  GridProps,
  Theme,
  Typography,
  TypographyProps,
} from '@mui/material';
import CustomInput from 'components/atoms/Input';

export const StyledTitle = styled(Typography)<TypographyProps>`
  ${({ theme }: { theme: Theme }) => {
    return {
      borderRadius: '8px',
      fontWeight: 500,
      minWidth: '100px',
      paddingBottom: '16px',
      color: theme.palette.neutral[800],
    };
  }}
`;

export const PricingElement = styled(Grid)<GridProps>`
  padding: 8px 0px 8px 8px;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
  border-left-width: 0px;
`;
export const StyleCustomInput = styled(CustomInput)`
  margin-left: 0px !important;
  margin: 0px;
`;
