import * as React from 'react';
import { SVGProps } from 'react';
const Download = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#6A7178"
      d="M9.833 1.333H4.499c-.733 0-1.326.6-1.326 1.333l-.007 10.667c0 .733.593 1.333 1.327 1.333h8.006c.734 0 1.334-.6 1.334-1.333v-8l-4-4Zm2.666 12h-8V2.666h4.667V6h3.333v7.333Zm-6.666-3.327.94.94 1.06-1.053v2.773h1.333V9.893l1.06 1.06.94-.947-2.66-2.673-2.673 2.673Z"
    />
  </svg>
);
export default Download;
