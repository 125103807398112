import { Typography, styled } from '@mui/material';
import CustomButton from 'components/atoms/Button';
import TextBadge from 'components/atoms/TextBadge';
import { theme } from 'theme';

export const StyledButton = styled(CustomButton)`
  ${({ theme }) => ({
    boxShadow: 'none',
    background: theme.palette.neutral[50],
    ':hover': {
      borderWidth: 0,
      outline: 'none',
      background: theme.palette.neutral[50],
    },
    ':active': {
      borderWidth: 0,
      outline: 'none',
      background: theme.palette.neutral[50],
    },
    ':focus': {
      borderWidth: 0,
      outline: 'none',
      background: theme.palette.neutral[50],
    },
  })}
`;

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const StyledTextBadge = styled(TextBadge)`
  display: flex;
  height: 24px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

export const StyledTypography = styled(Typography)`
  font-family: ${theme.typography.fontFamily};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  color: var(--brand-primary-base, #29306b);
`;
