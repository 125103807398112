/* eslint-disable no-unsafe-optional-chaining */
import { USER } from 'constants/index';
import { RootState } from 'store';
import { getFieldVisibility } from 'utils';

const { SHIPPING_ADDRESS_SELECT_VISIBILITY, BILL_TO_VISIBILITY } =
  getFieldVisibility();

export const getFormConfig = (state: RootState) => {
  return state?.orderFormReducer?.orderForm?.config;
};

export const getOrderId = (state: RootState) => {
  return state?.orderFormReducer?.orderForm?.id;
};

export const getFormData = (state: RootState) => {
  return state?.orderFormReducer?.orderForm;
};

export const getSelectedVet = (state: RootState) => {
  return state?.orderFormReducer?.orderForm?.farmInfo?.vets;
};

export const getFarmData = (state: RootState) => {
  return state?.orderFormReducer?.orderForm?.farmInfo;
};

export const getInfectionData = (state: RootState) => {
  return state?.orderFormReducer?.orderForm?.infectionInfo;
};

export const getAddresses = (state: RootState) => {
  const { farmAddresses } = state?.orderFormReducer?.orderForm?.farmInfo;

  return {
    farmAddresses: farmAddresses?.value,
  };
};

export const getDosingAndFillingInfo = (state: RootState) => {
  return state?.orderFormReducer?.orderForm?.dosingAndFillingInfo;
};

export const getBottlePricingData = (state: RootState) => {
  return state?.orderFormReducer?.orderForm?.bottlesPricingInfo;
};

export const getAdditionalInstructions = (state) => {
  return state?.orderFormReducer?.orderForm?.AdditionalInstructionsInfo;
};

export const getAuthorizedIsolates = (state: RootState) => {
  return state?.orderFormReducer?.orderForm?.infectionInfo?.authorizedIsolates
    ?.data;
};

export const getSaveOrderData = (state: RootState) => ({
  vetId: state?.orderFormReducer?.orderForm?.farmInfo?.vets?.value?.id,
  createdByUserId: state?.authReducer?.account?.id,
  animalCount:
    state?.orderFormReducer?.orderForm?.infectionInfo?.numOfAnimal?.value,
  bottleTag:
    state?.orderFormReducer?.orderForm?.AdditionalInstructionsInfo?.bottleTag
      ?.value,
  internalReference:
    state?.orderFormReducer?.orderForm?.AdditionalInstructionsInfo
      ?.internalReference?.value || '',
  comment:
    state?.orderFormReducer?.orderForm?.AdditionalInstructionsInfo
      ?.additionalComment?.value,
  requestLetterComment:
    state?.orderFormReducer?.orderForm?.AdditionalInstructionsInfo
      ?.requestLetterComment?.value,
  capColorId:
    state?.orderFormReducer?.orderForm?.AdditionalInstructionsInfo?.colors
      ?.value?.id,
  customerId: BILL_TO_VISIBILITY
    ? state?.orderFormReducer?.orderForm?.farmInfo?.billingAddresses?.value?.id
    : state?.orderFormReducer?.orderForm?.farmInfo?.customer?.value?.id,
  animalGroupId:
    state?.orderFormReducer?.orderForm?.infectionInfo?.animalGroups?.value?.id,
  animalSpeciesId:
    state?.orderFormReducer?.orderForm?.infectionInfo?.animalSpecies?.value?.id,
  productId:
    state?.orderFormReducer?.orderForm?.infectionInfo?.products?.value?.id ||
    null,
  doseId: state?.orderFormReducer?.orderForm?.infectionInfo?.doses?.value?.id,
  adjuvantId:
    state?.orderFormReducer?.orderForm?.infectionInfo?.adjuvants?.value?.id,
  administrationRouteId:
    state?.orderFormReducer?.orderForm?.infectionInfo?.administrationRoutes
      ?.value?.id || null,
  orderBottles: (function () {
    const bottles =
      state?.orderFormReducer?.orderForm?.dosingAndFillingInfo?.bottles?.value;

    return bottles
      ?.map((bottle) => ({
        bottleCount: bottle?.count,
        bottlePrice: bottle?.price,
        bottleId: bottle?.id,
      }))
      .filter((bottle) => bottle?.bottleCount > 0);
  })(),
  orderIsolates:
    state?.orderFormReducer?.orderForm?.infectionInfo?.isolates?.value?.map(
      (isolate) => ({ isolateId: isolate?.id, isBoosted: isolate?.isBoosted })
    ),
  farmId: !state?.orderFormReducer?.orderForm?.farmInfo?.nonAdjacentRequired
    ?.value
    ? state?.orderFormReducer?.orderForm?.farmInfo?.farmAddresses?.value?.[0]
        ?.id
    : null,
  nonAdjacentFarmIds: state?.orderFormReducer?.orderForm?.farmInfo
    ?.nonAdjacentRequired?.value
    ? state?.orderFormReducer?.orderForm?.farmInfo?.farmAddresses?.value?.map(
        (farm) => farm?.id
      )
    : [],
  orderShippingAddresses: SHIPPING_ADDRESS_SELECT_VISIBILITY
    ? state?.orderFormReducer?.orderForm?.farmInfo?.shippingSites?.value?.map(
        (address: { id: number }) => ({
          addressId: address?.id,
        })
      )
    : state?.addressesManager?.newOrderAddresses?.map((address: any) => {
        return {
          addressId: address?.addressId,
          stateId: address?.stateId,
        };
      }),
  monthlyBottleUsage:
    state?.orderFormReducer?.orderForm?.dosingAndFillingInfo?.bottleUsage
      ?.value,
  parentOrderID: 0,
  vaccinationSchedule:
    state?.orderFormReducer?.orderForm?.AdditionalInstructionsInfo
      ?.vaccinationSchedule?.value,
  clinicalSign:
    state?.orderFormReducer?.orderForm?.AdditionalInstructionsInfo
      ?.clinicalSigns?.value,
});

//state: RootState
export const getShareData = (state: RootState) => ({
  id: state?.orderFormReducer?.orderForm?.id,
  createdByUserId: state?.authReducer?.account?.id,
  lockedByUserId: null,
  vetId: state?.orderFormReducer?.orderForm?.farmInfo?.vets?.value?.id,
  lockTime: null,
  status: 0,
  animalCount:
    state?.orderFormReducer?.orderForm?.infectionInfo?.numOfAnimal?.value,
  monthlyBottleUsage:
    state?.orderFormReducer?.orderForm?.dosingAndFillingInfo?.bottleUsage
      ?.value,
  bottleTag:
    state?.orderFormReducer?.orderForm?.AdditionalInstructionsInfo?.bottleTag
      ?.value,
  comment:
    state?.orderFormReducer?.orderForm?.AdditionalInstructionsInfo
      ?.additionalComment?.value,
  requestLetterComment:
    state?.orderFormReducer?.orderForm?.AdditionalInstructionsInfo
      ?.requestLetterComment?.value,
  capColorId:
    state?.orderFormReducer?.orderForm?.AdditionalInstructionsInfo?.colors
      ?.value?.id || null,
  customerId: BILL_TO_VISIBILITY
    ? state?.orderFormReducer?.orderForm?.farmInfo?.billingAddresses?.value?.id
    : state?.orderFormReducer?.orderForm?.farmInfo?.customer?.value?.id,
  animalGroupId:
    state?.orderFormReducer?.orderForm?.infectionInfo?.animalGroups?.value
      ?.id || null,
  animalSpeciesId:
    state?.orderFormReducer?.orderForm?.infectionInfo?.animalSpecies?.value
      ?.id || null,
  productId:
    state?.orderFormReducer?.orderForm?.infectionInfo?.products?.value?.id ||
    null,
  doseId:
    state?.orderFormReducer?.orderForm?.infectionInfo?.doses?.value?.id || null,
  administrationRouteId:
    state?.orderFormReducer?.orderForm?.infectionInfo?.administrationRoutes
      ?.value?.id || null,
  internalReference:
    state?.orderFormReducer?.orderForm?.AdditionalInstructionsInfo
      ?.internalReference?.value || '',
  serialNumber: null,
  referredOrderId: null,
  orderBottles: (function () {
    const bottles =
      state?.orderFormReducer?.orderForm?.dosingAndFillingInfo?.bottles?.value;
    if (!bottles) return [];

    return bottles
      ?.map((bottle) => ({
        bottleCount: bottle?.count,
        bottlePrice: bottle?.price,
        bottleId: bottle?.id,
      }))
      .filter((bottle) => bottle?.bottleCount > 0);
  })(),
  orderIsolates: (function () {
    const Isolates =
      state?.orderFormReducer?.orderForm?.infectionInfo?.isolates?.value;
    if (!Isolates) return null;
    return Isolates?.map((isolate) => ({
      isolateId: isolate?.id,
      isBoosted: isolate?.isBoosted,
    }));
  })(),
  farmId: !state?.orderFormReducer?.orderForm?.farmInfo?.nonAdjacentRequired
    ?.value
    ? state?.orderFormReducer?.orderForm?.farmInfo?.farmAddresses?.[0]?.value
        ?.id
    : null,
  nonAdjacentFarmIds: state?.orderFormReducer?.orderForm?.farmInfo
    ?.nonAdjacentRequired
    ? state?.orderFormReducer?.orderForm?.farmInfo?.farmAddresses?.value?.map(
        (farm) => farm?.id
      )
    : null,
  orderShippingAddresses: state?.addressesManager?.newOrderAddresses?.map(
    (address: any) => {
      if (SHIPPING_ADDRESS_SELECT_VISIBILITY)
        return {
          addressId: address?.id,
        };
      return {
        addressId: address?.addressId,
        stateId: address?.stateId,
      };
    }
  ),
  adjuvantId:
    state?.orderFormReducer?.orderForm?.infectionInfo?.adjuvants?.value?.id ||
    null,
  vaccinationSchedule:
    state?.orderFormReducer?.orderForm?.AdditionalInstructionsInfo
      ?.vaccinationSchedule?.value,
  clinicalSign:
    state?.orderFormReducer?.orderForm?.AdditionalInstructionsInfo
      ?.clinicalSigns?.value,
});

export const getIsUserTypeTm = (state: RootState) =>
  state?.authReducer?.account?.role === USER.TM;

export const getIsUserTypeVet = (state: RootState) =>
  state?.authReducer?.account?.role === USER.VET;

export const getOrderFormMode = (state: RootState) =>
  state?.orderFormReducer?.orderForm?.orderMode;

export const getSelectedCustomer = (state: RootState) => {
  return state?.orderFormReducer?.orderForm?.farmInfo?.customer;
};
