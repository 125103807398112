import withLayout from 'layout';
import {
  StyledCircleupIcon,
  StyledFormHeaderContainer,
  StyledGrid,
  StyledGridScroll,
  StyledTitleStatusOrder,
  StyledTypography,
} from './styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Skeleton,
  Stack,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import OrderDetailsContent from 'components/organisms/OrderDetailsContent';
import { useTranslation } from 'react-i18next';
import { ExpandIconClose, ExpandIconOpen } from 'components/icons';
import SerialTimeline from 'components/molecules/OrderTimeLineSerials';
import Sidebar from 'components/organisms/SideBar';
import { useEffect, useState } from 'react';
import {
  useGetOrderByIdQuery,
  useGetOrderConfigQuery,
  useLazyGetAuthorizedIsolatesQuery,
} from 'api/orderForm';
import { useParams } from 'react-router-dom';
import { getUserAccount } from 'store/features/auth/index.selector';
import { useSelector } from 'react-redux';
import { formatDate, getEnvironmentSettings, getFieldVisibility } from 'utils';
import PageHeader from 'components/molecules/PageHeader';
import RenderByCondition from 'components/atoms/RenderByCondition';

const { SERIALS_SELECT_VISIBILITY } = getEnvironmentSettings();

const SerialsAccordionMobile = ({ data }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={() => handleChange()}
      sx={{ marginX: 2, boxShadow: 'none' }}
    >
      <AccordionSummary
        expandIcon={expanded ? <ExpandIconClose /> : <ExpandIconOpen />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{t('orderForm.orderStatus')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {data.length > 0 && (
          <SerialTimeline
            orderSerials={data}
            hideSelect={SERIALS_SELECT_VISIBILITY}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

const AdminOrderDetails = () => {
  const user = useSelector(getUserAccount);
  useGetOrderConfigQuery(
    { userId: user?.id, role: user?.role },
    { skip: !(user?.id && user?.role) }
  );
  const [activeSection, setActiveSection] = useState('');
  const handleSectionClick = (sectionId) => {
    setActiveSection(sectionId);
  };
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const idFromParams = parseInt(id, 10) || 0;
  const { data, isLoading, isFetching } = useGetOrderByIdQuery(idFromParams);
  const [result, setResult] = useState(null);
  const [orderSerials, setOrderSerials] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const {
    PRICING_STEP_VISIBILITY,
    SERIALS_VISIBILITY,
    ISOLATE_DEROGATION_COLUMN_VISIBILITY,
  } = getFieldVisibility();

  const [
    getAuthorizedIsolates,
    {
      isLoading: authorizedIsolatesLoading,
      isFetching: authorizedIsolatesFetching,
    },
  ] = useLazyGetAuthorizedIsolatesQuery();

  useEffect(() => {
    if (data && ISOLATE_DEROGATION_COLUMN_VISIBILITY) {
      getAuthorizedIsolates({
        customerId: data?.customer?.id,
        farmId: data?.farm?.id,
        animalSpecieId: data?.animalSpecies?.id,
      });
    }
  }, [data]);

  useEffect(() => {
    if (!isLoading && !isFetching && data) {
      setResult(data);
      const newOrderSerials = data?.orderSerials
        ?.map((orderSerial) => {
          let lastNonNullStepIndex = -1;
          orderSerial?.orderSerialSteps?.forEach((serialStep, index) => {
            if (serialStep?.stepDate !== null) {
              lastNonNullStepIndex = index;
            }
          });
          return {
            ...orderSerial,
            serialNumber: orderSerial?.serialNumber,
            steps: orderSerial?.orderSerialSteps?.map((serialStep, index) => {
              const formattedDate = serialStep?.stepDate
                ? formatDate(serialStep.stepDate)
                : null;
              return {
                ...serialStep,
                name: serialStep?.orderSerialStepStatus?.name,
                date: formattedDate,
                description: serialStep?.orderSerialStepStatus?.description,
                color: serialStep?.orderSerialStepStatus?.color,
                current: index === lastNonNullStepIndex,
              };
            }),
          };
        })
        .filter((item) => item);
      setOrderSerials(newOrderSerials);
    }
  }, [data, isFetching, isLoading]);

  const sidebarItems = [
    {
      id: 'section1',
      label: t('orderForm.stepOneTitle'),
    },
    {
      id: 'section2',
      label: t('orderForm.stepTwoTitle'),
    },
    {
      id: 'section3',
      label: t('orderForm.stepThreeTitle'),
    },
    {
      id: 'section5',
      label: t('orderForm.stepFiveTitle'),
    },
    {
      id: 'section6',
      label: t('orderForm.documentsSection'),
    },
  ];
  if (PRICING_STEP_VISIBILITY) {
    sidebarItems.splice(3, 0, {
      id: 'section4',
      label: t('orderForm.stepFourTitle'),
    });
  }
  if (SERIALS_VISIBILITY) {
    sidebarItems.push({
      id: 'section7',
      label: t('orderForm.serialsSection'),
    });
  }
  const hasCancellationComment = Boolean(result?.cancelReason);

  return (
    <>
      <PageHeader title="" />
      <Grid
        container
        justifyContent="center"
        height={'100%'}
        width={'100%'}
        pb={10}
      >
        {!isLoading &&
        !isFetching &&
        !authorizedIsolatesFetching &&
        !authorizedIsolatesLoading ? (
          <Grid
            item
            bgcolor={theme.palette.neutral[50]}
            width={{ md: hasCancellationComment ? 1550 : 1140, xs: '98%' }}
            marginX={{ xs: 'auto' }}
            marginTop={{ xs: 3 }}
          >
            <StyledFormHeaderContainer>
              <Grid item xs={11}>
                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                  <StyledTypography variant="h4" color="primary.main">
                    {t('orderForm.orderNumber')} : {result?.id}
                  </StyledTypography>
                </Box>
              </Grid>
            </StyledFormHeaderContainer>
            <Grid p={{ md: hasCancellationComment ? 4 : 5, xs: 1 }}>
              {isMobile ? (
                <>
                  <SerialsAccordionMobile data={orderSerials} />
                  <RenderByCondition show={[hasCancellationComment]}>
                    <StyledGrid>
                      <Typography
                        fontFamily={theme.typography.fontFamily}
                        fontSize={15}
                        fontWeight={600}
                        mb={1}
                      >
                        {' '}
                        {t('orderForm.rejectionComment')}
                      </Typography>
                      {result?.cancelReason}
                    </StyledGrid>
                  </RenderByCondition>
                </>
              ) : (
                <>
                  <StyledTitleStatusOrder mb={5}>
                    {t('orderForm.orderStatus')}{' '}
                  </StyledTitleStatusOrder>
                  {orderSerials?.length > 0 && (
                    <Grid
                      md={12}
                      container
                      flexDirection={{ md: 'row' }}
                      rowSpacing={3}
                    >
                      <Grid md={hasCancellationComment ? 9 : 12}>
                        <SerialTimeline
                          orderSerials={orderSerials}
                          hideSelect={SERIALS_SELECT_VISIBILITY}
                        />
                      </Grid>
                      <RenderByCondition show={[hasCancellationComment]}>
                        <StyledGrid>
                          <Typography
                            color="neutral.700"
                            variant="body2"
                            fontWeight={600}
                            mb={1}
                          >
                            {' '}
                            {t('orderForm.rejectionComment')}
                          </Typography>
                          {result?.cancelReason}
                        </StyledGrid>
                      </RenderByCondition>
                    </Grid>
                  )}
                </>
              )}
              <Grid mt={4}>
                <Divider />
              </Grid>
            </Grid>
            <Grid
              container
              flexDirection={{ md: 'row', xs: 'column' }}
              pl={{ md: 9, xs: 0 }}
              pr={{ md: 9, xs: 0 }}
            >
              <Grid item mt={4} md={2} mr={7}>
                <Sidebar
                  handleButtonClick={handleSectionClick}
                  activeSection={activeSection}
                  sidebarItems={sidebarItems}
                />
              </Grid>
              <Grid item md={8} padding={{ md: '5px', xs: '12px' }}>
                <OrderDetailsContent
                  data={result}
                  activeSection={activeSection}
                />
              </Grid>
              <StyledGridScroll item md={2} ml={{ md: 98, xs: 0 }}>
                <StyledCircleupIcon
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                />
              </StyledGridScroll>
            </Grid>
          </Grid>
        ) : (
          <Stack spacing={2} width="70%">
            <Skeleton
              animation="wave"
              variant="text"
              sx={{ fontSize: '1rem' }}
            />
            <Skeleton
              animation="wave"
              variant="rounded"
              width="20%"
              height={30}
            />
            <Skeleton animation="wave" variant="rounded" height={100} />
            <Skeleton animation="wave" variant="rounded" height={65} />
            <Skeleton animation="wave" variant="rounded" height={30} />
            <Skeleton animation="wave" variant="rounded" height={20} />
          </Stack>
        )}
      </Grid>
    </>
  );
};

export default withLayout(AdminOrderDetails);
