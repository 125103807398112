import { Box, styled } from '@mui/material';

export const StyledAddressesGridBox = styled(Box)(({ theme }) => ({
  padding: '2.25rem 2.625rem 7.25rem 2.625rem',
  [theme.breakpoints.down('md')]: {
    overflowX: 'auto',
    maxWidth: '100%',
    padding: '1rem 1rem 1rem 1rem',
  },
}));

export const StyledAddressesHeaderBox = styled(Box)`
  padding: 2.25rem 2.625rem 0 2.625rem;

  ${({ theme }) => {
    return {
      [theme.breakpoints.down('md')]: {
        padding: '1rem 1rem 1 1rem',
      },
    };
  }};
`;
