import ErrorUI from 'components/organisms/ErrorUI';
import withLayout from 'layout';
import { useTranslation } from 'react-i18next';

const SystemDown = () => {
  const { t } = useTranslation();

  return (
    <ErrorUI
      title={t('errorPage.systemDownTitle')}
      description={t('errorPage.systemDownDescription')}
      titleFontSizeMd={'75px'}
    />
  );
};

export default withLayout(SystemDown);
