import React from 'react';
import { Grid, Typography } from '@mui/material';
import {
  StyledBox,
  StyledButton,
  StyledDescriptionsComment,
  StyledTitleComment,
} from './styles';

import { useTranslation } from 'react-i18next';
import { StatusPacketProps } from './types';
import TextBadge from 'components/atoms/TextBadge';
import { isNotEmptyOrWhitespace } from 'utils';
import RenderByCondition from 'components/atoms/RenderByCondition';
import { EditWithoutTitleIcon } from 'components/icons';

const StatusPacket: React.FC<StatusPacketProps> = (props) => {
  const { date, status, comment, color, editStatus } = props;
  const { t } = useTranslation();
  return (
    <Grid container direction={'row'} m={2} ml={0}>
      <Grid container flexDirection={'row'}>
        <Grid item xs={9} container flexDirection={'row'}>
          <Grid mr={2}>
            <TextBadge color={'#4F575E'} name={date} />
          </Grid>
          <Grid>
            <TextBadge color={color} name={status} />
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <StyledButton
            variant="outlined"
            endIcon={<EditWithoutTitleIcon />}
            onClick={editStatus}
          >
            <Typography variant="body2">{t('docs.editStatus')}</Typography>
          </StyledButton>
        </Grid>
      </Grid>
      <Grid xs={12}>
        <RenderByCondition show={[isNotEmptyOrWhitespace(comment)]}>
          <StyledBox>
            <StyledTitleComment>{t('docs.stateComment')}</StyledTitleComment>
            <StyledDescriptionsComment>{comment}</StyledDescriptionsComment>
          </StyledBox>
        </RenderByCondition>
      </Grid>
    </Grid>
  );
};

export default StatusPacket;
