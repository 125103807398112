import { Grid, Menu, Typography, styled } from '@mui/material';
import theme from 'theme';

export const StyledConfigGrid = styled(Grid)`
  ${({ theme }) => ({
    borderRadius: '8px',
    border: `1px solid var(--neutral-neutral-4,${theme.palette.neutral['400']})`,
    display: 'flex',
    paddingLeft: '1rem',
    alignItems: 'center',
    margin: '1rem 0rem 1rem 0rem',
    minHeight: '4.5rem',
  })}
`;

export const StyledTemplateTitle = styled(Typography)`
  color: ${theme.palette.neutral[700]};
  font-family: ${theme.typography.fontFamily};
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
`;

export const StyledForm = styled('form')`
  padding-block: 32px;
  padding-inline: 16px;
  min-width: 500px;
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      minWidth: 250,
      padding: '0',
    },
  })}
`;

export const StyledMenu = styled(Menu)`
  '& .MuiPaper-root': {
    borderRadius: '10px',
    width: '200px',
  }
  box-shadow: 0px 7px 14px 0px #4145581A; 
`;
