import withLayout from 'layout';
import ShareRequestOrganism from 'components/organisms/ShareRequest';
import { useLazyGetIsolateForShareDeclineQuery } from 'api/shareIsolate';
import { useEffect } from 'react';
import { PAGES } from 'constants/index';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserRole } from 'store/features/auth/index.selector';

const ShareRequestDecline = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const userRole = useSelector(getUserRole);
  const [fetchQuery, { data, isLoading }] =
    useLazyGetIsolateForShareDeclineQuery();

  useEffect(() => {
    if (isNaN(Number(id))) navigate(PAGES[userRole].NOT_FOUND);
    else {
      fetchQuery({ id: Number(id) });
    }
  }, [id]);

  return (
    <ShareRequestOrganism
      data={data?.data}
      isLoading={isLoading}
      isolateId={data?.data?.isolateForShare?.id}
    />
  );
};

export default withLayout(ShareRequestDecline);
