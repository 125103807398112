import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DownloadIcon } from 'components/icons';
import CustomButton from 'components/atoms/Button';
import PDFViewer from '../ReviewPacket/PdfReview';
import { PdfPreviewHeaderProps, PdfPreviewProps } from './types';

const PdfPreviewHeader = ({
  buttonActions,
  headerTitle,
}: PdfPreviewHeaderProps) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} container mb={2} flexDirection={'row'}>
      <Grid item xs={12} textAlign={'start'} md={6}>
        <Typography variant="h5" color="primary">
          {headerTitle || t('docs.packetPreview')}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        textAlign={{ xs: 'start', md: 'end' }}
        mt={{ xs: 2, md: 0 }}
        flexDirection={'row'}
      >
        {buttonActions?.map((button) => {
          return (
            <CustomButton
              key={button?.id}
              onClick={button.onClick}
              sx={{
                marginInline: 1,
                ...button?.sx,
              }}
              endIcon={button?.endIcon || <DownloadIcon />}
              variant={button?.variant}
              loading={button?.isLoading}
            >
              <Typography
                color={
                  button?.variant === 'contained' ? 'neutral.50' : 'neutral.700'
                }
                variant="body1"
              >
                {button?.title || t('global.download')}
              </Typography>
            </CustomButton>
          );
        })}
      </Grid>
    </Grid>
  );
};

const PdfPreview = ({
  buttonActions,
  fileDataUri,
  pdfIsLoading,
  headerTitle,
}: PdfPreviewProps) => {
  return (
    <Grid
      container
      padding={2}
      sx={{
        minHeight: { xs: 500, md: 700 },
        maxHeight: { xs: 500, md: 700 },
      }}
      bgcolor="neutral.200"
    >
      <PdfPreviewHeader
        buttonActions={buttonActions}
        headerTitle={headerTitle}
      />
      <PDFViewer fileUrl={fileDataUri} loading={pdfIsLoading} />
    </Grid>
  );
};

export default PdfPreview;
