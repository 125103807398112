import { Grid } from '@mui/material';
import React from 'react';
import { StyledButton, StyledHeader, StyledTypography } from './styles';
import { CustomHeaderProps } from './types';

const HeaderWithButton: React.FC<CustomHeaderProps> = (props) => {
  const { title, buttonProps, titleStyle } = props;
  return (
    <StyledHeader container>
      <Grid item>
        <StyledTypography style={titleStyle} variant="h6">
          {title}
        </StyledTypography>
      </Grid>
      {buttonProps.isHide && (
        <Grid item>
          <StyledButton
            onClick={buttonProps.action}
            disabled={buttonProps.disabled}
          >
            {buttonProps.title}
          </StyledButton>
        </Grid>
      )}
    </StyledHeader>
  );
};
export default HeaderWithButton;
