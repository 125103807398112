import { Box, TableCell, Typography, styled } from '@mui/material';

export const StyledTableHeadCell = styled(TableCell)`
  padding-left: 0px;
`;

export const StyledTableBodyCell = styled(TableCell)`
  padding-left: 0px;
  width: 125px;
  height: 10px !important;
  max-height: 10px !important;
`;

export const StyledTableHeadCellText = styled(Typography)`
  ${({ theme }) => {
    return {
      fontWeight: 600,
      color: theme.palette.neutral[700],
      inlineSize: '150px',
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      cursor: 'pointer',
      width: '100%',
    };
  }}
`;

export const StyledTableHeadCellTextWithoutEllipsis = styled(Typography)`
  ${({ theme }) => {
    return {
      fontWeight: 600,
      color: theme.palette.neutral[700],
    };
  }}
`;

export const StyledTableBodyCellText = styled(Typography)`
  ${({ theme }) => {
    return {
      fontWeight: 400,
      color: theme.palette.neutral.main,
      inlineSize: '150px',
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
    };
  }}
`;

export const StyledChosenIsolatesTitle = styled(Box)`
  ${() => ({
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '22px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#4F575E',
    width: '100%',
  })}
`;
