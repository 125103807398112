import { SVGProps } from 'react';

const ActiveCheckBox = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <rect
      width={24}
      height={24}
      x={1}
      y={0.5}
      fill="#29306B"
      rx={3.5}
      style={{ margin: 0 }}
    />
    <path
      fill="#FFF"
      d="M7.98 12.3l-3.75-3.75a.751.751 0 0 1 0-1.06.751.751 0 0 1 1.06 0l2.97 2.97 8.53-8.53a.751.751 0 0 1 1.06 0 .751.751 0 0 1 0 1.06L9.04 12.3a.751.751 0 0 1-1.06 0Z"
      transform="translate(1.5, 4.5)"
    />
  </svg>
);

export default ActiveCheckBox;
