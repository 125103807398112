import * as React from 'react';
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import CustomButton from 'components/atoms/Button';
import CustomDatePicker from 'components/molecules/CustomDatePicker';
import {
  TitleProps,
  DatePickerComponentProps,
  ButtonGroupProps,
  ReorderReminderPopUpProps,
} from './types';
import CustomDialog from 'components/molecules/Dialog';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';

const Title: React.FC<TitleProps> = ({ id, reminderMode }) => {
  const { t } = useTranslation();

  const getTextContent = () => {
    if (reminderMode === 'create') {
      return t('dashboard.reorderReminderModalCreateContent', {
        deliveryText: `<u>${t('dashboard.deliveryText')}</u>`,
      });
    } else if (reminderMode === 'edit') {
      return t('dashboard.reorderReminderModalEditContent', {
        orderId: id,
      });
    }
    return '';
  };

  return (
    <Grid item xs={12} data-testid="title">
      <Typography
        variant="body1"
        fontWeight={500}
        dangerouslySetInnerHTML={{ __html: getTextContent() }}
      ></Typography>
    </Grid>
  );
};

const DatePickerComponent: React.FC<DatePickerComponentProps> = ({
  date,
  setDate,
  reminderMode,
  minDate,
}) => {
  const { t } = useTranslation();

  const getTextContent = () => {
    if (reminderMode === 'create') {
      return t('dashboard.reorderReminderModalCreateContentDateLabel');
    } else if (reminderMode === 'edit') {
      return t('dashboard.reorderReminderModalEditContentDateLabel');
    }
    return '';
  };

  return (
    <Grid item xs={12} data-testid="date-picker">
      <Typography variant="body1" mb={1}>
        {getTextContent()}
      </Typography>
      <CustomDatePicker
        value={date}
        onChange={(newValue, context) => setDate(newValue, context)}
        sx={{ width: '100%' }}
        label=""
        minDate={minDate}
      />
    </Grid>
  );
};

const ButtonGroup: React.FC<ButtonGroupProps> = ({
  resetDate,
  saveDate,
  reminderMode,
  saveIsLoading,
  removeIsLoading,
  btnIsDisabled,
}) => {
  const { t } = useTranslation();

  const getTextContent = () => {
    if (reminderMode === 'create') {
      return t('global.cancelTitle');
    } else if (reminderMode === 'edit') {
      return t('global.delete');
    }
    return '';
  };

  return (
    <Grid item xs={12} data-testid="button-group">
      <Grid
        container
        xs={12}
        alignItems={'end'}
        justifyContent={'flex-end'}
        columnGap={2}
      >
        <Grid item>
          <CustomButton
            variant="outlined"
            sx={{ backgroundColor: 'white' }}
            onClick={() => {
              if (reminderMode === 'edit') resetDate('delete');
              else resetDate('cancel');
            }}
            data-testid="reset-button"
            loading={removeIsLoading}
          >
            {getTextContent()}
          </CustomButton>
        </Grid>
        <Grid item>
          <CustomButton
            variant="contained"
            onClick={() => {
              saveDate();
            }}
            data-testid="save-button"
            loading={saveIsLoading}
            disabled={btnIsDisabled}
          >
            {t('global.save')}
          </CustomButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ReorderReminderPopUp: React.FC<ReorderReminderPopUpProps> = ({
  setDate,
  date,
  resetDate,
  saveDate,
  isOpen,
  setOpen,
  orderId = 0,
  saveIsLoading,
  removeIsLoading,
  reminderMode,
  minDate,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const [btnIsDisabled, setBtnIsDisabled] = React.useState(true);

  const modalTitle = useMemo(() => {
    if (reminderMode === 'create')
      return t('dashboard.reorderReminderModalTitle');
    else if (reminderMode === 'edit')
      return t('dashboard.reorderReminderModalEditTitle');
    else return '';
  }, [reminderMode]);

  useEffect(() => {
    // Reset the btnIsDisabled state when the popup is closed
    if (!isOpen || date === null) {
      setBtnIsDisabled(true);
    }
  }, [isOpen, date]);

  return (
    <CustomDialog
      open={isOpen}
      handleClose={() => setOpen(false)}
      modalTitle={modalTitle}
      maxWidth={'lg'}
      sx={{ height: 500 }}
      fullScreen={isMobile}
    >
      <Grid
        container
        flexDirection={'column'}
        sx={{ width: { md: 800 } }}
        spacing={2}
        padding={{ md: 4, xs: 1 }}
        rowGap={4}
        data-testid="dialog-content"
      >
        <Title id={orderId} reminderMode={reminderMode} />
        <DatePickerComponent
          setDate={(date, context) => {
            setDate(date, context);
            if (context) setBtnIsDisabled(context.validationError);
          }}
          date={date}
          reminderMode={reminderMode}
          minDate={minDate}
        />
        <ButtonGroup
          resetDate={resetDate}
          saveDate={saveDate}
          saveIsLoading={saveIsLoading}
          removeIsLoading={removeIsLoading}
          reminderMode={reminderMode}
          btnIsDisabled={btnIsDisabled}
        />
      </Grid>
    </CustomDialog>
  );
};

export default ReorderReminderPopUp;
