import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from 'translations/en.json';
import fr from 'translations/fr.json';

import { LANGUAGE_CODES, I18NEXT_LANGUAGE_KEY } from 'constants/index';

export const getLanguage = () => {
  const envirenementLanguage =
    process.env.REACT_APP_DEFAULT_LANGUAGE?.toLocaleLowerCase();
  const localStorageLanguage = localStorage.getItem(I18NEXT_LANGUAGE_KEY);
  if (
    localStorageLanguage &&
    Object.values(LANGUAGE_CODES).includes(localStorageLanguage)
  ) {
    return localStorageLanguage;
  } else if (
    envirenementLanguage &&
    Object.values(LANGUAGE_CODES).includes(envirenementLanguage)
  ) {
    localStorage.setItem(I18NEXT_LANGUAGE_KEY, envirenementLanguage);
    return envirenementLanguage;
  }
  return LANGUAGE_CODES.en;
};

export const initializeI18n = () =>
  i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: en,
      },
      fr: {
        translation: fr,
      },
    },
    lng: getLanguage(),
    fallbackLng: false,
    interpolation: {
      escapeValue: false,
    },
    returnEmptyString: false,
  });

export default i18n;
