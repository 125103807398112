import { Box, Grid, Typography } from '@mui/material';
import { StyledDataGrid } from './styles';
import { DataGridProps } from '@mui/x-data-grid';
import React from 'react';

const CustomFooter = ({ PaginationComponent, pageSize }) => {
  return (
    <Grid
      p={2}
      container
      justifyContent="end"
      alignItems={'center'}
      spacing={2}
      py={2}
    >
      <Grid item>
        <Typography color="neutral.10" fontWeight={500}>
          Page size: {pageSize}
        </Typography>
      </Grid>
      <Grid item>{PaginationComponent}</Grid>
    </Grid>
  );
};

interface CustomDataGridProps extends DataGridProps {
  totalPages: number;
  pageNumber: number;
  pageSize: number;
  noRowLabel: string;
  PaginationComponent: React.ReactNode;
}

const CustomDataGrid = ({
  rows,
  columns,
  onRowSelectionModelChange,
  pageSize = 0,
  onSortModelChange,
  loading,
  totalPages = 0,
  pageNumber,
  paginationMode = 'server',
  sortingMode = 'server',
  filterMode = 'server',
  PaginationComponent,
  noRowLabel = '',
  rowHeight = 40,
  disableColumnMenu = false,
  onRowClick,
}: CustomDataGridProps) => {
  return (
    <Box sx={{ width: '100%' }}>
      <StyledDataGrid
        data-testid="custom-data-grid"
        rows={rows}
        columns={columns}
        checkboxSelection
        disableRowSelectionOnClick
        onRowSelectionModelChange={onRowSelectionModelChange}
        onSortModelChange={onSortModelChange}
        filterMode={filterMode}
        sortingMode={sortingMode}
        loading={loading}
        paginationMode={paginationMode as any}
        rowCount={totalPages}
        pageSizeOptions={[pageNumber]}
        slots={{
          footer: () => {
            return (
              <CustomFooter
                pageSize={pageSize}
                PaginationComponent={PaginationComponent}
              />
            );
          },
        }}
        localeText={{
          noRowsLabel: noRowLabel,
        }}
        rowHeight={rowHeight}
        disableColumnMenu={disableColumnMenu}
        onRowClick={onRowClick}
      />
    </Box>
  );
};

export default CustomDataGrid;
