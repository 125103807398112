import { Paper, Typography, styled } from '@mui/material';
import { Stack } from '@mui/system';
import theme from 'theme';

export const StyledPaper = styled(Paper)`
  ${({ theme }) => {
    return {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '700px',
      padding: '0px 0px 26px 0px',
      whiteSpace: 'pre-wrap',
      borderRadius: '8px 8px 0px 0px',
      margin: '15px',
      boxShadow: theme.shadows[5],
      [theme.breakpoints.down('md')]: {
        width: '90%',
        padding: 25,
      },
    };
  }}
`;
export const StyledHeader = styled(Stack)`
  display: flex;
  padding: 0px;
  margin: 24px;
  height: 44px;
  justify-content: space-between;
  align-items: center;
  background: ${theme.palette.info.light};
`;
export const StyledTitle = styled(Typography)`
  padding: 15px;
`;
export const StyledDescription = styled(Typography)`
  padding: 24px;
`;
