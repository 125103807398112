import { FormControl, Grid, styled } from '@mui/material';
import CustomSelect from 'components/atoms/Select';

export const StyledForm = styled('form')`
  width: 100%;
  height: 100%;
`;

export const StyledGridContainer = styled(Grid)`
  margin: 0;
  width: 100%;
  height: 100%;
  padding-left: 15px;
  padding-right: 15px;
  align-content: baseline;
`;
export const StyledBottleFormTotalRow = styled(Grid)(() => ({
  borderWidth: '1px',
  borderTopColor: '#CED4DA',
  borderTopStyle: 'solid',
  borderBottomStyle: 'solid',
  borderBottomColor: '#CED4DA',
}));

export const StyledFormControl = styled(FormControl)`
  ${() => ({
    width: '60%',
    maxWidth: 500,
  })}
`;
export const StyledCustomSelect = styled(CustomSelect)`
  ${(props) =>
    props.deleteIconHidden
      ? `
    .MuiSvgIcon-root {
      display: none;
    }
    font-weight: 500;
    `
      : ''}
`;
