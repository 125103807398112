import * as React from 'react';
import { SVGProps } from 'react';

interface InfoProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const Info: React.FC<InfoProps> = ({ color = '#00AEEF', ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 23"
    width={15}
    height={15}
    fill="none"
    {...props}
  >
    <path
      fill={color}
      d="M12 0C5.344 0 0 5.39 0 12c0 6.656 5.344 12 12 12 6.61 0 12-5.344 12-12 0-6.61-5.39-12-12-12Zm0 21.75c-5.39 0-9.75-4.36-9.75-9.75 0-5.344 4.36-9.75 9.75-9.75 5.344 0 9.75 4.406 9.75 9.75 0 5.39-4.406 9.75-9.75 9.75Zm1.875-6h-.75v-4.125A1.14 1.14 0 0 0 12 10.5h-1.5a1.11 1.11 0 0 0-1.125 1.125c0 .656.469 1.125 1.125 1.125h.375v3h-.75A1.11 1.11 0 0 0 9 16.875C9 17.531 9.469 18 10.125 18h3.75A1.11 1.11 0 0 0 15 16.875a1.14 1.14 0 0 0-1.125-1.125ZM12 9c.797 0 1.5-.656 1.5-1.5 0-.797-.703-1.5-1.5-1.5-.844 0-1.5.703-1.5 1.5 0 .844.656 1.5 1.5 1.5Z"
    />
  </svg>
);
export default Info;
