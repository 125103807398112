import ErrorUI from 'components/organisms/ErrorUI';
import withLayout from 'layout';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <ErrorUI
      dataTestId={'404-notfound-page'}
      title={'404'}
      description={t('errorPage.notFoundDescription')}
    />
  );
};

export default withLayout(NotFound);
