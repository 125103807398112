import { SVGProps } from 'react';

const Notification = (props: SVGProps<SVGSVGElement>) => (
  <svg style={{ width: '32px', height: '32px' }} fill="none" {...props}>
    <path
      fill="#29306B"
      d="M13.438 1c0-.5.5-1 1-1C15 0 15.5.5 15.5 1v1.063c5 .5 9 4.75 9 9.937v1.875a10.27 10.27 0 0 0 3 7.25l.188.188c.5.5.812 1.187.812 1.937 0 1.563-1.25 2.75-2.813 2.75H3.25C1.687 26 .5 24.812.5 23.25c0-.75.25-1.438.75-1.938l.188-.187c1.937-1.938 3.062-4.563 3.062-7.25V12a9.979 9.979 0 0 1 9-9.938L13.437 1Zm1 3c-4.376 0-8 3.625-8 8v1.875c0 3.25-1.25 6.375-3.563 8.688l-.188.125c-.124.187-.25.375-.25.562 0 .438.376.75.813.75h22.438c.437 0 .812-.313.812-.75 0-.188-.125-.375-.25-.563l-.188-.125C23.75 20.25 22.5 17.125 22.5 13.876V12c0-4.375-3.625-8-8.063-8Zm0 26c.874 0 1.624-.5 1.937-1.313.188-.5.75-.812 1.25-.625.5.188.813.75.625 1.313C17.687 30.938 16.187 32 14.437 32a3.989 3.989 0 0 1-3.75-2.625 1.047 1.047 0 0 1 .626-1.313c.5-.187 1.062.125 1.25.625A2.011 2.011 0 0 0 14.437 30Z"
    />
  </svg>
);
export default Notification;
