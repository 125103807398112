import { Box, Grid, List, ListItem, Typography, styled } from '@mui/material';
import { Link } from 'react-router-dom';

// backup
export const AppVersionContainer = styled(Grid)(() => ({
  top: 'auto',
  bottom: 0,
  background: 'transparent',
  right: 0,
}));

export const StyledFooter = styled(Box)(({ theme }) => ({
  flex: '0 0 auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '12px 32px',
  background: 'blue',
  [theme?.breakpoints?.down('md')]: {
    padding: '10px 8px',
  },
  backgroundColor: `${theme.palette.neutral[50]}`,
}));

export const StyledLink = styled(Link)(() => ({
  display: 'inline-flex',
  textDecoration: 'none',
}));

export const StyledTypography = styled(Typography)(() => ({
  '&:hover': {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

export const StyledList = styled(List)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'row',
  alignItems: 'center',
  alignContent: 'center',
  [theme?.breakpoints?.down('xs')]: {
    padding: '10px 8px',
    flexDirection: 'column',
  },
}));

export const StyledListItem = styled(ListItem)(() => ({
  width: '100%',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const ReleaseContainer = styled(Box)(({ theme }) => ({
  boxShadow:
    '0px 3px 6px rgba(0, 0, 0, 0.07), 0px 7px 14px rgba(65, 69, 88, 0.1)',
  backgroundColor: `${theme.palette.background.default}`,
  color: `${theme.palette.neutral.main}`,
  padding: 10,
}));
