import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { GridProps } from '@mui/system';

export const StyledGrid = styled(Grid)<GridProps>`
  padding: 8px 0px 8px 0px;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
  border-left-width: 0px;
`;
