import * as React from 'react';
import { SVGProps } from 'react';

const ReadCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={17}
    fill="none"
    {...props}
  >
    <path
      fill="#00AEEF"
      d="M8 1.832a6.67 6.67 0 0 0-6.667 6.667A6.67 6.67 0 0 0 8 15.165 6.67 6.67 0 0 0 14.667 8.5 6.67 6.67 0 0 0 8 1.832Zm0 12a5.34 5.34 0 0 1-5.333-5.333A5.34 5.34 0 0 1 8 3.165 5.34 5.34 0 0 1 13.333 8.5 5.34 5.34 0 0 1 8 13.832Zm3.06-8.28L6.667 9.945 4.94 8.225l-.94.94 2.667 2.667L12 6.499l-.94-.947Z"
    />
  </svg>
);
export default ReadCheck;
