import { useState, useMemo, useEffect } from 'react';
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { DocumentStatus, FILE_TYPE, PacketStatus } from 'constants/index';
import CustomDialog from 'components/molecules/Dialog';
import CustomButton from 'components/atoms/Button';
import CustomSelect from 'components/atoms/Select';
import { DownloadIcon } from 'components/icons';
import {
  StyledBox,
  StyledButtonGroup,
  StyledComment,
  StyledDescription,
  StyledForm,
  StyledSelect,
  StyledTitle,
} from './style';
import CustomInput from 'components/atoms/Input';
import CustomRadioButton from 'components/atoms/RadioButton';
import useToast from 'hooks/useToast';
import DocList from '../DocList';
import DocItem from 'components/molecules/DocItem';

const FileInput = ({ onFileChange, title }) => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const [fileName, setFileName] = useState('');
  useEffect(() => {
    setFileName('');
  }, [title]);
  const handleFileChange = (uploadedFile, fileName) => {
    if (uploadedFile && uploadedFile.type === FILE_TYPE.PDF) {
      setFileName(fileName);
      onFileChange(uploadedFile);
    } else {
      onFileChange('');
      setFileName('');
      toast.warn(t('errorMessages.pdfFileTypeWarning'), {});
    }
  };

  return (
    <DocList
      data={[
        {
          bgColor: '#E5F7FD',
          title: <StyledTitle>{title}</StyledTitle>,
          description: <StyledDescription>{fileName}</StyledDescription>,
          buttonActions: [
            {
              title: 'upload',
              icon: <DownloadIcon />,
              type: 'input',
              onClick: (event) => {
                const file = event.target.files[0];
                handleFileChange(file, file.name);
              },
            },
          ],
        },
      ]}
      render={(data) => <DocItem sx={{ mb: 1, mt: 2 }} {...data} />}
    />
  );
};

const EditPacketPopUp = ({
  orderId,
  stateId,
  documentId,
  isOpen,
  setIsOpen,
  changeStatusPacketData,
}) => {
  const { t } = useTranslation();
  const [selectedStatus, setSelectedStatus] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const validationSchemas = {
    Approved: Yup.object().shape({
      status: Yup.string().trim().required(t('errorMessages.statusEditDoc')),
      file: Yup.string().trim().required(t('errorMessages.uploadFileEditDoc')),
    }),
    Rejected: Yup.object().shape({
      status: Yup.string().trim().required(t('errorMessages.statusEditDoc')),
      comment: Yup.string().trim().required(t('errorMessages.commentEditDoc')),
    }),
  };

  const statusOptions = [
    { value: 'Approved', label: 'Approved' },
    { value: 'Rejected', label: 'Rejected' },
  ];

  const initialValues = {
    status: '',
    comment: '',
    checkedOption: '',
    file: '',
  };

  const selectedValidationSchema = useMemo(() => {
    return validationSchemas[selectedStatus] || validationSchemas['Approved'];
  }, [selectedStatus]);

  const formik = useFormik({
    initialValues,
    validationSchema: selectedValidationSchema,
    onSubmit: handleSubmit,
    validateOnMount: true,
    enableReinitialize: true,
  });

  async function handleSubmit() {
    if (formik.isValid) {
      const formData = new FormData();
      const status =
        formik.values.status === 'Approved'
          ? DocumentStatus.Approved
          : DocumentStatus.Denied;
      const isApproveLetter =
        formik.values.checkedOption === PacketStatus.ApproveLetter
          ? 'true'
          : 'false';

      formData.append('orderId', orderId);
      formData.append('stateId', stateId);
      formData.append('status', status.toString());
      formData.append('comment', formik.values?.comment);
      formData.append('isLetterApproved', isApproveLetter);
      formData.append('file', formik.values.file);
      formData.append('documentId', documentId);

      changeStatusPacketData(formData);
      resetStatusPacketForm();
      setIsOpen(false);
    } else {
      formik.validateForm();
    }
  }

  const resetStatusPacketForm = () => {
    formik.resetForm(initialValues);
    setSelectedStatus('');
  };
  const handleClose = () => {
    resetStatusPacketForm();
    setIsOpen(false);
  };

  const handleFileChange = (uploadedFile) => {
    formik.setFieldValue('file', uploadedFile);
  };

  const title = useMemo(() => {
    formik.setFieldValue('file', '');
    return formik.values.checkedOption === PacketStatus.ApproveLetter
      ? t('docs.approvalLetter')
      : t('docs.signedPacket');
  }, [formik.values.checkedOption]);

  const activeUploadFile = useMemo(() => {
    return formik.values.status === 'Approved';
  }, [formik.values]);

  const handleStatusChange = (item) => {
    formik.setFieldValue('status', item.value);
    setSelectedStatus(item.value);
    formik.setFieldValue('file', '');
    formik.setFieldValue('comment', '');
    formik.setFieldValue('checkedOption', '');
  };

  return (
    <CustomDialog
      open={isOpen}
      handleClose={handleClose}
      modalTitle={t('docs.editStatusTitle')}
      sx={{ minWidth: 600 }}
      fullScreen={isMobile}
    >
      <StyledForm onSubmit={formik.handleSubmit}>
        <StyledBox container direction="column">
          <StyledSelect item xs={12}>
            <CustomSelect
              label={t('docs.status')}
              options={statusOptions}
              name="status"
              value={{
                value: selectedStatus,
                label: selectedStatus,
              }}
              onChange={(e, item: any) => {
                handleStatusChange(item);
              }}
              onBlur={formik.handleBlur}
              error={Boolean(formik.touched.status && formik.errors.status)}
              errorMessage={formik.errors.status}
            />
          </StyledSelect>
          <StyledComment marginTop={2}>
            <CustomInput
              multiline
              minRows={5}
              sx={{ width: '100%' }}
              name="comment"
              label={t('docs.comment')}
              placeholder={t('docs.comment')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.comment}
              error={Boolean(formik.touched.comment && formik.errors.comment)}
              errorMessage={formik.errors.comment}
            />
          </StyledComment>
          <Grid
            container
            flexDirection="row"
            alignItems="center"
            marginTop="1rem"
          >
            {activeUploadFile && (
              <StyledBox container direction="row">
                <Grid item>
                  <CustomRadioButton
                    label={t('docs.approvalLetter')}
                    value={PacketStatus.ApproveLetter}
                    name="checkedOption"
                    checked={
                      formik.values.checkedOption === PacketStatus.ApproveLetter
                    }
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item>
                  <CustomRadioButton
                    label={t('docs.signedPacket')}
                    value={PacketStatus.SignedPacket}
                    name="checkedOption"
                    checked={
                      formik.values.checkedOption === PacketStatus.SignedPacket
                    }
                    onChange={formik.handleChange}
                  />
                </Grid>
              </StyledBox>
            )}
          </Grid>
          {activeUploadFile && formik.values.checkedOption && (
            <Grid item>
              <FileInput onFileChange={handleFileChange} title={title} />
            </Grid>
          )}
          {Boolean(formik.touched.file && formik.errors.file) && (
            <Grid item xs={12}>
              <Typography variant="body2" color="error">
                {formik.errors.file}
              </Typography>
            </Grid>
          )}
          <StyledButtonGroup
            container
            direction="row"
            justifyContent="flex-end"
            spacing={1}
          >
            <Grid item>
              <CustomButton
                size="medium"
                variant="outlined"
                onClick={() => {
                  resetStatusPacketForm();
                  handleClose();
                }}
              >
                {t('orderForm.cancel')}
              </CustomButton>
            </Grid>
            <Grid item>
              <CustomButton
                disabled={
                  formik.isSubmitting || !formik.isValid || !formik.dirty
                }
                size="medium"
                type="submit"
                onClick={handleSubmit}
              >
                {t('orderForm.save')}
              </CustomButton>
            </Grid>
          </StyledButtonGroup>
        </StyledBox>
      </StyledForm>
    </CustomDialog>
  );
};

export default EditPacketPopUp;
