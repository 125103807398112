import {
  Configuration,
  PublicClientApplication,
  RedirectRequest,
} from '@azure/msal-browser';

const clientId = process.env.REACT_APP_API_MSAL_CLIENT_ID;
const knownAuthorities = process.env.REACT_APP_KNOWN_AUTHORITIES;
const redirectUri = process.env.REACT_APP_REDIRECT_URI;
const loginAuthority = process.env.REACT_APP_LOGIN_AUTHORITY;
const resetPasswordAuthority = process.env.REACT_APP_RESET_PASSWORD_AUTHORITY;

export const msalConfig: Configuration = {
  auth: {
    clientId,
    authority: loginAuthority,
    knownAuthorities: [knownAuthorities],
    redirectUri: redirectUri,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

export const loginRequest: RedirectRequest = {
  scopes: ['openid', 'profile'],
};

export const passwordResetRequest: RedirectRequest = {
  scopes: [],
  authority: resetPasswordAuthority,
};

class MsalSingleton {
  static instance;

  static getInstance() {
    if (!MsalSingleton.instance) {
      MsalSingleton.instance = new PublicClientApplication(msalConfig);
    }
    return MsalSingleton.instance;
  }

  static getLoginRequest() {
    return loginRequest;
  }

  static getPasswordResetRequest() {
    return passwordResetRequest;
  }
}

export default MsalSingleton;
