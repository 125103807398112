import {
  Divider,
  Grid,
  Paper,
  Tooltip,
  Typography,
  useTheme,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import PageHeader from 'components/molecules/PageHeader';

import { StyledCard, StyledEllipsisContainer, StyledTableCell } from './styles';
import { useSelector } from 'react-redux';

import { truncateText, truncateString } from 'utils';
import RenderByCondition from 'components/atoms/RenderByCondition';
import { useLocation, useNavigate } from 'react-router-dom';
import BackdropLoader from 'components/atoms/BackdropLoader';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useMemo } from 'react';
import useToast from 'hooks/useToast';
import { PAGES, USER_ROLE } from 'constants/index';
import { getUserRole } from 'store/features/auth/index.selector';
import CustomButton from 'components/atoms/Button';
import CustomSelect from 'components/atoms/Select';
import { DownloadIcon } from 'components/icons';
import DocItem from 'components/molecules/DocItem';
import { useSendIsolateForShareMutation } from 'api/shareIsolate';
import RenderByRole from 'components/atoms/RenderByRole';
import { ExtensionErrorType } from 'api/isolate';

const RowTable = ({ row }) => {
  return (
    <TableRow>
      <StyledTableCell>
        <StyledEllipsisContainer>
          <Tooltip title={row.id} placement="top-start">
            <Typography>{row.id}</Typography>
          </Tooltip>
        </StyledEllipsisContainer>
      </StyledTableCell>

      <StyledTableCell>
        <StyledEllipsisContainer>
          <Tooltip title={row.name} placement="top-start" followCursor>
            <Typography>{truncateString(row.name, 20)}</Typography>
          </Tooltip>
        </StyledEllipsisContainer>
      </StyledTableCell>

      <StyledTableCell sx={{ maxWidth: 150 }}>
        <Typography color="neutral.900">
          {row.serialNumbers?.map((serial) => (
            <Tooltip
              title={serial}
              placement="top-start"
              key={serial}
              followCursor
            >
              <StyledEllipsisContainer>
                {truncateText(serial, 5)}
              </StyledEllipsisContainer>
            </Tooltip>
          ))}
        </Typography>
      </StyledTableCell>
    </TableRow>
  );
};

const Table = ({ data }: any) => {
  const { t } = useTranslation();

  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: 'none',
        overflow: 'auto',
        minHeight: { xs: 400, md: 700 },
        maxHeight: { xs: 400, md: 700 },
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: '20%' }}>
            <Typography color="neutral.700" fontWeight={600} variant="body2">
              {t('isolates.isolateId')}
            </Typography>
          </TableCell>
          <TableCell sx={{ width: '40%' }}>
            <Typography color="neutral.700" fontWeight={600} variant="body2">
              {t('isolates.isolateName')}
            </Typography>
          </TableCell>
          <TableCell sx={{ width: '40%' }}>
            <Typography color="neutral.700" fontWeight={600} variant="body2">
              {t('isolates.serialNumber')}
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <RowTable row={data} />
      </TableBody>
    </TableContainer>
  );
};

const Content = ({ children }) => (
  <Grid item container p={{ xs: 4, md: 8 }} spacing={{ xs: 2, md: 5 }}>
    {children}
  </Grid>
);

const CustomerSelect = ({ onChange, value, options }) => {
  return (
    <CustomSelect
      label="Customer"
      placeholder={'Select'}
      options={options}
      onChange={onChange}
      name={'customer'}
      value={value}
    />
  );
};

const VetSelect = ({ onChange, value, options }) => {
  const userRole = useSelector(getUserRole);

  return (
    <RenderByRole allowedRoles={[USER_ROLE.TM]} userRole={userRole}>
      <CustomSelect
        label="Veterinarian"
        placeholder={'Select'}
        options={options}
        onChange={onChange}
        name={'vet'}
        value={value}
      />
    </RenderByRole>
  );
};

const ButtonGroup = ({ sendOnClick, onCancel, isLoading, sendIsDisable }) => {
  return (
    <Grid item xs={12} mb={2} height={50} mt={2}>
      <Grid container justifyContent={'flex-end'} alignItems={'flex-end'}>
        <Grid item mr={2}>
          <CustomButton
            variant="outlined"
            color="primary"
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </CustomButton>
        </Grid>
        <Grid item>
          <CustomButton
            variant="contained"
            color="primary"
            onClick={() => {
              sendOnClick();
            }}
            loading={isLoading}
            disabled={sendIsDisable}
          >
            Send
          </CustomButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

const SelectGroup = ({ onChange, state, vetOptions, customerOptions }) => {
  return (
    <>
      <Grid item>
        <CustomerSelect
          value={state.customer}
          onChange={onChange}
          options={customerOptions}
        />
      </Grid>
      <Grid item width={'100%'}>
        <VetSelect value={state.vet} onChange={onChange} options={vetOptions} />
      </Grid>
    </>
  );
};

const Container = ({ children }) => {
  const theme = useTheme();

  return (
    <Grid
      item
      xs={12}
      sx={{
        border: `1px solid ${theme.palette.neutral[400]}`,
        borderRadius: 1,
        padding: 2,
      }}
      height={600}
      container
      flexDirection={'column'}
      rowSpacing={2}
    >
      {children}
    </Grid>
  );
};

const UploadComponent = ({ docItemData }) => {
  return (
    <Grid item>
      <DocItem {...docItemData} />
    </Grid>
  );
};

const PermissionLetter = ({ data, isolateId }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [state, setState] = useState({ customer: null, vet: null });
  const [fileData, setFileData] = useState({ file: null, name: '' });
  const location = useLocation();
  const userRole = useSelector(getUserRole);
  const [
    sendShareRequestMutation,
    {
      isLoading: shareRequestIsLoading,
      isSuccess: shareRequestIsSuccess,
      data: shareData,
    },
  ] = useSendIsolateForShareMutation();
  const { toast } = useToast();

  const onChange = (e, value) => {
    setState({ ...state, [e.target.name]: value });
  };

  const handlePdfUpload = (event) => {
    const file = event.target.files[0];
    const name = event.target.files[0].name;

    setFileData({ file, name });
  };

  const customerOptions = useMemo(() => {
    return data?.customerOptions?.map((customer) => ({
      ...customer,
      label: customer?.name,
      value: customer?.id,
    }));
  }, [data?.customerOptions]);

  const vetOptions = useMemo(() => {
    return data?.vetOptions?.map((vet) => ({
      ...vet,
      label: vet?.userName,
      value: vet?.id,
    }));
  }, [data?.vetOptions]);

  useEffect(() => {
    if (shareRequestIsSuccess && !shareRequestIsLoading) {
      if (shareData?.errorCode === ExtensionErrorType.AlreadyRequested) {
        toast.warn(shareData?.message, {
          duration: 3000,
        });
      } else {
        toast.success(shareData?.message, {
          duration: 3000,
        });
        const doesAnyHistoryEntryExist = location.key !== 'default';

        if (doesAnyHistoryEntryExist) navigate(-1);
        else navigate(PAGES[userRole].ROOT);
      }
    }
  }, [shareRequestIsSuccess, shareData]);

  const sendShareRequest = async () => {
    const formData: any = new FormData();

    formData.append('isolateId', isolateId?.toString());
    formData.append('customerId', state.customer?.id?.toString());
    formData.append('vetId', state.vet?.id?.toString() || '');
    formData.append('file', fileData?.file);

    sendShareRequestMutation(formData);
  };

  const docItemData = useMemo(
    () => ({
      title: (
        <Typography color="primary" fontWeight={600}>
          Permission Request letter
        </Typography>
      ),
      description: fileData?.name || '',
      bgColor: theme.palette.info.light,
      buttonActions: [
        {
          title: fileData?.file ? 'update' : 'upload',
          icon: <DownloadIcon />,
          type: 'input',
          onClick: (event) => {
            handlePdfUpload(event);
          },
        },
      ],
    }),
    [fileData]
  );

  const sendIsDisabled = () => {
    if (userRole === USER_ROLE.VET) return !(state.customer && fileData?.file);
    else return !(state.customer && state.vet && fileData?.file);
  };

  return (
    <Grid
      component={Paper}
      sx={{
        boxShadow: 'none',
        padding: {
          md: 2,
          xs: 0,
        },
        minHeight: 700,
        maxHeight: 700,
      }}
      item
      container
      flexDirection={'row'}
    >
      <RenderByCondition show={[Boolean(data?.isolateForShare)]}>
        <>
          <Grid item xs={12} mb={4} height={20}>
            <Typography color={'primary'} fontWeight={600} variant="h5">
              Permission letter
            </Typography>
          </Grid>
          <Container>
            <SelectGroup
              onChange={onChange}
              state={state}
              vetOptions={vetOptions}
              customerOptions={customerOptions}
            />
            <UploadComponent docItemData={docItemData} />
          </Container>
          <ButtonGroup
            sendOnClick={sendShareRequest}
            onCancel={() => {
              navigate(-1);
            }}
            isLoading={shareRequestIsLoading}
            sendIsDisable={sendIsDisabled()}
          />
        </>
      </RenderByCondition>
      <BackdropLoader open={shareRequestIsLoading} />
    </Grid>
  );
};

const ShareIsolateList = ({ data }) => {
  const { t } = useTranslation();

  const rejectComment = data?.comment;

  return (
    <Grid flexDirection={'row'} height={'100%'} container>
      <RenderByCondition show={[Boolean(rejectComment)]}>
        <Grid item xs={12}>
          <Typography mb={1} color="primary" variant="body1">
            {t('global.comment')}
          </Typography>
          <Divider />
          <Typography my={2} color="error">
            {rejectComment}
          </Typography>
        </Grid>
      </RenderByCondition>
      <Grid
        mt={3}
        height={'100%'}
        sx={{
          minHeight: { xs: 400, md: 700 },
          maxHeight: { xs: 400, md: 700 },
        }}
        item
        xs={12}
      >
        <RenderByCondition show={[Boolean(data?.isolateForShare)]}>
          <Table data={data?.isolateForShare} />
        </RenderByCondition>
      </Grid>
    </Grid>
  );
};

const CardHeader = ({ requestId }) => (
  <Grid
    item
    container
    bgcolor={'info.light'}
    alignItems={'center'}
    py={2}
    px={2}
  >
    <Grid item>
      <Typography variant="h6" color="primary">
        Isolate : {requestId}
      </Typography>
    </Grid>
  </Grid>
);

const ShareRequestOrganism = ({ data, isLoading, isolateId }) => {
  const theme = useTheme();

  return (
    <Grid container>
      <PageHeader title={'Share Request'} />
      <StyledCard flexDirection={'column'} item container theme={theme}>
        <CardHeader requestId={isolateId} />
        <Content>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <ShareIsolateList data={data} />
            </Grid>
            <Grid item md={6} xs={12}>
              <PermissionLetter data={data} isolateId={isolateId} />
            </Grid>
          </Grid>
        </Content>
      </StyledCard>
      <BackdropLoader open={isLoading} />
    </Grid>
  );
};

export default ShareRequestOrganism;
