import { TOAST_DEFAULT_DURATION } from 'constants/index';
import { ToastContext } from 'context/toast';
import { useContext } from 'react';

const useToast = () => {
  const { state, setState }: any = useContext(ToastContext);

  const setClose = () => {
    setState({ ...state, open: false });
  };

  const setOpen = () => {
    setState({ ...state, open: true });
  };

  const setter = (message, props) => {
    const duration = props.duration || TOAST_DEFAULT_DURATION;
    return {
      props: { ...props },
      message,
      key: new Date().getTime() + Math.random(),
      position: props.position,
      duration,
      open: true,
      onFinishCallBack: props.onFinishCallBack,
      setClose: setClose,
    };
  };

  const toast = {
    success: function (message, props) {
      setState({
        ...setter(message, props),
        type: 'success',
      });
    },

    info: function (message, props) {
      setState({
        ...setter(message, props),
        type: 'info',
      });
    },

    error: function (message, props) {
      setState({
        ...setter(message, props),
        type: 'error',
      });
    },

    warn: function (message, props) {
      setState({
        ...setter(message, props),
        type: 'warning',
      });
    },
  };

  return {
    toast,
    toastState: state,
    setClose,
    setOpen,
  };
};

export default useToast;
