interface RenderByConditionProps {
  show: boolean[];
  children: React.ReactNode | JSX.Element;
}

const RenderByCondition = (props: RenderByConditionProps): any => {
  const { show, children } = props;

  const allTrueValues =
    show.length === 0 ? false : show.every((condition) => condition === true);

  if (allTrueValues) return children;

  return null;
};

export default RenderByCondition;
