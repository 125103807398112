import { useLazyIsolateExtensionRequestByIdQuery } from 'api/isolate';
import IsolateExtensionOrganism from 'components/organisms/IsolateExtension';
import { PAGES } from 'constants/index';
import withLayout from 'layout';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getUserRole } from 'store/features/auth/index.selector';

const IsolateExtensionRejectRequest = () => {
  const params = useParams();
  const navigate = useNavigate();
  const userRole = useSelector(getUserRole);
  const [
    fetchIsolateExtension,
    {
      isLoading: isolateExtensionRequestIsLoading,
      isFetching: isolateExtensionRequestIsFetching,
      data: isolateExtensionRequestData,
    },
  ] = useLazyIsolateExtensionRequestByIdQuery();

  useEffect(() => {
    if (isNaN(Number(params.id))) navigate(PAGES[userRole].NOT_FOUND);
    else fetchIsolateExtension({ id: Number(params.id) });
  }, [params]);

  const isolatesIds =
    isolateExtensionRequestData?.data?.isolateForExtension?.map(
      (isolate) => isolate?.id
    );

  return (
    <IsolateExtensionOrganism
      isolateIds={isolatesIds}
      extendIsolatesIsLoading={
        isolateExtensionRequestIsLoading || isolateExtensionRequestIsFetching
      }
    />
  );
};

export default withLayout(IsolateExtensionRejectRequest);
