import { styled } from '@mui/material';
import CustomInput from '../Input';

export const StyledSearchBar = styled(CustomInput)`
  ${() => ({
    display: 'flex',
    border: 0,
    '& .MuiInputBase-root': {
      '&:hover': {
        border: 'none', // Remove border on hover
      },
      '&.Mui-focused': {
        border: 'none', // Remove border on focus
      },
    },
  })}
`;
