import styled from '@emotion/styled';
import {
  Grid,
  GridProps,
  Theme,
  Typography,
  TypographyProps,
} from '@mui/material';
import { TextareaAutosize } from '@mui/base';
import CustomInput from 'components/atoms/Input';
import { StyledErrorTextProps } from 'components/atoms/Input/types';

export const StyledTitle = styled(Typography)<TypographyProps>`
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  min-width: 100px;
  padding-bottom: 27px;
  color: var(--neutral-neutral-8, #4f575e);
`;
export const AdditionalInstructionsElement = styled(Grid)<GridProps>``;
export const StyleCustomInput = styled(CustomInput)`
  margin-left: 0px !important;
  margin: 0px;
`;
export const StyledTextarea = styled(TextareaAutosize)`
  width: 100%;
  min-height: 100px;
  padding: 8px;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
`;
export const StyledErrorText = styled('span')<
  StyledErrorTextProps & { theme?: Theme }
>`
  ${({ size }) => {
    if (['small', 'medium'].includes(size))
      return {
        fontSize: 12,
      };
    return { fontSize: 14 };
  }};
  margin-top: 8;
  color: ${({ theme }) => theme.palette.error.main};
`;
export const StyledTitleTextarea = styled(Typography)`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
`;
export const StyledOptionValue = styled(Grid)(() => ({
  height: 20,
  width: 20,
  borderRadius: '100%',
  marginRight: 12,
}));
