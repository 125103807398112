import { Grid, GridProps, Typography } from '@mui/material';
import { StyledButton, VisuallyHiddenInput } from './styles';

interface DocItemProps {
  sx?: GridProps['sx'];
  title: string | JSX.Element;
  description: string | JSX.Element;
  bgColor: string;
  buttonActions: Array<{
    title: string;
    icon: JSX.Element;
    type?: 'input' | 'button';
    onClick: (e) => void;
    disabled?: boolean;
  }>;
}

const DocItem = (props: DocItemProps) => {
  const { sx, title, description, bgColor, buttonActions } = props;
  return (
    <Grid
      container
      bgcolor={bgColor}
      sx={{ padding: 2, borderRadius: '8px', ...sx }}
    >
      <Grid md={6} container item direction={'column'}>
        <Grid item>{title}</Grid>
        <Grid item>{description}</Grid>
      </Grid>
      <Grid
        md={6}
        container
        item
        alignItems={'center'}
        justifyContent={'flex-end'}
        columnGap={2}
      >
        {buttonActions?.map((button) => (
          <Grid item key={button?.title}>
            <StyledButton
              size="small"
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onClick={button?.type === 'input' ? () => {} : button?.onClick}
              endIcon={button?.icon}
              component={button?.type === 'input' ? 'label' : 'button'}
              disabled={button?.disabled}
            >
              <Typography variant="body2" color="neutral.700">
                {button?.title}
              </Typography>
              {button?.type === 'input' && (
                <VisuallyHiddenInput
                  type="file"
                  onChange={(event) => {
                    const file = event?.target?.files?.[0];
                    if (file) button?.onClick(event);
                    event.target.value = '';
                  }}
                />
              )}
            </StyledButton>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default DocItem;
