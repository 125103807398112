import styled from '@emotion/styled';
import { Button, Grid, Typography } from '@mui/material';
import theme from 'theme';

export const StyledHeader = styled(Grid)`
  display: flex;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: 2;
`;

export const StyledBody = styled(Grid)`
  display: flex;
  margin: 8px;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: #f8f9fa;
  border-radius: 5px;
`;

export const StyledBodyContent = styled(Grid)`
  display: flex;
  margin: 7px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const StyledButton = styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${theme.palette.neutral[50]};
`;

export const StyledTypography = styled(Typography)`
  ${() => ({
    paddingLeft: '8px',
    fontWeight: 800,
    fontSize: 18,
    lineHeight: '22px',
  })}
`;
