import styled from '@emotion/styled';
import { Badge } from '@mui/material';

export const SmallBadge: any = styled(Badge)(({ theme }: any) => ({
  '& .MuiBadge-badge': {
    minWidth: '18px', // Adjust the width of the badge
    height: '18px', // Adjust the height of the badge
    fontSize: '14px', // Adjust the font size inside the badge
    fontWeight: 'bold', // Adjust the font weight inside the badge
    padding: '0 0px', // Adjust padding inside the badge
    backgroundColor: '#DB4F80',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));
