import * as React from 'react';
import { SVGProps } from 'react';

const RightArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={21}
    fill="none"
    viewBox="0 0 25 37"
    style={{ margin: 0, padding: 0 }}
    {...props}
  >
    <path
      fill="#29306B"
      d="M7.219 27.922l6.703-7.125c.187-.235.328-.516.328-.797 0-.234-.14-.516-.328-.75L7.219 12.125c-.422-.469-1.125-.469-1.594-.047-.469.422-.469 1.125-.047 1.594l6.047 6.375L5.578 26.375c-.422.469-.422 1.172.047 1.594.469.422 1.172.422 1.594-.047z"
    />
  </svg>
);

export default RightArrow;
