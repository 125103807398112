import React from 'react';
import { TablePagination } from '@mui/material';
import { CustomTablePaginationProps } from './types';
import usePagination from 'hooks/usePagination';

const CustomTablePagination: React.FC<CustomTablePaginationProps> = ({
  rows,
  rowsPerPage,
}) => {
  const { currentPage, goToPage } = usePagination(rows, rowsPerPage);

  const handleChangePage = (event, newPage) => {
    goToPage(newPage);
  };

  return (
    <div>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={currentPage}
        onPageChange={handleChangePage}
      />
    </div>
  );
};

export default CustomTablePagination;
