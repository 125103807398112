import { theme } from 'theme/index';
import styled from '@emotion/styled';
import { DataGrid } from '@mui/x-data-grid';

export const StyledDataGrid = styled(DataGrid)(() => ({
  background: theme.palette.neutral[50],

  boxShadow: '0px 7px 14px 0px #4145581A',

  border: 'none',

  maxHeight: '100%',
  minHeight: 600,

  '& .MuiDataGrid-virtualScroller': {
    minHeight: 600,
  },

  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: theme.palette.neutral[200],
    minHeight: '80px !important',
    position: 'sticky',
    top: 50,
    zIndex: 100,
    [theme.breakpoints.down('md')]: {
      top: 160,
    },
  },

  '& .MuiDataGrid-main': {
    overflow: 'unset',
  },

  width: '100%',

  '& .MuiDataGrid-colCell:not(.MuiDataGrid-colCellMoving) .MuiDataGrid-sortIcon':
    {
      opacity: 1, // Set opacity to 1 to make the sort arrow always visible
    },

  '& .MuiDataGrid-cell': {
    cursor: 'pointer',
  },

  '& .MuiDataGrid-menuIcon': {
    display: 'none',
  },

  '& .MuiDataGrid-row:hover': {
    backgroundColor: theme.palette.info.light,
  },
}));

export default StyledDataGrid;
