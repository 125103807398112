import { Grid, Typography } from '@mui/material';
import { EditIcon, EventIcon } from 'components/icons';
import { calculateDaysDateDifference, formatDate } from 'utils/index';
import dayjs from 'dayjs';

const ReorderReminder = ({ editIconOnClick, value }) => {
  const getColor = () => {
    const days = calculateDaysDateDifference(
      formatDate(dayjs()),
      formatDate(value)
    );

    if (days <= 35) {
      return '#101213';
    } else if (days <= 63) {
      return '#E9530E';
    } else if (days <= 84) {
      return '#FDB515';
    } else {
      return '#9274EE';
    }
  };

  return (
    <Grid
      data-testid="reorder-reminder"
      item
      onClick={(e) => {
        editIconOnClick(e);
      }}
    >
      <Grid
        container
        flexDirection={'row'}
        sx={{
          background: getColor(),
          borderRadius: '5px',
          cursor: 'pointer',
        }}
        padding={'4px'}
        alignItems={'center'}
        justifyContent={'space-between'}
        mt={{ xs: 2, md: 0 }}
      >
        <Grid item sx={{ mt: '4px' }}>
          <EventIcon fill="#fff" />
        </Grid>
        <Grid item paddingInline={'8px'}>
          <Typography variant="body2" color="neutral.50" display="inline">
            {formatDate(value)}
          </Typography>
        </Grid>
        <Grid item>
          <EditIcon />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReorderReminder;
