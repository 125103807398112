import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  LogoIcon,
  LogoutIcon,
  NotificationIcon,
  ProfileTypeIcon,
  SettingsIcon,
} from 'components/icons';
import {
  LanguageSelectorContainer,
  NotificationIconContainer,
  PendingSignaturesContainer,
  ProfileMenuContainer,
  StyledBadge,
  StyledHeader,
} from './styles';
import { useAuth } from 'hooks/useAuth';
import LanguageSelector from 'components/atoms/LanguageSelector';
import { PAGES, USER_ROLE, SIGNAL_R_EVENT } from 'constants/index';
import { useDispatch, useSelector } from 'react-redux';
import { getUserRole } from 'store/features/auth/index.selector';
import ProfileMenu from 'components/atoms/ProfileMenu';
import { ProfileMenuItem } from 'components/atoms/ProfileMenu/types';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import NotificationPopUp from '../NotificationPopUp';
import useNotification, { notificationMapper } from 'hooks/useNotification';
import useToast from 'hooks/useToast';
import { api } from 'config/api';
import RenderByRole from 'components/atoms/RenderByRole';
import PendingSignatures from '../PendingSignatures';
import { useLazyGetUnsignedOrdersCountQuery } from 'api/dashboard';

const Header: React.FC = () => {
  const [notificationPopUpIsOpen, setNotificationPopUpIsOpen] =
    React.useState(false);
  const userRole = useSelector(getUserRole);
  const { logout } = useAuth({
    method: 'msal',
  });

  const handleLogout = async () => {
    logout();
  };
  const { data, sendNewMessage } = useNotification([
    SIGNAL_R_EVENT.RECEIVED.LOAD_TASKS,
  ]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { toast } = useToast();
  const navigate = useNavigate();

  const notificationItems = useMemo(() => {
    const config = notificationMapper(dispatch, api, toast, userRole);

    return data?.LoadTasks?.data?.map((item) => {
      return {
        text: config[item?.type]?.notificationMessage(
          {
            id: item?.owner?.displayId,
            displayText: item?.owner?.displayText,
            status: item?.owner?.status,
          },
          t
        ),
        icon: ProfileTypeIcon,
        endButton: {
          text: config[item?.type]?.buttonMessage(t, undefined),
          onClick: (observer) => {
            config[item?.type]?.execute({
              ...item,
              userRole: userRole,
              notificationId: item?.id,
              id: item?.owner?.actionId,
              navigate,
              callback: (event, data) => {
                observer(event, data);
              },
              observer,
              sendNewMessage,
              duplicateNotification: (data?.LoadTasks?.data || []).reduce(
                (acc, i) => {
                  if (
                    i?.owner?.actionId === item?.owner?.actionId &&
                    i?.id !== item?.id
                  ) {
                    acc.push({ id: i?.id });
                  }
                  return acc;
                },
                []
              ),
            });
          },
          style: {},
        },
        avatar: {
          src: '',
          alt: '',
          name: 'name',
        },
        onClick: () => {
          sendNewMessage(
            SIGNAL_R_EVENT.SEND.ON_MARK_TASK_AS_READ,
            item?.id,
            undefined
          );
        },
        isRead: item?.isRead,
      };
    });
  }, [data?.LoadTasks, t, userRole]);

  const countNoRead = useMemo(() => {
    return data?.LoadTasks?.data?.filter((item) => !item?.isRead)?.length;
  }, [data]);

  const profileMenuItems: Array<ProfileMenuItem> = [
    userRole === USER_ROLE.VET
      ? {
          label: t('profileMenuItems.settings'),
          value: 'settings',
          icon: <SettingsIcon />,
          hasDivider: false,
          redirectUrl: PAGES[USER_ROLE.VET]?.ACCOUNT_SETTINGS,
        }
      : false,
    {
      label: t('profileMenuItems.logout'),
      value: 'logout',
      icon: <LogoutIcon />,
      onClick: handleLogout,
      hasDivider: false,
    },
  ].filter(Boolean) as Array<ProfileMenuItem>;

  const [fetchUnsignedOrdersCount, { data: unsignedOrdersCountData }] =
    useLazyGetUnsignedOrdersCountQuery();
  useEffect(() => {
    if (userRole === USER_ROLE.CSS) {
      fetchUnsignedOrdersCount();
    }
  }, [userRole, fetchUnsignedOrdersCount]);

  // function to filter orders by navigating to admin/dashboard?searchKey=unsignedOrdersCountData.statusSearchKey
  const navigateToUnsignedOrders = () => {
    navigate(
      `${PAGES[USER_ROLE.CSS]?.ROOT}?searchKey=${
        unsignedOrdersCountData?.statusSearchKey
      }`
    );
  };

  return (
    <StyledHeader>
      <LogoIcon
        data-testid="logo-icon"
        onClick={() => {
          navigate(PAGES[userRole]?.ROOT);
        }}
      />
      <div>
        <LanguageSelectorContainer>
          <LanguageSelector />
        </LanguageSelectorContainer>

        <NotificationIconContainer
          onClick={() => {
            setNotificationPopUpIsOpen(!notificationPopUpIsOpen);
          }}
        >
          <NotificationIcon data-testid="notification-icon" />

          <NotificationPopUp
            handleClose={() => setNotificationPopUpIsOpen(false)}
            open={notificationPopUpIsOpen}
            items={notificationItems}
            countNoRead={countNoRead}
          />

          <StyledBadge
            badgeContent={
              <Typography color="neutral.50" variant={'body3' as any}>
                {countNoRead}
              </Typography>
            }
            isDisabled={countNoRead === 0 || !countNoRead}
          />
        </NotificationIconContainer>

        <RenderByRole userRole={userRole} allowedRoles={[USER_ROLE.CSS]}>
          <PendingSignaturesContainer onClick={navigateToUnsignedOrders}>
            <PendingSignatures count={unsignedOrdersCountData?.count || 0} />
          </PendingSignaturesContainer>
        </RenderByRole>

        <ProfileMenuContainer>
          <ProfileMenu menuItems={profileMenuItems} />
        </ProfileMenuContainer>
      </div>
    </StyledHeader>
  );
};

export default Header;
