import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { CustomDatePickerProps } from './types';
import dayjs from 'dayjs';

function CustomDatePicker({
  sx = {},
  value,
  onChange,
  label,
  minDate,
}: Readonly<CustomDatePickerProps>): JSX.Element {
  const locale = dayjs.locale();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <DatePicker
        label={label}
        sx={{ ...sx }}
        value={value}
        onChange={(newValue, ctx) => onChange(newValue, ctx)}
        minDate={minDate}
        data-testid="date-picker"
      />
    </LocalizationProvider>
  );
}

export default CustomDatePicker;
