import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { StyledPaginationItem } from './styles';
import { CustomPaginationProps } from './types';

const CustomPagination: React.FC<CustomPaginationProps> = (props) => {
  const { totalPages, currentPage, defaultPage, handleChange } = props;

  return (
    <Stack spacing={2}>
      <Pagination
        count={totalPages}
        page={currentPage}
        defaultPage={defaultPage}
        onChange={handleChange}
        renderItem={(item) => {
          return (
            <StyledPaginationItem
              {...item}
              shape="rounded"
              size="medium"
              variant="outlined"
              selected={item?.page === currentPage}
            />
          );
        }}
      />
    </Stack>
  );
};

export default CustomPagination;
