import {
  FarmInfoKeys,
  ORDER_FORM_STEP_NAMES,
  USER,
  InfectionInfoKeys,
  DosingAndFillingInfoKeys,
  AdditionalInstructionsInfoKeys,
  BottlesPricingInfoKeys,
} from 'constants/index';

export type Option = {
  label: string;
  value: string;
  [key: string]: any;
};

type AnimalGroupItem = {
  id: number;
  animalSpecies: Array<{ id: 1; name: string }>;
  name: string;
};

export interface IOrderForm {
  orderForm: {
    orderMode: ModeState;
    id: number;
    config: any;
    user: {
      type: (typeof USER)[keyof typeof USER];
    };
    [ORDER_FORM_STEP_NAMES.FARM_INFO]: {
      [FarmInfoKeys.VETS]: { data: Array<Option>; value: Option };
      [FarmInfoKeys.NON_ADJACENT_REQUIRED]: { value: boolean };
      [FarmInfoKeys.FARM_ADDRESSES]: {
        value: any;
        data: Array<Option>;
        totalCount: number;
        selectCount: number;
      };
      [FarmInfoKeys.SHIPPING_SITES]: {
        value: any;
        data: Array<Option>;
        totalCount: number;
        selectCount: number;
      };
      [FarmInfoKeys.SHIPPING_SITES_BY_STATES]: {
        value: {
          id: number;
          stateId: number;
        }[];
        data: Array<Option>;
        totalCount: number;
        selectCount: number;
        totalStateCount: number;
      };
      [FarmInfoKeys.BILLING_ADDRESSES]: { value: Option; data: Array<Option> };
      [FarmInfoKeys.CUSTOMER]: {
        value: Option;
        data: Array<Option>;
      };
    };
    [ORDER_FORM_STEP_NAMES.INFECTION_INFO]: {
      [InfectionInfoKeys.ANIMAL_GROUPS]: {
        data: Array<AnimalGroupItem>;
        value: Option;
      };
      [InfectionInfoKeys.ANIMAL_SPECIES]: {
        data: Array<Option>;
        value: Option;
      };
      [InfectionInfoKeys.AUTHORIZED_ISOLATES]: {
        data: Array<{
          isolateId: number;
          animalSpeciesId: string;
        }>;
      };
      [InfectionInfoKeys.PRODUCTS]: {
        value: Option;
        data: Array<Option>;
      };
      [InfectionInfoKeys.ISOLATES]: {
        data: Array<Option>;
        value: Array<Option>;
      };
      [InfectionInfoKeys.ADJUVANTS]: {
        value: Option;
        data: [];
        totalCount: number;
        selectCount: number;
      };
      [InfectionInfoKeys.ADMINISTRATION_ROUTES]: {
        data: Array<Option>;
        value: Option;
      };
      [InfectionInfoKeys.DOSES]: {
        value: Option;
        data: [];
        min: number;
        max: number;
      };
      [InfectionInfoKeys.NUM_OF_ANIMAL]: {
        value: number;
        min: number;
        max: number;
      };
    };
    [ORDER_FORM_STEP_NAMES.DOSING_AND_FILLING_INFO]: {
      [DosingAndFillingInfoKeys.BOTTLES]: {
        value: any;
        data: [];
        totalVolume: number;
        totalCount: number;
        totalDoses: number;
      };
      [DosingAndFillingInfoKeys.BOTTLE_USAGE]: {
        value: number;
        min: number;
        max: number;
      };
    };
    [ORDER_FORM_STEP_NAMES.BOTTLE_PRICING]: {
      [BottlesPricingInfoKeys.BOTTLES]: {
        value: Array<Option>;
        data: [];
      };
    };
    [ORDER_FORM_STEP_NAMES.ADDITIONAL_INSTRUCTIONS_INFO]: {
      [AdditionalInstructionsInfoKeys.COLORS]: {
        value: Option;
        data: [];
      };
      [AdditionalInstructionsInfoKeys.BOTTLE_TAG]: {
        value: string;
        data: Array<any>;
      };
      [AdditionalInstructionsInfoKeys.VACCINATION_SCHEDULE]: {
        value: string;
      };
      [AdditionalInstructionsInfoKeys.CLINICAL_SIGNS]: {
        value: string;
      };
      [AdditionalInstructionsInfoKeys.INTERNAL_REFERENCE]: {
        value: string;
      };
      [AdditionalInstructionsInfoKeys.ADDITIONAL_COMMENT]: {
        value: string;
        data: [];
      };
      [AdditionalInstructionsInfoKeys.REQUEST_LETTER_COMMENT]: {
        value: string;
        data: [];
      };
    };
  };
}

export interface ISetFieldValueAction {
  propPath: string;
  value: any;
  formName: (typeof ORDER_FORM_STEP_NAMES)[keyof typeof ORDER_FORM_STEP_NAMES];
  actions?: any;
}

export interface ISetUser {
  type: IOrderForm['orderForm']['user']['type'];
}

export enum ModeState {
  ORDER = 'order',
  REORDER = 'reorder',
  EDIT = 'edit',
}
