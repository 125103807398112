import withLayout from 'layout';
import { StyledFormHeaderContainer, StyledTypography } from './styles';
import { Box, Grid, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import {
  Address,
  Customer,
  OrderAddress,
  OrderFormResponse,
  useGetOrderConfigQuery,
  useOrderAddressesQuery,
  useSaveOrderAddressesMutation,
} from 'api/orderForm';
import { useNavigate, useParams } from 'react-router-dom';
import { PAGES, USER_ROLE } from 'constants/index';
import {
  getUserAccount,
  getUserRole,
} from 'store/features/auth/index.selector';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from 'components/atoms/Button';
import theme from 'theme';
import AddressFormContent from 'components/organisms/Addresses/AddressForms/Main';
import { getFarmData } from 'store/features/orderForm/index.selectors';
import { AddressTypes } from 'enums';
import { AddressModalProps } from 'components/organisms/Addresses/AddressModal/types';
import { getDataVisibility } from 'utils';
import BackdropLoader from 'components/atoms/BackdropLoader';
import {
  getCustmerId,
  getNewOrderAddresses,
  getProximityAddressesOrder,
  getSelectedStateIdForm,
  getStatesDataOrder,
} from 'store/features/addressesManager/index.selectors';
import { setStatesDataOrder } from 'store/features/addressesManager/index.slice';
import RenderByRole from 'components/atoms/RenderByRole';
import PageHeader from 'components/molecules/PageHeader';
import TabsRendering from 'components/molecules/TabsRendering';

const useCommonHelper = ({
  addressType,
  selectedFarmAddresses,
  selectedShippingAddresses,
  selectedBillingAddresses,
  viewOnly,
  addressData,
  editMode,
}) => {
  const getDataList = () => {
    switch (addressType) {
      case AddressTypes.Main:
        return selectedFarmAddresses?.value;
      case AddressTypes.Shipping:
        return selectedShippingAddresses?.value;
      case AddressTypes.Billing:
        return selectedBillingAddresses?.value;
      default:
        return [];
    }
  };

  const getDisplayedAddresses = () => {
    if (viewOnly) {
      return addressData;
    }
    return editMode ? addressData : getDataList();
  };

  return {
    getDataList,
    getDisplayedAddresses,
  };
};

const NonAdjacentAddresses: React.FC<AddressModalProps> = ({
  addressData,
  addressType,
  editMode,
  onClose,
  viewOnly,
  onSelectDone,
  modePreviewTotalCount,
  modePreviewSelectCount,
  selectType = 'multiple',
  statesData,
  setStatesData,
  orderAddresses,
  setOrderAddresses,
  addressesByState,
  setAddressesByState,
  isShippingAddresse,
  activeTab = 0,
  currentProximityAddresses,
  CurrentAddressData,
  value,
  onValueChange,
  custmerId,
}) => {
  const { t } = useTranslation();
  const farmData = useSelector(getFarmData);
  const {
    farmAddresses: selectedFarmAddresses,
    shippingSites: selectedShippingAddresses,
    billingAddresses: selectedBillingAddresses,
    nonAdjacentRequired: isNonAdjacentRequired,
  } = farmData || {};
  const { getDisplayedAddresses } = useCommonHelper({
    addressType,
    selectedFarmAddresses,
    selectedShippingAddresses,
    selectedBillingAddresses,
    viewOnly,
    addressData,
    editMode,
  });

  const modalTitle = useMemo(() => {
    if (addressType === AddressTypes.Main) {
      if (viewOnly) {
        return t('orderForm.farmAddressesTitle');
      } else {
        if (isNonAdjacentRequired?.value) {
          return t('orderForm.proximityAddressesTitle');
        }
        return t('orderForm.farmAddressesTitle');
      }
    } else if (addressType === AddressTypes.Shipping) {
      return t('orderForm.shippingSitesTitle');
    } else if (addressType === AddressTypes.Proximity) {
      return t('orderForm.proximityAddressesTitle');
    }
    return null;
  }, [isNonAdjacentRequired?.value, addressType]);

  return (
    <Grid
      container
      justifyContent="center"
      height={'100%'}
      width={'100%'}
      pb={10}
    >
      <Grid
        item
        bgcolor={theme.palette.neutral[50]}
        width={{ md: '100%', xs: '98%' }}
        marginX={{ xs: 'auto' }}
        marginTop={{ xs: 3 }}
      >
        <StyledFormHeaderContainer>
          <Grid item xs={11}>
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <StyledTypography variant="h4" color="primary.main">
                Non-adjacent Addresses
              </StyledTypography>
            </Box>
          </Grid>
        </StyledFormHeaderContainer>

        <Grid mt={4}>
          <AddressFormContent
            id="address-form-content"
            addressType={addressType}
            addressData={getDisplayedAddresses()}
            editMode={viewOnly ? false : editMode}
            subTitle={modalTitle}
            onClose={onClose}
            onSelectDone={onSelectDone}
            modePreviewTotalCount={modePreviewTotalCount}
            modePreviewSelectCount={modePreviewSelectCount}
            selectType={selectType}
            statesData={statesData}
            setStatesData={setStatesData}
            isShippingAddresse={isShippingAddresse}
            orderAddresses={orderAddresses}
            setOrderAddresses={setOrderAddresses}
            addressesByState={addressesByState}
            setAddressesByState={setAddressesByState}
            modeTest={false}
            activeTab={activeTab}
            currentProximityAddresses={currentProximityAddresses}
            value={value}
            onValueChange={onValueChange}
            CurrentAddressData={CurrentAddressData}
            fromShippingPage={true}
            isOrderForm={false}
            custmerId={custmerId}
          />
          <Divider />
        </Grid>
      </Grid>
    </Grid>
  );
};

const ShippingSites: React.FC<AddressModalProps> = ({
  addressData,
  addressType,
  editMode,
  onClose,
  viewOnly,
  onSelectDone,
  modePreviewTotalCount,
  modePreviewSelectCount,
  selectType = 'multiple',
  statesData,
  setStatesData,
  orderAddresses,
  setOrderAddresses,
  addressesByState,
  setAddressesByState,
  isShippingAddresse,
  activeTab = 0,
  selectedStateId,
  setSelectedStateId,
  value,
  onValueChange,
  CurrentAddressData,
  CurrentAddressState,
}) => {
  const { t } = useTranslation();
  const farmData = useSelector(getFarmData);
  const {
    farmAddresses: selectedFarmAddresses,
    shippingSites: selectedShippingAddresses,
    billingAddresses: selectedBillingAddresses,
    nonAdjacentRequired: isNonAdjacentRequired,
  } = farmData || {};
  const { getDisplayedAddresses } = useCommonHelper({
    addressType,
    selectedFarmAddresses,
    selectedShippingAddresses,
    selectedBillingAddresses,
    viewOnly,
    addressData,
    editMode,
  });

  const modalTitle = useMemo(() => {
    if (addressType === AddressTypes.Main) {
      if (viewOnly) {
        return t('orderForm.farmAddressesTitle');
      } else {
        if (isNonAdjacentRequired?.value) {
          return t('orderForm.proximityAddressesTitle');
        }
        return t('orderForm.farmAddressesTitle');
      }
    } else if (addressType === AddressTypes.Shipping) {
      return t('orderForm.shippingSitesTitle');
    } else if (addressType === AddressTypes.Proximity) {
      return t('orderForm.proximityAddressesTitle');
    }
    return null;
  }, [isNonAdjacentRequired?.value, addressType]);

  return (
    <Grid
      container
      justifyContent="center"
      height={'100%'}
      width={'100%'}
      pb={10}
    >
      <Grid
        item
        bgcolor={theme.palette.neutral[50]}
        width={{ md: '100%', xs: '98%' }}
        marginX={{ xs: 'auto' }}
        marginTop={{ xs: 3 }}
      >
        <StyledFormHeaderContainer>
          <Grid item xs={11}>
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <StyledTypography variant="h4" color="primary.main">
                Shipping Sites
              </StyledTypography>
            </Box>
          </Grid>
        </StyledFormHeaderContainer>

        <Grid mt={1}>
          <AddressFormContent
            id="address-form-content"
            addressType={addressType}
            addressData={getDisplayedAddresses()}
            editMode={viewOnly ? false : editMode}
            subTitle={modalTitle}
            onClose={onClose}
            onSelectDone={onSelectDone}
            modePreviewTotalCount={modePreviewTotalCount}
            modePreviewSelectCount={modePreviewSelectCount}
            selectType={selectType}
            statesData={statesData}
            setStatesData={setStatesData}
            isShippingAddresse={isShippingAddresse}
            orderAddresses={orderAddresses}
            setOrderAddresses={setOrderAddresses}
            selectedStateId={selectedStateId}
            setSelectedStateId={setSelectedStateId}
            addressesByState={addressesByState}
            setAddressesByState={setAddressesByState}
            modeTest={true}
            activeTab={activeTab}
            value={value}
            onValueChange={onValueChange}
            isOrderForm={false}
            CurrentAddressData={CurrentAddressData}
            CurrentAddressState={CurrentAddressState}
            fromShippingPage={true}
          />
          <Divider />
        </Grid>
      </Grid>
    </Grid>
  );
};

const ManageAddressesContent = () => {
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [isShippingAddressOpen, setIsShippingAddressOpen] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const userRole = useSelector(getUserRole);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const selectedStateIdForm = useSelector(getSelectedStateIdForm);
  const user = useSelector(getUserAccount);
  const { data: orderConfigData, isLoading: configIsLoading } =
    useGetOrderConfigQuery(
      {
        userId: user?.id,
        role: user?.role,
      },
      { skip: !(user?.id && user?.role) }
    );
  const idFromParams = parseInt(id, 10) || 0;
  const { data: orderAddressesData, isLoading: orderAddessesIsLoading } =
    useOrderAddressesQuery(idFromParams);
  const custmerId = useSelector(getCustmerId);
  const shippingAddressesOrder = useSelector(getNewOrderAddresses);
  const farmAddressesOrder = useSelector(getProximityAddressesOrder);
  const statesDataOrder = useSelector(getStatesDataOrder);
  const dispatch = useDispatch();

  const [
    saveOrderAddresses,
    {
      isSuccess: saveOrderAddressesIsSuccess,
      isLoading: saveOrderAddressesIsLoading,
    },
  ] = useSaveOrderAddressesMutation();

  useEffect(() => {
    if (saveOrderAddressesIsSuccess && !saveOrderAddressesIsLoading)
      navigate(PAGES[userRole].DASHBOARD);
  }, [saveOrderAddressesIsLoading, saveOrderAddressesIsSuccess]);

  const currentOrderFarms: OrderAddress[] = orderAddressesData
    ?.filter((orderAddress) => !orderAddress.addressId)
    ?.map((orderAddress) => {
      const farmAddress: OrderAddress = {
        id: orderAddress?.farm?.id,
        street: orderAddress?.farm?.address?.street,
        city: orderAddress?.farm?.address?.city,
        stateName: orderAddress?.farm?.address?.state?.name,
        zipCode: orderAddress?.farm?.address?.zipCode,
        country: orderAddress?.farm?.address?.country,
        phoneNumber: orderAddress?.farm?.address?.phoneNumber,
        contact: orderAddress?.farm?.address?.contact,
        stateId: orderAddress?.farm?.address?.state?.id,
        name: orderAddress?.farm?.name,
        complex: orderAddress?.farm?.complex,
        capacity: orderAddress?.farm?.capacity,
        officialNumber: orderAddress?.farm?.officialNumber,
        farmId: orderAddress?.farm?.id,
        status: orderAddress?.status,
      };
      return farmAddress;
    });

  function findCustomerById(
    orderConfigData: OrderFormResponse,
    id: number
  ): Customer | undefined {
    return orderConfigData?.customers?.find((customer) => customer?.id === id);
  }

  const customerOfOrder = findCustomerById(orderConfigData, custmerId);

  const currentOrderFarmsIds = new Set(
    currentOrderFarms?.map((farm) => farm?.id)
  );

  const customerFarms: Address[] = customerOfOrder?.farms
    ?.filter((farm) => !currentOrderFarmsIds.has(farm?.id))
    ?.map((farm) => {
      return {
        id: farm?.id,
        street: farm.address.street,
        city: farm.address.city,
        stateName: farm?.address?.state?.name,
        zipCode: farm.address.zipCode,
        country: farm.address.country,
        phoneNumber: farm.address.phoneNumber,
        contact: farm.address.contact,
        stateId: farm.address.state?.id,
        name: farm?.name,
        complex: farm.complex,
        capacity: farm.capacity,
        officialNumber: farm.officialNumber,
        farmId: farm?.id,
      };
    });

  const getFilteredShippingsRows = (selectedStateId: number) => {
    const currentOrderShipping: OrderAddress[] = orderAddressesData
      ?.filter(
        (orderAddress) =>
          orderAddress.addressId > 0 && orderAddress.stateId == selectedStateId
      )
      ?.map((orderAddress) => {
        const { address } = orderAddress;
        const { state } = address;

        return {
          id: address?.id,
          street: address.street,
          city: address.city,
          name: address?.name,
          stateName: state?.name,
          zipCode: address.zipCode,
          country: address.country,
          phoneNumber: address.phoneNumber,
          contact: address.contact,
          stateId: state?.id,
          status: orderAddress.status,
        };
      })
      .filter((shippingAddress) => shippingAddress !== null);
    return currentOrderShipping;
  };

  const customerShippingAddresses: Address[] =
    customerOfOrder?.shippingAddresses
      ?.filter((address) => !currentOrderFarmsIds.has(address.id))
      ?.map((address) => {
        return {
          id: address?.id,
          street: address.street,
          city: address.city,
          stateName: address?.state?.name,
          zipCode: address.zipCode,
          country: address.country,
          phoneNumber: address.phoneNumber,
          contact: address.contact,
          stateId: address.state?.id,
          name: address?.name,
        };
      });

  function getSelectableShippingData(selectedStateId: number) {
    const currentOrderShipping = getFilteredShippingsRows(selectedStateId);

    const currentOrderShippingIds = new Set(
      currentOrderShipping?.map((address) => address?.id)
    );

    const selectableShippingAddresses = customerShippingAddresses?.filter(
      (address) => !currentOrderShippingIds.has(address?.id)
    );

    return selectableShippingAddresses ?? [];
  }

  const onSaveClick = async () => {
    saveOrderAddresses([...farmAddressesOrder, ...shippingAddressesOrder]);
  };

  const tabs = [
    {
      label: 'Non-Adjacent Addresses',
      tabComponent: (
        <NonAdjacentAddresses
          isOpen={isAddressModalOpen}
          addressData={customerFarms}
          addressType={AddressTypes.Main} //
          editMode={true}
          onClose={() => setIsAddressModalOpen(false)}
          modePreviewTotalCount={0}
          modePreviewSelectCount={0}
          selectType={'multiple'}
          isShippingAddresse={true}
          activeTab={activeTab}
          currentProximityAddresses={currentOrderFarms}
          CurrentAddressData={currentOrderFarms}
          custmerId={custmerId}
        />
      ),
    },
    {
      label: 'Shipping Sites',
      tabComponent: (
        <ShippingSites
          isOpen={isShippingAddressOpen}
          addressData={getSelectableShippingData(Number(selectedStateIdForm))}
          addressType={AddressTypes.Shipping}
          editMode={true}
          onClose={() => setIsShippingAddressOpen(false)}
          modePreviewTotalCount={0}
          modePreviewSelectCount={0}
          selectType={'multiple'}
          isShippingAddresse={false}
          activeTab={activeTab}
          CurrentAddressData={getFilteredShippingsRows(
            Number(selectedStateIdForm)
          )}
          CurrentAddressState={[]}
        />
      ),
    },
  ];

  function getUniqueArray(
    selectedStatesData: any[],
    tempStateData: any[] | null | undefined
  ): any[] {
    const filteredStates =
      tempStateData?.reduce((acc, selectedAddress) => {
        if (!acc.some((item) => item.id === selectedAddress.stateId)) {
          acc.push({
            variant: 'body1',
            name: selectedAddress.stateName,
            id: selectedAddress.stateId,
          });
        }
        return acc;
      }, []) ?? [];

    const combinedArray = [...filteredStates, ...selectedStatesData];

    return combinedArray.reduce((acc: any[], current: any) => {
      const isDuplicate = acc.some((item) => item.id === current.id);
      if (!isDuplicate) {
        acc.push(current);
      }
      return acc;
    }, []);
  }

  useEffect(() => {
    if (statesDataOrder?.length === 0) {
      const uniqueArray = getUniqueArray([], currentOrderFarms ?? []);
      dispatch(setStatesDataOrder(uniqueArray ?? []));
    }
  }, [currentOrderFarms]);

  return (
    <>
      <Grid container flexDirection={'row'}>
        <Grid item xs={10} md={11}>
          <PageHeader title={t('orderForm.manageAddress')} />
        </Grid>
        <RenderByRole
          userRole={userRole}
          allowedRoles={[USER_ROLE.VET, USER_ROLE.TM]}
        >
          <Grid item xs={2} md={1} my={{ xs: 2, md: 2 }} mb={{ xs: 4, md: 4 }}>
            <CustomButton size="medium" type="button" onClick={onSaveClick}>
              {t('orderForm.save')}
            </CustomButton>
          </Grid>
        </RenderByRole>
      </Grid>
      <Grid item xs={6} sx={{ my: 5 }}>
        <TabsRendering
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          canSwitchTab={true}
        />
      </Grid>
      <BackdropLoader
        open={
          saveOrderAddressesIsLoading ||
          configIsLoading ||
          orderAddessesIsLoading
        }
      />
    </>
  );
};

const ManageAddresses = () => {
  const { ADDRESSES_BY_STATE_VISIBILITY } = getDataVisibility();
  return <>{ADDRESSES_BY_STATE_VISIBILITY && <ManageAddressesContent />}</>;
};

export default withLayout(ManageAddresses);
