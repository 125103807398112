import { ComponentType } from 'react';

import Header from 'components/organisms/Header';
import Main from 'components/organisms/Main';
import { Item, Grid } from './styles';
import Footer from 'components/organisms/Footer';

const withLayout = <P extends object>(WrappedComponent: ComponentType<P>) => {
  return function WithLayout(props: P) {
    const MainArea = () => (
      <Main>
        <WrappedComponent {...props} />
      </Main>
    );

    return (
      <Grid>
        <Item gridArea="header">
          <Header />
        </Item>

        <Item gridArea="main" style={{ position: 'relative' }}>
          <MainArea />
        </Item>
        <Item gridArea="footer">
          <Footer />
        </Item>
      </Grid>
    );
  };
};

export default withLayout;
