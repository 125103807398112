import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { LANGUAGE_CODES, I18NEXT_LANGUAGE_KEY } from 'constants/index';

export const useChangeLanguage = () => {
  const { i18n } = useTranslation();

  const [currentLanguage, setLanguage] = useLocalStorage(
    I18NEXT_LANGUAGE_KEY,
    LANGUAGE_CODES.en
  );

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    setLanguage(language);
  };

  return [changeLanguage, { currentLanguage }] as const;
};
