import { api } from 'config/api';

type QueryParams = {
  id: number;
};

export enum shareStatus {
  Sent,
  Denied,
  Approved,
  None,
}

export interface IsolateForShareResponse {
  data: Data;
  isSuccess: boolean;
  errorCode: string;
  message: string;
  statusCode: number;
  errors: any[];
}

export interface Data {
  comment: string;
  isolateForShare: IsolateForShare;
  customerOptions: CustomerOption[];
  vetOptions: VetOption[];
}

export interface IsolateForShare {
  id: number;
  name: string;
  comment: string;
  expirationDate: string;
  serialNumbers: any[];
}

export interface CustomerOption {
  id: number;
  name: string;
}

export interface VetOption {
  id: number;
  userName: string;
}

const transformResponse = (res: any) => res;

const shareIsolateApi = api.injectEndpoints({
  endpoints: (build) => ({
    getIsolateForShare: build.query<IsolateForShareResponse, QueryParams>({
      query: (query) => {
        return {
          url: `Isolate/getIsolateForShareRequest?isolateId=${query.id}`,
        };
      },
      transformResponse: transformResponse,
    }),
    getIsolateForShareDecline: build.query<
      IsolateForShareResponse,
      QueryParams
    >({
      query: (query) => {
        return {
          url: `Isolate/getShareRequest?shareRequestId=${query.id}`,
        };
      },
      transformResponse: transformResponse,
    }),
    sendIsolateForShare: build.mutation<any, any>({
      query: (body) => {
        return {
          url: 'Isolate/createShareRequest',
          method: 'POST',
          body: body,
        };
      },
    }),
  }),
});

export const {
  useGetIsolateForShareQuery,
  useSendIsolateForShareMutation,
  useGetIsolateForShareDeclineQuery,
  useLazyGetIsolateForShareDeclineQuery,
} = shareIsolateApi;

export default shareIsolateApi;
