import * as React from 'react';
import { SVGProps } from 'react';
const LeftArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={21}
    fill="none"
    viewBox="0 0 25 37"
    style={{ margin: 0, padding: 0 }}
    {...props}
  >
    <path
      fill="#29306B"
      d="m17.781 27.922-6.703-7.125c-.187-.235-.328-.516-.328-.797 0-.234.14-.516.328-.75l6.703-7.125c.422-.469 1.125-.469 1.594-.047.469.422.469 1.125.047 1.594l-6.047 6.375 6.047 6.328c.422.469.422 1.172-.047 1.594-.469.422-1.172.422-1.594-.047Z"
    />
  </svg>
);
export default LeftArrow;
