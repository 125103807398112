import { Box, StepIconProps, Stepper, styled } from '@mui/material';
import CustomCircle from 'components/atoms/Circle';

export const StyledContainer = styled(Box)`
  ${({ theme }) => ({
    width: '100%',
    display: 'flex',
    background: theme.palette.neutral[50],
    flexDirection: 'row',
    padding: 60,
    [theme.breakpoints.down('md')]: {
      padding: '10px 5px',
      flexDirection: 'column',
      position: 'relative',
    },
  })}
`;

export const StyledStepper = styled(Stepper)`
  ${({ theme }) => ({
    width: 200,
    display: 'block',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: 200,
      overflowY: 'scroll',
      marginBottom: 40,
      paddingLeft: 15,
    },
  })}
`;

export const StyledContentContainer = styled(Box)`
  ${() => ({ flex: 1, display: 'flex' })}
`;

export const StyledStepIcon = styled(CustomCircle)<
  Omit<StepIconProps, 'icon' | 'active' | 'completed'> & {
    active: string;
    completed: string;
  }
>`
  ${({ active, completed, theme }) => {
    if (active === 'true' && completed === 'false')
      return {
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: 'transparent',
        color: theme.palette.primary.main,
      };
    else if (completed === 'true' && active === 'false')
      return {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.neutral[50],
      };
    else {
      return {
        border: `1px solid ${theme.palette.neutral[700]}`,
        backgroundColor: 'transparent',
        color: theme.palette.neutral[700],
      };
    }
  }}
`;
