import { Grid } from '@mui/material';
import { PdfPreviewContainer } from './style';
import Form from './Form';

import { useEffect } from 'react';
import usePdfDoc from 'hooks/useDocMerge';
import { IContact } from 'types';
import {
  useSentPacketByEmailMutation,
  useSentPacketMutation,
  useUploadDocumentMutation,
} from 'api/docs';
import usePdfUpload from 'hooks/useDocUpload';
import { useFormik } from 'formik';
import { DocumentTypeIdEnum, PAGES } from 'constants/index';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserRole } from 'store/features/auth/index.selector';
import BackdropLoader from 'components/atoms/BackdropLoader';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import useToast from 'hooks/useToast';
import PdfPreview from '../PdfPreview';

export interface ReviewPacketProps {
  goNext?: () => void;
  mails?: { cc: Array<IContact>; main: Array<IContact> };
  pdfUrls?: Array<string>;
  stateData?: {
    stateName: string;
    stateId: number | string;
    docuSignEnabled: boolean;
  };
  orderId?: string;
}

const ReviewPacket = (props: ReviewPacketProps) => {
  const { goNext, mails, pdfUrls, stateData, orderId } = props;
  const {
    fileDataUri,
    downloadFile,
    fetchFiles,
    convertBase64ToBlob,
    isLoading: pdfIsLoading,
  } = usePdfDoc();
  const [
    uploadFn,
    { isLoading: uploadFileIsLoading, isError: uploadFileIsError },
  ] = useUploadDocumentMutation();
  const userRole = useSelector(getUserRole);
  const { t } = useTranslation();
  const { toast } = useToast();

  const { handlePdfUpload } = usePdfUpload(uploadFn);
  const [
    sendPacket,
    {
      isLoading: sendPacketIsLoading,
      isSuccess: sendPacketIsSuccess,
      isError: sendPacketIsError,
    },
  ] = useSentPacketMutation();
  const [
    sendPacketByEmail,
    {
      isLoading: sendPacketByEmailIsLoading,
      isSuccess: sendPacketByEmailIsSuccess,
      isError: sendPacketByEmailIsError,
    },
  ] = useSentPacketByEmailMutation();
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    object: Yup.string().required(t('errorMessages.objectRequired')),
    emailContent: Yup.string().required(
      t('errorMessages.emailContentRequired')
    ),
    mainEmails: Yup.array()
      .required(t('errorMessages.fieldRequired'))
      .min(1, t('errorMessages.fieldRequired')),
  });

  const formik = useFormik({
    initialValues: {
      object: '',
      mainEmails: mails.main,
      ccEmails: mails.cc,
      emailContent: '',
    },
    validationSchema,

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {
      goNext();
    },
  });

  const prepareData = (packetDoc) => {
    return {
      orderId: orderId,
      stateId: stateData?.stateId,
      contacts: [
        ...(formik?.values?.ccEmails || []),
        ...(formik?.values?.mainEmails || []),
      ],
      emailSubject: formik?.values?.object,
      emailBody: formik?.values?.emailContent,
      documentId: packetDoc?.id,
    };
  };

  useEffect(() => {
    if (!sendPacketIsLoading && sendPacketIsSuccess) {
      navigate(`${PAGES[userRole].DASHBOARD}`);
    }
  }, [sendPacketIsSuccess, sendPacketIsLoading]);

  useEffect(() => {
    if (!sendPacketByEmailIsLoading && sendPacketByEmailIsSuccess) {
      navigate(`${PAGES[userRole].DASHBOARD}`);
    }
  }, [sendPacketByEmailIsSuccess, sendPacketByEmailIsLoading]);

  const sendMergedFile = () => {
    return new Promise((resolve, reject) => {
      try {
        const formData = new FormData();

        formData.append('OrderId', orderId);

        formData.append('TypeId', DocumentTypeIdEnum.Packet.toString());

        formData.append('File', convertBase64ToBlob());

        formData.append('StateId', stateData?.stateId?.toString());
        handlePdfUpload(formData).then((res) => {
          resolve(res?.data);
        });
      } catch (e) {
        reject(e);
      }
    });
  };

  const sendByEmailForm = async () => {
    const res = await sendMergedFile();
    if (res) sendPacketByEmail(prepareData(res));
  };
  const sendByDocSignForm = async () => {
    const res = await sendMergedFile();
    if (res) sendPacket(prepareData(res));
  };
  useEffect(() => {
    fetchFiles(pdfUrls || []);
  }, [pdfUrls]);

  useEffect(() => {
    if (uploadFileIsError) {
      toast.error(t('messages.fileUploadFail'), {});
    }
  }, [uploadFileIsError]);

  useEffect(() => {
    if (sendPacketIsError) {
      toast.error(t('messages.packetFailed'), {});
    }
  }, [sendPacketIsError]);

  useEffect(() => {
    if (sendPacketByEmailIsError) {
      toast.error(t('messages.packetFailed'), {});
    }
  }, [sendPacketByEmailIsError]);

  const isLoading = sendPacketIsLoading || uploadFileIsLoading;

  const dirty = formik?.dirty;

  const isValid = formik.isValid;

  return (
    <Grid container spacing={2} padding={{ xs: 1, md: 6 }}>
      <Grid item xs={12} md={6} container>
        <Form
          goNext={goNext}
          mails={mails}
          stateName={stateData?.stateName}
          formData={formik}
          isBtnDisabled={!(isValid && dirty)}
          docuSignEnabled={!(isValid && dirty) || !stateData?.docuSignEnabled}
          sendByEmailForm={sendByEmailForm}
          sendByDocSignForm={sendByDocSignForm}
        />
      </Grid>

      <PdfPreviewContainer item xs={12} md={6}>
        <Grid container padding={2} bgcolor="neutral.200" maxHeight={750}>
          <PdfPreview
            fileDataUri={fileDataUri}
            pdfIsLoading={pdfIsLoading}
            headerTitle={t('docs.packetPreview')}
            buttonActions={[
              {
                id: 1,
                onClick: () => {
                  downloadFile(
                    null,
                    `packet_${orderId}_${stateData?.stateName}.pdf`
                  );
                },
                variant: 'outlined',
              },
            ]}
          />
        </Grid>
      </PdfPreviewContainer>
      <BackdropLoader open={isLoading || sendPacketByEmailIsLoading} />
    </Grid>
  );
};

export default ReviewPacket;
