import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  StyledButton,
  StyledTextBadge,
  StyledTypography,
  VisuallyHiddenInput,
} from './styles';
import CustomButton from 'components/atoms/Button';
import { PAGES } from 'constants/index';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserRole } from 'store/features/auth/index.selector';
const ExtensionLetterDocs = ({
  data,
  selectedItem,
  onSelectItem,
  isShareRequest = false,
}) => {
  return (
    <Grid container xs={12} mt={5}>
      {/* Loop through the data */}
      {data?.map((item, index) => (
        <Grid
          key={item?.date}
          xs={12}
          flexDirection={'column'}
          mb={2}
          onClick={isShareRequest ? () => onSelectItem(index) : undefined}
        >
          <Grid mb={2}>
            <StyledTextBadge name={item.date} color="#4F575E" />
          </Grid>
          <Grid
            container
            bgcolor={
              isShareRequest && selectedItem === index
                ? '#E5F7FD'
                : item.bgColor
            }
            sx={{
              padding: 2,
              borderRadius: '8px',
              ...item.sx,
              cursor: isShareRequest ? 'pointer' : 'default',
            }}
            p={'16px'}
          >
            <Grid md={6} item container direction={'column'}>
              <Grid item>
                <StyledTypography>{item.title}</StyledTypography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  component="span"
                  style={{ fontSize: '0.8rem', marginRight: '10px' }}
                >
                  {item.description}
                </Typography>
                <Typography
                  variant="body2"
                  component="span"
                  style={{ fontSize: '0.8rem' }}
                >
                  {item.requestFileName}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              md={6}
              item
              container
              alignItems={'center'}
              justifyContent={'flex-end'}
              columnGap={2}
            >
              {/* Loop through buttonActions */}
              {item.buttonActions.map((button) => (
                <Grid item key={button?.title}>
                  <StyledButton
                    size="small"
                    onClick={button?.onClick}
                    endIcon={button?.icon}
                    component={button?.type}
                    disabled={button?.disabled}
                  >
                    <Typography variant="body2" color="neutral.700">
                      {button?.title}
                    </Typography>
                    {/* Add file input for 'input' type buttons */}
                    {button?.type === 'input' && (
                      <VisuallyHiddenInput
                        type="file"
                        onChange={(event) => {
                          const file = event?.target?.files?.[0];
                          if (file) button?.onClick(event);
                        }}
                      />
                    )}
                  </StyledButton>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

const ExtensionRequest = ({
  data,
  selectedItem,
  onSelectItem,
  isShareRequest = false,
}) => {
  const { t } = useTranslation();
  const userRole = useSelector(getUserRole);
  const navigate = useNavigate();
  const location = useLocation();

  const onCancel = () => {
    const doesAnyHistoryEntryExist = location.key !== 'default';
    if (doesAnyHistoryEntryExist) navigate(-1);
    else navigate(PAGES[userRole].ROOT);
  };

  return (
    <>
      <Grid
        container
        flexDirection={'column'}
        sx={{
          minHeight: { sx: 400, md: 650 },
          maxHeight: { sx: 400, md: 650 },
          overflowY: 'auto',
        }}
        alignItems={'space-between'}
      >
        <Grid item xs>
          <ExtensionLetterDocs
            data={data}
            {...(selectedItem !== undefined && { selectedItem })}
            {...(onSelectItem !== undefined && { onSelectItem })}
            isShareRequest={isShareRequest}
          />
        </Grid>
      </Grid>
      <Grid container mt={1} justifyContent={'flex-end'} px={1}>
        <CustomButton
          variant="outlined"
          style={{ alignItems: 'flex-end', justifyContent: 'end' }}
          onClick={() => {
            onCancel();
          }}
        >
          {t('global.cancelTitle')}
        </CustomButton>
      </Grid>
    </>
  );
};

export default ExtensionRequest;
