import { SVGProps } from 'react';

const SignatureIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={30}
    fill="none"
    {...props}
  >
    <path
      fill="#010002"
      d="M13.767 24.542a.35.35 0 00.253.107c.225 0 .473-.193.665-.35l.881-.694c.124-.03.233-.069.33-.116l.134.086.246.164.366-.549-.248-.165c-.008-.006-.017-.011-.022-.017.104-.266.062-.574-.115-.875-.254-.428-.824-.861-1.391-.811-.433.036-.619.25-.7.424-.18.396.064.914.243 1.207a.476.476 0 00-.035.047 11.145 11.145 0 01-.264-.029c-.986-.124-1.176-.336-1.212-.398-.053-.094.041-.295.119-.473.12-.256.266-.573.092-.869-.178-.303-.579-.372-.91-.396-.673-.051-.979.213-1.116.447-.509.869.592 2.602.816 2.942l.549-.366c-.453-.682-1.018-1.863-.796-2.24.073-.129.312-.139.496-.125.26.021.358.066.378.057-.007.06-.067.187-.105.273-.133.289-.314.684-.097 1.071.206.373.709.597 1.619.72-.429.678-.306.802-.176.928zm.999-2.522s.033-.028.154-.039c.012-.002.021-.002.029-.002.239 0 .576.224.739.494a.672.672 0 01.076.196l-.048-.022-.438.345a1.986 1.986 0 01-.076.011l.021-.027-.146-.201c-.221-.299-.357-.671-.311-.755zm.231 3.644l-.539-.379c.043-.06 1.051-1.447 2.771-1.295l-.059.656c-1.354-.113-2.166 1.008-2.173 1.018zm2.6-.964l.082-.667.652.08-.082.668-.652-.081zm3.821-5.258l.814-.817v9.289H1.354V0h20.879v10.85l-1.316 1.318V1.317H2.671v25.279h18.246v-6.65l.293-.295.209-.211-.001.002zM16.191 3.296v1.319H7.399V3.296h8.792zm2.257 4.151H4.712v-.742h13.736v.742zm0 2.224H4.712v-.745h13.736v.745zm0 2.282H4.712v-.743h13.736v.743zm0 2.319H4.712v-.743h13.736v.743zm8.111-1.807l-1.166 1.163-2.487-2.494 1.167-1.163 2.486 2.494zm-3.92-1.06l2.488 2.492-5.661 5.656-2.489-2.492 5.662-5.656zm-3.875 8.852l-3.192.703.703-3.192.271-.271 2.49 2.49-.272.27z"
    ></path>
  </svg>
);

export default SignatureIcon;
