import { SVGProps } from 'react';
const Cancel = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#29306B"
      d="M10.072 4.8L8 6.872 5.928 4.8 4.8 5.928 6.872 8 4.8 10.072 5.928 11.2 8 9.128l2.072 2.072 1.128-1.128L9.128 8 11.2 5.928 10.072 4.8zM8 0C3.576 0 0 3.576 0 8c0 4.424 3.576 8 8 8 4.424 0 8-3.576 8-8 0-4.424-3.576-8-8-8zm0 14.4A6.408 6.408 0 011.6 8c0-3.528 2.872-6.4 6.4-6.4 3.528 0 6.4 2.872 6.4 6.4 0 3.528-2.872 6.4-6.4 6.4z"
    ></path>
  </svg>
);
export default Cancel;
