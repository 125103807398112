import {
  Accordion,
  AccordionSummary,
  Box,
  Grid,
  Typography,
  styled,
} from '@mui/material';
import CustomButton from 'components/atoms/Button';
import theme from 'theme';

export const StyledHeaderContainer = styled(Grid)`
  ${({ theme }) => ({
    marginTop: 20,
    padding: 10,
    height: 55,
    backgroundColor: theme.palette.info.light,
    justifyContent: 'space-between',
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    display: 'flex',
  })}
`;

export const StyledCustomButton = styled(CustomButton)`
  ${() => ({
    float: 'right',
    height: '2.5rem',
    display: 'flex',
    padding: '0px 8px',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
  })}
`;

export const StyledTypography = styled(Typography)`
  ${{
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '25px',
    marginLeft: 1,
    marginTop: 5,
  }}
`;

export const StyledBodyContainer = styled(Grid)`
  ${({ theme }) => ({
    backgroundColor: theme.palette.neutral[50],
    display: 'flex',
    height: '1664px',
    padding: '32px',
    [theme.breakpoints.down('md')]: {
      padding: '15px',
    },
    gap: '32px',
    flexShrink: 0,
    alignSelf: 'stretch',
  })}
`;

export const StyledSelectTitle = styled(Typography)`
  ${({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '25px',
    marginBottom: '15px',
    color: theme.palette.primary[800],
  })}
`;
export const CustomAccordion = styled(Accordion)`
  ${({ theme }) => ({
    marginBottom: '20px',
    borderRadius: '8px',
    border: `1px solid ${theme.palette.neutral[400]}`,
  })}
`;

export const CustomAccordionSummary = styled(AccordionSummary)`
  ${() => ({
    marginLeft: '30px',
    fontWeight: 'bold',
  })}
`;

export const StyledBox = styled(Box)`
  ${({ theme }) => ({
    borderRadius: '8px',
    border: `1px solid var(--neutral-neutral-4,${theme.palette.neutral['400']})`,
    padding: '2rem 2rem 2rem 2rem',
    width: 'auto',
    height: 'auto',
    marginBottom: '20px',
  })}
`;
