import { SVGProps } from 'react';

const VerifiedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 14"
    width={15}
    height={15}
    fill="none"
    {...props}
  >
    <path
      d="M18.707 7.293a1 1 0 0 0-1.414 0L11 13.586l-2.793-2.793a1 1 0 1 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l7-7a1 1 0 0 0 0-1.414z"
      fill="currentColor"
    />
  </svg>
);

export default VerifiedIcon;
