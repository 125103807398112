import styled from '@emotion/styled';
import CustomButton from '../Button';
import { Box, Theme } from '@mui/material';

import {
  NumericInputProps,
  StyledButtonProps,
  StyledContainerProps,
  StyledInputProps,
} from './types';

export const StyledInput = styled('input')<StyledInputProps>`
  width: 100%;
  text-align: center;

  border: none;
  outline: none;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  [type='number'] {
    -moz-appearance: textfield;
  }

  ${({ disabled, theme }) => {
    if (disabled) return { background: theme.palette.neutral[200] };
  }}
`;

const getButtonBorderColor = (
  isInputFocused: boolean,
  error: NumericInputProps['error'],
  theme: Theme
) => {
  if (error && !isInputFocused) return theme.palette.error.main;
  else if (error && isInputFocused) return theme.palette.error.main;
  else if (!error)
    return isInputFocused
      ? theme.palette.primary.main
      : theme.palette.neutral[500];
};

export const StyledIncrementButton = styled(CustomButton)<StyledButtonProps>`
  ${({ isInputFocused, error, theme }) => ({
    height: '50%',
    borderRadius: 0,
    zIndex: 10,
    cursor: 'pointer',
    borderWidth: 0,
    minWidth: 0,
    width: '100%',
    padding: 0,
    borderLeftWidth: isInputFocused || error ? 2 : 1,
    borderBottomWidth: isInputFocused || error ? 2 : 1,
    borderColor: getButtonBorderColor(isInputFocused, error, theme as Theme),
    ':hover': {
      borderWidth: 0,
      borderLeftWidth: isInputFocused || error ? 2 : 1,
      borderBottomWidth: isInputFocused || error ? 2 : 1,
      borderColor: getButtonBorderColor(isInputFocused, error, theme as Theme),
    },
  })}
`;

export const StyledDecrementButton = styled(CustomButton)<StyledButtonProps>`
  ${({ isInputFocused, error, theme }) => ({
    height: '50%',
    borderRadius: 0,
    zIndex: 10,
    minWidth: 0,
    width: '100%',
    cursor: 'pointer',
    borderWidth: 0,
    padding: 0,
    borderLeftWidth: isInputFocused || error ? 2 : 1,
    borderColor: getButtonBorderColor(isInputFocused, error, theme as Theme),
    ':hover': {
      borderWidth: 0,
      borderLeftWidth: isInputFocused || error ? 2 : 1,
      borderColor: getButtonBorderColor(isInputFocused, error, theme as Theme),
    },
  })}
`;

const getContainerOutline = (
  isInputFocused: boolean,
  error: NumericInputProps['error'],
  theme: Theme
) => {
  if (error && !isInputFocused) return `2px solid ${theme.palette.error.main}`;
  else if (error && isInputFocused)
    return `2px solid ${theme.palette.error.main}`;
  else if (!error)
    return `${isInputFocused ? '2px' : '1px'} solid ${
      isInputFocused ? '#29306B' : '#CED4DA'
    } `;
};

export const StyledContainer = styled(Box)<StyledContainerProps>`
  ${({ isInputFocused, error, theme }) => ({
    maxWidth: '100%',
    minWidth: 50,
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    background: theme.palette.neutral[200],
    outline: getContainerOutline(isInputFocused, error, theme as Theme),
  })}

  ${({ size }) => {
    if (size === 'small')
      return {
        height: 32,
      };
    else if (size === 'medium')
      return {
        height: 40,
      };
    else if (size === 'large')
      return {
        height: 48,
      };
  }}
`;

export const StyledInputContainer = styled(Box)`
  ${() => ({
    flex: 1,
    display: 'flex',
  })}
`;

export const StyledButtonsContainer = styled(Box)`
  ${() => ({
    width: 40,
    maxWidth: '30%',
    display: 'flex',
    flexDirection: 'column',
  })}
`;
