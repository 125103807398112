import {
  Box,
  FormControl,
  Grid,
  TableCell,
  Typography,
  styled,
} from '@mui/material';

export const StyledTypography = styled(Typography)`
  ${() => ({
    fontWeight: 500,
    marginBottom: '24px',
  })}
`;

export const StyledEllipsisContainer = styled(Box)`
inlineSize: '150px',
display: 'block',
whiteSpace: 'nowrap',
overflow: 'hidden',
textOverflow: 'ellipsis',
`;

export const StyledForm = styled('form')`
  width: 100%;
  height: 100%;
`;

export const StyledGridContainer = styled(Grid)`
  margin: 0;
  width: 100%;
  height: 100%;
  align-content: baseline;
  padding-left: 40px;
  padding-right: 40px;
  ${({ theme }) => {
    return {
      [theme.breakpoints.down('md')]: {
        paddingLeft: '15px',
        paddingRight: '15px',
      },
    };
  }}
`;

export const StyledFormControl = styled(FormControl)`
  ${() => ({
    width: '60%',
    maxWidth: 500,
  })}
`;

export const StyledBox = styled(Box)`
  background: transparent;
`;

export const StyledChosenIsolatesTitle = styled(Box)`
  ${() => ({
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '22px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#4F575E',
  })}
`;

export const StyledIsolateName = styled(Box)`
  ${() => ({
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '50px',
  })}
`;

export const StyledFarmDetails = styled(Box)`
  ${() => ({
    fontSize: '15px',
    fontWeight: '600',
    paddingLeft: '25px',
    paddingTop: '15px',
  })}
`;
export const StyledTableHeadCell = styled(TableCell)`
  padding-left: 0px;
  height: 24px;
`;
export const StyledTableHeadCellText = styled(Typography)`
  ${({ theme }) => {
    return {
      fontWeight: 600,
      color: theme.palette.neutral[700],
      inlineSize: '150px',
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      cursor: 'pointer',
      width: '100%',
    };
  }}
`;

export const StyledTableBodyCell = styled(TableCell)`
  padding-left: 0px;
  width: 125px;
  height: 10px !important;
  max-height: 10px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
`;

export const StyledTableBodyCellText = styled(Typography)`
  ${({ theme }) => {
    return {
      fontWeight: 400,
      color: theme.palette.neutral.main,
      inlineSize: '150px',
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
    };
  }}
`;
