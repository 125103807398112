import {
  Accordion,
  Box,
  Grid,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import CustomButton from 'components/atoms/Button';
import theme from 'theme';

export const StyledTableHeadCell = styled(TableCell)`
  padding-left: 0px;
`;
export const StyledBox = styled(Box)`
  ${({ theme }) => {
    return {
      display: 'flex',
      width: '238px',
      padding: '8px 16px',
      fontWeight: 600,
      fontSize: 12,
      backgroundColor: theme.palette.neutral[200],
      alignItems: 'center',
      lineHeight: '18px',
      borderRadius: '16px',
      justifyContent: 'space-between',
      color: theme.palette.primary[900],
    };
  }}
`;

export const StyledTableBodyCell = styled(TableCell)`
  padding-left: 0px;
  padding: 5px;
  width: 190px;
`;
export const StyledIsolateTableBodyCell = styled(TableCell)`
  padding-left: 0px;
  width: 100px;
`;

export const StyledTableHeadCellText = styled(Typography)`
  ${({ theme }) => {
    return {
      fontWeight: 600,
      fontSize: 14,
      color: theme.palette.neutral[700],
      inlineSize: '100px',
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      cursor: 'pointer',
      width: '100%',
    };
  }}
`;

export const StyledTableHeadCellTextWithoutEllipsis = styled(Typography)`
  ${({ theme }) => {
    return {
      fontWeight: 600,
      color: theme.palette.neutral[700],
    };
  }}
`;

export const StyledTableBodyCellText = styled(Typography)`
  ${({ theme }) => {
    return {
      fontWeight: 400,
      color: theme.palette.neutral.main,
      inlineSize: '100px',
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
    };
  }}
`;

export const StyledIsolateTableBodyCellText = styled(Typography)`
  ${({ theme }) => {
    return {
      fontWeight: 400,
      color: theme.palette.neutral.main,
      inlineSize: '100px',
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    };
  }}
`;

export const StyledTableContainer = styled(TableContainer)`
  ${({ theme }) => {
    return {
      width: 604,
      [theme.breakpoints.down('md')]: {
        maxWidth: 320,
      },
    };
  }}
`;
export const StyledOptionsGrid = styled(Grid)`
  ${({ theme }) => {
    return {
      width: '630px',
      [theme.breakpoints.down('md')]: {
        maxWidth: '95%',
      },
    };
  }}
`;

export const StyledAccordion = styled(Accordion)`
  ${({ theme }) => ({
    borderRadius: '8px',
    border: `1px solid ${theme.palette.neutral[400]}`,
    [theme.breakpoints.down('md')]: {
      maxWidth: '95%',
    },
  })}
`;

export const StyledTypography = styled(Typography)`
  ${({ theme }) => ({
    paddingLeft: '8px',
    fontWeight: 800,
    fontSize: 18,
    lineHeight: '22px',
    color: theme.palette.primary.main,
  })}
`;

export const StyledTitlePacketOrder = styled(Typography)`
  ${() => ({
    color: `${theme.palette.primary.main}`,
    fontFamily: 'Noto Sans',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '22px',
    display: 'flex',
    alignItems: 'flex-start',
    gap: '10px',
  })}
`;

export const StyledPacketOrder = styled(Grid)`
  display: flex;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;
export const LastTableRow = styled(TableRow)`
  ${() => ({
    borderBottom: '0px',
  })}
`;

export const StyledDownloadAllPacketsButton = styled(CustomButton)`
  ${({ theme }) => {
    return {
      height: '2rem',
      padding: '0.3% 14%',
      borderRadius: '5px',
      minWidth: '30rem',
      [theme.breakpoints.down('md')]: {
        width: '95%',
        minWidth: '5rem',
        padding: '0',
      },
    };
  }}
`;

export const StyledGrid = styled(Grid)`
  ${({ theme }) => {
    return {
      '& .MuiPaper-root': {
        height: '2rem !important',
        color: theme.palette.success[200],
        backgroundColor: theme.palette.info.light,
        fontSize: '0.75rem',
        fontWeight: '500',
        lineHeight: '1.125rem',
      },
    };
  }}
`;
