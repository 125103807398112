export const ORDER_FORM_STEP_NAMES = {
  FARM_INFO: 'farmInfo',
  INFECTION_INFO: 'infectionInfo',
  DOSING_AND_FILLING_INFO: 'dosingAndFillingInfo',
  BOTTLE_PRICING: 'bottlesPricingInfo',
  ADDITIONAL_INSTRUCTIONS_INFO: 'AdditionalInstructionsInfo',
} as const;

const FormStepKeys = {
  ORDER_MODE: 'orderMode',
  ID: 'id',
  HAS_AGREED_TO_TERMS: 'hasAgreedToTerms',
  CONFIG: 'config',
  USER: 'user',
  FARM_INFO: 'farmInfo',
  INFECTION_INFO: 'infectionInfo',
  DOSING_AND_FILLING_INFO: 'dosingAndFillingInfo',
  BOTTLES_PRICING_INFO: 'bottlesPricingInfo',
  ADDITIONAL_INSTRUCTIONS_INFO: 'AdditionalInstructionsInfo',
} as const;

const UserKeys = {
  TYPE: 'type',
} as const;

const FarmInfoKeys = {
  VETS: 'vets',
  NON_ADJACENT_REQUIRED: 'nonAdjacentRequired',
  FARM_ADDRESSES: 'farmAddresses',
  SHIPPING_SITES: 'shippingSites',
  SHIPPING_SITES_BY_STATES: 'ShippingAddressesByStates',
  BILLING_ADDRESSES: 'billingAddresses',
  CUSTOMER: 'customer',
} as const;

const InfectionInfoKeys = {
  ANIMAL_GROUPS: 'animalGroups',
  ANIMAL_SPECIES: 'animalSpecies',
  PRODUCTS: 'products',
  ISOLATES: 'isolates',
  ADJUVANTS: 'adjuvants',
  ADMINISTRATION_ROUTES: 'administrationRoutes',
  DOSES: 'doses',
  NUM_OF_ANIMAL: 'numOfAnimal',
  AUTHORIZED_ISOLATES: 'authorizedIsolates',
} as const;

const DosingAndFillingInfoKeys = {
  BOTTLES: 'bottles',
  BOTTLE_USAGE: 'bottleUsage',
} as const;

const BottlesPricingInfoKeys = {
  BOTTLES: 'bottles',
} as const;

const AdditionalInstructionsInfoKeys = {
  COLORS: 'colors',
  BOTTLE_TAG: 'bottleTag',
  VACCINATION_SCHEDULE: 'vaccinationSchedule',
  CLINICAL_SIGNS: 'clinicalSigns',
  INTERNAL_REFERENCE: 'internalReference',
  ADDITIONAL_COMMENT: 'additionalComment',
  REQUEST_LETTER_COMMENT: 'requestLetterComment',
} as const;

export const FIELD_ACTION_NAME = {
  RESET: 'reset',
  SET: 'set',
  DEP: 'dependency',
};

export {
  FormStepKeys,
  UserKeys,
  FarmInfoKeys,
  InfectionInfoKeys,
  DosingAndFillingInfoKeys,
  BottlesPricingInfoKeys,
  AdditionalInstructionsInfoKeys,
};

export const COUNTRY_CODE = {
  US: 'US',
  FR: 'FR',
};

export const USER = {
  VET: 'Vet',
  TM: 'TM',
} as const;

export const USER_ROLE = {
  VET: 'Vet',
  TM: 'TM',
  REGULATORY: 'Regulatory',
  REGULATORYMANAGER: 'RegulatoryManager',
  CSS: 'CSS',
} as const;

export const AUTH_METHODS = {
  MSAL: 'msal',
} as const;

export const LANGUAGE_CODES = {
  en: 'en',
  fr: 'fr',
};

export const LANGUAGE_NAMES = {
  English: 'EN',
  French: 'FR',
};

export const PAGES: any = {
  [USER_ROLE.VET]: {
    ROOT: '/',
    DASHBOARD: '/dashboard',
    ORDER_FORM: '/order-form',
    REORDER_FORM: '/reorder-form',
    EDIT_FORM: '/edit-form',
    ORDER_DETAILS: '/order-details',
    COMPLETE_DOC: '/complete-document',
    REVIEW_DOC: '/review-document',
    MANAGE_ADDRESSES: '/manage-addresses',
    ISOLATE_DETAILS: '/isolate-details',
    ACCOUNT_SETTINGS: '/account-settings',
    ISOLATE_EXTENSION: '/isolate-extension',
    SHARE_REQUEST: '/share-request',
    SHARE_REQUEST_DECLINE: '/share-request-decline',
    LEGAL_NOTICE: '/legal-notice',
    PRIVACY_POLICY: '/privacy-policy',
    TERMS_OF_SERVICE: '/terms-of-service',
    TERMS_OF_SALE: '/terms-of-sale',
    NOT_FOUND: '/not-found',
    SYSTEM_DOWN: '/system-shutdown',
    INTERNAL_SERVER_ERROR: '/internal-server-error',
  },
  [USER_ROLE.TM]: {
    ROOT: '/admin/dashboard',
    DASHBOARD: '/admin/dashboard',
    ORDER_FORM: '/admin/order-form',
    REORDER_FORM: '/admin/reorder-form',
    EDIT_FORM: '/admin/edit-form',
    ORDER_DETAILS: '/admin/order-details',
    ADMIN_DOCS: '/admin/docs',
    COMPLETE_DOC: '/admin/complete-document',
    REVIEW_DOC: '/admin/review-document',
    MANAGE_ADDRESSES: '/admin/manage-addresses',
    ISOLATE_DETAILS: '/admin/isolate-details',
    ISOLATE_EXTENSION: '/admin/isolate-extension',
    SHARE_REQUEST: '/admin/share-request',
    SHARE_REQUEST_DECLINE: '/admin/share-request-decline',
    LEGAL_NOTICE: '/admin/legal-notice',
    PRIVACY_POLICY: '/admin/privacy-policy',
    TERMS_OF_SERVICE: '/admin/terms-of-service',
    TERMS_OF_SALE: '/admin/terms-of-sale',
    SYSTEM_DOWN: '/admin/system-shutdown',
    NOT_FOUND: '/admin/not-found',
    INTERNAL_SERVER_ERROR: '/admin/internal-server-error',
  },
  [USER_ROLE.REGULATORY]: {
    ROOT: '/admin/dashboard',
    DASHBOARD: '/admin/dashboard',
    ORDER_DETAILS: '/admin/order-details',
    COMPLETE_DOC: '/admin/complete-document',
    REVIEW_DOC: '/admin/review-document',
    MANAGE_ADDRESSES: '/admin/manage-addresses',
    ISOLATE_DETAILS: '/admin/isolate-details',
    ISOLATE_EXTENSION_APPROVAL: '/admin/isolate-extension-approval',
    ISOLATE_SHARE_APPROVAL: '/admin/isolate-share-approval',
    LEGAL_NOTICE: '/admin/legal-notice',
    PRIVACY_POLICY: '/admin/privacy-policy',
    TERMS_OF_SERVICE: '/admin/terms-of-service',
    TERMS_OF_SALE: '/admin/terms-of-sale',
    NOT_FOUND: '/admin/not-found',
    SYSTEM_DOWN: '/admin/system-shutdown',
    INTERNAL_SERVER_ERROR: '/admin/internal-server-error',
  },
  [USER_ROLE.REGULATORYMANAGER]: {
    ROOT: '/admin/dashboard',
    DASHBOARD: '/admin/dashboard',
    ORDER_DETAILS: '/admin/order-details',
    COMPLETE_DOC: '/admin/complete-document',
    REVIEW_DOC: '/admin/review-document',
    MANAGE_ADDRESSES: '/admin/manage-addresses',
    ISOLATE_DETAILS: '/admin/isolate-details',
    ISOLATE_EXTENSION_APPROVAL: '/admin/isolate-extension-approval',
    ISOLATE_SHARE_APPROVAL: '/admin/isolate-share-approval',
    LEGAL_NOTICE: '/admin/legal-notice',
    PRIVACY_POLICY: '/admin/privacy-policy',
    TERMS_OF_SERVICE: '/admin/terms-of-service',
    TERMS_OF_SALE: '/admin/terms-of-sale',
    SYSTEM_DOWN: '/admin/system-shutdown',
    NOT_FOUND: '/admin/not-found',
    INTERNAL_SERVER_ERROR: '/admin/internal-server-error',
  },
  [USER_ROLE.CSS]: {
    ROOT: '/admin/dashboard',
    DASHBOARD: '/admin/dashboard',
    ORDER_DETAILS: '/admin/order-details',
    ADMIN_DOCS: '/admin/docs',
    COMPLETE_DOC: '/admin/complete-document',
    REVIEW_DOC: '/admin/review-document',
    MANAGE_ADDRESSES: '/admin/manage-addresses',
    ISOLATE_DETAILS: '/admin/isolate-details',
    ISOLATE_EXTENSION_APPROVAL: '/admin/isolate-extension-approval',
    ISOLATE_SHARE_APPROVAL: '/admin/isolate-share-approval',
    LEGAL_NOTICE: '/admin/legal-notice',
    PRIVACY_POLICY: '/admin/privacy-policy',
    TERMS_OF_SERVICE: '/admin/terms-of-service',
    TERMS_OF_SALE: '/admin/terms-of-sale',
    SYSTEM_DOWN: '/admin/system-shutdown',
    NOT_FOUND: '/admin/not-found',
    INTERNAL_SERVER_ERROR: '/admin/internal-server-error',
  },
} as const;

export const NESTED_PAGES = {
  [USER_ROLE.VET]: {
    ISOLATE_INVENTORY: 'isolate-inventory',
  },
  [USER_ROLE.TM]: {
    ISOLATE_INVENTORY: 'isolate-inventory',
  },
  [USER_ROLE.REGULATORY]: {
    ISOLATE_INVENTORY: 'isolate-inventory',
    DOCUMENT_CONFIGURATION: 'document-configuration',
  },
  [USER_ROLE.CSS]: {
    ISOLATE_INVENTORY: 'isolate-inventory',
  },
} as const;

export const DOCUMENT_TYPE = {
  PRESCRIPTION: 'Prescription',
};

export const I18NEXT_LANGUAGE_KEY = 'i18nextLng';

export const ORDER_STATUSES = {
  INITIATED: 'Initiated',
  SUBMITTED: 'Submitted',
  CONFIRMED: 'Confirmed',
  READY_FOR_APPROVAL: 'ReadyForApproval',
  APPROVED: 'Approved',
  CLOSED: 'Closed',
  CANCELLED: 'Cancelled',
};

export const LIGHT_STATUS_COLOR = {
  SENT: '#F8F9FA',
  DENIED: '#FBE7E7',
  APPROVED: '#E5F7F3',
  NONE: '#E5F7FD',
};

export const DARK_STATUS_COLOR = {
  SENT: '#986D0D',
  DENIED: '#830C0C',
  APPROVED: '#51810F',
};

export enum DocumentTypeEnum {
  Generic,
  Prescription,
  RequestLetter,
  Packet,
  Form113113,
  CoverLetter,
  PermissionRequest,
  StateVetRequestLetter,
  ProductLicense,
  CopyOfLabel,
}

export enum DocumentStatus {
  Sent,
  Denied,
  Approved,
  None,
}

export enum ContactType {
  Main,
  CC,
}

export enum DocumentOwner {
  Order,
  Isolate,
}

export enum DocumentTypeIdEnum {
  Packet = 3,
}

export const PacketStatus = {
  ApproveLetter: 'ApproveLetter',
  SignedPacket: 'SignedPacket',
};

export const ORDER_ADDRESSE_STATUS = {
  READY_FOR_REQUEST: 'Ready for request',
  UNDER_APPROVAL: 'Under approval',
  APPROVED: 'Approved',
};

export const ORDER_ADDRESSE_COLOR = {
  READY_FOR_REQUEST: '#006B52',
  UNDER_APPROVAL: '#174C24',
  APPROVED: '#51810F',
};

export const ISOLATE_DEROGATION_INFO = {
  bgColor: '#FFF7E7',
  textColor: '#986D0D',
  getName: (t) => t('orderForm.derogationName'),
};

export const ISOLATE_DEROGATION_MESSAGE_STYLES = {
  textColor: '#C08A10',
};

export const ISOLATE_BOOST_CONSTANTS = {
  maxBoostMessageTextColor: '#29306B',
  maxBoost: 2,
  maxBoostWarningTextColor: '#C08A10',
};
export const defaultDashboardPageSize = 5;

export const DASHBOARD_INITIAL_PAGE = 1;

export const POSITIONS = {
  BOTTOM_LEFT: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  BOTTOM_CENTER: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  BOTTOM_RIGHT: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  TOP_LEFT: {
    vertical: 'top',
    horizontal: 'left',
  },
  TOP_CENTER: {
    vertical: 'top',
    horizontal: 'center',
  },
  TOP_RIGHT: {
    vertical: 'top',
    horizontal: 'right',
  },
};

export const IsolateSortFields = {
  NAME: 'Name',
  REFERENCE: 'Reference',
  SEROTYPE: 'SeroType',
  EXPIRATION_DATE: 'ExpirationDate',
  ISOLATION_DATE: 'IsolationDate',
  STATUS: 'Status',
  ANIMAL_SPECIES_NAME: 'AnimalSpeciesName',
  ORIGIN_FARM_NAME: 'OriginFarmName',
} as const;

export const IsolateFieldTable = {
  ISOLATE_NAME: 'isolateName',

  STATUS: 'status',

  RECEPTION: 'reception',

  SEROTYPE: 'serotype',

  EXPIRATION: 'expiration',

  FARM_ORIGIN: 'farmOrigin',

  SPECIES: 'species',

  ACTIONS: 'actions',
};

export const SIGNAL_R_EVENT = {
  RECEIVED: {
    LOAD_TASKS: 'LoadTasks',
  },
  SEND: {
    GET_TASKS: 'GetTasks',
    ON_MARK_TASK_AS_READ: 'OnMarkTaskAsRead',
    ON_MARK_ALL_TASKS_AS_READ: 'OnMarkAllTasksAsRead',
  },
};

export const TOAST_DEFAULT_DURATION = 3000;

export const FILE_TYPE = {
  PDF: 'application/pdf',
};

export const PolicyPages = {
  LegalNotice: 'LegalNotice',
  PrivacyPolicy: 'PrivacyPolicy',
  TermsOfService: 'TermsOfService',
  TermsOfSale: 'TermsOfSale',
};
