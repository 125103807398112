import { Button, Theme, styled } from '@mui/material';
import { CustomButtonProps } from './types';

const getVariantStyle = (theme: Theme, variant: string | undefined) => {
  if (variant === 'outlined') {
    return {
      color: theme.palette.primary['900'],
      borderColor: theme.palette.primary['900'],
      '&:hover': {
        background: theme.palette.neutral[50],
        outline: `1px solid ${theme.palette.primary['900']}`,
        boxShadow: 'inset 0px 250px 0px rgba(16, 18, 19, 0.1)',
      },
      '&:active': {
        background: theme.palette.neutral[50],
        outline: `1px solid ${theme.palette.primary['900']}`,
        boxShadow: 'inset 0px 250px 0px rgba(16, 18, 19, 0.22)',
      },
      '&:focused': {
        background: theme.palette.neutral[50],
        outline: `1px solid ${theme.palette.primary['900']}`,
        boxShadow: '0px 0px 0px 2px #90c2fc',
      },
      '&:disabled': {
        background: `${theme.palette.neutral[200]}`,
        outline: `1px solid ${theme.palette.neutral[400]}`,
        color: theme.palette.neutral[600],
      },
    };
  } else if (variant === 'contained') {
    return {
      border: `1px solid ${theme.palette.primary['900']}`,
      '&:hover': {
        background: theme.palette.primary['900'],
        boxShadow: 'inset 0px 250px 0px rgba(16, 18, 19, 0.1)',
      },
      '&:active': {
        background: theme.palette.primary['900'],
        boxShadow: 'inset 0px 250px 0px rgba(16, 18, 19, 0.22)',
      },
      '&:focus': {
        background: theme.palette.primary['900'],
        boxShadow: '0px 0px 0px 2px #90c2fc',
        outline: '#90c2fc',
      },
      '&:disabled': {
        background: theme.palette.neutral[200],
        border: `1px solid ${theme.palette.neutral[400]}`,
        color: theme.palette.neutral[600],
      },
    };
  }
};

const StyledButton = styled(Button)<CustomButtonProps>`
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  text-transform: none;

  ${({ variant, theme }) => {
    return getVariantStyle(theme, variant);
  }};

  ${({ size }) => {
    const communStyle = {
      fontStyle: 'normal',
      fontWeight: 500,
    };
    if (size === 'small') {
      return {
        ...communStyle,
        paddingBlock: '6px',
        fontSize: '12px',
        lineHeight: '18px',
        borderRadius: '4px',
      };
    } else if (size === 'medium') {
      return {
        ...communStyle,
        paddingBlock: '10px',
        fontSize: '14px',
        lineHeight: '21px',
        borderRadius: '8px',
      };
    } else if (size === 'large') {
      return {
        ...communStyle,
        paddingBlock: '12px',
        fontSize: '16px',
        lineHeight: '24px',
        borderRadius: '8px',
      };
    }
  }}
` as React.FC<CustomButtonProps>;

export default StyledButton;
