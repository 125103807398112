import { SVGProps } from 'react';
const ExportIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="24"
    fill="none"
    viewBox="0 0 10 24"
    {...props}
  >
    <path
      fill="#29306B"
      d="M7.1.8L3.95 3.95h2.363v7.087h1.575V3.95h2.363m1.575 14.175h-9.45A1.575 1.575 0 01.8 16.55V7.1a1.575 1.575 0 011.575-1.575h2.362V7.1H2.376v9.45h9.45V7.1H9.463V5.525h2.363A1.575 1.575 0 0113.4 7.1v9.45a1.575 1.575 0 01-1.575 1.575z"
    ></path>
  </svg>
);
export default ExportIcon;
