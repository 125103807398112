import { Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import { StyledAppBar, StyledTabs, StyledTab } from './styles';
import { ScrollableTabsButtonProps } from './types';
import theme from 'theme';

const TabsRendering: React.FC<ScrollableTabsButtonProps> = ({
  tabs,
  activeTab,
  setActiveTab,
  canSwitchTab = true,
}) => {
  const [localActiveTab, setLocalActiveTab] = useState(0);

  const handleChange = (event, newValue) => {
    if (!canSwitchTab) return;
    if (setActiveTab) {
      setActiveTab(newValue);
    } else {
      setLocalActiveTab(newValue);
    }
  };

  const value = typeof activeTab === 'number' ? activeTab : localActiveTab;

  return (
    <Grid container>
      <Grid item xs={12} sx={{ mr: 0, ml: 0 }}>
        <StyledAppBar
          position="static"
          sx={{
            backgroundColor: theme.palette.neutral[50],
            boxShadow: 0,
            flexDirection: 'row',
            alignItems: 'center',
            textAlign: 'center',
            [theme.breakpoints.up('sm')]: {
              width: '100%',
            },
          }}
        >
          <Box overflow="hidden">
            <StyledTabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="standard"
              aria-label="scrollable auto tabs example"
              allowScrollButtonsMobile
              sx={{ mr: 6 }}
            >
              {tabs.map((tab, index) => (
                <StyledTab key={tab?.label} label={tab.label} value={index} />
              ))}
            </StyledTabs>
          </Box>
        </StyledAppBar>
      </Grid>
      <Grid item xs={12}>
        {tabs[value].tabComponent}
      </Grid>
    </Grid>
  );
};

export default TabsRendering;
