import { useState } from 'react';

const usePagination = (data, itemsPerPage) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [currentData, setCurrentData] = useState([]);

  const totalPages = Math.ceil(data?.length / itemsPerPage);

  const goToPage = (page) => {
    const validatedPage = Math.max(0, Math.min(page, totalPages - 1));
    setCurrentPage(validatedPage);

    const startIndex = validatedPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const slicedData = data?.slice(startIndex, endIndex);
    setCurrentData(slicedData);
  };

  return {
    currentPage,
    currentData,
    totalPages,
    goToPage,
  };
};

export default usePagination;
