import { Grid, Typography } from '@mui/material';
import withLayout from 'layout';
import ReviewDocsIsolatesRequests from 'components/organisms/ReviewDocsForIsolatesExtension';
import PageHeader from 'components/molecules/PageHeader';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetIsolateShareRequestForRegQuery } from 'api/isolate';
import BackdropLoader from 'components/atoms/BackdropLoader';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getUserRole } from 'store/features/auth/index.selector';
import { PAGES } from 'constants/index';

const Header = () => (
  <Grid
    item
    container
    bgcolor={'info.light'}
    alignItems={'center'}
    py={2}
    px={2}
  >
    <Grid item>
      <Typography variant="h6" color="primary">
        Isolate Share Request :
      </Typography>
    </Grid>
  </Grid>
);
const Content = ({ children }) => (
  <Grid
    item
    container
    maxHeight={{ xs: '100vh', md: 850 }}
    p={{ xs: 4, md: 8 }}
    spacing={{ xs: 2, md: 5 }}
  >
    {children}
  </Grid>
);

const IsolatesShareApproval = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const userRole = useSelector(getUserRole);
  const navigate = useNavigate();

  const {
    data: extensionData,
    isLoading: extensionDataIsLoading,
    isFetching: extensionDataIsFetching,
  } = useGetIsolateShareRequestForRegQuery(
    { id: Number(id) },
    { skip: !Number(id) }
  );

  useEffect(() => {
    if (isNaN(Number(id))) navigate(PAGES[userRole].NOT_FOUND);
  }, [id]);

  return (
    <Grid container>
      <PageHeader title={t('docs.documentsView')} />
      <Grid item container bgcolor={'white'} direction={'column'}>
        <Header />
        <Content>
          <ReviewDocsIsolatesRequests
            data={extensionData}
            isShareRequest={true}
          />
        </Content>
      </Grid>
      <BackdropLoader
        open={extensionDataIsLoading || extensionDataIsFetching}
      />
    </Grid>
  );
};

export default withLayout(IsolatesShareApproval);
