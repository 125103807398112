import { SVGProps } from 'react';

const ExpendIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="10"
    fill="none"
    viewBox="0 0 18 10"
    {...props}
  >
    <path
      fill="#29306B"
      d="M16.875 2.219L9.75 8.922A1.073 1.073 0 019 9.25c-.281 0-.563-.094-.797-.281l-7.125-6.75C.61 1.797.61 1.094 1.031.625 1.453.156 2.156.156 2.625.578l6.375 6 6.328-6c.469-.422 1.172-.422 1.594.047.422.469.422 1.172-.047 1.594z"
    ></path>
  </svg>
);
export default ExpendIcon;
