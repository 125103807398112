import { LinearProgress, styled } from '@mui/material';

export const StyledProgress = styled(LinearProgress)(({ theme }) => ({
  margin: theme.spacing(2),
  position: 'absolute',
  left: '25%',
  right: '25%',
  top: '40%',
  bottom: '0',
  width: '50%',
}));
