import { Box, styled } from '@mui/material';

export const StyledFormHeaderContainer = styled(Box)`
  ${({ theme }) => ({
    height: 44,
    backgroundColor: theme.palette.info.light,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  })}
`;
