import withLayout from 'layout';
import { useEffect, useMemo } from 'react';
import { useLazyIsolateExtensionIsolatesQuery } from 'api/isolate';
import { useCustomNavigationLogic } from 'hooks/useCustomNavigationLogic';
import IsolateExtensionOrganism from 'components/organisms/IsolateExtension';

const IsolateExtension = () => {
  const { getParamsValues, paramsValues, location } = useCustomNavigationLogic({
    queryParams: ['isolate_ids'],
  });
  const [
    extendIsolates,
    {
      isLoading: extendIsolatesIsLoading,
      isFetching: extendIsolatesIsFetching,
    },
  ] = useLazyIsolateExtensionIsolatesQuery();

  const isolateIds = useMemo(
    () => (paramsValues as any)?.isolate_ids?.split(',') || null,
    [paramsValues]
  );

  useEffect(() => {
    getParamsValues();
  }, [location]);

  useEffect(() => {
    if (isolateIds)
      extendIsolates({
        isolatesIds: isolateIds,
      });
  }, [isolateIds]);

  return (
    <IsolateExtensionOrganism
      isolateIds={isolateIds}
      extendIsolatesIsLoading={
        extendIsolatesIsLoading || extendIsolatesIsFetching
      }
    />
  );
};

export default withLayout(IsolateExtension);
