import { RootState } from 'store';

export const getStateFormConfig = (state: RootState) => {
  return state?.stateConfigFormReducer?.templates?.value;
};

export const getStateFormStateName = (state: RootState) => {
  return state?.stateConfigFormReducer?.templates?.value?.name;
};

export const getStateFormStateId = (state: RootState) => {
  return state?.stateConfigFormReducer?.templates?.value?.id;
};

export const getStateFormAnimalGroups = (state: RootState) => {
  return state?.stateConfigFormReducer?.templates?.value?.animalGroups;
};

export const getStateFormDocumentTypes = (state: RootState) => {
  return state?.stateConfigFormReducer?.templates?.value?.documentTypes;
};

export const getIsEditBySpecies = (state: RootState) => {
  return state?.stateConfigFormReducer?.templates?.value
    ?.editBySpecies as boolean;
};

export const getStateFormContacts = (state: RootState) => {
  return state?.stateConfigFormReducer?.templates?.value?.contacts;
};

export const getStateFormNotes = (state: RootState) => {
  return state?.stateConfigFormReducer?.templates?.value?.notes;
};

export const getStateFormDocuSignEnabled = (state: RootState) => {
  return state?.stateConfigFormReducer?.templates?.value?.docuSignEnabled;
};
