import React from 'react';
import { Stack, Skeleton } from '@mui/material';

const LoaderSkelton: React.FC = () => {
  return (
    <Stack spacing={1} width="100%">
      <Skeleton animation="wave" variant="rounded" width="20%" height={30} />
      <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
      <Skeleton animation="wave" variant="rounded" height={70} />
      <Skeleton animation="wave" variant="rounded" height={40} />
    </Stack>
  );
};

export default LoaderSkelton;
