import React, { useState } from 'react';
import {
  StyledButtonsContainer,
  StyledContainer,
  StyledDecrementButton,
  StyledIncrementButton,
  StyledInput,
  StyledInputContainer,
} from './styles';
import { SmallArrowUpIcon } from 'components/icons';
import SmallArrowDown from 'components/icons/SmallArrowDown';
import { NumericInputProps } from './types';
import { InputLabel, Tooltip, Typography, useTheme } from '@mui/material';

const NumericInput: React.FC<NumericInputProps> = ({
  value,
  onValueChange,
  onIncrement,
  onDecrement,
  size = 'medium',
  error,
  errorMessage,
  disabled,
  label = '',
  ...props
}) => {
  const theme = useTheme();
  const [isInputFocused, setIsInputFocused] = useState(false);

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
  };

  // Handle change of the input field
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    if (value.startsWith('0')) {
      value = value.replace(/^0+/, '');
    }
    onValueChange?.(Number(value)); // Convert to number and call the handler
  };

  return (
    <>
      <InputLabel id="custom-number-input-label">
        <Tooltip placement="top" title={label}>
          <Typography
            variant="body1"
            color={theme.palette.neutral[800]}
            sx={{ mb: 0.5 }}
            {...props?.labelProps}
          >
            {label}
          </Typography>
        </Tooltip>
      </InputLabel>
      <StyledContainer
        isInputFocused={isInputFocused}
        size={size}
        error={error}
        disabled={disabled}
        theme={theme}
        {...props}
      >
        <StyledInputContainer>
          <StyledInput
            disabled={disabled}
            pattern={'d+'}
            value={value}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            theme={theme}
          />
        </StyledInputContainer>
        <StyledButtonsContainer>
          <StyledIncrementButton
            data-testid="increment-button"
            disabled={disabled}
            error={error}
            onClick={onIncrement}
            variant="outlined"
            color="neutral"
            isInputFocused={isInputFocused}
          >
            <SmallArrowUpIcon />
          </StyledIncrementButton>
          <StyledDecrementButton
            data-testid="decrement-button"
            disabled={disabled}
            error={error}
            onClick={onDecrement}
            variant="outlined"
            color="neutral"
            isInputFocused={isInputFocused}
          >
            <SmallArrowDown />
          </StyledDecrementButton>
        </StyledButtonsContainer>
      </StyledContainer>
      {error && (
        <Typography variant="body2" color="error" sx={{ my: 1 }}>
          {errorMessage}
        </Typography>
      )}
    </>
  );
};

export default NumericInput;
