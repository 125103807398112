import styled from '@emotion/styled';
import {
  Box,
  Grid,
  TableCell,
  TextareaAutosize,
  Typography,
} from '@mui/material';

export const StyledTitleTextarea = styled(Typography)`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
`;

export const StyledTextarea = styled(TextareaAutosize)`
  width: 100%;
  min-height: 100px;
  padding: 8px;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
`;

export const StyledTableCell = styled(TableCell)`
  cursor: 'pointer';
`;

export const StyledEllipsisContainer = styled(Box)`
  inlineSize: '80px',
  display: 'block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
`;

export const StyledCard = styled(Grid)<{ theme: any }>`
  ${({ theme }) => {
    return {
      borderRadius: '8px',
      boxShadow:
        '0px 7px 14px 0px rgba(65, 69, 88, 0.1), 0px 3px 6px 0px rgba(0, 0, 0, 0.07)',
      background: theme.palette.neutral[50],
    };
  }};
`;

export const StyledRequestLetterContent = styled(Grid)<{ theme: any }>`
  ${({ theme }) => {
    return {
      height: '100%',
      borderRadius: '8px',
      borderColor: theme.palette.neutral[400],
      borderStyle: 'solid',
      padding: 16,
      borderWidth: '1px',
    };
  }};
`;
