import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStateConfig } from './index.type';
import docApi, {
  Contact,
  DocumentType,
  Information,
  Note,
  StateConfig,
} from 'api/docs';

const initialState: IStateConfig = {
  templates: { value: null, data: null },
};

export const stateConfigFormSlice = createSlice({
  name: 'stateConfigData',
  initialState,
  reducers: {
    toggleEditBySpecies: (state, action: PayloadAction<boolean>) => {
      const editBySpecies = action.payload;
      state.templates.value.editBySpecies = editBySpecies;
      const documentTypes = state?.templates?.data?.documentTypes;
      const animalGroups = state?.templates?.data?.animalGroups;
      const contacts = state?.templates?.data?.contacts;
      const notes = state?.templates?.data?.notes;

      const oldEditBySpecies = state?.templates?.data?.editBySpecies;

      if (editBySpecies) {
        if (oldEditBySpecies) {
          state.templates.value = state?.templates?.data;
        } else {
          const updatedConfig = [];
          const updatedContacts = [];
          const updatedNotes = [];

          animalGroups?.forEach((group) => {
            documentTypes?.forEach((template) => {
              const updatedTemplate = {
                ...template,
                animalGroupId: group?.id,
              };

              updatedConfig.push(updatedTemplate);

              if (template?.information) {
                const updatedInformation = template?.information?.map(
                  (info) => ({
                    ...info,
                    animalGroupId: group?.id,
                  })
                );

                updatedTemplate.information = updatedInformation;
              }
            });

            contacts?.forEach((contact) => {
              const updatedContact = {
                ...contact,
                animalGroupId: group?.id,
              };

              updatedContacts.push(updatedContact);
            });

            notes?.forEach((note) => {
              const updatedNote = {
                ...note,
                animalGroupId: group?.id,
              };

              updatedNotes.push(updatedNote);
            });
          });

          state.templates.value.documentTypes = updatedConfig;
          state.templates.value.contacts = updatedContacts;
          state.templates.value.notes = updatedNotes;
        }
      } else if (oldEditBySpecies) {
        const data = state?.templates?.data;

        state.templates.value = state?.templates?.data;
        state.templates.value.editBySpecies = action.payload;

        const firstAnimalGroupId = data?.documentTypes[0]?.animalGroupId;
        const filteredDocumentTypes = data?.documentTypes.filter(
          (item) => item.animalGroupId === firstAnimalGroupId
        );

        const updatedDocumentTypes = filteredDocumentTypes.map((item) => ({
          ...item,
          animalGroupId: null,
        }));

        state.templates.value.documentTypes = updatedDocumentTypes;
        state.templates.value.contacts = [];
        state.templates.value.notes = [];
      } else {
        state.templates.value = state?.templates?.data;
      }
    },
    toggleTemplate: (state, action: PayloadAction<any>) => {
      const payload = action.payload;

      if ('information' in payload) {
        const template = payload as DocumentType;

        const updatedConfig = state?.templates?.value?.documentTypes?.map(
          (docType) => {
            if (
              docType.id === template?.id &&
              docType.animalGroupId === template?.animalGroupId
            ) {
              const isRequired = !docType?.isRequired;
              if (docType?.information.length > 0 && docType?.isRequired) {
                const updatedInformation = docType?.information?.map(
                  (info) => ({
                    ...info,
                    isRequired: false,
                  })
                );
                return {
                  ...docType,
                  isRequired,
                  information: updatedInformation,
                };
              }
              return {
                ...docType,
                isRequired,
              };
            }
            return docType;
          }
        );

        state.templates.value.documentTypes = updatedConfig;
      } else {
        const information = payload as Information;

        const updatedConfig = state.templates.value.documentTypes.map(
          (docType) => {
            const updatedInformation = docType.information.map((info) => {
              if (
                info.id === information.id &&
                info.animalGroupId === information.animalGroupId
              ) {
                return {
                  ...info,
                  isRequired: !info.isRequired,
                };
              }
              return info;
            });

            // Check if at least one information item in the document type is required
            const isAnyInformationRequired = updatedInformation.some(
              (info) => info.isRequired
            );

            // If any information item is required, set the document type as required
            if (isAnyInformationRequired) {
              return {
                ...docType,
                isRequired: true,
                information: updatedInformation,
              };
            }

            return {
              ...docType,
              information: updatedInformation,
            };
          }
        );

        state.templates.value.documentTypes = updatedConfig;
      }
    },
    setTemplatesData: (state, action: PayloadAction<StateConfig>) => {
      const stateConfigData = action.payload;
      state.templates.value = stateConfigData;
    },
    setFormContacts: (state, action: PayloadAction<Contact[]>) => {
      const stateContactsData = action.payload;
      state.templates.value.contacts = stateContactsData;
    },
    setFormNotes: (state, action: PayloadAction<Note[]>) => {
      const stateNotsData = action.payload;
      state.templates.value.notes = stateNotsData;
    },
    toggleDocuSign: (state, action: PayloadAction<boolean>) => {
      const docuSignEnabled = action.payload;
      state.templates.value.docuSignEnabled = docuSignEnabled;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      docApi.endpoints.getStateConfig.matchFulfilled,
      (state, action) => {
        const data = action.payload;
        state.templates.data = data;
        stateConfigFormSlice.caseReducers.setTemplatesData(state, {
          payload: data,
          type: '',
        });
      }
    );
  },
});

export const {
  toggleTemplate,
  setTemplatesData,
  toggleEditBySpecies,
  setFormNotes,
  setFormContacts,
  toggleDocuSign,
} = stateConfigFormSlice.actions;

export default stateConfigFormSlice.reducer;
