import React, { useEffect, useState } from 'react';
import withLayout from 'layout';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useGetOrderConfigQuery } from 'api/orderForm';
import { ModeState } from 'store/features/orderForm/index.type';
import FormHeader from 'components/organisms/FormHeader';
import FormContent from 'components/organisms/FormContent';
import { clearOrderForm, setMode } from 'store/features/orderForm/index.slice';
import { getUserAccount } from 'store/features/auth/index.selector';
import BackdropLoader from 'components/atoms/BackdropLoader';

const OrderForm: React.FC = () => {
  const [, setActiveStep] = useState(0);
  const user = useSelector(getUserAccount);
  const { isLoading } = useGetOrderConfigQuery(
    { userId: user?.id, role: user?.role },
    { refetchOnMountOrArgChange: true, skip: !(user?.id && user?.role) }
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setMode(ModeState.ORDER));
    dispatch(clearOrderForm());
  }, []);

  return (
    <Grid container justifyContent="center" height={'100%'} width={'100%'}>
      <Grid
        item
        m={{ xs: '0px', md: '60px' }}
        sx={{ width: 960, minWidth: 200 }}
      >
        <FormHeader />
        <FormContent setActiveStep={setActiveStep} />
        <BackdropLoader open={isLoading} />
      </Grid>
    </Grid>
  );
};

export default withLayout(OrderForm);
