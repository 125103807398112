import { Theme } from '@mui/material';
import { SVGProps } from 'react';

const SortArrowUp = (
  props: SVGProps<SVGSVGElement> & { show?: boolean; theme: Theme }
) => (
  <svg
    aria-hidden="true"
    className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall MuiDataGrid-sortIcon css-ptiqhd-MuiSvgIcon-root"
    data-testid="ArrowUpwardIcon"
    viewBox="0 0 24 24"
    style={{
      opacity: props?.show ? 1 : 0,
      fill: props?.theme?.palette?.primary?.main,
    }}
    {...props}
  >
    <path d="m4 12 1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z" />
  </svg>
);
export default SortArrowUp;
