import { SVGProps } from 'react';

const DisabledCheckBox = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <rect width={23} height={23} x={0.5} y={0.5} fill="#F1F3F5" rx={3.5} />
    <rect width={23} height={23} x={0.5} y={0.5} stroke="#E9ECEF" rx={3.5} />
  </svg>
);

export default DisabledCheckBox;
