import React, { useEffect, useMemo } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import withLayout from 'layout';
import { useParams, useSearchParams } from 'react-router-dom';
import ReviewPacket from 'components/organisms/ReviewPacket';
import { ContactType } from 'constants/index';
import { useGetOrderStateDocsQuery } from 'api/docs';
import usePdfDoc from 'hooks/useDocMerge';
import useToast from 'hooks/useToast';
import PageHeader from 'components/molecules/PageHeader';

const Content = ({ children }) => (
  <Grid
    item
    container
    p={{ xs: 4, md: 8 }}
    spacing={{ xs: 2, md: 5 }}
    mb={{ xs: 4, md: 4 }}
  >
    {children}
  </Grid>
);

const ReviewDocument: React.FC = () => {
  const [searchParams] = useSearchParams();
  const stateIdParam = useMemo(() => searchParams.get('stateId'), []);
  const { toast } = useToast();
  const { t } = useTranslation();
  const { id: urlOrderId } = useParams<{ id: string }>();
  const { data: orderStateDocs } = useGetOrderStateDocsQuery(
    { orderId: urlOrderId },
    {
      skip: !urlOrderId,
    }
  );
  const stateIndexByUrlStateId = (array: Array<any>, id: string) => {
    const parsedId = parseInt(id, 10);
    if (isNaN(parsedId)) {
      return 0;
    }
    return array?.findIndex((item) => item.id === parsedId);
  };
  const orderStateValue = useMemo(() => {
    return {
      data: orderStateDocs?.states[
        stateIndexByUrlStateId(orderStateDocs?.states, stateIdParam)
      ],
      index: stateIndexByUrlStateId(orderStateDocs?.states, stateIdParam),
    };
  }, []);

  const { isError: viewPdfIsError } = usePdfDoc();

  const mails = useMemo(() => {
    const contacts = orderStateValue?.data?.contacts;
    const mainContacts = [];
    const ccContacts = [];

    contacts?.forEach((contact) => {
      if (contact.type === ContactType.Main) mainContacts.push(contact);
      else if (contact.type === ContactType.CC) ccContacts.push(contact);
    });

    return { main: mainContacts, cc: ccContacts };
  }, [orderStateValue?.data]);

  const getPdfUrls = () => {
    const urls = [];
    urls.push(
      `${process.env.REACT_APP_API_BASE_URL}api/OrderDocument/mergeOrderDocuments?orderId=${urlOrderId}&stateId=${stateIdParam}`
    );
    return urls;
  };

  const pdfUrls = useMemo(() => {
    return getPdfUrls();
  }, [orderStateValue?.data, orderStateDocs]);

  const content = useMemo(() => {
    return (
      <ReviewPacket
        pdfUrls={pdfUrls}
        mails={mails}
        stateData={{
          stateName: orderStateValue?.data?.name,
          stateId: orderStateValue?.data?.id,
          docuSignEnabled: orderStateValue?.data?.docuSignEnabled,
        }}
        orderId={urlOrderId}
      />
    );
  }, [orderStateValue?.data]);

  useEffect(() => {
    if (viewPdfIsError) {
      toast.error(t('messages.viewFileFail'), {});
    }
  }, [viewPdfIsError]);

  return (
    <Grid container>
      <PageHeader title={t('docs.completeDoc')} />
      <Grid item container bgcolor={'white'} direction={'column'}>
        <Content>{content}</Content>
      </Grid>
    </Grid>
  );
};

export default withLayout(ReviewDocument);
