import { SVGProps } from 'react';

const AddressIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="24"
    fill="none"
    viewBox="0 0 18 24"
    {...props}
  >
    <path
      fill="#29306B"
      d="M4.125 4.875c0-.375.328-.75.75-.75h2.25c.375 0 .75.375.75.75v2.25c0 .422-.375.75-.75.75h-2.25a.74.74 0 01-.75-.75v-2.25zm9-.75c.375 0 .75.375.75.75v2.25c0 .422-.375.75-.75.75h-2.25a.74.74 0 01-.75-.75v-2.25c0-.375.328-.75.75-.75h2.25zm-9 6.75c0-.375.328-.75.75-.75h2.25c.375 0 .75.375.75.75v2.25c0 .422-.375.75-.75.75h-2.25a.74.74 0 01-.75-.75v-2.25zm9-.75c.375 0 .75.375.75.75v2.25c0 .422-.375.75-.75.75h-2.25a.74.74 0 01-.75-.75v-2.25c0-.375.328-.75.75-.75h2.25zM0 3c0-1.64 1.313-3 3-3h12c1.64 0 3 1.36 3 3v18c0 1.688-1.36 3-3 3H3c-1.688 0-3-1.313-3-3V3zm2.25 0v18c0 .422.328.75.75.75h3.75v-3A2.25 2.25 0 019 16.5c1.219 0 2.25 1.031 2.25 2.25v3H15c.375 0 .75-.328.75-.75V3c0-.375-.375-.75-.75-.75H3c-.422 0-.75.375-.75.75z"
    ></path>
  </svg>
);
export default AddressIcon;
