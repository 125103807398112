import React, { useState } from 'react';
import { MenuItem, Box, Typography, Tooltip, useTheme } from '@mui/material';
import { CloseIcon } from 'components/icons';
import ActiveCheckBox from 'components/icons/ActiveCheckBox';
import NormalCheckBox from 'components/icons/NormalCheckBox';
import CustomInput from '../Input';
import {
  StyledChip,
  StyledErrorText,
  StyledInputLabel,
  StyledSelect,
  listContainerStyle,
} from './styles';
import {
  CustomSelectProps,
  Option,
  RenderCheckboxWithLabelProps,
  RenderCustomOptionProps,
  RenderLabelProps,
  SearchBoxProps,
} from './types';

const menuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  PaperProps: {
    sx: {
      ...listContainerStyle,
    },
  },
};

const SearchBox: React.FC<SearchBoxProps> = ({
  searchEnabled,
  searchText,
  setSearchText,
}) => {
  return (
    <>
      {searchEnabled && (
        <Box sx={{ padding: '6px 16px' }}>
          <CustomInput
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            placeholder="Search"
            size="small"
            autoFocus
            fullWidth
            sx={{ marginBottom: 1, zIndex: 999 }}
          />
        </Box>
      )}
    </>
  );
};

const RenderCheckboxWithLabel: React.FC<RenderCheckboxWithLabelProps> = ({
  shouldRender,
  value,
  option,
}) =>
  shouldRender && (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box marginRight={1}>
        {(value as any)?.some((item) => item.value === option.value) ? (
          <ActiveCheckBox
            style={{
              width: '100%',
              paddingTop: '10px',
              paddingLeft: '7px',
              paddingRight: '5px',
            }}
          />
        ) : (
          <NormalCheckBox />
        )}
      </Box>
      <Box>{option.label}</Box>
    </Box>
  );

const RenderCustomOption: React.FC<RenderCustomOptionProps> = ({
  shouldRender,
  option,
  renderOption,
}) => shouldRender && <>{renderOption?.(option)}</>;

const RenderLabel: React.FC<RenderLabelProps> = ({ shouldRender, option }) =>
  shouldRender && <>{option?.label}</>;

const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  multiple = false,
  value,
  onChange,
  searchEnabled = false,
  withCheckbox = false,
  withCustomOption = false,
  errorMessage,
  size = 'medium',
  renderOption,
  label = '',
  error,
  labelColor,
  chipBgColor,
  deleteIconHidden,
  keepOpen = false,
  ...props
}) => {
  const [searchText, setSearchText] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();

  function dropAndAddToOptions(selectValue: Option) {
    const matchingOption = (value as Option[])?.find(
      (option) => option?.value === selectValue?.value
    );

    const updatedOptions = (value as Option[])?.filter(
      (option) => option?.value !== selectValue?.value
    );

    const label = options?.find(
      (option) => option.value === selectValue?.value
    )?.label;

    if (!matchingOption) {
      updatedOptions.push({
        label: label,
        value: selectValue?.value,
        ...selectValue,
      });
    }

    return updatedOptions;
  }

  const handleChange = (event: any, selectValue: Option) => {
    if (!multiple) {
      onChange(event, selectValue);
    } else {
      const selectedOptions = dropAndAddToOptions(selectValue);
      onChange(event, selectedOptions);
    }
    if (!keepOpen) handleClose();
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleDelete = (chipOption: Option) => {
    const data = (value as Option[])?.filter(
      (option) => option?.value !== chipOption?.value
    );
    if (data) {
      onChange({ target: { name: props?.name } }, data as any);
      handleClose();
    }
  };

  const filteredOptions = options?.filter((option) =>
    option?.label?.toLowerCase?.()?.includes?.(searchText?.toLowerCase?.())
  );

  const RenderMultipleValue = ({ selected }) => (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      {selected?.map((option: any) => {
        if (option?.label && option?.value)
          return (
            <Tooltip key={option?.value} title={option?.label} placement="top">
              <StyledChip
                key={option?.value}
                label={option?.label}
                onDelete={() => handleDelete(option)}
                color={'primary'}
                chipBgColor={
                  option.styleInfo?.bgColor
                    ? option.styleInfo?.bgColor
                    : chipBgColor
                }
                labelColor={
                  option.styleInfo?.textColor
                    ? option.styleInfo?.textColor
                    : labelColor
                }
                size="small"
                deleteIcon={
                  <CloseIcon
                    fill={
                      option.styleInfo?.bgColor
                        ? option.styleInfo?.textColor
                        : '#fff'
                    }
                  />
                }
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
                deleteIconHidden={deleteIconHidden}
              />
            </Tooltip>
          );
      })}
    </Box>
  );

  const RenderSingleValue = ({ selected }) => (
    <Tooltip key={selected?.value} title={selected?.label} placement="top">
      <span>{selected?.label}</span>
    </Tooltip>
  );

  const RenderValue = (selected: any) => {
    if (multiple) {
      return <RenderMultipleValue selected={selected} />;
    } else {
      return <RenderSingleValue selected={selected} />;
    }
  };

  const RenderCustomLabel = () => {
    if (label) {
      return (
        <StyledInputLabel htmlFor={props?.name}>
          <Tooltip placement="top" title={label}>
            <Typography
              variant="body1"
              color={theme.palette.neutral[800]}
              sx={{ mb: 0.5 }}
              {...props?.labelProps}
            >
              {label}
            </Typography>
          </Tooltip>
        </StyledInputLabel>
      );
    }

    return null;
  };

  const isArray = (value): value is Option[] => Array.isArray(value);

  const isObject = (value): value is Option => typeof value === 'object';

  const RenderPlaceholder = () => {
    if (
      !value ||
      (isObject(value) && !value?.label) ||
      (isArray(value) && value?.length === 0)
    ) {
      return (
        <Typography
          variant="body1"
          color={theme.palette.neutral[800]}
          sx={{ mb: 0.5, left: '20px', position: 'absolute', bottom: '10%' }}
        >
          {props?.placeholder}
        </Typography>
      );
    }
    return null;
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <RenderCustomLabel />
      <RenderPlaceholder />
      <StyledSelect
        labelId="custom-select-label"
        options={options}
        multiple={multiple}
        value={value}
        open={isOpen}
        onOpen={handleOpen}
        onClose={handleClose}
        size={size}
        error={error}
        renderValue={RenderValue}
        MenuProps={menuProps as any}
        onBlur={props.onBlur}
        {...props}
      >
        <SearchBox
          searchText={searchText}
          searchEnabled={searchEnabled}
          setSearchText={setSearchText}
        />
        {filteredOptions?.map((option) => (
          <Tooltip key={option?.value} title={option?.label} placement="top">
            <MenuItem
              key={option?.value}
              value={option?.value}
              onClick={(e: any) => {
                e.target.name = props?.name;
                handleChange(e, option);
              }}
            >
              <RenderCheckboxWithLabel
                shouldRender={withCheckbox && !withCustomOption}
                value={value}
                option={option}
              />
              <RenderCustomOption
                shouldRender={withCustomOption && !withCheckbox}
                option={option}
                renderOption={renderOption}
              />
              <RenderLabel
                shouldRender={!withCustomOption && !withCheckbox}
                option={option}
              />
            </MenuItem>
          </Tooltip>
        ))}
      </StyledSelect>
      {error && errorMessage && (
        <StyledErrorText size={size}>{errorMessage}</StyledErrorText>
      )}
    </Box>
  );
};

export default CustomSelect;
