import { Box, styled } from '@mui/material';

import CustomSelect from 'components/atoms/Select';
export const StyledSelect = styled(CustomSelect)`
  ${() => {
    return {
      marginTop: '3px',
      height: '35px',
      lineHeight: '15px',
      borderRadius: '50px',
      border: 0,
      outline: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    };
  }}
`;

export const StyledContainerBox = styled(Box)`
  ${() => {
    return { display: 'flex', flexDirection: 'row', width: '150px' };
  }}
`;

export const StyledIconBox = styled(Box)`
  ${() => {
    return {
      marginTop: '3px',
      marginRight: '8px',
    };
  }}
`;
export const StyledValueIconBox = styled(Box)`
  ${() => {
    return {
      marginTop: '0px',
      marginRight: '8px',
    };
  }}
`;
export const StyledValueContainerBox = styled(Box)`
  ${() => {
    return {
      display: 'flex',
      flexDirection: 'row',
    };
  }}
`;
