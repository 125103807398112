import { useState } from 'react';

interface UseCustomPaginationProps {
  initialPage: number;
  totalPages: number;
}

const useCustomPagination = ({
  initialPage,
  totalPages,
}: UseCustomPaginationProps) => {
  const [currentPage, setCurrentPage] = useState<number>(initialPage);
  const [defaultPage, setDefaultPage] = useState<number>(initialPage);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const resetPagination = (page: number) => {
    setDefaultPage(page);
    setCurrentPage(page);
  };

  return {
    defaultPage,
    currentPage,
    handleChange,
    totalPages,
    resetPagination,
  };
};

export default useCustomPagination;
