import { SVGProps } from 'react';
const DocumentIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="24"
    fill="none"
    viewBox="0 0 22 8"
    {...props}
  >
    <path
      fill="#000"
      d="M0 0v2h11V0H0zm0 4v2h11V4H0zm17 .1c-.1 0-.3.1-.4.2l-1 1 2.1 2.1 1-1c.2-.2.2-.6 0-.8l-1.3-1.3c-.1-.1-.2-.2-.4-.2zm-1.9 1.8l-6.1 6V14h2.1l6.1-6.1-2.1-2zM0 8v2h7V8H0z"
    ></path>
  </svg>
);
export default DocumentIcon;
