import styled from '@emotion/styled';
import { AppBar, Tab, Tabs } from '@mui/material';
import theme from 'theme';

export const StyledAppBar = styled(AppBar)`
  display: flex;
  padding: 16px 24px 0px 24px;
  flex-wrap: wrap;
  border-radius: 4px;
`;
export const StyledTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: '#5C628E',
  },
});

export const StyledTab = styled(Tab)(() => ({
  fontStyle: 'normal',
  fontFamily: theme.typography.fontFamily,
  fontSize: 14,
  textTransform: 'none',
  alignItems: 'center',
  flexDirection: 'column',
  display: 'flex',
  whiteSpace: 'nowrap',
  '&.Mui-selected': {
    fontSize: 14,
    fontWeight: 'bold',
  },
}));
