import { Box } from '@mui/material';
import CustomCircle from 'components/atoms/Circle';
import { EditPenIcon } from 'components/icons';
import { StyledEditButtonContainer, StyledParagraph } from './styles';
import { EditButtonProps } from './types';

const EditButton: React.FC<EditButtonProps> = (props) => {
  const { count, buttonTitle, onClick } = props;
  return (
    <StyledEditButtonContainer onClick={onClick}>
      <Box>
        <CustomCircle value={count} />
      </Box>
      <StyledParagraph color={'primary'} variant="body2" fontWeight={600}>
        {buttonTitle}
      </StyledParagraph>
      <Box>
        <EditPenIcon />
      </Box>
    </StyledEditButtonContainer>
  );
};

export default EditButton;
