import { styled } from '@mui/material';
import CustomSelect from 'components/atoms/Select';

export const StyledCustomSelect = styled(CustomSelect)`
  ${() => {
    return {
      '.MuiSvgIcon-root': {
        display: 'none',
      },
    };
  }}
`;
