import * as React from 'react';
import { SVGProps } from 'react';

const RectangleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="2"
    height="37"
    fill="none"
    viewBox="0 0 2 37"
    {...props}
  >
    <path fill="#29306B" d="M0 0H2V37H0z"></path>
  </svg>
);
export default RectangleIcon;
