import * as React from 'react';
import {
  FormControlLabel,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import SearchBox from 'components/atoms/SearchBox';
import TextBadge from 'components/atoms/TextBadge';
import CustomSelect from 'components/atoms/Select';
import CustomDataGrid from 'components/molecules/CustomDataGrid';
import {
  ExtensionDocumentStatus,
  ExtensionErrorType,
  ShareIsolateRequestStatus,
  useGetIsolatesMutation,
} from 'api/isolate';
import { SortDirection } from 'enums';
import {
  calculateDateDifference,
  formatDate,
  getFieldVisibility,
  truncateString,
} from 'utils';
import {
  IsolateFieldTable,
  IsolateSortFields,
  PAGES,
  USER_ROLE,
} from 'constants/index';
import CustomPagination from 'components/molecules/CustomPagination';
import useCustomPagination from 'hooks/useCustomPagination';
import { useTranslation } from 'react-i18next';
import RenderByCondition from 'components/atoms/RenderByCondition';
import { useDispatch, useSelector } from 'react-redux';
import ExpirationDate from 'components/icons/ExpirationDate';
import SortArrowUp from 'components/icons/SortArrowUp';
import SortArrowDown from 'components/icons/SortArrowDown';
import { setIsolates } from 'store/features/isolate/index.slice';
import { getIsolates } from 'store/features/isolate/index.selector';
import Search from 'components/icons/Search';
import CustomButton from 'components/atoms/Button';
import useToast from 'hooks/useToast';
import {
  ExtendIcon,
  ExtensionRequestIcon,
  PermissionIcon,
} from 'components/icons';
import { StyledCheckBox } from './styles';
import { getUserRole } from 'store/features/auth/index.selector';
import { useLocation, useNavigate } from 'react-router-dom';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { useCustomNavigationLogic } from 'hooks/useCustomNavigationLogic';
import dayjs from 'dayjs';

const {
  ISOLATES_TABLE_SELECT_VISIBILITY_FARMS,
  ISOLATES_TABLE_EXPIRATION_DATE_VISIBILITY,
  ISOLATE_ACTION_COLUMN_VISIBILITY,
} = getFieldVisibility();

const CustomHeader = ({ column, sortModel, onSortModelChange }: any) => {
  const [isHover, setIsHover] = React.useState(false);
  const theme = useTheme();

  const handleClick = () => {
    let newSortModel = [];

    const prepareSort = (sort) => {
      if (sort === 'asc') return 'desc';
      else if (sort === 'desc') return null;
      else if (sort === null) return 'asc';
    };

    newSortModel = sortModel?.map((sort) => {
      if (sort.field === column?.field) {
        return {
          ...sort,
          sort: prepareSort(sort?.sort),
        };
      }
      return sort;
    });

    const sort = newSortModel.find((sort) => sort.field === column.field);

    onSortModelChange(newSortModel, sort);
  };

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const isNan =
    sortModel.find((sort) => sort.field === column.field)?.sort === null;

  const isAsc =
    sortModel.find((sort) => sort.field === column.field)?.sort === 'asc';

  const isDesc =
    sortModel.find((sort) => sort.field === column.field)?.sort === 'desc';

  return (
    <Grid
      container
      alignItems="center"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      width={column?.width || '100%'}
    >
      <Grid item>
        <Typography color={'primary'} variant="body1" fontWeight={600}>
          {column.headerName}
        </Typography>
      </Grid>
      <Grid item>
        <IconButton
          size="small"
          onClick={handleClick}
          sx={{ height: 36, width: 36, ml: '2px' }}
        >
          {isNan && isHover && <SortArrowUp show={true} theme={theme} />}
          {isAsc && <SortArrowUp show={true} theme={theme} />}
          {isDesc && <SortArrowDown show={true} theme={theme} />}
        </IconButton>
      </Grid>
    </Grid>
  );
};

const CellData = (props) => {
  const { color = 'neutral.10', value, sx } = props;

  return (
    <Typography
      sx={{
        ...sx,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
      color={color}
      fontWeight={500}
    >
      {value}
    </Typography>
  );
};

const getColumns = (t, userRole, navigate) => {
  const columns = [
    {
      field: IsolateFieldTable.ISOLATE_NAME,
      headerName: t('isolates.name'),
      width: ISOLATE_ACTION_COLUMN_VISIBILITY ? 200 : 250,
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <Grid container flexDirection={'column'}>
            <Tooltip
              title={`${params.row.isolateReference},${params.row.isolateName} `}
            >
              <>
                <CellData value={`${params.row.isolateReference || ''}`} />
                <CellData
                  value={`${
                    truncateString(
                      params.row.isolateName,
                      ISOLATE_ACTION_COLUMN_VISIBILITY ? 20 : 40
                    ) || ''
                  }`}
                />
              </>
            </Tooltip>
          </Grid>
        );
      },
      sortable: false,
    },
    {
      field: IsolateFieldTable.STATUS,
      headerName: t('isolates.status'),
      width: ISOLATE_ACTION_COLUMN_VISIBILITY ? 120 : 150,
      hideSortIcons: true,
      sortable: false,
      renderCell(params) {
        const RenderShareStatus = () => {
          const statusMapper = (status) => {
            switch (status) {
              case ShareIsolateRequestStatus.Sent:
                return 'Sent';
              case ShareIsolateRequestStatus.Denied:
                return 'Declined';
              case ShareIsolateRequestStatus.Approved:
                return 'Approved';
              default:
                return '';
            }
          };

          // loop through params?.row?.isolateShareRequests and return true if at least one of them is denied
          const isDenied = params?.row?.isolateShareRequests?.some(
            (item) => item?.status === ShareIsolateRequestStatus.Denied
          );

          const TextBadgeUI = () => {
            return (
              <Tooltip
                title={
                  <Grid container flexDirection={'column'}>
                    {params?.row?.isolateShareRequests?.map((item) => {
                      const data = {
                        status: statusMapper(item?.status),
                        customer: 'Customer ' + item?.customer?.name,
                        id: item?.id,
                      };
                      return (
                        <Grid key={data?.id} item xs={12}>
                          {data?.status} : {data?.customer}
                        </Grid>
                      );
                    })}
                  </Grid>
                }
                arrow
                followCursor
              >
                <Grid
                  item
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer',
                  }}
                >
                  <TextBadge
                    name={
                      <Typography sx={{ marginTop: '5px' }} variant="body1">
                        {params?.row?.isolateShareRequests?.length || ''}
                      </Typography>
                    }
                    color={isDenied ? '#A60F0F' : '#006B52'}
                    variant="body1"
                    sx={{
                      p: 0,
                      px: 2,
                    }}
                    IconComponent={() => (
                      <PermissionIcon
                        fill={isDenied ? '#A60F0F' : '#006B52'}
                        style={{ marginTop: '6px' }}
                      />
                    )}
                  />
                </Grid>
              </Tooltip>
            );
          };

          const isSentOrDeniedOrApproved =
            params?.row?.isolateShareRequests?.some(
              (item) =>
                item?.status === ShareIsolateRequestStatus.Denied ||
                item?.status === ShareIsolateRequestStatus.Sent ||
                item?.status === ShareIsolateRequestStatus.Approved
            );

          return (
            <RenderByCondition show={[isSentOrDeniedOrApproved]}>
              <Grid
                container
                flexDirection={'row'}
                spacing={1}
                alignItems={'center'}
                mt={'1px'}
              >
                <TextBadgeUI />
              </Grid>
            </RenderByCondition>
          );
        };
        return (
          <Grid container>
            <Grid item xs={12}>
              <TextBadge
                name={params?.value?.name}
                color={params?.value?.color}
              />
            </Grid>

            <Grid item xs={12}>
              <RenderShareStatus />
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: IsolateFieldTable.RECEPTION,
      headerName: t('isolates.reception'),
      width: ISOLATE_ACTION_COLUMN_VISIBILITY ? 140 : 160,
      hideSortIcons: true,
      sortable: false,
      renderCell: (params) => (
        <Tooltip title={params.row.reception}>
          <CellData value={`${params.row.reception || ''}`} />
        </Tooltip>
      ),
    },
    ISOLATES_TABLE_EXPIRATION_DATE_VISIBILITY
      ? {
          field: IsolateFieldTable.EXPIRATION,
          headerName: t('isolates.expiration'),
          width: ISOLATE_ACTION_COLUMN_VISIBILITY ? 140 : 160,
          hideSortIcons: true,
          sortable: false,
          renderCell: (params) => {
            const currentDate = formatDate(dayjs());

            const { days, months } = calculateDateDifference(
              currentDate,
              params?.row?.expiration
            );

            const status = params?.row?.isolateExtensionRequest?.status;
            const extensionRequestIsolatesLength =
              params?.row?.isolateExtensionRequest
                ?.extensionRequestIsolatesLength;

            const RenderWarningDate = () => {
              const UI = (
                <Grid
                  container
                  flexDirection={'row'}
                  alignItems={'center'}
                  alignContent={'center'}
                  justifyItems={'center'}
                  mt={1}
                >
                  <Grid item mr={1} mt={'2px'}>
                    <ExpirationDate />
                  </Grid>
                  <Grid item>
                    <CellData
                      color={'warning.900'}
                      value={`${months}m, ${days}d`}
                    />
                  </Grid>
                </Grid>
              );

              if (months < 3) return UI;
              else if (months == 3 && days == 0) {
                return UI;
              }
              return null;
            };

            const RenderExtensionStatus = () => {
              const getColor = () => {
                switch (status) {
                  case ExtensionDocumentStatus.Sent:
                    return '#006B52';
                  case ExtensionDocumentStatus.Denied:
                    return '#A60F0F';
                  default:
                    return '#006B52';
                }
              };

              const getStatusName = () => {
                switch (status) {
                  case ExtensionDocumentStatus.Sent:
                    return 'Sent';
                  case ExtensionDocumentStatus.Denied:
                    return 'Declined';
                }
              };

              const TextBadgeUI = () => {
                return (
                  <Grid item>
                    <TextBadge
                      name={getStatusName()}
                      color={getColor()}
                      variant="body1"
                      IconComponent={() => (
                        <ExtendIcon
                          fill={getColor()}
                          style={{ marginBottom: '-8px' }}
                        />
                      )}
                    />
                  </Grid>
                );
              };

              const ActionButtonUI = () => {
                return (
                  <Grid item>
                    <RenderByCondition
                      show={[
                        [
                          ExtensionDocumentStatus.Denied,
                          ExtensionDocumentStatus.Sent,
                        ]?.includes(status),
                      ]}
                    >
                      <Grid item>
                        <RenderByCondition
                          show={[extensionRequestIsolatesLength > 1]}
                        >
                          <ExtensionRequestIcon fill={getColor()} />
                        </RenderByCondition>
                      </Grid>
                    </RenderByCondition>
                  </Grid>
                );
              };

              return (
                <RenderByCondition
                  show={[
                    Boolean(params?.row?.isolateExtensionRequest?.id),
                    [
                      ExtensionDocumentStatus.Sent,
                      ExtensionDocumentStatus.Denied,
                    ]?.includes(status),
                  ]}
                >
                  <Grid
                    container
                    flexDirection={'row'}
                    spacing={1}
                    alignItems={'center'}
                    mt={'1px'}
                  >
                    <TextBadgeUI />
                    <ActionButtonUI />
                  </Grid>
                </RenderByCondition>
              );
            };

            return (
              <Grid container flexDirection={'column'}>
                <Tooltip title={params.row.expiration}>
                  <CellData value={`${params.row.expiration || ''}`} />
                </Tooltip>
                <RenderWarningDate />
                <RenderExtensionStatus />
              </Grid>
            );
          },
        }
      : null,
    {
      field: IsolateFieldTable.SEROTYPE,
      headerName: t('isolates.serotype'),
      width: 130,
      hideSortIcons: true,
      sortable: false,
      renderCell: (params) => (
        <Tooltip title={params.row.serotype}>
          <CellData value={`${params.row.serotype || ''}`} />
        </Tooltip>
      ),
    },
    {
      field: IsolateFieldTable.FARM_ORIGIN,
      headerName: t('isolates.farmOrigin'),
      hideSortIcons: true,
      sortable: false,
      minWidth: ISOLATE_ACTION_COLUMN_VISIBILITY ? 180 : 300,
      renderCell: (params) => {
        const farmOrigin = params.row.farmOrigin;
        const farmAddress = params.row.farmAddress;
        const farmPhone = params.row.farmPhone;
        const capacity = params.row?.capacity;
        return (
          <Grid container flexDirection={'column'}>
            <Tooltip
              title={`${farmOrigin}, ${farmAddress} ${farmPhone} ${capacity}`}
            >
              <>
                <CellData value={`${farmOrigin || ''}`} />
                <CellData
                  value={`${
                    truncateString(
                      farmAddress,
                      ISOLATE_ACTION_COLUMN_VISIBILITY ? 20 : 40
                    ) || ''
                  }`}
                />
                <CellData value={`${farmPhone || ''}`} />
                <CellData value={`${capacity || ''}`} />
              </>
            </Tooltip>
          </Grid>
        );
      },
    },
    {
      field: IsolateFieldTable.SPECIES,
      headerName: t('isolates.species'),
      width: 160,
      hideSortIcons: true,
      sortable: false,
      renderCell: (params) => (
        <Tooltip title={params.row.species}>
          <CellData value={`${params.row.species || ''}`} />
        </Tooltip>
      ),
    },
    ISOLATE_ACTION_COLUMN_VISIBILITY
      ? {
          field: IsolateFieldTable.ACTIONS,
          headerName: '',
          width: 160,
          hideSortIcons: true,
          sortable: false,
          type: 'actions',
          disableClickEventBubbling: true,
          renderCell: (params) => {
            const redirectToExtensionDetailsPageOnClick = () => {
              if (
                params?.row?.isolateExtensionRequest?.status ===
                  ExtensionDocumentStatus.Denied &&
                [USER_ROLE.TM, USER_ROLE.VET].includes(userRole)
              ) {
                navigate(
                  `${PAGES[userRole].ISOLATE_EXTENSION}/${params?.row?.isolateExtensionRequestId}`
                );
              } else if (
                params?.row?.isolateExtensionRequest?.status ===
                  ExtensionDocumentStatus.Sent &&
                [
                  USER_ROLE.CSS,
                  USER_ROLE.REGULATORY,
                  USER_ROLE.REGULATORYMANAGER,
                ].includes(userRole)
              ) {
                navigate(
                  `${PAGES[userRole].ISOLATE_EXTENSION_APPROVAL}/${params?.row?.isolateExtensionRequestId}`
                );
              }
            };

            const redirectToShareDetailsPageOnClick = () => {
              const atLeastOneSent = params?.row?.isolateShareRequests?.some(
                (item) => item?.status === ShareIsolateRequestStatus.Sent
              );
              if ([USER_ROLE.TM, USER_ROLE.VET].includes(userRole)) {
                // to be verified with BA;
                navigate(
                  `${PAGES[userRole].SHARE_REQUEST}/${params?.row?.isolateShareRequests?.isolateId}`
                );
              } else if (
                atLeastOneSent &&
                [
                  USER_ROLE.CSS,
                  USER_ROLE.REGULATORY,
                  USER_ROLE.REGULATORYMANAGER,
                ].includes(userRole)
              ) {
                navigate(
                  `${PAGES[userRole].ISOLATE_SHARE_APPROVAL}/${params?.row?.isolateShareRequests?.[0]?.isolateId}`
                );
              }
            };

            const redirectToExtendPageOnClick = () => {
              if (params?.row?.extendFn) {
                params?.row?.extendFn(params?.row);
              }
            };

            function redirectToShareRequest() {
              if (params?.row?.shareFn) {
                params?.row?.shareFn(params?.row);
              }
            }

            const isExtendButtonVisible = [
              USER_ROLE.VET,
              USER_ROLE.TM,
            ].includes(userRole);

            const isShareButtonVisible = [USER_ROLE.VET, USER_ROLE.TM].includes(
              userRole
            );

            const isAttachmentButtonVisibleForExtension =
              [
                USER_ROLE.CSS,
                USER_ROLE.REGULATORY,
                USER_ROLE.REGULATORYMANAGER,
              ].includes(userRole) &&
              [params?.row?.isolateExtensionRequest?.status].includes(
                ExtensionDocumentStatus.Sent
              );

            const atLeastOneSent = params?.row?.isolateShareRequests?.some(
              (item) => item?.status === ShareIsolateRequestStatus.Sent
            );

            const isAttachmentButtonVisibleForShare =
              [
                USER_ROLE.CSS,
                USER_ROLE.REGULATORY,
                USER_ROLE.REGULATORYMANAGER,
              ].includes(userRole) && atLeastOneSent;

            return [
              isExtendButtonVisible ? (
                <GridActionsCellItem
                  icon={<ExtendIcon />}
                  label="Edit"
                  className="textPrimary"
                  onClick={() => {
                    redirectToExtendPageOnClick();
                  }}
                  color="inherit"
                  key={1}
                />
              ) : null,
              isShareButtonVisible ? (
                <GridActionsCellItem
                  icon={<PermissionIcon fill={'#000000'} />}
                  label="Edit"
                  className="textPrimary"
                  onClick={() => redirectToShareRequest()}
                  color="inherit"
                  key={3}
                />
              ) : null,
              isAttachmentButtonVisibleForExtension ? (
                <GridActionsCellItem
                  icon={<ExtendIcon />}
                  label="Edit"
                  className="textPrimary"
                  onClick={() => redirectToExtensionDetailsPageOnClick()}
                  color="inherit"
                  key={3}
                />
              ) : null,
              isAttachmentButtonVisibleForShare ? (
                <GridActionsCellItem
                  icon={<PermissionIcon />}
                  label="Edit"
                  className="textPrimary"
                  onClick={() => redirectToShareDetailsPageOnClick()}
                  color="inherit"
                  key={3}
                />
              ) : null,
            ].filter(Boolean);
          },
        }
      : null,
  ];

  return columns?.filter(Boolean);
};

const createRow = (columns, item) => {
  return {
    id: item?.id,
    isolateName: item?.name,
    status: { name: item?.status?.name, color: item?.status?.color },
    reception: formatDate(item?.isolationDate),
    expiration: formatDate(item?.expirationDate),
    farmOrigin: item?.originFarm?.name,
    species: item?.animalSpecies?.name,
    serotype: item?.seroType,
    isolateReference: item?.reference,
    farmAddress: item?.originFarm?.address?.street,
    farmPhone: item?.originFarm?.address?.phoneNumber,
    capacity: item?.originFarm?.capacity,
    extendFn: item?.extendFn,
    shareFn: item?.shareFn,
    isExtendable: item?.extendable,
    isolateExtensionRequestId: item?.isolateExtensionRequestId,
    extensionError: item?.extensionError,
    isolateExtensionRequest: item?.isolateExtensionRequest,
    canBeShared: item?.canBeShared,
    isolateShareRequests: item?.isolateShareRequests,
  };
};

const useDataGrid = ({
  columns,
  data,
  prepareRowCallback,
  extendFn,
  shareFn,
}) => {
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const navigate = useNavigate();
  const role = useSelector(getUserRole);

  const onRowSelectionModelChange = (value, cb = undefined) => {
    setSelectedRows(value);

    if (typeof cb === 'function') cb(value);
    else if (cb)
      throw new Error('onRowSelectionModelChange callback must be a function');
  };

  const resetSelectedRows = () => {
    setSelectedRows([]);
  };

  React.useEffect(() => {
    const rows = [];
    const columnsFields = columns?.map(() => columns.field);

    data?.forEach((item) => {
      const row = prepareRowCallback(columnsFields, {
        ...item,
        extendFn: extendFn,
        shareFn: shareFn,
      });
      rows.push(row);
    });
    setRows(rows);
  }, [data]);

  const onSortModelChange = (e, callback) => {
    if (callback) callback(e);
  };

  const onRowClick = (params) => {
    navigate(`${PAGES[role].ISOLATE_DETAILS}/${params?.row?.id}`);
  };

  return {
    onRowClick,
    selectedRows,
    rows,
    onRowSelectionModelChange,
    onSortModelChange,
    columns,
    resetSelectedRows,
  };
};

const CustomerSelect = ({ customerOptions, setCustomer, value, count, t }) => {
  const [defaultValue, setDefaultValue] = React.useState({
    label: t('global.all'),
    value: 'All',
  });

  React.useEffect(() => {
    setDefaultValue({
      label: t('global.all'),
      value: 'All',
    });
  }, [t]);

  return (
    <Grid
      md={4}
      xs={12}
      mb={{
        sx: 2,
        md: 0,
      }}
    >
      <Grid container alignItems={'center'}>
        <Grid item>
          <Typography
            color={'primary'}
            variant="body1"
            fontWeight={600}
            sx={{ display: 'inline-block' }}
          >
            {t('isolates.customers', { count: count })}
          </Typography>
        </Grid>
        <Grid item xs>
          <CustomSelect
            label=""
            options={[defaultValue, ...(customerOptions || [])]}
            onChange={(e, value) => {
              setCustomer(value);
            }}
            name={'customer'}
            value={value || defaultValue}
            sx={{ mx: 1 }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const FarmSelect = ({ farmOptions, setFarm, value, count, t }) => {
  const [defaultValue, setDefaultValue] = React.useState({
    label: t('global.all'),
    value: 'All',
  });

  React.useEffect(() => {
    setDefaultValue({
      label: t('global.all'),
      value: 'All',
    });
  }, [t]);

  return (
    <Grid md={4} xs={12} mb={{ xs: 2, md: 0 }}>
      <Grid container alignItems={'center'}>
        <Grid item>
          <Typography
            color={'primary'}
            variant="body1"
            fontWeight={600}
            sx={{ display: 'inline-block' }}
          >
            {t('isolates.farms', { count: count })}
          </Typography>
        </Grid>
        <Grid item xs>
          <CustomSelect
            label=""
            options={[defaultValue, ...(farmOptions || [])]}
            onChange={(e, value) => {
              setFarm(value);
            }}
            name={'farm'}
            value={value || defaultValue}
            sx={{ mx: 2 }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const SelectContainer = ({
  farm,
  setFarm,
  farmOptions,
  t,
  customer,
  setCustomer,
  customerOptions,
}) => {
  return (
    <>
      <RenderByCondition show={[ISOLATES_TABLE_SELECT_VISIBILITY_FARMS]}>
        <FarmSelect
          value={farm}
          setFarm={setFarm}
          farmOptions={farmOptions}
          count={farmOptions?.length}
          t={t}
        />
      </RenderByCondition>
      <CustomerSelect
        value={customer}
        setCustomer={setCustomer}
        customerOptions={customerOptions}
        count={customerOptions?.length}
        t={t}
      />
    </>
  );
};

const CheckBox = (props) => {
  const { checked, onChange, label, value, disabled } = props;
  return (
    <FormControlLabel
      {...props}
      value={value}
      control={<StyledCheckBox disableRipple={true} />}
      label={label}
      onChange={onChange}
      disabled={disabled}
      checked={checked}
    />
  );
};

const FilterButtons = ({
  handleClick,
  isAll,
  isExtend,
  isWithout,
  isExtendedBtnDisabled,
  onExtendButtonClick,

  isShare,
}) => {
  const { t } = useTranslation();
  const userRole = useSelector(getUserRole);

  return (
    <Grid item md={ISOLATE_ACTION_COLUMN_VISIBILITY ? 8 : 4} xs={12}>
      <RenderByCondition show={[!ISOLATES_TABLE_SELECT_VISIBILITY_FARMS]}>
        <Grid container flexDirection={'row'} alignItems={'center'} gap={2}>
          <Grid item>
            <RenderByCondition
              show={[[USER_ROLE.TM, USER_ROLE.VET].includes(userRole as any)]}
            >
              <CustomButton
                onClick={() => {
                  if (onExtendButtonClick) onExtendButtonClick();
                }}
                disabled={isExtendedBtnDisabled}
                endIcon={
                  <ExtendIcon
                    fill={!isExtendedBtnDisabled ? '#fff' : '#000'}
                    disabled={isExtendedBtnDisabled}
                  />
                }
              >
                <Typography variant="body2" fontWeight={600}>
                  {t('isolates.extend')}
                </Typography>
              </CustomButton>
            </RenderByCondition>
          </Grid>

          <Grid item>
            <Typography variant="body1" fontWeight={600} color={'primary'}>
              {t('isolates.request')}
            </Typography>
          </Grid>
          <Grid item>
            <CheckBox
              checked={isAll}
              onChange={() =>
                handleClick({ target: { name: 'isAll' } }, !isAll)
              }
              type={isAll ? 'active' : 'normal'}
              value={isAll}
              label={
                <Typography variant="body1" fontWeight={500} color={'primary'}>
                  {t('isolates.all')}
                </Typography>
              }
            />
          </Grid>

          <Grid item>
            <CheckBox
              checked={isShare}
              onChange={() =>
                handleClick({ target: { name: 'isShare' } }, !isShare)
              }
              type={isShare ? 'active' : 'normal'}
              value={isShare}
              label={
                <Typography variant="body1" fontWeight={500} color={'primary'}>
                  {t('isolates.share')}
                </Typography>
              }
            />
          </Grid>

          <Grid item>
            <CheckBox
              checked={isExtend}
              onChange={() =>
                handleClick({ target: { name: 'isExtend' } }, !isExtend)
              }
              type={isExtend ? 'active' : 'normal'}
              value={isExtend}
              label={
                <Typography variant="body1" fontWeight={500} color={'primary'}>
                  {t('isolates.extend')}
                </Typography>
              }
            />
          </Grid>
          <Grid item>
            <CheckBox
              checked={isWithout}
              onChange={() =>
                handleClick({ target: { name: 'isWithout' } }, !isWithout)
              }
              type={isWithout ? 'active' : 'normal'}
              value={isWithout}
              label={
                <Typography variant="body1" fontWeight={500} color={'primary'}>
                  {t('isolates.without')}
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </RenderByCondition>
    </Grid>
  );
};

export const preparePropertyName = (field) => {
  switch (field) {
    case 'isolateName':
      return IsolateSortFields.NAME;
    case 'status':
      return IsolateSortFields.STATUS;
    case 'reception':
      return IsolateSortFields.ISOLATION_DATE;
    case 'serotype':
      return IsolateSortFields.SEROTYPE;
    case 'expiration':
      return IsolateSortFields.EXPIRATION_DATE;
    case 'farmOrigin':
      return IsolateSortFields.ORIGIN_FARM_NAME;
    case 'species':
      return IsolateSortFields.ANIMAL_SPECIES_NAME;
    default:
      return '';
  }
};

export const prepareDirection = (sort) => {
  switch (sort) {
    case 'asc':
      return SortDirection.Ascending;
    case 'desc':
      return SortDirection.Descending;
    case null:
      return null;
    default:
      return SortDirection.Ascending;
  }
};

const initialSortModel = () => {
  return [
    {
      field: IsolateFieldTable.ISOLATE_NAME,
      sort: null,
    },
    {
      field: IsolateFieldTable.STATUS,
      sort: null,
    },
    {
      field: IsolateFieldTable.RECEPTION,
      sort: null,
    },
    {
      field: IsolateFieldTable.SEROTYPE,
      sort: null,
    },
    {
      field: IsolateFieldTable.EXPIRATION,
      sort: null,
    },
    {
      field: IsolateFieldTable.FARM_ORIGIN,
      sort: null,
    },
    {
      field: IsolateFieldTable.SPECIES,
      sort: null,
    },
  ];
};

const defaultPageNumber = 1;
const defaultPageSize = 200;

const reducer = (state, action) => {
  state = { ...state, ...action(state) };

  return state;
};

export const useForm = () => {
  const [
    fetchIsolate,
    { data: isolatesData, isLoading: fetchIsolateIsLoading },
  ] = useGetIsolatesMutation();
  const navigate = useNavigate();
  const userRole = useSelector(getUserRole);
  const { getParamsValues, paramsValues, setParams }: any =
    useCustomNavigationLogic({
      queryParams: [
        'customerId',
        'farmId',
        'extended',
        'pageNumber',
        'pageSize',
        'searchKey',
        'withoutExtensionOrShare',
        'shared',
      ],
    });
  const lc = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isolatesDataFromStore = useSelector(getIsolates);
  const cols = getColumns(t, userRole, navigate);

  const [state, setState] = React.useReducer(reducer, {
    customer: null,
    farm: null,
    searchKey: '',
    isExtend: false,
    isShare: false,
    isAll: false,
    isWithout: false,
    sortModel: initialSortModel(),
    sortData: {
      pageSize: defaultPageSize,
      pageNumber: defaultPageNumber,
      searchKey: '',
      sortCriteria: [],
    },
    pageSize: defaultPageSize,
    pageNumber: defaultPageNumber,
    isLoading: false,
    rows: [],
    customerOptions: [],
    farmOptions: [],
    isExtendedBtnDisabled: true,
    isShareDisabled: true,
  });
  const { toast } = useToast();
  const { currentPage, handleChange, defaultPage, totalPages } =
    useCustomPagination({
      initialPage: 1,
      totalPages: isolatesDataFromStore?.totalPages,
    });

  const {
    rows,
    onRowSelectionModelChange,
    selectedRows,
    resetSelectedRows,
    onRowClick,
  } = useDataGrid({
    columns: cols,
    data: isolatesData?.items,
    prepareRowCallback: createRow,
    extendFn: extendFn,
    shareFn: shareFn,
  });

  const paginationOnClick = (event, value) => {
    setParams({
      pageNumber: value,
    });
    handleChange(undefined, value);
    fetchIsolate({
      ...state?.sortData,
      pageNumber: value,
      searchKey: state?.searchKey,
    });
  };

  React.useEffect(() => {
    fetchIsolate({
      ...state?.sortData,
      pageNumber: 1,
      searchKey: state?.searchKey,
    });
    handleChange(undefined, 1);
  }, [state?.sortData]);

  React.useEffect(() => {
    resetSelectedRows();
  }, []);

  React.useEffect(() => {
    dispatch(setIsolates(isolatesData));
  }, [isolatesData]);

  React.useEffect(() => {
    getParamsValues();
  }, [lc]);

  React.useEffect(() => {
    setState((state) => ({
      ...state,
      isAll:
        paramsValues?.shared === 'true' &&
        paramsValues?.extended === 'true' &&
        paramsValues?.withoutExtensionOrShare === 'true',
      isShare: paramsValues?.shared === 'true',
      isExtend: paramsValues?.extended === 'true',
      isWithout: paramsValues?.withoutExtensionOrShare === 'true',
      searchKey: paramsValues?.searchKey || '',
      sortData: {
        ...state?.sortData,
        pageNumber: parseInt(paramsValues?.pageNumber) || 1,
        pageSize: defaultPageSize,
        shared: paramsValues?.shared === 'true',
        searchKey: paramsValues?.searchKey || '',
        extended: paramsValues?.extended === 'true',
        withoutExtensionOrShare:
          paramsValues?.withoutExtensionOrShare === 'true',
        customerId: parseInt(paramsValues?.customerId) || null,
        farmId: parseInt(paramsValues?.farmId) || null,
      },
    }));
  }, [paramsValues]);

  const findSelectedCustomer = (data, customerId) => {
    const selectedCustomer = data?.customerDtos?.find(
      (c) => c?.id === customerId
    );

    return selectedCustomer
      ? {
          ...selectedCustomer,
          label: selectedCustomer.name,
          value: selectedCustomer.id,
        }
      : null;
  };

  const findSelectedFarm = (data, farmId) => {
    const selectedFarm = data?.farmDtos?.find((f) => f?.id === farmId);
    return selectedFarm
      ? { ...selectedFarm, label: selectedFarm.name, value: selectedFarm.id }
      : null;
  };

  React.useEffect(() => {
    const shouldSkip =
      (ISOLATES_TABLE_SELECT_VISIBILITY_FARMS &&
        state?.customer &&
        state.farm) ||
      (!ISOLATES_TABLE_SELECT_VISIBILITY_FARMS && state?.customer);

    if (shouldSkip) {
      return;
    }

    const customerId = parseInt(state?.sortData?.customerId);
    const farmId = parseInt(state?.sortData?.farmId);

    if (!ISOLATES_TABLE_SELECT_VISIBILITY_FARMS && customerId && isolatesData) {
      const selectedCustomer = findSelectedCustomer(isolatesData, customerId);
      setState((state) => ({ ...state, customer: selectedCustomer }));
    } else if (
      ISOLATES_TABLE_SELECT_VISIBILITY_FARMS &&
      (customerId || farmId) &&
      isolatesData
    ) {
      const selectedCustomer = customerId
        ? findSelectedCustomer(isolatesData, customerId)
        : null;
      const selectedFarm = farmId
        ? findSelectedFarm(isolatesData, farmId)
        : null;

      setState((state) => ({
        ...state,
        customer: selectedCustomer,
        farm: selectedFarm,
      }));
    }
  }, [state.sortData?.customerId, isolatesData, state.sortData.farmId]);

  const isolateExtensionVerification = (selectedRows) => {
    if (!ISOLATE_ACTION_COLUMN_VISIBILITY) return;

    let isExtendedBtnDisabled = true;

    const isolate = isolatesData?.items?.find(
      (isolate) => isolate.id === selectedRows[selectedRows.length - 1]
    );

    const extensionErrorTypeArray = Object.keys(ExtensionErrorType).map(
      (key) => ExtensionErrorType[key]
    );

    const getMessageDependOnExtensionError = (error) => {
      switch (error) {
        case ExtensionErrorType.NeverUsed:
          return t('isolates.neverUsed');
        case ExtensionErrorType.AlreadyExtended:
          return t('isolates.alreadyExtended');
        case ExtensionErrorType.AlreadyRequested:
          return t('isolates.alreadyRequested');
        default:
          return '';
      }
    };

    if (!selectedRows || selectedRows?.length === 0)
      isExtendedBtnDisabled = true;
    else if (
      !isolate?.extendable &&
      [...extensionErrorTypeArray].includes(isolate?.extensionError)
    ) {
      toast.warn(getMessageDependOnExtensionError(isolate?.extensionError), {
        duration: 8000,
      });
      isExtendedBtnDisabled = true;
    } else {
      const isolates = isolatesData?.items?.filter((isolate) =>
        selectedRows?.includes(isolate.id)
      );

      isExtendedBtnDisabled = isolates?.some((isolate, index, array) => {
        return index > 0 && isolate?.type !== array[index - 1]?.type;
      });

      if (isExtendedBtnDisabled)
        toast.warn(t('isolates.differentType'), {
          duration: 8000,
        });
    }

    setState((state) => ({
      ...state,
      isExtendedBtnDisabled: isExtendedBtnDisabled,
    }));

    return isExtendedBtnDisabled;
  };

  const extendDataPreparation = (selectedRows) => {
    const encodedIsolateIds = encodeURIComponent([selectedRows]?.join(','));

    navigate({
      pathname: `${PAGES[userRole].ISOLATE_EXTENSION}`,
      search: `?isolate_ids=${encodedIsolateIds}`,
    });
    resetSelectedRows();
  };

  const onExtendButtonClick = async () => {
    extendDataPreparation(selectedRows);
  };

  function extendFn(selectedRow) {
    if (!isolateExtensionVerification([selectedRow?.id]))
      extendDataPreparation([selectedRow?.id]);
  }

  const shareDataPreparation = (selectedRow) => {
    navigate(`${PAGES[userRole].SHARE_REQUEST}/${selectedRow?.id}`);
  };

  function shareFn(selectedRow) {
    if (!isolateShareVerification(selectedRow)) {
      shareDataPreparation(selectedRow);
    }
  }

  const isolateShareVerification = (selectedRow) => {
    if (!ISOLATE_ACTION_COLUMN_VISIBILITY) return;

    if (selectedRow?.canBeShared) {
      return false;
    } else {
      toast.warn('Selected isolate not sharable', {
        duration: 8000,
      });
    }
    return true;
  };

  const onRowSelectChange = (value) => {
    onRowSelectionModelChange(value);
    isolateExtensionVerification(value);
  };

  const setSortData = (name, value) => {
    setState((state) => ({
      ...state,
      sortData: { ...state?.sortData, [name]: value },
    }));
  };

  const onChange = (e, value) => {
    const fieldName = e?.target?.name;

    switch (fieldName) {
      case 'customerId':
        setState((state) => ({ ...state, customer: value?.data }));
        setParams({
          customerId: value?.value,
        });
        setSortData(fieldName, value?.value);
        break;
      case 'farmId':
        setState((state) => ({ ...state, farm: value?.data }));
        setParams({
          farmId: value?.value,
        });
        setSortData(fieldName, value?.value);
        break;
      case 'searchKey':
        if (value === '') setParams({ searchKey: '' });
        setState((state) => ({
          ...state,
          searchKey: value,
        }));

        break;
    }
  };

  const handleSort = (value) => {
    const columnSortData = {
      propertyName: preparePropertyName(value?.sort?.field),
      direction: prepareDirection(value?.sort?.sort),
    };

    const sortCriteriaData =
      state?.sortData?.sortCriteria?.filter(
        (item) => item?.propertyName !== preparePropertyName(value?.sort?.field)
      ) || [];

    return {
      sortModel: value?.sortModel,
      sortData: {
        ...state?.sortData,
        sortCriteria:
          columnSortData?.direction === null
            ? [...(sortCriteriaData || [])]
            : [...sortCriteriaData, columnSortData],
        pageNumber: 1,
      },
    };
  };

  const handleClick = (event, value) => {
    const fieldName = event?.target?.name;

    switch (fieldName) {
      case 'searchKey':
        setParams({
          searchKey: state?.searchKey,
        });
        fetchIsolate({
          ...state?.sortData,
          pageNumber: 1,
          searchKey: state?.searchKey,
        });
        handleChange(undefined, 1);
        break;
      case 'sort':
        setState((state) => ({
          ...state,
          ...handleSort(value),
        }));
        break;
      case 'isAll':
        setParams({
          isAll: value,
          shared: value,
          extended: value,
          withoutExtensionOrShare: value,
        });
        setState((state) => ({
          ...state,
          isAll: value,
          isShare: value,
          isExtend: value,
          isWithout: value,
          sortData: {
            ...state?.sortData,
            pageNumber: 1,
            extended: value,
            withoutExtensionOrShare: value,
          },
        }));
        break;
      case 'isShare':
        setParams({
          shared: value,
        });
        setState((state) => ({
          ...state,
          isShare: value,
          isAll: value && state?.isExtend && state?.isWithout,
          sortData: {
            ...state?.sortData,
            pageNumber: 1,
            shared: value,
          },
        }));
        break;
      case 'isExtend':
        setParams({
          extended: value,
        });
        setState((state) => ({
          ...state,
          isExtend: value,
          isAll: value && state?.isWithout && state?.isShare,
          sortData: {
            ...state?.sortData,
            pageNumber: 1,
            extended: value,
          },
        }));
        break;
      case 'isWithout':
        setParams({
          withoutExtensionOrShare: value,
        });
        setState((state) => ({
          ...state,
          isWithout: value,
          isAll: value && state?.isExtend && state?.isShare,
          sortData: {
            ...state?.sortData,
            pageNumber: 1,
            withoutExtensionOrShare: value,
          },
        }));
        break;

      default:
        break;
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      fetchIsolate({
        ...state?.sortData,
        pageNumber: 1,
        searchKey: state?.searchKey,
      });
      handleChange(undefined, 1);
    }
  };

  const customerOptions = React.useMemo(
    () =>
      isolatesDataFromStore?.customerDtos?.map((item) => ({
        ...item,
        label: item?.name,
        value: item?.id,
      })),
    [isolatesDataFromStore]
  );

  const farmOptions = React.useMemo(
    () =>
      isolatesDataFromStore?.farmDtos?.map((item) => ({
        ...item,
        label: item?.name,
        value: item?.id,
      })),
    [isolatesDataFromStore]
  );

  return {
    onRowSelectChange,
    onChange,
    handleClick,
    searchKey: state.searchKey,
    fetchIsolateIsLoading,
    rows,
    cols,
    pageSize: state.pageSize,
    totalPages,
    pageNumber: state.pageNumber,
    currentPage,
    defaultPage,
    paginationOnClick,
    customer: state.customer,
    setCustomer: (value) =>
      setState((state) => ({ ...state, customer: value })),
    customerOptions,
    farm: state.farm,
    setFarm: (value) => setState((state) => ({ ...state, farm: value })),
    farmOptions,
    sortModel: state.sortModel,
    setSearchKey: (value) =>
      setState((state) => ({ ...state, searchKey: value })),
    isolatesDataFromStore,
    isAll: state.isAll,
    isExtend: state.isExtend,
    isWithout: state.isWithout,
    isShare: state.isShare,
    isExtendedBtnDisabled: state.isExtendedBtnDisabled,
    isShareDisabled: state.isShareDisabled,
    onExtendButtonClick,
    resetSelectedRows,
    selectedRows,
    handleKeyPress,
    onRowClick,
  };
};

const IsolateInventoryOrganism = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const {
    onRowSelectChange: onRowSelectionModelChange,
    paginationOnClick,
    onChange,
    searchKey,
    fetchIsolateIsLoading,
    rows,
    cols,
    pageSize,
    totalPages,
    pageNumber,
    currentPage,
    defaultPage,
    customer,
    customerOptions,
    farm,
    farmOptions,
    sortModel,
    handleClick,
    isAll,
    isExtend,
    isWithout,
    isShare,
    isExtendedBtnDisabled,
    onExtendButtonClick,
    handleKeyPress,
    onRowClick,
  } = useForm();

  return (
    <Grid
      direction={'column'}
      container
      rowGap={4}
      mt={4}
      sx={{
        background: theme.palette.neutral[50],
      }}
      padding={{ xs: 2, md: 4 }}
    >
      <Grid container alignItems="center" justifyContent="center" spacing={1}>
        <Grid item xs>
          <SearchBox
            size="medium"
            placeholder={t('global.searchPlaceHolder')}
            startIcon={<Search />}
            onChange={(event) =>
              onChange(
                {
                  target: { ...event?.target, name: 'searchKey' },
                },
                event?.target?.value
              )
            }
            onKeyDown={handleKeyPress}
            value={searchKey}
          />
        </Grid>
        <Grid item>
          <CustomButton
            variant="outlined"
            size="medium"
            onClick={() =>
              handleClick({ target: { name: 'searchKey' } }, searchKey)
            }
          >
            <Typography variant="body2" fontWeight={600}>
              {t('global.search')}
            </Typography>
          </CustomButton>
        </Grid>
      </Grid>
      <Grid container xs={12} sx={{ position: 'sticky', top: 0, zIndex: 1000 }}>
        <Grid
          direction={'row'}
          container
          sx={{
            background: theme.palette.neutral[100],
            paddingY: 1,
            paddingX: 1,
          }}
        >
          <FilterButtons
            handleClick={handleClick}
            isAll={isAll}
            isExtend={isExtend}
            isWithout={isWithout}
            isShare={isShare}
            isExtendedBtnDisabled={isExtendedBtnDisabled}
            onExtendButtonClick={onExtendButtonClick}
          />
          <SelectContainer
            farm={farm}
            setFarm={(value) => {
              onChange(
                { target: { name: 'farmId' } },
                { value: value?.value, data: value }
              );
            }}
            farmOptions={farmOptions}
            t={t}
            customer={customer}
            setCustomer={(value) => {
              onChange(
                { target: { name: 'customerId' } },
                { value: value?.value, data: value }
              );
            }}
            customerOptions={customerOptions}
          />
        </Grid>
      </Grid>
      <CustomDataGrid
        rows={rows}
        columns={cols?.map((col) => {
          return {
            ...col,
            renderHeader: () => (
              <CustomHeader
                column={col}
                sortModel={sortModel}
                onSortModelChange={(sortModel, sort) => {
                  handleClick(
                    { target: { name: 'sort' } },
                    { sortModel, sort }
                  );
                }}
              />
            ),
          };
        })}
        pageSize={pageSize}
        totalPages={totalPages}
        pageNumber={pageNumber}
        onRowSelectionModelChange={onRowSelectionModelChange}
        loading={fetchIsolateIsLoading}
        PaginationComponent={
          <CustomPagination
            currentPage={currentPage}
            handleChange={paginationOnClick}
            defaultPage={defaultPage}
            totalPages={totalPages}
          />
        }
        noRowLabel={t('isolates.notFound')}
        rowHeight={100}
        disableColumnMenu={false}
        onRowClick={onRowClick}
      />
    </Grid>
  );
};

export default IsolateInventoryOrganism;
