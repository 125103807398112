import { Middleware, MiddlewareAPI, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import orderFormReducer from 'store/features/orderForm/index.slice';
import authReducer from 'store/features/auth/index.slice';
import stateConfigFormReducer from 'store/features/stateConfigForm/index.slice';
import addressesManagerReducer from 'store/features/addressesManager/index.slice';
import isolateReducer from 'store/features/isolate/index.slice';
import apiErrorSlice, { setApiError } from 'store/features/error/index.slice';

import { api } from 'config/api';

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    const setError = (action) => {
      const status = action?.meta?.baseQueryMeta?.response?.status;
      const message = action?.meta?.baseQueryMeta?.response?.statusText;

      api.dispatch(setApiError({ status, message }));
    };

    if (
      [500, 503, 404].includes(action?.meta?.baseQueryMeta?.response?.status)
    ) {
      setError(action);
    }

    return next(action);
  };

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    orderFormReducer,
    authReducer,
    stateConfigFormReducer,
    addressesManager: addressesManagerReducer,
    isolateReducer: isolateReducer,
    apiErrorSlice: apiErrorSlice,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware).concat(rtkQueryErrorLogger),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
