import { TableCell, TableContainer, Typography, styled } from '@mui/material';

export const StyledTableContainer = styled(TableContainer)`
  ${({ theme }) => {
    return {
      width: '100%',
      [theme.breakpoints.down('md')]: {
        maxWidth: 320,
      },
    };
  }}
`;

export const StyledTableHeadCell = styled(TableCell)`
  padding-left: 16px;
`;

export const StyledTableHeadCellText = styled(Typography)`
  ${({ theme }) => {
    return {
      paddingLeft: '8px',
      fontWeight: 600,
      fontSize: 14,
      color: theme.palette.neutral[700],
    };
  }}
`;

export const StyledForm = styled('form')`
  padding: 2rem 2rem 2rem 2rem;
  min-width: 500px;
  min-height: 300px;
`;

export const StyledConfirmationForm = styled('form')`
  padding: 2rem 2rem 2rem 2rem;
  min-width: 500px;
  min-height: 150px;

  ${({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      minWidth: 250,
      padding: '0',
    },
  })}
`;
