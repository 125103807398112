import * as React from 'react';
import { SVGProps } from 'react';

const EditPen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    viewBox="-4 -2 20 20"
    fill="none"
    {...props}
  >
    <path
      fill="#29306B"
      d="m13.842 1.718.378.378a.747.747 0 0 1 0 1.06l-.91.912-1.44-1.44.91-.91a.747.747 0 0 1 1.059 0h.003ZM6.617 7.884l4.193-4.197 1.441 1.44-4.197 4.194a.748.748 0 0 1-.325.19l-1.828.523.522-1.829a.748.748 0 0 1 .19-.325l.004.004ZM11.72.659 5.554 6.82a2.233 2.233 0 0 0-.572.972l-.893 3.125a.75.75 0 0 0 .928.928l3.125-.894c.369-.106.703-.303.972-.572l6.165-6.162a2.25 2.25 0 0 0 0-3.181l-.378-.378a2.25 2.25 0 0 0-3.181 0ZM2.81 1.877a2.75 2.75 0 0 0-2.75 2.75v8.5a2.75 2.75 0 0 0 2.75 2.75h8.5a2.75 2.75 0 0 0 2.75-2.75v-3.5a.748.748 0 0 0-.75-.75.748.748 0 0 0-.75.75v3.5c0 .691-.559 1.25-1.25 1.25h-8.5c-.69 0-1.25-.559-1.25-1.25v-8.5c0-.69.56-1.25 1.25-1.25h3.5c.416 0 .75-.334.75-.75a.748.748 0 0 0-.75-.75h-3.5Z"
    />
  </svg>
);
export default EditPen;
