import { Checkbox, styled } from '@mui/material';

export const StyledCheckBox = styled(Checkbox)(() => ({
  '.css-i4bv87-MuiSvgIcon-root': {
    fontSize: '1.8rem',
    paddingRight: '15px',
    width: '54px',
    height: '31px',
  },
}));
