import { SVGProps } from 'react';

const SmallArrowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={7}
    fill="none"
    {...props}
  >
    <path
      fill="#6A7178"
      d="M11.25 1.881 6.5 6.351a.716.716 0 0 1-.5.218.849.849 0 0 1-.531-.188l-4.75-4.5C.406 1.601.406 1.131.688.82c.28-.313.75-.313 1.062-.031l4.25 4 4.219-4C10.53.506 11 .506 11.28.819c.281.312.281.781-.031 1.062Z"
    />
  </svg>
);
export default SmallArrowDown;
