import { Box, Link, TableCell, Typography, styled } from '@mui/material';
import theme from 'theme';

export const StyledHeadlineContainer = styled(Box)`
  ${({ theme }) => {
    return {
      background: theme.palette.primary[50],
      border: 'none',
      display: 'flex',
      cursor: 'pointer',
      borderRadius: '80px',
      padding: '10px 20px',
      textAlign: 'left',
      maxWidth: 400,
    };
  }}
`;

export const StyledTableBodyCellText = styled(Typography)`
  ${({ theme }) => {
    return {
      fontWeight: 400,
      color: theme.palette.neutral.main,
      inlineSize: '150px',
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
      cursor: 'pointer',
    };
  }}
`;

export const StyledTableHeadCell = styled(TableCell)`
  padding-left: 0px;
`;

export const StyledTableBodyCell = styled(TableCell)`
  padding-left: 0px;
  height: 10px !important;
  max-height: 10px !important;
`;

export const StyledTableHeadCellText = styled(Typography)`
  ${({ theme }) => {
    return {
      fontWeight: 600,
      color: theme.palette.neutral[700],
      inlineSize: '150px',
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      cursor: 'pointer',
      width: '100%',
    };
  }}
`;

export const StyledLink = styled(Link)`
  ${{
    fontFamily: theme.typography.fontFamily,
    fontSize: '22px',
    paddingRight: '16px',
    fontStyle: 'normal',
    lineHeight: '27px',
    alignItems: 'center',
    display: 'inline-flex',
    color: theme.palette.primary[600],
  }}
`;

export const StyledTitle = styled(Typography)`
  ${{
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '35px',
    color: theme.palette.primary.main,
  }}
`;
