import React, { useEffect, useState } from 'react';
import { AddressFormProps } from './types';
import CustomTable from 'components/molecules/Table';
import { StyledAddressesGridBox, StyledAddressesHeaderBox } from './styles';
import AddressFormHeaderWithStates from '../HeaderWithStates';
import useSelectableRows from 'hooks/useSelectableRows';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedStateId } from 'store/features/addressesManager/index.slice';
import {
  getAddressesByStateForm,
  getSelectedStateId,
  getSelectedStateIdForm,
  getStatesFormData,
} from 'store/features/addressesManager/index.selectors';

const AddressFormContentViewer: React.FC<AddressFormProps> = ({
  addressData,
  editMode,
  subTitle,
  onClose,
  addressType,
  onSelectDone,
  modePreviewSelectCount,
  modePreviewTotalCount,
  selectType = 'multiple',
  addressesByState,
  activeTab = 0,
  fromShippingPage = false,
  isOrderForm = false,
  isOrderDetails,
  isFarmsDisplayed,
}) => {
  const statesFormData = useSelector(getStatesFormData);

  const selectedStateIdForm = useSelector(getSelectedStateIdForm);

  const addressesByStateForm = useSelector(getAddressesByStateForm);

  const selectedStateId = useSelector(getSelectedStateId);

  const [state, actions] = useSelectableRows({
    rows: addressData,
    initialMode: selectType,
    stateId: selectedStateId,
  });
  const { isAllChecked, selectedRows } = state;
  const { onSelectAllRows, onSelectRow, clearSelectedRows } = actions;

  const handleStateClick = (state: StateInfo) => {
    if (isFarmsDisplayed) {
      return;
    }
    if (
      (isOrderForm || isOrderDetails
        ? selectedStateIdForm
        : selectedStateId) === state.id
    ) {
      console.log(
        `Deselected State - Name: ${state?.name}, Id: ${state?.id}, Code: ${state?.code}`
      );
    } else {
      dispatch(setSelectedStateId(state.id));
    }
  };

  useEffect(() => {
    if (!fromShippingPage) {
      return;
    }
    clearSelectedRows();
  }, [selectedStateId, selectedStateIdForm]);

  type StateInfo = {
    name: string;
    id?: string;
    code?: string;
    color?: string;
  };

  const dispatch = useDispatch();

  const [filteredAddresses, setFilteredAddresses] = useState<any[]>([]);

  useEffect(() => {
    // Define a function to filter the address data
    const filterAddresses = (addresses: any[], stateId: string) => {
      return addresses.filter((address) => address.shippingStateId === stateId);
    };

    // Assuming addressData is available in your component,
    // you would replace 'addressData' with your actual data source
    const updatedFilteredAddresses = filterAddresses(
      addressData,
      selectedStateId
    );

    // Update the state with the filtered data
    setFilteredAddresses(updatedFilteredAddresses);
  }, [selectedStateId]);

  return (
    <form>
      <StyledAddressesHeaderBox>
        {fromShippingPage && (
          <AddressFormHeaderWithStates
            subTitle={subTitle}
            addressData={addressData}
            editMode={editMode}
            onSelectRow={onSelectRow}
            onSelectAllRows={onSelectAllRows}
            selectedRows={selectedRows}
            isAllChecked={isAllChecked}
            id={''}
            onClose={onClose}
            addressType={addressType}
            onSelectDone={onSelectDone}
            modePreviewSelectCount={modePreviewSelectCount}
            modePreviewTotalCount={modePreviewTotalCount}
            showSelectAll={selectType === 'multiple'}
            statesData={statesFormData}
            selectedStateId={isFarmsDisplayed ? '' : selectedStateId}
            handleStateClick={handleStateClick}
            isStateActive={activeTab == 1 || isOrderDetails}
            addressesByState={
              isOrderForm ? addressesByStateForm : addressesByState
            }
            displayAddAddress={false}
            isOrderForm={isOrderForm}
          />
        )}
      </StyledAddressesHeaderBox>
      <StyledAddressesGridBox>
        <CustomTable
          rows={isFarmsDisplayed ? addressData : filteredAddresses}
          selectionMode={editMode}
          hasPagination={false}
          isAllChecked={isAllChecked}
          selectedRows={selectedRows}
          onSelectRow={onSelectRow}
          onSelectAllRows={onSelectAllRows}
          rowsPerPage={100}
          addressType={addressType}
          showSelectAll={selectType === 'multiple'}
          showStatus={true}
        />
      </StyledAddressesGridBox>
    </form>
  );
};

export default AddressFormContentViewer;
