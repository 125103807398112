import React, { useState } from 'react';
import CustomSelect from 'components/atoms/Select';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { SerialWithStatus, SerialTimelineProps, OrderStep } from './types';
import {
  StyledSelectContainer,
  StyledHorizontalLine,
  StyledDateTypography,
  StyledDescriptionTypography,
  StyledContainer,
  StyledTimelineWrapper,
  InnerBox,
  DateDescriptionBox,
} from './styles';
import TextBadge from 'components/atoms/TextBadge';

const OrderTimeLine: React.FC<{
  steps: OrderStep[];
}> = ({ steps }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <StyledTimelineWrapper>
      {steps?.map((step, index, array) => (
        <StyledContainer
          key={step?.id}
          width="100%"
          currentStepIndex={step?.current}
        >
          <InnerBox isCentered={!step?.current}>
            <TextBadge name={step.name} color={step?.color} variant="body4" />
            {!step?.current && (
              <StyledHorizontalLine
                style={{
                  opacity: index === array.length - 1 && !isMobile ? 0 : 1,
                }}
              />
            )}
          </InnerBox>
          <DateDescriptionBox>
            <StyledDateTypography variant="body2">
              {step?.date as any}
            </StyledDateTypography>
            <StyledDescriptionTypography variant="body2">
              {step?.description}
            </StyledDescriptionTypography>
          </DateDescriptionBox>
        </StyledContainer>
      ))}
    </StyledTimelineWrapper>
  );
};

const SerialTimeline: React.FC<SerialTimelineProps> = ({
  orderSerials,
  hideSelect,
}) => {
  const [selectedSerial, setSelectedSerial] = useState<SerialWithStatus>(
    orderSerials?.[0]
  );

  const handleSelectChange = (event: any, value: any) => {
    const serial = orderSerials?.find(
      (serial) => serial?.serialNumber === value.value
    );

    if (serial) {
      setSelectedSerial(serial);
    }
  };

  if (hideSelect && orderSerials?.[0]?.steps?.length === 0) {
    return <Box>No serials planned</Box>;
  }
  return (
    <Box>
      {hideSelect && (
        <StyledSelectContainer data-testid="select-container">
          <CustomSelect
            options={orderSerials?.map((serial) => ({
              label: serial?.serialNumber,
              value: serial?.serialNumber,
            }))}
            value={{
              label: selectedSerial?.serialNumber,
              value: selectedSerial?.serialNumber,
            }}
            onChange={handleSelectChange}
            withCheckbox={false}
            placeholder="Select Serial Number"
            size="small"
          />
        </StyledSelectContainer>
      )}

      <OrderTimeLine steps={selectedSerial?.steps} />
    </Box>
  );
};

export default SerialTimeline;
