import { Grid, Paper, styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

export const StyledNoteContainer = styled(Paper)`
  ${() => ({
    position: 'relative',
    padding: '0.25rem',
    marginTop: '2rem',
    background: 'transparent',
    boxShadow: 'none',
    '&:last-child::after': {
      display: 'none',
    },
    '&::after': {
      content: '""',
      display: 'block',
      height: '1px',
      backgroundColor: 'rgba(0,0,0,0.2)',
      marginTop: '0.5rem',
    },
  })}
`;

export const StyledScrollableContent = styled(Box)`
  ${() => ({
    overflowY: 'auto',
    marginTop: '0.5rem',
    maxHeight: '42px',
    width: '100%',
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px grey',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'darkgrey',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-button': {
      display: 'none',
    },
    '& p': {
      fontWeight: 300,
    },
  })}
`;

export const StyledEditButton = styled(IconButton)`
  ${() => ({
    position: 'relative',
    float: 'right',
  })}
`;

export const StyledNotesContainer = styled(Grid)`
  ${() => ({
    margin: '0 auto',
  })}
`;

export const StyledNote = styled(Grid)`
  ${() => ({
    width: '100%',
  })}
`;
export const StyledForm = styled('form')`
  padding: 1rem 1rem 1rem 1rem;
  min-width: 500px;
  min-height: 250px;
`;
