import { useState } from 'react';

const usePdfUpload = (uploadFn) => {
  const [res, setRes] = useState(null);
  const [error, setError] = useState(null);

  const handlePdfUpload = async (payload) => {
    try {
      const res = await uploadFn(payload);
      setRes(res);
      return res;
    } catch (e) {
      setError(null);
    }
  };

  return {
    error,
    res,
    handlePdfUpload,
  };
};

export default usePdfUpload;
