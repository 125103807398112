import { useTranslation } from 'react-i18next';
import { LANGUAGE_CODES, LANGUAGE_NAMES } from 'constants/index';

import { useChangeLanguage } from 'hooks/useChangeLanguage';

import React from 'react';

import { UsFlagIcon, FranceFlagIcon } from 'components/icons';

import { LanguageOption } from './types';
import {
  StyledValueContainerBox,
  StyledValueIconBox,
  StyledIconBox,
  StyledContainerBox,
  StyledSelect,
} from './styles';

const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();
  const [changeLanguage] = useChangeLanguage();

  const languages: LanguageOption[] = [
    {
      label: LANGUAGE_NAMES.English,
      value: LANGUAGE_CODES.en,
      icon: <UsFlagIcon />,
    },
    {
      label: LANGUAGE_NAMES.French,
      value: LANGUAGE_CODES.fr,
      icon: <FranceFlagIcon />,
    },
  ];

  const onLanguageChange = (e: any, value: any) => {
    const language = value.value;
    changeLanguage(language);
  };
  return (
    <StyledSelect
      options={languages}
      value={languages.find((lang) => lang.value === i18n.language)}
      onChange={onLanguageChange}
      placeholder="placeholder"
      sx={{ mt: 0 }}
      withCustomOption={true}
      renderOption={(option: any) => {
        return (
          <StyledContainerBox>
            <StyledIconBox> {option.icon} </StyledIconBox>
            <div>{option.label}</div>
          </StyledContainerBox>
        );
      }}
      renderValue={(value: any) => {
        return (
          <StyledValueContainerBox>
            <StyledValueIconBox> {value.icon} </StyledValueIconBox>
            <div>{value.label}</div>
          </StyledValueContainerBox>
        );
      }}
    />
  );
};

export default LanguageSelector;
