import { useState } from 'react';

export const useLocalStorage = (
  key: string,
  initialValue: string
): [string, (value: string) => void] => {
  const fetchFromLocalStorage = (key: string, defaultValue: string): string => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? item : defaultValue;
    } catch (error) {
      console.error(error);
      return defaultValue;
    }
  };

  const [storedValue, setStoredValue] = useState(() =>
    fetchFromLocalStorage(key, initialValue)
  );

  const setValue = (value: string) => {
    window.localStorage.setItem(key, value);
    setStoredValue(value);
  };

  return [storedValue, setValue];
};
