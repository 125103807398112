import React from 'react';
import { FormControlLabel, Radio } from '@mui/material';

import { RadioButtonProps } from './types';
import { StyledContainer, StyledErrorMessage, StyledLabel } from './styles';
import {
  RadioActiveIcon,
  RadioActiveDisabledIcon,
  RadioNormalIcon,
  RadioDisabledIcon,
  RadioErrorIcon,
} from 'components/icons';

const CustomRadioButton: React.FC<RadioButtonProps> = ({
  label = '',
  value,
  checked,
  disabled,
  type = 'normal',
  error,
  errorMessage,
  onChange,
  ...props
}) => {
  const getIcon = () => {
    if (type === 'active') return <RadioActiveIcon />;
    else if (type === 'active-disabled') return <RadioActiveDisabledIcon />;
    else if (type === 'normal') return <RadioNormalIcon />;
    else if (type === 'disabled') return <RadioDisabledIcon />;
    else if (type === 'error') return <RadioErrorIcon />;
    return <RadioNormalIcon />;
  };

  return (
    <StyledContainer>
      <FormControlLabel
        {...props}
        control={<Radio icon={getIcon()} disableRipple={true} />}
        label={<StyledLabel>{label}</StyledLabel>}
        onChange={onChange}
        value={value}
        checked={checked}
        disabled={disabled}
      />
      {error && (
        <StyledErrorMessage variant="body2" color="error">
          {errorMessage}
        </StyledErrorMessage>
      )}
    </StyledContainer>
  );
};

export default CustomRadioButton;
