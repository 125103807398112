const EditWithoutTitleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill="#6A7178"
      d="M9.373 6.013l.614.614-6.04 6.04h-.614v-.614l6.04-6.04zM11.773 2a.667.667 0 00-.466.193l-1.22 1.22 2.5 2.5 1.22-1.22c.26-.26.26-.68 0-.94l-1.56-1.56A.655.655 0 0011.773 2zm-2.4 2.127L2 11.5V14h2.5l7.373-7.373-2.5-2.5z"
    ></path>
  </svg>
);

export default EditWithoutTitleIcon;
