import { Button, Grid, Typography, styled } from '@mui/material';
import theme from 'theme';

export const StyledHeader = styled(Grid)`
  display: flex;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid ${theme.palette.primary[900]};
`;
export const StyledTypography = styled(Typography)`
  color: ${theme.palette.primary[900]};
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: ${theme.typography.fontFamily};
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
`;
export const StyledButton = styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: none;
  border-radius: 4px;
  color: ${theme.palette.primary[900]};
  font-weight: 500;
  border: 1px solid ${theme.palette.primary[900]};
  background: ${theme.palette.neutral[50]};
`;
