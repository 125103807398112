import { SVGProps } from 'react';

const NormalCheckBox = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <rect width={24} height={24} x={8} y={8} fill="#fff" rx={4} />
    <rect width={24} height={24} x={8} y={8} stroke="#DEE2E6" rx={4} />
  </svg>
);
export default NormalCheckBox;
