import * as React from 'react';
import { SVGProps } from 'react';

const Extend = (props: SVGProps<SVGSVGElement> & { disabled?: boolean }) => {
  const { fill, disabled = false } = props;

  const fillColor = () => {
    return disabled ? '#DEE2E6' : fill ?? '#000';
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={25}
      fill="none"
      {...props}
    >
      <path
        fill={fillColor()}
        d="M7.88 3.96 6.6 2.43 2 6.28l1.29 1.53 4.59-3.85ZM22 6.29l-4.6-3.86-1.29 1.53 4.6 3.86L22 6.29ZM12 4.57a9 9 0 0 0-9 9c0 4.97 4.02 9 9 9a9 9 0 0 0 0-18Zm0 16c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7Zm1-11h-2v3H8v2h3v3h2v-3h3v-2h-3v-3Z"
      />
    </svg>
  );
};

export default Extend;
