import { Box, styled } from '@mui/material';

export const StyledBox = styled(Box)`
  ${() => ({
    overflowX: 'auto',
    display: 'flex',
    flexGrow: 1,
    gap: 10,
    scrollBehavior: 'smooth',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    scrollbarWidth: 'none',
  })}
`;
