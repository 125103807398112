import { styled, Grid } from '@mui/material';
import { Link } from 'react-scroll';
export const StyledLink = styled(Grid)`
  display: flex;
  height: 37px;
  align-items: center;
  gap: 23px;
  width: 100%;
  align-self: stretch;
  &:hover {
    cursor: pointer;
  }
`;
export const StyledContentLink = styled(Link)`
  color: var(--brand-primary-base);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: ${(props) => (props.active ? 600 : 330)};
  line-height: 21px;
`;
export const StyledContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  width: 520px;

  ${({ theme }) => {
    return {
      [theme.breakpoints.down('md')]: {
        margin: 'auto',
        marginLeft: '20px',
        marginBottom: '20px',
        width: '300px',
      },
    };
  }};
`;
