import {
  Theme,
  styled,
  TextField,
  Typography,
  TypographyProps,
} from '@mui/material';
import { CustomInputProps, StyledErrorTextProps } from './types';
import { Size } from 'types';

const getVariantStyle = (theme: Theme, variant: string | undefined) => {
  if (variant === 'outlined')
    return {
      backgroundColor: theme.palette.neutral[50],
      color: theme.palette.neutral[900],
      borderRadius: '4px',
      'input::placeholder': {
        color: theme.palette.neutral[700],
      },

      '&:hover': {
        outline: `1px solid ${theme.palette.neutral[600]}`,
      },
      '&:active': {
        outline: `1px solid ${theme.palette.primary['900']}`,
        boxShadow: 'inset 0px 250px 0px rgba(16, 18, 19, 0.22)',
      },
      '&:focus': {
        outline: `1px solid ${theme.palette.primary['900']}`,
      },
      'input:disabled': {
        backgroundColor: `${theme.palette.neutral[200]} !important`,
        color: theme.palette.neutral[600],
      },
    };
};

const getSize = (size: Size) => {
  if (size === 'small') {
    return {
      '& input': {
        paddingBlock: '6px',
      },
    };
  } else if (size === 'medium') {
    return {
      '& input': {
        paddingBlock: '10px',
      },
    };
  } else if (size === 'large') {
    return {
      '& input': {
        paddingBlock: '12px',
      },
    };
  }
};

const getErrorStyle = (theme: Theme, error: boolean | undefined) => {
  if (error) {
    return {
      borderColor: theme.palette.error[900],
    };
  }
};

const StyledInput = styled(TextField)<CustomInputProps>`
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  min-width: 50px;

  ${({ variant, theme }) => {
    return getVariantStyle(theme, variant);
  }};

  ${({ size }) => {
    return getSize(size as Size);
  }}
  ${({ error, theme }) => {
    return getErrorStyle(theme, error);
  }}
`;

const StyledTypography = styled(Typography)<
  TypographyProps & { error: boolean }
>`
  ${({ error, theme }) => {
    if (error) {
      return {
        marginTop: '8px',
        color: theme.palette.error.main,
      };
    }
  }}
`;

export const StyledErrorText = styled('span')<
  StyledErrorTextProps & { theme?: Theme }
>`
  ${({ size }) => {
    if (['small', 'medium'].includes(size))
      return {
        fontSize: 12,
      };

    return { fontSize: 14 };
  }};
  margin-top: 8;
  color: ${({ theme }) => theme.palette.error.main};
`;

export { StyledTypography, StyledInput };
