import * as React from 'react';
import { SVGProps } from 'react';

const RadioActive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <rect width={24} height={24} fill="#29306B" rx={12} />
    <rect width={12} height={12} x={6} y={6} fill="#fff" rx={6} />
  </svg>
);
export default RadioActive;
