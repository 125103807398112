import ShareRequestOrganism from 'components/organisms/ShareRequest';
import withLayout from 'layout';
import { useGetIsolateForShareQuery } from 'api/shareIsolate';
import { useEffect } from 'react';
import { PAGES } from 'constants/index';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserRole } from 'store/features/auth/index.selector';

const ShareRequest = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const userRole = useSelector(getUserRole);
  const { data, isLoading } = useGetIsolateForShareQuery(
    { id: Number(id) },
    {
      skip: !Number(id),
    }
  );

  useEffect(() => {
    if (isNaN(Number(id))) navigate(PAGES[userRole].NOT_FOUND);
  }, [id]);

  return (
    <ShareRequestOrganism
      data={data?.data}
      isLoading={isLoading}
      isolateId={id}
    />
  );
};

export default withLayout(ShareRequest);
