import { SVGProps } from 'react';
const Close = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={11}
    fill="none"
    {...props}
  >
    <path
      fill={props?.fill || '#272B30'}
      d="M10.75 9.524c.281.313.281.782 0 1.063-.313.312-.781.312-1.063 0L6 6.868l-3.719 3.719c-.312.312-.781.312-1.062 0-.313-.281-.313-.75 0-1.063l3.718-3.718-3.718-3.72c-.313-.312-.313-.78 0-1.062a.684.684 0 0 1 1.031 0L6 4.774l3.719-3.718a.684.684 0 0 1 1.031 0c.313.28.313.75 0 1.062L7.031 5.806l3.719 3.718Z"
    />
  </svg>
);
export default Close;
