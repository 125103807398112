import Toast from 'components/atoms/Toast';
import React, { useMemo, useState } from 'react';

export const ToastContext: any = React.createContext({});

const RenderToast = () => {
  const { state }: any = React.useContext(ToastContext);

  return (
    <Toast
      message={state.message}
      type={state.type}
      anchorOrigin={
        state?.position || { vertical: 'top', horizontal: 'center' }
      }
      open={state?.open}
      setOpen={state?.setClose}
      autoHideDuration={state?.duration}
      onFinishCallBack={state?.onFinishCallBack}
      key={state?.key}
    />
  );
};

const ToastProvider = ({ children }) => {
  const [state, setState] = useState({
    message: '',
    type: '',
    open: false,
    duration: 0,
    position: '',
    key: '',
    setOpen: undefined,
    props: {},
  });
  const value = useMemo(() => ({ state: state, setState: setState }), [state]);
  return (
    <ToastContext.Provider value={value}>
      {children}
      <RenderToast />
    </ToastContext.Provider>
  );
};

export default ToastProvider;
