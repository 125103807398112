import { api } from 'config/api';

interface CancelRequest {
  cancelReason: string;
}

interface unsignedOrderCount {
  count: number;
  statusSearchKey: string;
}

export const dashboardApi = api.injectEndpoints({
  endpoints: (build) => ({
    createReminderOrderDate: build.mutation<void, { id: number; date: string }>(
      {
        query: ({ id, date }) => ({
          url: `Order/${id}/reminder`,
          method: 'PUT',
          body: date,
        }),
        invalidatesTags: ['Orders'],
      }
    ),

    cancelOrder: build.mutation<
      void,
      { id: number; cancelRequest: CancelRequest }
    >({
      query: ({ id, cancelRequest }) => ({
        url: `Order/${id}/cancel`,
        method: 'PUT',
        body: cancelRequest,
      }),
      invalidatesTags: ['Orders'],
    }),

    getUnsignedOrdersCount: build.query<unsignedOrderCount, void>({
      query: () => ({
        url: 'Order/unsignedOrdersCount',
        method: 'GET',
      }),
    }),

    sendVetSignatureReminder: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `Vet/sendVetSignatureReminder/${id}`,
        method: 'PUT',
      }),
    }),
  }),
});
export const {
  useCreateReminderOrderDateMutation,
  useCancelOrderMutation,
  useLazyGetUnsignedOrdersCountQuery,
  useSendVetSignatureReminderMutation,
} = dashboardApi;
