import React, { SVGProps } from 'react';

const CircleUp = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={40}
      fill="none"
      {...props}
    >
      <path
        fill="#101213"
        d="M20.75 14.375a1.073 1.073 0 0 0-.797-.375c-.375.047-.656.14-.844.375l-4.078 4.5c-.328.328-.375.797-.187 1.219.14.422.562.656.984.656h2.625l.047 4.125c0 .656.469 1.125 1.125 1.125h.75c.61 0 1.078-.469 1.078-1.125V20.75h2.625c.422 0 .844-.234 1.031-.656.141-.422.094-.89-.234-1.219l-4.125-4.5ZM20 8C13.344 8 8 13.39 8 20c0 6.656 5.344 12 12 12 6.61 0 12-5.344 12-12 0-6.61-5.39-12-12-12Zm0 21.75c-5.39 0-9.75-4.36-9.75-9.75 0-5.344 4.36-9.75 9.75-9.75 5.344 0 9.75 4.406 9.75 9.75 0 5.39-4.406 9.75-9.75 9.75Z"
      />
    </svg>
  );
};
export default CircleUp;
