import {
  InputAdornment,
  InputLabel,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { StyledInput, StyledErrorText } from './styles';
import { CustomInputProps } from './types';
import React from 'react';

const CustomInput: React.FC<CustomInputProps> = ({
  size = 'small',
  variant = 'outlined',
  errorMessage,
  startIcon,
  endIcon,
  label = '',
  error,
  ...props
}) => {
  const theme = useTheme();
  return (
    <>
      <InputLabel id="custom-input-label">
        <Tooltip placement="top" title={label}>
          <Typography
            variant="body1"
            color={theme.palette.neutral[800]}
            sx={{ mb: 0.5 }}
            {...props?.labelProps}
          >
            {label}
          </Typography>
        </Tooltip>
      </InputLabel>
      <StyledInput
        size={size}
        variant={variant}
        InputProps={{
          startAdornment: startIcon ? (
            <InputAdornment position="start">{startIcon}</InputAdornment>
          ) : null,
          endAdornment: endIcon ? (
            <InputAdornment position="end">{endIcon}</InputAdornment>
          ) : null,
        }}
        {...props}
        error={error}
      />
      {error && <StyledErrorText size={size}>{errorMessage}</StyledErrorText>}
    </>
  );
};

export default CustomInput;
