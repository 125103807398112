import * as React from 'react';
import { SVGProps } from 'react';

const ActiveDisabledCheckBox = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <rect width={24} height={24} x={8} y={8} fill="#E9ECEF" rx={4} />
    <path
      fill="#DEE2E6"
      d="M26.75 15.219c.313.312.313.781 0 1.062l-8.25 8.25a.684.684 0 0 1-1.031 0l-4.25-4.25c-.313-.281-.313-.75 0-1.062a.736.736 0 0 1 1.031 0l3.75 3.75 7.719-7.75a.736.736 0 0 1 1.031 0Z"
    />
    <rect width={24} height={24} x={8} y={8} stroke="#DEE2E6" rx={4} />
  </svg>
);
export default ActiveDisabledCheckBox;
