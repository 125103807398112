import { SVGProps } from 'react';
const TranslationFranceFlag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 512 512"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M112 0C50.144 0 0 50.144 0 112v288c0 61.856 50.144 112 112 112h58.667V0H112z"
      style={{
        fill: '#41479b',
      }}
    />
    <path
      d="M170.667 0v512h170.666V0z"
      style={{
        fill: '#f5f5f5',
      }}
    />
    <path
      d="M400 0h-58.667v512H400c61.856 0 112-50.144 112-112V112C512 50.144 461.856 0 400 0z"
      style={{
        fill: '#ff4b55',
      }}
    />
  </svg>
);
export default TranslationFranceFlag;
