import { Divider, Grid, Typography, useTheme } from '@mui/material';
import {
  StyledBottleFormTotalRow,
  StyledCustomSelect,
  StyledForm,
  StyledGridContainer,
} from './styles';
import NumericInput from 'components/atoms/NumericInput';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDosingAndFillingInfo,
  getInfectionData,
} from 'store/features/orderForm/index.selectors';
import {
  BottlesPricingInfoKeys,
  DosingAndFillingInfoKeys,
  ORDER_FORM_STEP_NAMES,
} from 'constants/index';
import { setFieldValue } from 'store/features/orderForm/index.slice';
import CustomInput from 'components/atoms/Input';
import { useTranslation } from 'react-i18next';
import StepperButtonGroup from 'components/molecules/StepperButtonGroup';
import { OrderFormStepComponentProps } from 'types';
import { getFieldVisibility, isNumber, toSpaceSeparatedNumber } from 'utils';

import * as Yup from 'yup';
import InfoField from 'components/atoms/InfoField';
import Info from 'components/icons/Info';
import RenderByCondition from 'components/atoms/RenderByCondition';

const useForm = () => {
  const initialValues = useSelector(getDosingAndFillingInfo);
  const { t } = useTranslation();

  const validationSchema = Yup.object({
    bottles: Yup.object().shape({
      totalCount: Yup.number()
        .min(1, t('errorMessages.numberOfBottlesRequired'))
        .required(t('errorMessages.numberOfBottlesRequired')),
    }),
  });

  const {
    values,
    errors,
    touched,
    isValid,
    handleBlur,
    setFieldValue: formikSetFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      console.log();
    },
    enableReinitialize: true,
    validateOnMount: true,
  });

  return {
    values,
    errors,
    touched,
    isValid,
    handleBlur,
    formikSetFieldValue,
  };
};

const { BOTTLE_USAGE_VISIBILITY, LIMIT_OF_BOTTLES_VISIBILITY } =
  getFieldVisibility();

const TitleWithInput = (props) => {
  const { fieldName, onChange, value } = props;
  const { t } = useTranslation();

  const handleChange = (e: any) => {
    const fieldValue = e.target.value;

    if (!isNumber(fieldValue) && fieldValue !== '') return;

    const numberValue = parseInt(fieldValue) || 0;

    onChange(e, numberValue);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h5" color="neutral.800" fontWeight={'normal'}>
          {t('orderForm.productionPlanning')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={8}>
            <Typography variant="body2">
              {t('orderForm.usageOfBottleText')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <CustomInput
              size="medium"
              name={fieldName}
              value={value === 0 ? '' : value}
              label=""
              onChange={(event: any) => {
                handleChange(event);
              }}
              fullWidth
              inputProps={{
                style: { textAlign: 'center' },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const BottleFormTotalRow = ({ totalVolume, totalCount, totalDoses }) => {
  const { t } = useTranslation();

  return (
    <StyledBottleFormTotalRow container mt={2} bgcolor={'info.light'} p={2}>
      <Grid item xs={12} md={4}>
        <Typography variant="subtitle1" color="primary.main" fontWeight={600}>
          {t('orderForm.totalVolume', {
            totalVolume: toSpaceSeparatedNumber(totalVolume),
          })}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="subtitle1" color="primary.main" fontWeight={600}>
          {t('orderForm.totalBottles')}: {toSpaceSeparatedNumber(totalCount)}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography
          variant="subtitle1"
          color="primary.main"
          fontWeight={600}
          style={{ whiteSpace: 'nowrap' }}
        >
          {t('orderForm.totalDoses')}:{' '}
          {totalDoses == 0 || totalDoses == '0'
            ? t('global.notApplicable')
            : toSpaceSeparatedNumber(totalDoses)}
        </Typography>
      </Grid>
    </StyledBottleFormTotalRow>
  );
};

const BottleForm = (props) => {
  const {
    fieldName,
    onChange,
    data,
    value: bottlesValues,
    errors,
    touched,
    handleBlur,
    volumeOptions,
    calculateTotal,
  } = props;

  const {
    totalVolume = 0,
    totalCount = 0,
    totalDoses = 0,
    volumePerDose = 0,
  } = data;
  const { t } = useTranslation();

  const handleBlurCustom = () =>
    handleBlur({ target: { id: BottlesPricingInfoKeys.BOTTLES } });

  const errorMessage = errors?.bottles?.totalCount;
  const isError = touched.bottles && Boolean(errors?.bottles);

  const updateBottleNumberCount = (id, newCount) => {
    return bottlesValues?.map((bottle) => {
      if (bottle?.id === id)
        return {
          ...bottle,
          count: newCount,
        };
      return bottle;
    });
  };

  const handleBottleSelectChange = (event, value, bottle) => {
    const newBottle = {
      ...value,
      count: bottle?.count,
      id: value?.id,
    };

    const newBottles = bottlesValues;
    newBottles[0] = newBottle;

    onChange({ target: { name: 'bottles' } }, newBottles);
    calculateTotal(bottlesValues, volumePerDose);
  };

  const handleBottleOnChange = (e, { id, count }: any) => {
    const data = updateBottleNumberCount(id, count);
    onChange(e, data);

    calculateTotal(data, volumePerDose);
  };

  const theme = useTheme();

  const calculateDosesForRow = ({
    volumePerDose,
    bottlesCount,
    bottleVolume,
  }) => {
    if (
      !bottlesCount ||
      !volumePerDose ||
      volumePerDose === 0 ||
      bottlesCount === 0
    )
      return 0;

    const result = (bottleVolume / volumePerDose).toFixed(2);

    return toSpaceSeparatedNumber(parseFloat(result));
  };

  const getDisabled = () => {
    if (!LIMIT_OF_BOTTLES_VISIBILITY) return { disabled: true };
    return {};
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" color="neutral.800" fontWeight={'normal'}>
          {t('orderForm.bottleFilling') + ' *'}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container rowGap={2}>
          {bottlesValues?.map((bottle) => {
            return (
              <Grid container key={bottle?.id} columnSpacing={2}>
                <Grid item xs={4}>
                  <StyledCustomSelect
                    label={t('orderForm.bottleVolume')}
                    name={fieldName}
                    value={{
                      label: bottle?.volume ? bottle?.volume + ' ml' : '',
                      value: bottle?.volume,
                    }}
                    style={{
                      border: '1px solid  #CED4DA',
                      textAlign: 'center',
                      background: '#E5F7FD',
                      color: 'primary.main',
                      fontWeight: '500',
                    }}
                    size="medium"
                    options={volumeOptions}
                    onChange={(event, value) => {
                      handleBottleSelectChange(event, value, bottle);
                    }}
                    {...getDisabled()}
                    deleteIconHidden={!LIMIT_OF_BOTTLES_VISIBILITY}
                  />
                </Grid>
                <Grid item xs={4}>
                  <NumericInput
                    value={bottle?.count === 0 ? '' : bottle?.count}
                    label={t('orderForm.numberOfBottles')}
                    onValueChange={(newValue) => {
                      // Ensure the new value is non-negative before updating
                      if (newValue >= 0) {
                        handleBottleOnChange(
                          { target: { name: fieldName } },
                          { count: newValue, id: bottle?.id }
                        );
                      }
                    }}
                    onDecrement={() => {
                      const newValue = bottle?.count - 1;
                      if (newValue >= 0)
                        handleBottleOnChange(
                          { target: { name: fieldName } },
                          { count: newValue, id: bottle?.id }
                        );
                    }}
                    onIncrement={() => {
                      handleBottleOnChange(
                        { target: { name: fieldName } },
                        { count: bottle?.count + 1, id: bottle?.id }
                      );
                    }}
                    sx={{ borderRadius: '4px' }}
                    onBlur={handleBlurCustom}
                    size="medium"
                  />
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                  }}
                >
                  {
                    <InfoField
                      message={
                        calculateDosesForRow({
                          volumePerDose: volumePerDose,
                          bottlesCount: bottle?.count,
                          bottleVolume: bottle?.volume,
                        }) +
                        ' ' +
                        t('orderForm.numberOfDosesPerBottle', {
                          pluralLetter: bottle?.count > 1 ? 's' : '',
                        })
                      }
                      color={theme.palette.info[600]}
                      IconComponent={() => (
                        <Info color={theme.palette.info[600]} />
                      )}
                    />
                  }
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        {isError && (
          <Typography variant="body2" color="error" sx={{ my: 1 }}>
            {errorMessage}
          </Typography>
        )}

        <BottleFormTotalRow
          totalVolume={totalVolume}
          totalCount={totalCount}
          totalDoses={totalDoses}
        />
      </Grid>
    </Grid>
  );
};

const AnimalInformation = () => {
  const animalInfo = useSelector(getInfectionData);
  const { t } = useTranslation();

  const animalGroupValue = animalInfo?.animalGroups?.value?.label || '';
  const animalSpeciesValues = animalInfo?.animalSpecies?.value?.label;

  return (
    <Grid container spacing={1}>
      <Grid item>
        <Typography variant="body2" color="neutral.main" fontWeight={600}>
          {t('orderForm.animalInfo')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="body2" color="neutral.main">
            {animalGroupValue}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" color="neutral.main">
            {animalSpeciesValues}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const StepperButton = (props) => {
  const { goBack, goNext, onCancel, nextDisabled } = props;
  return (
    <Grid item xs={12} sx={{ mt: 6 }}>
      <StepperButtonGroup
        goNext={goNext}
        goBack={goBack}
        onCancel={onCancel}
        showNext={true}
        showBackBtn={true}
        showCancelBtn={true}
        nextBtnDisabled={nextDisabled}
      />
    </Grid>
  );
};

const formName = ORDER_FORM_STEP_NAMES.DOSING_AND_FILLING_INFO;

const DosingAndFilling: React.FC<OrderFormStepComponentProps> = (props) => {
  const { goNext, goBack, onCancel } = props;
  const formFieldData = useSelector(getDosingAndFillingInfo);
  const doses = useSelector(getInfectionData)?.doses;
  const selectedAnimalGroup =
    useSelector(getInfectionData)?.animalGroups?.value;
  const { values, errors, touched, isValid, handleBlur, formikSetFieldValue } =
    useForm();

  const totalVolume = values?.bottles?.totalVolume;
  const totalCount = values?.bottles?.totalCount;
  const totalDoses = values?.bottles?.totalDoses;
  const volumePerDose = doses?.value?.value;

  const dispatch = useDispatch();

  const setField = (propPathValue, value, actions) => {
    formikSetFieldValue(propPathValue, value);
    dispatch(
      setFieldValue({ propPath: propPathValue, value, formName, actions })
    );
  };

  const onChange = (e: any, value: any, options?: any, actions = []) => {
    const fieldName = e.target.name;
    const propPathValue = options?.propPath ?? `${fieldName}.value`;

    setField(propPathValue, value, actions);
  };

  const initiateBottlesValues = (bottle: any) => ({
    ...bottle,
    count: 0,
    price: 0,
  });

  useEffect(() => {
    if (!formFieldData?.bottles?.value) {
      const bottles = LIMIT_OF_BOTTLES_VISIBILITY
        ? selectedAnimalGroup?.bottles?.map(initiateBottlesValues)
        : formFieldData?.bottles?.data?.map(initiateBottlesValues);

      onChange({ target: { name: DosingAndFillingInfoKeys.BOTTLES } }, bottles);
      calculateTotal(bottles, volumePerDose);
    }
  }, [formFieldData, selectedAnimalGroup]);

  const updateByObjIteration = (data, fieldName) => {
    for (const prop in data) {
      const name = `${fieldName}.${prop}`;
      const value = data?.[`${prop}`];
      onChange(
        {
          target: {
            name: name,
          },
        },
        value,
        { propPath: name }
      );
    }
  };

  const calculateTotal = (bottles, volumePerDose) => {
    let totalVolume = 0;
    let totalCount = 0;

    bottles?.forEach((bottle) => {
      if (bottle?.count > 0) {
        totalVolume += bottle?.count * bottle?.volume;
        totalCount += bottle?.count;
      }
    });

    const totalDoses = volumePerDose == 0 ? 0 : totalVolume / volumePerDose;
    updateByObjIteration(
      {
        totalVolume,
        totalCount,
        totalDoses: totalDoses?.toFixed(2),
      },
      DosingAndFillingInfoKeys.BOTTLES
    );
  };

  const volumeOptions = selectedAnimalGroup?.bottles?.map((bottle: any) => ({
    ...bottle,
    label: `${bottle.volume} ml`,
    value: bottle.volume,
  }));

  return (
    <StyledForm>
      <StyledGridContainer container rowGap={5}>
        <AnimalInformation />
        <BottleForm
          fieldName={DosingAndFillingInfoKeys.BOTTLES}
          data={{
            totalVolume: totalVolume,
            totalCount: totalCount,
            totalDoses: totalDoses,
            volumePerDose: volumePerDose,
          }}
          onChange={onChange}
          value={
            LIMIT_OF_BOTTLES_VISIBILITY
              ? [values?.bottles?.value?.[0]]
              : values?.bottles?.value
          }
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          volumeOptions={volumeOptions}
          updateByObjIteration={updateByObjIteration}
          calculateTotal={calculateTotal}
        />
        <RenderByCondition show={[BOTTLE_USAGE_VISIBILITY]}>
          <TitleWithInput
            onChange={onChange}
            value={values?.bottleUsage.value}
            fieldName={DosingAndFillingInfoKeys.BOTTLE_USAGE}
            min={values?.bottleUsage?.min}
            ht
            max={values?.bottleUsage?.max}
          />
        </RenderByCondition>
        <StepperButton
          goNext={goNext}
          goBack={goBack}
          onCancel={onCancel}
          nextDisabled={!isValid}
        />
      </StyledGridContainer>
    </StyledForm>
  );
};

export default DosingAndFilling;
