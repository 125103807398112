import styled from '@emotion/styled';
import { Box, Checkbox, Typography, TypographyProps } from '@mui/material';
import { TVariant } from 'types';

export const StyledLabel = styled(Box)`
  margin: 0px 8px;
  display: inline-block;
`;

export const StyledCheckBox = styled(Checkbox)(() => ({
  '.css-i4bv87-MuiSvgIcon-root': {
    fontSize: '1.8rem',
    paddingRight: '15px',
    width: '54px',
    height: '31px',
  },
}));
export const StyledErrorMessage = styled(Typography)`
  margin-top: 6px;
` as React.FC<Omit<TypographyProps, 'variant'> & { variant: TVariant }>;

export const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;
