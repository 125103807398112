import Search from 'components/icons/Search';
import { CustomInputProps } from '../Input/types';
import { StyledSearchBar } from './styles';
import { useTranslation } from 'react-i18next';

const SearchBox = (props: CustomInputProps) => {
  const { t } = useTranslation();
  const {
    startIcon = <Search />,
    placeholder = t('global.searchPlaceHolder'),
    onChange,
    onKeyDown,
    value,
  } = props;

  return (
    <StyledSearchBar
      size="medium"
      placeholder={placeholder}
      startIcon={startIcon}
      onChange={onChange}
      onKeyDown={onKeyDown}
      value={value}
    />
  );
};

export default SearchBox;
