import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store';

export const getStatesFormData = (state: RootState) => {
  return state.addressesManager?.statesFormData;
};

export const getSelectedStateIdForm = (state: RootState) => {
  return state.addressesManager?.selectedStateIdForm;
};

export const getOrderAddressesForm = (state: RootState) => {
  return state.addressesManager?.orderAddressesForm;
};

export const getAddressesByStateForm = (state: RootState) => {
  return state.addressesManager?.addressesByStateForm;
};
export const getProximityAddressesOrder = (state: RootState) => {
  return state.addressesManager?.proximityAddressesOrder;
};

export const getStatesDataOrder = (state: RootState) => {
  return state.addressesManager?.statesDataOrder;
};

export const getSelectedFarmsRows = (state: RootState) => {
  return state.addressesManager?.selectedFarmsRows;
};

export const getSelectedShippingRows = (state: RootState, stateId: string) => {
  return state.addressesManager?.selectedShippingRows?.[stateId] || [];
};

export const getAllSelectedShippingRows = (state: RootState) => {
  return state.addressesManager?.selectedShippingRows || [];
};

export const getNewOrderAddresses = (state: RootState) => {
  return state.addressesManager?.newOrderAddresses;
};

export const getSelectedStateIdWithRows = createSelector(
  (state) => state.addressesManager.selectedShippingRows,
  (selectedShippingRows) => Object.keys(selectedShippingRows)
);

export const getCustmerId = (state: RootState) => {
  return state.addressesManager?.custmerId;
};

export const getSelectedStateId = (state: RootState) => {
  return state.addressesManager?.selectedStateId;
};
