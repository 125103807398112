/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import CustomCircle from 'components/atoms/Circle';
import UnderlineButton from 'components/atoms/UnderlineButton';
import EditButton from 'components/molecules/EditButton';
import StepperButtonGroup from 'components/molecules/StepperButtonGroup';
import { PAGES, USER_ROLE } from 'constants/index';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  getAdditionalInstructions,
  getDosingAndFillingInfo,
  getFarmData,
  getInfectionData,
  getOrderFormMode,
  getSaveOrderData,
} from 'store/features/orderForm/index.selectors';
import { getFieldVisibility, toSpaceSeparatedNumber } from 'utils';
import {
  StyledTableBodyCell,
  StyledTableBodyCellText,
  StyledTableHeadCell,
  StyledTableHeadCellText,
  StyledTableHeadCellTextWithoutEllipsis,
} from './styles';
import {
  useEditOrderMutation,
  useSaveOrderMutation,
  useSaveReOrderMutation,
  useSignOrderMutation,
} from 'api/orderForm';
import { useNavigate, useParams } from 'react-router-dom';
import { ModeState } from 'store/features/orderForm/index.type';
import { getUserRole } from 'store/features/auth/index.selector';
import useShareOrder from 'hooks/useShareOrder';
import RenderByCondition from 'components/atoms/RenderByCondition';
import TextBadge from 'components/atoms/TextBadge';
import { getNewOrderAddresses } from 'store/features/addressesManager/index.selectors';
import { CheckedIcon, LeftArrow } from 'components/icons';
import PopupConfirmation from 'components/molecules/PopupConfirmation';
import { IActionButton } from 'components/molecules/PopupConfirmation/types';

const {
  PRICING_STEP_VISIBILITY,
  SPECIFIC_PRODUCT_VISIBILITY,
  ANIMAL_COUNT_VISIBILITY,
  ISOLATE_DATA_VISIBILITY,
  CLINICAL_SIGNS_VISIBILITY,
  BOTTLE_NAME_VISIBILITY,
  INTERNAL_REFERENCE_VISIBILITY,
  BILL_TO_VISIBILITY,
  REQUEST_LETTER_COMMENT,
  CUSTOMER_VISIBILITY,
  ROUTE_OF_ADMINISTRATION_VISIBILITY,
  BOTTLE_USAGE_VISIBILITY,
  ISOLATE_DEROGATION_COLUMN_VISIBILITY,
  CAP_COLOR_VISIBILITY,
  ORDER_DETAILS_VERTICAL_ADDRESSES_DISPLAY,
} = getFieldVisibility();

const FarmAndAddresses = ({ goToStep }) => {
  const { t } = useTranslation();
  const data = useSelector(getFarmData);
  const newOrderAddresses = useSelector(getNewOrderAddresses);

  return (
    <Grid container direction={{ xs: 'column', md: 'column' }} rowGap={4}>
      <Grid item>
        <EditButton
          count={1}
          buttonTitle={t('orderForm.stepOneTitle')}
          onClick={() => {
            goToStep(0);
          }}
        />
      </Grid>
      <Grid container rowGap={1} direction={'column'}>
        <Grid item direction={'column'}>
          <Typography color="neutral.700" variant="body2" fontWeight={600}>
            {t('orderForm.veterinaryName')}
          </Typography>
          <Divider />
        </Grid>
        <Grid item direction={'column'}>
          <Typography color="neutral.main" fontWeight={400} variant="body1">
            {data?.vets?.value?.label}
          </Typography>
        </Grid>
      </Grid>
      <Grid container rowGap={1} direction={'column'}>
        {/* NEW */}
        <RenderByCondition show={[ORDER_DETAILS_VERTICAL_ADDRESSES_DISPLAY]}>
          <Grid container direction={'column'} rowGap={1}>
            <Grid item>
              <Grid container direction={'column'} rowGap={1}>
                <Typography
                  color="neutral.700"
                  variant="body2"
                  fontWeight={600}
                >
                  {t('orderForm.shippingSiteSelected', {
                    count:
                      newOrderAddresses?.length > 0
                        ? newOrderAddresses?.length
                        : data?.shippingSites?.selectCount,
                  })}
                </Typography>
                <Divider />
              </Grid>
            </Grid>

            <Grid item>
              <Grid container direction="row">
                <Typography
                  color="neutral.700"
                  variant="body2"
                  fontWeight={600}
                >
                  {data?.shippingSites?.value?.[0]?.name},{' '}
                  {data?.shippingSites?.value?.[0]?.zipCode},{' '}
                  {data?.shippingSites?.value?.[0]?.city}{' '}
                </Typography>
                <UnderlineButton
                  title={t('global.edit')}
                  onClick={() => {
                    goToStep(0);
                  }}
                  sx={{ marginLeft: '4px' }}
                />
              </Grid>
            </Grid>
          </Grid>
        </RenderByCondition>

        {/* OLD */}
        <RenderByCondition show={[!ORDER_DETAILS_VERTICAL_ADDRESSES_DISPLAY]}>
          <>
            <Grid container direction={'row'}>
              <Typography color="neutral.700" variant="body2" fontWeight={600}>
                {t('orderForm.shippingSiteSelected', {
                  count:
                    newOrderAddresses?.length > 0
                      ? newOrderAddresses?.length
                      : data?.shippingSites?.selectCount,
                })}
              </Typography>
              <UnderlineButton
                title={t('global.edit')}
                onClick={() => {
                  goToStep(0);
                }}
                sx={{ marginLeft: '4px' }}
              />
            </Grid>
            <Divider />
          </>
        </RenderByCondition>

        {/* NEW */}
        <RenderByCondition show={[ORDER_DETAILS_VERTICAL_ADDRESSES_DISPLAY]}>
          <Grid container direction={'column'} rowGap={1} mt={2.5}>
            <Grid item>
              <Grid container direction={'column'} rowGap={1}>
                <Typography
                  color="neutral.700"
                  variant="body2"
                  fontWeight={600}
                >
                  {t(
                    data?.nonAdjacentRequired?.value
                      ? 'orderForm.proximityAddressSelected'
                      : 'orderForm.farmAddressSelected',
                    {
                      count: data?.farmAddresses?.selectCount,
                    }
                  )}
                </Typography>
                <Divider />
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row">
                <Typography
                  color="neutral.700"
                  variant="body2"
                  fontWeight={600}
                >
                  {data?.farmAddresses?.value?.[0]?.name}
                </Typography>
                <UnderlineButton
                  title={t('global.edit')}
                  onClick={() => {
                    goToStep(0);
                  }}
                  sx={{ marginLeft: '4px' }}
                />
              </Grid>
            </Grid>
          </Grid>
        </RenderByCondition>

        {/* OLD  */}
        <RenderByCondition show={[!ORDER_DETAILS_VERTICAL_ADDRESSES_DISPLAY]}>
          <Grid container direction={'row'} mt={2.5}>
            <Typography color="neutral.700" variant="body2" fontWeight={600}>
              {t(
                data?.nonAdjacentRequired?.value
                  ? 'orderForm.proximityAddressSelected'
                  : 'orderForm.farmAddressSelected',
                {
                  count: data?.farmAddresses?.selectCount,
                }
              )}
            </Typography>
            <UnderlineButton
              title={t('global.edit')}
              onClick={() => {
                goToStep(0);
              }}
              sx={{ marginLeft: '4px' }}
            />
          </Grid>
          <Divider />
        </RenderByCondition>
      </Grid>
      <RenderByCondition show={[BILL_TO_VISIBILITY]}>
        <Grid container rowGap={1} direction={'column'}>
          <Typography color="neutral.700" variant="body2" fontWeight={600}>
            {t('orderForm.billingAddress')}
          </Typography>
          <Divider />
          <Typography color="neutral.main" fontWeight={400} variant="body1">
            {data?.billingAddresses?.value?.label}
          </Typography>
        </Grid>
      </RenderByCondition>

      <RenderByCondition show={[CUSTOMER_VISIBILITY]}>
        <Grid container rowGap={1} direction={'column'}>
          <Typography color="neutral.700" variant="body2" fontWeight={600}>
            {t('orderForm.selectedCustomer')}
          </Typography>
          <Divider />
          <Typography color="neutral.main" fontWeight={400} variant="body1">
            {data?.customer?.value?.label}
          </Typography>
        </Grid>
      </RenderByCondition>
    </Grid>
  );
};

const InfectionAndIsolates = ({ goToStep }) => {
  const { t } = useTranslation();
  const data = useSelector(getInfectionData);

  const isolates = useMemo(() => {
    return data?.isolates?.value.map((isolate) => {
      return {
        ...isolate,
      };
    });
  }, [data]);

  const isolatesListLength = isolates?.length;
  const chosenProductIsBoosting: boolean = data?.products?.value?.isBoosting;

  return (
    <Grid container direction={'column'} rowGap={4}>
      <Grid item>
        <EditButton
          count={2}
          buttonTitle={t('orderForm.stepTwoTitle')}
          onClick={() => {
            goToStep(1);
          }}
        />
      </Grid>

      <Grid container rowGap={1} direction={'column'}>
        <Grid container direction={'row'}>
          <Grid item xs={6}>
            <Typography color="neutral.700" variant="body2" fontWeight={600}>
              {t('orderForm.animalGroup')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography color="neutral.700" variant="body2" fontWeight={600}>
              {t('orderForm.animalSpecies')}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container direction={'row'}>
          <Grid item xs={6}>
            <Typography color="neutral.main" fontWeight={400} variant="body1">
              {data?.animalGroups?.value?.label}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography color="neutral.main" fontWeight={400} variant="body1">
              {data?.animalSpecies?.value?.label}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <RenderByCondition show={[SPECIFIC_PRODUCT_VISIBILITY]}>
        <Grid container rowGap={1} direction={'column'}>
          <Typography color="neutral.700" variant="body2" fontWeight={600}>
            {t('orderForm.specificProduct')}
          </Typography>
          <Divider />
          <Typography color="neutral.main" fontWeight={400} variant="body1">
            {data?.products?.value?.label}
          </Typography>
        </Grid>
      </RenderByCondition>

      <Grid container rowGap={1} direction={'column'}>
        <Typography color="neutral.700" variant="body2" fontWeight={600}>
          {t('orderForm.adjuvant')}
        </Typography>
        <Divider />
        <Typography color="neutral.main" fontWeight={400} variant="body1">
          {data?.adjuvants?.value?.label}
        </Typography>
      </Grid>

      <RenderByCondition show={[ROUTE_OF_ADMINISTRATION_VISIBILITY]}>
        <Grid container rowGap={1} direction={'column'}>
          <Typography color="neutral.700" variant="body2" fontWeight={600}>
            {t('orderForm.administrationRoutes')}
          </Typography>
          <Divider />
          <Typography color="neutral.main" fontWeight={400} variant="body1">
            {data?.administrationRoutes?.value?.label}
          </Typography>
        </Grid>
      </RenderByCondition>

      <DosesAndAnimalNumberTable data={data} />

      <Grid
        container
        direction={'row'}
        justifyContent={'flex-start'}
        marginTop={'1rem'}
      >
        <Typography color="neutral.700" variant="body2" fontWeight={600}>
          {t('orderForm.isolateList', {
            isolateCount: isolatesListLength,
          })}
        </Typography>
        <Divider />
        <TableContainer sx={{ width: 550 }}>
          <Table
            sx={{
              width: '100%',
            }}
          >
            <TableHead>
              <TableRow>
                <StyledTableHeadCell sx={{ paddingLeft: 0 }}>
                  <Tooltip title={t('orderForm.isolateId')}>
                    <StyledTableHeadCellText variant="body2">
                      {t('orderForm.isolateId')}
                    </StyledTableHeadCellText>
                  </Tooltip>
                </StyledTableHeadCell>
                <RenderByCondition show={[ISOLATE_DATA_VISIBILITY]}>
                  <>
                    <StyledTableHeadCell sx={{ paddingLeft: 0 }}>
                      <Tooltip title={t('orderForm.farm')}>
                        <StyledTableHeadCellText variant="body2">
                          {t('orderForm.farm')}
                        </StyledTableHeadCellText>
                      </Tooltip>
                    </StyledTableHeadCell>
                    <StyledTableHeadCell sx={{ paddingLeft: 0 }}>
                      <Tooltip title={t('orderForm.capacity')}>
                        <StyledTableHeadCellText variant="body2">
                          {t('orderForm.capacity')}
                        </StyledTableHeadCellText>
                      </Tooltip>
                    </StyledTableHeadCell>
                  </>
                </RenderByCondition>
                <StyledTableHeadCell sx={{ paddingLeft: 0 }}>
                  <Tooltip title={t('orderForm.seroType')}>
                    <StyledTableHeadCellText variant="body2">
                      {t('orderForm.seroType')}
                    </StyledTableHeadCellText>
                  </Tooltip>
                </StyledTableHeadCell>

                <RenderByCondition
                  show={[ISOLATE_DEROGATION_COLUMN_VISIBILITY]}
                >
                  <StyledTableHeadCell sx={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Tooltip title={''}>
                      <StyledTableHeadCellText variant="body2">
                        {''}
                      </StyledTableHeadCellText>
                    </Tooltip>
                  </StyledTableHeadCell>
                </RenderByCondition>

                <RenderByCondition show={[chosenProductIsBoosting]}>
                  <StyledTableHeadCell sx={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Tooltip title={t('orderForm.boostColumnName')}>
                      <StyledTableHeadCellText variant="body2">
                        {t('orderForm.boostColumnName')}
                      </StyledTableHeadCellText>
                    </Tooltip>
                  </StyledTableHeadCell>
                </RenderByCondition>
              </TableRow>
            </TableHead>
            <TableBody>
              <React.Fragment>
                {isolates?.map((isolate) => {
                  const farmName = isolate?.originFarm?.name;
                  const farmAddress = `${isolate?.originFarm?.address?.street}, ${isolate?.originFarm?.address?.city}, ${isolate?.originFarm?.address?.country}`;
                  const farmPhone = isolate?.originFarm?.address?.phoneNumber;
                  const capacity = isolate?.originFarm?.capacity || 0;

                  return (
                    <TableRow
                      style={{
                        height: '10px !important',
                        maxHeight: '10px !important',
                      }}
                      key={farmName}
                    >
                      <StyledTableBodyCell>
                        <Tooltip
                          title={isolate.reference}
                          style={{ color: isolate?.styleInfo?.textColor }}
                        >
                          <StyledTableBodyCellText variant="body2">
                            {isolate?.reference}
                          </StyledTableBodyCellText>
                        </Tooltip>
                        <Tooltip
                          title={isolate.name}
                          style={{ color: isolate?.styleInfo?.textColor }}
                        >
                          <StyledTableBodyCellText variant="body2">
                            {isolate?.name}
                          </StyledTableBodyCellText>
                        </Tooltip>
                      </StyledTableBodyCell>

                      <RenderByCondition show={[ISOLATE_DATA_VISIBILITY]}>
                        <>
                          <StyledTableBodyCell>
                            <Tooltip title={farmName}>
                              <StyledTableBodyCellText variant="body2">
                                {farmName}
                              </StyledTableBodyCellText>
                            </Tooltip>
                            <Tooltip title={farmAddress}>
                              <StyledTableBodyCellText variant="body2">
                                {farmAddress}
                              </StyledTableBodyCellText>
                            </Tooltip>
                            <Tooltip title={farmPhone}>
                              <StyledTableBodyCellText variant="body2">
                                {farmPhone}
                              </StyledTableBodyCellText>
                            </Tooltip>
                          </StyledTableBodyCell>
                          <StyledTableBodyCell>
                            <Tooltip title={capacity}>
                              <StyledTableBodyCellText variant="body2">
                                {capacity}
                              </StyledTableBodyCellText>
                            </Tooltip>
                          </StyledTableBodyCell>
                        </>
                      </RenderByCondition>
                      <StyledTableBodyCell>
                        <Tooltip
                          title={isolate?.seroType}
                          style={{ color: isolate?.styleInfo?.textColor }}
                        >
                          <StyledTableBodyCellText variant="body2">
                            {isolate?.seroType}
                          </StyledTableBodyCellText>
                        </Tooltip>
                      </StyledTableBodyCell>
                      <RenderByCondition
                        show={[ISOLATE_DEROGATION_COLUMN_VISIBILITY]}
                      >
                        <StyledTableBodyCell>
                          <Tooltip title={isolate?.styleInfo?.name}>
                            <Typography display="inline">
                              <TextBadge
                                name={isolate?.styleInfo?.name}
                                color={isolate?.styleInfo?.textColor}
                                variant="body3"
                              />
                            </Typography>
                          </Tooltip>
                        </StyledTableBodyCell>
                      </RenderByCondition>

                      <RenderByCondition show={[chosenProductIsBoosting]}>
                        <StyledTableBodyCell>
                          <RenderByCondition show={[isolate?.isBoosted]}>
                            <StyledTableBodyCellText
                              style={{ paddingLeft: '14px' }}
                            >
                              <CheckedIcon style={{ textAlign: 'center' }} />
                            </StyledTableBodyCellText>
                          </RenderByCondition>
                        </StyledTableBodyCell>
                      </RenderByCondition>
                    </TableRow>
                  );
                })}
              </React.Fragment>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

const BottlePricesTable = () => {
  const { t } = useTranslation();
  const data: any = useSelector(getDosingAndFillingInfo)?.bottles?.value || [];
  const theme = useTheme();

  const getTotal = () => {
    return data?.reduce((total, item) => total + item.price * item.count, 0);
  };

  const totalPrice = getTotal();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableHeadCell sx={{ paddingLeft: 0 }}>
              <Tooltip title={t('orderForm.bottleVolume')}>
                <StyledTableHeadCellText variant="body2">
                  {t('orderForm.bottleVolume')}
                </StyledTableHeadCellText>
              </Tooltip>
            </StyledTableHeadCell>
            <StyledTableHeadCell sx={{ paddingLeft: 0 }}>
              <Tooltip title={t('orderForm.pricePerBottle')}>
                <StyledTableHeadCellText variant="body2">
                  {t('orderForm.pricePerBottle')}
                </StyledTableHeadCellText>
              </Tooltip>
            </StyledTableHeadCell>
            <StyledTableHeadCell sx={{ paddingLeft: 0 }}>
              <Tooltip title={t('orderForm.numberOrdered')}>
                <StyledTableHeadCellText variant="body2">
                  {t('orderForm.numberOrdered')}
                </StyledTableHeadCellText>
              </Tooltip>
            </StyledTableHeadCell>
            <StyledTableHeadCell sx={{ paddingLeft: 0 }}>
              <Tooltip title={t('global.price')}>
                <StyledTableHeadCellText variant="body2">
                  {t('global.price')}
                </StyledTableHeadCellText>
              </Tooltip>
            </StyledTableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, index) => (
            <React.Fragment key={row?.count}>
              <RenderByCondition show={[row?.count > 0]}>
                <TableRow>
                  <StyledTableBodyCell>
                    <StyledTableBodyCellText variant="body2">
                      {row.volume} ml
                    </StyledTableBodyCellText>
                  </StyledTableBodyCell>

                  <StyledTableBodyCell>
                    <StyledTableBodyCellText variant="body2">
                      $ {row.price}
                    </StyledTableBodyCellText>
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>
                    <StyledTableBodyCellText variant="body2">
                      {row.count}
                    </StyledTableBodyCellText>
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>
                    <StyledTableBodyCellText variant="body2">
                      $ {(row.price * row.count).toFixed(2)}
                    </StyledTableBodyCellText>
                  </StyledTableBodyCell>
                </TableRow>
                {index < data?.length - 1 && <Divider />}
              </RenderByCondition>
            </React.Fragment>
          ))}
          <TableRow sx={{ background: theme.palette.neutral[200] }}>
            <TableCell colSpan={4}>
              <Typography variant="body2" color="primary" fontWeight={600}>
                {t('orderForm.totalPrice', { totalPrice: totalPrice })}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const BottlesTableTotalRow = ({ totalVolume, totalCount, totalDoses }) => {
  const { t } = useTranslation();

  return (
    <Grid container bgcolor={'neutral.200'} p={2}>
      <Grid item xs={12} md={4}>
        <Typography variant="subtitle1" color="primary.main" fontWeight={600}>
          {t('orderForm.totalVolume', { totalVolume: totalVolume })}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="subtitle1" color="primary.main" fontWeight={600}>
          {t('orderForm.totalBottles')}: {totalCount}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="subtitle1" color="primary.main" fontWeight={600}>
          {t('orderForm.totalDoses')}:{' '}
          {totalDoses == 0 ? t('global.notApplicable') : totalDoses}
        </Typography>
      </Grid>
    </Grid>
  );
};

const calculateDosesForRow = ({
  volumePerDose,
  bottlesCount,
  bottleVolume,
}) => {
  if (volumePerDose === 0 || bottlesCount === 0) return '0';
  const result = (bottleVolume / volumePerDose).toFixed(2);

  return toSpaceSeparatedNumber(parseFloat(result));
};

const BottlesTable = ({ data }) => {
  const { t } = useTranslation();
  const volumePerDose = useSelector(getInfectionData)?.doses?.value?.volume;

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableHeadCell>
              <Tooltip title={t('orderForm.bottleVolume')}>
                <StyledTableHeadCellText variant="body2">
                  {t('orderForm.bottleVolume')}
                </StyledTableHeadCellText>
              </Tooltip>
            </StyledTableHeadCell>
            <StyledTableHeadCell>
              <Tooltip title={t('orderForm.numberOfBottles')}>
                <StyledTableHeadCellText variant="body2">
                  {t('orderForm.numberOfBottles')}
                </StyledTableHeadCellText>
              </Tooltip>
            </StyledTableHeadCell>
            <StyledTableHeadCell>
              <Tooltip title={t('orderForm.numberOfDosesPerBottle')}>
                <StyledTableHeadCellText variant="body2">
                  {t('orderForm.numberOfDosesPerBottle', {
                    pluralLetter: 's',
                  })}
                </StyledTableHeadCellText>
              </Tooltip>
            </StyledTableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.value?.map((row, index) => {
            if (row.count === 0) return null;
            return (
              <React.Fragment key={row?.count}>
                <TableRow>
                  <StyledTableBodyCell>
                    <StyledTableBodyCellText variant="body1"></StyledTableBodyCellText>
                    {row?.volume} ml
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>
                    <StyledTableBodyCellText variant="body1"></StyledTableBodyCellText>
                    {toSpaceSeparatedNumber(row.count)}
                  </StyledTableBodyCell>

                  <StyledTableBodyCell>
                    <StyledTableBodyCellText variant="body1">
                      {calculateDosesForRow({
                        volumePerDose: volumePerDose,
                        bottlesCount: row?.count,
                        bottleVolume: row?.volume,
                      })}
                    </StyledTableBodyCellText>
                  </StyledTableBodyCell>
                </TableRow>
                {index < data.length - 1 && <Divider />}
              </React.Fragment>
            );
          })}
          <TableRow>
            <StyledTableBodyCell colSpan={4} sx={{ padding: 0 }}>
              <BottlesTableTotalRow
                totalVolume={toSpaceSeparatedNumber(data?.totalVolume)}
                totalCount={toSpaceSeparatedNumber(data?.totalCount)}
                totalDoses={toSpaceSeparatedNumber(data?.totalDoses)}
              />
            </StyledTableBodyCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const DosesAndAnimalNumberTable = ({ data }) => {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableHeadCell>
              <StyledTableHeadCellTextWithoutEllipsis variant="body2">
                {t('orderForm.volumePerDoses')}
              </StyledTableHeadCellTextWithoutEllipsis>
            </StyledTableHeadCell>

            <RenderByCondition show={[ANIMAL_COUNT_VISIBILITY]}>
              <StyledTableHeadCell>
                <StyledTableHeadCellTextWithoutEllipsis variant="body2">
                  {t('orderForm.numberOfAnimals')}
                </StyledTableHeadCellTextWithoutEllipsis>
              </StyledTableHeadCell>
            </RenderByCondition>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <StyledTableBodyCell sx={{ borderBottom: 0 }}>
              <StyledTableBodyCellText variant="body1">
                {data?.doses?.value?.value} ml
              </StyledTableBodyCellText>
            </StyledTableBodyCell>
            <RenderByCondition show={[ANIMAL_COUNT_VISIBILITY]}>
              <StyledTableBodyCell sx={{ borderBottom: 0 }}>
                <StyledTableBodyCellText variant="body1">
                  {data?.numOfAnimal?.value}
                </StyledTableBodyCellText>
              </StyledTableBodyCell>
            </RenderByCondition>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const DosingAndFilling = ({ goToStep }) => {
  const { t } = useTranslation();
  const data: any = useSelector(getDosingAndFillingInfo);
  return (
    <Grid container direction={'column'} rowGap={4}>
      <Grid item>
        <EditButton
          count={3}
          buttonTitle={t('orderForm.stepThreeTitle')}
          onClick={() => {
            goToStep(2);
          }}
        />
      </Grid>

      <Grid container rowGap={1}>
        <BottlesTable data={data?.bottles} />
      </Grid>

      <RenderByCondition show={[BOTTLE_USAGE_VISIBILITY]}>
        <Grid container rowGap={1} direction={'column'} mt={2}>
          <Typography color="neutral.700" variant="body2" fontWeight={600}>
            {t('orderForm.usageOfBottle')}
          </Typography>
          <Divider />
          <Typography color="neutral.main" fontWeight={400} variant="body1">
            {data?.bottleUsage?.value}
          </Typography>
        </Grid>
      </RenderByCondition>
    </Grid>
  );
};

const Pricing = ({ goToStep }) => {
  const { t } = useTranslation();

  return (
    <Grid container direction={'column'} rowGap={4}>
      <Grid item>
        <EditButton
          count={4}
          buttonTitle={t('orderForm.stepFourTitle')}
          onClick={() => {
            goToStep(3);
          }}
        />
      </Grid>

      <Grid container rowGap={1}>
        <BottlePricesTable />
      </Grid>
    </Grid>
  );
};

const CapColorAndBottleTagTable = ({ data }) => {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <RenderByCondition show={[CAP_COLOR_VISIBILITY]}>
              <StyledTableHeadCell>
                <StyledTableHeadCellText variant="body2">
                  {t('orderForm.capColor')}
                </StyledTableHeadCellText>
              </StyledTableHeadCell>
            </RenderByCondition>
            <RenderByCondition show={[BOTTLE_NAME_VISIBILITY]}>
              <StyledTableHeadCell>
                <StyledTableHeadCellText fontWeight={600}>
                  {t('orderForm.bottleTag')}
                </StyledTableHeadCellText>
              </StyledTableHeadCell>
            </RenderByCondition>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <RenderByCondition show={[CAP_COLOR_VISIBILITY]}>
              <StyledTableBodyCell sx={{ borderBottom: 0 }}>
                <Grid container direction={'row'} columnGap={2}>
                  <Grid item>
                    <CustomCircle
                      color={data?.colors?.value?.value}
                      size="small"
                    />
                  </Grid>
                  <Grid item>
                    <StyledTableBodyCellText variant="body1">
                      {data?.colors?.value?.label}
                    </StyledTableBodyCellText>
                  </Grid>
                </Grid>
              </StyledTableBodyCell>
            </RenderByCondition>
            <RenderByCondition show={[BOTTLE_NAME_VISIBILITY]}>
              <StyledTableBodyCell sx={{ borderBottom: 0 }}>
                <StyledTableBodyCellText variant="body1">
                  {data?.bottleTag?.value}
                </StyledTableBodyCellText>
              </StyledTableBodyCell>
            </RenderByCondition>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const AdditionalInstructions = ({ goToStep }) => {
  const data = useSelector(getAdditionalInstructions);
  const { t } = useTranslation();

  return (
    <Grid container direction={'column'} rowGap={4}>
      <Grid item>
        <EditButton
          count={PRICING_STEP_VISIBILITY ? 5 : 4}
          buttonTitle={t('orderForm.stepFiveTitle')}
          onClick={() => {
            goToStep(PRICING_STEP_VISIBILITY ? 4 : 3);
          }}
        />
      </Grid>

      <CapColorAndBottleTagTable data={data} />
      <Grid container rowGap={1} direction={'column'}>
        <Typography color="neutral.700" variant="body2" fontWeight={600}>
          {t('orderForm.vaccinationSchedule')}
        </Typography>
        <Divider />
        <Typography color="neutral.main" fontWeight={400} variant="body1">
          {data?.vaccinationSchedule?.value}
        </Typography>
      </Grid>
      <RenderByCondition show={[INTERNAL_REFERENCE_VISIBILITY]}>
        <Grid container rowGap={1} direction={'column'}>
          <Typography color="neutral.700" variant="body2" fontWeight={600}>
            {t('orderForm.internalReference')}
          </Typography>
          <Divider />
          <Typography color="neutral.main" fontWeight={400} variant="body1">
            {data?.internalReference?.value}
          </Typography>
        </Grid>
      </RenderByCondition>

      <RenderByCondition show={[CLINICAL_SIGNS_VISIBILITY]}>
        <Grid container rowGap={1} direction={'column'}>
          <Typography color="neutral.700" variant="body2" fontWeight={600}>
            {t('orderForm.clinicalSigns')}
          </Typography>
          <Divider />
          <Typography color="neutral.main" fontWeight={400} variant="body1">
            {data?.clinicalSigns?.value}
          </Typography>
        </Grid>
      </RenderByCondition>
      <Grid container rowGap={1} direction={'column'}>
        <Typography color="neutral.700" variant="body2" fontWeight={600}>
          {t('orderForm.vetComment')}
        </Typography>
        <Divider />
        <Typography color="neutral.main" fontWeight={400} variant="body1">
          {data?.additionalComment?.value}
        </Typography>
      </Grid>

      <RenderByCondition show={[REQUEST_LETTER_COMMENT]}>
        <Grid container rowGap={1} direction={'column'}>
          <Typography color="neutral.700" variant="body2" fontWeight={600}>
            {t('orderForm.requestLetterComments')}
          </Typography>
          <Divider />
          <Typography color="neutral.main" fontWeight={400} variant="body1">
            {data?.requestLetterComment?.value}
          </Typography>
        </Grid>
      </RenderByCondition>
    </Grid>
  );
};

const FormRecap = (props) => {
  const { goBack, onCancel, goToStep } = props;
  const { t } = useTranslation();
  const userRole = useSelector(getUserRole);
  const orderData = useSelector(getSaveOrderData);
  const priceData: any =
    useSelector(getDosingAndFillingInfo)?.bottles.value || [];
  const navigate = useNavigate();

  const orderFormMode = useSelector(getOrderFormMode);

  const IS_REORDER_MODE = orderFormMode == ModeState.REORDER;
  const IS_EDIT_MODE = orderFormMode == ModeState.EDIT;

  const [saveOrderFunction, OrderState] = useSaveOrderMutation();

  const [saveReOrderFunction, ReOrderState] = useSaveReOrderMutation();

  const [editOrder, EditState] = useEditOrderMutation();

  const saveOrder = IS_REORDER_MODE ? saveReOrderFunction : saveOrderFunction;

  const {
    handleShareDraft,
    shareOrderIsSuccess,
    shareOrderIsLoading,
    saveReOrderIsLoading,
    editOrderIsLoading,
  } = useShareOrder();

  const { id } = useParams<{ id: string }>();
  const idFromParams = parseInt(id, 10) || 0;

  let saveOrderIsSuccess, saveOrderIsError, apiOrderData, saveOrderIsLoading;

  if (IS_REORDER_MODE) {
    saveOrderIsSuccess = ReOrderState.isSuccess;
    saveOrderIsError = ReOrderState.isError;
    apiOrderData = ReOrderState.data;
    saveOrderIsLoading = ReOrderState.isLoading;
  } else if (IS_EDIT_MODE) {
    saveOrderIsSuccess = EditState.isSuccess;
    saveOrderIsError = EditState.isError;
    apiOrderData = EditState.data;
    saveOrderIsLoading = EditState.isLoading;
  } else {
    saveOrderIsSuccess = OrderState.isSuccess;
    saveOrderIsError = OrderState.isError;
    apiOrderData = OrderState.data;
    saveOrderIsLoading = OrderState.isLoading;
  }

  const handleSaveOrder = async () => {
    if (IS_REORDER_MODE) {
      orderData.parentOrderID = idFromParams;
    }
    if (IS_EDIT_MODE) {
      const data = {
        id: idFromParams,
        data: { ...orderData },
      };
      await editOrder(data);
    } else {
      await saveOrder({ ...orderData });
    }
  };

  const [
    signOrder,
    {
      isSuccess: signOrderSuccess,
      isError: signOrderIsError,
      isLoading: signOrderIsLoading,
    },
  ] = useSignOrderMutation();

  const callSignOrder = async (id: number) => await signOrder({ orderId: id });
  const loadingStates = [
    saveOrderIsLoading,
    signOrderIsLoading,
    shareOrderIsLoading,
    editOrderIsLoading,
    saveReOrderIsLoading,
  ];
  const goNextIsLoading = loadingStates.some((state) => state);
  useEffect(() => {
    if (saveOrderIsSuccess && !saveOrderIsError) {
      if (IS_EDIT_MODE) {
        const orderId = parseInt(id, 10) || 0;
        callSignOrder(orderId);
      } else {
        callSignOrder(apiOrderData?.id);
      }
    }
  }, [saveOrderIsError, saveOrderIsSuccess]);

  useEffect(() => {
    if (signOrderSuccess && !signOrderIsError) {
      navigate(PAGES[userRole].DASHBOARD);
    }
  }, [signOrderSuccess, signOrderIsError]);

  useEffect(() => {
    if (shareOrderIsSuccess) {
      navigate(PAGES[userRole].DASHBOARD);
    }
  }, [shareOrderIsSuccess]);

  const VerifyVetToSign = () => {
    if (PRICING_STEP_VISIBILITY && userRole === USER_ROLE.VET) {
      return priceData.every(({ price }) => price === 0);
    }

    return false;
  };

  const handleShare = async () => {
    await handleShareDraft();
  };
  const [open, setOpen] = React.useState(false);

  const handlePopUpOpen = () => setOpen(true);
  const handlePopUpClose = () => setOpen(false);
  const actionButtons: Array<IActionButton> = [
    {
      title: t('global.saveAndSend'),
      onClick: () => {
        if (VerifyVetToSign()) {
          handleShare();
        } else {
          handleSaveOrder();
        }
        handlePopUpClose();
      },
      variant: 'contained',
    },
    {
      title: t('global.decline'),
      onClick: () => {
        handlePopUpClose();
      },
      variant: 'outlined',
      icon: <LeftArrow />,
    },
  ];
  const saveAndSignOrder = () => {
    handlePopUpOpen();
  };

  let description;

  if (userRole == USER_ROLE.VET) {
    description = t('messages.messageConfirmationOrderSignVet');
  } else if (userRole == USER_ROLE.TM) {
    description = t('messages.messageConfirmationOrderSignTM');
  }
  return (
    <Grid
      container
      paddingLeft={{ md: '40px', xs: '10px' }}
      width={{ xs: '350px', md: '590px' }}
    >
      <Typography
        variant="h5"
        color="neutral.800"
        fontWeight={500}
        paddingBottom={{ xs: 5, md: 2 }}
      >
        {t('orderForm.stepFivePageTitle')}
      </Typography>
      <Grid container rowGap={6} flexDirection={{ xs: 'column', md: 'column' }}>
        <FarmAndAddresses goToStep={goToStep} />

        <InfectionAndIsolates goToStep={goToStep} />

        <DosingAndFilling goToStep={goToStep} />

        <RenderByCondition show={[PRICING_STEP_VISIBILITY]}>
          <Pricing goToStep={goToStep} />
        </RenderByCondition>

        <AdditionalInstructions goToStep={goToStep} />

        <StepperButtonGroup
          goNextBtnTitle={
            VerifyVetToSign()
              ? t('orderForm.submitOrderTitle')
              : t('global.sign')
          }
          goBack={goBack}
          onCancel={onCancel}
          showNext={true}
          showBackBtn={true}
          showCancelBtn={true}
          nextBtnDisabled={false}
          goNext={saveAndSignOrder}
          goNextIsLoading={goNextIsLoading}
        />
      </Grid>
      <PopupConfirmation
        isOpen={open}
        handleClose={handlePopUpClose}
        title={t('orderForm.validationOrder')}
        description={description}
        actionButtons={actionButtons}
      />
    </Grid>
  );
};

export default FormRecap;
