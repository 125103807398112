import { Paper, styled } from '@mui/material';

export const StyledPaper = styled(Paper)`
  ${({ theme }) => {
    return {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '35%',
      padding: '26px 24px',
      whiteSpace: 'pre-wrap',
      borderRadius: 3,
      boxShadow: theme.shadows[5],
      [theme.breakpoints.down('md')]: {
        width: '90%',
        padding: 25,
      },
    };
  }}
`;
