import React from 'react';
import CustomStepper from 'components/organisms/Setpper';
import { Box } from '@mui/material';
import FarmAndAddresses from 'components/organisms/OrderFormSteps/FarmAndAddresses';
import { useTranslation } from 'react-i18next';
import Isolates from 'components/organisms/OrderFormSteps/Isolates';
import DosingAndFilling from 'components/organisms/OrderFormSteps/DosingAndFilling';
import PricingStep from 'components/organisms/OrderFormSteps/Pricing';
import { getFieldVisibility } from 'utils';
import AdditionalInsructionsStep from 'components/organisms/OrderFormSteps/AdditionalInsructions';
import FormRecap from 'components/organisms/OrderFormSteps/Recap';
import { FormContentProps } from './types';
import { useSelector } from 'react-redux';
import { getIsUserTypeTm } from 'store/features/orderForm/index.selectors';

const { PRICING_STEP_VISIBILITY } = getFieldVisibility();

const FormContent: React.FC<FormContentProps> = ({
  setActiveStep,
  isFormDisabled,
  toggleFormDisabled,
}) => {
  const { t } = useTranslation();

  const isUserTm = useSelector(getIsUserTypeTm);

  const steps = [
    {
      label: t('orderForm.stepOneTitle'),
      component: FarmAndAddresses as any,
      setActiveStep: (step: number) => setActiveStep(step),
    },
    {
      label: t('orderForm.stepTwoTitle'),
      component: Isolates as any,
      setActiveStep: (step: number) => setActiveStep(step),
    },
    {
      label: t('orderForm.stepThreeTitle'),
      component: DosingAndFilling as any,
      setActiveStep: (step: number) => setActiveStep(step),
    },
    {
      label: t('orderForm.stepFiveTitle'),
      component: AdditionalInsructionsStep as any,
      setActiveStep: (step: number) => setActiveStep(step),
    },
    {
      label: t('orderForm.stepSixTitle'),
      component: FormRecap as any,
      setActiveStep: (step: number) => setActiveStep(step),
    },
  ];

  if (PRICING_STEP_VISIBILITY && isUserTm) {
    steps.splice(3, 0, {
      label: t('orderForm.stepFourTitle'),
      component: PricingStep as any,
      setActiveStep: (step: number) => setActiveStep(step),
    });
  }

  return (
    <Box height={'100%'}>
      <CustomStepper
        steps={steps}
        isFormDisabled={isFormDisabled}
        toggleFormDisabled={toggleFormDisabled}
      />
    </Box>
  );
};

export default FormContent;
