import React from 'react';
import CustomCheckBox from 'components/atoms/CheckBoxButton';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { CustomTableCell, CustomTableRow } from './styles';
import { CustomTableProps, HeadCell } from './types';
import usePagination from 'hooks/usePagination';
import CustomTablePagination from '../Pagination';
import { AddressTypes } from 'enums';
import { useTranslation } from 'react-i18next';
import { getEnvironmentSettings, getFieldVisibility } from 'utils';
import TextBadge from 'components/atoms/TextBadge';
import { ORDER_ADDRESSE_STATUS, ORDER_ADDRESSE_COLOR } from 'constants/index';

const {
  ADDRESS_STATE_VISIBILITY,
  ADDRESS_CAPACITY_VISIBILITY,
  ADDRESS_COMPLEX_VISIBILITY,
} = getFieldVisibility();
const { IDM_VISIBILITY } = getEnvironmentSettings();

const CustomTable: React.FC<CustomTableProps> = ({
  rows,
  selectionMode,
  hasPagination,
  isAllChecked,
  selectedRows,
  rowsPerPage,
  onSelectAllRows,
  onSelectRow,
  addressType,
  showSelectAll = false,
  showStatus = false,
  inputSearchValue,
}) => {
  const { t } = useTranslation();
  const { currentPage } = usePagination(rows, rowsPerPage);
  const headCells: HeadCell[] = [
    {
      id: 'Status',
      field: 'Status',
      visible: showStatus,
      label: 'Status',
      addressType: [
        AddressTypes.Shipping,
        AddressTypes.Billing,
        AddressTypes.Main,
        AddressTypes.Proximity,
      ],
    },
    {
      id: 'OfficialNumber',
      field: 'OfficialNumber',
      visible: IDM_VISIBILITY,
      label: 'IDM',
      addressType: [AddressTypes.Main],
    },
    {
      id: 'Name',
      field: 'Name',
      visible: true,
      label: t('orderForm.name'),
      addressType: [
        AddressTypes.Shipping,
        AddressTypes.Billing,
        AddressTypes.Main,
        AddressTypes.Proximity,
      ],
    },
    {
      id: 'Street',
      field: 'Street',
      visible: true,
      label: t('orderForm.address'),
      addressType: [
        AddressTypes.Shipping,
        AddressTypes.Billing,
        AddressTypes.Main,
        AddressTypes.Proximity,
      ],
    },
    {
      id: 'City',
      field: 'City',
      visible: true,
      label: t('orderForm.city'),
      addressType: [
        AddressTypes.Shipping,
        AddressTypes.Billing,
        AddressTypes.Main,
        AddressTypes.Proximity,
      ],
    },
    {
      id: 'stateName',
      field: 'stateName',
      visible: ADDRESS_STATE_VISIBILITY,
      label: t('orderForm.state'),
      addressType: [
        AddressTypes.Shipping,
        AddressTypes.Billing,
        AddressTypes.Main,
        AddressTypes.Proximity,
      ],
    },
    {
      id: 'ZipCode',
      field: 'ZipCode',
      visible: true,
      label: t('orderForm.zip'),
      addressType: [
        AddressTypes.Shipping,
        AddressTypes.Billing,
        AddressTypes.Main,
        AddressTypes.Proximity,
      ],
    },
    {
      id: 'Id',
      field: 'Id',
      visible: false,
      label: 'Id',
      addressType: [
        AddressTypes.Shipping,
        AddressTypes.Billing,
        AddressTypes.Main,
        AddressTypes.Proximity,
      ],
    },
    {
      id: 'Country',
      field: 'Country',
      visible: true,
      label: t('orderForm.country'),
      addressType: [
        AddressTypes.Shipping,
        AddressTypes.Main,
        AddressTypes.Proximity,
      ],
    },
    {
      id: 'Contact',
      field: 'Contact',
      visible: true,
      label: t('orderForm.contact'),
      addressType: [AddressTypes.Shipping],
    },
    {
      id: 'PhoneNumber',
      field: 'PhoneNumber',
      visible: true,
      label: t('orderForm.phone'),
      addressType: [AddressTypes.Shipping],
    },
    {
      id: 'Capacity',
      field: 'Capacity',
      visible: ADDRESS_CAPACITY_VISIBILITY,
      label: t('orderForm.capacity'),
      addressType: [
        AddressTypes.Billing,
        AddressTypes.Main,
        AddressTypes.Proximity,
      ],
    },
    {
      id: 'Complex',
      field: 'Complex',
      visible: ADDRESS_COMPLEX_VISIBILITY,
      label: t('orderForm.complex'),
      addressType: [
        AddressTypes.Billing,
        AddressTypes.Main,
        AddressTypes.Proximity,
      ],
    },
    {
      id: 'Type',
      field: 'Type',
      visible: false,
      label: 'Type',
      addressType: [
        AddressTypes.Shipping,
        AddressTypes.Billing,
        AddressTypes.Main,
        AddressTypes.Proximity,
      ],
    },
    {
      id: 'UserId',
      field: 'UserId',
      visible: false,
      label: 'UserId',
      addressType: [
        AddressTypes.Shipping,
        AddressTypes.Billing,
        AddressTypes.Main,
        AddressTypes.Proximity,
      ],
    },
  ];
  const renderTableColumns = () => {
    return headCells
      .filter(
        (headCell) =>
          headCell?.visible && headCell?.addressType?.includes(addressType)
      )
      .map((headCell) => (
        <CustomTableCell key={headCell?.id}>{headCell?.label}</CustomTableCell>
      ));
  };

  const getStatusProps = (status: number) => {
    switch (status) {
      case 0:
        return {
          name: ORDER_ADDRESSE_STATUS.READY_FOR_REQUEST,
          color: ORDER_ADDRESSE_COLOR.READY_FOR_REQUEST,
        };
      case 1:
        return {
          name: ORDER_ADDRESSE_STATUS.UNDER_APPROVAL,
          color: ORDER_ADDRESSE_COLOR.UNDER_APPROVAL,
        };
      case 2:
        return {
          name: ORDER_ADDRESSE_STATUS.APPROVED,
          color: ORDER_ADDRESSE_COLOR.APPROVED,
        };
      default:
        return { name: '', color: undefined };
    }
  };

  const renderTableRows = () => {
    const startIndex = currentPage * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    const rowsData = rows
      ?.filter((item) => {
        if (!inputSearchValue) return item;
        else if (
          item?.name?.toLowerCase().includes(inputSearchValue.toLowerCase()) ||
          item?.officialNumber
            ?.toLowerCase()
            .includes(inputSearchValue.toLowerCase())
        ) {
          return item;
        }
      }) // filter rows based on search input
      ?.map((item, index) => {
        const data = Object.entries(item);
        const addressEntry = data.find(([key]) => key === 'address');

        if (addressEntry) {
          const addressData = Object.entries(addressEntry[1]);
          const stateEntry = addressData.find(([key]) => key === 'state');

          if (stateEntry) {
            const stateObject = stateEntry[1];

            if (stateObject?.name) {
              // If stateObject exists and has a "name" property, assign it to "state"
              data.push(['state', stateObject?.name]);
            } else {
              // If stateObject is null or missing "name", assign an empty string to "state"
              data.push(['state', '']);
            }
          }

          data.push(...addressData);
        }

        return {
          id: index + 1,
          data,
        };
      });

    return rowsData?.slice(startIndex, endIndex)?.map((row) => {
      const { id, data } = row;

      return (
        <CustomTableRow key={id} selected={selectedRows?.has(data?.[0]?.[1])}>
          {selectionMode && (
            <TableCell padding="checkbox">
              <CustomCheckBox
                sx={{ marginLeft: '1rem' }}
                checked={selectedRows?.has(data?.[0]?.[1])}
                onChange={() => onSelectRow(data?.[0]?.[1])}
                onClick={() => onSelectRow(data?.[0]?.[1])}
                type={selectedRows?.has(data?.[0]?.[1]) ? 'active' : 'normal'}
                value={''}
              />
            </TableCell>
          )}
          {headCells
            .filter(
              (headCell) =>
                headCell?.visible &&
                headCell?.addressType?.includes(addressType)
            )
            .map((headCell) => {
              const value = data?.find(
                ([key]) =>
                  key ===
                  headCell.field.charAt(0).toLowerCase() +
                    headCell.field.slice(1)
              )?.[1];

              if (headCell.id === 'Status') {
                const badgeProps = getStatusProps(value);
                return (
                  <TableCell key={headCell?.id}>
                    <TextBadge {...badgeProps} />
                  </TableCell>
                );
              }

              return <TableCell key={headCell?.id}>{value}</TableCell>;
            })}
        </CustomTableRow>
      );
    });
  };

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow sx={{ background: '#F1F3F5' }}>
            {selectionMode && (
              <TableCell padding="checkbox">
                {showSelectAll && (
                  <CustomCheckBox
                    data-testid="select-all"
                    sx={{ marginLeft: '1rem' }}
                    checked={isAllChecked}
                    type={isAllChecked ? 'active' : 'normal'}
                    onClick={() => {
                      onSelectAllRows();
                    }}
                    value={''}
                  />
                )}
              </TableCell>
            )}
            {renderTableColumns()}
          </TableRow>
        </TableHead>
        <TableBody>{renderTableRows()}</TableBody>
      </Table>
      {hasPagination && (
        <CustomTablePagination
          rows={rows}
          rowsPerPage={rowsPerPage}
        ></CustomTablePagination>
      )}
    </div>
  );
};

export default CustomTable;
