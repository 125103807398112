import { SVGProps } from 'react';

const ExpandIconClose = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="7"
    fill="none"
    viewBox="0 0 12 7"
    {...props}
  >
    <path
      fill="#6A7178"
      d="M.75 5.188L5.5.718A.716.716 0 016 .5c.188 0 .375.062.531.187l4.75 4.5c.313.282.313.75.031 1.063-.28.313-.75.313-1.062.031l-4.25-4-4.219 4C1.47 6.563 1 6.563.72 6.25.438 5.937.438 5.469.75 5.187z"
    ></path>
  </svg>
);
export default ExpandIconClose;
