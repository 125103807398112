import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
  retry,
} from '@reduxjs/toolkit/query/react';
import { RootState } from 'store';
import { Mutex } from 'async-mutex';
import { setToken } from 'store/features/auth/index.slice';
import MsalSingleton from './msal';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

const pca = MsalSingleton.getInstance();

const url = process.env.REACT_APP_ACCESS_TOKEN_SCOPE_URL;
const webClientId = process.env.REACT_APP_ACCESS_TOKEN_WEB_APP_CLIENT_ID;

const acquireToken = (instance, accounts) => {
  const request = {
    scopes: [`${url}/${webClientId}/access_as_user`],
    account: accounts[0],
  };

  return new Promise((resolve) => {
    instance
      .acquireTokenSilent(request)
      .then((tokenResponse) => {
        const fetchedAccessToken = tokenResponse?.accessToken;
        resolve(fetchedAccessToken);
      })
      .catch((error) => {
        if (error?.name === 'BrowserAuthError')
          return instance.acquireTokenRedirect(request);
        if (error instanceof InteractionRequiredAuthError) {
          return instance.acquireTokenRedirect(request);
        }
      });
  });
};

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  // Here should be baseUrl of the API
  baseUrl: `${process.env.REACT_APP_API_BASE_URL}api/`,
  prepareHeaders: (headers, api) => {
    const state = api?.getState() as RootState;
    const token = state?.authReducer?.account?.token;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

// create a new mutex
const mutex = new Mutex();
const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error && result?.error?.status === 401) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      const token = await acquireToken(pca, pca.getAllAccounts());
      try {
        if (token) {
          api.dispatch(setToken({ token } as { token: string }));
          result = await baseQuery(args, api, extraOptions);
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

const baseQueryWithRetry = retry(baseQueryWithReauth, { maxRetries: 3 });

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const api = createApi({
  /**
   * `reducerPath` is optional and will not be required by most users.
   * This is useful if you have multiple API definitions,
   * e.g. where each has a different domain, with no interaction between endpoints.
   * Otherwise, a single API definition should be used in order to support tag invalidation,
   * among other features
   */
  reducerPath: 'api',
  /**
   * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
   */
  baseQuery: baseQueryWithRetry,
  /**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   * If you want all endpoints defined in the same file, they could be included here instead
   */
  endpoints: () => ({}),
  tagTypes: [
    'Config',
    'vetData',
    'complete-docs',
    'orderAddresses',
    'Orders',
    'VetAdditionalRecipients',
    'shareRequest',
    'delete-contact',
    'delete-document',
    'edit-document',
  ],
});
