import { Grid, styled } from '@mui/material';

export const StyledGrid = styled(Grid)`
  justify-content: center;
  align-items: center;
  gap: 0px;
  align-self: stretch;
`;

export const StyledForm = styled('form')`
  padding: 2rem 2rem 2rem 2rem;
  min-width: 500px;
  min-height: 200px;
`;
