import { StyledContainer, StyledContentLink, StyledLink } from './styles';
import { RectangleIcon, SmallRectangleIcon } from 'components/icons';

const Sidebar = ({ handleButtonClick, sidebarItems, activeSection }) => {
  return (
    <StyledContainer container direction="column" spacing={4}>
      {sidebarItems?.map((section) => (
        <StyledLink key={section.id}>
          {activeSection === section.id ? (
            <RectangleIcon />
          ) : (
            <SmallRectangleIcon />
          )}

          <StyledContentLink
            active={activeSection === section.id}
            activeClass="active"
            to={section.id}
            spy={true}
            smooth={true}
            offset={-70}
            duration={800}
            onClick={() => handleButtonClick(section.id)}
          >
            {section.label}
          </StyledContentLink>
        </StyledLink>
      ))}
    </StyledContainer>
  );
};

export default Sidebar;
