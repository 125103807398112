import { Grid, Typography, useTheme } from '@mui/material';
import CustomButton from 'components/atoms/Button';
import withLayout from 'layout';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserAccount,
  getUserRole,
} from 'store/features/auth/index.selector';
import { capitalizeFirstLetter, getEnvironmentSettings } from 'utils';
import TabsNavigation from 'components/molecules/TabsNavigation';

import { useLocation, useNavigate } from 'react-router-dom';
import { NESTED_PAGES, PAGES, USER_ROLE } from 'constants/index';
import RenderByRole from 'components/atoms/RenderByRole';
import RenderByCondition from 'components/atoms/RenderByCondition';
import { resetAddressManagementAll } from 'store/features/addressesManager/index.slice';

const { STATE_INFORMATION_VISIBILITY } = getEnvironmentSettings();

const AdminDashboard: React.FC = () => {
  const { t } = useTranslation();
  const user = useSelector(getUserAccount);
  const userRole = useSelector(getUserRole);
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams, setSearchParams] = useState({
    [PAGES[userRole].DASHBOARD]: '',
    [`${PAGES[userRole].DASHBOARD}/${NESTED_PAGES[userRole].ISOLATE_INVENTORY}`]:
      '',
  });

  const navigateToOrderForm = () => {
    dispatch(resetAddressManagementAll());
    navigate(`${PAGES[userRole].ORDER_FORM}`);
  };
  const { CREATE_ORDER_BY_ROLE_VISIBILITY } = getEnvironmentSettings();

  useEffect(() => {
    if (location) {
      setSearchParams((prev) => ({
        ...prev,
        [location.pathname]: location.search,
      }));
    }
  }, [location]);

  const getOrderPageLabel = () => {
    const text =
      t('dashboard_tabs.titleTabOneNavigation')?.split(' ')?.[1] || '';

    switch (userRole) {
      case USER_ROLE.TM:
        return t('dashboard_tabs.titleTabOneNavigation');
      case USER_ROLE.VET:
        return t('dashboard_tabs.titleTabOneNavigation');
      case USER_ROLE.REGULATORY:
        return text;
      case USER_ROLE.REGULATORYMANAGER:
        return text;
      case USER_ROLE.CSS:
        return text;
    }
  };

  const tabs = useMemo(() => {
    return [
      {
        label: getOrderPageLabel(),
        path: `${PAGES[userRole].DASHBOARD}`,
        search: searchParams?.[PAGES[userRole].DASHBOARD],
      },
      {
        label: t('dashboard_tabs.titleTabTwoNavigation'),
        path: `${PAGES[userRole].DASHBOARD}/${NESTED_PAGES[userRole].ISOLATE_INVENTORY}`,
        search:
          searchParams?.[
            `${PAGES[userRole].DASHBOARD}/${NESTED_PAGES[userRole].ISOLATE_INVENTORY}`
          ],
      },
      STATE_INFORMATION_VISIBILITY &&
        userRole === USER_ROLE.REGULATORY && {
          label: 'State information',
          path: `${PAGES[userRole].DASHBOARD}/${NESTED_PAGES[userRole].DOCUMENT_CONFIGURATION}`,
          search: '',
        },
    ].filter(Boolean);
  }, [location, t]);

  return (
    <Grid container>
      <Grid item xs={12} container direction={'row'} mt={{ xs: 4, md: 0 }}>
        <Grid item xs direction="row" spacing={8}>
          <Typography variant="h4" sx={{ color: theme.palette.primary[500] }}>
            {t('global.greeting', {
              username: capitalizeFirstLetter(user?.userName),
            })}
          </Typography>
        </Grid>
        <RenderByCondition
          show={[
            !(!CREATE_ORDER_BY_ROLE_VISIBILITY && userRole === USER_ROLE.TM),
          ]}
        >
          <RenderByRole userRole={userRole} allowedRoles={[USER_ROLE.TM]}>
            <Grid item>
              <CustomButton onClick={navigateToOrderForm}>
                {t('orderForm.titleOrder')}
              </CustomButton>
            </Grid>
          </RenderByRole>
        </RenderByCondition>
      </Grid>
      <Grid item xs={12} sx={{ my: 5 }}>
        <TabsNavigation tabs={tabs} />
      </Grid>
    </Grid>
  );
};

export default withLayout(AdminDashboard);
