import { Grid, Link, Typography, styled } from '@mui/material';
import CustomButton from 'components/atoms/Button';
import theme from 'theme';

export const StyledLink = styled(Link)`
  ${{
    fontFamily: theme.typography.fontFamily,
    fontSize: '22px',
    paddingRight: '16px',
    fontStyle: 'normal',
    lineHeight: '27px',
    alignItems: 'center',
    display: 'inline-flex',
    color: theme.palette.primary[600],
  }}
`;

export const StyledTitle = styled(Typography)`
  ${{
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '35px',
    color: theme.palette.primary.main,
  }}
`;

export const StyledForm = styled('form')`
  padding: 1rem 1rem 1rem 1rem;
  min-width: 500px;
  min-height: 250px;
`;

export const StyledGrid = styled(Grid)`
  display: flex;
  padding: 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Extended-Palette-Blue-Lightest, #e5f7fd);
`;

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
export const StyledButton = styled(CustomButton)`
  ${() => ({
    boxShadow: 'none',
    background: theme.palette.neutral[50],
    ':hover': {
      borderWidth: 0,
      outline: 'none',
      background: theme.palette.neutral[50],
    },
    ':active': {
      borderWidth: 0,
      outline: 'none',
      background: theme.palette.neutral[50],
    },
    ':focus': {
      borderWidth: 0,
      outline: 'none',
      background: theme.palette.neutral[50],
    },
  })}
`;
