import { useEffect, useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import type { PDFDocumentProxy } from 'pdfjs-dist';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { StyledProgress } from './styles';
import { Grid } from '@mui/material';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

type PDFFile = string | File | null;

interface PDFViewerProps {
  fileUrl: PDFFile;
  loading?: boolean;
}

const PDFViewer = ({ fileUrl, loading = true }: PDFViewerProps) => {
  const [file, setFile] = useState(null);
  const [numPages, setNumPages] = useState<number>();
  const [isLoading, setIsLoading] = useState(() => loading);
  const [fileLoad, setFileLoad] = useState(false);

  useEffect(() => {
    setFile(fileUrl);
  }, [fileUrl]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  function onDocumentLoadSuccess({
    numPages: nextNumPages,
  }: PDFDocumentProxy): void {
    setNumPages(nextNumPages);
    setFileLoad(true);
  }

  return (
    <Grid
      item
      xs={12}
      container
      height={{ xs: '80%', md: '90%' }}
      sx={{ overflowY: 'scroll' }}
      position={'relative'}
      bgcolor={'white'}
    >
      {isLoading ? (
        <StyledProgress />
      ) : (
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          options={options}
          loading={fileLoad}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      )}
    </Grid>
  );
};

export default PDFViewer;
