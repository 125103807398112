import {
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CustomSelect from 'components/atoms/Select';
import { useTranslation } from 'react-i18next';
import {
  StyledChosenIsolatesTitle,
  StyledForm,
  StyledGridContainer,
  StyledTableHeadCell,
  StyledTableHeadCellText,
  StyledTableBodyCell,
  StyledTableBodyCellText,
} from './styles';
import { useFormik } from 'formik';
import {
  getAddresses,
  getAuthorizedIsolates,
  getFarmData,
  getFormConfig,
  getInfectionData,
  getOrderFormMode,
} from 'store/features/orderForm/index.selectors';
import * as Yup from 'yup';
import { OrderFormStepComponentProps } from 'types';
import { useDispatch, useSelector } from 'react-redux';
import { CloseIcon, DropIcon } from 'components/icons';
import StepperButtonGroup from 'components/molecules/StepperButtonGroup';
import {
  DosingAndFillingInfoKeys,
  ISOLATE_BOOST_CONSTANTS,
  ISOLATE_DEROGATION_INFO,
  ISOLATE_DEROGATION_MESSAGE_STYLES,
  ORDER_FORM_STEP_NAMES,
  InfectionInfoKeys,
  FIELD_ACTION_NAME,
} from 'constants/index';
import { setFieldValue } from 'store/features/orderForm/index.slice';
import {
  formatNumber,
  getDistinctByProperty,
  getFieldVisibility,
  isNumber,
  ISOLATE_SELECTION_LIMIT,
} from 'utils';
import { ModeState } from 'store/features/orderForm/index.type';
import CustomInput from 'components/atoms/Input';
import RenderByCondition from 'components/atoms/RenderByCondition';
import React, { useEffect, useMemo, useState } from 'react';
import TextBadge from 'components/atoms/TextBadge';
import InfoField from 'components/atoms/InfoField';
import CustomAutoComplete from 'components/molecules/AutoComplete';
import CustomDialog from 'components/molecules/Dialog';
import { IsolateDetailsContainer } from 'pages/Shared/IsolateDetails';
import { useLazyGetAuthorizedIsolatesQuery } from 'api/orderForm';
import BackdropLoader from 'components/atoms/BackdropLoader';

const formName = ORDER_FORM_STEP_NAMES.INFECTION_INFO;

const {
  SPECIFIC_PRODUCT_VISIBILITY,
  ISOLATE_DATA_VISIBILITY,
  REORDER_VISIBILITY_ISOLATE,
  ANIMAL_COUNT_VISIBILITY,
  ROUTE_OF_ADMINISTRATION_VISIBILITY,
  ISOLATE_DEROGATION_COLUMN_VISIBILITY,
} = getFieldVisibility();

const useForm = () => {
  const initialFormData = useSelector(getInfectionData);
  const { t } = useTranslation();
  const isNonAdjacentRequired =
    useSelector(getFarmData)?.nonAdjacentRequired?.value;
  const addresses = useSelector(getAddresses);

  const validationSchema = Yup.object().shape({
    animalGroups: Yup.object().shape({
      value: Yup.object()
        .shape({
          label: Yup.string().required(t('errorMessages.animalGroupRequired')),
          value: Yup.string().required(t('errorMessages.animalGroupRequired')),
        })
        .nullable()
        .required(t('errorMessages.animalGroupRequired')),
    }),

    animalSpecies: Yup.object().shape({
      value: Yup.object()
        .shape({
          label: Yup.string().required(
            t('errorMessages.animalSpeciesRequired')
          ),
          value: Yup.string().required(
            t('errorMessages.animalSpeciesRequired')
          ),
        })
        .nullable()
        .required(t('errorMessages.animalSpeciesRequired')),
    }),
    products: Yup.object().shape({
      value: Yup.object()
        .shape({
          label: Yup.string().required(t('errorMessages.productRequired')),
          value: Yup.string().required(t('errorMessages.productRequired')),
        })
        .nullable()
        .required(t('errorMessages.productRequired')),
    }),
    adjuvants: Yup.object().shape({
      value: Yup.object().shape({
        label: Yup.string().required(t('errorMessages.adjuvantRequired')),
        value: Yup.string().required(t('errorMessages.adjuvantRequired')),
      }),
    }),
    administrationRoutes: ROUTE_OF_ADMINISTRATION_VISIBILITY
      ? Yup.object().shape({
          value: Yup.object().shape({
            label: Yup.string().required(
              t('errorMessages.administrationRoutesRequired')
            ),
            value: Yup.string().required(
              t('errorMessages.administrationRoutesRequired')
            ),
          }),
        })
      : Yup.object().notRequired(),
    doses: Yup.object().shape({
      value: Yup.object().shape({
        label: Yup.string().required(t('errorMessages.doseRequired')),
        value: Yup.string().required(t('errorMessages.doseRequired')),
      }),
    }),
    numOfAnimal: ANIMAL_COUNT_VISIBILITY
      ? Yup.object().shape({
          value: Yup.number().min(1, t('errorMessages.numberOfAnimalRequired')),
        })
      : Yup.object().notRequired(),
    isolates: Yup.object().shape({
      value: Yup.array()
        .of(
          Yup.object().shape({
            label: Yup.string().required(t('errorMessages.isolateRequired')),
            value: Yup.string().required(t('errorMessages.isolateRequired')),
          })
        )
        .min(1, t('errorMessages.isolateRequired'))
        .required(t('errorMessages.isolateRequired')),
    }),
  });

  const {
    values: formikValues,
    errors,
    touched,
    isValid,
    setFieldValue: formikSetFieldValue,
    handleBlur,
  } = useFormik({
    initialValues: initialFormData,
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: () => {
      console.log('');
    },
  });

  return {
    formikValues,
    errors,
    touched,
    isValid,
    formikSetFieldValue,
    handleBlur,
    initialFormData,
    isNonAdjacentRequired,
    addresses,
  };
};

const AdministrationRoutes = (props) => {
  const { touched, errors, onChange, handleBlur, formikValues, formFieldData } =
    props;
  const { t } = useTranslation();

  const administrationRoutes = useMemo(() => {
    return formFieldData.administrationRoutes?.data?.map((route) => ({
      ...route,
      label: route?.name,
      value: route?.name,
    }));
  }, [formFieldData]);

  const handleBlurCustom = () =>
    handleBlur({ target: { id: InfectionInfoKeys.ADMINISTRATION_ROUTES } });

  const errorMessage = errors?.administrationRoutes?.value?.value;
  const isError =
    touched.administrationRoutes && Boolean(errors?.ADMINISTRATION_ROUTES);

  const onAdministrationRoutesChange = (e, value) => {
    const commonValue = { label: '', value: '' };

    const actions = [
      {
        key: InfectionInfoKeys.DOSES,
        name: FIELD_ACTION_NAME.RESET,
        formName: ORDER_FORM_STEP_NAMES.INFECTION_INFO,
        value: commonValue,
      },
      {
        key: InfectionInfoKeys.DOSES,
        name: FIELD_ACTION_NAME.SET,
        formName: ORDER_FORM_STEP_NAMES.INFECTION_INFO,
        data: value?.doses,
      },
      {
        key: DosingAndFillingInfoKeys.BOTTLES,
        name: FIELD_ACTION_NAME.RESET,
        formName: ORDER_FORM_STEP_NAMES.DOSING_AND_FILLING_INFO,
        value: null,
      },
    ];

    onChange(e, value, actions);
  };

  const isDisabled = !formikValues?.adjuvants?.value?.value;

  return (
    <Grid item xs={12} md={6}>
      <CustomSelect
        label={t('orderForm.administrationRoutes') + ' *'}
        options={administrationRoutes}
        onChange={onAdministrationRoutesChange}
        name={InfectionInfoKeys.ADMINISTRATION_ROUTES}
        onBlur={handleBlurCustom}
        value={formikValues?.administrationRoutes?.value}
        disabled={isDisabled}
        error={isError}
        errorMessage={errorMessage}
      />
    </Grid>
  );
};

const Adjuvant = (props) => {
  const { touched, errors, onChange, handleBlur, formikValues, formFieldData } =
    props;
  const { t } = useTranslation();
  const selectedAnimalGroup =
    useSelector(getInfectionData)?.animalGroups?.value;

  const initiateAdjuvantsValues = (adjuvant) => ({
    ...adjuvant,
    label: adjuvant?.name,
    value: adjuvant?.name,
  });

  const adjuvants = useMemo(() => {
    return ISOLATE_DEROGATION_COLUMN_VISIBILITY
      ? formFieldData?.adjuvants?.data?.map(initiateAdjuvantsValues)
      : selectedAnimalGroup?.adjuvants?.map(initiateAdjuvantsValues);
  }, [formFieldData, selectedAnimalGroup]);

  const handleBlurCustom = () =>
    handleBlur({ target: { id: InfectionInfoKeys.ADJUVANTS } });

  const onAdjuvantChange = (e, value) => {
    const commonValue = { label: '', value: '' };
    const actions = [
      {
        key: InfectionInfoKeys.ADMINISTRATION_ROUTES,
        name: FIELD_ACTION_NAME.RESET,
        formName: ORDER_FORM_STEP_NAMES.INFECTION_INFO,
        value: commonValue,
      },
      {
        key: ROUTE_OF_ADMINISTRATION_VISIBILITY
          ? InfectionInfoKeys.ADMINISTRATION_ROUTES
          : InfectionInfoKeys.DOSES,
        name: FIELD_ACTION_NAME.SET,
        formName: ORDER_FORM_STEP_NAMES.INFECTION_INFO,
        data: ROUTE_OF_ADMINISTRATION_VISIBILITY
          ? value?.administrationRoutes
          : value?.doses,
      },
      {
        key: InfectionInfoKeys.DOSES,
        name: FIELD_ACTION_NAME.RESET,
        formName: ORDER_FORM_STEP_NAMES.INFECTION_INFO,
        value: commonValue,
      },
      {
        key: DosingAndFillingInfoKeys.BOTTLES,
        name: FIELD_ACTION_NAME.RESET,
        formName: ORDER_FORM_STEP_NAMES.DOSING_AND_FILLING_INFO,
        value: null,
      },
    ];

    onChange(e, value, actions);
  };

  const errorMessage = errors?.adjuvants?.value?.value;
  const isError = touched.adjuvants && Boolean(errors?.adjuvants);

  const isDisabled = !formikValues?.products?.value?.value;

  return (
    <Grid item xs={12} md={ROUTE_OF_ADMINISTRATION_VISIBILITY ? 6 : 12}>
      <CustomSelect
        label={t('orderForm.adjuvant') + ' *'}
        options={adjuvants}
        onChange={onAdjuvantChange}
        name={InfectionInfoKeys.ADJUVANTS}
        onBlur={handleBlurCustom}
        value={formikValues?.adjuvants?.value}
        disabled={isDisabled}
        error={isError}
        errorMessage={errorMessage}
      />
    </Grid>
  );
};

const NumberPerDose = (props) => {
  const { touched, errors, onChange, handleBlur, formikValues, formFieldData } =
    props;
  const { t } = useTranslation();

  const handleBlurCustom = () =>
    handleBlur({ target: { id: InfectionInfoKeys.DOSES } });

  const errorMessage = errors?.doses?.value?.value;
  const isError = touched.doses && Boolean(errors?.doses);

  const doses = useMemo(() => {
    return formFieldData?.doses?.data?.map((dose) => ({
      ...dose,
      label: dose?.volume + ' ml',
      value: dose?.volume,
      id: dose?.id,
    }));
  }, [formFieldData]);

  const onDoseChange = (e, value) => {
    const actions = [
      {
        key: DosingAndFillingInfoKeys.BOTTLES,
        name: FIELD_ACTION_NAME.RESET,
        formName: ORDER_FORM_STEP_NAMES.DOSING_AND_FILLING_INFO,
        value: null,
      },
      {
        key: DosingAndFillingInfoKeys.BOTTLES,
        name: FIELD_ACTION_NAME.SET,
        formName: ORDER_FORM_STEP_NAMES.DOSING_AND_FILLING_INFO,
        data: value?.bottles,
      },
    ];

    onChange(e, value, actions);
  };

  const isDisabled = useMemo(() => {
    const adjuvantValue = formikValues?.adjuvants?.value?.value;
    const administrationRouteValue =
      formikValues?.administrationRoutes?.value?.value;

    if (ROUTE_OF_ADMINISTRATION_VISIBILITY)
      return !(adjuvantValue && administrationRouteValue);

    return !adjuvantValue;
  }, [formikValues?.adjuvants, formikValues?.administrationRoutes]);

  return (
    <Grid item xs={12} md={ANIMAL_COUNT_VISIBILITY ? 6 : 12}>
      <CustomSelect
        name={InfectionInfoKeys.DOSES}
        label={t('orderForm.volumePerDoses') + ' *'}
        options={doses}
        onChange={onDoseChange}
        onBlur={handleBlurCustom}
        value={formikValues?.doses?.value}
        disabled={isDisabled}
        error={isError}
        errorMessage={errorMessage}
        size="medium"
      />
    </Grid>
  );
};

const NumberOfAnimal = (props) => {
  const { touched, errors, onChange, handleBlur, formikValues } = props;
  const { t } = useTranslation();

  const handleBlurCustom = () =>
    handleBlur({ target: { id: InfectionInfoKeys.ANIMAL_GROUPS } });

  const handleChange = (e: any) => {
    const fieldValue = e.target.value;

    if (!isNumber(fieldValue) && fieldValue !== '') return;

    const numberValue = parseInt(fieldValue) || 0;

    onChange(e, numberValue);
  };

  const errorMessage = errors?.numOfAnimal?.value;
  const isError = touched.numOfAnimal && Boolean(errors?.numOfAnimal);

  return (
    <Grid item xs={12} md={6}>
      <CustomInput
        size="medium"
        label={t('orderForm.numberOfAnimals') + ' *'}
        name={InfectionInfoKeys.NUM_OF_ANIMAL}
        value={formatNumber(formikValues?.numOfAnimal?.value)}
        onChange={handleChange}
        inputProps={{
          style: {
            textAlign: 'center',
          },
        }}
        sx={{ minWidth: { xs: '300px', md: '100%' } }}
        labelProps={{
          paddingRight: 16,
        }}
        error={isError}
        errorMessage={errorMessage}
        onBlur={handleBlurCustom}
      />
    </Grid>
  );
};

const AnimalGroup = (props) => {
  const { touched, errors, onChange, handleBlur, formikValues, formFieldData } =
    props;
  const isError = touched?.animalGroups && Boolean(errors?.animalGroups);
  const errorMessage = errors?.animalGroups?.value?.label;

  const { t } = useTranslation();
  const orderFormMode = useSelector(getOrderFormMode);
  const IS_REORDER_MODE = orderFormMode == ModeState.REORDER;

  const animalGroup = useMemo(() => {
    return formFieldData?.animalGroups?.data?.map((animal) => ({
      ...animal,
      label: animal?.name,
      value: animal?.id,
    }));
  }, [formFieldData?.animalGroups?.data]);

  const animalGroupSelectOnChange = (e, value) => {
    const commonValue = { label: '', value: '' };
    const actions: any = [
      {
        key: InfectionInfoKeys.ANIMAL_SPECIES,
        name: FIELD_ACTION_NAME.RESET,
        formName: ORDER_FORM_STEP_NAMES.INFECTION_INFO,
        value: commonValue,
      },
      {
        key: InfectionInfoKeys.ANIMAL_SPECIES,
        name: FIELD_ACTION_NAME.SET,
        formName: ORDER_FORM_STEP_NAMES.INFECTION_INFO,
        data: value?.animalSpecies,
      },
      {
        key: InfectionInfoKeys.PRODUCTS,
        name: FIELD_ACTION_NAME.RESET,
        formName: ORDER_FORM_STEP_NAMES.INFECTION_INFO,
        value: commonValue,
      },
      {
        key: InfectionInfoKeys.ADJUVANTS,
        name: FIELD_ACTION_NAME.RESET,
        formName: ORDER_FORM_STEP_NAMES.INFECTION_INFO,
        value: commonValue,
      },
      {
        key: InfectionInfoKeys.ADMINISTRATION_ROUTES,
        name: FIELD_ACTION_NAME.RESET,
        formName: ORDER_FORM_STEP_NAMES.INFECTION_INFO,
        value: commonValue,
      },
      {
        key: InfectionInfoKeys.DOSES,
        name: FIELD_ACTION_NAME.RESET,
        formName: ORDER_FORM_STEP_NAMES.INFECTION_INFO,
        value: commonValue,
      },
      {
        key: DosingAndFillingInfoKeys.BOTTLES,
        name: FIELD_ACTION_NAME.RESET,
        formName: ORDER_FORM_STEP_NAMES.DOSING_AND_FILLING_INFO,
        value: null,
      },
    ];

    if ((IS_REORDER_MODE && REORDER_VISIBILITY_ISOLATE) || !IS_REORDER_MODE) {
      actions.push({
        key: InfectionInfoKeys.ISOLATES,
        name: FIELD_ACTION_NAME.RESET,
        formName: ORDER_FORM_STEP_NAMES.INFECTION_INFO,
        value: [],
      });
    }

    onChange(e, value, actions);
  };

  const animalGroupOnBlur = () =>
    handleBlur({ target: { id: InfectionInfoKeys.ANIMAL_GROUPS } });

  return (
    <Grid item xs={12} md={6}>
      <CustomSelect
        label={t('orderForm.animalGroup') + ' *'}
        options={animalGroup}
        onChange={animalGroupSelectOnChange}
        name={InfectionInfoKeys.ANIMAL_GROUPS}
        onBlur={animalGroupOnBlur}
        value={formikValues?.animalGroups?.value}
        error={isError}
        errorMessage={errorMessage}
      />
    </Grid>
  );
};

const AnimalSpecie = (props) => {
  const { touched, errors, onChange, handleBlur, formikValues, formFieldData } =
    props;
  const isError = touched?.animalSpecies && Boolean(errors?.animalSpecies);
  const errorMessage = errors?.animalSpecies?.value?.label;
  const { t } = useTranslation();
  const farmData = useSelector(getFarmData);
  const customerId = farmData?.billingAddresses?.value?.id;
  const farmId = farmData?.farmAddresses?.value[0]?.id;

  const [getAuthorizedIsolates, { isLoading: getAuthorizedIsolatesLoading }] =
    useLazyGetAuthorizedIsolatesQuery();

  const animalSpecies = useMemo(
    () =>
      formFieldData?.animalSpecies?.data?.map((specie) => ({
        ...specie,
        label: specie.name,
        value: specie.id,
      })) || [],

    [formFieldData?.animalGroups, formikValues?.animalGroups?.value?.value]
  );

  const animalSpecieSelectOnChange = (e, value) => {
    const commonValue = { label: '', value: '' };
    const actions: any = [
      {
        key: InfectionInfoKeys.PRODUCTS,
        name: FIELD_ACTION_NAME.RESET,
        formName: ORDER_FORM_STEP_NAMES.INFECTION_INFO,
        value: commonValue,
      },
      {
        key: InfectionInfoKeys.PRODUCTS,
        name: FIELD_ACTION_NAME.SET,
        formName: ORDER_FORM_STEP_NAMES.INFECTION_INFO,
        data: value?.products,
      },
      {
        key: InfectionInfoKeys.ADJUVANTS,
        name: FIELD_ACTION_NAME.RESET,
        formName: ORDER_FORM_STEP_NAMES.INFECTION_INFO,
        value: commonValue,
      },
      {
        key: InfectionInfoKeys.ADMINISTRATION_ROUTES,
        name: FIELD_ACTION_NAME.RESET,
        formName: ORDER_FORM_STEP_NAMES.INFECTION_INFO,
        value: commonValue,
      },
      {
        key: InfectionInfoKeys.DOSES,
        name: FIELD_ACTION_NAME.RESET,
        formName: ORDER_FORM_STEP_NAMES.INFECTION_INFO,
        value: commonValue,
      },
      {
        key: DosingAndFillingInfoKeys.BOTTLES,
        name: FIELD_ACTION_NAME.RESET,
        formName: ORDER_FORM_STEP_NAMES.DOSING_AND_FILLING_INFO,
        value: null,
      },
    ];

    if (ISOLATE_DEROGATION_COLUMN_VISIBILITY) {
      actions.push({
        key: InfectionInfoKeys.ISOLATES,
        name: FIELD_ACTION_NAME.RESET,
        formName: ORDER_FORM_STEP_NAMES.INFECTION_INFO,
        value: [],
      });
    }

    if (ISOLATE_DEROGATION_COLUMN_VISIBILITY) {
      getAuthorizedIsolates({
        farmId: farmId,
        customerId: customerId,
        animalSpecieId: value?.id,
      })
        .unwrap()
        .then(() => {
          onChange(e, value, actions);
        });
    } else onChange(e, value, actions);
  };

  const animalSpecieOnBlur = () =>
    handleBlur({ target: { id: InfectionInfoKeys.ANIMAL_SPECIES } });

  const isDisabled = !formikValues?.animalGroups?.value?.value;

  return (
    <Grid item xs={12} md={6}>
      <CustomSelect
        label={t('orderForm.animalSpecies') + ' *'}
        options={animalSpecies}
        onChange={animalSpecieSelectOnChange}
        name={InfectionInfoKeys.ANIMAL_SPECIES}
        onBlur={animalSpecieOnBlur}
        value={formikValues?.animalSpecies?.value}
        disabled={isDisabled}
        error={isError}
        errorMessage={errorMessage}
      />
      <RenderByCondition show={[Boolean(ISOLATE_DEROGATION_COLUMN_VISIBILITY)]}>
        <BackdropLoader open={getAuthorizedIsolatesLoading} />
      </RenderByCondition>
    </Grid>
  );
};

const SpecificProduct = (props) => {
  const { touched, errors, onChange, handleBlur, formikValues, formFieldData } =
    props;
  const isError = touched?.products && Boolean(errors?.products);
  const errorMessage = errors?.products?.value?.label;
  const { t } = useTranslation();

  const products = useMemo(() => {
    return formFieldData?.products?.data?.map((product) => ({
      ...product,
      value: product?.name,
      label: product?.name,
    }));
  }, [formFieldData]);

  const specificProductOnBlur = () =>
    handleBlur({ target: { id: InfectionInfoKeys.PRODUCTS } });

  const specificProductOnChange = (e, value) => {
    const commonValue = { label: '', value: '' };

    // If the chosen product is not boosting, remove boost for all isolates
    const isolates = !value?.isBoosting
      ? formikValues?.isolates?.value?.map((isolate) => ({
          ...isolate,
          isBoosted: false,
        }))
      : formikValues?.isolates?.value;

    const actions = [
      {
        key: InfectionInfoKeys.ADJUVANTS,
        name: FIELD_ACTION_NAME.RESET,
        formName: ORDER_FORM_STEP_NAMES.INFECTION_INFO,
        value: commonValue,
      },
      {
        key: InfectionInfoKeys.ADJUVANTS,
        name: FIELD_ACTION_NAME.SET,
        formName: ORDER_FORM_STEP_NAMES.INFECTION_INFO,
        data: value?.adjuvants,
      },
      {
        key: InfectionInfoKeys.ADMINISTRATION_ROUTES,
        name: FIELD_ACTION_NAME.RESET,
        formName: ORDER_FORM_STEP_NAMES.INFECTION_INFO,
        value: commonValue,
      },
      {
        key: InfectionInfoKeys.DOSES,
        name: FIELD_ACTION_NAME.RESET,
        formName: ORDER_FORM_STEP_NAMES.INFECTION_INFO,
        value: commonValue,
      },
      {
        key: DosingAndFillingInfoKeys.BOTTLES,
        name: FIELD_ACTION_NAME.RESET,
        formName: ORDER_FORM_STEP_NAMES.DOSING_AND_FILLING_INFO,
        value: null,
      },
      {
        key: InfectionInfoKeys.ISOLATES,
        name: FIELD_ACTION_NAME.RESET,
        formName: ORDER_FORM_STEP_NAMES.INFECTION_INFO,
        value: isolates,
      },
    ];

    onChange(e, value, actions);
  };

  const isDisabled = !formikValues?.animalSpecies?.value?.value;

  return (
    <Grid item xs={12}>
      <CustomSelect
        label={t('orderForm.specificProduct') + ' *'}
        options={products}
        onChange={specificProductOnChange}
        name={InfectionInfoKeys.PRODUCTS}
        value={formikValues?.products?.value}
        disabled={isDisabled}
        onBlur={specificProductOnBlur}
        error={isError}
        errorMessage={errorMessage}
      />
    </Grid>
  );
};

const AvailableIsolatesList = (props) => {
  const { isolatesListLength, isolatesList, setField, formFieldData } = props;
  const { t } = useTranslation();
  const orderFormMode = useSelector(getOrderFormMode);
  const IS_REORDER_MODE = orderFormMode == ModeState.REORDER;

  const onDeleteIsolate = (propPath: string, id: number) => {
    const filteredData = formFieldData?.isolates?.value?.filter(
      (isolate) => isolate.id !== id
    );
    if (filteredData?.length) setField(propPath, filteredData);
    else setField(propPath, []);
  };

  const oneOfSelectedIsolatesNeedsDerogation: boolean = isolatesList?.some(
    (i) => i?.styleInfo?.name === ISOLATE_DEROGATION_INFO.getName(t)
  );

  const [showIsolateDerogationMessage, setShowIsolateDerogationMessage] =
    useState(
      ISOLATE_DEROGATION_COLUMN_VISIBILITY &&
        oneOfSelectedIsolatesNeedsDerogation
    );
  const onDerogationMessageIconClick = () => {
    setShowIsolateDerogationMessage(false);
  };

  useEffect(() => {
    setShowIsolateDerogationMessage(
      ISOLATE_DEROGATION_COLUMN_VISIBILITY &&
        oneOfSelectedIsolatesNeedsDerogation
    );
  }, [oneOfSelectedIsolatesNeedsDerogation]);

  const chosenProductIsBoosting: boolean =
    formFieldData?.products?.value?.isBoosting;

  const onBoostCheckChange = (e, value, boostedIsolate) => {
    const data = isolatesList?.map((isolate) => {
      if (
        isolate?.name?.toLocaleLowerCase() ===
          boostedIsolate.name?.toLocaleLowerCase() &&
        isolate?.seroType === boostedIsolate?.seroType
      ) {
        return {
          ...isolate,
          isBoosted: value,
        };
      }
      return isolate;
    });
    setField('isolates.value', data);
  };

  const boostedIsolates = isolatesList?.filter((isolate) => isolate?.isBoosted);
  const numberOfDistinctBoostedSerotypes: number = getDistinctByProperty(
    boostedIsolates,
    'seroType'
  )?.length;

  const oneOfTheChosenIsolatesHaveTheSameSerotype = (isolate) => {
    return boostedIsolates?.some((i) => i?.seroType === isolate?.seroType);
  };

  return (
    <Grid item container xs={12}>
      <Grid item xs={12}>
        <StyledChosenIsolatesTitle>
          {t('orderForm.isolateList', {
            isolateCount: isolatesListLength,
          })}
        </StyledChosenIsolatesTitle>
      </Grid>

      <RenderByCondition show={[chosenProductIsBoosting]}>
        <Tooltip
          title={''}
          style={{
            color: ISOLATE_BOOST_CONSTANTS.maxBoostMessageTextColor,
            marginTop: '15px',
          }}
        >
          <Typography variant="body2">
            {t('orderForm.maxBoostWarning')}
          </Typography>
        </Tooltip>
      </RenderByCondition>

      <Grid xs={12} container marginTop="1rem" item sx={{ width: '100px' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableHeadCell sx={{ paddingLeft: 0 }}>
                  <Tooltip title={t('orderForm.isolateId')}>
                    <StyledTableHeadCellText variant="body2">
                      {t('orderForm.isolateId')}
                    </StyledTableHeadCellText>
                  </Tooltip>
                </StyledTableHeadCell>

                <RenderByCondition show={[ISOLATE_DATA_VISIBILITY]}>
                  <>
                    <StyledTableHeadCell sx={{ paddingLeft: 0 }}>
                      <Tooltip title={t('orderForm.farm')}>
                        <StyledTableHeadCellText variant="body2">
                          {t('orderForm.farm')}
                        </StyledTableHeadCellText>
                      </Tooltip>
                    </StyledTableHeadCell>
                    <StyledTableHeadCell sx={{ paddingLeft: 0 }}>
                      <Tooltip title={t('orderForm.capacity')}>
                        <StyledTableHeadCellText variant="body2">
                          {t('orderForm.capacity')}
                        </StyledTableHeadCellText>
                      </Tooltip>
                    </StyledTableHeadCell>
                  </>
                </RenderByCondition>

                <StyledTableHeadCell
                  sx={{ paddingLeft: 0, width: 'smallWidth' }}
                >
                  <Tooltip title={t('orderForm.seroType')}>
                    <StyledTableHeadCellText variant="body2">
                      {t('orderForm.seroType')}
                    </StyledTableHeadCellText>
                  </Tooltip>
                </StyledTableHeadCell>

                <RenderByCondition
                  show={[
                    ISOLATE_DEROGATION_COLUMN_VISIBILITY &&
                      oneOfSelectedIsolatesNeedsDerogation,
                  ]}
                >
                  <StyledTableHeadCell sx={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Tooltip title={''}>
                      <StyledTableHeadCellText variant="body2">
                        {''}
                      </StyledTableHeadCellText>
                    </Tooltip>
                  </StyledTableHeadCell>
                </RenderByCondition>

                <RenderByCondition show={[chosenProductIsBoosting]}>
                  <StyledTableHeadCell sx={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Tooltip title={t('orderForm.boostColumnName')}>
                      <StyledTableHeadCellText variant="body2">
                        {t('orderForm.boostColumnName')}
                      </StyledTableHeadCellText>
                    </Tooltip>
                  </StyledTableHeadCell>
                </RenderByCondition>

                <RenderByCondition
                  show={[
                    (IS_REORDER_MODE && REORDER_VISIBILITY_ISOLATE) ||
                      !IS_REORDER_MODE,
                  ]}
                >
                  <StyledTableHeadCell sx={{ paddingLeft: 0 }}>
                    <Tooltip title={t('orderForm.actions')}>
                      <StyledTableHeadCellText variant="body2">
                        {t('orderForm.actions')}
                      </StyledTableHeadCellText>
                    </Tooltip>
                  </StyledTableHeadCell>
                </RenderByCondition>
              </TableRow>
            </TableHead>
            <TableBody>
              {isolatesList?.map((isolate) => {
                const farmName = isolate?.originFarm?.name;
                const farmAddress = `${isolate?.originFarm?.address?.street}, ${isolate?.originFarm?.address?.city}, ${isolate?.originFarm?.address?.country}`;
                const farmPhone = isolate?.originFarm?.address?.phoneNumber;

                const capacity = isolate?.originFarm?.capacity || 0;

                return (
                  <TableRow key={isolate.id}>
                    <StyledTableBodyCell>
                      <Tooltip
                        title={isolate?.reference}
                        style={{ color: isolate?.styleInfo?.textColor }}
                      >
                        <StyledTableBodyCellText variant="body2">
                          {isolate?.reference}
                        </StyledTableBodyCellText>
                      </Tooltip>
                      <Tooltip
                        title={isolate?.name}
                        style={{ color: isolate?.styleInfo?.textColor }}
                      >
                        <StyledTableBodyCellText variant="body2">
                          {isolate?.name}
                        </StyledTableBodyCellText>
                      </Tooltip>
                    </StyledTableBodyCell>

                    <RenderByCondition show={[ISOLATE_DATA_VISIBILITY]}>
                      <>
                        <StyledTableBodyCell>
                          <Tooltip title={farmName}>
                            <StyledTableBodyCellText variant="body2">
                              {farmName}
                            </StyledTableBodyCellText>
                          </Tooltip>
                          <Tooltip title={farmAddress}>
                            <StyledTableBodyCellText variant="body2">
                              {farmAddress}
                            </StyledTableBodyCellText>
                          </Tooltip>
                          <Tooltip title={farmPhone}>
                            <StyledTableBodyCellText variant="body2">
                              {farmPhone}
                            </StyledTableBodyCellText>
                          </Tooltip>
                        </StyledTableBodyCell>
                        <StyledTableBodyCell>
                          <Tooltip title={capacity}>
                            <StyledTableBodyCellText variant="body2">
                              {capacity}
                            </StyledTableBodyCellText>
                          </Tooltip>
                        </StyledTableBodyCell>
                      </>
                    </RenderByCondition>

                    <StyledTableBodyCell>
                      <Tooltip
                        title={isolate?.seroType}
                        style={{ color: isolate?.styleInfo?.textColor }}
                      >
                        <StyledTableBodyCellText variant="body2">
                          {isolate?.seroType}
                        </StyledTableBodyCellText>
                      </Tooltip>
                    </StyledTableBodyCell>

                    <RenderByCondition
                      show={[
                        ISOLATE_DEROGATION_COLUMN_VISIBILITY &&
                          oneOfSelectedIsolatesNeedsDerogation,
                      ]}
                    >
                      <StyledTableBodyCell>
                        <Tooltip title={isolate?.styleInfo?.name}>
                          <Typography display="inline">
                            <TextBadge
                              name={isolate?.styleInfo?.name}
                              color={isolate?.styleInfo?.textColor}
                              variant="body3"
                            />
                          </Typography>
                        </Tooltip>
                      </StyledTableBodyCell>
                    </RenderByCondition>

                    <RenderByCondition show={[chosenProductIsBoosting]}>
                      <StyledTableBodyCell>
                        <Tooltip title={''}>
                          <Checkbox
                            disabled={
                              !isolate?.canBeBoosted ||
                              (!isolate?.isBoosted &&
                                !oneOfTheChosenIsolatesHaveTheSameSerotype(
                                  isolate
                                ) &&
                                numberOfDistinctBoostedSerotypes >= 2)
                            }
                            checked={isolate?.isBoosted}
                            onChange={(event) =>
                              onBoostCheckChange(
                                event,
                                event?.target?.checked,
                                isolate
                              )
                            }
                          />
                        </Tooltip>
                      </StyledTableBodyCell>
                    </RenderByCondition>

                    <RenderByCondition
                      show={[
                        (IS_REORDER_MODE && REORDER_VISIBILITY_ISOLATE) ||
                          !IS_REORDER_MODE,
                      ]}
                    >
                      <StyledTableBodyCell>
                        <StyledTableBodyCellText
                          variant="body2"
                          sx={{ cursor: 'pointer' }}
                          onClick={() =>
                            onDeleteIsolate('isolates.value', isolate?.id)
                          }
                        >
                          <DropIcon />
                        </StyledTableBodyCellText>
                      </StyledTableBodyCell>
                    </RenderByCondition>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <RenderByCondition show={[chosenProductIsBoosting]}>
        <InfoField
          message={t('orderForm.maxBoostLimitMessage')
            .replace('{{count}}', numberOfDistinctBoostedSerotypes.toString())
            .replace(
              '{{maxBoost}}',
              ISOLATE_BOOST_CONSTANTS.maxBoost.toString()
            )}
          color={ISOLATE_BOOST_CONSTANTS.maxBoostMessageTextColor}
          style={{
            margin: '15px 0 0 0',
            borderRadius: 0,
            fontSize: '15px',
            whiteSpace: 'pre-line',
          }}
        />
      </RenderByCondition>

      <RenderByCondition show={[showIsolateDerogationMessage]}>
        <InfoField
          message={t('orderForm.derogationMessage')}
          color={ISOLATE_DEROGATION_MESSAGE_STYLES.textColor}
          IconComponent={CloseIcon}
          alignIcon="right"
          style={{
            margin: '15px 0 0 0',
            borderRadius: 0,
            fontSize: '14px',
            whiteSpace: 'pre-line',
          }}
          IconStyle={{ cursor: 'pointer' }}
          onIconClick={onDerogationMessageIconClick}
        />
      </RenderByCondition>
    </Grid>
  );
};

const AvailableIsolates = (props) => {
  const { touched, errors, onChange, handleBlur, formikValues, formFieldData } =
    props;
  const isError = touched?.isolates && Boolean(errors?.isolates);
  const errorMessage = errors?.isolates?.value;
  const [isolateModalData, setIsolateModalData] = useState({
    isOpen: false,
    isolateId: null,
    selectOpen: false,
  });
  const { t } = useTranslation();
  const theme = useTheme();
  const isSMup = useMediaQuery(theme.breakpoints.up('sm'));
  const authorizedIsolates = useSelector(getAuthorizedIsolates);

  const chosenProductIsBoosting: boolean =
    formFieldData?.products?.value?.isBoosting;

  const isolates = useMemo(() => {
    const isolateNeedsDerogation = (isolate: any) =>
      !authorizedIsolates?.some((ai) => ai?.isolateId == isolate?.id) &&
      ISOLATE_DEROGATION_COLUMN_VISIBILITY;

    return formFieldData?.isolates?.data?.map((isolate) => ({
      ...isolate,
      label: isolate?.name,
      value: isolate?.id,
      isBoosted: false,
      reference: isolate?.reference,
      farmName: isolate?.originFarm?.name,
      customerName: isolate?.originCustomer?.name,
      serotype: isolate?.seroType,
      styleInfo: isolateNeedsDerogation(isolate)
        ? {
            ...ISOLATE_DEROGATION_INFO,
            name: ISOLATE_DEROGATION_INFO.getName(t),
          }
        : null,
    }));
  }, [formFieldData.isolates, authorizedIsolates]);

  const handleChange = (e, value) => {
    // set the boosting state of the newly chosen isolate to the same as the previously chosen isolates with the same name and serotype
    const previouslyChosenIsolates = [...value];
    let newlySelectedIsolate = previouslyChosenIsolates?.pop();

    if (!newlySelectedIsolate || !chosenProductIsBoosting) {
      onChange(e, value);
      return;
    }

    newlySelectedIsolate = previouslyChosenIsolates?.some(
      (isolate) =>
        isolate?.name?.toLocaleLowerCase() ===
          newlySelectedIsolate?.name?.toLocaleLowerCase() &&
        isolate?.seroType === newlySelectedIsolate?.seroType &&
        isolate?.isBoosted
    )
      ? {
          ...newlySelectedIsolate,
          isBoosted: true,
        }
      : newlySelectedIsolate;

    const data = [...previouslyChosenIsolates, newlySelectedIsolate];

    onChange(e, data);
  };

  const isolateSelectOnBlur = () =>
    handleBlur({ target: { id: InfectionInfoKeys.ISOLATES } });

  const isolateLimitWarningMessage = t('orderForm.isolateLimit', {
    count: ISOLATE_SELECTION_LIMIT as any,
  });

  const isDisabled = isolates?.length === 0;

  return (
    <Grid item xs={12}>
      <CustomAutoComplete
        label={t('orderForm.availableIsolate') + ' *'}
        name={InfectionInfoKeys.ISOLATES}
        options={isolates}
        onChange={handleChange}
        handleOpenModalIconClick={(id) => {
          setIsolateModalData({
            ...isolateModalData,
            isOpen: true,
            isolateId: id,
          });
        }}
        error={isError}
        errorMessage={errorMessage}
        isDisabled={isDisabled}
        open={isolateModalData?.selectOpen}
        handleOpen={(isOpen) => {
          setIsolateModalData({ ...isolateModalData, selectOpen: isOpen });
        }}
        handleOnBlur={() => isolateSelectOnBlur()}
        value={formikValues?.isolates?.value || []}
        limit={parseInt(ISOLATE_SELECTION_LIMIT) + 1}
      />
      <Typography color="info.main" variant="body2" mt="4px">
        {isolateLimitWarningMessage}
      </Typography>
      <CustomDialog
        open={isolateModalData?.isOpen}
        handleClose={() =>
          setIsolateModalData({
            ...isolateModalData,
            isOpen: false,
            selectOpen: true,
          })
        }
        fullScreen
        modalTitle={''}
        containerSx={{
          '& .MuiPaper-root': {
            width: isSMup ? '85%' : '100%',
            height: isSMup ? '85%' : '100%',
          },
        }}
      >
        <RenderByCondition show={[Boolean(isolateModalData?.isolateId)]}>
          <IsolateDetailsContainer
            id={isolateModalData.isolateId}
            showPageHeader={false}
          />
        </RenderByCondition>
      </CustomDialog>
    </Grid>
  );
};

const InfectionAndIsolates: React.FC<OrderFormStepComponentProps> = (props) => {
  const { goNext, goBack, onCancel } = props;
  const apiConfiguration = useSelector(getFormConfig);

  const [isFormValid, setIsFormValid] = useState(false);

  const orderFormMode = useSelector(getOrderFormMode);
  const IS_REORDER_MODE = orderFormMode == ModeState.REORDER;

  const dispatch = useDispatch();
  const {
    formikValues,
    errors,
    touched,
    isValid,
    formikSetFieldValue,
    handleBlur,
    initialFormData,
  } = useForm();

  // if chosen product is boosting, at least one isolate should be boosted
  const chosenProductIsBoosting: boolean =
    formikValues?.products?.value?.isBoosting;
  const oneOfSelectedIsolatesIsBoosted: boolean =
    formikValues?.isolates?.value.some((i) => i?.isBoosted);

  const validBoost =
    !chosenProductIsBoosting ||
    (chosenProductIsBoosting && oneOfSelectedIsolatesIsBoosted);

  useEffect(() => {
    if (
      (isValid || !SPECIFIC_PRODUCT_VISIBILITY) &&
      formikValues?.animalGroups?.value?.value &&
      formikValues?.animalSpecies?.value?.value &&
      ((SPECIFIC_PRODUCT_VISIBILITY && formikValues?.products?.value?.value) ||
        !SPECIFIC_PRODUCT_VISIBILITY) &&
      formikValues?.isolates?.value.length > 0 &&
      validBoost
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [
    isValid,
    formikValues?.animalGroups,
    formikValues?.animalSpecies,
    formikValues?.products,
    formikValues?.isolates,
    validBoost,
  ]);

  const setFieldToEmptyForDependencies = (propPathValue, value, formName) => {
    formikSetFieldValue(propPathValue, value);
    dispatch(setFieldValue({ propPath: propPathValue, value, formName }));
  };

  const setField = (propPathValue, value, actions) => {
    formikSetFieldValue(propPathValue, value);
    dispatch(
      setFieldValue({ propPath: propPathValue, value, formName, actions })
    );
  };

  const onChange = (e: any, value: any, actions = []) => {
    const fieldName = e.target.name;
    const propPathValue = `${fieldName}.value`;

    setField(propPathValue, value, actions);
  };

  const isolatesList = formikValues?.isolates?.value;

  const isolatesListLength = isolatesList?.length;

  return (
    <StyledForm>
      <StyledGridContainer container rowGap={5}>
        <Grid container spacing={2}>
          <AnimalGroup
            touched={touched}
            errors={errors}
            setField={setField}
            setFieldToEmptyForDependencies={setFieldToEmptyForDependencies}
            onChange={onChange}
            handleBlur={handleBlur}
            formikValues={formikValues}
            formFieldData={initialFormData}
          />
          <AnimalSpecie
            touched={touched}
            errors={errors}
            setField={setField}
            setFieldToEmptyForDependencies={setFieldToEmptyForDependencies}
            onChange={onChange}
            handleBlur={handleBlur}
            formikValues={formikValues}
            formFieldData={initialFormData}
          />
        </Grid>

        <RenderByCondition show={[SPECIFIC_PRODUCT_VISIBILITY]}>
          <SpecificProduct
            touched={touched}
            errors={errors}
            setField={setField}
            setFieldToEmptyForDependencies={setFieldToEmptyForDependencies}
            onChange={onChange}
            handleBlur={handleBlur}
            formikValues={formikValues}
            formFieldData={initialFormData}
            apiConfiguration={apiConfiguration}
          />
        </RenderByCondition>

        <Grid container rowSpacing={5} columnSpacing={{ md: 2, xs: 0 }}>
          <Adjuvant
            touched={touched}
            errors={errors}
            setField={setField}
            setFieldToEmptyForDependencies={setFieldToEmptyForDependencies}
            onChange={onChange}
            handleBlur={handleBlur}
            formikValues={formikValues}
            formFieldData={initialFormData}
            apiConfiguration={apiConfiguration}
          />
          <RenderByCondition show={[ROUTE_OF_ADMINISTRATION_VISIBILITY]}>
            <AdministrationRoutes
              touched={touched}
              errors={errors}
              setField={setField}
              setFieldToEmptyForDependencies={setFieldToEmptyForDependencies}
              onChange={onChange}
              handleBlur={handleBlur}
              formikValues={formikValues}
              formFieldData={initialFormData}
              apiConfiguration={apiConfiguration}
            />
          </RenderByCondition>
        </Grid>

        <Grid container rowSpacing={5} columnSpacing={{ md: 2, xs: 0 }}>
          <NumberPerDose
            touched={touched}
            errors={errors}
            setField={setField}
            setFieldToEmptyForDependencies={setFieldToEmptyForDependencies}
            onChange={onChange}
            handleBlur={handleBlur}
            formikValues={formikValues}
            formFieldData={initialFormData}
            apiConfiguration={apiConfiguration}
          />
          <RenderByCondition show={[ANIMAL_COUNT_VISIBILITY]}>
            <NumberOfAnimal
              touched={touched}
              errors={errors}
              setField={setField}
              setFieldToEmptyForDependencies={setFieldToEmptyForDependencies}
              onChange={onChange}
              handleBlur={handleBlur}
              formikValues={formikValues}
              formFieldData={initialFormData}
              apiConfiguration={apiConfiguration}
            />
          </RenderByCondition>
        </Grid>

        <RenderByCondition
          show={[
            (IS_REORDER_MODE && REORDER_VISIBILITY_ISOLATE) || !IS_REORDER_MODE,
          ]}
        >
          <AvailableIsolates
            touched={touched}
            errors={errors}
            setField={setField}
            setFieldToEmptyForDependencies={setFieldToEmptyForDependencies}
            onChange={onChange}
            handleBlur={handleBlur}
            formikValues={formikValues}
            formFieldData={initialFormData}
            apiConfiguration={apiConfiguration}
          />
        </RenderByCondition>

        <AvailableIsolatesList
          isolatesListLength={isolatesListLength}
          isolatesList={isolatesList}
          setField={setField}
          formFieldData={initialFormData}
        />

        <Grid item xs={12} sx={{ mt: 6 }}>
          <StepperButtonGroup
            goNext={goNext}
            goBack={goBack}
            onCancel={onCancel}
            showNext={true}
            showBackBtn={true}
            showCancelBtn={true}
            nextBtnDisabled={!isFormValid}
          />
        </Grid>
      </StyledGridContainer>
    </StyledForm>
  );
};

export default InfectionAndIsolates;
