import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Grid, useMediaQuery } from '@mui/material';
import CustomModal from '../Modal';
import { CustomButton } from '../CardContent/styles';
import { StyledForm } from './styles';
import { DocumentPopUpProps } from './types';
import theme from 'theme';
import CustomInput from 'components/atoms/Input';

const DocumentPopUp: React.FC<DocumentPopUpProps> = ({
  open,
  setOpen,
  onAddDocument,
  loading,
  documentTypes,
}) => {
  const { t } = useTranslation();
  const isSMup = useMediaQuery(theme.breakpoints.up('sm'));

  const getUniqueNames = () => {
    const uniqueNames = documentTypes?.map((document) => document?.name);
    return uniqueNames;
  };

  const validationSchema = yup.object().shape({
    text: yup
      .string()
      .required(t('errorMessages.fieldRequired'))
      .notOneOf(getUniqueNames() ?? [], t('errorCodes.nameMustBeUnique')),
  });

  const handleSubmit = (values) => {
    onAddDocument(values.text);
  };
  const getErrorMessage = (value) => {
    if (value !== undefined) return value.toString();
    else return '';
  };

  return (
    <CustomModal title={t('docs.addDocument')} open={open} setOpen={setOpen}>
      <Formik
        initialValues={{ text: '' }}
        validationSchema={validationSchema}
        onSubmit={() => {
          console.log('');
        }}
      >
        {({
          errors,
          values,
          handleBlur,
          handleChange,
          touched,
          dirty,
          isValid,
        }) => (
          <StyledForm>
            <Grid
              container
              direction="column"
              sx={{ width: isSMup ? '100%' : '55%' }}
            >
              <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
                <CustomInput
                  sx={{ width: '100%' }}
                  name="text"
                  placeholder={t('docs.addDocument')}
                  label={t('docs.nameDocument')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.text}
                  data-testid="document-input"
                  error={Boolean(touched.text && errors.text)}
                  errorMessage={getErrorMessage(errors.text)}
                />
              </Grid>
              <Grid container direction="row" justifyContent="flex-end">
                <Grid item>
                  <CustomButton
                    size="medium"
                    variant="outlined"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    {t('orderForm.cancel')}
                  </CustomButton>
                </Grid>
                <Grid item>
                  <CustomButton
                    size="medium"
                    type="submit"
                    disabled={!(isValid && dirty) || loading}
                    loading={loading}
                    data-testid="save-button"
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit(values);
                    }}
                  >
                    {t('orderForm.save')}
                  </CustomButton>
                </Grid>
              </Grid>
            </Grid>
          </StyledForm>
        )}
      </Formik>
    </CustomModal>
  );
};

export default DocumentPopUp;
