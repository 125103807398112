import * as React from 'react';
import { SVGProps } from 'react';

const Edit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={13}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="m11.284 1.407.31.31c.24.24.24.629 0 .867l-.745.747-1.18-1.18.745-.744c.24-.24.63-.24.867 0h.003ZM5.368 6.455l3.434-3.436 1.18 1.18-3.437 3.433a.612.612 0 0 1-.266.156l-1.497.427.427-1.497a.612.612 0 0 1 .157-.266l.002.003ZM9.546.539 4.498 5.585a1.828 1.828 0 0 0-.468.796l-.732 2.558a.614.614 0 0 0 .76.76l2.559-.731c.302-.087.575-.249.795-.469l5.049-5.045a1.843 1.843 0 0 0 0-2.605l-.31-.31a1.842 1.842 0 0 0-2.605 0Zm-7.294.998A2.252 2.252 0 0 0 0 3.79v6.96A2.252 2.252 0 0 0 2.252 13h6.96a2.252 2.252 0 0 0 2.25-2.252V7.883a.613.613 0 0 0-.613-.614.613.613 0 0 0-.614.614v2.865c0 .566-.458 1.024-1.024 1.024h-6.96a1.023 1.023 0 0 1-1.023-1.024v-6.96c0-.565.458-1.023 1.024-1.023h2.865c.34 0 .614-.273.614-.614a.613.613 0 0 0-.614-.614H2.252Z"
    />
  </svg>
);
export default Edit;
