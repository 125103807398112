import { SVGProps } from 'react';

const CheckedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="14"
    fill="none"
    viewBox="0 0 15 14"
    {...props}
  >
    <path
      fill="#00AEEF"
      d="M7.5.332A6.67 6.67 0 00.835 6.999a6.67 6.67 0 006.667 6.666A6.67 6.67 0 0014.167 7 6.67 6.67 0 007.501.332zm0 12a5.34 5.34 0 01-5.333-5.333 5.34 5.34 0 015.334-5.334A5.34 5.34 0 0112.834 7a5.34 5.34 0 01-5.333 5.333zm3.06-8.28L6.168 8.445l-1.726-1.72-.94.94 2.666 2.667 5.334-5.333-.94-.947z"
    ></path>
  </svg>
);

export default CheckedIcon;
