import {
  NESTED_PAGES,
  PAGES,
  SIGNAL_R_EVENT,
  USER_ROLE,
} from 'constants/index';
import { useEffect, useState } from 'react';

import { signalRConnectionManager } from 'config/signalR';
import { UserRole } from 'types';

export enum TaskAction {
  None,
  RedirectToOrderDetailsPage,
  GenerateRequestLetter,
  RedirectToIsolateExtensionPage,
  RedirectToExtensionInventoryPage,
  RedirectToReorderPage,
  RedirectToEditPage,
  RedirectToPermissionRejectionPage,
  RedirectToIsolateDetailPage,
  RedirectToExtensionRejectionPage,
  RedirectToPermissionInventoryPage,
  RedirectToCompleteDoc,
}

export enum TaskType {
  None,
  OrderStatusUpdate,
  AddressAddition,
  IsolateExpiration,
  IsolateExtensionRequest,
  IsolateExtensionApproval,
  IsolateExtensionRejection,
  RequestPermissionRequest,
  RequestPermissionApproval,
  RequestPermissionRejection,
  ReorderReminder,
  SharingOrders,
}

interface TaskTypeMessageMap {
  data: {
    status: string;
    displayText: string;
    id: number | string;
  };
  t: any;
  userRole: UserRole;
  taskType: TaskType;
}

function taskTypeMessageMap(
  data: TaskTypeMessageMap['data'],
  t: TaskTypeMessageMap['t'],
  userRole: TaskTypeMessageMap['userRole'],
  taskType: TaskTypeMessageMap['taskType']
) {
  const { displayText, id, status } = data;

  const messages = {
    [USER_ROLE.VET]: {
      [TaskType.OrderStatusUpdate]: t('notifications.vetOrderStatusUpdate', {
        id,
        status: status,
      }),
      [TaskType.AddressAddition]: t('notifications.vetAddressAddition', { id }),
      [TaskType.IsolateExpiration]: t('notifications.vetIsolateExpiration', {
        id,
      }),
      [TaskType.IsolateExtensionRequest]: t(
        'notifications.vetIsolateExtensionRequest',
        { id }
      ),
      [TaskType.IsolateExtensionApproval]: t(
        'notifications.vetIsolateExtensionApproval',
        { id }
      ),
      [TaskType.IsolateExtensionRejection]: t(
        'notifications.vetIsolateExtensionRejection',
        { id }
      ),
      [TaskType.RequestPermissionRequest]: t(
        'notifications.vetRequestPermissionRequest',
        { id }
      ),
      [TaskType.RequestPermissionApproval]: t(
        'notifications.vetRequestPermissionApproval',
        { id }
      ),
      [TaskType.RequestPermissionRejection]: t(
        'notifications.vetRequestPermissionRejection',
        { id }
      ),
      [TaskType.ReorderReminder]: t('notifications.vetReorderReminder', { id }),
      [TaskType.SharingOrders]: t('notifications.vetSharingOrders'),
    },
    [USER_ROLE.REGULATORY]: {
      [TaskType.OrderStatusUpdate]: t(
        'notifications.regOrderReadyForApproval',
        {
          id,
          status: status,
        }
      ),
      [TaskType.AddressAddition]: t('notifications.regOrderReadyForApproval', {
        id,
        status: status,
      }),
      [TaskType.IsolateExtensionRequest]: t(
        'notifications.regNewRequestForIsolateExtension'
      ),
      [TaskType.RequestPermissionRequest]: t(
        'notifications.regIsolateSharingPermission',
        { id }
      ),
    },
    [USER_ROLE.CSS]: {
      [TaskType.OrderStatusUpdate]: t(
        'notifications.cssOrderReadyForApproval',
        {
          id,
        }
      ),
      [TaskType.IsolateExpiration]: t(
        'notifications.cssIsolateExpirationAlert',
        {
          id,
        }
      ),
    },
    [USER_ROLE.TM]: {
      [TaskType.SharingOrders]: t(
        'notifications.tmAttendingVeterinarianSharedIntentToOrder',
        {
          name: displayText,
        }
      ),
      [TaskType.OrderStatusUpdate]: t('notifications.tmOrderStatusChange', {
        id,
        status: status,
      }),
      [TaskType.IsolateExpiration]: t(
        'notifications.tmIsolateExpirationAlert',
        {
          id,
        }
      ),
    },
  };

  return messages[userRole][taskType] ?? '';
}

const taskActionButtonMessage = (t, taskAction: TaskAction) => {
  switch (taskAction) {
    case TaskAction.GenerateRequestLetter:
      return t('notifications.generateButton');
    case TaskAction.RedirectToOrderDetailsPage:
      return t('notifications.viewButton');
    case TaskAction.RedirectToIsolateExtensionPage:
      return t('notifications.viewButton');
    case TaskAction.RedirectToIsolateDetailPage:
      return t('notifications.viewButton');
    case TaskAction.RedirectToPermissionRejectionPage:
      return t('notifications.viewButton');
    case TaskAction.RedirectToExtensionInventoryPage:
      return t('notifications.viewButton');
    case TaskAction.RedirectToEditPage:
      return t('notifications.viewButton');
    case TaskAction.RedirectToReorderPage:
      return t('notifications.reorderNowButton');
    case TaskAction.RedirectToCompleteDoc:
      return t('notifications.viewButton');
    default:
      return '';
  }
};

export const notificationMapper = (dispatch, api, toast, userRole) => {
  const mappedActions = {
    [USER_ROLE.VET]: {
      [TaskType.OrderStatusUpdate]: {
        execute: function (params: any) {
          const {
            userRole,
            id,
            navigate,
            observer = undefined,
            sendNewMessage,
            notificationId,
          } = params;
          navigate(`${PAGES[userRole].ORDER_DETAILS}/${id}`);

          sendNewMessage(
            SIGNAL_R_EVENT.SEND.ON_MARK_TASK_AS_READ,
            notificationId,
            undefined
          );

          if (observer?.on) {
            observer.on('finish', '');
          }
        },
        notificationMessage: (
          metaData,
          t,
          userRole = USER_ROLE.VET,
          taskType = TaskType.OrderStatusUpdate
        ) => taskTypeMessageMap(metaData, t, userRole, taskType),
        buttonMessage: (
          t,
          taskAction = TaskAction.RedirectToOrderDetailsPage
        ) => taskActionButtonMessage(t, taskAction),
      },
      [TaskType.AddressAddition]: {
        execute: function (params: any) {
          const { id, observer, sendNewMessage, notificationId } = params;

          const data = dispatch(
            api.endpoints.generateRequestLetter.initiate({ orderId: id })
          );

          observer.on('isLoading', { isLoading: true });

          data
            ?.unwrap()
            .then((res) => {
              toast.success('Request Letter Generated Successfully', {
                duration: 3000,
              });

              observer.on('isLoading', { isLoading: false });
              if (sendNewMessage) {
                sendNewMessage(
                  SIGNAL_R_EVENT.SEND.ON_MARK_TASK_AS_READ,
                  notificationId,
                  undefined
                );
              }
              observer.on('finish', res);
            })
            .catch((err) => {
              toast.error('Request Letter Generation Failed', {
                duration: 3000,
              });

              observer.on('isLoading', { isLoading: false });
              observer.on('finish', err);
            });
        },
        notificationMessage: (
          metaData,
          t,
          userRole = USER_ROLE.VET,
          taskType = TaskType.AddressAddition
        ) => taskTypeMessageMap(metaData, t, userRole, taskType),
        buttonMessage: (t, taskAction = TaskAction.GenerateRequestLetter) =>
          taskActionButtonMessage(t, taskAction),
      },
      [TaskType.IsolateExpiration]: {
        execute: function (params: any) {
          const {
            userRole,
            navigate,
            observer,
            sendNewMessage,
            notificationId,
          } = params;
          navigate(
            `${PAGES[userRole].DASHBOARD}/${NESTED_PAGES[userRole].ISOLATE_INVENTORY}`
          );

          sendNewMessage(
            SIGNAL_R_EVENT.SEND.ON_MARK_TASK_AS_READ,
            notificationId,
            undefined
          );

          if (observer?.on) {
            observer.on('finish', '');
          }
        },
        notificationMessage: (
          metaData,
          t,
          userRole = USER_ROLE.VET,
          taskType = TaskType.IsolateExpiration
        ) => taskTypeMessageMap(metaData, t, userRole, taskType),
        buttonMessage: (
          t,
          taskAction = TaskAction.RedirectToIsolateExtensionPage
        ) => taskActionButtonMessage(t, taskAction),
      },
      [TaskType.IsolateExtensionApproval]: {
        execute: function (params: any) {
          const {
            userRole,
            id,
            navigate,
            observer,
            sendNewMessage,
            notificationId,
          } = params;
          navigate(`${PAGES[userRole].ISOLATE_DETAILS}/${id}`);

          sendNewMessage(
            SIGNAL_R_EVENT.SEND.ON_MARK_TASK_AS_READ,
            notificationId,
            undefined
          );

          if (observer?.on) {
            observer.on('finish', '');
          }
        },
        notificationMessage: (
          metaData,
          t,
          userRole = USER_ROLE.VET,
          taskType = TaskType.IsolateExtensionApproval
        ) => taskTypeMessageMap(metaData, t, userRole, taskType),
        buttonMessage: (
          t,
          taskAction = TaskAction.RedirectToIsolateDetailPage
        ) => taskActionButtonMessage(t, taskAction),
      },
      [TaskType.IsolateExtensionRejection]: {
        execute: function (params: any) {
          const {
            userRole,
            id,
            navigate,
            observer,
            sendNewMessage,
            notificationId,
            duplicateNotification,
          } = params;

          navigate(`${PAGES[userRole].ISOLATE_EXTENSION}/${id}`);

          if (sendNewMessage) {
            duplicateNotification?.forEach((item) => {
              sendNewMessage(
                SIGNAL_R_EVENT.SEND.ON_MARK_TASK_AS_READ,
                item.id,
                undefined
              );
            });
            sendNewMessage(
              SIGNAL_R_EVENT.SEND.ON_MARK_TASK_AS_READ,
              notificationId,
              undefined
            );
          }

          if (observer?.on) {
            observer.on('finish', '');
          }
        },
        notificationMessage: (
          metaData,
          t,
          userRole = USER_ROLE.VET,
          taskType = TaskType.IsolateExtensionRejection
        ) => taskTypeMessageMap(metaData, t, userRole, taskType),
        buttonMessage: (
          t,
          taskAction = TaskAction.RedirectToPermissionRejectionPage
        ) => taskActionButtonMessage(t, taskAction),
      },
      [TaskType.RequestPermissionApproval]: {
        execute: function (params: any) {
          const {
            userRole,
            id,
            navigate,
            observer,
            sendNewMessage,
            notificationId,
          } = params;
          navigate(`${PAGES[userRole].ISOLATE_DETAILS}/${id}`);

          sendNewMessage(
            SIGNAL_R_EVENT.SEND.ON_MARK_TASK_AS_READ,
            notificationId,
            undefined
          );

          if (observer?.on) {
            observer.on('finish', '');
          }
        },
        notificationMessage: (
          metaData,
          t,
          userRole = USER_ROLE.VET,
          taskType = TaskType.RequestPermissionApproval
        ) => taskTypeMessageMap(metaData, t, userRole, taskType),
        buttonMessage: (
          t,
          taskAction = TaskAction.RedirectToIsolateDetailPage
        ) => taskActionButtonMessage(t, taskAction),
      },
      [TaskType.RequestPermissionRejection]: {
        execute: function (params: any) {
          const {
            userRole,
            id,
            navigate,
            observer,
            sendNewMessage,
            notificationId,
          } = params;
          navigate(`${PAGES[userRole].SHARE_REQUEST_DECLINE}/${id}`);

          if (sendNewMessage) {
            sendNewMessage(
              SIGNAL_R_EVENT.SEND.ON_MARK_TASK_AS_READ,
              notificationId,
              undefined
            );
          }

          if (observer?.on) {
            observer.on('finish', '');
          }
        },
        notificationMessage: (
          metaData,
          t,
          userRole = USER_ROLE.VET,
          taskType = TaskType.RequestPermissionRejection
        ) => taskTypeMessageMap(metaData, t, userRole, taskType),
        buttonMessage: (
          t,
          taskAction = TaskAction.RedirectToPermissionRejectionPage
        ) => taskActionButtonMessage(t, taskAction),
      },
      [TaskType.ReorderReminder]: {
        execute: function (params: any) {
          const {
            userRole,
            id,
            navigate,
            observer = undefined,
            sendNewMessage,
            notificationId,
          } = params;
          navigate(`${PAGES[userRole].REORDER_FORM}/${id}`);

          sendNewMessage(
            SIGNAL_R_EVENT.SEND.ON_MARK_TASK_AS_READ,
            notificationId,
            undefined
          );

          if (observer?.on) {
            observer.on('finish', '');
          }
        },
        notificationMessage: (
          metaData,
          t,
          userRole = USER_ROLE.VET,
          taskType = TaskType.ReorderReminder
        ) => taskTypeMessageMap(metaData, t, userRole, taskType),
        buttonMessage: (t, taskAction = TaskAction.RedirectToReorderPage) =>
          taskActionButtonMessage(t, taskAction),
      },
    },
    [USER_ROLE.REGULATORY]: {
      [TaskType.OrderStatusUpdate]: {
        execute: function (params: any) {
          const {
            userRole,
            id,
            navigate,
            observer = undefined,
            sendNewMessage,
            notificationId,
          } = params;
          navigate(`${PAGES[userRole].ORDER_DETAILS}/${id}`);

          sendNewMessage(
            SIGNAL_R_EVENT.SEND.ON_MARK_TASK_AS_READ,
            notificationId,
            undefined
          );

          if (observer?.on) {
            observer.on('finish', '');
          }
        },
        notificationMessage: (
          metaData,
          t,
          userRole = USER_ROLE.REGULATORY,
          taskType = TaskType.OrderStatusUpdate
        ) => taskTypeMessageMap(metaData, t, userRole, taskType),
        buttonMessage: (
          t,
          taskAction = TaskAction.RedirectToOrderDetailsPage
        ) => taskActionButtonMessage(t, taskAction),
      },
      [TaskType.AddressAddition]: {
        execute: function (params: any) {
          const {
            userRole,
            id,
            navigate,
            observer = undefined,
            sendNewMessage,
            notificationId,
          } = params;
          navigate({
            pathname: `${PAGES[userRole].COMPLETE_DOC}/${id}`,
            search: `?stateId=${null}`,
          });

          sendNewMessage(
            SIGNAL_R_EVENT.SEND.ON_MARK_TASK_AS_READ,
            notificationId,
            undefined
          );

          if (observer?.on) {
            observer.on('finish', '');
          }
        },
        notificationMessage: (
          metaData,
          t,
          userRole = USER_ROLE.REGULATORY,
          taskType = TaskType.AddressAddition
        ) => taskTypeMessageMap(metaData, t, userRole, taskType),
        buttonMessage: (t, taskAction = TaskAction.RedirectToCompleteDoc) =>
          taskActionButtonMessage(t, taskAction),
      },
      [TaskType.IsolateExtensionRequest]: {
        execute: function (params: any) {
          const {
            userRole,
            navigate,
            observer = undefined,
            sendNewMessage,
            notificationId,
          } = params;

          sendNewMessage(
            SIGNAL_R_EVENT.SEND.ON_MARK_TASK_AS_READ,
            notificationId,
            undefined
          );

          navigate({
            pathname: `${PAGES[userRole].DASHBOARD}/${NESTED_PAGES[userRole].ISOLATE_INVENTORY}`,
            search: '?extended=true',
          });

          if (observer?.on) {
            observer.on('finish', '');
          }
        },
        notificationMessage: (
          metaData,
          t,
          userRole = USER_ROLE.REGULATORY,
          taskType = TaskType.IsolateExtensionRequest
        ) => taskTypeMessageMap(metaData, t, userRole, taskType),
        buttonMessage: (
          t,
          taskAction = TaskAction.RedirectToExtensionInventoryPage
        ) => taskActionButtonMessage(t, taskAction),
      },
      [TaskType.RequestPermissionRequest]: {
        execute: function (params: any) {
          const {
            userRole,
            navigate,
            observer = undefined,
            sendNewMessage,
            notificationId,
          } = params;

          sendNewMessage(
            SIGNAL_R_EVENT.SEND.ON_MARK_TASK_AS_READ,
            notificationId,
            undefined
          );

          navigate({
            pathname: `${PAGES[userRole].DASHBOARD}/${NESTED_PAGES[userRole].ISOLATE_INVENTORY}`,
            search: '?shared=true',
          });

          if (observer?.on) {
            observer.on('finish', '');
          }
        },
        notificationMessage: (
          metaData,
          t,
          userRole = USER_ROLE.REGULATORY,
          taskType = TaskType.RequestPermissionRequest
        ) => taskTypeMessageMap(metaData, t, userRole, taskType),
        buttonMessage: (
          t,
          taskAction = TaskAction.RedirectToExtensionInventoryPage
        ) => taskActionButtonMessage(t, taskAction),
      },
    },
    [USER_ROLE.CSS]: {
      [TaskType.OrderStatusUpdate]: {
        execute: function (params: any) {
          const {
            userRole,
            id,
            navigate,
            observer = undefined,
            sendNewMessage,
            notificationId,
          } = params;
          navigate(`${PAGES[userRole].ORDER_DETAILS}/${id}`);

          sendNewMessage(
            SIGNAL_R_EVENT.SEND.ON_MARK_TASK_AS_READ,
            notificationId,
            undefined
          );

          if (observer?.on) {
            observer.on('finish', '');
          }
        },
        notificationMessage: (
          metaData,
          t,
          userRole = USER_ROLE.CSS,
          taskType = TaskType.OrderStatusUpdate
        ) => taskTypeMessageMap(metaData, t, userRole, taskType),
        buttonMessage: (
          t,
          taskAction = TaskAction.RedirectToOrderDetailsPage
        ) => taskActionButtonMessage(t, taskAction),
      },
      [TaskType.IsolateExpiration]: {
        execute: function (params: any) {
          const {
            userRole,
            id,
            navigate,
            observer = undefined,
            sendNewMessage,
            notificationId,
          } = params;
          navigate(`${PAGES[userRole].ISOLATE_DETAILS}/${id}`);

          sendNewMessage(
            SIGNAL_R_EVENT.SEND.ON_MARK_TASK_AS_READ,
            notificationId,
            undefined
          );

          if (observer?.on) {
            observer.on('finish', '');
          }
        },
        notificationMessage: (
          metaData,
          t,
          userRole = USER_ROLE.CSS,
          taskType = TaskType.IsolateExpiration
        ) => taskTypeMessageMap(metaData, t, userRole, taskType),
        buttonMessage: (
          t,
          taskAction = TaskAction.RedirectToIsolateDetailPage
        ) => taskActionButtonMessage(t, taskAction),
      },
    },
    [USER_ROLE.TM]: {
      [TaskType.SharingOrders]: {
        execute: function (params: any) {
          const {
            userRole,
            id,
            navigate,
            observer = undefined,
            sendNewMessage,
            notificationId,
          } = params;
          navigate(`${PAGES[userRole].EDIT_FORM}/${id}`);

          sendNewMessage(
            SIGNAL_R_EVENT.SEND.ON_MARK_TASK_AS_READ,
            notificationId,
            undefined
          );

          if (observer?.on) {
            observer.on('finish', '');
          }
        },
        notificationMessage: (
          metaData,
          t,
          userRole = USER_ROLE.TM,
          taskType = TaskType.SharingOrders
        ) => taskTypeMessageMap(metaData, t, userRole, taskType),
        buttonMessage: (t, taskAction = TaskAction.RedirectToEditPage) =>
          taskActionButtonMessage(t, taskAction),
      },
      [TaskType.OrderStatusUpdate]: {
        execute: function (params: any) {
          const {
            userRole,
            id,
            navigate,
            observer = undefined,
            sendNewMessage,
            notificationId,
          } = params;
          navigate(`${PAGES[userRole].ORDER_DETAILS}/${id}`);

          sendNewMessage(
            SIGNAL_R_EVENT.SEND.ON_MARK_TASK_AS_READ,
            notificationId,
            undefined
          );

          if (observer?.on) {
            observer.on('finish', '');
          }
        },
        notificationMessage: (
          metaData,
          t,
          userRole = USER_ROLE.TM,
          taskType = TaskType.OrderStatusUpdate
        ) => taskTypeMessageMap(metaData, t, userRole, taskType),
        buttonMessage: (t, taskAction = TaskAction.RedirectToEditPage) =>
          taskActionButtonMessage(t, taskAction),
      },
      [TaskType.IsolateExpiration]: {
        execute: function (params: any) {
          const {
            userRole,
            navigate,
            observer = undefined,
            sendNewMessage,
            notificationId,
          } = params;
          navigate(
            `${PAGES[userRole].DASHBOARD}/${NESTED_PAGES[userRole].ISOLATE_INVENTORY}`
          );

          sendNewMessage(
            SIGNAL_R_EVENT.SEND.ON_MARK_TASK_AS_READ,
            notificationId,
            undefined
          );

          if (observer?.on) {
            observer.on('finish', '');
          }
        },
        notificationMessage: (
          metaData,
          t,
          userRole = USER_ROLE.TM,
          taskType = TaskType.IsolateExpiration
        ) => taskTypeMessageMap(metaData, t, userRole, taskType),
        buttonMessage: (
          t,
          taskAction = TaskAction.RedirectToExtensionInventoryPage
        ) => taskActionButtonMessage(t, taskAction),
      },
    },
  };

  return mappedActions[userRole];
};

const useNotification = (serverEventKeys) => {
  const [data, setData] = useState(() =>
    Object.fromEntries(serverEventKeys.map((key) => [key, null]))
  );

  useEffect(() => {
    const eventConsumer = (event, data) => {
      if (serverEventKeys?.includes(event)) {
        setData((prev) => ({ ...prev, [event]: data }));
      }
    };
    signalRConnectionManager.registerEventConsumer(eventConsumer);

    return () => {
      signalRConnectionManager.unregisterEventConsumer(eventConsumer);
    };
  }, []);

  return {
    data: data,
    sendNewMessage: signalRConnectionManager.sendNewMessage,
  };
};

export default useNotification;
