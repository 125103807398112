import styled from '@emotion/styled';
import { Avatar, Button, Divider, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';

export const StyledAvatar = styled(Avatar)`
  width: 36px;
  height: 36px;
`;

export const StyledButton = styled(Button)`
  margin: 0px;
  padding: 0px;
  width: 36px;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

export const StyledMenuItem = styled(MenuItem)`
  width: -moz-max-content;
  height: 45px;
  display: flex;
  align-items: center;
`;

export const StyledIconBox = styled('span')`
  margin-top: 17px;
`;

export const StyledDivider = styled(Divider)`
  width: 100%;
  padding: 0px;
  margin: 0px;
`;
