import styled from '@emotion/styled';
import { GridAreaType } from './types';

export const Grid = styled('div')`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto minmax(0, 1fr) auto;
  grid-template-areas:
    'header header header header'
    'main main main main'
    'footer footer footer footer';
  min-height: 100vh;
`;

export const Item = styled('div')(
  ({ gridArea }: { gridArea: GridAreaType }) => ({
    gridArea: gridArea,
  })
);
